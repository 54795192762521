import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { Table, Row, Col, Alert } from "reactstrap"
import moment from "moment"
import _ from "lodash"

import SearchBox from "../../../components/SearchBox"
import CreateBtn from "../../../components/Button/CreateBtn"
import DeleteBtn from "../../../components/Button/DeleteBtn"
import LinkEditBtn from "../../../components/Button/LinkEditBtn"
import EditBtn from "../../../components/Button/EditBtn"
import CustomPagination from "../../../components/CustomPagination"
import SubscriptionDetail from "./SubscriptionDetail"

import setModal from "../../../components/Modal/action"

import useModal from "../../../components/Modal/useModal"

import {
    getAllSub,
    changePageSizeSubscription,
    changeCurrentPageSubscription,
    removeSub
} from "../redux/action"

const Subscription = props => {
    const { t, subscriptionList, currentPage, pageSize, pageCount, totalItems, testSuiteId } = props

    const [searchString, setSearchString] = useState("")
    const sortBy = "Name"
    const [sortOrder, setSortOrder] = useState("ASC")
    const [loaded, setLoaded] = useState(false)

    const [isOpen, setIsOpen] = useState(false)
    const [currentId, setCurrentId] = useState(null)
    const [currentTestSuite, setCurrentTestSuite] = useState();

    const toggle = () => {
        setIsOpen(!isOpen)
        setCurrentId(null)
    }

    const getAll = async page => {
        const { currentPage, pageSize } = props
        await props.getAllSub({
            currentPage: page || currentPage,
            pageSize,
            searchString,
            sortBy,
            sortOrder,
            testSuiteId
        })

        if (page) {
            await props.changeCurrentPageSubscription(page)
        }

        if (!loaded) {
            setLoaded(true)
        }
    }

    useEffect(() => {
        document.title = t("subscription_list")
        getAll(1)
    }, [testSuiteId])

    useEffect(() => {
        if (loaded) {
            getAll(1)
        }
    }, [sortOrder])

    useEffect(() => {
        if (loaded) {
            getAll()
        }
    }, [currentPage])

    const onSort = () => {
        if (sortOrder === "ASC") {
            setSortOrder("DESC")
        } else {
            setSortOrder("ASC")
        }
    }

    const onDelete = id => {
        props.setModal({
            type: "danger",
            message: t("delete_subscription_confirm"),
            cb: () => props.removeSub(id, () => getAll(1))
        })
    }

    const testSuiteListFiltered = !!testSuiteId
        ? subscriptionList.filter(item => item.isActive)
        : subscriptionList

    return <div className={!testSuiteId && "pl-2"}>
        <SubscriptionDetail
            currentTestSuite={currentTestSuite}
            testSuiteId={testSuiteId}
            id={currentId}
            isOpen={isOpen}
            toggle={toggle}
            callback={() => getAll(1)}
        />
        <Row className={`mb-2 ${!testSuiteId && "my-2"}`}>
            <Col md={12} className={!testSuiteId && "py-2"}>
                {testSuiteId
                    ? <p className="font-weight-bold">{t("subscription_list")}</p>
                    : <h5>{t("subscription_list").toUpperCase()}</h5>}
            </Col>
            <Col md={8} className="d-flex">
                <div className="mr-2">
                    <CreateBtn
                        size="sm"
                        title={t("create_subscription")}
                        style={{ width: "150px", height: "32px" }}
                        onClick={() => {
                            // open modal
                            toggle()
                        }}
                    />
                </div>
                <SearchBox
                    className="mr-0"
                    value={searchString}
                    onChange={e => setSearchString(e.target.value)}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            getAll(1);
                        }
                    }}
                />
            </Col>
        </Row>
        {
            totalItems
                ? <Row>
                    <Col md={8} className="text-right">
                        <b>{t("total_subscription")}: {totalItems}</b>
                    </Col>
                </Row>
                : null
        }
        <Row className="mb-2">
            {testSuiteListFiltered && testSuiteListFiltered.length
                ? <Col md={8}>
                    <Table hover bordered striped responsive className="grid-table mb-2">
                        <thead>
                            <tr>
                                <th>
                                    {t("start_time")}
                                </th>
                                <th>
                                    {t("end_time")}
                                </th>
                                <th>
                                    {t("student")}
                                </th>
                                <th>
                                    {t("number_of_payments")}
                                </th>
                                {!testSuiteId && <th>
                                    {t("test_suite")}
                                </th>}
                                {!testSuiteId && <th className="text-center">
                                    {t("is_active")}
                                </th>}
                                <th className="text-center">{t("action")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {testSuiteListFiltered.map((subscription, index) => <tr key={index}>
                                <td>
                                    {moment(subscription.startTime).format("L")}
                                </td>
                                <td>
                                    {moment(subscription.endTime).format("L")}
                                </td>
                                <td>
                                    <LinkEditBtn
                                        label={_.get(subscription, "student.name", "")}
                                        onClick={() => {
                                            window.open(`/user/edit-user/${_.get(subscription, "student.identityId", 0)}`)
                                        }}
                                    />
                                </td>
                                <td>
                                    {subscription.paymentCount}
                                </td>
                                {!testSuiteId && <td>
                                    <LinkEditBtn
                                        label={_.get(subscription, "testSuite.name", "")}
                                        onClick={() => {
                                            window.open(`/edit-test-suite/${_.get(subscription, "testSuite.id", 0)}`)
                                        }}
                                    />
                                </td>}
                                {!testSuiteId && <td className="text-center">
                                    {subscription.isActive
                                        ? <i className="fa fa-check text-success" />
                                        : <i className="fa fa-times text-danger" />}
                                </td>}
                                <td className="text-center pl-3">
                                    <EditBtn
                                        className="mr-2 d-inline"
                                        onClick={() => {
                                            setCurrentTestSuite(subscription.testSuite)
                                            setCurrentId(subscription.id)
                                            setIsOpen(true)
                                        }}
                                    />
                                    {/* <DeleteBtn
                                        className="d-inline mr-3"
                                        onClick={() => onDelete(subscription.id)}
                                    /> */}
                                </td>
                            </tr>)}
                        </tbody>
                    </Table>
                </Col>
                : <Col md={8}>
                    <Alert color="primary" className="mb-0">{t("no_subscription_to_display")}</Alert>
                </Col>}
        </Row>

        <Row>
            <Col md={8} className="text-right">
                <CustomPagination
                    pageCount={pageCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    pageSizeName="subscriptionPageSize"
                    changePage={page => {
                        props.changeCurrentPageSubscription(page);
                    }}
                    changePageSize={props.changePageSizeSubscription}
                    onChangePageSize={() => getAll(1)}
                />
            </Col>
        </Row>
    </div >
}

const mapStateToProps = state => ({
    subscriptionList: state.subscription.subscriptionList,
    currentPage: state.subscription.currentPage,
    pageCount: state.subscription.pageCount,
    pageSize: state.subscription.pageSize,
    totalItems: state.subscription.totalItems
})

const mapDispatchToProps = {
    setModal,
    getAllSub,
    changePageSizeSubscription,
    changeCurrentPageSubscription,
    removeSub
}

const SubscriptionHOC = withTranslation("common")(Subscription)

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionHOC)