import React, { Component } from 'react';
import _ from "lodash"
import moment from "moment";
import { withTranslation } from 'react-i18next'

import { Col, Row, Alert, Table } from "reactstrap";
import LinkEditBtn from '../../../components/Button/LinkEditBtn';
import Tooltip from "../../../components/Tooltip";

const styles = {
    table: {
        maxHeight: 250,
        overflowY: "auto",
        display: "block",
    },
}

class ChildTable extends Component {
    // renderTooltipCourses = () => {
    //     const { data } = this.props;
    //     if (data.courses && data.courses.length > 0) {
    //         return data.courses.map(c => {
    //             const id = `tooltip-course-${c.id}`;
    //             //   console.log(id)
    //             //   console.log(c)
    //             return (
    //                 <Tooltip key={id} id={id} {...this.props}>
    //                     <p>Course name: <strong>{c.name}</strong></p>
    //                     <p>Price: {c.price}</p>
    //                     <p>Date: {moment(c.startDate).format("YYYY/MM/DD")} - {moment(c.endDate).format("YYYY/MM/DD")}</p>
    //                     <p>Time: {moment(c.startTime, "HH:mm:ss").format("HH:mm")} - {moment(c.endTime, "HH:mm:ss").format("HH:mm")}</p>
    //                     <p>Students: {c.numberOfStudents}</p>
    //                 </Tooltip>
    //             );
    //         })
    //     }
    // }

    render() {
        const { data, t } = this.props;
        // console.log(data)

        return (
            <Row form className="mt-2 p-2" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                {/* {this.renderTooltipCourses()} */}

                <Col md="4" style={styles.table}>
                    {
                        !data.courses || (data.courses && data.courses.length === 0) ?
                            <Alert color="primary">{t('no_course_to_display')}</Alert>
                            :
                            <Table bordered className="table table-striped">
                                <thead >
                                    <tr>
                                        <th>{t('course_name')}</th>
                                        <th>{t('price')}</th>
                                        <th>{t('discount')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.courses.map((m, index) => (
                                            <tr key={`${index}`}>
                                                <td>
                                                    <LinkEditBtn
                                                        onClick={() => window.open(`/course/edit-course/${m.id}`)}
                                                        label={m.name}
                                                        data-tip={`tooltip-course-${m.id}`}
                                                        data-for={`tooltip-course-${m.id}`}
                                                    />
                                                </td>
                                                <td>{m.price}</td>
                                                <td>{m.discount}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                    }
                </Col>
                {data.programs && data.programs.length > 0 ? data.programs.map((program, programIndex) => {
                    return <Col className="mb-2" md="4" style={styles.table}>
                        <Table bordered className="table table-striped">
                            <thead>
                                <tr>
                                    <th>
                                        {t('program')} {programIndex + 1}: <span><LinkEditBtn
                                            onClick={() => window.open(`/course/edit-program/${_.get(program, "id")}`)}
                                            label={`${_.get(program, "name")}`}
                                        /></span>
                                    </th>
                                    <th>{t('price')}</th>
                                    <th>{t('discount')}: {_.get(program, "programDiscount")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    program.courses && program.courses.map((m, courseIndex) => (
                                        <tr key={`${courseIndex}`}>
                                            <td>
                                                <LinkEditBtn
                                                    onClick={() => window.open(`/course/edit-course/${_.get(m, "id")}`)}
                                                    label={_.get(m, "name")}
                                                />
                                            </td>
                                            <td colSpan="2">{_.get(m, "price")}</td>
                                            {/* <td>{_.get(m, "discount")}</td> */}
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                }) : <Col md="4" style={styles.table}>
                        <Alert color="primary">{t('no_programs_to_display')}</Alert>
                    </Col>}
            </Row>
        );
    }
}

export default withTranslation('common')(ChildTable)
