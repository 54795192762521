export default (state = {
    testResultList: [],
    totalItems: 0,
    currentPage: 1,
    pageCount: 1,
    pageSize: localStorage.getItem("testResultPageSize") ? Number(localStorage.getItem("testResultPageSize")) : 30
}, action) => {
    switch (action.type) {
        case "SET_TEST_RESULT":
            return {
                ...state,
                testResultList: action.payload,
                // currentPage: action.currentPage,
                // sortBy: action.sortBy,
                // sortOrder: action.sortOrder,
                totalItems: action.totalItems,
                pageCount: action.pageCount || 1,
            }
        case "CHANGE_PAGE_SIZE_TEST_RESULT":
            return {
                ...state,
                pageSize: action.payload
            }
        case "CHANGE_CURRENT_PAGE_TEST_RESULT":
            return {
                ...state,
                currentPage: action.payload
            }
        default:
            return state
    }
}