import React, { memo } from 'react'
import { Table } from "reactstrap";
import _ from "lodash";
import LinkEditBtn from './Button/LinkEditBtn';

export default memo(function StudentListTable(props) {
    const { t } = props
    return (
        <Table bordered className="table table-striped">
            <thead>
                <tr>
                    <th>{t('email')}</th>
                    <th>{t('first_name')}</th>
                    <th>{t('last_name')}</th>
                    <th>{t('cell_phone')}</th>
                </tr>
            </thead>
            <tbody>
                {
                    props.students.map((m, index) => (
                        <tr key={`${index}`}>
                            <td>
                                <LinkEditBtn
                                    onClick={() => window.open(`/user/edit-user/${_.get(m, "student.identity.id")}`)}
                                    label={_.get(m, "student.identity.email")}
                                />
                                {/* <span className="float-right" style={{ marginTop: 1 }}>{_.get(m, "student.identity.id") + 10000}</span> */}
                            </td>
                            <td>{_.get(m, "student.identity.firstName")}</td>
                            <td>{_.get(m, "student.identity.lastName")}</td>
                            <td>{_.get(m, "student.identity.phoneNumber")}</td>
                        </tr>
                    ))
                }
            </tbody>
        </Table>
    )
})
