import { updateQuestion, createQuestion, getAllQuestion, deleteQuestion, getQuestion } from "../../../util/api"
import setLoading from "../../../components/Spinner/action"
import setModal from "../../../components/Modal/action"
import setAlert from "../../../components/Alert/action"
import history from "../../../util/history"

export const create = data => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await createQuestion(data)
        if (!res.data) throw new Error("")

        dispatch(setAlert({
            type: "success",
            message: "create_question_success"
        }))

        dispatch(setLoading(false))
        return res.data
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: err.response && err.response.data || "create_question_error"
        }))
    }

    dispatch(setLoading(false))
}

export const update = data => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await updateQuestion(data)
        if (!res.data) throw new Error("")

        dispatch(setAlert({
            type: "success",
            message: "update_question_success"
        }))

        dispatch(setLoading(false))
        return res.data
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: [JSON.stringify(err.response.data), "update_question_error"]
        }))
    }

    dispatch(setLoading(false))
}

export const getQuestions = ({ currentPage, pageSize, searchString, categoryId, type, articleId }) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await getAllQuestion({ currentPage: currentPage || 0, pageSize: pageSize || 0, searchString: searchString || '', categoryId: categoryId || 0, type, articleId: articleId || 0 })
        if (!res.data) throw new Error("")

        dispatch({
            type: "SET_QUESTION_LIST",
            payload: res.data.data,
            currentPage: res.data.currentPage,
            totalItems: res.data.totalItems,
            pageCount: res.data.pageCount,
        })
        if (!pageSize && !currentPage && !categoryId && !searchString && !type) {
            dispatch({
                type: "SET_FULL_QUESTION_LIST",
                payload: res.data.data,
            })
        }
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: [JSON.stringify(err.response.data), "get_all_question_error"]
        }))
    }

    dispatch(setLoading(false))
}

export const changePageSizeQuestionList = pageSize => async dispatch => {
    dispatch({
        type: "CHANGE_PAGE_SIZE_QUESTION_LIST",
        payload: pageSize
    })
}

export const removeQuestion = ({ id, cb }) => async dispatch => {
    dispatch(setLoading(true))

    try {
        await deleteQuestion(id)

        if (cb) cb()
        dispatch(setAlert({
            type: "success",
            message: "delete_question_success"
        }))
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: [JSON.stringify(err.response.data), "delete_question_error"]
        }))
    }

    dispatch(setLoading(false))
}

export const get = ({ id, cb }) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await getQuestion(id)
        if (!res.data) throw new Error("")

        if (cb) cb(res.data)
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: err.response && err.response.data && err.response.data.status === 400 ? 'Question not found.' : err.message
        }))
        history.push("/question-bank/questions")
    }

    dispatch(setLoading(false))
}