const initialState = {
    testSuitePayments: [],
    totalItems: 0,
    currentPage: 1,
    pageCount: 1,
    pageSize: localStorage.getItem("paymentPageSize")
        ? Number(localStorage.getItem("paymentPageSize"))
        : 30
}

export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_TEST_SUITE_PAYMENT_LIST":
            return {
                ...state,
                testSuitePayments: action.payload,
                // currentPage: action.currentPage,
                sortBy: action.sortBy,
                sortOrder: action.sortOrder,
                totalItems: action.totalItems,
                pageCount: action.pageCount || 1
            }
        case "CHANGE_PAGE_SIZE_TEST_SUITE_PAYMENT":
            return {
                ...state,
                pageSize: action.payload
            }
        case "CHANGE_CURRENT_PAGE_TEST_SUITE_PAYMENT":
            return {
                ...state,
                currentPage: action.payload
            }
        default:
            return state
    }
}
