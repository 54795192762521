export default (state = {
    fullAssessmentList: [],
    assessmentList: [],
    totalItems: 0,
    currentPage: 1,
    pageCount: 1,
    pageSize: localStorage.getItem("assessmentListPageSize") ? Number(localStorage.getItem("assessmentListPageSize")) : 30
}, action) => {
    switch (action.type) {
        case "SET_ASSESSMENT_LIST":
            return {
                ...state,
                assessmentList: action.payload,
                currentPage: action.currentPage,
                totalItems: action.totalItems,
                pageCount: action.pageCount,
            }
        case "SET_FULL_ASSESSMENT_LIST":
            return {
                ...state,
                fullAssessmentList: action.payload,
            }
        case "CHANGE_PAGE_SIZE_ASSESSMENT_LIST":
            return {
                ...state,
                pageSize: action.payload
            }
        default:
            return state
    }
}