import React from "react";
import {CardBody, Col, Row, TabContent, TabPane, Nav, NavItem, NavLink} from "reactstrap";
import cn from "classnames";
import {Route} from "react-router-dom";
import Page404 from "./Page404";

const CustomTabs = (props) => {
    const {location: {pathname}, history, tabs, title, className} = props
    const toggle = (path) => {
        history.push(path);

    }
    const renderContent = () => {
        const activeTab = tabs.map(t => t.path).indexOf(pathname);

        if (activeTab === -1) {
            return <Page404/>
        }
        return (
            <Row>
                <Col xl={12}>
                    <div className="header-wrapper">
                        <strong className="table-header-label">{title}</strong>
                    </div>

                    <CardBody className="grid-body reduced-padding-grid">
                        <Nav tabs>
                            {tabs.map(i => (
                                <NavItem key={i.path}>
                                    <NavLink
                                        className={cn({active: pathname === i.path})}
                                        onClick={() => {
                                            toggle(i.path);
                                        }}
                                    >
                                        {i.title}
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>
                        <TabContent
                            activeTab={pathname}
                            className="border-bottom-0 border-left-0 border-right-0 reduced-padding-tabcontent"
                        >
                            {tabs.map(i => (
                                <TabPane key={i.path} tabId={i.path}>
                                    <Route
                                        path={i.path}
                                        exact
                                        component={i.component}
                                    />
                                </TabPane>
                            ))}
                        </TabContent>
                    </CardBody>
                </Col>
            </Row>
        )
    }
    return (
        <div className={cn("animated fadeIn", className)}>
            {renderContent()}
        </div>
    )
}

export default CustomTabs