import React, { useState, useEffect, useCallback } from "react"
import { Row, Col, Input, CustomInput, Button } from "reactstrap"
import {CKEditor} from "ckeditor4-react"
import _ from "lodash"

import DeleteBtn from "../../../components/Button/DeleteBtn"
import MultiSelectInput from "./MultiSelectInput"
import CustomSelected from "../../../components/Selector/CustomSelected"
import ProwritingEditor from "../../MyTask/ProwritingEditor"

const AnswerList = (props) => {
  const {
    isMulti,
    isSingleChoice,
    isOnlyOneAnswer,
    isMatrix,
    isBlankText,
    isBlankDropDown,
    isHotText,
    isHotspot,
    isInlineOrderDragDrop,
    isMatchThePairs,
    isBlankDragDrop,
    isHotspotDragDrop,
    isDropZoneDragDrop,
    answerArr,
    xCoordinate,
    yCoordinate,
    t,
    spots,
    blanks,
    dropZones,
    onChangeAnswer,
    onAddNewAnswer,
    onCheckCorrectAnswer,
    onRemoveAnswer,
    handleBlankDropDownChange,
    handleBlankDropDownInputChange,
    handleBlankDropDownKeyDown,
    onSelectCorrectAnswerBlankDropDown,
    onSetAnswerOrder,
    onChangeMatchingText,
    disabled,
    currentEdittingEditorIdRef,
    noSetInitValue,
    isClickPublish
  } = props

  const [renderAnswers, setRenderAnswers] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => setRenderAnswers(true), 500)
    return () => clearTimeout(timeout)
  }, [answerArr])

  let matrixAnswersCode = []
  if (isMatrix && xCoordinate && yCoordinate) {
    let index = 0
    for (let i = 1; i <= xCoordinate.length; i++) {
      for (let j = 1; j <= yCoordinate.length; j++) {
        matrixAnswersCode = [
          ...matrixAnswersCode,
          { id: index, text: `X${i} - Y${j}` },
        ]
        index++
      }
    }
  }

  const isBlank = isBlankText || isBlankDropDown || isBlankDragDrop

  // order options
  const orderLength = answerArr && answerArr.length
  const orderOptions = Array.from(
    { length: orderLength },
    (item, index) => index + 1
  ).map((order) => ({
    label: order,
    value: order,
  }))

  // hotspot options
  const spotLength = spots ? spots.length : 0
  const hotspotOptions = Array.from(
    { length: spotLength },
    (item, index) => index
  ).map((index) => ({
    label: index + 1,
    value: index + 1,
    id: spots ? spots[index].id : "",
  }))

  // dropzones options
  const dropZonesOptions =
    dropZones && dropZones.length
      ? dropZones.map((zone) => ({ value: zone.text, label: zone.text }))
      : []

  // config ckeditor
  let editorConfig = {
    height: 150,
    allowedContent: true,
    toolbar: [
      {
        name: "basicstyles",
        groups: ["basicstyles", "cleanup"],
        items: ["Bold", "Italic"],
      },
      { name: "insert", items: ["Image"] },
      {
        name: "wiris",
        items: [
          "ckeditor_wiris_formulaEditor",
          "ckeditor_wiris_formulaEditorChemistry",
        ],
      },
    ],
    // removePlugins: "toolbar",
  }

  if (
    isBlankDragDrop ||
    isMatchThePairs ||
    isDropZoneDragDrop ||
    isInlineOrderDragDrop ||
    isHotspotDragDrop ||
    isSingleChoice ||
    isMulti
  ) {
    editorConfig = {
      ...editorConfig,
      filebrowserImageUploadUrl: "/api/file/Upload?",
      filebrowserUploadUrl: "/api/file/Upload?",
    }
  }

  // min answer
  const minAnswer =
    isSingleChoice || isMulti || isInlineOrderDragDrop || isMatchThePairs
      ? 2
      : 1

  const blankOptions =
    blanks && blanks.length
      ? blanks.map((item) => ({ value: item, label: item }))
      : []

  const onChangeAnswerMemo = (content, answerId) => { 
    onChangeAnswer({
      option: "text",
      data: content,
      id: answerId,
    })
  }

  return (
    <div className="mb-2">
      {answerArr && answerArr.length ? (
        <Row>
          <Col md={1}>
            {!isOnlyOneAnswer &&
              !isMatrix &&
              !isBlankText &&
              !isBlankDropDown &&
              !isHotText &&
              !isHotspot && (
                <Button
                  disabled={disabled}
                  color="primary"
                  // size="lg"
                  style={{ width: "120px" }}
                  onClick={onAddNewAnswer}
                >
                  <i className="fa fa-plus mr-1" />
                  {t("add_new_row")}
                </Button>
              )}
          </Col>
          {(isBlank || isHotText) && <Col md={2}>{t("token")}</Col>}
          {!isHotspot && (
            <Col className="ml-5" md={isBlank || isHotText || isMatchThePairs ? 4 : 8}>
              {t("answer")}
            </Col>
          )}
          {isMatchThePairs && <Col md={4}>{t("matching_text")}</Col>}
          {/* {!isInlineOrderDragDrop && <Col md={1}>{t("mark")}</Col>} */}
          {/* {!isBlankText &&
            !isBlankDragDrop &&
            !isInlineOrderDragDrop &&
            !isMatchThePairs &&
            !isHotspotDragDrop &&
            !isDropZoneDragDrop && (
              <Col md={1} className="text-center">
                {t("correct_answer")}
              </Col>
            )} */}
          {isDropZoneDragDrop && <Col md={2}>{t("drop_zone")}</Col>}
          {isHotspotDragDrop && <Col md={2}>{t("hotspot")}</Col>}
          {isInlineOrderDragDrop && <Col md={2}>{t("order")}</Col>}
          {answerArr.length > 2 && <Col md={1} className="text-center" />}
        </Row>
      ) : null}

      <hr className="my-2" />

      {renderAnswers &&
        answerArr &&
        answerArr.map((item, index) => {
          // !!item.text && item.text =
          return (
            <React.Fragment key={item.id}>
              <Row className="ckeditoranswer" id={index}>
                <Col md={1}>Option {index + 1}</Col>

                {(isBlankText || isBlankDropDown || isHotText) && (
                  <Col md={2}>
                    <Input readOnly value={item.token} />
                  </Col>
                )}

                {isBlankDragDrop && (
                  <Col md={2}>
                    <CustomSelected
                      options={blankOptions}
                      value={blankOptions.find(
                        (option) => option.value === item.token
                      )}
                      onChange={(e) =>
                        onChangeAnswer({
                          option: "token",
                          data: e ? e.value : null,
                          id: item.id,
                        })
                      }
                      isClearable
                    />
                  </Col>
                )}

                {!isHotspot && (
                  <Col md={isBlank || isHotText || isMatchThePairs ? 4 : 8}>
                    {isMatrix && (
                      <h6>
                        {matrixAnswersCode[index]
                          ? matrixAnswersCode[index].text
                          : ""}
                      </h6>
                    )}

                    {(isBlankText || isHotText) && (
                      <Input
                        value={item.text}
                        onChange={(e) =>
                          onChangeAnswer({
                            option: "text",
                            data: e.target.value,
                            id: item.id,
                          })
                        }
                      />
                    )}

                    {isBlankDropDown && (
                      <MultiSelectInput
                        t={t}
                        value={_.get(item, "value", [])}
                        inputValue={_.get(item, "inputValue", "")}
                        handleChange={handleBlankDropDownChange(
                          _.get(item, "id")
                        )}
                        handleInputChange={handleBlankDropDownInputChange(
                          _.get(item, "id")
                        )}
                        handleKeyDown={handleBlankDropDownKeyDown(
                          _.get(item, "id")
                        )}
                      />
                    )}

                    {/* {isHotspot && null} */}

                    {!isMatrix &&
                      !isBlankText &&
                      !isBlankDropDown &&
                      !isHotText && (
                        <ProwritingEditor 
                          currentEdittingEditorIdRef={currentEdittingEditorIdRef}
                          initValue={item.text}
                          answerId={item.id}
                          onChange={onChangeAnswerMemo}
                          isAnswerList
                          isClickPublish={isClickPublish}
                          renderInput={() => {
                            return !isMulti ? (
                              <input
                                title="Correct answer"
                                type="radio"
                                checked={item.isCorrectAnswer || item.checked}
                                onChange={(e) =>
                                  onCheckCorrectAnswer({ e, id: item.id })
                                }
                              />
                            ) : (
                              <input
                                title="Correct answer"
                                type="checkbox"
                                id={`correctAnswer-${index}`}
                                checked={item.isCorrectAnswer || item.checked}
                                onChange={(e) =>
                                  onCheckCorrectAnswer({ e, id: item.id })
                                }
                              />
                            )
                          }}
                        />
                      )}
                  </Col>
                )}

                {isMatchThePairs && (
                  <Col md={4}>
                    <CKEditor
                      data={item.matchingText}
                      onChange={(e) =>
                        setTimeout(() => {
                          onChangeAnswer({
                            option: "matchingText",
                            data: e.editor.getData(),
                            id: item.id,
                          })
                        }, 100)
                      }
                      config={editorConfig}
                      onBeforeLoad={(CKEDITOR) => {
                        CKEDITOR.disableAutoInline = true
                        CKEDITOR.config.height = 75
                        CKEDITOR.config.toolbar = "default"
                        CKEDITOR.config.enterMode = CKEDITOR.ENTER_BR
                      }}
                    />
                  </Col>
                )}

                {/* {!isInlineOrderDragDrop && (
                  <Col md={1}>
                    <Input
                      type="number"
                      value={item.mark || ""}
                      onChange={(e) =>
                        onChangeAnswer({
                          option: "mark",
                          data: e.target.value,
                          id: item.id,
                        })
                      }
                    />
                  </Col>
                )} */}

                {isInlineOrderDragDrop && (
                  <Col md={2}>
                    <CustomSelected
                      options={orderOptions}
                      value={
                        _.get(item, "correctOrder")
                          ? orderOptions.find(
                              (order) =>
                                order.value === _.get(item, "correctOrder")
                            )
                          : null
                      }
                      onChange={(e) =>
                        onSetAnswerOrder({ id: item.id, value: e.value })
                      }
                      // onChange={e => onSelectCorrectAnswerBlankDropDown(index, e.value)}
                      // value={_.get(item, "value") ? _.get(item, "value").find(val => val.isCorrectAnswer) : null}
                    />
                  </Col>
                )}

                {isHotspotDragDrop && (
                  <Col md={2}>
                    <CustomSelected
                      isMulti
                      options={hotspotOptions}
                      onChange={(e) =>
                        onChangeMatchingText({ id: item.id, value: e })
                      }
                      value={
                        _.get(item, "matchingText")
                          ? hotspotOptions.filter((hotspot) =>
                              _.get(item, "matchingText").includes(hotspot.id)
                            )
                          : null
                      }
                      // value={_.get(item, "matchingText") ? hotspotOptions.filter(hotspot => _)}
                    />
                  </Col>
                )}

                {/* {!isBlank &&
                  !isInlineOrderDragDrop &&
                  !isMatchThePairs &&
                  !isHotspotDragDrop &&
                  !isDropZoneDragDrop && (
                    <Col md={1} className="text-center">
                      {!isMulti ? (
                        <Input
                          type="radio"
                          checked={item.isCorrectAnswer || item.checked}
                          onChange={(e) =>
                            onCheckCorrectAnswer({ e, id: item.id })
                          }
                        />
                      ) : (
                        <CustomInput
                          type="checkbox"
                          id={`correctAnswer-${index}`}
                          checked={item.isCorrectAnswer || item.checked}
                          onChange={(e) =>
                            onCheckCorrectAnswer({ e, id: item.id })
                          }
                        />
                      )}
                    </Col>
                  )} */}

                {isDropZoneDragDrop && (
                  <Col md={2}>
                    <CustomSelected
                      options={dropZonesOptions}
                      onChange={(e) =>
                        onChangeAnswer({
                          option: "matchingText",
                          data: e.value,
                          id: item.id,
                        })
                      }
                      value={
                        _.get(item, "matchingText")
                          ? dropZonesOptions.find(
                              (option) =>
                                option.value === _.get(item, "matchingText")
                            )
                          : null
                      }
                    />
                  </Col>
                )}

                {isBlankDropDown && (
                  <Col md={2}>
                    <CustomSelected
                      options={_.get(item, "value")}
                      onChange={(e) =>
                        onSelectCorrectAnswerBlankDropDown(index, e.value)
                      }
                      value={
                        _.get(item, "value")
                          ? _.get(item, "value").find(
                              (val) => val.isCorrectAnswer || val.isChecked
                            )
                          : null
                      }
                    />
                  </Col>
                )}

                {answerArr.length > minAnswer &&
                  !isOnlyOneAnswer &&
                  !isMatrix &&
                  !isBlankText &&
                  !isHotText &&
                  !isHotspot && (
                    <Col md={1} className="text-center">
                      <button className="btn btn-danger px-2" onClick={onRemoveAnswer(item.id)} >
                        <i 
                          className={props.isUndo ? "fa fa-undo" : "fa fa-trash"} 
                          style={{ fontSize: 16, cursor: 'pointer' }} 
                        />
                      </button>
                      {/* <DeleteBtn onClick={onRemoveAnswer(item.id)} /> */}
                    </Col>
                  )}
              </Row>
              <hr className="my-2" />
            </React.Fragment>
          )
        })}
    </div>
  )
}

export default AnswerList
