import React, { memo } from 'react'
import { Button } from 'reactstrap'

export default memo(function LinkEditBtn(props) {
    return (
        <Button
            color="link"
            onClick={props.onClick}
            {...props}
        >
            {props.children}
            <p style={{ marginBottom: "0.2rem" }}>{props.label || ''} {props.noIcon ? "" : <span className="fa fa-pencil-square-o ml-1" />}</p>
        </Button>
    )
})
