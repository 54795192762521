import React, { useRef } from 'react';
import { useLocation } from "react-router-dom";
import { AppSidebarMinimizer } from "@coreui/react";
import $ from "jquery";


function AutoCloseSideBarMinimizer() {
	let location = useLocation();
	const minifier = useRef(null);
	React.useEffect(() => {
		if ($('div.sidebar-nav').hasClass('ps-container')) {
			minifier.current.handleClick();
		}
	}, [location]);
	return (
		<AppSidebarMinimizer ref={minifier} />
	);
}
export default AutoCloseSideBarMinimizer;