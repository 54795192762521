import { useState } from "react";

const useMutation = ({ mutationFn, onSuccess, onError }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const mutate = async (...rest) => {
        setIsLoading(true);

        try {
            await mutationFn.call(null, ...rest);

            if (onSuccess) {
                onSuccess();
            }
        } catch (err) {
            setError(err);

            if (onError) {
                onError(err);
            }
        }

        setIsLoading(false);
    };

    return {
        isLoading,
        error,
        mutate
    };
};

export default useMutation;
