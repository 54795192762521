import {
    getAllTestResult,
    getTestResult
} from "../../../util/api"
import setLoading from "../../../components/Spinner/action"
import setModal from "../../../components/Modal/action"

export const getAllResult = ({ currentPage = 1, pageSize = 100, searchString, status, studentIds, testSuiteIds, testPackIds, startTime, endTime }) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await getAllTestResult({ currentPage, pageSize, searchString, status, studentIds, testSuiteIds, testPackIds, startTime, endTime })

        if (!res.data) throw new Error("Error in getting test result")

        if (Array.isArray(res.data)) throw new Error(res.data.map(item => item.value))

        dispatch({
            type: "SET_TEST_RESULT",
            payload: res.data.data,
            // currentPage: res.data.currentPage,
            totalItems: res.data.totalItems,
            pageCount: res.data.pageCount,
        })
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: JSON.stringify(err.response.data)
        }))
    }

    dispatch(setLoading(false))
}

export const changePageSizeTestResult = pageSize => async dispatch => {
    dispatch({
        type: "CHANGE_PAGE_SIZE_TEST_RESULT",
        payload: pageSize
    })
}

export const changeCurrentPageTestResult = page => async dispatch => {
    dispatch({
        type: "CHANGE_CURRENT_PAGE_TEST_RESULT",
        payload: page
    })
}

export const getResult = id => async dispatch => {
    dispatch(setLoading(true))
    let res = null

    try {
        res = await getTestResult(id)
        if (!res.data) throw new Error("Error in creating test result")

        if (Array.isArray(res.data)) throw new Error(res.data.map(item => item.value))
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: JSON.stringify(err.message)
        }))
    }

    dispatch(setLoading(false))
    return res
}