import { combineReducers } from "redux"

import loading from "./components/Spinner/reducer"
import alert from "./components/Alert/reducer"
import modal from "./components/Modal/reducer"
import inputModal from "./components/ModalInput/reducer"
import report from "./containers/Report/redux/reducer"
import common from "./containers/Common/reducer"

import questionCategory from "./containers/QuestionCategory/redux/reducer"
import question from "./containers/Question/redux/reducer"
import assessment from "./containers/Assessment/redux/reducer"
import assessmentResponse from "./containers/AssessmentResponse/redux/reducer"
import subject from "./containers/Subject/redux/reducer"

import testPack from "./containers/TestPack/redux/reducer"
import testSuite from "./containers/TestSuite/redux/reducer"
import testResult from "./containers/TestResult/redux/reducer"
import testReport from "./containers/TestReports/redux/reducer"

import subscription from "./containers/Subscription/redux/reducer"
import testSuitePayment from "./containers/TestSuitePayment/redux/reducer"
import article from "./containers/Article/redux/reducer"
import confirm from "./components/ConfirmDialog/reducer"

const root = {
    loading,
    alert,
    report,
    modal,
    inputModal,
    common,
    questionCategory,
    question,
    assessment,
    assessmentResponse,
    subject,
    testPack,
    testSuite,
    testResult,
    testReport,
    subscription,
    testSuitePayment,
    article,
    confirm
}

export default combineReducers(root)
