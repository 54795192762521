import React from "react"
import { withTranslation } from 'react-i18next'

import CustomTabs from "../../components/CustomTabs"
import TestPack from "../TestPack/view"
import TestSuite from "../TestSuite/view"
import TestResult from '../TestResult/view'

const TestManagement = props => {
    const { t } = props

    const tabs = [
        { path: "/test-management/test-pack", title: t("test_pack"), component: TestPack },
        { path: "/test-management/test-suite", title: t("test_suite"), component: TestSuite },
        { path: "/test-management/test-result", title: t("test_result"), component: TestResult }
    ]

    return <CustomTabs
        {...props}
        title={t("test_management_upper")}
        tabs={tabs}
    />
}

export default withTranslation("common")(TestManagement)

