import _ from "lodash";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Select from "react-select";
import {
    Alert,
    Button,
    Col,
    CustomInput,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";
import ArticleSelector from "../../components/Selector/ArticleSelector";
import {
    approveQuestion,
    getQuestionReviewProofreader,
    updateProofreaderQuestion
} from "../../util/api";
import AnswerList from "../Question/view/AnswerList";
import setLoading from "../../components/Spinner/action";
import ProwritingEditor, { TINY_MCE_API_KEY } from "../MyTask/ProwritingEditor";
import CategorySelector from "../../components/CategorySelector";
import useConfirm from "../../hooks/useConfirm";
import useMutation from "../../hooks/useMutation";
import useAlert from "../../hooks/useAlert";
import Spinner from "../../components/Spinner";
import { Editor } from "@tinymce/tinymce-react";

const questionTypes = [
    { label: "Single Choice", value: 0 },
    { label: "Multiple Choice", value: 1 }
];

const QuestionReview = ({ t }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id: questionIdParam } = useParams();
    const allArticles = useSelector(state => state.article.allArticles);
    const currentProofreader = useSelector(state => state.common.userInfo);

    const [question, setQuestion] = useState(null);
    const [isChecking, setIsChecking] = useState(true);
    const [alert, setAlert] = useState("");
    const [msg, setMsg] = useState(null); // { type: primary | danger, value: string }
    const questionText = useRef();
    const instruction = useRef();
    const solution = useRef();
    const [noSetInitValue, setNoSetInitValue] = useState(false);
    const currentEdittingEditorIdRef = useRef();
    const { showConfirm } = useConfirm();
    const { showSuccess, showError } = useAlert();
    const { mutate: handleApprove, isLoading: isApproving } = useMutation({
        mutationFn: approveQuestion,
        onSuccess: () => {
            showSuccess("Successfully!");
            history.push("/question-bank/question-review");
        },
        onError: err => {
            showError(err.response.data.Message);
        }
    });
    const [error, setError] = useState(null);
    const [errors, setErrors] = useState({});
    const [enableShowError, setEnableShowError] = useState(false);
    const [isShowInstruction, setIsShowInstruction] = useState(false);
    const [isShowQuestionPurpose, setIsShowQuestionPurpose] = useState(true)
    const questionProposeRef = useRef(null);
    const questionSyllabus = useRef(null);
    const [isShowArticle ,setIsShowArticle] = useState(false);


    useEffect(() => {
        getQuestion();
    }, []);

    useEffect(() => {
        if (question && question.instruction){
            setIsShowInstruction(true)
        }
        if (question && question.purpose === ""){
            setIsShowQuestionPurpose(false)
        }
        if (question && question.articleId){
            setIsShowArticle(true)
        }
    }, [question])

    const getQuestion = async () => {
        dispatch(setLoading(true));

        try {
            const res = await getQuestionReviewProofreader(questionIdParam);

            if (res.data) {
                const { data } = res;
                setQuestion({
                    ...data,
                    answer: JSON.parse(data.answer)
                });

                setNoSetInitValue(true);
            } else {
                setAlert("No question found!");
            }
        } catch (error) {
            setError(error.response.data.Message);
        }

        setIsChecking(false);
        dispatch(setLoading(false));
    };

    const isDuplicateAnswer = answer => {
        const answerTexts = {};
        let isDuplicate = false;
        answer.forEach(ans => {
            if (!answerTexts[ans.text]) {
                answerTexts[ans.text] = ans.text;
            } else {
                isDuplicate = true;
            }
        });

        if (isDuplicate) {
            window.alert("Duplicate answer!");
        }
        return isDuplicate;
    };

    const validate = () => {
        let isValid = true;
        const error = {};

        [
            "categoryId",
            "difficultyLevel",
            "type",
            // "point"
            // "questionOrder"
        ].forEach(field => {
            if (
                question[field] === null ||
                question[field] === undefined ||
                question[field] === ""
            ) {
                isValid = false;
                error[field] = true;
            } else {
                error[field] = false;
            }
        });

        if (questionText.current.getContent() === "") {
            isValid = false;
            error.questionText = true;
        }

        if (solution.current.getContent() === "") {
            isValid = false;
            error.solution = true;
        }

        if (
            !question.answer ||
            !question.answer.find(e => e.checked === true)
        ) {
            isValid = false;
            error.questionAnswers = true;
        }

        if (Object.keys(error).length > 0) {
            let el = null;
            [
                "categoryId",
                "difficultyLevel",
                "questionText",
                "type",
                "questionAnswers",
                "solution",
                // "point"
            ].forEach(field => {
                if (error[field] && !el) {
                    el = document.getElementById(field);
                }
            });
            if (el) {
                window.scroll({
                    top:
                        el.getBoundingClientRect().bottom +
                        el.getBoundingClientRect().height,
                    left: 0,
                    behavior: "smooth"
                });
            }
        }

        // if (instruction.current.getContent() === "") {
        //     isValid = false;
        //     error.instruction = true;
        // }

        setErrors(error);
        return isValid;
    };

    const handleSubmit = async () => {
        if (!enableShowError) {
            setEnableShowError(true);
        }
        if (!validate()) return;
        if (isDuplicateAnswer(question.answer)) return;
        dispatch(setLoading(true));
        try {
            const res = await updateProofreaderQuestion({
                ...question,
                questionId: question.questionId,
                proofreaderId: currentProofreader.identity.id,
                currentVersion: question.version,
                answer: JSON.stringify(
                    question.answer.map(item => {
                        return {
                            ...item,
                            text: item.text.replaceAll("text-decoration: underline wavy red;", ""),
                            isCorrectAnswer: undefined
                        };
                    })
                ),
                questionText: questionText.current
                    .getContent()
                    .replaceAll("text-decoration: underline wavy red;", ""),
                instruction: instruction.current ? instruction.current
                    .getContent()
                    .replaceAll("text-decoration: underline wavy red;", "") : "",
                solution: solution.current
                    .getContent()
                    .replaceAll("text-decoration: underline wavy red;", ""),
                articleId: question.articleId || 0,
                questionOrder: question.questionOrder || 0,
                point: question.point || 0,
                syllabus: question.syllabus || ""
            });

            if (res.data) {
                setMsg({
                    type: "primary",
                    value: "Successfully"
                });
                getQuestion();
            }
        } catch (error) {
            setMsg({
                type: "danger",
                value: error && error.response && error.response.data ? error.response.data.Message : ""
            });
        }
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        dispatch(setLoading(false));
    };

    const handleChange = field => e => {
        setQuestion(preState => ({
            ...preState,
            [field]: e ? e.value : null
        }));

        if (enableShowError) {
            setErrors(pre => {
                return {
                    ...pre,
                    [field]: (e || e.value) === ""
                };
            });
        }
    };

    const handleChangeAnswer = ({ data, id, option }) => {
        handleChange("answer")({
            value: question.answer.map(item => {
                if (item.id !== id) return item;

                switch (option) {
                    case "text":
                        return { ...item, text: data };
                    case "mark":
                        return { ...item, mark: Number(data) };
                    case "matchingText":
                        return { ...item, matchingText: data };
                    case "token":
                        return { ...item, token: data };
                    default:
                        return item;
                }
            })
        });
    };

    const handleCheckCorrectAnswer = ({ e, id }) => {
        if (+question.type === 1) {
            handleChange("answer")({
                value: question.answer.map(item => {
                    if (item.id !== id) return item;

                    return {
                        ...item,
                        // isCorrectAnswer: e.target.checked
                        checked: e.target.checked
                    };
                })
            });
        } else {
            if (!e.target.checked) return;
            setErrors(pre => {
                return {
                    ...pre,
                    questionAnswers: false
                };
            });

            handleChange("answer")({
                value: question.answer.map(item => {
                    if (item.id !== id) return { ...item, checked: false };

                    return {
                        ...item,
                        // isCorrectAnswer: true
                        checked: e.target.checked
                    };
                })
            });
        }
    };

    const getNextId = arr => {
        try {
            if (!arr || !arr.length) return 1;
            const maxIdItem = _.maxBy(arr, "id");
            if (maxIdItem) return maxIdItem.id + 1;
            return 1;
        } catch {
            return 1;
        }
    };

    const handleAddNewAnswer = () => {
        const genAlphabet = {
            2: "C. ",
            3: "D. ",
            4: "E. ",
            5: "F. ",
            6: "G. ",
            7: "H. ",
            8: "I. ",
            9: "J. "
        };

        handleChange("answer")({
            value: [
                ...question.answer,
                {
                    id: getNextId(question.answer),
                    text: genAlphabet[question.answer.length],
                    isCorrectAnswer: false,
                    mark: 0,
                    correctAnswer: 0,
                    matchingText: ""
                }
            ]
        });
    };

    const handleRemoveAnswer = id => () => {
        if (question.answer.length === 2) return;

        handleChange("answer")({
            value: question.answer.filter(item => item.id !== id)
        });
    };

    const currentArticle =
        allArticles &&
        question &&
        allArticles.find(item => +question.articleId === +item.id);

    if (isChecking || alert) {
        return (
            <div className="mt-2 p-2">
                <div className="mb-2">
                    <Link
                        style={{
                            fontSize: "1.2rem"
                        }}
                        to="/question-bank/question-review"
                    >
                        <i
                            className="fa fa-long-arrow-left mr-2"
                            aria-hidden="true"
                        ></i>
                        My questions review
                    </Link>
                </div>

                {alert && <Alert color="warning">{alert}</Alert>}
            </div>
        );
    }

    const categories = question ? question.task.categories.split(",") : [];
    const categoryIds = question ? question.task.categoryIds.split(",") : [];

    const categoryOptions = categories.map((c, index) => {
        return {
            label: c,
            value: categoryIds[index]
        };
    });

    if (isChecking) {
        return (
            <div className="p-2">
                <span>Loading...</span>
            </div>
        );
    }

    if (error) {
        return (
            <div className="p-2">
                <Alert color="danger">{error}</Alert>
            </div>
        );
    }

    return (
        <div className="mt-2 p-2">
            {isApproving && <Spinner isLoading />}

            <div className="mb-2">
                <Link
                    style={{
                        fontSize: "1.2rem"
                    }}
                    to="/question-bank/question-review"
                >
                    <i
                        className="fa fa-long-arrow-left mr-2"
                        aria-hidden="true"
                    ></i>
                    My questions review
                </Link>
            </div>

            {msg && <Alert color={msg.type}>{msg.value}</Alert>}

            <Col id="categoryId" md={9} className="mb-2">
                <FormGroup className="d-flex align-items-center">
                    <Label>Question purpose</Label>
                    <Input type="checkbox" className="ml-2 mt-0 position-relative" checked={isShowQuestionPurpose} onChange={(e) => setIsShowQuestionPurpose(e.target.checked)}/>  
                </FormGroup>
                {isShowQuestionPurpose && <Editor
                    // disabled={isViewDetail}
                    onInit={(evt, editor) => {
                        questionProposeRef.current = editor
                    }}
                    apiKey={TINY_MCE_API_KEY}
                    onEditorChange={(content, editor) => {handleChange("purpose")({
                        value: content
                    }) }}
                    value={question ? question.purpose : ""}
                    init={{
                        height: 400,
                        menubar: false,
                        branding: false,
                        extended_valid_elements: "*[*]",
                        toolbar:
                            "undo redo | formatselect | image media | " +
                            "bold italic backcolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent" +
                            "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table",
                        plugins:
                            "advlist autolink lists link image media charmap preview anchor " +
                            "searchreplace visualblocks code fullscreen " +
                            "insertdatetime media table code help wordcount",
                        content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        file_picker_types: "image",
                        image_uploadtab: true
                    }}
                />}
            </Col>

            <Col id="categoryId" md={9}>
                <FormGroup>
                    <Label>
                        Question category<span className="color-danger">*</span>
                    </Label>

                    <CategorySelector
                        isDisabled
                        value={categoryOptions.find(c => {
                            return +c.value === +question.categoryId;
                        })}
                        placeholder="Select category"
                        options={categoryOptions}
                        onChange={handleChange("categoryId")}
                    />

                    {errors.categoryId && (
                        <span className="color-danger">
                            Question category is required!
                        </span>
                    )}
                </FormGroup>
            </Col>

            <Col id="difficultyLevel" md={12} className="mb-2">
                <Label>
                    Difficulty level<span className="color-danger">*</span>
                </Label>
                <p
                    className="mb-2 font-weight-bold"
                    style={{ color: "#a94442", fontSize: "0.9em" }}
                >
                    (The Difficulty Level provides an indication of the
                    difficulty level of each test question)
                </p>
                <div className="d-flex align-items-center">
                    <CustomInput
                        className="mr-3"
                        type="radio"
                        id="difficulty-1"
                        onClick={() =>
                            handleChange("difficultyLevel")({
                                value: 1
                            })
                        }
                        checked={question.difficultyLevel === 1}
                        label="Level 1"
                    />

                    <CustomInput
                        className="mr-3"
                        type="radio"
                        id="difficulty-2"
                        onClick={() =>
                            handleChange("difficultyLevel")({
                                value: 2
                            })
                        }
                        checked={question.difficultyLevel === 2}
                        label="Level 2"
                    />

                    <CustomInput
                        className="mr-3"
                        type="radio"
                        id="difficulty-3"
                        onClick={() =>
                            handleChange("difficultyLevel")({
                                value: 3
                            })
                        }
                        checked={question.difficultyLevel === 3}
                        label="Level 3"
                    />

                    <CustomInput
                        type="radio"
                        id="difficulty-4"
                        onClick={() =>
                            handleChange("difficultyLevel")({
                                value: 4
                            })
                        }
                        checked={question.difficultyLevel === 4}
                        label="Level 4"
                    />
                </div>

                {errors.difficultyLevel && (
                    <span className="color-danger">
                        Difficulty level is required!
                    </span>
                )}
            </Col>

            <Col md={9} className="mb-2">
                <FormGroup className="position-relative">
                    <Label>Article</Label>
                    <Input type="checkbox" className="position-absolute mt-0" style={{top: "2px", left: "64px" }} checked={isShowArticle} onChange={(e) => setIsShowArticle(e.target.checked)}/>   
                </FormGroup>
        
                {isShowArticle && <ArticleSelector
                    // isDisabled
                    noLabel
                    onChange={handleChange("articleId")}
                    value={
                        question && currentArticle
                            ? {
                                    ...currentArticle,
                                    label: currentArticle.name,
                                    value: currentArticle.id
                                }
                            : null
                    }
                    isClearable
                />}
        
            </Col>


            {isShowArticle && (
                <Col md={9} className="mb-2">
                    <Label>Question order</Label>

                    <Input
                        type="number"
                        value={question.questionOrder || ""}
                        onChange={e => {
                            handleChange("questionOrder")({
                                value: e.target.value
                            });
                        }}
                    />
                </Col>
            )}
        
            <Col id="instruction" md={12} className="mb-2">
                <FormGroup className="position-relative">
                    <Label>Instruction</Label>
                    <Input type="checkbox" className="position-absolute mt-0" style={{top: "2px", left: "88px" }} checked={isShowInstruction} onChange={(e) => setIsShowInstruction(e.target.checked)}/>  
                </FormGroup>
                {isShowInstruction && <ProwritingEditor
                    // invalid={errors.instruction}
                    initValue={question.instruction}
                    innerRef={instruction}
                    currentEdittingEditorIdRef={currentEdittingEditorIdRef}
                    isClickPublish={false}
                    onChange={value => {
                        if (enableShowError) {
                            setErrors(pre => {
                                return {
                                    ...pre,
                                    instruction: value === ""
                                };
                            });
                        }
                    }}
                />}

                {/* {errors.instruction && (
                    <span className="color-danger">
                        Instruction is required!
                    </span>
                )} */}
            </Col>

            <Col id="questionText" xs={12} className="mb-2">
                <FormGroup>
                    <Label>
                        Question text<span className="color-danger">*</span>
                    </Label>

                    <ProwritingEditor
                        invalid={errors.questionText}
                        innerRef={questionText}
                        currentEdittingEditorIdRef={currentEdittingEditorIdRef}
                        isClickPublish={false}
                        initValue={question.questionText}
                        onChange={value => {
                            if (enableShowError) {
                                setErrors(pre => {
                                    return {
                                        ...pre,
                                        questionText: value === ""
                                    };
                                });
                            }
                        }}
                    />

                    {errors.questionText && (
                        <span className="color-danger">
                            Question text is required!
                        </span>
                    )}
                </FormGroup>
            </Col>

            <Row className="mb-2">
                <Col id="type" md={9} className="mb-2">
                    <FormGroup>
                        <Label>
                            Question type<span className="color-danger">*</span>
                        </Label>

                        <Select
                            className={classNames({
                                "invalid-field": errors.type
                            })}
                            value={
                                question.type === 0
                                    ? {
                                          label: "Single Choice",
                                          value: 0
                                      }
                                    : {
                                          label: "Multiple Choice",
                                          value: 1
                                      }
                            }
                            placeholder="Select type"
                            options={questionTypes}
                            onChange={e => {
                                handleChange("type")(e);

                                handleChange("answer")({
                                    value: question.answer.map(ans => {
                                        return {
                                            ...ans,
                                            checked: false
                                        };
                                    })
                                });

                                // dispatch(setLoading(true));
                            }}
                        />

                        {errors.type && (
                            <span className="color-danger">
                                Question type is required!
                            </span>
                        )}
                    </FormGroup>
                </Col>

                <Col md={9} className="mb-2">
                    <Label>Number of answers: {question ? question.task.numberOfAnswears : ""}</Label>
                </Col>

                <Col id="answer-list" md={12} className="mb-2">
                    <div id="questionAnswers">
                        <AnswerList
                            t={t}
                            answerArr={question.answer || []}
                            noSetInitValue={noSetInitValue}
                            isMulti={+question.type === 1}
                            isSingleChoice={+question.type === 0}
                            onAddNewAnswer={handleAddNewAnswer}
                            onChangeAnswer={handleChangeAnswer}
                            onCheckCorrectAnswer={handleCheckCorrectAnswer}
                            onRemoveAnswer={handleRemoveAnswer}
                            currentEdittingEditorIdRef={
                                currentEdittingEditorIdRef
                            }
                            isClickPublish={false}
                        />
                        {errors.questionAnswers && (
                            <span className="color-danger">
                                The question must have the correct answer!
                            </span>
                        )}
                    </div>
                </Col>

                <Col id="solution" md={12} className="mb-2">
                    <Label>
                        Solution<span className="color-danger">*</span>
                    </Label>

                    <ProwritingEditor
                        invalid={errors.solution}
                        isClickPublish={false}
                        initValue={question.solution}
                        innerRef={solution}
                        currentEdittingEditorIdRef={currentEdittingEditorIdRef}
                        onChange={value => {
                            if (enableShowError) {
                                setErrors(pre => {
                                    return {
                                        ...pre,
                                        solution: value === ""
                                    };
                                });
                            }
                        }}
                    />

                    {errors.solution && (
                        <span className="color-danger">
                            Solution is required!
                        </span>
                    )}
                </Col>

                {/* <Col id="point" md={9} className="mb-2">
                    <Label>
                        Point<span className="color-danger">*</span>
                    </Label>

                    <Input
                        className={classNames({
                            "is-invalid": errors.point
                        })}
                        type="number"
                        value={question.point || ""}
                        onChange={e => {
                            handleChange("point")({
                                value: e.target.value
                            });
                        }}
                    />

                    {errors.point && (
                        <span className="color-danger">Point is required!</span>
                    )}
                </Col> */}

                <Col md={9} className="mb-2">
                    <FormGroup className="d-flex align-items-center">
                        <Label>Syllabus</Label>
                    </FormGroup>
                    <Editor
                        // disabled={isViewDetail}
                        onInit={(evt, editor) => {
                            questionSyllabus.current = editor
                        }}
                        apiKey={TINY_MCE_API_KEY}
                        onEditorChange={(content, editor) => {handleChange("syllabus")({
                            value: content
                        }) }}
                        value={question ? question.syllabus : ""}
                        init={{
                            height: 400,
                            menubar: false,
                            branding: false,
                            extended_valid_elements: "*[*]",
                            toolbar:
                                "undo redo | formatselect | image media | " +
                                "bold italic backcolor | alignleft aligncenter " +
                                "alignright alignjustify | bullist numlist outdent indent" +
                                "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table",
                            plugins:
                                "advlist autolink lists link image media charmap preview anchor " +
                                "searchreplace visualblocks code fullscreen " +
                                "insertdatetime media table code help wordcount",
                            content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            file_picker_types: "image",
                            image_uploadtab: true
                        }}
                    />
                </Col>

                <Col xs={12}>
                    <Button
                        style={{ width: "110px" }}
                        // disabled={!editable}
                        color="primary"
                        className="mr-2"
                        onClick={handleSubmit}
                    >
                        Save question
                    </Button>

                    <Button
                        style={{ width: "110px" }}
                        color="primary"
                        className="mr-2"
                        onClick={() => {
                            window.open(
                                `/preview-question/${question.questionId}?action=preview`,
                                "_blank"
                            );
                        }}
                    >
                        Preview
                    </Button>

                    <Button
                        style={{ width: "110px" }}
                        color="success"
                        className="mr-2"
                        onClick={() => {
                            showConfirm({
                                message:
                                    "Are you sure you approve this question?",
                                onConfirm: async () => {
                                    if (!enableShowError) {
                                        setEnableShowError(true);
                                    }
                                    if (!validate()) return;
                                    if (isDuplicateAnswer(question.answer))
                                        return;
                                    await handleSubmit();
                                    handleApprove(question.questionId, true);
                                }
                            });
                        }}
                    >
                        Approve
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default withTranslation("common")(QuestionReview);
