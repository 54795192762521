import React, { useEffect, useState } from "react"
import { Col, Row, Table, Alert, Button, ModalFooter, FormGroup, Label, Modal, ModalBody, ModalHeader, Input, FormFeedback } from "reactstrap";
import { connect } from "react-redux"
import { withTranslation } from 'react-i18next'

import CustomPagination from "../../../components/CustomPagination"
import SearchBox from "../../../components/SearchBox"
import CreateBtn from "../../../components/Button/CreateBtn"
import DeleteBtn from "../../../components/Button/DeleteBtn"
import LinkEditBtn from "../../../components/Button/LinkEditBtn";

import setModal from "../../../components/Modal/action"

import { create, update, getAll, remove, changePageSizeSubject } from "../redux/action"

const Subject = props => {
    const { subjects, currentPage, pageSize, totalItems, pageCount, t } = props

    const sortBy = "Name"
    const [sortOrder, setSortOrder] = useState("ASC")

    const [currentSubject, setCurrentSubject] = useState({})
    const [checkName, setCheckName] = useState(false)

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        if (isOpen) {
            setCurrentSubject({})
            setCheckName(false)
        }

        setIsOpen(!isOpen)
    }

    const getData = page => {
        props.getAll({
            currentPage: page || currentPage,
            pageSize,
            pageCount,
            sortBy,
            sortOrder
        })
    }

    useEffect(() => {
        getData(1)
    }, [sortOrder])

    const onSort = () => {
        if (sortOrder === "ASC") {
            setSortOrder("DESC")
            return
        }

        setSortOrder("ASC")
    }

    const onDelete = id => {
        props.setModal({
            type: "danger",
            message: "delete_subject_confirm",
            cb: () => props.remove(id, () => getData(1))
        })
    }

    const handleChange = async () => {
        if (!currentSubject || !currentSubject.name || !currentSubject.name.trim())
            return

        const func = currentSubject && currentSubject.id
            ? props.update
            : props.create

        await func(currentSubject, () => {
            toggle()
            getData(1)
        })
    }

    const renderModal = () => {
        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    {" "}
                    {currentSubject && currentSubject.id ? t('edit_subject') : t('create_subject')}
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label >
                            {t('name')} <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                            type="text"
                            onChange={e => setCurrentSubject({ ...currentSubject, name: e.target.value })}
                            placeholder={t('name')}
                            value={currentSubject && currentSubject.name}
                            invalid={checkName && (!currentSubject || !currentSubject.name || !currentSubject.name.trim())}
                        />
                        {checkName && (!currentSubject || !currentSubject.name || !currentSubject.name.trim())
                            && <FormFeedback>{t("name_empty")}</FormFeedback>}
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {
                        setCheckName(true)
                        handleChange()
                    }}>
                        {currentSubject && currentSubject.id ? t('update') : t('create')}
                    </Button>{" "}
                    <Button color="secondary" onClick={toggle}>
                        {t('cancel')}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    return <Row className="mb-2">
        {renderModal()}
        <Col md={6} className="d-flex align-items-center mb-2">
            <CreateBtn title={t('create_subject')} onClick={() => setIsOpen(true)} />
            <div className="text-right link-adv flex-grow-1">{t('total_of_subject')}: <span className="font-weight-bold">{totalItems || 0}</span></div>
        </Col>
        <Col md={12} />
        <Col md={6} className="mb-2">
            {subjects && subjects.length
                ? <Table hover bordered striped responsive className="grid-table col-md-12 mb-2">
                    <thead>
                        <tr>
                            <th
                                scope="col"
                                style={{ cursor: "pointer" }}
                                onClick={onSort}
                            >
                                {props.t('subject_name')}
                                <i className="fa fa-sort float-right" />
                            </th>
                            <th scope="col" width="48"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {subjects &&
                            subjects.map((rp, index) => (
                                <tr key={`${index}`}>
                                    <td>
                                        <LinkEditBtn
                                            onClick={() => {
                                                setCurrentSubject(rp)
                                                toggle()
                                            }}
                                            title={rp.name}
                                            label={rp.name}
                                        />
                                    </td>
                                    <td>
                                        <div className="text-center justify-content-center d-flex">
                                            <DeleteBtn onClick={() => {
                                                onDelete(rp.id);
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
                : <Alert color="primary">
                    {t("no_subject_to_display")}</Alert>}
            <CustomPagination
                pageCount={pageCount}
                pageSize={pageSize}
                currentPage={currentPage}
                pageSizeName="subjectPageSize"
                changePage={page => {
                    getData(page);
                }}
                changePageSize={props.changePageSizeSubject}
                onChangePageSize={() => getData(1)}
            />
        </Col>
    </Row>
}

const SubjectHOC = withTranslation("common")(Subject)

const mapStateToProps = state => ({
    subjects: state.subject.subjects,
    currentPage: state.subject.currentPage,
    pageCount: state.subject.pageCount,
    totalItems: state.subject.totalItems,
    pageSize: state.subject.pageSize
})

const mapDispatchToProps = {
    create,
    update,
    getAll,
    remove,
    changePageSizeSubject,
    setModal
}

export default connect(mapStateToProps, mapDispatchToProps)(SubjectHOC)