import moment from "moment";
import _ from "lodash"

const gender = ["Unknown", "Male", "Female"];

const userSingleFormat = data => {
  if (data) {
    return {
      value: data.id,
      id: data.id,
      userId: data.id,
      label: `${data.firstName} ${data.lastName}`,
      phoneNumber: data.phoneNumber,
      workOfficePhone: data.workOfficePhone,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      gender: data.profile ? data.profile.gender : 0,
      isActive: data.isActive
    };
  }
  return null;
};

const basicFormat = data => {
  if (data && data.length > 0) {
    const arr = data.map(i => ({
      value: i.id,
      label: i.name,
    }));
    return arr;
  }
  return [];
};


export const formatToLocalTime = (time) => moment(`${time}Z`).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
export const formatToLocalTimeWithTime = (time) => moment(`${time}Z`)


export default {
  studentsFormat: data => {
    if (data && data.length > 0) {
      const arr = data.map(i => ({
        value: i.student.id,
        label: `${i.student.identity.firstName} ${i.student.identity.lastName}`,
        phoneNumber: i.student.identity.phoneNumber,
        firstName: i.student.identity.firstName,
        lastName: i.student.identity.lastName,
        email: i.student.identity.email,
        userId: i.student.identity.id,
        discount: i.discount,
        isPaid: i.isPaid,
        programId: i.programId,
        gender: i.student.identity.profile && i.student.identity.profile.gender && gender[i.student.identity.profile.gender] || gender[0],
        age: i.student.identity.profile && moment().diff(i.student.identity.profile.birthday, 'years'),
        programDiscounts: i.programDiscounts,
        familyId: i.familyId,
        familyName: i.familyName
      }));
      return arr;
    }
    return [];
  },
  studentsInCourseFormat: data => {
    if (data && data.length > 0) {
      const arr = data.map(i => ({
        value: i.studentId,
        label: `${i.firstName} ${i.lastName}`,
        phoneNumber: i.phoneNumber,
        firstName: i.firstName,
        lastName: i.lastName,
        email: i.email,
        userId: i.userId,
        discount: i.discount,
        isPaid: i.isPaid,
        // programId: i.programId,
        gender: i.gender && gender[i.gender] || gender[0],
        age: i.birthday && i.birthday !== "0001-01-01T00:00:00" && moment().diff(i.birthday, 'years'),
        programDiscount: i.programDiscount,
        familyId: i.familyId,
        familyName: i.familyName,
        programId: i.programId,
        isSent: i.isSent
      }));
      return arr;
    }
    return [];
  },
  userFormat: data => {
    if (data && data.length > 0) {
      const arr = data.map(i => ({
        value: i.id,
        label: `${i.firstName} ${i.lastName}`,
        userId: i.userId,
        phoneNumber: i.phoneNumber,
        firstName: i.firstName,
        lastName: i.lastName,
        email: i.email,
        gender: i.gender,
        isActive: i.isActive,
        teacherSkills: i.teacherSkills || null
      }));
      return arr;
    }
    return [];
  },
  userListPageFormat: data => {
    if (data && data.length > 0) {
      const arr = data.map(i => ({
        id: i.identity.id,
        name: `${i.identity.firstName || ""} ${i.identity.lastName || ""}`,
        phoneNumber: i.identity.phoneNumber,
        email: i.identity.email,
        createdAt: i.identity.createdAt || '',
        roles: i.roles,
        employed: i.employed,
        courses: i.courses && i.courses.map(m => m.course),
        programs: i.programs && i.programs.map(m => m.program),
        note: i.note,
        isActive: i.isActive,
        family: i.family,
        gender: i.gender && gender[i.gender] || gender[0],
        birthday: i.birthday,
        teacherSkills: i.teacherSkills,
        sentInvoiceAt: i.sentInvoiceAt
      }));
      return arr;
    }
    return [];
  },
  userSingleFormat,
  identityFormat: data => {
    if (data) {
      return {
        value: data.identity.id,
        userId: data.identity.id,
        label: `${data.identity.firstName} ${data.identity.lastName}`,
      };
    }
    return null;
  },
  teacherSkill: data => {
    if (data && data.id) {
      if (data && data.id) {
        return {
          value: data.id,
          userId: data.identity.id,
          label: `${data.identity.firstName} ${data.identity.lastName}`,
        };
      }
      return {};
    }
  },
  basicUsersFormat: data => {
    if (data && data.length > 0) {
      const arr = data.map(i => ({
        value: i.id,
        label: `${i.firstName} ${i.lastName}`,
      }));
      return arr;
    }
    return [];
  },
  basicUserFormat: data => {
    if (data && data.id) {
      return {
        value: data.id,
        userId: data.id,
        label: `${data.firstName} ${data.lastName}`,
      };
    }
  },
  singleBasicFormat: data => {
    if (data && data.id) {
      return {
        value: data.id,
        label: data.name,
      };
    }
    return null;
  },

  basicFormat,
  withStudentFormat: data => {
    if (data && data.length > 0) {
      const arr = data.map(i => ({
        value: i.id,
        label: i.name,
        studentEnrollments: i.studentEnrollments && i.studentEnrollments.filter(f => !f.isDeleted)
      }));
      return arr;
    }
    return [];
  },
  familyFormat: data => {
    if (data && data.length > 0) {
      const arr = data.map(i => ({
        value: i.id,
        label: i.name,
        firstParent: i.firstParent,
        secondParent: i.secondParent,
        paymentParentId: i.paymentParentId
      }));
      return arr;
    }
    return [];
  },
  singleFamilyFormat: data => {
    if (data) {
      return {
        id: data.id,
        value: data.id,
        label: data.name,
        firstParent: userSingleFormat(data.firstParent),
        secondParent: userSingleFormat(data.secondParent),
        paymentParentId: data.payingParent.id
      };
    }
    return null;
  },
  courseFullInfoFormat: data => {
    if (data && data.length > 0) {
      const arr = data.map(i => ({
        ...i,
        value: i.id,
        label: i.name,
      }));
      return arr;
    }
    return [];
  },
  studentEnrollmentFormat: data => {
    if (data && data.length > 0) {
      const arr = data.map(i => ({
        value: i.userId,
        label: `${i.firstName} ${i.lastName}`,
        courses: Array.isArray(i.courses) && i.courses.length > 0 ? i.courses.map(c => {
          if (c === null) {
            return {}
          }
          return {
            id: c.id,
            name: c.name,
            price: c.price,
            userId: i.userId,
          }
        }) : [],
        waitingCourses: Array.isArray(i.waitingCourses) && i.waitingCourses.length > 0 ? i.waitingCourses.map(c => {
          if (c === null) {
            return {}
          }
          return {
            id: c.id,
            name: c.name
          }
        }) : [],
        programs: i.programs ? i.programs.map(p => {
          return {
            id: p.id,
            name: p.name,
            price: p.price,
            userId: i.userId,
            // courses: p.courses,
          }
        }) : [],
        discount: i.discount,
        price: i.totalPrice || 0,
        isActive: i.isActive,
      }));
      return arr;
    }
    return [];
  },
  childrenCourses: data => {
    let courses = [];
    data.forEach(cs => {
      cs.courses.forEach(c => {
        courses = [...courses, { ...c, course: c, student: `${cs.firstName} ${cs.lastName}`, courseId: c.id }]
      });
    });
    return courses;
  },
  childrenPrograms: data => {
    let programs = [];
    data.forEach(ps => {
      ps.programs.forEach(p => {
        programs = [...programs, {
          ...p,
          courses: p.courses.map(m => { return { course: m } })
        }]
      });
    });
    return programs;
  },
  courseWithStudentFormat: data => {
    if (data && data.length > 0) {
      const arr = data.map(i => ({
        value: i.course.id,
        label: i.course.name,
        studentEnrollments: i.course.studentEnrollments && i.course.studentEnrollments.filter(f => f.programId == null && !f.isDeleted)
      }));
      return arr;
    }
    return [];
  },
  userNameFormat: data => {
    if (data) {
      return {
        value: data.id,
        label: data.userName,
      };
    }
    return {};
  },
  totalTemplateFormat: data => {
    if (data) {
      const formatArray = data.map(t => ({
        ...t,
        value: t.id,
        label: t.name
      }))

      return formatArray
    }
    return []
  },
  totalCategoryFormat: (data, t) => {
    if (data) {
      const formatArray = data.map(c => ({
        ...c,
        value: c.id,
        label: t(`mailCate${c.id}`)
      }))
      return formatArray
    }

    return []
  },

};
