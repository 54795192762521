import React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"

export default props => {
    const { isOpen, toggle, title, onConfirm, confirmText, cancelText, children, t, noFooter, size } = props

    return <Modal isOpen={isOpen} toggle={toggle} size={size}>
        {title && <ModalHeader toggle={toggle}>
            {title}
        </ModalHeader>}
        <ModalBody>
            {children}
        </ModalBody>
        {!noFooter && <ModalFooter>
            {onConfirm && <Button color="success" onClick={onConfirm}>
                {confirmText || t("confirm")}
            </Button>}
            <Button color="secondary" onClick={toggle}>
                {cancelText || t("cancel")}
            </Button>
        </ModalFooter>}
    </Modal>
}