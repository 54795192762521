import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { hideConfirmDialog } from "./action";

const ConfirmDialog = () => {
    const { isShowConfirm, onConfirm, onReject, message } = useSelector(
        s => s.confirm
    );
    const dispatch = useDispatch();

    const toggle = () => {
        dispatch(hideConfirmDialog());
    };

    const handleClose = () => {
        if (onReject) {
            onReject();
        }

        toggle();
    };

    const handleConfirm = () => {
        if (onConfirm) {
            onConfirm();

            toggle();
        }
    };

    return (
        <Modal style={{
            marginTop: '20vh'
        }} isOpen={isShowConfirm} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                <h4 className="m-0">Confirm</h4>
            </ModalHeader>

            <ModalBody>{message}</ModalBody>

            <ModalFooter>
                <Button onClick={handleClose} style={{ width: "90px" }}>
                    Close
                </Button>
                <Button
                    onClick={handleConfirm}
                    style={{ width: "90px" }}
                    color="primary"
                >
                    Confirm
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ConfirmDialog;
