import React, { useState, useEffect } from "react"
import { Row, Col, CardBody, Card, CardFooter, Input, Button, CustomInput, Collapse } from "reactstrap"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Formik } from "formik"
import * as yup from "yup"
import _ from "lodash"
import moment from "moment"

import { get, updateResponseEvaluationAction, updateAssessmentResponseStatusAction } from "../redux/action"
import Response from "./Response"

import setModal from "../../../components/Modal/action"
import setAlert from "../../../components/Alert/action"
import { QUESTION_TYPE } from "../../../util/Constant"

const responseColor = {
    correct: "#4dbd74",
    notMarked: "#54a0ff",
    marked: "#bbbbbb",
    wrong: "#FF0000",
    unknown: "grey"
}

const schema = yup.object({
    isCorrect: yup.boolean(),
    mark: yup.number(),
})

const AssessmentResponseDetail = props => {
    const { t, match: { params: { assessmentResponseId } }, windowWidth, } = props
    const [currentAssessmentResponse, setCurrentAssessmentResponse] = useState(null)

    const loadData = () => {
        props.get({
            id: assessmentResponseId, cb: (data) => {
                setCurrentAssessmentResponse(data)
            }
        })
    }

    const getResponseStatus = response => {
        if (response) {
            if (response.isMarked && response.isCorrect) return { status: t('assessmentResponse.correct'), color: responseColor.correct }
            if (response.isMarked && !response.isCorrect) return { status: t('assessmentResponse.wrong'), color: responseColor.wrong }
            if (!response.isMarked) return { status: t('assessmentResponse.notMarked'), color: responseColor.notMarked }
        }
        return { status: t('Unanswered'), color: responseColor.unknown }
    }

    const [evaluationComment, setEvaluationComment] = useState("")

    const handleCompleteEvaluation = () => {
        props.updateAssessmentResponseStatusAction(currentAssessmentResponse.id, { status: "Completed", evaluationComment }, () => {
            props.setAlert({
                type: 'success',
                message: t("success")
            })
            props.history.goBack()
        })
    }
    useEffect(() => loadData(), [])

    const [isOpen, setIsOpen] = useState(false)
    const collapseIcon = isOpen ? 'fa-chevron-down' : 'fa-chevron-right'

    if (!currentAssessmentResponse) return "Loading..."

    const renderQuestionResponse = ({ question, response, key = 0 }) => {
        const canEditResult = response && currentAssessmentResponse.status === "PendingEvaluation" && [QUESTION_TYPE.EXTENDED_RESPONSE, QUESTION_TYPE.SHORT_ANSWER].includes(question.type)

        return <Card className="w-100 p-mb-2" key={key}>
            <CardBody className="p-2">
                <p className="font-weight-bold mb-2 d-flex align-items-center">
                    {t("Question")}
                    <Button
                        color="link"
                        className="ml-2"
                        onClick={() => window.open(`/question/${question.id}`)}
                    >
                        {t("view_question")}
                    </Button>
                </p>
                <div className="p-mb-2 mb-2" dangerouslySetInnerHTML={{ __html: question.text }} />

                {QUESTION_TYPE.HOT_TEXT === question.type && <div className="mb-2">
                    <hr />
                    {question.answers.map((ans, index) => <i key={index}>
                        {`${ans.token} = ${ans.text}`}{index < question.answers.length - 1 && ", "}
                    </i>)}
                </div>}

                <p className="font-weight-bold mb-2">{t("Response")}</p>
                <div className="mb-2">
                    {response
                        ? <Response
                            type={question.type}
                            response={response.response}
                            text={question.text}
                            answers={question.answers}
                            xCoordinateOptions={question.xCoordinateOptions}
                            yCoordinateOptions={question.yCoordinateOptions}
                            hotSpotOptions={question.hotSpotOptions}
                            imageUrl={question.imageUrl}
                        />
                        : "(No response)"}
                </div>

                <div className="mb-2">
                    <p
                        className="mb-2"
                        style={{
                            color: getResponseStatus(response).color,
                            fontSize: "1.2em",
                            fontWeight: 500,
                            fontStyle: "italic"
                        }}
                    >
                        {getResponseStatus(response).status}
                    </p>
                    <p className="mb-2 font-weight-bold">{t("assessmentResponse.mark")}: {response ? response.mark : 0}</p>
                </div>
            </CardBody>
            {canEditResult && <CardFooter>
                <Formik
                    initialValues={{
                        isCorrect: response && response.isCorrect,
                        questionId: question.id,
                        mark: response ? response.mark : 0
                    }}
                    validationSchema={schema}
                    onSubmit={async (values, actions) => {
                        props.updateResponseEvaluationAction({
                            id: assessmentResponseId,
                            ...values
                        }, () => {
                            loadData()
                            props.setAlert({
                                type: 'success',
                                message: t("success")
                            })
                        })
                    }}
                >
                    {formProps => <Row>
                        <Col md={6} className="d-flex align-items-center">
                            <p className="font-weight-bold mr-2 mb-0">{t("assessmentResponse.mark")}</p>

                            <div className="mr-2" style={{ width: "50px" }}>
                                <Input
                                    placeholder={t("assessmentResponse.mark")}
                                    name="mark"
                                    value={formProps.values.mark}
                                    onChange={formProps.handleChange}
                                    onBlur={formProps.handleBlur("mark")}
                                />
                                {formProps.touched.mark && formProps.errors.mark && <p className="text-danger">{formProps.errors.mark}</p>}
                            </div>

                            <div className="mr-2">
                                <div className="d-flex">
                                    <CustomInput
                                        type="checkbox"
                                        id={`isCorrect-${question.id}`}
                                        name="isCorrect"
                                        checked={formProps.values.isCorrect}
                                        onChange={e => formProps.setFieldValue("isCorrect", e.target.checked)}
                                    />
                                    <p className="font-weight-bold mb-0">{t("assessmentResponse.correct")}</p>
                                </div>
                                {formProps.touched.isCorrect && formProps.errors.isCorrect && <p className="text-danger">{formProps.errors.isCorrect}</p>}
                            </div>

                            <Button color="success" onClick={formProps.handleSubmit}>{t("save")}</Button>
                        </Col>
                    </Row>}
                </Formik>
            </CardFooter>}
        </Card>
    }

    return <div className="animated fadeIn py-2 px-3">
        <Row className="mb-2">
            <Col md={12}>
                <h5>{t("exam_name")}: {currentAssessmentResponse.examName}</h5>
                <p className="mb-2 font-weight-bold">{t("submitted_time")}: {moment(currentAssessmentResponse.submittedTime).format("L")}</p>
                <p className="mb-2 font-weight-bold">{t("student")}: {_.get(currentAssessmentResponse, "student.identity.fullName", "")}</p>
                <p
                    className="mb-2 cursor-pointer font-weight-bold text-primary"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {t("more_info")}
                    <span>
                        <i className={`fa ${collapseIcon} ml-2`} style={{ fontSize: "0.8em" }} aria-hidden="true" />
                    </span>
                </p>

                <Collapse isOpen={isOpen}>
                    <p className="font-weight-bold mb-2">{t("right_answers")}: {currentAssessmentResponse.rightAnswerCount}</p>
                    <p className="font-weight-bold mb-2">{t("wrong_answers")}: {currentAssessmentResponse.wrongAnswerCount}</p>
                    <p className="font-weight-bold mb-2">{t("pending_answers")}: {currentAssessmentResponse.markPendingAnswerCount}</p>
                    <p className="font-weight-bold mb-2">{t("unanswered_questions")}: {currentAssessmentResponse.unansweredQuestionCount}</p>
                </Collapse>
            </Col>
        </Row>
        <Row className="mb-2">
            {_.get(currentAssessmentResponse, "assessment.questions", []).length
                ? currentAssessmentResponse.assessment.questions.map((i, index) => {
                    const question = i.question
                    const response = _.get(currentAssessmentResponse, "questionResponses", []).find(r => r.questionId === question.id)

                    return <Col key={index} md={12}>
                        {QUESTION_TYPE.COMPOSITE !== question.type
                            ? renderQuestionResponse({ question, response })
                            : <Card>
                                <CardBody className="p-3">
                                    <p className="font-weight-bold mb-2">{t("composite_question")}</p>
                                    <div className="p-mb-2 mb-2" dangerouslySetInnerHTML={{ __html: question.text }} />
                                    {question.subQuestions.map((subQuestion, index) => {
                                        const subResponse = _.get(currentAssessmentResponse, "questionResponses", []).find(r => r.questionId === subQuestion.id)
                                        return renderQuestionResponse({ question: subQuestion, response: subResponse, key: index })
                                    })}
                                </CardBody>
                            </Card>}
                    </Col>
                })
                : null}
        </Row>
        {currentAssessmentResponse.status === "PendingEvaluation"
            ? <Row>
                <Col md={12} className="mb-2">
                    <p className="mb-2 font-weight-bold">{t("teacher_comment")}</p>
                    <Input
                        type="textarea"
                        value={evaluationComment}
                        onChange={e => setEvaluationComment(e.target.value)}
                    />
                </Col>
                <Col md={12} className="d-flex justify-content-end">
                    <Button
                        color="success"
                        onClick={() => handleCompleteEvaluation()}
                        title="Complete evaluation"
                    >
                        {t("complete_evaluation")}
                    </Button>
                </Col>
            </Row>
            : null}
        {currentAssessmentResponse.status === "Completed" && currentAssessmentResponse.evaluationComment && currentAssessmentResponse.evaluationComment.trim()
            ? <Row>
                <Col md={12}>
                    <p className="font-weight-bold mb-2">{t("teacher_comment")}: {currentAssessmentResponse.evaluationComment}</p>
                </Col>
            </Row>
            : null}
    </div>
}

const mapStateToProps = state => ({
    windowWidth: state.common.windowWidth,
    tableZone: state.common.tableZone
})

const mapDispatchToProps = {
    get,
    setModal,
    setAlert,
    updateResponseEvaluationAction,
    updateAssessmentResponseStatusAction
}

const AssessmentResponseDetailHOC = withTranslation("common")(AssessmentResponseDetail)

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentResponseDetailHOC)