export default {
  items: t => [
    {
      name: 'User management',
      // url: "/user-family/users",
      icon: "icon-user",
      roles: ["Admin"],
      children: [
        {
          name: 'Users',
          url: "/user-family/users",
          icon: "icon-user",
          roles: ["Admin"],
        },
        {
          name: 'Groups',
          url: "/user-family/groups",
          icon: "fa fa-users",
          roles: ["Admin"],
        },
        {
          name: 'Subjects',
          url: "/user-family/subjects",
          icon: "fa fa-object-group",
          roles: ["Admin"],
        },
        {
          name: 'Reminder',
          url: "/user-family/question-reminder",
          icon: "fa fa-bell-o",
          roles: ["Admin"],
        },
      ]
    },
    {
      name: t("Question authoring"),
      icon: "icon-doc",
      children: [
        {
          name: t("assessment_management"),
          url: "/question-bank/assessments",
          icon: "fa fa-book",
          roles: [""],
        },
        {
          name: t("questions"),
          url: "/question-bank/questions",
          icon: "icon-question",
          roles: ["Admin"],
        },
        {
          name: t("question_categories"),
          url: "/question-bank/question-categories",
          icon: "fa fa-bars",
          roles: [""],
        },
        {
          name: t("articles"),
          url: "/question-bank/articles",
          icon: "fa fa-indent",
          roles: ["Question creator"],
        },
        {
          name: t("Create Task Assignment"),
          url: "/question-bank/create-task-assignment",
          icon: "fa fa-plus",
          roles: ["Admin"],
        },
        {
          name: t("My tasks"),
          url: "/question-bank/my-task",
          icon: "fa fa-tasks",
          roles: ["Question creator"],
        },
        {
          name: t("My question review"),
          url: "/question-bank/question-review",
          icon: "fa fa-question",
          roles: ["Proof reader"],
        }
      ],
    },
    {
      name: t("test_management"),
      icon: "icon-pencil",
      roles: [""],
      children: [
        {
          name: t("test_packs"),
          url: "/test-management/test-pack",
          icon: "fa fa-file",
          roles: [""],
        },
        {
          name: t("test_suites"),
          url: "/test-management/test-suite",
          icon: "fa fa-object-group",
          roles: [""],
        },
        {
          name: t("test_result"),
          url: "/test-management/test-result",
          icon: "fa fa-check",
          roles: [""],
        },
      ],
    },
    {
      name: t("subscriptions"),
      icon: "icon-user-following",
      roles: [""],
      children: [
        {
          name: t("subscriptions"),
          url: "/subscription-management/subscriptions",
          icon: "icon-user-following",
          roles: [""],
        },
        {
          name: t("Payment"),
          url: "/subscription-management/payments",
          icon: "fa fa-check",
          roles: [""],
        },
      ],
    },

    {
      name: t("reports"),
      url: "/test-reports/test",
      icon: "icon-chart",
      roles: [""],
      children: [
        {
          name: t("test_reports"),
          url: "/test-reports/test",
          icon: "fa fa-bookmark",
          roles: [""],
        },
        {
          name: t("student_reports"),
          url: "/test-reports/student",
          icon: "fa fa-user-o",
          roles: [""],
        },
      ],
    },
    {
      name: t("set_up_lower"),
      url: "/setup/subject",
      icon: "icon-settings",
      roles: [""],
    }
  ],
};
