import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    FormGroup,
    Input,
    Label,
    Button,
    Table
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CreateBtn from "../../components/Button/CreateBtn";
import CustomPagination from "../../components/CustomPagination";
import { checkSpecialChars, sortAlphabet } from "../../util/Common";
import setAlert from "../../components/Alert/action";
import {
    createTaskSubject,
    deleteTaskSubject,
    getAllTaskSubject
} from "../../util/api";
import useFetch from "../../hooks/useFetch";

const SubjectList = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { data: subjectsData, reload: refetchTasks } = useFetch(
        getAllTaskSubject
    );
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const ipSearch = useRef("");
    const [search, setSearch] = useState("");
    // const [status, setStatus] = useState(null); // 0 | 1
    const [isOpen, setIsOpen] = useState(false);
    const [nameSubject, setNameSubject] = useState("");
    // const [description, setDescription] = useState("");

    const { t } = useTranslation("common");

    const dataTable = sortAlphabet(
        subjectsData
            ? subjectsData
                  //   .filter(e => {
                  //       if (status !== null) {
                  //           return e.isActive === !!status;
                  //       }

                  //       return e;
                  //   })
                  .filter(e => {
                      return `${e.name}`.includes(search.trim());
                  })
            : [],
        "firstName"
    );

    useEffect(() => {
        if (!dataTable) return;

        if (currentPage > Math.ceil(dataTable.length / pageSize)) {
            setCurrentPage(1);
        }
    }, [pageSize, subjectsData]);

    const toggle = () => {
        setIsOpen(!isOpen);
        setNameSubject("");
        // setDescription("");
    };

    const onConfirm = async () => {
        if (checkSpecialChars(nameSubject)) {
            window.alert("Subject's name does not contain special characters!");
            return;
        }

        if (!nameSubject.trim()) {
            window.alert("Subject's name is required!");
            return;
        }
        try {
            // call api
            const data = {
                name: nameSubject,
            };
            await createTaskSubject(data);
            toggle();
            dispatch(
                setAlert({
                    type: "success",
                    message: "Create Subject's Name successfully"
                })
            );
            refetchTasks();
        } catch (err) {
            toggle();
            dispatch(
                setAlert({
                    type: "danger",
                    message: "Create failed"
                })
            );
        }
    };

    const handleDelete = async taskId => {
        try {
            const isConfirm = window.confirm(
                "Are you sure you want to delete?"
            );

            if (isConfirm) {
                await deleteTaskSubject(taskId);
                refetchTasks();
            }
        } catch (error) {
            console.log("Error::", error);
        }
    };

    const renderModal = () => {
        return (
            <Modal isOpen={isOpen} toggle={toggle} className="create-subject">
                <ModalHeader toggle={toggle}>Create Subject</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>Subject&apos;s Name</Label>

                        <Input
                            type="text"
                            autoFocus
                            value={nameSubject}
                            onChange={e => setNameSubject(e.target.value)}
                            invalid={false}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{ width: "100px" }}
                        color="secondary"
                        onClick={toggle}
                    >
                        {t("Cancel")}
                    </Button>
                    <Button
                        style={{ width: "100px" }}
                        color="success"
                        onClick={onConfirm}
                    >
                        {t("Confirm")}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    return (
        <div>
            {renderModal()}
            <div className="row">
                <div className="col-4 d-flex">
                    <CreateBtn
                        title="Create subject"
                        onClick={() => {
                            setIsOpen(true);
                        }}
                        style={{
                            width: "120px"
                        }}
                    />

                    <div style={{ marginRight: "30px" }} />

                    <Input
                        placeholder="Search"
                        onChange={e => {
                            ipSearch.current = e.target.value;
                        }}
                        onKeyDown={e => {
                            if (e.key === "Enter") {
                                setSearch(ipSearch.current);
                            }
                        }}
                        style={{
                            flex: 1
                        }}
                    />
                </div>
                {/* <div className="col-2">
                    <Select
                        isClearable
                        placeholder="Select status"
                        options={[
                            { value: 1, label: "Active" },
                            { value: 0, label: "Non active" }
                        ]}
                        onChange={e => {
                            if (!e) {
                                setStatus(null);
                                return;
                            }
                            setStatus(e.value);
                        }}
                    />
                </div> */}
            </div>

            <div className="table-content">
                <Table
                    id="table-questions"
                    hover
                    bordered
                    striped
                    responsive
                    className="grid-table mb-2 mt-2"
                >
                    <thead>
                        <tr>
                            <th style={{ width: "40%" }}>Subject</th>
                            <th style={{ width: "20%", textAlign: "center" }}>
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataTable
                            ? dataTable
                                  .slice(
                                      (currentPage - 1) * pageSize,
                                      currentPage * pageSize
                                  )
                                  .map((e, index) => (
                                      <tr key={index}>
                                          <td>{e.name}</td>
                                          <td className="text-center">
                                              <i
                                                  style={{
                                                      cursor: "pointer"
                                                  }}
                                                  onClick={() => {
                                                      history.push({
                                                          pathname: `/subjects/edit-subject/${e.id}`,
                                                          data: e
                                                      });
                                                  }}
                                                  className="fa fa-pencil mr-2"
                                                  aria-hidden="true"
                                              ></i>
                                              <i
                                                  onClick={() =>
                                                      handleDelete(e.id)
                                                  }
                                                  className="fa fa-trash"
                                                  aria-hidden="true"
                                                  style={{
                                                      cursor: "pointer"
                                                  }}
                                              />
                                          </td>
                                      </tr>
                                  ))
                            : ""}
                    </tbody>
                </Table>

                <CustomPagination
                    pageCount={
                        dataTable ? Math.ceil(dataTable.length / pageSize) : 0
                    }
                    pageSize={pageSize}
                    currentPage={currentPage}
                    // pageSizeName={}
                    changePage={e => {
                        setCurrentPage(e);
                    }}
                    changePageSize={e => {
                        setPageSize(e);
                    }}
                    onChangePageSize={() => {}}
                />
            </div>
        </div>
    );
};

export default SubjectList;
