import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import _ from "lodash"
import {
    Button,
    Card,
    CardBody,
    CardGroup,
    Col,
    Container,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
} from "reactstrap";

import history from "../../../util/history"
import { authenticator } from "../redux/actions"
import setAlert from "../../../components/Alert/action";

const Authentication = props => {
    const email = _.get(props, 'history.location.state.email')
    const isSent = _.get(props, 'history.location.state.isSent')

    const [codeAuth, setCodeAuth] = useState("")

    useEffect(() => {
        if (!email) {
            history.push("/login")
        }
    }, [])

    const onSubmitAuth = e => {
        e.preventDefault();
        if (codeAuth.trim().length === 0) {
            props.setAlert("Email or password is not valid");
        } else {
            props.authenticator({ email, codeAuth });
        }
    };

    return <Form onSubmit={e => onSubmitAuth(e)} >
        <div className="app flex-row align-items-center">
            <Container>
                <Row className="justify-content-center">
                    <Col md="4">
                        <CardGroup>
                            <Card className="p-4">
                                <CardBody>
                                    <h3>2FA Authentication</h3>
                                    {isSent && <p className="mb-2">
                                        Please check your email to complete 2FA
                                    </p>}
                                    <InputGroup className="mb-4">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="icon-lock" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type="text"
                                            value={codeAuth}
                                            onChange={e => setCodeAuth(e.target.value)}
                                            placeholder="Verification Token"
                                            required
                                        />
                                    </InputGroup>
                                    <Row>
                                        <Col xs="6">
                                            <Button color="primary" className="px-4" type="submit">
                                                Login
                                            </Button>
                                        </Col>
                                        <Col xs="6" className="text-right" />
                                    </Row>
                                </CardBody>
                            </Card>
                        </CardGroup>
                    </Col>
                </Row>
            </Container>
        </div>
    </Form >
}

const mapDispatchToProps = {
    authenticator,
    setAlert
}

export default connect(null, mapDispatchToProps)(Authentication)