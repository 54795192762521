import React, { memo } from 'react'
import { Button } from 'reactstrap'

export default memo(function CreateBtn(props) {
    return (
        <Button
            color="success"
            onClick={props.onClick}
            style={{ height: props.height ? props.height : '' }}
            {...props}
        >
            <i className="fa fa-plus" /> {props.title}
        </Button>
    )
})