import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Card, CardBody, Col, Row } from "reactstrap"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { getTextFromHTML } from "../../../util/helper"
import QuestionCategorySelector from "../../../components/Selector/QuestionCategorySelector"
import { getQuestions } from "../../Question/redux/action"

const AssessmentQuestionSelector = props => {
    const { filteredQuestions, fullQuestionList, selectedQuestionIds, onChangeSelectedItems } = props

    const [categoryIdFilter, setCategoryId] = useState(0)
    const [filteredFilteredQuestions, setFilteredQuestions] = useState([])
    const [selectedQuestions, setSelectedQuestions] = useState([])

    useEffect(() => {
        setFilteredQuestions(filteredQuestions.filter(q => !selectedQuestionIds.includes(q.id)))
    }, [filteredQuestions])

    useEffect(() => {
        props.getQuestions({ categoryId: categoryIdFilter })
    }, [categoryIdFilter])

    useEffect(() => {
        if (fullQuestionList.length) { setSelectedQuestions(selectedQuestionIds.map(q => fullQuestionList.filter(i => i.id === q)[0] || { id: 0, text: `Unknown question ${selectedQuestionIds.join(',')}` })) }
    }, [fullQuestionList])

    const grid = 8
    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "lightgrey",
        padding: grid,
        width: "100%"
    })

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        // padding: grid * 2,
        // margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        // background: isDragging ? "lightgreen" : "grey",

        // styles we need to apply on draggables
        ...draggableStyle
    })

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }


    /**
     * Moves an item from one list to another list.
     */
    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source)
        const destClone = Array.from(destination)
        const [removed] = sourceClone.splice(droppableSource.index, 1)

        destClone.splice(droppableDestination.index, 0, removed)

        const result = {}
        result[droppableSource.droppableId] = sourceClone
        result[droppableDestination.droppableId] = destClone

        return result
    }

    const getList = (id) => {
        if (id === "questionItems") return filteredFilteredQuestions
        if (id === "selectedQuestions") return selectedQuestions
    }

    const onDragEnd = (result) => {
        const { source, destination } = result

        // dropped outside the list
        if (!destination) {
            return
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                getList(source.droppableId),
                source.index,
                destination.index
            )

            if (source.droppableId === 'questionItems') {
                setFilteredQuestions(items)
            }
            if (source.droppableId === 'selectedQuestions') {
                setSelectedQuestions(items)
                if (onChangeSelectedItems)
                    onChangeSelectedItems(items.map(i => i.id))
            }
        } else {
            const moveResult = move(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination
            )
            setFilteredQuestions(moveResult.questionItems)
            setSelectedQuestions(moveResult.selectedQuestions)
            if (onChangeSelectedItems)
                onChangeSelectedItems(moveResult.selectedQuestions.map(i => i.id))
        }
    }
    return <DragDropContext onDragEnd={onDragEnd}>
        <Row>
            <Col xs="4">
                <QuestionCategorySelector
                    noLabel
                    onChange={e => setCategoryId(e ? e.value : 0)}
                    isClearable
                />
            </Col>
        </Row>
        <Row>

            <Col xs="4" className="mr-2">
                <h6>All questions</h6>

                <Droppable droppableId="questionItems">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{ ...getListStyle(snapshot.isDraggingOver), maxHeight: "60vh", overflowY: "auto", overflowX: "hidden" }}
                        >
                            {filteredFilteredQuestions.map((item, index) => (
                                <Draggable key={item.id} draggableId={`questionItems${item.id.toString()}`} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <Card className="mb-1">
                                                <CardBody className="p-2" >
                                                    {!!item.title ? item.title : ""}
                                                </CardBody>
                                            </Card>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </Col>

            <Col xs="4">
                <h6>Selected questions</h6>
                <Droppable droppableId="selectedQuestions">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {selectedQuestions.map((item, index) => (
                                <Draggable key={item.id} draggableId={`selected${item.id.toString()}`} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <Card className="mb-1">
                                                <CardBody className="p-2" >
                                                    {index + 1}. {!!item.title ? item.title : ""}
                                                </CardBody>
                                            </Card>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </Col>
        </Row>
    </DragDropContext>
}

const mapStateToProps = state => ({
    filteredQuestions: state.question.questionList,
    fullQuestionList: state.question.fullQuestionList
})

const mapDispatchToProps = {
    getQuestions
}

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentQuestionSelector)