/* eslint-disable no-restricted-globals */
import UserList from "../User/view/home"
import editUser from "../User/view/edit"
import homeSetup from "../Setup/view/home"
import EditGroup from "../User/view/EditGroup"

import ELearningHomePage from "../Assessment/view"
import EditAssessment from "../Assessment/view/EditAssessment"
import PreviewAssessment from "../Assessment/view/PreviewAssessment"
import EditQuestion from "../Question/view/EditQuestion"
import AssessmentResponsesList from "../AssessmentResponse/view/AssessmentResponsesList"
import AssessmentResponseDetail from "../AssessmentResponse/view/AssessmentResponseDetail"
import AssHome from "../Assessment/view/Home"

import TestPackDetail from "../TestPack/view/TestPackDetail"
import TestSuiteDetail from "../TestSuite/view/TestSuiteDetail"
import TestSuite from "../TestSuite/view"
import TestPack from "../TestPack/view"
// import TestResult from "../TestResult/view"
import TestManagement from "../TestManagement"

import Subscription from "../Subscription/view"
import SubscriptionHome from "../Subscription/view/Home"
// import TestSuitePayment from "../TestSuitePayment/view"
import ReportListContainer from "../TestReports/view/ReportListContainer"
import QuestionReview from "../QuestionReview/QuestionReview"
import PreviewQuestion from "../Question/view/PreviewQuestion"
import QuestionEdit from "../MyTask/EditQuestion";
import CreateQuestion from "../MyTask/CreateQuestion";
import EditSubject from "../User/view/EditSubject"
import CommentableQuestionPreview from "../Question/view/CommentableQuestionPreview"
import ApprovalHistory from "../ApprovalHistory"
import EditQuestionReminder from "../User/view/EditQuestionReminder"

export default [
    {
        path: "/user/create-user",
        exact: true,
        name: "CreateUser",
        component: editUser
    },
    {
        path: "/user/edit-user/:id",
        exact: true,
        name: "EditUser",
        component: editUser
    },
    {
        path: "/groups/edit-group/:id",
        exact: true,
        name: "EditGroup",
        component: EditGroup
    },
    {
        path: "/subjects/edit-subject/:id",
        exact: true,
        name: "EditSubject",
        component: EditSubject
    },
    {
        path: "/question-reminder/edit/:id",
        exact: true,
        name: "EditQuestionReminder",
        component: EditQuestionReminder
    },
    {
        path: "/user-family/:p",
        exact: false,
        name: "UserFamilyList",
        component: UserList
    },
    {
        path: "/setup/:p",
        exact: false,
        name: "Setup",
        component: homeSetup
    },
    {
        path: "/question-bank/:p",
        name: "ELearning",
        component: ELearningHomePage
    },
    {
        path: "/create-question",
        exact: true,
        name: "Create question",
        component: EditQuestion
    },
    {
        path: "/question/:id",
        exact: true,
        name: "Edit question",
        component: EditQuestion
    },
    {
        path: "/create-assessment",
        exact: true,
        name: "Create assessment",
        component: EditAssessment
    },
    {
        path: "/edit-assessment/:id",
        exact: true,
        name: "Edit assessment",
        component: EditAssessment
    },
    {
        path: "/question-bank/assessments",
        exact: true,
        name: "Assessments",
        component: AssHome
    },
    {
        path: "/preview-assessment/:id",
        exact: true,
        name: "Preview assessment",
        component: PreviewAssessment
    },
    {
        path: "/assessment-responses/:assessmentAssignmentId",
        exact: true,
        name: "View exam responses",
        component: AssessmentResponsesList
    },
    {
        path: "/assessment-response/:assessmentResponseId",
        exact: true,
        name: "Response detail",
        component: AssessmentResponseDetail
    },
    {
        path: "/create-test-pack",
        exact: true,
        name: "Create test pack",
        component: TestPackDetail
    },
    {
        path: "/edit-test-pack/:id",
        exact: true,
        name: "Edit test pack",
        component: TestPackDetail
    },
    {
        path: "/create-test-suite",
        exact: true,
        name: "Create test suite",
        component: TestSuiteDetail
    },
    {
        path: "/edit-test-suite/:id",
        exact: true,
        name: "Edit test suite",
        component: TestSuiteDetail
    },
    {
        path: "/test-management/:p",
        name: "Test Management",
        component: TestManagement
    },
    {
        path: "/test-management/test-suite",
        name: "Test Suite",
        component: TestSuite
    },
    {
        path: "/test-management/test-pack",
        name: "Test Packs",
        component: TestPack
    },
    // {
    //   path: "/test-management/test-result",
    //   name: "Test Result",
    //   component: TestResult
    // },
    {
        path: "/subscription-management/:p",
        name: "Subscription Management",
        component: Subscription
    },
    {
        path: "/subscription-management/subscriptions",
        name: "Subscriptions",
        component: SubscriptionHome
    },
    {
        path: "/test-reports/:reportType",
        name: "Test reports",
        component: ReportListContainer
    },
    {
        exact: true,
        path: "/review-question/:id",
        name: "Review Question",
        component: QuestionReview
    },
    {
        exact: true,
        path: "/preview-question/:id",
        name: "Preview Question",
        component: PreviewQuestion,
    },
    {
        exact: true,
        path: "/commentable-preview-question/:questionId",
        name: "Preview Question",
        component: CommentableQuestionPreview,
    },
    {
        exact: true,
        path: "/edit-question/:id",
        name: "Edit Question",
        component: QuestionEdit
    },
    {
        exact: true,
        path: "/new-question",
        name: "Create Question",
        component: CreateQuestion
    },
    {
        exact: true,
        path: "/approval-history/:questionId",
        name: "Approval history",
        component: ApprovalHistory
    }
]
