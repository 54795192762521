import React, { Component } from "react";
import { Pagination, PaginationItem, PaginationLink, Label, Input } from "reactstrap";
import { withTranslation } from 'react-i18next'

import CustomSelected from "./Selector/CustomSelected"

class CustomPagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumberInput: this.props.currentPage || 1,
    };
  }

  componentDidUpdate(prevProps) {
    const prevCurrentPage = prevProps.currentPage;
    if (this.props.currentPage && prevCurrentPage !== this.props.currentPage) {
      this.setState({ pageNumberInput: this.props.currentPage });
    }
  }

  onKeyUp = e => {
    if (e.keyCode === 13) {
      this.props.changePage(parseInt(this.state.pageNumberInput), this.props.changePageParams);
    }
  };

  onChangePageNumber = e => {
    this.setState({
      pageNumberInput: e.target.value,
    });
  };

  onNextClick = () => {
    this.changePage("relative", 1);
  };

  onPrevClick = () => {
    this.changePage("relative", -1);
  };

  clickFirstPage = () => {
    this.changePage("absolute", 1);
  };

  clickLastPage = () => {
    this.changePage("absolute", this.props.pageCount);
  };

  changePage = (type, pageNumber) => {
    let newPage = 1;
    if (type === "relative") {
      const { currentPage } = this.props;
      newPage = parseInt(currentPage) + pageNumber;
    }
    if (type === "absolute") {
      newPage = pageNumber;
    }

    this.props.changePage(newPage, this.props.changePageParams);
    this.setState({ pageNumberInput: newPage.toString() });
  };

  options = [{ label: 10, value: 10 }, { label: 20, value: 20 }, { label: 30, value: 30 }, { label: 50, value: 50 }, { label: 100, value: 100 }]

  render() {
    const prevDisabled = this.props.currentPage <= 1 || !this.props.currentPage;
    const nextDisabled = this.props.currentPage >= this.props.pageCount || !this.props.currentPage;

    const currentOption = this.options.find(option => option.value === this.props.pageSize) || this.options[2]

    return (
      <Pagination className="grid-pagination ">
        <div style={{ width: 80 }} className="mr-2">
          {this.props.pageSize &&
            <CustomSelected
            value={currentOption}
            style={{ width: 40 }}
            options={this.options}
            onChange={async e => {
              if (this.props.pageSize === e) return;
              
              if (this.props.pageSizeName) {
                localStorage.setItem(this.props.pageSizeName, e.value)
              }
              
              await this.props.changePageSize(e.value)
              this.props.onChangePageSize()
            }}
              className="text-center"
              menuPlacement="top"
            />
          }

        </div>

        <PaginationItem disabled={prevDisabled}>
          <PaginationLink
            disabled={prevDisabled}
            onClick={this.onPrevClick}
            previous
            tag="button"
          >
            {this.props.t('previous')}
          </PaginationLink>
        </PaginationItem>
        <PaginationItem>
          <PaginationLink onClick={this.clickFirstPage} tag="button">
            1
            </PaginationLink>
        </PaginationItem>

        <Label className="page-input-label" htmlFor="pageNumberInput" />
        <Input
          type="text"
          id="pageNumberInput"
          className="page-input"
          onKeyUp={this.onKeyUp}
          onChange={this.onChangePageNumber}
          value={this.state.pageNumberInput}
        />
        <PaginationItem>
          <PaginationLink onClick={this.clickLastPage} tag="button">
            {this.props.pageCount || 1}
          </PaginationLink>
        </PaginationItem>

        <PaginationItem disabled={nextDisabled}>
          <PaginationLink disabled={nextDisabled} onClick={this.onNextClick} next tag="button">
            {this.props.t('next')}
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    );
  }
}

export default withTranslation('common')(CustomPagination)
