import {
    getAllTestSuite,
    createTestSuite,
    updateTestSuite,
    deleteTestSuite,
    getTestSuite
} from "../../../util/api"
import setLoading from "../../../components/Spinner/action"
import setModal from "../../../components/Modal/action"
import setAlert from "../../../components/Alert/action"

export const getAllSuite = ({ currentPage, pageSize, searchString, sortBy, sortOrder }) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await getAllTestSuite({ currentPage, pageSize, searchString, sortBy, sortOrder })

        if (!res.data) throw new Error("Error in getting test suite")

        if (Array.isArray(res.data)) throw new Error(res.data.map(item => item.value))

        if (pageSize) {
            dispatch({
                type: "SET_TEST_SUITE",
                payload: res.data.data,
                // currentPage: res.data.currentPage,
                totalItems: res.data.totalItems,
                pageCount: res.data.pageCount,
            })
        } else {
            dispatch({
                type: "SET_ALL_TEST_SUITE",
                payload: res.data.data
            })
        }
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: JSON.stringify(err.response.data)
        }))
    }

    dispatch(setLoading(false))
}

export const changePageSizeTestSuite = pageSize => async dispatch => {
    dispatch({
        type: "CHANGE_PAGE_SIZE_TEST_SUITE",
        payload: pageSize
    })
}

export const changeCurrentPageTestSuite = page => async dispatch => {
    dispatch({
        type: "CHANGE_CURRENT_PAGE_TEST_SUITE",
        payload: page
    })
}

export const createSuite = (data, cb) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await createTestSuite(data)
        if (!res.data) throw new Error("Error in creating test suite")

        if (Array.isArray(res.data)) throw new Error(res.data.map(item => item.value))

        dispatch(setAlert({
            type: "success",
            message: "create_test_suite_success"
        }))
        if (cb) cb()
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: JSON.stringify(err.message)
        }))
    }

    dispatch(setLoading(false))
}

export const updateSuite = (data, cb) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await updateTestSuite(data)
        if (!res.data) throw new Error("Error in updating test suite")

        if (Array.isArray(res.data)) throw new Error(res.data.map(item => item.value))

        dispatch(setAlert({
            type: "success",
            message: "update_test_suite_success"
        }))
        if (cb) cb()
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: JSON.stringify(err.message)
        }))
    }

    dispatch(setLoading(false))
}

export const removeSuite = (id, cb) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await deleteTestSuite(id)
        if (res.status !== 200) throw new Error("Error in deleting test suite")

        dispatch(setAlert({
            type: "success",
            message: "delete_test_suite_success"
        }))

        if (cb) cb()
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: err.response.data
        }))
    }

    dispatch(setLoading(false))
}

export const getSuite = id => async dispatch => {
    dispatch(setLoading(true))
    let res = null

    try {
        res = await getTestSuite(id)
        if (!res.data) throw new Error("Error in creating test suite")

        if (Array.isArray(res.data)) throw new Error(res.data.map(item => item.value))
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: JSON.stringify(err.message)
        }))
    }

    dispatch(setLoading(false))
    return res
}