import React, { useEffect, useState } from "react";

const Timer = ({ onChange }) => {
    const [timer, setTimer] = useState(0);

    useEffect(() => {
        const timeId = setInterval(() => {
            setTimer(pre => {
                if (onChange && typeof onChange === 'function') {
                    onChange(pre + 1);
                }
                return pre + 1;
            });
        }, 1000);

        return () => {
            clearInterval(timeId);
        };
    }, []);

    const hours = Math.floor(timer / 3600);
    const minutes = Math.floor((timer - hours * 3600) / 60);
    const seconds = timer - hours * 3600 - minutes * 60;

    return (
        <div
            style={{
                border: "1px solid #9c9a9a",
                height: "32px",
                width: "auto",
                padding: "0 10px",
                display: "inline-flex",
                alignItems: "center"
            }}
        >
            <i
                style={{
                    color: "steelblue"
                }}
                className="fa fa-clock-o"
                aria-hidden="true"
            ></i>

            <span className="ml-5">
                {hours < 10 ? `0${hours}` : hours}:
                {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
            </span>
        </div>
    );
};

export default Timer;
