import { getLinkChangePassword } from "../../../util/api";
import setLoading from "../../../components/Spinner/action";
import setAlert from "../../../components/Alert/action";
import history from "../../../util/history";

export const sendEmailToChangePassword = data => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await getLinkChangePassword(data);
        
        if (Array.isArray(res.data)) throw new Error(res.data[0].value);

        if (res.data.errorMessage) {
            window.alert(res.data.errorMessage);
            dispatch(setLoading(false));
            return;
        }

        if (res.status === 200) {
            setAlert({
                type: "success",
                message: "Please check your email."
            })
            history.push("/login");
        }
    } catch (err) {
        setAlert({
            type: "danger",
            message: err.message || "Email invalid."
        })
    }

    dispatch(setLoading(false))
};
