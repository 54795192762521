import React from 'react'
import {
    Col,
    Row,
    FormGroup,
    Label,
    Input,
    FormFeedback,
    InputGroupAddon,
    Button,
    InputGroup
} from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import validation from '../../../util/validation';

export default ({
    id,
    additionalEmail,
    viewOnly,
    checkValidateOnly,
    firstName,
    onChangeFirstName,
    lastName,
    onChangeLastName,
    email,
    onChangeEmail,
    onChangeHP,
    homePhone,
    phoneNumber,
    onChangePN,
    check,
    workOfficePhone,
    onChangeWOP,
    birthday,
    onChangeBirthday,
    onChangeAE,
    onChangeSSN,
    ssn,
    randomEmail,
    checkSsn,
    t
}) => {
    return (
        <>
            <FormGroup>
                <Label>{t('first_name')} <span style={{ color: 'red' }}>*</span></Label>
                <Input
                    type="text"
                    value={firstName.value}
                    onChange={event => onChangeFirstName(event.target.value)}
                    autoFocus={!id}
                    onBlur={() => check("firstName")}
                    placeholder={t('first_name')}
                    invalid={firstName.invalid}
                    readOnly={viewOnly}
                />
                {firstName.invalid && (
                    <FormFeedback>{firstName.errorMessage}</FormFeedback>
                )}
            </FormGroup>

            <FormGroup>
                <Label>{t('last_name')} <span style={{ color: 'red' }}>*</span></Label>
                <Input
                    type="text"
                    value={lastName.value}
                    onChange={event => onChangeLastName(event.target.value)}
                    onBlur={() => check("lastName")}
                    placeholder={t('last_name')}
                    invalid={lastName.invalid}
                    readOnly={viewOnly}
                />
                {lastName.invalid && <FormFeedback>{lastName.errorMessage}</FormFeedback>}
            </FormGroup>

            <FormGroup>
                <Label>{t('Email/login_name')}<span style={{ color: 'red' }}>*</span></Label>
                <InputGroup>
                    <Input
                        type="text"
                        value={email.value}
                        onChange={event => onChangeEmail(event.target.value)}
                        onBlur={() => check("email", validation.email)}
                        placeholder={t('Email/login_name')}
                        invalid={email.invalid}
                        readOnly={viewOnly}
                    />
                    {/* <InputGroupAddon addonType="append" >
                        <Button color="secondary" onClick={randomEmail}>
                            <span className="dice" />
                        </Button>
                    </InputGroupAddon> */}
                </InputGroup>
                {email.invalid && (
                    <FormFeedback style={{ display: "flex" }}>
                        {email.errorMessage}
                    </FormFeedback>
                )}

            </FormGroup>

            <FormGroup>
                <Label>{t('Phone number')}</Label>
                <Input
                    type="text"
                    value={phoneNumber.value}
                    onChange={event => onChangePN(event.target.value)}
                    onBlur={() => checkValidateOnly("phoneNumber", validation.phone)}
                    placeholder={t('Phone number')}
                    invalid={phoneNumber.invalid}
                    readOnly={viewOnly}
                />
                {phoneNumber.invalid && (
                    <FormFeedback>{phoneNumber.errorMessage}</FormFeedback>
                )}
            </FormGroup>
            {/* <FormGroup>
                <Label>{t('Home_phone')}</Label>
                <Input
                    type="text"
                    value={homePhone.value}
                    onChange={event => onChangeHP(event.target.value)}
                    onBlur={() => checkValidateOnly("homePhone", validation.phone)}
                    placeholder={t('Home_phone')}
                    invalid={homePhone.invalid}
                    readOnly={viewOnly}
                />
                {homePhone.invalid && <FormFeedback>{homePhone.errorMessage}</FormFeedback>}
            </FormGroup> */}
            {/* <FormGroup>
                <Label>{t('Work_office_phone')}</Label>
                <Input
                    type="text"
                    value={workOfficePhone.value}
                    onChange={event => onChangeWOP(event.target.value)}
                    onBlur={() => checkValidateOnly("workOfficePhone", validation.phone)}
                    placeholder={t('Work_office_phone')}
                    invalid={workOfficePhone.invalid}
                    readOnly={viewOnly}
                />
                {workOfficePhone.invalid && (
                    <FormFeedback>{workOfficePhone.errorMessage}</FormFeedback>
                )}
            </FormGroup> */}

            <Row form>
                <Col md="6">
                    <FormGroup>
                        <Label>
                            {t('Birthday')}
                        </Label>
                        <DatePicker

                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            maxTime={moment().format("L")}
                            selected={birthday.value && moment(birthday.value).toDate()}
                            value={
                                (birthday.value && moment(birthday.value).format("L")) ||
                                ""
                            }
                            filterDate={(date) => {
                                return moment() > date;
                            }}
                            onChange={onChangeBirthday}
                            placeholderText={t('Birthday')}
                            customInput={
                                <Input
                                    type="text"
                                    placeholder={t('Birthday')}
                                    style={{ width: 'maxContent' }}
                                />
                            }
                            locale="en-gb"
                            readOnly={viewOnly}
                        />
                    </FormGroup>
                </Col>

                {/* <Col md="6" className="pl-md-1">
                    <FormGroup>
                        <Label>{t('Last_number_in_SSN')}</Label>
                        <Input
                            type="number"
                            value={ssn.value || ""}
                            onChange={event => {
                                if (!(event.target.value.length > 4)) {
                                    onChangeSSN(event.target.value)
                                }
                            }}
                            min={0}
                            onBlur={() => checkSsn()}
                            placeholder={t('Last_number_in_SSN')}
                            readOnly={viewOnly}
                            invalid={ssn.invalid}
                        />
                        {ssn.invalid && <FormFeedback>{ssn.errorMessage}</FormFeedback>}
                    </FormGroup>
                </Col> */}


            </Row>


            <Row form>
                <Col md="6">
                    <FormGroup>
                        <Label>{t('Additional_email')}</Label>
                        <Input
                            type="text"
                            value={additionalEmail.value}
                            onChange={event => onChangeAE(event.target.value)}
                            onBlur={() => check("additionalEmail", validation.email, true)}
                            placeholder={t('Additional_email')}
                            invalid={additionalEmail.invalid}
                            readOnly={viewOnly}
                        />
                        {additionalEmail.invalid && <FormFeedback>{additionalEmail.errorMessage}</FormFeedback>}
                    </FormGroup>
                </Col>

                <Col md="6" className="float-right">
                    <FormGroup>
                        <Label>{t('age')}</Label>
                        <Input
                            type="text"
                            readOnly
                            value={birthday && birthday.value ? moment().diff(birthday.value, 'years') : 0}
                            placeholder={t('age')}
                        />
                    </FormGroup>
                </Col>
            </Row>

        </>
    )
}
