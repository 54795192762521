const email = str => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(str);

const password = str => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/.test(str);

const phone = str => /^[0-9\-\+]{6,12}$/.test(str);

const validation = {
  email,
  password,
  phone,
};

export default validation;
