import React from "react";

const CustomCheckbox = ({ checked, id, label }) => {
    return (
        <div className="choice-container mb-1">
            <input
                hidden
                className="form-check-input"
                type="checkbox"
                name="multipleChoiceAnswer"
                id={id}
                checked={checked}
                style={{
                    background: checked ? "#1798fa" : ""
                }}
            />
            <label
                className="form-check-label d-flex align-items-center"
                htmlFor={id}
            >
                <span
                    dangerouslySetInnerHTML={{
                        __html: label
                    }}
                ></span>
            </label>
        </div>
    );
};

export default CustomCheckbox;
