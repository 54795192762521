import React, { useState } from "react";
import { Row, Col, FormGroup, Input, Label, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { updateTaskSubject } from "../../../util/api";

const EditSubject = props => {
    const {
        match: {
            params: { id }
        },
        location: { data }
    } = props;
    const history = useHistory();
    const [nameSubject, setNameSubject] = useState(data.name);
    // const [description, setDescription] = useState(data.description);
    const handleSubmit = async id => {
        // call api update group
        const newSubject = {
            id,
            name: nameSubject,
            // description
        };
        await updateTaskSubject(newSubject);
        history.push(`/user-family/subjects`);
    };
    return (
        <>
            <div className="card-body px-3">
                <strong className="table-header-label">Edit Subject</strong>
            </div>
            <Row form>
                <Col md={5} className="p-3">
                    <FormGroup>
                        <Label>New Subject&apos;s Name</Label>
                        <Input
                            type="text"
                            autoFocus
                            value={nameSubject}
                            onChange={e => setNameSubject(e.target.value)}
                            invalid={false}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row form>
                <Col md={5} className="px-3">
                    <Button
                        className="px-4 pl-1 mr-2"
                        color="primary"
                        onClick={() => handleSubmit(id)}
                    >
                        Update
                    </Button>
                    <Button
                        onClick={() => {
                            history.push("/user-family/subjects");
                        }}
                        className="px-4 mr-2 ml-2"
                    >
                        Cancel
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default EditSubject;
