import React, { PureComponent } from 'react'
import { Row, Col } from 'reactstrap';
import CustomBtn from '../../../components/Button/CustomBtn';

export default class btns extends PureComponent {
    render() {
        const { viewOnly, isLocked, onPressAbsenceBtn, resetPassword, onPressSecretBtn, lockUser, resendToken, hasSecret, t } = this.props;
        return (
            <Row className="mb-2" >
                <Col md="6" xs="6" className="pr-md-1">
                    <CustomBtn
                        title={t("Absence_history")}
                        icon="fa fa-list"
                        color="secondary"
                        dark="true"
                        block
                        onClick={onPressAbsenceBtn}
                    />
                </Col>

                <Col md="6" xs="6" className="pl-md-1">
                    <CustomBtn
                        title={t("Reset_password")}
                        icon="fa fa-key"
                        color="secondary"
                        block
                        dark="true"
                        onClick={resetPassword}
                        disabled={viewOnly}
                    />
                </Col>

                <Col md="6" xs="6" className="pr-md-1 mt-2">
                    <CustomBtn
                        title={t("Secret")}
                        icon="fa fa-user-secret"
                        color={hasSecret ? "success" : "secondary"}
                        dark="true"
                        block
                        onClick={onPressSecretBtn}
                        disabled={viewOnly}
                    />
                </Col>

                <Col md="6" xs="6" className="pl-md-1 mt-2">
                    <CustomBtn
                        title={isLocked ? t("Unlock") : t("Lock")}
                        icon={isLocked ? "fa fa-unlock" : "fa fa-lock"}
                        color={isLocked ? "primary" : "danger"}
                        block
                        onClick={lockUser}
                        disabled={viewOnly}
                    />
                </Col>

                <Col md="6" xs="6" className="pr-md-1 mt-2">
                    <CustomBtn
                        title={t("resend_token")}
                        icon="fa fa-envelope"
                        color="success"
                        block
                        onClick={resendToken}
                        disabled={viewOnly}
                    />
                </Col>
            </Row>
        )
    }
}