import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    FormGroup,
    Input,
    Label,
    Button,
    Badge,
    Table
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { updateGroup } from "../../../util/api";

const EditGroup = (props) => {
    const {
        match: {
          params: { id },
        },
        location: {
          data
        }
      } = props;
    const history = useHistory();
    const [nameGroup, setNameGroup] = useState(data.name);
    const [description, setDescription] = useState(data.description);
    const handleSubmit = async (id) => {
        // call api update group
        const newGroup = {
          id,
          name: nameGroup,
          description
        }
        await updateGroup(newGroup)
        history.push(`/user-family/groups`)
    }
    return (<>
        <div className="card-body px-3">
            <strong className="table-header-label">Edit Group's Name</strong>
        </div>
        <Row form>
          <Col md={5} className="p-3">
            <FormGroup>
              <Label>
                New Group's Name
              </Label>
              <Input
                    type="text"
                    autoFocus
                    value={nameGroup}
                    onChange={e => setNameGroup(e.target.value)}
                    invalid={false}
                />

              <Label>
                New Description
              </Label>
              <Input
                    type="text"
                    autoFocus
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    invalid={false}
                />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
            <Col md={5} className="px-3">
                <Button 
                className="px-4 pl-1 mr-2" 
                color="primary"
                onClick={()=> handleSubmit(id)}
                >
                Update
                </Button>
                <Button
                onClick={() => {
                    history.push("/user-family/groups")
                }}
                className="px-4 mr-2 ml-2"
                >
                Cancel
                </Button>
            </Col>
        </Row>
        </>
    )
}

export default EditGroup