export default (state = {
    subscriptionList: [],
    totalItems: 0,
    currentPage: 1,
    pageCount: 1,
    pageSize: localStorage.getItem("subscriptionPageSize") ? Number(localStorage.getItem("subscriptionPageSize")) : 30
}, action) => {
    switch (action.type) {
        case "SET_SUBSCRIPTION_LIST":
            return {
                ...state,
                subscriptionList: action.payload,
                // currentPage: action.currentPage,
                sortBy: action.sortBy,
                sortOrder: action.sortOrder,
                totalItems: action.totalItems,
                pageCount: action.pageCount || 1,
            }
        case "CHANGE_PAGE_SIZE_SUBSCRIPTION":
            return {
                ...state,
                pageSize: action.payload
            }
        case "CHANGE_CURRENT_PAGE_SUBSCRIPTION":
            return {
                ...state,
                currentPage: action.payload
            }
        default:
            return state
    }
}