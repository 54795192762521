import React, { useEffect, useState, useTransition } from "react";
import classnames from "classnames";
import "./PreviewQuestion.scss";
import { useParams, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { FormGroup, Row, Col, Label, Button, Input, Container, Modal, ModalHeader, ModalBody } from "reactstrap";

import CustomModal from "../../../components/Modal/CustomModal";
import {
    approveQuestion,
    getCBTQuestionDetail,
    publishQuestionToCBT,
    markQuestionReadyForTest,
    confirmSalary,
    getCheckListComment
} from "../../../util/api";

import { htmlToDocx } from "../../../util/htmlToDocx";
import { QUESTION_TYPE_NUM } from "../../../util/Constant";
import CustomRadio from "../../../components/CustomRadio";
import CustomCheckbox from "../../../components/CustomCheckbox";
import RenderByRole from "../../../components/RenderByRole";
import useMutation from "../../../hooks/useMutation";
import useAlert from "../../../hooks/useAlert";
import useConfirm from "../../../hooks/useConfirm";
import Spinner from "../../../components/Spinner";
import RejectDialog from "./RejectDialog";
import Draggable from 'react-draggable';
import Select from "react-select";
import { sortAlphabet } from "../../../util/Common";
import useFetch from "../../../hooks/useFetch";

const PreviewQuestion = ({ t }) => {
    const { id: questionIdFromParam } = useParams();
    const history = useHistory();

    const { data: rawChecklist, isLoading: isFetchingCheckList} = useFetch(getCheckListComment, [], true)
    const [question, setQuestion] = useState(null);
    const [isOpenSolutionModal, setIsOpenSolutionModal] = useState(false);
    const [error, setError] = useState(null);
    const [isHideQuestion, setIsHideQuestion] = useState(false);
    const [isFetchingQuestionDetail, setIsFetchingQuestionDetail] = useState(
        true
    );
    const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);
    const [checklistStep, setChecklistStep] = useState(1);
    const [isShowChecklist, setIsShowChecklist] = useState(false); 
    const [isClickReady4Test, setIsClickReady4Test] = useState(false);
    const [indexParent, setIndexParent] = useState(0);
    const [indexChild, setIndexChild] = useState(0);

    const { showSuccess, showError } = useAlert();
    const { showConfirm } = useConfirm();
    const {
        mutate: handlePublishQuestionToCBT,
        isLoading: isPublishing
    } = useMutation({
        mutationFn: publishQuestionToCBT,
        onSuccess: () => {
            getQuestionDetail();
            showSuccess("Successfully!");
        },
        onError: err => {
            showError(err.response.data.Message);
        }
    });

    const { mutate: handleApprove, isLoading: isApproving } = useMutation({
        mutationFn: approveQuestion,
        onSuccess: () => {
            showSuccess("Successfully!");
        },
        onError: () => {
            showError("Failed: Cannot approve");
        }
    });
    const { mutate: handleMarkQuestionReadyForTest } = useMutation({
        mutationFn: markQuestionReadyForTest,
        onSuccess: () => {
            showSuccess("Publish question successfully");
        },
        onError: err => {
            showError("Failed: ", err.response.data.Message);
        }
    });
    
    const urlParams = new URLSearchParams(window.location.search);
    const origin = urlParams.get("origin");
    const action = urlParams.get("action");
    const taskId = urlParams.get("taskId")
    const getQuestionDetail = async () => {
        setIsFetchingQuestionDetail(true);
        try {
            const res = await getCBTQuestionDetail(questionIdFromParam);
            setQuestion(res.data);
        } catch (err) {
            setError("Question not found!");
        }
        setIsFetchingQuestionDetail(false);
    };
    const parentLevel1 = rawChecklist && rawChecklist.filter(item => item.parentId === 0)
    const checkListData = parentLevel1 && parentLevel1.map((record) => {
        const level2 = rawChecklist && rawChecklist.filter((item) => item.parentId === record.id)
        const child = level2.map((item)=> {
            const temp = rawChecklist && rawChecklist.find(e => e.parentId === item.id)
            return {...item, message: temp.title}
        })
        return {...record, child}
    })

    const lengthCheckList = checkListData && checkListData.reduce( (accumulator, currentValue) => accumulator + currentValue.child.length, 0)


    useEffect(() => {
        getQuestionDetail();
    }, []);

    const confirmExportToCbt = () => {
        showConfirm({
            message: "Are you sure you want to export questions to CBT?",
            onConfirm: () => {
                handlePublishQuestionToCBT(questionIdFromParam);
            }
        });
    };

    const confirmApprove = () => {
        showConfirm({
            message: "Are you sure you approve this question?",
            onConfirm: () => {
                handleApprove(questionIdFromParam, false);
            }
        });
    };

    const confirmPublish = async() => {
        setIsShowChecklist(true)
    }

    const renderArticleSide = () => {
        return (
            <div
                className={classnames("article-section", {
                    "col-md-12": isHideQuestion,
                    "col-md-6": !isHideQuestion
                })}
            >
                <div>
                    <h3>{question.article.name}</h3>
                </div>

                <div
                    dangerouslySetInnerHTML={{
                        __html: question.article.content
                    }}
                />

                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <div
                    className={classnames("right-arrow", {
                        "d-block": isHideQuestion,
                        "d-none": !isHideQuestion
                    })}
                    onClick={() => setIsHideQuestion(false)}
                >
                    <div />
                </div>
            </div>
        );
    };

    const renderSolutionModal = () => {
        return (
            <CustomModal
                t={t}
                size="lg"
                title="Question Solution"
                isOpen={isOpenSolutionModal}
                toggle={() => {
                    setIsOpenSolutionModal(false);
                }}
            >
                <Row className="mb-2">
                    <Col md={12} className="mb-2">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: question.solution
                            }}
                        />
                    </Col>
                </Row>
            </CustomModal>
        );
    };

    const SingleChoiceAnswerList = () => {
        return (
            <div>
                {question.singleChoiceAnswers.map((answer, index) => {
                    return (
                        <FormGroup tag="fieldset" row key={index}>
                            <Col sm={10}>
                                <Label
                                    check
                                    style={{
                                        fontSize: "16px",
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                >
                                    <CustomRadio
                                        checked={answer.checked}
                                        label={answer.text.replaceAll(
                                            "<p>&nbsp;</p>",
                                            ""
                                        )}
                                        // label={answer.text}
                                    />
                                </Label>
                            </Col>
                        </FormGroup>
                    );
                })}
            </div>
        );
    };

    const MultipleChoiceAnswerList = () => {
        return (
            <div id="ans-list">
                {question.multipleChoiceAnswers.map((answer, index) => {
                    return (
                        <CustomCheckbox
                            id={`multipleChoiceAnswer${index}`}
                            checked={answer.checked}
                            label={answer.text.replaceAll("<p>&nbsp;</p>", "")}
                            // label={answer.text}
                        />
                    );
                })}
            </div>
        );
    };

    const exportToWord = () => {
        let html = "";

        if (question.article && question.article.content) {
            html += question.article.name + "<br/> <br/>" 
            html += question.article.content + "<br />";
        }

        if (question.instruction){
            html += `<p><strong>Instruction:</strong> ${question.instruction}</p>`;
            html += "<br />";
        }

        html += question.questionText;

        html += "<br />";

        const answers =
            question.singleChoiceAnswers.length > 0
                ? question.singleChoiceAnswers
                : question.multipleChoiceAnswers;

        answers.forEach(ans => {
            html += ans.text;
        });

        html += `<br /><p><strong>Solution:</strong></p>${question.solution}`;

        htmlToDocx(html, `Doc_${Date.now()}.docx`);
    };

    if (error) {
        return <h3>{error}</h3>;
    }

    if (isFetchingQuestionDetail) {
        return "Loading...";
    }
    // const validateChecklist = () => {
    //     let isValid = false
    //     const arrayValidate = Object.values(validate)
        
    //     if (checklistContent.length === arrayValidate.length) {
    //         isValid = arrayValidate.every(i => i === true)
            
    //     }
    //     return isValid
    // }


    const handleConfirmCheckList = async() => {
        if (checklistStep !== lengthCheckList){
            setChecklistStep(checklistStep + 1)
        }
        if (indexChild < checkListData[indexParent].child.length - 1){
            setIndexChild(prev => prev + 1)
        }
        if (indexChild === checkListData[indexParent].child.length - 1 && indexParent < checkListData.length - 1){
            setIndexParent(prev => prev + 1)
            setIndexChild(0)
        }
    }

    // const handleConfirmCheckList = async() => {
    //     if (!validateChecklist()){
    //         const tempValidate = validate
    //         tempValidate[checklistStep] = true
    //         const newValidate = {...tempValidate}
    //         setValidate(newValidate)
    //         if (checklistStep !== checklistContent.length){
    //             setChecklistStep(checklistStep + 1)
    //         }
    //     } 
    //     else {
    //         if (!checklistDone){
    //             setChecklistDone(true)
    //         } 
    //     }
    // }
    const handlePublishCreator = async() => {
        setIsClickReady4Test(true)
        await handleMarkQuestionReadyForTest(questionIdFromParam);
        history.push(`/question-bank/my-task?id=${taskId}`);
    }
    
    const handleBackCheckList = () => {
        setChecklistStep(checklistStep - 1)
    }
    const handleNextCheckList = () => {
        setChecklistStep(checklistStep + 1)
    }   

    const renderModalChecklist = () => {
        return (
                <Draggable>
                    <div 
                        className="container checklist shadow rounded bg-light text-dark d-flex flex-column border p-0 justify-content-between"
                    >
                        <div className="checklist__header border-bottom p-3 d-flex align-items-center justify-content-between ">
                            <h5 style={{fontSize: "20px", marginBottom: 0, fontWeight: "500"}}>Check list before ready for test</h5>
                            <div className="close"
                                onClick={() => {
                                    setIsShowChecklist(false)
                                }}
                            >
                                &times;       
                            </div>
                        </div> 
                        <div className="px-3 py-2">
                            <div className="d-flex flex-column">
                                <div className="d-flex py-2">
                                    <div className="badge bg-primary mr-2" style={{fontSize: "16px", fontFamily: "Arial,Helvetica,sans-serif"}}>
                                        {checkListData && checkListData[indexParent].title}
                                    </div>
                                    <div className="badge bg-primary" style={{fontSize: "16px", fontFamily: "Arial,Helvetica,sans-serif"}}>
                                        {checkListData && checkListData[indexParent].child[indexChild].title}
                                    </div>
                                </div>
                                <span style={{fontSize: "16px", fontFamily: "Arial,Helvetica,sans-serif"}}>
                                    {`${checklistStep}. ${checkListData && checkListData[indexParent].child[indexChild].message}`}  
                                    {/* { checklistStep && validate[checklistStep] && <span>&#9989;</span>}  */}
                                </span>
                            </div>
                            
                        </div>
                        <div className="checklist__footer d-flex justify-content-end mt-auto p-3 border-top">
                            {/* <button
                                className="btn m-1 checklist__btn checklist__btn--secondary"     
                                onClick={handleBackCheckList}
                                disabled={checklistStep === 1}
                            >
                                Back
                            </button>
                            { checklistDone && <button
                                className="btn m-1 checklist__btn checklist__btn--primary"
                                onClick={handleNextCheckList}
                                disabled={checklistStep === checklistContent.length}
                            >
                                Next
                            </button>} */}
                            <button
                                className="btn m-1 checklist__btn checklist__btn--secondary" 
                                onClick={ () => {
                                        history.push(
                                            `/edit-question/${questionIdFromParam}`
                                        )
                                    }
                                }
                            >
                                No
                            </button>
                            {lengthCheckList !== checklistStep && <button
                                className="btn m-1 checklist__btn checklist__btn--primary"
                                onClick={handleConfirmCheckList}
                                // disabled={checklistStep === lengthCheckList + 1}
                            >
                                Yes
                            </button>}
                            {lengthCheckList === checklistStep && <button
                                className="btn m-1 checklist__btn checklist__btn--primary"
                                onClick={handlePublishCreator}
                                disabled={isClickReady4Test}
                            >
                                Ready for test
                            </button>}

                        </div>

                    </div>
                </Draggable>                
        )
    }

    return (
        <div id="preview-question-page" className="preview-question">
            <Spinner
                isLoading={
                    isPublishing || isFetchingQuestionDetail || isApproving
                }
            />

            <RejectDialog
                isOpen={isOpenRejectDialog}
                toggle={() => {
                    setIsOpenRejectDialog(!isOpenRejectDialog);
                }}
            />
            <div className="bg-image" />

            <div className="col-md-9 mx-auto main-content">
                <Label style={{fontSize: "16px", marginLeft: "25px", marginTop: "8px"}}><strong>Question Preview</strong></Label>
            
                <div className="row">
                    {isOpenSolutionModal && renderSolutionModal()}
                    {question.article && renderArticleSide()}

                    <div
                        className={classnames("question-content-section", {
                            "col-md-6": !!question.article,
                            "col-md-12": !question.article,
                            "d-none": isHideQuestion,
                            "d-block": !isHideQuestion
                        })}
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: question.instruction
                            }}
                            style={{
                                fontSize: "16px",
                                textAlign: "center",
                                marginBottom: "10px"
                            }}
                        />

                        <div>
                            <p
                                style={{ fontSize: "16px" }}
                                dangerouslySetInnerHTML={{
                                    __html: question.questionText
                                }}
                            ></p>
                        </div>

                        {question.type === QUESTION_TYPE_NUM.SINGLE_CHOICE ? (
                            <SingleChoiceAnswerList />
                        ) : (
                            <MultipleChoiceAnswerList />
                        )}

                        {question.solution && <div className="mt-4">
                            <h5>Solution: </h5>
                            <div
                                className="font14"
                                dangerouslySetInnerHTML={{
                                    __html: question.solution
                                }}
                            />
                        </div>}

                        {question.syllabus && <div className="mt-4">
                            <h5>Syllabus: </h5>
                            <div
                                className="font14"
                                dangerouslySetInnerHTML={{
                                    __html: question.syllabus
                                }}
                            />
                        </div>}

                        {question.purpose && <div className="mt-4">
                            <h5>Question purpose: </h5>
                            <div
                                className="font14"
                                dangerouslySetInnerHTML={{
                                    __html: question.purpose
                                }}
                            />
                        </div>}
                        

                        {question.article && (
                            <div
                                className="left-arrow"
                                onClick={() => setIsHideQuestion(true)}
                            >
                                <div />
                            </div>
                        )}
                    </div>
                    {isShowChecklist && renderModalChecklist()}
                    <div className="btn-view-solution col-md-12 mt-3 p-0">
                        {/* <div>
                            <button
                                onClick={() => setIsOpenSolutionModal(true)}
                                className="button-foot"
                            >
                                View Solution
                            </button>
                        </div> */}

                        {action === "checkToApprove" && <RenderByRole roles={["Proof reader"]}>
                            <div>
                                <button
                                    className="button-foot"
                                    onClick={() => {
                                        history.push(
                                            `/commentable-preview-question/${questionIdFromParam}`
                                        );
                                    }}
                                >
                                    Add comments
                                </button>
                                <button
                                    className="button-foot"
                                    onClick={() => {
                                        setIsOpenRejectDialog(true);
                                    }}
                                >
                                    Reject
                                </button>
                                <button
                                    className="button-foot"
                                    onClick={confirmApprove}
                                >
                                    Approve
                                </button>
                            </div>
                        </RenderByRole>}

                        <RenderByRole roles={["Admin"]}>
                            <div>
                                <button
                                    onClick={() => {
                                        exportToWord();
                                    }}
                                    className="button-foot"
                                >
                                    Export to word
                                </button>
                                <button
                                    onClick={confirmExportToCbt}
                                    className="button-foot"
                                >
                                    Export to CBT
                                </button>
                            </div>
                        </RenderByRole>

                        {(origin === "create-question" || action === "publish") && <RenderByRole roles={["Question creator"]}>
                            <div className="d-flex justify-content-end w-100">
                                <button
                                    onClick={() => {
                                        history.push(
                                            `/edit-question/${questionIdFromParam}`
                                        );
                                    }}
                                    className="button-foot"
                                >
                                    Edit
                                </button>
                                <button
                                    onClick={confirmPublish}
                                    className="button-foot"
                                >
                                    View Check List
                                </button>
                            </div>
                        </RenderByRole>}
                    </div>
                </div>
            </div> 
        </div>
    );
};

export default withTranslation("common")(PreviewQuestion);
