import {createAssessment, getAssessment, getAllAssessment, deleteAssessment, updateAssessment} from "../../../util/api"
import setLoading from "../../../components/Spinner/action"
import setModal from "../../../components/Modal/action"
import setAlert from "../../../components/Alert/action"

export const create = data => async dispatch => {
    dispatch(setLoading(true))
    try {
        const res = await createAssessment(data)
        if (!res.data) throw new Error("")

        dispatch(setAlert({
            type: "success",
            message: "assessment.create_success"
        }))

        dispatch(setLoading(false))
        return res.data
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: [err.response && err.response.data]
        }))
    }
    dispatch(setLoading(false))
}

export const update = data => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await updateAssessment(data)

        dispatch(setAlert({
            type: "success",
            message: "assessment.update_success"
        }))

        return res.data
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: [err.response && err.response.data]
        }))
    }

    dispatch(setLoading(false))
}

export const get = ({id, cb}) => async dispatch => {
    dispatch(setLoading(true))
    try {
        const res = await getAssessment(id)
        if (cb) cb(res.data)
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: [err.response && err.response.data, "get_assessment_error"]
        }))
    }

    dispatch(setLoading(false))
}


export const getAll = ({currentPage, pageSize, searchString, type, isInHouseTest}) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await getAllAssessment({currentPage, pageSize, searchString, type, isInHouseTest});
        if (!res.data) throw new Error("")

        dispatch({
            type: "SET_ASSESSMENT_LIST",
            payload: res.data.data,
            currentPage: res.data.currentPage,
            totalItems: res.data.totalItems,
            pageCount: res.data.pageCount,
        })
        if (!pageSize && !currentPage && !searchString && !type) {
            dispatch({
                type: "SET_FULL_ASSESSMENT_LIST",
                payload: res.data.data,
            })
        }
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: [err.response && err.response.data, "get_assessment_error"]
        }))
    }

    dispatch(setLoading(false))
}

export const removeAssessment = ({id, cb}) => async dispatch => {
    dispatch(setLoading(true))

    try {
        await deleteAssessment(id)

        if (cb) cb()
        dispatch(setAlert({
            type: "success",
            message: "assessment.delete_success"
        }))
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: [err.response && err.response.data, "assessment.delete_error"]
        }))
    }

    dispatch(setLoading(false))
}

export const changePageSizeAssessmentList = pageSize => async dispatch => {
    dispatch({
        type: "CHANGE_PAGE_SIZE_ASSESSMENT_LIST",
        payload: pageSize
    })
}