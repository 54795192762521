const modal = (
  state = {
    modal: {
      type: "primary",
      message: "",
      cb: null,
      label: null,
      cb1: null,
      cb2: null,
      cb3: null,
      noButton: false,
      type1: "primary",
      type2: "primary",
      label1: "",
      label2: "",
      dismiss: null,
      cancelText: null
    },
  },
  action,
) => {
  switch (action.type) {
    case "SET_MODAL":
      return {
        ...state,
        modal: {
          message: action.modal.message,
          type: action.modal.type || state.modal.type,
          cb: action.modal.cb,
          cb1: action.modal.cb1,
          cb2: action.modal.cb2,
          cb3: action.modal.cb3,
          noButton: action.modal.noButton || false,
          type1: action.modal.type1 || state.modal.type1,
          type2: action.modal.type2 || state.modal.type2,
          label1: action.modal.label1 || state.modal.label1,
          label2: action.modal.label2 || state.modal.label2,
          label: action.modal.label,
          dismiss: action.modal.dismiss || null,
          cancelText: action.modal.cancelText || null
        },
      };
    default:
      return state;
  }
};

export default modal;
