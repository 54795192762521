import React from 'react'
import {
    Card,
    CardBody,
    Col,
    Row,
    FormGroup,
    Label,
    Input,
    CustomInput,
    CardTitle,
    FormFeedback
} from "reactstrap";
import { canAccess, disableRole } from "../../../util/Common";

export default ({
    id,
    gender,
    viewOnly,
    onChangeGender,
    isActive,
    onChangeActive,
    userRoles,
    roles,
    select,
    receiveCourseInfo,
    onChangeReceiveCourseInfo,
    family,
    t
}) => {
    const genders = [
        { value: 1, display: t("Male") },
        { value: 2, display: t("Female") },
        { value: 0, display: t("Unknown") },
    ]

    const actives = [
        { value: true, display: t("Active") },
        { value: false, display: t("Non-active") },
    ]

    const availabeRoles = [
        { name: t("admin"), value: "Admin", roles: ["Admin"] },
        { name: t("Question creator"), value: "Question creator", roles: ["Admin"] },
        { name: t("Proofreader"), value: "Proof reader", roles: ["Admin"] },
        // { name: t("teacher"), value: "Teacher" },
        // { name: t("student"), value: "Student" },
    ]

    return (
        <Card style={{ marginTop: '19px', marginBottom: '2px' }}>
            <CardBody className="p-2 ">
                <CardTitle className="m-0"><strong>{t('More_information')}</strong></CardTitle>
                <Row>
                    {/* gender radio */}
                    <Col xl={4} md={6} xs={6} className='mb-2'>
                        <FormGroup>
                            <Label><strong>Gender</strong></Label>
                            {genders.map((v, i) => (
                                <FormGroup check key={`${i}gender`}>
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="gender"
                                            checked={gender.value === v.value}
                                            onChange={() => onChangeGender(v.value)}
                                            disabled={viewOnly}
                                        />{" "}
                                        {v.display}
                                    </Label>
                                </FormGroup>
                            ))}
                            {gender.invalid && (
                                <FormFeedback style={{ display: "flex", marginTop: 5 }}>
                                    {gender.errorMessage}
                                </FormFeedback>
                            )}
                        </FormGroup>
                    </Col>

                    {/* active status radio */}
                    <Col xl={4} md={6} xs={6}>
                        <FormGroup>
                            <Label><strong>Status</strong></Label>
                            {actives.map((v, i) => (
                                <FormGroup check key={`${i}active`}>
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="isActive"
                                            checked={isActive.value === v.value}
                                            onChange={() => onChangeActive(v.value)}
                                            disabled={viewOnly}
                                        />{" "}
                                        {v.display}
                                        <div />
                                    </Label>
                                </FormGroup>
                            ))}
                            {isActive.invalid && (
                                <FormFeedback style={{ display: "flex", marginTop: 5 }}>
                                    {isActive.errorMessage}
                                </FormFeedback>
                            )}
                        </FormGroup>
                    </Col>

                    {/* roles selected */}
                    <Col xl={12} md={12} xs={12}>
                        <Label><strong>Role</strong></Label>
                        <Row>
                            {availabeRoles.map((e, index) => {
                                return (
                                    <Col md={4}>
                                        <CustomInput
                                            key={`${index}`}
                                            type="checkbox"
                                            label={e.name}
                                            disabled={!canAccess(userRoles, e.roles) || viewOnly || disableRole(e.value, roles.value, id)}
                                            id={e.value}
                                            onClick={() => select(e.value)}
                                            checked={roles.value && roles.value.includes(e.value)}
                                        />
                                    </Col>
                                )
                            })}

                            {/* {roles.value && (roles.value.includes('Staff') || roles.value.includes("Admin")) && <Col md={4}>
                                <CustomInput
                                    type="checkbox"
                                    label={t("admin_salary")}
                                    disabled={viewOnly}
                                    id="admin-salary"
                                    onClick={() => select("Admin Salary")}
                                    checked={roles.value && roles.value.includes("Admin Salary")}
                                />
                            </Col>} */}

                            {roles.invalid && (
                                <FormFeedback style={{ display: "flex", paddingLeft: '15px' }} className="mt-1">
                                    {roles.errorMessage}
                                </FormFeedback>
                            )}
                        </Row>
                    </Col>
{/* 
                    {roles.value && roles.value.includes("Student") && family && family.value
                        ? <Col md={12} className="mt-3">
                            <CustomInput
                                type="checkbox"
                                label={t("receive_course_info")}
                                disabled={viewOnly}
                                id="receive-course-info"
                                onClick={e => onChangeReceiveCourseInfo(e)}
                                checked={receiveCourseInfo}
                            />
                        </Col> : null} */}
                </Row>
            </CardBody>
        </Card >
    )
}
