export default (state = {
    articles: [],
    allArticles: [],
    totalItems: 0,
    currentPage: 1,
    pageCount: 1,
    pageSize: localStorage.getItem("articlesPageSize") ? Number(localStorage.getItem("articlesPageSize")) : 30
}, action) => {
    switch (action.type) {
        case "SET_ARTICLE":
            return {
                ...state,
                articles: action.payload,
                currentPage: action.currentPage,
                sortBy: action.sortBy,
                sortOrder: action.sortOrder,
                totalItems: action.totalItems,
                pageCount: action.pageCount,
            }
        case "SET_ALL_ARTICLE":
            return {
                ...state,
                allArticles: action.payload
            }
        case "CHANGE_PAGE_SIZE_ARTICLE":
            return {
                ...state,
                pageSize: action.payload
            }
        default:
            return state
    }
}