/* eslint-disable import/no-cycle */
import localStorage from "localStorage";
import _ from "lodash";
import moment from "moment";
import { checkTokenApi, EXTRA_SALARY_CATEGORY_ENDPOINT } from './api';
import { ACCESS_TOKEN } from "./Constant";
import store from '../store'

export const getAccessToken = () => {
  try {
    return localStorage.getItem(ACCESS_TOKEN);
  } catch (err) {
    return null;
  }
};

export const getFirstDayOfWeek = curr => {
  const first = curr.getDate() - curr.getDay()
  return new Date(curr.setDate(first));
};

export const getLastDayOfWeek = curr => {
  const first = curr.getDate() - curr.getDay()
  const last = first + 6;
  return new Date(curr.setDate(last));
};

export const canAccess = (userRoles, componentRoles) => {
  if (!Array.isArray(userRoles)) {
    return false
  }
  if (Array.isArray(componentRoles)) {
    const intersects = userRoles.filter(i => componentRoles.includes(i))
    return intersects.length > 0
  }
  return true
}

export const disableRole = (userRoles, allRoles, id) => {
  if (userRoles === "Admin" && (allRoles.includes("Question creator") || allRoles.includes("Proof reader"))){
    return true
  }
  if (userRoles !== "Admin" && (allRoles.includes("Admin"))){
    return true
  }
  if (id && userRoles !== "Admin"){
    return false
  }
  return false
}

export const checkSpecialChars = (str) => {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(str);
}

export const firstCheckToken = async () => {
  const token = localStorage.getItem(ACCESS_TOKEN);
  if (token && typeof token === "string") {
    try {
      const _nToken = await checkTokenApi({ token });
      if (_nToken && _nToken.data) {
        localStorage.setItem(ACCESS_TOKEN, _nToken.data);
      } else {
        localStorage.removeItem(ACCESS_TOKEN);
      }
      return _.get(_nToken, "data", null);
    } catch (e) {
      localStorage.removeItem(ACCESS_TOKEN);
      return null;
    }
  }
  return null;
}

export const isTeacherOrAssistanceOnly = () => {
  const { common } = store.getState()
  const userInfo = common && common.userInfo
  const userRoles = userInfo && userInfo.roles

  return userRoles &&
    !userRoles.includes('Admin') &&
    !userRoles.includes('Staff') &&
    !userRoles.includes('Developer') &&
    (userRoles.includes('Teacher') || userRoles.includes('Assistance'))
}

export const isParentOrStudent = () => {
  const { common } = store.getState()
  const userInfo = common && common.userInfo
  const userRoles = userInfo && userInfo.roles

  return userRoles &&
    !userRoles.includes('Admin') &&
    !userRoles.includes('Staff') &&
    !userRoles.includes('Developer') &&
    !userRoles.includes('Teacher') &&
    !userRoles.includes('Assistance') &&
    (userRoles.includes('Parent') || userRoles.includes('Student'))
}

export const isAdminOrSalary = () => {
  const { common } = store.getState()
  const userInfo = common && common.userInfo
  const userRoles = userInfo && userInfo.roles
  return userRoles &&
    (userRoles.includes('Admin') || userRoles.includes('Admin Salary'))
}

export const isQuestionCreator = () => {
  const { common } = store.getState()
  const userInfo = common && common.userInfo
  const userRoles = userInfo && userInfo.roles
  return userRoles &&
    (userRoles.includes('Question creator'))
}

export const isProofReader = () => {
  const { common } = store.getState()
  const userInfo = common && common.userInfo
  const userRoles = userInfo && userInfo.roles
  return userRoles &&
    (userRoles.includes('Proof reader'))
}



export const uppercaseName = (name) => {
  return name[0].toUpperCase() + name.slice(1)
}

export const getNumberOfDate = date => {
  const arr = [
    parseInt(moment(date, "YYYY-MM-DD").format("YYYY")),
    parseInt(moment(date, "YYYY-MM-DD").format("MM")) - 1,
    parseInt(moment(date, "YYYY-MM-DD").format("DD")),
  ];
  return arr;
};

/**
 * 
 * @param {Array} arr 
 * @param {string} field 
 * @returns Array
 */
export const sortAlphabet = (arr = [], sortField = 'label') => {
  return arr.sort((a, b) => {
    if (a[sortField] < b[sortField]) {
      return -1;
    }
    if (a[sortField] > b[sortField]) {
      return 1;
    }
    return 0;
  })
}