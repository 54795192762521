import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import $ from 'jquery'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import common_en from "./translates/en/common.json"
import common_se from "./translates/se/common.json"
// import 'tinymce/plugins/image';

const WIRISpluginsScript = document.createElement('script')
WIRISpluginsScript.type = 'text/javascript'
WIRISpluginsScript.src = 'https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image'
document.head.appendChild(WIRISpluginsScript)

window.$ = $;
// window.tinymce = require('tinymce'); 

// require('@wiris/mathtype-tinymce6');

i18next.init({
    interpolation: { escapeValue: false },
    lng: localStorage.getItem('lang') || 'en',
    resources: {
        en: {
            common: common_en
        },
        se: {
            common: common_se
        },
    },
});


ReactDOM.render(
    <I18nextProvider i18n={i18next}>
        <App />
    </I18nextProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();