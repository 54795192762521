import React from "react";
import { withTranslation } from "react-i18next";

import CustomTabs from "../../../components/CustomTabs";
import Home from "./Home";
import TestSuitePayment from "../../TestSuitePayment/view/index";

const SubscriptionManagement = props => {
  const { t } = props;

  const tabs = [
    {
      path: "/subscription-management/subscriptions",
      title: t("subscriptions"),
      component: Home,
    },
    {
      path: "/subscription-management/payments",
      title: t("payments"),
      component: TestSuitePayment,
    },
  ];

  return <CustomTabs {...props} title={t("Subscriptions")} tabs={tabs} />;
};

export default withTranslation("common")(SubscriptionManagement);
