/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { memo, useState, useEffect } from 'react'
import _ from "lodash";

// headerComponentParams: { reGetData: this.getAll, sortable: true, sortOrder: this.state.sortOrder, sortBy: "Name" },

export default memo(function CustomAgHeader(props) {
    const [sortOrder, onSort] = useState(props.sortOrder || 'ASC');
    const [isCreate, endCreate] = useState(true);

    const colId = _.get(props, 'column.colId', "");
    const sortBy = colId.charAt(0).toUpperCase() + colId.slice(1);
    const onSortBy = () => {
        if (!props.sortable) return;
        endCreate(false);
        if (sortOrder === 'DESC') onSort('ASC')
        else onSort('DESC')
    };

    useEffect(() => {
        if (props.reSetState) props.reSetState(sortOrder, props.sortBy || sortBy)
        if (props.reGetData && !isCreate) props.reGetData(null, sortOrder, props.sortBy || sortBy);
    }, [sortOrder])

    return (
        <div onClick={onSortBy}>
            {props.displayName}
            {props.sortable && <i className="fa fa-sort ml-2" />}
        </div>
    )
})
