import React, { useEffect } from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Formik } from "formik";
import { sendEmailToChangePassword } from "../redux/actions";

// import { authRedirect } from "../../../util/common";

const signUpStyle = {
    width: "100%",
    borderRadius: "4px",
    backgroundColor: "#fff",
    padding: "16px 24px"
};

const fields = [{ name: "email", placeholder: "Email" }];

const initialValues = {
    email: ""
};

const schema = yup.object({
    email: yup
        .string()
        .email("Email is invalid.")
        .required("Email is required.")
});

function ForgotPassword(props) {
    useEffect(() => {
        // authRedirect()
    }, []);

    return (
        <>
            <div className="bg-gradient-info" style={{ height: "30vh" }} />
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(values, actions) => {
                    props.sendEmailToChangePassword(values);
                }}
            >
                {formikProps => (
                    <div
                        className="d-flex flex-column align-items-center"
                        style={{ marginTop: "-150px", padding: "0 32px" }}
                    >
                        <h2 className="text-center text-white">
                            FORGOT PASSWORD
                        </h2>
                        <Col md={5} style={signUpStyle}>
                            {fields.map((field, index) => (
                                <FormGroup key={index} className="mb-2">
                                    <Label>Email</Label>
                                    <Input
                                        type="text"
                                        name={field.name}
                                        placeholder={field.placeholder}
                                        value={formikProps.email}
                                        onChange={formikProps.handleChange}
                                        onBlur={formikProps.handleBlur}
                                        invalid={
                                            !!(
                                                formikProps.touched[
                                                    field.name
                                                ] &&
                                                formikProps.errors[field.name]
                                            )
                                        }
                                    />
                                    {formikProps.touched[field.name] &&
                                        formikProps.errors[field.name] && (
                                            <p
                                                className="text-danger mb-2"
                                                style={{ fontSize: "0.85em" }}
                                            >
                                                {formikProps.errors[field.name]}
                                            </p>
                                        )}
                                </FormGroup>
                            ))}

                            <FormGroup className="mb-2 mt-3">
                                <Button
                                    block
                                    color="primary"
                                    onClick={formikProps.handleSubmit}
                                >
                                    Send
                                </Button>
                            </FormGroup>

                            <Row>
                                <Col xs={6}>
                                    <FormGroup className="mb-2 mt-3">
                                        <Link block color="primary" to="/login">
                                            Back to login
                                        </Link>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                )}
            </Formik>
        </>
    );
}

const mapDispatchToProps = {
    sendEmailToChangePassword
};

export default connect(null, mapDispatchToProps)(ForgotPassword);
