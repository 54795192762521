import React, { useState, useEffect } from "react"
import { Row, Col, Input, Button } from "reactstrap"
import {CKEditor} from "ckeditor4-react"

const Coordinate = props => {
    const { isX, t, coordinateArr, onAddNewRow, onRemoveRow, onChangeTextRow } = props

    const [render, setRender] = useState(false)
    useEffect(() => {
        const timeout = setTimeout(() => setRender(true), 500)

        return () => clearTimeout(timeout)
    }, [coordinateArr])

    return <div className="mb-2">
        <Row>
            <Col md={12} className="d-flex align-items-center justify-content-between">
                <span>{t("add_coordinate", { direction: isX ? "X" : "Y" })}</span>
                <Button
                    color="primary"
                    size="sm"
                    style={{ fontSize: "0.8em" }}
                    onClick={onAddNewRow({ isX })}
                >
                    <i className="fa fa-plus mr-1" />
                    {t("add_new_row")}
                </Button>
            </Col>
        </Row>

        <hr className="my-2" />

        <Row>
            {render && coordinateArr && coordinateArr.map((item, index) => <React.Fragment key={item.id}>
                <Col md={12} className="d-flex align-items-start mb-2">
                    <h6 className="mr-2 font-weight-bold">{`${isX ? "X" : "Y"}${index + 1}:`}</h6>
                    <div className="flex-grow-1 mr-2">
                        <CKEditor
                            data={item.text}
                            onChange={e => setTimeout(() => {
                                onChangeTextRow({ text: e.editor.getData(), isX, id: item.id })
                            }, 100)}
                            config={{
                                allowedContent: true,
                                toolbar: [
                                    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic'] },
                                    { name: 'insert', items: ['Image'] },
                                ],
                                filebrowserImageUploadUrl: '/api/file/Upload?',
                                filebrowserUploadUrl: '/api/file/Upload?',
                            }}
                            onBeforeLoad={(CKEDITOR) => {
                                CKEDITOR.disableAutoInline = true
                                CKEDITOR.config.height = 75
                                CKEDITOR.config.toolbar = "default"
                                CKEDITOR.config.enterMode = CKEDITOR.ENTER_BR
                            }}

                        />
                    </div>

                    {coordinateArr.length > 1 && <Button
                        color="danger"
                        size="sm"
                        title={t("delete")}
                        onClick={onRemoveRow({ isX, id: item.id })}
                    >
                        <i className="fa fa-trash" />
                    </Button>}
                </Col>
                <Col md={6} />
            </React.Fragment>)}
        </Row>
    </div>
}

export default Coordinate