import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Table, Row, Col, Alert } from "reactstrap"
import { withTranslation } from "react-i18next"

import SearchBox from "../../../components/SearchBox"
import CreateBtn from "../../../components/Button/CreateBtn"
import DeleteBtn from "../../../components/Button/DeleteBtn"
import LinkEditBtn from "../../../components/Button/LinkEditBtn"
import CustomPagination from "../../../components/CustomPagination"

import setModal from "../../../components/Modal/action"

import {
    getAllSuite,
    changePageSizeTestSuite,
    changeCurrentPageTestSuite,
    removeSuite
} from "../redux/action"

const TestSuite = props => {
    const { t, testSuiteList, currentPage, pageSize, pageCount, totalItems } = props

    const [searchString, setSearchString] = useState("")
    const sortBy = "Name"
    const [sortOrder, setSortOrder] = useState("ASC")
    const [loaded, setLoaded] = useState(false)

    const getAll = async page => {
        const { currentPage, pageSize } = props
        await props.getAllSuite({
            currentPage: page || currentPage,
            pageSize,
            searchString,
            sortBy,
            sortOrder
        })

        if (page) {
            await props.changeCurrentPageTestSuite(page)
        }

        if (!loaded) {
            setLoaded(true)
        }
    }

    useEffect(() => {
        document.title = t("test_suite")
        getAll(1)
    }, [])

    useEffect(() => {
        if (loaded) {
            getAll(1)
        }
    }, [sortOrder])

    useEffect(() => {
        if (loaded) {
            getAll()
        }
    }, [currentPage])

    const onSort = () => {
        if (sortOrder === "ASC") {
            setSortOrder("DESC")
        } else {
            setSortOrder("ASC")
        }
    }

    const onDelete = id => {
        props.setModal({
            type: "danger",
            message: t("delete_test_suite_confirm"),
            cb: () => props.removeSuite(id, () => getAll(1))
        })
    }

    return <div>
        <Row className="mb-2">
            <Col md={8} className="d-flex">
                <div style={{ marginRight: "30px" }}>
                    <CreateBtn
                        title={t("create_suite")}
                        style={{ width: "120px", height: "32px" }}
                        onClick={() => props.history.push("/create-test-suite")}
                    />
                </div>
                <SearchBox
                    className="mr-0"
                    value={searchString}
                    onChange={e => setSearchString(e.target.value)}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            getAll(1);
                        }
                    }}
                />
            </Col>
        </Row>
        {totalItems
            ? <Row>
                <Col md={8} className="text-right">
                    <b>{t("total_test_suite")}: {totalItems}</b>
                </Col>
            </Row>
            : null}
        <Row className="mb-2">
            {testSuiteList && testSuiteList.length
                ? <Col md={8}>
                    <Table hover bordered striped responsive className="grid-table mb-2">
                        <thead>
                            <tr>
                                <th className="align-top">
                                    {t("test_suite_name")}
                                    {/* <i
                                        className="fa fa-sort float-right mt-1"
                                        style={{ cursor: 'pointer' }}
                                        onClick={onSort}
                                    /> */}
                                </th>
                                <th className="align-top">
                                    {t("description")}
                                </th>
                                <th className="align-top">
                                    {t("grade")}
                                </th>
                                <th className="text-center align-top">{t("action")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {testSuiteList.map((testSuite, index) => <tr key={index}>
                                <td>
                                    <LinkEditBtn
                                        noIcon
                                        onClick={() => props.history.push(`/edit-test-suite/${testSuite.id}`)}
                                    >
                                        {testSuite.name}
                                    </LinkEditBtn>
                                </td>
                                <td className="p-mb-0" dangerouslySetInnerHTML={{ __html: testSuite.description }} />
                                <td>
                                    {testSuite.grade}
                                </td>
                                <td className="text-center pl-3">
                                    <DeleteBtn
                                        className="d-inline mr-3"
                                        onClick={() => onDelete(testSuite.id)}
                                    />
                                </td>
                            </tr>)}
                        </tbody>
                    </Table>
                </Col>
                : <Col md={8} sm={12}>
                    <Alert color="primary" className="mb-0">{t("no_test_suite_to_display")}</Alert>
                </Col>}
        </Row>

        <Row className="mb-2">
            <Col md={8} className="text-right">
                <CustomPagination
                    pageCount={pageCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    pageSizeName="testSuitePageSize"
                    changePage={page => {
                        props.changeCurrentPageTestSuite(page);
                    }}
                    changePageSize={props.changePageSizeTestSuite}
                    onChangePageSize={() => getAll(1)}
                />
            </Col>
        </Row>
    </div>
}

const mapStateToProps = state => ({
    testSuiteList: state.testSuite.testSuiteList,
    currentPage: state.testSuite.currentPage,
    pageCount: state.testSuite.pageCount,
    pageSize: state.testSuite.pageSize,
    totalItems: state.testSuite.totalItems
})

const mapDispatchToProps = {
    setModal,
    getAllSuite,
    changePageSizeTestSuite,
    changeCurrentPageTestSuite,
    removeSuite
}

const TestSuiteHOC = withTranslation("common")(TestSuite)

export default connect(mapStateToProps, mapDispatchToProps)(TestSuiteHOC)