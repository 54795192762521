import { HIDE_CONFIRM, SHOW_CONFIRM } from "./constants";

const confirmReducer = (
    state = {
        isShowConfirm: false,
        onConfirm: () => {},
        onReject: () => {},
        message: ""
    },
    action
) => {
    const { type, payload } = action;

    switch (type) {
        case SHOW_CONFIRM:
            return {
                isShowConfirm: true,
                onConfirm: payload.onConfirm,
                onReject: payload.onReject,
                message: payload.message
            };

        case HIDE_CONFIRM:
            return {
                isShowConfirm: false,
                onConfirm: () => {},
                onReject: () => {},
                message: ""
            };

        default:
            return state;
    }
};

export default confirmReducer;
