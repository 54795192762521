import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Login from ".";
import { login } from "../redux/actions";
import setAlert from "../../../components/Alert/action";

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login,
      setAlert,
    },
    dispatch,
  );

export default connect(
  null,
  mapDispatchToProps,
)(Login);
