import React, { memo } from 'react'
import { Button } from 'reactstrap'

export default memo(function CustomBtn(props) {
    return (
        <Button
            color={props.color || "success"}
            onClick={props.onClick}
            style={{ height: 32, textOverflow: 'ellipsis', display: "block", wordWrap: "breakWord", overflow: "hidden", maxHeight: "3.6em", lineHeight: "1.8em" }}
            {...props}
        >
            {props.icon && <i className={props.icon} style={{ fontSize: 12, color: props.dark ? '#23282c' : '#fff' }} />} {props.title}
        </Button>
    )
    
})
