import localStorage from "localStorage";

import {
    apiUpload,
    api,
    getEmployee,
    getCustomer,
    syncToFortnoxCusomterApi,
    syncToFortnoxEmployeeApi,
    getImportStudents,
    FILE,
    TEACHER_ENDPOINT,
    TEACHER_CONTROLLER_ENDPOINT
} from "../../../util/api";
import setLoading from "../../../components/Spinner/action";
import setModal from "../../../components/Modal/action";
import setAlert from "../../../components/Alert/action";
import { getAccessToken } from "../../../util/Common";
import { USER_EMAIL } from "../../../util/Constant";

export const uploadFile = (data) => async dispatch => {
    dispatch(setLoading(true));
    try {
        const response = await apiUpload.post(`${FILE}/uploadAvatar`, data);
        if (Array.isArray(response.data)) {
            const message = response.data.map(e => e.value);
            dispatch(
                setModal({
                    type: "danger",
                    message,
                })
            );
            dispatch(setLoading(false));
            return;
        }
        dispatch(setLoading(false));
        return response.data;
    } catch (error) {

    }
    dispatch(setLoading(false));
};

export const uploadFiles = (data) => async dispatch => {
    dispatch(setLoading(true));
    try {
        const response = await apiUpload.post(`${FILE}/uploadFiles`, data);
        dispatch(setLoading(false));
        return response.data;
    } catch (error) {
        console.log(error.message)
    }
    dispatch(setLoading(false));
};

export const checkUserOnFortnox = (type, id) => async dispatch => {
    dispatch(setLoading(true));
    try {
        let response = null;
        if (type === 'customer') {
            response = await getCustomer(id)
        } else if (type === 'employee') {
            response = await getEmployee(id)
        }

        if (response.data) {
            dispatch(setLoading(false));
            return response.data;
        }
        dispatch(setLoading(false));
        return null;
    } catch (error) {

    }
    dispatch(setLoading(false));

    return null;
};

export const syncToFortnox = (type, data) => async dispatch => {
    dispatch(setLoading(true));
    try {
        let response = null;
        if (type === 'customer') {
            response = await syncToFortnoxCusomterApi(data)
        } else if (type === 'employee') {
            response = await syncToFortnoxEmployeeApi(data)
        }
        if (Array.isArray(response.data)) {
            const message = response.data.map(e => e.value);
            dispatch(
                setModal({
                    type: "danger",
                    message,
                })
            );
            dispatch(setLoading(false));
            return false;
        }
        if (response.data) {
            dispatch(setAlert({
                type: "success",
                message: `sync_user_to_fortnox_success`,
            }))
        }
        dispatch(setLoading(false));
        return true;
    } catch (error) {
        dispatch(
            setModal({
                type: "danger",
                message: "cant_sync_fortnox",
            })
        );
    }
    dispatch(setLoading(false));
    return false;
};

export const checkPassword = (data, id, cb) => async dispatch => {
    apiUpload.defaults.headers.common["Authorization"] = `Bearer ${getAccessToken()}`;
    const email = localStorage.getItem(USER_EMAIL)
    const submitData = { ...data, email }
    dispatch(setLoading(true));
    try {
        const response = await api.post(`${TEACHER_ENDPOINT}/checkpasswordforsecretnote/?userId=${id}`, submitData);
        if (Array.isArray(response.data)) {
            const message = response.data.map(e => e.value);
            dispatch(
                setModal({
                    type: "danger",
                    message,
                })
            );
            dispatch(setLoading(false));
            return;
        }
        if (response.data !== null) {
            dispatch(setAlert({
                type: "success",
                message: `password_correct`,
                cb: null,
            }))
            if (cb) cb(response.data);
        }
    } catch (error) {
    }
    dispatch(setLoading(false));
};

export const updateSecretNote = (data, id, cb) => async dispatch => {
    apiUpload.defaults.headers.common["Authorization"] = `Bearer ${getAccessToken()}`;
    const email = localStorage.getItem(USER_EMAIL)
    const submitData = { ...data, email }
    dispatch(setLoading(true));
    try {
        const response = await api.post(`${TEACHER_ENDPOINT}/updatesecretnote/?userId=${id}`, submitData);
        if (response.data !== null) {
            dispatch(setAlert({
                type: "success",
                message: `update_secret_success`,
                cb: null,
            }))
            if (cb) cb(response.data);
        }
    } catch (error) {
    }
    dispatch(setLoading(false));
};

export const toggleEmployTeacher = (id, cb) => async dispatch => {
    dispatch(setLoading(true));
    try {
        const response = await api.post(`${TEACHER_CONTROLLER_ENDPOINT}/toggleemploy/${id}`);
        if (response.data) {
            if (response.data) {
                if (Array.isArray(response.data)) {
                    const message = response.data.map(e => e.value);
                    dispatch(
                        setModal({
                            type: "danger",
                            message,
                        }),
                    );
                    dispatch(setLoading(false));
                    return;
                }
                if (cb) {
                    cb(response.data);
                }
            }

        }
    } catch (error) { }
    dispatch(setLoading(false));
};

export const getAllImportStudents = ({ currentPage, pageSize, searchString, sortBy, sortOrder, isDone }) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await getImportStudents({ currentPage: currentPage - 1, pageSize, searchString, sortBy, sortOrder, isDone })
        dispatch({
            type: "SET_USER_LIST",
            payload: res.data.data || [],
            importedCurrentPage: res.data.currentPage + 1,
            pageCount: res.data.pageCount,
            totalItems: res.data.totalItems,
            importedPageSize: res.data.pageSize
        })
        dispatch(setLoading(false))
    } catch (err) {

    }

    dispatch(setLoading(false))
}

export const resendMailToken = id => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await api.post(`${TEACHER_ENDPOINT}/resendToken?id=${id}`);
        if (res.data) {
            if (Array.isArray(res.data)) {
                const message = res.data.map(e => e.value);
                dispatch(
                    setModal({
                        type: "danger",
                        message,
                    }),
                );
            } else {
                dispatch(setModal({
                    type: "success",
                    message: ["resend_token_success"]
                }))
            }
        }
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: ["resend_token_failed"]
        }))
    }

    dispatch(setLoading(false))
}

export const changePassword = ({ oldPassword, newPassword }, cb) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await api.post(`${TEACHER_ENDPOINT}/changePassword`, { oldPassword, newPassword })

        if (res.data) {
            if (Array.isArray(res.data)) {
                const message = res.data.map(e => e.value);
                dispatch(
                    setModal({
                        type: "danger",
                        message,
                    }),
                );
            } else {
                dispatch(setModal({
                    type: "success",
                    message: ["change_password_success"]
                }))

                if (cb) cb()
            }
        }
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: err.message
        }))
    }

    dispatch(setLoading(false))
}