import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import DatePicker from "react-datepicker"
import { Input, CustomInput, Row, Col, Button, Modal, ModalHeader, ModalBody, Label, Alert, Table } from "reactstrap"
import moment from "moment"
import _ from "lodash"

import ErrorHandler from "../../../components/ErrorHandler"
import UserSelected from "../../../components/Selector/UserSelected"
import LabelRequired from "../../../components/LabelRequired"
import LinkEditBtn from "../../../components/Button/LinkEditBtn"
import TestSuiteSelector from '../../../components/Selector/TestSuiteSelector'

import setModal from "../../../components/Modal/action"
import { createSub, updateSub, getSub } from "../redux/action"

import { SERVER_DATE_FORMAT } from "../../../util/Constant"

const SubscriptionDetail = props => {
    const { t, id, isOpen, toggle, testSuiteId, callback, currentTestSuite } = props

    const [startTime, setStartTime] = useState(null)
    const [endTime, setEndTime] = useState(null)
    const [student, setStudent] = useState(null)
    const [isActive, setIsActive] = useState(true)
    const [payments, setPayments] = useState([])
    const [testSuite, setTestSuite] = useState(null)

    const [checkRequired, setCheckRequired] = useState(false)

    const reset = () => {
        setStartTime(null)
        setEndTime(null)
        setStudent(null)
        setIsActive(true)
        setPayments([])
        setCheckRequired(false)
    }

    useEffect(() => {
        if (id) {
            getData()
        } else {
            reset()
        }
    }, [id])

    const getData = async () => {
        try {
            const res = await props.getSub(id)
            setStartTime(moment(res.data.startTime).toDate())
            setEndTime(moment(res.data.endTime).toDate())
            setStudent(res.data.student)
            setIsActive(res.data.isActive)
            setPayments(res.data.payments)
        } catch (err) {
            props.setModal({
                type: "danger",
                message: err.message
            })
        }
    }

    const onSubmit = () => {
        try {
            setCheckRequired(true)

            if (!startTime || !endTime || !student) return

            const start = new Date(startTime).getTime()
            const end = new Date(endTime).getTime()

            if (start > end) {
                props.setModal({
                    type: "danger",
                    message: t("end_time_cant_equal_start_time")
                })

                return
            }

            const data = {
                startTime: moment(startTime).format(SERVER_DATE_FORMAT),
                endTime: moment(endTime).format(SERVER_DATE_FORMAT),
                isActive
            }

            if (id) {
                data.id = id
            } else {
                data.studentId = student.id
                data.testSuiteId = testSuiteId || testSuite.value
            }

            const func = id ? props.updateSub : props.createSub

            func(data, () => {
                toggle()
                callback()
            })
        } catch (err) {
            props.setModal({
                type: "danger",
                message: err.message
            })
        }
    }

    return <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
            <Row>
                <Col md={12}>
                    <h5 className="mb-0">{id ? t("edit_subscription") : t("create_subscription")}</h5>
                </Col>
            </Row>
        </ModalHeader>
        <ModalBody>
            <Row>
                <Col md={6} className="mb-2">
                    <LabelRequired text={t("start_time")} />
                    <DatePicker
                        todayButton={t('today')}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        value={startTime && moment(startTime).format("L")}
                        selected={startTime}
                        onChange={e => setStartTime(e)}
                        placeholderText={t('start_time')}
                        customInput={
                            <Input
                                invalid={false}
                            />
                        }
                        locale="en-gb"
                    />
                    {checkRequired && !startTime && <ErrorHandler text={t("start_time_empty")} />}
                </Col>
                <Col md={6} className="mb-2">
                    <LabelRequired text={t("end_time")} />
                    <DatePicker
                        todayButton={t('today')}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        value={endTime && moment(endTime).format("L")}
                        selected={endTime}
                        onChange={e => setEndTime(e)}
                        placeholderText={t('end_time')}
                        customInput={
                            <Input
                                invalid={false}
                            />
                        }
                        locale="en-gb"
                    />
                    {checkRequired && !startTime && <ErrorHandler text={t("end_time_empty")} />}
                </Col>
            </Row>
            <Row className="mb-2">
                {!id
                    ? <Col md={12}>
                        {<UserSelected
                            require
                            userRole="Student"
                            includeEmail
                            important
                            value={student}
                            onChange={e => setStudent(e)} />}
                        {checkRequired && !student && <ErrorHandler text={t("student_empty")} />}
                    </Col>
                    : <Col md={12}>
                        <LabelRequired text={t("student")} /><br />
                        <LinkEditBtn
                            label={_.get(student, "name", "")}
                            onClick={() => window.open(`/user/edit-user/${_.get(student, "identityId", 0)}`)}
                        />
                    </Col>}
            </Row>
            <Row className="mb-2">
                {!id
                    ? <Col md={12}>
                        {!testSuiteId && <TestSuiteSelector
                            label={t("test_suites")}
                            require
                            isNotMulti
                            value={testSuite}
                            onChange={e => setTestSuite(e)}
                        />}
                        {checkRequired && !testSuite && !testSuiteId && <ErrorHandler text={t("test_suite_empty")} />}
                    </Col>
                    : <Col md={12}>
                        {!testSuiteId && <>
                            <LabelRequired text={t("test_suites")} /><br />
                            <LinkEditBtn
                                label={_.get(currentTestSuite, "name", "")}
                                onClick={() => window.open(`/edit-test-suite/${_.get(currentTestSuite, "id", 0)}`)}
                            />
                        </>}
                    </Col>}
            </Row>
            {!testSuiteId && <Row className="mb-2">
                <Col md={12}>
                    <CustomInput
                        type="checkbox"
                        id="is_active_subscription_checkbox"
                        label={t("is_active")}
                        checked={isActive}
                        onChange={e => setIsActive(!isActive)}
                    />
                </Col>
            </Row>}
            {!!id && <Row className="mb-2">
                <Col md={12}>
                    <Label>{t("payments")}</Label>
                    {payments && !!payments.length
                        ? <Table hover bordered striped responsive className="grid-table mb-2">
                            <thead>
                                <tr>
                                    <th>{t("amount")}</th>
                                    <th>{t("paid_date")}</th>
                                    <th>{t("pricing_plan")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {payments.map((payment, index) => <tr key={index}>
                                    <td>{payment.amount}</td>
                                    <td>{moment(payment.createdAt).format("L")}</td>
                                    <td>{_.get(payment, "pricingPlan.name", "")}</td>
                                </tr>)}
                            </tbody>
                        </Table>
                        : <Alert color="primary">{t("no_payments_to_display")}</Alert>}
                </Col>
            </Row>}
            <Row className="mb-2">
                <Col md={12}>
                    <Button
                        className="mr-2"
                        color="success"
                        onClick={onSubmit}
                    >
                        {id ? t("update") : t("create")}
                    </Button>
                    <Button
                        onClick={toggle}
                    >
                        {t("cancel")}
                    </Button>
                </Col>
            </Row>
        </ModalBody>
    </Modal>
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {
    setModal,
    createSub,
    updateSub,
    getSub
}

const SubscriptionDetailHOC = withTranslation("common")(SubscriptionDetail)

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionDetailHOC)