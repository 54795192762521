import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import "./CommentableQuestionPreview.scss";
import { useHistory, useParams } from "react-router-dom";
import { Row, Col, Container, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Editor } from "@tinymce/tinymce-react";
import setLoading from "../../../components/Spinner/action";
import {
    getCommentablePreviewQuestion,
    updateQuestionHtml,
    getComments,
    addComment,
    approveQuestion,
    getCheckListComment,
    deleteComment
} from "../../../util/api";
import RejectDialog from "./RejectDialog";
import useConfirm from "../../../hooks/useConfirm";
import useMutation from "../../../hooks/useMutation";
import useAlert from "../../../hooks/useAlert";
import useFetch from "../../../hooks/useFetch";
import { TINY_MCE_API_KEY } from "../../MyTask/ProwritingEditor";
import CommentBox from "./CommentBox";
import PreviewQuestionProofreader from "../../../components/PreviewQuestionProofreader";

const filterText = node => {
    if (
        node.nodeType === Node.TEXT_NODE ||
        node.tagName.toUpperCase() === "IMG"
    ) {
        return NodeFilter.FILTER_ACCEPT;
    }
    return NodeFilter.FILTER_SKIP;
};

const getTextNodesUnder = el => {
    const textNodes = [];
    const walk = document.createTreeWalker(
        el,
        NodeFilter.SHOW_ALL,
        filterText,
        false
    );
    while (walk.nextNode()) {
        textNodes.push(walk.currentNode);
    }
    return textNodes;
};

const createHighLightNode = (text, id) => {
    const span = document.createElement("span");
    span.style.backgroundColor = "#ff4122"; // '#FFFF76'//'#B4D7FF'
    span.style.userSelect = "none";
    span.setAttribute("type", "highlight");
    span.setAttribute("highlightId", id);
    span.innerHTML = text;
    return span;
};

const AREAS = {
    0: "question_text",
    1: "answer",
    2: "solution"
};

const CommentableQuestionPreview = () => {
    const { questionId } = useParams();
    const editorRef = useRef(null);
    const areaSelect = useRef();
    const proofreaderCBTQuestionIdRef = useRef(0);
    const dispatch = useDispatch();
    const history = useHistory();
    const [question, setQuestion] = useState();
    const [questionHtml, setQuestionHtml] = useState({
        question_text: "",
        answer: "",
        solution: ""
    });
    const [currentHighLightedText, setCurrentHighLightedText] = useState("");
    const [initQuestionHtml, setInitQuestionHtml] = useState("");
    const [commentInput, setCommentInput] = useState("");
    const [comments, setComments] = useState([]);
    const currentHighlightIdRef = useRef("");
    const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);
    const { showSuccess, showError } = useAlert();
    const [isHandled, setIsHandled] = useState(false);
    const { data: rawChecklist, isLoading: isFetchingCheckList } = useFetch(
        getCheckListComment,
        [],
        true
    );

    const canHighlightRef = useRef(true);

    const fetchComments = async () => {
        if (!proofreaderCBTQuestionIdRef.current) return;
        try {
            const res = await getComments(proofreaderCBTQuestionIdRef.current);
            setComments(res.data);
        } catch (err) {}
    };

    const { mutate: handleAddComment } = useMutation({
        mutationFn: addComment,
        onSuccess: () => {
            fetchComments();
            setCommentInput("");
            setCommentBox({
                isShow: false,
                top: 0,
                left: 0
            });
        },
        onError: () => {
            showError("Failed: Cannot add comment");
        }
    });
    const [commentBox, setCommentBox] = useState({
        isShow: false,
        top: 0,
        left: 0
    });

    const toggleRejectDialog = () => {
        setIsOpenRejectDialog(!isOpenRejectDialog);
    };

    const getPreviewData = async () => {
        try {
            dispatch(setLoading(true));
            const res = await getCommentablePreviewQuestion(questionId);
            setIsHandled(
                +res.data.status !== 0 || (+res.data.status === 0 && !!res.data.rejectionReason)
            );
            canHighlightRef.current = !res.data.rejectionReason && !res.data.status;
            const newQuestionHtml = `
                <div>${
                    res.data.questionInfo.article
                        ? res.data.questionInfo.article.content
                        : ""
                }</div>
                <div>${res.data.questionInfo.instruction}</div>
                <div class='mb-3'>
                    ${res.data.questionInfo.questionText}
                </div>
            `;
            if (res.data.questionHighlightHtml) {
                setQuestionHtml(
                    JSON.parse(
                        res.data.questionHighlightHtml.replaceAll(
                            "rgb(255, 255, 23)",
                            "rgb(180, 215, 255)"
                        )
                    )
                );
            } else {
                // init new
                setInitQuestionHtml(newQuestionHtml);
            }
            proofreaderCBTQuestionIdRef.current = res.data.proofreadCBTQuestionId;
            setComments(res.data.comments);
            setQuestion(res.data);

            setTimeout(() => {
                const questionAreaNode = document.querySelectorAll(
                    ".can-highlight"
                );
                if (questionAreaNode) {
                    questionAreaNode.forEach((qsArea, index) => {
                        qsArea.addEventListener("mouseup", e =>
                            onMouseUpInSideQuestionArea(e, index)
                        );
                    });
                }
            }, 0);
        } catch (err) {
            console.log(err);
        }

        dispatch(setLoading(false));
    };

    const saveQuestionHtml = async htmlString => {
        if (!proofreaderCBTQuestionIdRef.current) return;
        try {
            await updateQuestionHtml({
                proofreadCBTQuestionId: proofreaderCBTQuestionIdRef.current,
                questionHtml: htmlString
            });
        } catch (err) {}
    };

    const onMouseUpInSideQuestionArea = (mouseEvent, area) => {
        const selection = document.getSelection();
        if (!canHighlightRef.current) {
            selection.removeAllRanges();
            return;
        }
        if (!selection) return;
        if (!selection.toString()) return;
        const { anchorNode, focusNode } = selection;
        const currentTextNode = selection.anchorNode.nodeValue;
        const { anchorOffset, focusOffset } = selection;
        if (
            anchorNode.data.slice(anchorOffset, focusOffset) === selection.toString() ||
            anchorNode.data.slice(focusOffset, anchorOffset) === selection.toString()
        ) {
            // let node = anchorNode
            // while(node) {
            //     if (node.className && node.className.includes("answer")) {
            //         selection.removeAllRanges();
            //         return
            //     }
            //     node = node.parentNode
            // }
            const left =
                focusOffset > anchorOffset
                    ? currentTextNode.slice(0, anchorOffset)
                    : currentTextNode.slice(0, focusOffset);
            const right =
                focusOffset > anchorOffset
                    ? currentTextNode.slice(focusOffset)
                    : currentTextNode.slice(anchorOffset);
            const selected =
                focusOffset > anchorOffset
                    ? currentTextNode.slice(anchorOffset, focusOffset)
                    : currentTextNode.slice(focusOffset, anchorOffset);
            const parentSpan = document.createElement("span");
            parentSpan.appendChild(document.createTextNode(left));
            const highlightId = uuidv4();
            parentSpan.appendChild(createHighLightNode(selected, highlightId));
            parentSpan.appendChild(document.createTextNode(right));
            anchorNode.parentNode.replaceChild(parentSpan, anchorNode);

            currentHighlightIdRef.current = highlightId;
            const questionAreaNode = document.querySelectorAll(
                ".can-highlight"
            )[area];
            const newQuestionHtml = questionAreaNode.innerHTML
                .replaceAll("rgb(255, 255, 23)", "rgb(180, 215, 255)")
                .replaceAll("rgb(255, 65, 34)", "rgb(255, 255, 23)");
            setQuestionHtml(pre => {
                console.log('Premium', pre)
                saveQuestionHtml(
                    JSON.stringify({
                        ...pre,
                        [AREAS[area]]: newQuestionHtml
                    })
                );

                return {
                    ...pre,
                    [AREAS[area]]: newQuestionHtml
                }
            });
            setCurrentHighLightedText(selected);

            setCommentBox({
                isShow: true,
                top: mouseEvent.clientY + 20,
                left: mouseEvent.clientX - 125
            });
            return;
        }

        let questionAreaNode = document.querySelectorAll(".can-highlight")[area];
        const texts = getTextNodesUnder(questionAreaNode);
        let anchorIndex = -1;
        let focusIndex = -1;

        for (let i = 0; i < texts.length - 1; i++) {
            if (texts[i] === anchorNode) {
                anchorIndex = i;
            }

            if (texts[i] === focusNode) {
                focusIndex = i;
            }
        }

        if (anchorIndex === -1) {
            // anchor is img
            for (let i = 0; i < texts.length - 1; i++) {
                if (
                    anchorNode.contains(texts[i]) &&
                    texts[i].nodeType !== Node.TEXT_NODE
                ) {
                    anchorIndex = i;
                }
            }
        }

        if (focusIndex === -1) {
            // focus is img
            for (let i = 0; i < texts.length - 1; i++) {
                if (
                    focusNode.contains(texts[i]) &&
                    texts[i].nodeType !== Node.TEXT_NODE
                ) {
                    focusIndex = i;
                }
            }
        }

        const startOffset =
            focusIndex > anchorIndex ? anchorOffset : focusOffset;
        const endOffset = focusIndex > anchorIndex ? focusOffset : anchorOffset;
        const startIndex = focusIndex > anchorIndex ? anchorIndex : focusIndex;
        const endIndex = focusIndex > anchorIndex ? focusIndex : anchorIndex;
        let highlightText = "";

        // Check duplicate highlight
        for (let i = startIndex; i <= endIndex; i++) {
            if (
                !!texts[i] &&
                texts[i].parentElement.getAttribute("type") === "highlight"
            ) {
                selection.removeAllRanges();
                return;
            }
        }

        // Check highlight contains answer
        // for (let i = startIndex; i <= endIndex; i++) {
        //     if (!texts[i]) return
        //     let node = texts[i]
        //     while(node) {
        //         if (node.className && node.className.includes("answer")) {
        //             selection.removeAllRanges();
        //             return
        //         }
        //         node = node.parentNode
        //     }
        // }

        const highlightId = uuidv4();
        for (let i = startIndex; i <= endIndex; i++) {
            if (!texts[i]) {
                continue;
            }
            if (texts[i].tagName === "IMG") continue;
            let selected = texts[i].nodeValue;
            if (i === startIndex) {
                selected = texts[i].nodeValue.slice(startOffset);
                const left = texts[i].nodeValue.slice(0, startOffset);
                const parentSpan = document.createElement("span");
                parentSpan.appendChild(document.createTextNode(left));
                parentSpan.appendChild(
                    createHighLightNode(selected, highlightId)
                );
                texts[i].parentNode.replaceChild(parentSpan, texts[i]);
                highlightText += selected;
                continue;
            }

            if (i === endIndex) {
                selected = texts[i].nodeValue.slice(0, endOffset);
                const right = texts[i].nodeValue.slice(endOffset);
                const parentSpan = document.createElement("span");
                parentSpan.appendChild(
                    createHighLightNode(selected, highlightId)
                );
                parentSpan.appendChild(document.createTextNode(right));
                texts[i].parentNode.replaceChild(parentSpan, texts[i]);
                highlightText += selected;
                continue;
            }

            highlightText += selected;
            const newNode = createHighLightNode(selected, highlightId);
            const forAppendNode = document.querySelector(".for-append");
            forAppendNode.append(newNode);
            const shouldBeIgnored =
                forAppendNode.firstElementChild.getBoundingClientRect()
                    .width === 0;
            forAppendNode.removeChild(forAppendNode.firstChild);
            if (!shouldBeIgnored) {
                texts[i].parentNode.replaceChild(newNode, texts[i]);
            }
        }

        questionAreaNode = document.querySelectorAll(".can-highlight")[area];
        currentHighlightIdRef.current = highlightId;
        const newQuestionHtml = questionAreaNode.innerHTML
            .replaceAll("rgb(255, 255, 23)", "rgb(180, 215, 255)")
            .replaceAll("rgb(255, 65, 34)", "rgb(255, 255, 23)");
        setQuestionHtml(pre => {
            console.log("Dak Buh Lmao :: ", pre);
            saveQuestionHtml(
                JSON.stringify({
                    ...pre,
                    [AREAS[area]]: newQuestionHtml
                })
            );

            return {
                ...pre,
                [AREAS[area]]: newQuestionHtml
            };
        });
        setCurrentHighLightedText(highlightText);

        setCommentBox({
            isShow: true,
            top: mouseEvent.clientY + 20,
            left: mouseEvent.clientX - 20
        });
    };

    useEffect(() => {
        getPreviewData();
    }, []);

    useEffect(() => {
        if (!questionHtml) return;
        setTimeout(() => {
            const spans = document.querySelectorAll("[type=highlight]");

            if (currentHighlightIdRef.current) {
                const hightlights = document.querySelectorAll(
                    `[highlightId='${currentHighlightIdRef.current}']`
                );
                let highlightText = "";
                for (
                    let hIndex = 0;
                    hIndex <= hightlights.length - 1;
                    hIndex++
                ) {
                    hightlights[hIndex].style.backgroundColor =
                        "rgb(255, 255, 23)";
                    highlightText += hightlights[hIndex].innerHTML;
                }
                setCurrentHighLightedText(highlightText);
            }

            spans.forEach((e, i) =>
                e.addEventListener("click", event => {
                    event.stopPropagation();
                    const clientRect = e.getBoundingClientRect();
                    
                    if (canHighlightRef.current) {
                        setCommentBox({
                            isShow: true,
                            top: clientRect.top + clientRect.height * 2,
                            left: event.clientX - 20
                        });
                    }

                    const highlightId = e.getAttribute("highlightId");
                    if (highlightId === currentHighlightIdRef.current) return;
                    const hightlights = document.querySelectorAll(
                        `[highlightId='${highlightId}']`
                    );
                    let highlightText = "";
                    for (
                        let hIndex = 0;
                        hIndex <= hightlights.length - 1;
                        hIndex++
                    ) {
                        hightlights[hIndex].style.backgroundColor = "rgb(255, 65, 34)";
                        highlightText += hightlights[hIndex].innerHTML;
                    }
                    currentHighlightIdRef.current = highlightId;
                    const questionAreaNode = document.querySelectorAll(
                        ".can-highlight"
                    );
                    setQuestionHtml({
                        question_text: questionAreaNode[0].innerHTML.replaceAll("rgb(255, 255, 23)", "rgb(180, 215, 255)").replaceAll("rgb(255, 65, 34)", "rgb(255, 255, 23)"),
                        answer: questionAreaNode[1].innerHTML.replaceAll("rgb(255, 255, 23)", "rgb(180, 215, 255)").replaceAll("rgb(255, 65, 34)", "rgb(255, 255, 23)"),
                        solution: questionAreaNode[2].innerHTML.replaceAll("rgb(255, 255, 23)", "rgb(180, 215, 255)").replaceAll("rgb(255, 65, 34)", "rgb(255, 255, 23)"),
                    })
                    setCurrentHighLightedText(highlightText);
                })
            );
        }, 0);
    }, [questionHtml]);

    const removeComment = async commentId => {
        try {
            const highlightTextId = comments.find(c => c.id === commentId)
                .hightlightTextId;
            const count = comments.filter(
                c => c.hightlightTextId === highlightTextId
            ).length;
            await deleteComment(commentId);
            await fetchComments();
            if (count === 1) {
                removeHightlight(highlightTextId);
            }
        } catch (err) {}
    };

    const handleClickComment = highlightId => {
        // const hightlights = document.querySelectorAll(
        //     `[highlightId='${highlightId}']`
        // );
        // for (let hIndex = 0; hIndex <= hightlights.length - 1; hIndex++) {
        //     hightlights[hIndex].style.backgroundColor = "rgb(255, 255, 23)";
        // }

        currentHighlightIdRef.current = highlightId;
        const questionAreaNode = document.querySelectorAll(".can-highlight");
        setQuestionHtml({
            question_text: questionAreaNode[0].innerHTML.replaceAll("rgb(255, 255, 23)", "rgb(180, 215, 255)").replaceAll("rgb(255, 65, 34)", "rgb(255, 255, 23)"),
            answer: questionAreaNode[1].innerHTML.replaceAll("rgb(255, 255, 23)", "rgb(180, 215, 255)").replaceAll("rgb(255, 65, 34)", "rgb(255, 255, 23)"),
            solution: questionAreaNode[2].innerHTML.replaceAll("rgb(255, 255, 23)", "rgb(180, 215, 255)").replaceAll("rgb(255, 65, 34)", "rgb(255, 255, 23)"),
        })
    };

    const removeHightlight = highlightTextId => {
        const spans = document.querySelectorAll(`[highlightid="${highlightTextId}"]`);

        spans.forEach(span => {
            span.parentNode.replaceChild(
                document.createTextNode(span.innerHTML.replaceAll("&nbsp;", " ")),
                span
            );
        });

        const questionAreaNode = document.querySelectorAll(".can-highlight");
        setQuestionHtml({
            question_text: questionAreaNode[0].innerHTML,
            answer: questionAreaNode[1].innerHTML,
            solution: questionAreaNode[2].innerHTML,
        })
        saveQuestionHtml({
            question_text: questionAreaNode[0].innerHTML,
            answer: questionAreaNode[1].innerHTML,
            solution: questionAreaNode[2].innerHTML,
        })
    };

    const hasComments = comments ? comments.length > 0 : false;

    return (
        <Container style={{
            backgroundColor: '#FAFBFD',
            minHeight: 'calc(100vh - 55px)'
        }} fluid>
            <Row>
                <Col xs={12}>
                    <p
                        className="m-0"
                        style={{
                            fontSize: "24px",
                            fontSstyle: "bold"
                        }}
                    >
                        Detect errors or areas of improvement in question and
                        add comments for those parts:
                    </p>
                    <p
                        className="m-0"
                        style={{
                            fontSize: "18px",
                            fontStyle: "italic",
                            paddingBottom: "10px"
                        }}
                    >
                        Please use your mouse to select a range of text in the
                        question that you think are erroneous or need to improve
                        and add a comment there.
                    </p>
                </Col>
            </Row>

            {commentBox.isShow && (
                <CommentBox
                    checkListData={rawChecklist}
                    isLoading={isFetchingCheckList}
                    onAddComment={(value, checkListId) => {
                        if (!proofreaderCBTQuestionIdRef.current) return;
                        handleAddComment(
                            proofreaderCBTQuestionIdRef.current,
                            value,
                            currentHighlightIdRef.current,
                            checkListId
                        );
                    }}
                    toggle={() => {
                        setCommentBox({
                            isShow: false,
                            top: 0,
                            left: 0
                        });
                        if (!currentHighlightIdRef.current) return;
                        if (
                            comments &&
                            comments.findIndex(c => c.hightlightTextId === currentHighlightIdRef.current) === -1
                        ) {
                            removeHightlight(currentHighlightIdRef.current);
                            currentHighlightIdRef.current = "";
                        }
                    }}
                    position={{
                        top: commentBox.top,
                        left: commentBox.left
                    }}
                />
            )}

            <RejectDialog
                isOpen={isOpenRejectDialog}
                toggle={toggleRejectDialog}
            />

            <Row className="mt-2">
                {/* <Col xs={8}>
                    <div
                        className="border rounded p-2 can-highlight"
                        dangerouslySetInnerHTML={{
                            __html: questionHtml
                        }}
                    ></div>
                </Col> */}
                <Col xs={8}>
                    <PreviewQuestionProofreader
                        solution={questionHtml.solution}
                        answer={questionHtml.answer}
                        questionHighlightHtml={questionHtml.question_text}
                        syllabus={question ? question.syllabus : ''}
                        purpose={question ? question.purpose : ''}
                        category={question ? question.category : ''}
                        subject={question ? question.subject : ''}
                        categoryId={question ? question.questionInfo.categoryId : ''}
                        difficultyLevel={question ? question.questionInfo.difficultyLevel : ''}
                    />
                </Col>

                <Col xs={4}>
                    <div className="comment-list border bg-white p-2">
                        <h5 className="m-0">Comments</h5>
                        <hr />
                        <ul
                            className="comment-list p-0"
                            style={{
                                listStyle: "none"
                            }}
                        >
                            {comments &&
                                comments
                                    .filter(
                                        c =>
                                            c.hightlightTextId ===
                                            currentHighlightIdRef.current
                                    )
                                    .map((comment, index) => {
                                        const checkList = rawChecklist
                                            ? rawChecklist.find(
                                                  e =>
                                                      e.id ===
                                                      comment.checkListId
                                              )
                                            : null;
                                        const checkListText = checkList
                                            ? checkList.title
                                            : "";
                                        return (
                                            <li
                                                key={comment.id}
                                                onClick={() => {
                                                    handleClickComment(
                                                        comment.hightlightTextId
                                                    );
                                                }}
                                                className={classNames(
                                                    "d-flex align-items-top justify-content-between",
                                                    {
                                                        active:
                                                            comment.hightlightTextId ===
                                                            currentHighlightIdRef.current
                                                    }
                                                )}
                                            >
                                                <div className="d-flex align-items-top justify-content-between w-100">
                                                    <div
                                                        style={{ width: "20%" }}
                                                    >
                                                        <span>{index + 1}</span>
                                                    </div>
                                                    <div
                                                        style={{ width: "80%" }}
                                                    >
                                                        <div className="font-weight-bold">
                                                            {checkListText}
                                                        </div>
                                                        <div>
                                                            {comment.comment}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    onClick={() =>
                                                        removeComment(
                                                            comment.id
                                                        )
                                                    }
                                                    style={{
                                                        color: "red",
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                    X
                                                </div>
                                            </li>
                                        );
                                    })}
                        </ul>
                    </div>
                </Col>

                {!isHandled && (
                    <Col
                        xs={12}
                        style={{
                            position: "fixed",
                            bottom: 10
                            // right: 0
                        }}
                    >
                        {hasComments && (
                            <Button
                                onClick={() => {
                                    history.push(
                                        `/review-question/${proofreaderCBTQuestionIdRef.current}`
                                    );
                                }}
                                className="px-3 mr-2"
                                color="primary"
                            >
                                Edit
                            </Button>
                        )}
                    </Col>
                )}
                <div className="for-append"></div>
            </Row>

            <Row className="d-none">
                <Editor
                    onInit={(evt, editor) => {
                        editorRef.current = editor;
                    }}
                    apiKey={TINY_MCE_API_KEY}
                    initialValue={initQuestionHtml}
                    onEditorChange={(content, editor) => {
                        if (!editorRef.current.getContent()) return;
                        const iframe = document.querySelector("iframe");
                        const element = iframe.contentWindow.document.querySelector(
                            "#tinymce"
                        );
                        setQuestionHtml({
                            question_text: element.innerHTML,
                            solution: question.questionInfo.solution,
                            answer: `${JSON.parse(question.questionInfo.answer)
                                .map(
                                    (e, i) =>
                                        `<div class='mb-2 answer'><span class='d-flex align-items-center'>${e.text}</span></div>`
                                )
                                .join(" ")}`
                        });
                    }}
                    init={{
                        height: 500,
                        menubar: false,
                        external_plugins: {
                            tiny_mce_wiris: `${window.location.href}/node_modules/@wiris/mathtype-tinymce6/plugin.min.js`
                        },
                        extended_valid_elements: "*[*]",
                        toolbar:
                            "undo redo | formatselect | image | " +
                            "bold italic backcolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            // "removeformat | help" +
                            "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table",
                        plugins:
                            "advlist autolink lists link image charmap print preview anchor " +
                            "searchreplace visualblocks code fullscreen " +
                            "insertdatetime media table paste code help wordcount table",
                        content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        file_picker_types: "image",
                        image_uploadtab: true
                    }}
                />
            </Row>
        </Container>
    );
};

export default CommentableQuestionPreview;
