import _ from "lodash";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import {
    Alert,
    Button,
    Col,
    CustomInput,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Editor } from "@tinymce/tinymce-react";
import ArticleSelector from "../../components/Selector/ArticleSelector";
import { QUESTION_TYPE } from "../../util/Constant";
import AnswerList from "../Question/view/AnswerList";
import setLoading from "../../components/Spinner/action";
import { createCBTQuestion, getMyTasks, getQuestionReminder } from "../../util/api";
import ProwritingEditor, { TINY_MCE_API_KEY } from "./ProwritingEditor";
import useFetch from "../../hooks/useFetch";
import Timer from "../../components/Timer";
import CategorySelector from "../../components/CategorySelector";


const questionTypes = [
    { label: "Single Choice", value: "SingleChoice" },
    { label: "Multiple Choice", value: "MultipleChoice" }
];

const defaultAnswerList = [
    {
        id: 1,
        text: "A. ",
        isCorrectAnswer: false,
        mark: 0,
        value: [],
        inputValue: "",
        correctOrder: 0,
        matchingText: ""
    },
    {
        id: 2,
        text: "B. ",
        isCorrectAnswer: false,
        mark: 0,
        value: [],
        inputValue: "",
        correctOrder: 0,
        matchingText: ""
    }
];
const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

const ALLOW_CREATOR_TO_SELECT = 0;

const CreateQuestion = ({ t }) => {
    const history = useHistory();
    const { data: tasks } = useFetch(getMyTasks);

    const urlParams = new URLSearchParams(window.location.search);
    const taskId = urlParams.get("taskId");
    const alphabet = ALPHABET.split('')
    const generateDefaultAnswerList = (number) => {
        let defaultValue;
        const array = []
        for (let i = 1; i < number + 1; i++) {
            defaultValue = {
                id: i,
                text: `<b>${alphabet[i - 1]}. </b>`,
                isCorrectAnswer: false,
                mark: 0,
                value: [],
                inputValue: "",
                correctOrder: 0,
                matchingText: ""
            }
            array.push(defaultValue)
        }
        return array
    }

    const [question, setQuestion] = useState({
        // articleId: '',
        // answers: [],
        difficultyLevel: "",
        categoryId: "",
        questionType: "SingleChoice",
        point: "",
        questionOrder: "",
        answers: defaultAnswerList,
        numberOfAnswears: "",
    });
    const [errors, setErrors] = useState({});
    const [enableShowError, setEnableShowError] = useState(false);
    const [msg, setMsg] = useState(null); // { type: primary | danger, value: string }
    const [isShowInstruction, setIsShowInstruction] = useState(false);
    const [isShowArticle, setIsShowArticle] = useState(false);
    const [isShowQuestionPurpose, setIsShowQuestionPurpose] = useState(true);
    const [questionReminder, setQuestionReminder] = useState(null);
    const [modal, setModal] = useState(false);

    const questionText = useRef();
    const instruction = useRef();
    const solution = useRef();
    const currentEdittingEditorIdRef = useRef();
    const timeInSeconds = useRef(0);
    const dispatch = useDispatch();
    const questionProposeRef = useRef(null);
    const questionSyllabus = useRef(null);


    const currentTask = tasks ? tasks.find(item => +item.id === +taskId) : null;
    const categories = currentTask ? currentTask.categories.split(",") : [];
    const categoryIds = currentTask ? currentTask.categoryIds.split(",") : [];

    const handleChange = field => e => {
        setQuestion(preState => ({
            ...preState,
            [field]: e ? e.value : null
        }));

        if (enableShowError) {
            setErrors(pre => {
                return {
                    ...pre,
                    [field]: (e || e.value) === ""
                };
            });
        }
    };

    const validate = () => {
        let isValid = true;
        const error = {};
        [
            "categoryId",
            "difficultyLevel",
            "questionType",
            // "point"
            // "questionOrder"
        ].forEach(field => {
            if (
                question[field] === null ||
                question[field] === undefined ||
                question[field] === ""
            ) {
                isValid = false;
                error[field] = true;
            } else {
                error[field] = false;
            }
        });

        if (questionText.current && questionText.current.getContent() === "") {
            isValid = false;
            error.questionText = true;
        }

        if (solution.current && solution.current.getContent() === "") {
            isValid = false;
            error.solution = true;
        }

        if (
            !question.answers ||
            !question.answers.find(e => e.isCorrectAnswer === true)
        ) {
            isValid = false;
            error.questionAnswers = true;
        }

        if (question.answers.length !== currentTask.numberOfAnswears) {
            isValid = false;
            error.numberOfAnswears = true
        }

        if (Object.keys(error).length > 0) {
            let el = null;
            [
                "categoryId",
                "difficultyLevel",
                "questionText",
                "questionType",
                "questionAnswers",
                "solution",
                // "point"
            ].forEach(field => {
                if (error[field] && !el) {
                    el = document.getElementById(field);
                }
            });
            if (el) {
                window.scroll({
                    top:
                        el.getBoundingClientRect().bottom +
                        el.getBoundingClientRect().height,
                    left: 0,
                    behavior: "smooth"
                });
            }
        }
        // if (instruction.current.getContent() === "") {
        //     isValid = false;
        //     error.instruction = true;
        // }

        setErrors(error);
        return isValid;
    };

    const handleSubmit = async () => {
        // if (!enableShowError) {
        //     setEnableShowError(true);
        // }
        // if (!validate()) return;

        const data = {
            timeInSeconds: timeInSeconds.current,
            articleId: question.articleId,
            difficultyLevel: question.difficultyLevel,
            instruction: instruction.current ? instruction.current
                .getContent()
                .replaceAll("text-decoration: underline wavy red;", "")
                : '',
            categoryId: question.categoryId,
            questionText: questionText.current ? questionText.current
                .getContent()
                .replaceAll("text-decoration: underline wavy red;", "")
                : '',
            // questionText: '',
            type: question.questionType === QUESTION_TYPE.SINGLE_CHOICE ? 0 : 1,
            solution: solution.current ? solution.current
                .getContent()
                .replaceAll("text-decoration: underline wavy red;", "")
                : '',
            // solution: '',
            answerLayout: 1,
            taskId,
            answer: JSON.stringify(
                question.answers
                    ? question.answers.map(e => ({
                        id: uuidv4(),
                        text: e.text.replaceAll("text-decoration: underline wavy red;", ""),
                        checked: e.isCorrectAnswer
                    }))
                    : ""
            ),
            point: question.point || 0,
            questionOrder: question.questionOrder || 0,
            purpose: question.purpose || '',
            syllabus: question.syllabus || '',
        };

        try {
            console.log(data);
            dispatch(setLoading(true));
            const res = await createCBTQuestion(data);
            console.log(res);

            if (res.data) {
                history.push(
                    `/preview-question/${res.data.id}?origin=create-question&taskId=${res.data.taskId}&action=preview`
                );
            }
        } catch (error) {
            console.log(error);
            setMsg({
                type: "danger",
                value: error.response.data.Message
            });

            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
        }

        dispatch(setLoading(false));
    };

    const handleChangeAnswer = ({ data, id, option }) => {
        handleChange("answers")({
            value: question.answers.map(item => {
                if (item.id !== id) return item;

                switch (option) {
                    case "text":
                        return { ...item, text: data };
                    case "mark":
                        return { ...item, mark: Number(data) };
                    case "matchingText":
                        return { ...item, matchingText: data };
                    case "token":
                        return { ...item, token: data };
                    default:
                        return item;
                }
            })
        });
    };

    const handleCheckCorrectAnswer = ({ e, id }) => {
        if (QUESTION_TYPE.MULTIPLE_CHOICE === question.questionType) {
            handleChange("answers")({
                value: question.answers.map(item => {
                    if (item.id !== id) return item;

                    return {
                        ...item,
                        isCorrectAnswer: e.target.checked
                    };
                })
            });
        } else {
            if (!e.target.checked) return;
            setErrors(pre => {
                return {
                    ...pre,
                    questionAnswers: false
                };
            });
            handleChange("answers")({
                value: question.answers.map(item => {
                    if (item.id !== id)
                        return { ...item, isCorrectAnswer: false };

                    return {
                        ...item,
                        isCorrectAnswer: true
                    };
                })
            });
        }
    };

    const getNextId = arr => {
        try {
            if (!arr || !arr.length) return 1;
            const maxIdItem = _.maxBy(arr, "id");
            if (maxIdItem) return maxIdItem.id + 1;
            return 1;
        } catch {
            return 1;
        }
    };

    const handleAddNewAnswer = () => {
        const genAlphabet = ALPHABET.split('')

        handleChange("answers")({
            value: [
                ...question.answers,
                {
                    id: getNextId(question.answers),
                    text: `<b>${genAlphabet[question.answers.length]}. <b/>`,
                    isCorrectAnswer: false,
                    mark: 0,
                    correctAnswer: 0,
                    matchingText: ""
                }
            ]
        });
        if (question && currentTask && question.answers.length + 1 === currentTask.numberOfAnswears) {
            setErrors(pre => {
                return {
                    ...pre,
                    numberOfAnswears: false
                };
            });
        }
    };

    const handleRemoveAnswer = id => () => {
        if (question.answers.length === 2) return;

        handleChange("answers")({
            value: question.answers.filter(item => item.id !== id)
        });
    };


    const categoryOptions = categories.map((c, index) => {
        return {
            label: c,
            value: categoryIds[index]
        };
    });

    if (categoryOptions.length > 0 && !question.categoryId) {
        setQuestion(pre => {
            return {
                ...pre,
                categoryId: categoryOptions[0].value
            };
        });
    }

    if (
        currentTask &&
        currentTask.questionDifficultyLevel !== ALLOW_CREATOR_TO_SELECT &&
        !question.difficultyLevel
    ) {
        setQuestion(pre => {
            return {
                ...pre,
                difficultyLevel: currentTask.questionDifficultyLevel
            };
        });
    }

    const isDisabledSelectQuestionDifficulty = currentTask
        ? currentTask.questionDifficultyLevel !== ALLOW_CREATOR_TO_SELECT
        : false;

    const toggle = () => setModal(!modal)
    const getCurrentQuestionReminder = async (questionReminderId) => {
        try {
            const { data: questionReminderData } = await getQuestionReminder(questionReminderId);
            setQuestionReminder(questionReminderData);
            toggle();
        } catch (err) {
            console.log("err getCurrentQuestionReminder", err)
        }
    }

    useEffect(() => {
        if (currentTask && currentTask.numberOfAnswears) {
            handleChange("answers")({
                value: generateDefaultAnswerList(currentTask.numberOfAnswears)
            });
        }
        if (currentTask && currentTask.questionReminderId) {
            getCurrentQuestionReminder(currentTask.questionReminderId);
        }
    }, [currentTask])

    return (
        <div className="mt-2 p-2">
            <div className="mb-2">
                <Link
                    style={{
                        fontSize: "1.2rem"
                    }}
                    to={`/question-bank/my-task?id=${taskId}`}
                >
                    <i
                        className="fa fa-long-arrow-left mr-2"
                        aria-hidden="true"
                    ></i>
                    My tasks
                </Link>
            </div>

            <h5>Create question</h5>

            {msg && <Alert color={msg.type}>{msg.value}</Alert>}

            <Row className="mb-2">
                <Col id="categoryId" md={9} className="mb-2">
                    <FormGroup className="d-flex align-items-center">
                        <Label>Question purpose</Label>
                        <Input type="checkbox" className="ml-2 mt-0 position-relative" checked={isShowQuestionPurpose} onChange={(e) => setIsShowQuestionPurpose(e.target.checked)} />
                    </FormGroup>
                    {isShowQuestionPurpose && <Editor
                        // disabled={isViewDetail}
                        onInit={(evt, editor) => {
                            questionProposeRef.current = editor
                        }}
                        apiKey={TINY_MCE_API_KEY}
                        onEditorChange={(content, editor) => {
                            handleChange("purpose")({
                                value: content
                            })
                        }}
                        initialValue=""
                        init={{
                            height: 400,
                            menubar: false,
                            branding: false,
                            extended_valid_elements: "*[*]",
                            toolbar:
                                "undo redo | formatselect | image media | " +
                                "bold italic backcolor | alignleft aligncenter " +
                                "alignright alignjustify | bullist numlist outdent indent" +
                                "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table",
                            plugins:
                                "advlist autolink lists link image media charmap preview anchor " +
                                "searchreplace visualblocks code fullscreen " +
                                "insertdatetime media table code help wordcount",
                            content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            file_picker_types: "image",
                            image_uploadtab: true
                        }}
                    />}
                </Col>

                <Col md={3}>
                    <FormGroup>
                        <Label className="d-block text-white">.</Label>
                        <Timer
                            onChange={time => {
                                timeInSeconds.current = time;
                            }}
                        />
                    </FormGroup>
                </Col>

                <Col id="categoryId" md={9} className="mb-2">
                    <FormGroup>
                        <Label>
                            Question category
                            <span className="color-danger">*</span>
                        </Label>

                        <CategorySelector
                            isDisabled
                            invalid={errors.categoryId}
                            placeholder="Select category"
                            options={categoryOptions}
                            value={categoryOptions[0]}
                            onChange={handleChange("categoryId")}
                        />

                        {errors.categoryId && (
                            <span className="color-danger">
                                Question category is required!
                            </span>
                        )}
                    </FormGroup>
                </Col>

                <Col id="difficultyLevel" md={12} className="mb-2">
                    <Label>
                        Difficulty level<span className="color-danger">*</span>
                    </Label>
                    <p
                        className="mb-2 font-weight-bold"
                        style={{ color: "#a94442", fontSize: "0.9em" }}
                    >
                        (The Difficulty Level provides an indication of the
                        difficulty level of each test question)
                    </p>
                    <div className="d-flex align-items-center">
                        <CustomInput
                            disabled={isDisabledSelectQuestionDifficulty}
                            className="mr-3"
                            type="radio"
                            id="difficulty-1"
                            onClick={() =>
                                handleChange("difficultyLevel")({
                                    value: 1
                                })
                            }
                            checked={question.difficultyLevel === 1}
                            label="Level 1"
                        />

                        <CustomInput
                            disabled={isDisabledSelectQuestionDifficulty}
                            className="mr-3"
                            type="radio"
                            id="difficulty-2"
                            onClick={() =>
                                handleChange("difficultyLevel")({
                                    value: 2
                                })
                            }
                            checked={question.difficultyLevel === 2}
                            label="Level 2"
                        />

                        <CustomInput
                            disabled={isDisabledSelectQuestionDifficulty}
                            className="mr-3"
                            type="radio"
                            id="difficulty-3"
                            onClick={() =>
                                handleChange("difficultyLevel")({
                                    value: 3
                                })
                            }
                            checked={question.difficultyLevel === 3}
                            label="Level 3"
                        />

                        <CustomInput
                            disabled={isDisabledSelectQuestionDifficulty}
                            type="radio"
                            id="difficulty-4"
                            onClick={() =>
                                handleChange("difficultyLevel")({
                                    value: 4
                                })
                            }
                            checked={question.difficultyLevel === 4}
                            label="Level 4"
                        />
                    </div>

                    {errors.difficultyLevel && (
                        <span className="color-danger">
                            Difficulty level is required!
                        </span>
                    )}
                </Col>


                <Col md={9} className="mb-2">
                    <FormGroup className="position-relative">
                        <Label>Article</Label>
                        <Input type="checkbox" className="position-absolute mt-0" style={{ top: "2px", left: "64px" }} onChange={(e) => setIsShowArticle(e.target.checked)} />
                    </FormGroup>

                    {isShowArticle && <ArticleSelector
                        noLabel
                        onChange={handleChange("articleId")}
                        isClearable
                    // invalid
                    />}
                </Col>


                {isShowArticle && (
                    <Col md={9} className="mb-2">
                        <Label>Question order</Label>

                        <Input
                            // className={classNames({
                            //     "is-invalid": errors.questionOrder
                            // })}
                            type="number"
                            value={question.questionOrder || ""}
                            onChange={e => {
                                handleChange("questionOrder")({
                                    value: e.target.value
                                });
                            }}
                        />
                    </Col>
                )}

                <Col md={12} className="mb-2">
                    <FormGroup className="d-flex align-items-center">
                        <Label>
                            Instruction
                        </Label>
                        <Input type="checkbox" className="ml-2 mt-0 position-relative" onChange={(e) => setIsShowInstruction(e.target.checked)} />
                    </FormGroup>

                    {isShowInstruction && <ProwritingEditor
                        // invalid={errors.instruction}
                        innerRef={instruction}
                        isClickPublish={false}
                        currentEdittingEditorIdRef={currentEdittingEditorIdRef}
                        onChange={value => {
                            if (enableShowError) {
                                setErrors(pre => {
                                    return {
                                        ...pre,
                                        instruction: value === ""
                                    };
                                });
                            }
                        }}
                    />}

                    {/* {errors.instruction && (
                        <span className="color-danger">
                            Instruction is required!
                        </span>
                    )} */}
                </Col>

                <Col id="questionText" xs={12} className="mb-2">
                    <FormGroup>
                        <Label>
                            Question text<span className="color-danger">*</span>
                        </Label>
                        <ProwritingEditor
                            invalid={errors.questionText}
                            innerRef={questionText}
                            isClickPublish={false}
                            currentEdittingEditorIdRef={
                                currentEdittingEditorIdRef
                            }
                            onChange={value => {
                                if (enableShowError) {
                                    setErrors(pre => {
                                        return {
                                            ...pre,
                                            questionText: value === ""
                                        };
                                    });
                                }
                            }}
                        />

                        {errors.questionText && (
                            <span className="color-danger">
                                Question text is required!
                            </span>
                        )}
                    </FormGroup>
                </Col>

                <Col id="questionType" md={9} className="mb-2">
                    <FormGroup>
                        <Label>
                            Question type<span className="color-danger">*</span>
                        </Label>

                        <Select
                            className={classNames({
                                "invalid-field": errors.questionType
                            })}
                            placeholder="Select type"
                            defaultValue={questionTypes[0]}
                            options={questionTypes}
                            onChange={e => {
                                handleChange("questionType")(e);

                                handleChange("answers")({
                                    value: generateDefaultAnswerList(currentTask.numberOfAnswears)
                                });
                            }}
                        />

                        {errors.questionType && (
                            <span className="color-danger">
                                Question type is required!
                            </span>
                        )}
                    </FormGroup>
                </Col>

                <Col md={9} className="mb-2">
                    <Label>Number of answers: {currentTask ? currentTask.numberOfAnswears : "2"}</Label>
                </Col>

                {question.questionType && (
                    <>
                        <Col id="answer-list" md={12} className="mb-2">
                            <div id="questionAnswers">
                                <AnswerList
                                    t={t}
                                    noSetInitValue
                                    // isInlineOrderDragDrop
                                    answerArr={question.answers || []}
                                    isMulti={
                                        QUESTION_TYPE.MULTIPLE_CHOICE ===
                                        question.questionType
                                    }
                                    isSingleChoice={
                                        QUESTION_TYPE.SINGLE_CHOICE ===
                                        question.questionType
                                    }
                                    onAddNewAnswer={handleAddNewAnswer}
                                    onChangeAnswer={handleChangeAnswer}
                                    onCheckCorrectAnswer={
                                        handleCheckCorrectAnswer
                                    }
                                    onRemoveAnswer={handleRemoveAnswer}
                                    currentEdittingEditorIdRef={
                                        currentEdittingEditorIdRef
                                    }
                                    isClickPublish={false}
                                />
                                <div className="d-flex flex-column">
                                    {errors.questionAnswers && (
                                        <span className="color-danger">
                                            The question must have the correct answer!
                                        </span>
                                    )}
                                    {errors.numberOfAnswears && (
                                        <span className="color-danger">
                                            The question must have correct number of answer!
                                        </span>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </>
                )}

                <Col id="solution" md={12} className="mb-2">
                    <Label>
                        Solution<span className="color-danger">*</span>
                    </Label>

                    <ProwritingEditor
                        invalid={errors.solution}
                        innerRef={solution}
                        isClickPublish={false}
                        currentEdittingEditorIdRef={currentEdittingEditorIdRef}
                        onChange={value => {
                            if (enableShowError) {
                                setErrors(pre => {
                                    return {
                                        ...pre,
                                        solution: value === ""
                                    };
                                });
                            }
                        }}
                    />

                    {errors.solution && (
                        <span className="color-danger">
                            Solution is required!
                        </span>
                    )}
                </Col>

                {/* <Col id="point" md={9} className="mb-2">
                    <Label>
                        Point<span className="color-danger">*</span>
                    </Label>

                    <Input
                        className={classNames({
                            "is-invalid": errors.point
                        })}
                        type="number"
                        value={question.point || ""}
                        onChange={e => {
                            handleChange("point")({
                                value: e.target.value
                            });
                        }}
                    />

                    {errors.point && (
                        <span className="color-danger">Point is required!</span>
                    )}
                </Col> */}

                <               Col md={9} className="mb-2">
                    <FormGroup className="d-flex align-items-center">
                        <Label>Syllabus</Label>
                    </FormGroup>
                    {<Editor
                        // disabled={isViewDetail}
                        onInit={(evt, editor) => {
                            questionSyllabus.current = editor
                        }}
                        apiKey={TINY_MCE_API_KEY}
                        onEditorChange={(content, editor) => {
                            handleChange("syllabus")({
                                value: content
                            })
                        }}
                        initialValue=""
                        init={{
                            height: 400,
                            menubar: false,
                            branding: false,
                            extended_valid_elements: "*[*]",
                            toolbar:
                                "undo redo | formatselect | image media | " +
                                "bold italic backcolor | alignleft aligncenter " +
                                "alignright alignjustify | bullist numlist outdent indent" +
                                "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table",
                            plugins:
                                "advlist autolink lists link image media charmap preview anchor " +
                                "searchreplace visualblocks code fullscreen " +
                                "insertdatetime media table code help wordcount",
                            content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            file_picker_types: "image",
                            image_uploadtab: true
                        }}
                    />}
                </Col>

                <Col xs={12}>
                    <Button
                        color="primary"
                        className="mr-2"
                        onClick={handleSubmit}
                        style={{
                            width: "120px"
                        }}
                    >
                        Add question
                    </Button>
                </Col>
            </Row>

            {questionReminder
                ? <Modal isOpen={modal} toggle={toggle} size="lg">
                    <ModalHeader toggle={toggle}><h3>Question Reminder</h3></ModalHeader>
                    <ModalBody>
                        <div
                            dangerouslySetInnerHTML={{ __html: questionReminder.description }}
                        ></div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggle}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal> : null}

        </div>
    );
};

export default withTranslation("common")(CreateQuestion);
