import { getAllArticles, createArticle, updateArticle, deleteArticle } from "../../../util/api"
import setLoading from "../../../components/Spinner/action"
import setModal from "../../../components/Modal/action"
import setAlert from "../../../components/Alert/action"

export const getArticleAction = ({ currentPage, pageSize, searchString, sortBy, sortOrder }) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await getAllArticles({ currentPage, pageSize, searchString, sortBy, sortOrder })

        if (!res.data) throw new Error("Error in getting articles")

        if (pageSize) {
            dispatch({
                type: "SET_ARTICLE",
                payload: res.data.data,
                currentPage: res.data.currentPage,
                sortBy: res.data.sortBy,
                sortOrder: res.data.sortOrder,
                totalItems: res.data.totalItems,
                pageCount: res.data.pageCount,
            })
        } else {
            dispatch({
                type: "SET_ALL_ARTICLE",
                payload: res.data.data
            })
        }
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: JSON.stringify(err.response ? err.response.data : err)
        }))
    }

    dispatch(setLoading(false))
}

export const changePageSizeArticleAction = pageSize => async dispatch => {
    dispatch({
        type: "CHANGE_PAGE_SIZE_ARTICLE",
        payload: pageSize
    })
}

export const createArticleAction = ({ name, content, cb }) => async dispatch => {
    dispatch(setLoading(true))

    try {
        await createArticle({ name, content })

        dispatch(setAlert({
            type: "success",
            message: "Create article successfully"
        }))
        if (cb) cb()
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: err.response && err.response.data
        }))
    }

    dispatch(setLoading(false))
}

export const updateArticleAction = ({ id, name, content, cb }) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await updateArticle({ id, name, content })
        if (!res.data) throw new Error("Error in updating article")

        if (cb) cb()
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: err.response.data
        }))
    }

    dispatch(setLoading(false))
}

export const deleteArticleAction = ({ id, cb }) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await deleteArticle(id)
        if (res.status !== 200) throw new Error("Error in deleting article")

        if (cb) cb()
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: err.response.data
        }))
    }

    dispatch(setLoading(false))
}