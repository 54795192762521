import React from 'react'
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import Select from "react-select";
import { withTranslation } from 'react-i18next'
import { fetchUserByRole } from '../../util/api';


class UserSelected extends React.PureComponent {
    state = {
        inputValue: '',
        isLoading: false,
        options: [],
    }

    componentDidMount() {
        if (this.props.userRole !== 'Student') {
            this.loadData()
        }
    }

    onInputChange = (inputValue) => {
        const { userRole } = this.props;
        this.setState({ inputValue })

        if (userRole === "Student") {
            clearTimeout(this.timeoutId)
            this.timeoutId = setTimeout(this.loadData, 300);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.teacherSkillIds && nextProps.teacherSkillIds.length !== this.props.teacherSkillIds.length) {
            if (this.props.userRole === 'Teacher') this.loadData(nextProps.teacherSkillIds)
        }
        if (nextProps.initData !== this.props.initData) {
            this.changeOptionDisable(nextProps.initData)
        }
    }

    changeOptionDisable = init => {
        const initData = init || this.props.initData;
        if (initData && Array.isArray(initData) && initData.length > 0) {
            const ids = initData.map(m => m.value);
            if (ids.length > 0) {
                const options = [...this.state.options]
                this.setState({ options: options.map(m => ({ ...m, isDisabled: ids.includes(m.value) })) })
            }
        }
    }

    loadData = async (nextTeacherSkillIds) => {
        const { inputValue } = this.state;
        if (inputValue.length < 2 && this.props.userRole === 'Student') {
            this.setState({ options: [] })
            return
        }
        this.setState({ isLoading: true })
        const { userRole, teacherSkillIds, moreData, categoryType, semester, year } = this.props
        const { data } = await fetchUserByRole({ role: userRole, searchString: inputValue, teacherSkillIds: nextTeacherSkillIds || teacherSkillIds || null, moreData: moreData || null, categoryType, semester, year })
        const options = (data || []).map(i => ({
            ...i,
            userId: i.userId,
            value: i.userId,
            label: `${i.firstName} ${i.lastName}${this.props.userRole === 'Student' && this.props.includeEmail ? ` (${i.email})` : ''}`
        }))

        await this.setState({ options, isLoading: false });
        this.changeOptionDisable();
    }

    renderPlaceHolder = role => {
        // role = Parent, Student, Assistance
        if (role !== 'Teacher' && role !== 'TeachersAndAssistances') {
            return `${this.props.t('start_typing')} ${this.props.t(role.toLowerCase()).toLowerCase()}`
        }

        if (role === 'TeachersAndAssistances') {
            return this.props.t('select_teacher_assistance')
        }

        return this.props.t('select_teacher')
    }

    render() {
        const { noLabel, label, invalid, errorMessage, userRole, require, important } = this.props;
        const { inputValue, isLoading, options } = this.state;

        return (
            <FormGroup>
                {!noLabel && <Label>{label || userRole} {require && <span style={{ color: "red" }}>*</span>}</Label>}
                <Select
                    options={options}
                    inputValue={inputValue}
                    onInputChange={this.onInputChange}
                    isLoading={isLoading}
                    placeholder={this.renderPlaceHolder(userRole)}
                    {...this.props}
                    className="basic-single"
                    classNamePrefix="select"
                    value={important
                        ? this.props.value
                        : !this.props.isMulti && options && this.props.value && options.some(teacher => teacher.userId === this.props.value.userId) ? this.props.value : this.props.isMulti ? this.props.value : null}
                />
                {invalid && (
                    <FormFeedback style={{ display: "flex" }}>
                        {errorMessage}
                    </FormFeedback>
                )}
            </FormGroup>
        )
    }
}

export default withTranslation('common')(UserSelected)
