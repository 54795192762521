import axios from "axios"
import { getAccessToken } from "./Common"
import { tryGetAuthCode } from "./GraphServices"
import encodeParams from "./encodeParams"
import store from "../store"
import { getBaseUrl } from "./helper"

export const BASE_ENDPOINT = `${getBaseUrl()}/api`

export const LOGIN_ENDPOINT = `/account/loginadmin`
export const AUTHEN_ENDPOINT = `/account/authentication`
export const REPORT_ENDPOINT = `/attendance`
export const FILE = "/file"
export const DASHBOARD_ENDPOINT = `/dashboard`
export const EXTRA_SALARY_CATEGORY_ENDPOINT = `/salary/category`
export const COURSE_ENDPOINT = `/course`
export const COURSE_TYPE = `/coursetype`
export const TEACHER_ENDPOINT = `/user`
export const TEACHER_CONTROLLER_ENDPOINT = `/teacher`
export const USER_INFORMATION = (id) => `/user/${id}`
export const RESET_PASSWORD_TEACHER_ENDPOINT = `/teacher/adminResetPassword`
export const REMOVE_ATTENDANCE_ENDPOINT = `/attendance/remove`
export const CREATE_USER = "/user"
export const CREATEMAIL_ENDPOINT = `/mailtemplate/createmail`
export const SENDMAIL_ENDPOINT = `/mailtemplate/sendmail`
export const OLD_USERS = `/user/oldusers`
export const REMOVE_OLD_USERS = `/user/removeusers`
export const PAYMENT = `/payments`

export const api = axios.create({
    baseURL: BASE_ENDPOINT,
    timeout: 30000,
    headers: {
        "Content-Type": "application/json"
    },
    paramsSerializer: (params) => {
        return encodeParams(params)
    }
})

export const apiUpload = axios.create({
    baseURL: BASE_ENDPOINT,
    timeout: 15000,
    headers: {
        "Content-Type": "multipart/form-data"
    }
})
    ;[api, apiUpload].forEach((i) =>
        i.interceptors.request.use(
            (config) => {
                const token = getAccessToken()
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`
                }
                return config
            },
            (error) => Promise.reject(error)
        )
	)
	
export const generateData = async () => {
	return await api.post("/datainitiation")
}

export const fetchUserByRole = ({
    role,
    searchString,
    teacherSkillIds,
    moreData,
    categoryType,
    semester,
    year
}) =>
    api.get("user/userByRole", {
        params: {
            role,
            searchString,
            teacherSkillIds,
            moreData,
            categoryType,
            semester,
            year
        }
    })
export const fetchGraphAuthCode = (code) =>
    api.get("/msgraph/authorize", { params: { code } })
export const tryCallGraphApi = async (handler) => {
    const res = await handler()
    if (res && res.data && res.data === 401) {
        const code = await tryGetAuthCode()

        const authResult = await fetchGraphAuthCode(code)
        if (authResult) {
            const lastRes = await handler()
            if (lastRes.data !== 401) {
                return lastRes
            }
        }
        throw new Error("An error occured! Make sure you login correct account")
    }
    return res
}

export const fetchUserByUserIds = ({ userIds, semester, year }) =>
    api.get("user/getByUserIds", { params: { userIds, semester, year } })

export const fetchAllCourse = ({ year, semester, searchString, isWaiting }) =>
    api.get("course/?currentPage=-1", {
        params: {
            year,
            semester,
            searchString,
            isWaiting
        }
    })

export const fetchCourseWithStudent = ({ searchString }) =>
    api.get("course/getallcoursewithstudent", { params: { searchString } })

export const fetchAdmin = () =>
    Promise.all([
        api.get("dashboard/AdminGeneral"),
        api.get("dashboard/AdminPayment"),
        api.get("dashboard/StudioAnalyses"),
        api.get("dashboard/BestOfCourse")
    ])

export const fetchStudentOfCourse = (courseId) =>
    api.get(`${COURSE_ENDPOINT}/studentsOfCourse`, { params: { courseId } })
export const fetchCourseOfStudent = ({ id, year, semester, flags }) =>
    api.get(`${COURSE_ENDPOINT}/coursesOfStudent`, {
        params: {
            studentId: id,
            year,
            semester,
            flags
        }
    })

export const deleteImportedUser = (id) =>
    api.post(`user/deleteImportedUser/?id=${id}`)
export const deleteOldImportedUser = (id) =>
    api.post(`user/deleteOldImportedUser/?id=${id}`)
export const fetchSendMail = () =>
    tryCallGraphApi(() => api.post(SENDMAIL_ENDPOINT, null, { timeout: 0 }))
export const fetchCreateMail = (body) =>
    tryCallGraphApi(() => api.post(CREATEMAIL_ENDPOINT, body))
export const fetchCheckExistMessages = () =>
    tryCallGraphApi(() => api.get("mailtemplate/checkexistmessages"))

export const fetchStudentFromCourseAndProgram = (
    categoryType,
    courseIds,
    programIds,
    year,
    semester
) =>
    api.post("course/getstudentwithparentbycourseandprogram", {
        categoryType,
        courseIds,
        programIds,
        year,
        semester
    })
export const getStudentByCourseAndProgram = (
    courseIds,
    programIds,
    year,
    semester
) =>
    api.post("course/GetStudentByCourseAndProgram", {
        courseIds,
        programIds,
        year,
        semester
    })

export const fetchNumberOfActiveStudent = ({
    categoryType,
    forceRecreate,
    year,
    semester
}) =>
    api.get("user/countstudentsactiveandfamilies", {
        params: {
            categoryType,
            forceRecreate,
            year,
            semester
        }
    })
export const fetchTaskProgress = (type = 1) =>
    api.get(`mailtemplate/taskprogress/?type=${type}`)

export const submitClassMissing = (id) =>
    api.get(`attendance/adminsubmitclass/?classroomid=${id}`)

export const addExtraSalary = (extra) => api.post(`teacher/extrasalary`, extra)
export const deleteExtraSalary = (id) => api.delete(`teacher/extrasalary/${id}`)

export const getTeacherActive = ({ currentPage, pageSize }) =>
    api.get(`teacher/getActiveTeachers`, { params: { currentPage, pageSize } })

export const getSecretKey = () => api.get("teacher/getSecretKey")

export const checkTokenApi = (token) => api.post("account/checktoken", token)

export const getEditStatus = (objectType, objectId) => {
    const userId = store.getState().common.userInfo.identity.id || 0
    return api.get(
        `/edit?userId=${userId}&objectType=${objectType}&objectId=${objectId}`
    )
}

export const overwriteEditStatus = (objectType, objectId) => {
    const userId = store.getState().common.userInfo.identity.id || 0
    return api.post(
        `/edit?userId=${userId}&objectType=${objectType}&objectId=${objectId}`
    )
}

export const deleteEditStatus = (objectType, objectId) => {
    const userId = store.getState().common.userInfo.identity.id || 0
    return api.delete(
        `/edit?userId=${userId}&objectType=${objectType}&objectId=${objectId}`
    )
}

export const updateSalaryMetaInfo = (data) =>
    api.put(`teacher/salaryInfo`, data)

export const updateInvoiceInfo = (data) =>
    api.post(`payment/updateInvoiceInfo`, data)

export const getSingleTeacherSalaryApi = (startDate, endDate) => {
    const teacherId = store.getState().common.userInfo.identity.id || 0
    return api.get(
        `teacher/singleTeacherSalary?teacherId=${teacherId}&startDate=${startDate}&endDate=${endDate}`
    )
}

export const confirmSalary = (startDate) => {
    const teacherId = store.getState().common.userInfo.identity.id || 0
    return api.put(
        `teacher/salaryInfo/confirm?teacherId=${teacherId}&startDate=${startDate}`
    )
}

export const createSalaryTransaction = (startDate, endDate) => {
    return api.post(
        `fortnox/createSalaryTransaction`,
        { startDate, endDate },
        { timeout: 0 }
    )
}

export const sendToFortnoxInvoice = (year, semester) => {
    return api.post(
        "fortnox/SendToFortnoxInvoice",
        { Year: year, Semester: semester },
        { timeout: 0 }
    )
}

export const sendConfirmSalaryRequest = (data) =>
    api.post(`teacher/sendMailConfirmSalary`, data)
export const getEmployee = (id) => api.get(`fortnox/GetEmployee/${id}`)
export const getCustomer = (id) => api.get(`fortnox/GetCustomer/${id}`)
export const randomEmail = (name) => api.get(`user/randomemail/?name=${name}`)
export const syncToFortnoxEmployeeApi = (data) =>
    api.post(`fortnox/SyncToFortnoxEmployee/`, data)
export const syncToFortnoxCusomterApi = (data) =>
    api.post(`fortnox/SyncToFortnoxCustomer/`, data)

export const createAllPayment = (sem, year) =>
    api.post(
        `payment/create-all/?semester=${sem}&year=${year}`,
        {},
        { timeout: 0 }
    )
export const getCreateAllPaymentStatus = () =>
    api.get(`payment/create-all-status`)
export const cancelCreateAllPayment = () =>
    api.post("payment/create-all-cancel")
export const addStudentToProgramFromCourse = (programId, studentId) =>
    api.post(
        `program/addStudentFromCourse/?programId=${programId}&studentId=${studentId}`
    )

export const updateAllPayment = (semester, year) =>
    api.get(`payment/updateAllPayment/?semester=${semester}&year=${year}`, {
        timeout: 0
    })
export const getUpdateAllPaymentStatus = () =>
    api.get(`payment/update-all-status`)

export const getImportStudents = ({
    currentPage,
    pageSize,
    searchString,
    sortBy,
    sortOrder,
    isDone
}) =>
    api.get(`user/importStudents`, {
        params: {
            currentPage,
            pageSize,
            searchString,
            sortBy,
            sortOrder,
            isDone
        }
    })

export const completeImport = (id, existedUserId) =>
    api.post(`user/completeImport/?id=${id}&existedUserId=${existedUserId}`)

export const getTeacherFromSkill = ({
    skillIds,
    currentPage,
    pageSize,
    teacherIds
}) =>
    api.get("teacher/getTeacherFromSkill", {
        params: {
            skillIds,
            currentPage,
            pageSize,
            teacherIds
        }
    })

export const updateOwnProfileUser = (data) => api.put("api/user", data)

export const deleteEmptyDraftFolder = () =>
    tryCallGraphApi(() => api.get("mailtemplate/deleteEmptyDraft"))

export const importStudent = (data) => api.post("user/importStudent", data)

export const getUrlLog = () => api.get("logs")

export const resetSalary = (teacherId, startDate) =>
    api.post(
        `/teacher/salary/reset?teacherId=${teacherId}&startDate=${startDate}`
    )
export const getGraphClientId = () => api.get("mailtemplate/graphClientId")

export const getAllQuestionCategory = ({
    currentPage,
    pageSize,
    searchString,
    sortBy,
    sortOrder
}) =>
    api.get(`/category`, {
        params: {
            currentPage,
            pageSize,
            searchString,
            sortBy,
            sortDescending: sortOrder === "DESC"
        }
    })

export const createQuestionCategory = (data) => api.post(`/category`, data)

export const updateQuestionCategory = (data) =>
    api.put(`/category/${data.id}`, data)

export const deleteQuestionCategory = (id) => api.delete(`/category/${id}`)

export const getAllArticles = ({
    currentPage,
    pageSize,
    searchString,
    sortBy,
    sortOrder
}) =>
    api.get(`/article`, {
        params: {
            currentPage,
            pageSize,
            searchString,
            sortBy,
            sortDescending: sortOrder === "DESC"
        }
    })

export const createArticle = (data) => api.post(`/article`, data)

export const updateArticle = (data) =>
    api.put(`/article/${data.id}`, data)

export const deleteArticle = (id) => api.delete(`/article/${id}`)

export const createQuestion = (data) => api.post(`/question`, data)

export const updateQuestion = (data) => api.patch(`/question/${data.id}`, data)

export const getAllQuestion = ({
    currentPage,
    pageSize,
    searchString,
    categoryId,
    type,
    articleId
}) =>
    api.get(`/question`, {
        params: {
            currentPage,
            pageSize,
            searchString,
            type,
            categoryId,
            articleId
        }
    })

export const deleteQuestion = (id) => api.delete(`/question/${id}`)

export const getQuestion = (id) => api.get(`/question/${id}`)

export const createHightlightedTextComment = (data) => api.post(`/question/createHightlightedTextComment`, data)

export const removeHightlightedTextComment = (id, questionId) => api.delete(`/question/removeHightlightedTextComment?id=${id}&questionId=${questionId}`)

export const createAssessment = (data) => api.post(`/assessment`, data)

export const createAssessmentAssignment = (data) =>
    api.post(`/assessmentAssignment`, data)

export const updateAssessment = (data) =>
    api.patch(`/assessment/${data.id}`, data)

export const updateAssessmentAssignment = (data) =>
    api.patch(`/assessmentAssignment/${data.id}`, data)

export const getAssessment = (id) => api.get(`/assessment/${id}`)

export const getAssessmentAssignment = (id) =>
    api.get(`/assessmentAssignment/${id}`)

export const getAssessmentResponses = ({
    assessmentAssignmentId,
    currentPage,
    pageSize
}) =>
    api.get(`/assessmentAssignment/${assessmentAssignmentId}/responses`, {
        params: { currentPage, pageSize }
    })

export const getAssessmentResponseWithResults = (id) =>
    api.get(`/assessmentResponse/${id}/results`)

export const updateResponseEvaluation = (data) =>
    api.put(`/assessmentResponse/${data.id}/updateEvaluation`, data)
export const updateAssessmentResponseStatus = (id, data) =>
    api.put(`/assessmentResponse/${id}/updateStatus`, data)

export const getAllAssessment = ({
    currentPage = 1,
    pageSize = 0,
    searchString = "",
    type,
    isInHouseTest
}) =>
    api.get(`/assessment`, {
        params: { currentPage, pageSize, searchString, type, isInHouseTest }
    })

export const getAllAssessmentAssignments = ({
    currentPage,
    pageSize,
    searchString
}) =>
    api.get(`/assessmentAssignment`, {
        params: {
            currentPage,
            pageSize,
            searchString
        }
    })

export const deleteAssessment = (id) => api.delete(`/assessment/${id}`)

export const deleteAssessmentAssignment = (id) =>
    api.delete(`/assessmentAssignment/${id}`)

export const createSubject = (data) => api.post(`/subject`, data)

export const getSubject = (id) => api.get(`/subject/${id}`)

export const updateSubject = (data) => api.put(`/subject`, data)

export const deleteSubject = (id) => api.delete(`/subject/${id}`)

export const getAllSubjects = ({
    currentPage,
    pageSize,
    searchString,
    sortBy,
    sortOrder
}) =>
    api.get(`/subject/all`, {
        params: {
            currentPage,
            pageSize,
            searchString,
            sortBy,
            sortOrder
        }
    })

export const createQuestionReminder = (data) => api.post(`/questionReminder/CreateQuestionReminder`, data)

export const getQuestionReminder = (id) => api.get(`/questionReminder/GetQuestionReminder?id=${id}`)

export const updateQuestionReminder = (data) => api.put(`/questionReminder/UpdateQuestionReminder`, data)

export const deleteQuestionReminder = (id) => api.delete(`/questionReminder/${id}`)

export const getAllQuestionReminder = () => api.get(`/questionReminder/getAllQuestionReminder`)

export const getAllTestPack = ({
    currentPage,
    pageSize,
    searchString,
    sortBy,
    sortOrder
}) =>
    api.get("/testpack", {
        params: {
            currentPage,
            pageSize,
            searchString,
            sortBy,
            sortOrder
        }
    })

export const createTestPack = (data) => api.post("/testpack", data)
export const updateTestPack = (data) => api.patch(`/testpack/${data.id}`, data)
export const deleteTestPack = (id) => api.delete(`/testpack/${id}`)
export const getTestPack = (id) => api.get(`/testpack/${id}`)

export const getAllTestSuite = ({
    currentPage,
    pageSize,
    searchString,
    sortBy,
    sortOrder
}) =>
    api.get("/testsuite", {
        params: {
            currentPage,
            pageSize,
            searchString,
            sortBy,
            sortOrder
        }
    })

export const createTestSuite = (data) => api.post("/testsuite", data)
export const updateTestSuite = (data) =>
    api.patch(`/testsuite/${data.id}`, data)
export const deleteTestSuite = (id) => api.delete(`/testsuite/${id}`)
export const getTestSuite = (id) => api.get(`/testsuite/${id}`)

export const getAllSubscription = ({
    currentPage,
    pageSize,
    searchString,
    sortBy,
    sortOrder,
    testSuiteId
}) =>
    api.get("/testsuitesubscription", {
        params: {
            currentPage,
            pageSize,
            searchString,
            sortBy,
            sortOrder,
            testSuiteId
        }
    })

export const createSubscription = (data) =>
    api.post("/testsuitesubscription", data)
export const updateSubscription = (data) =>
    api.patch(`/testsuitesubscription/${data.id}`, data)
export const deleteSubscription = (id) =>
    api.delete(`/testsuitesubscription/${id}`)
export const getSubscription = (id) => api.get(`/testsuitesubscription/${id}`)

export const getAllTestSuitePayment = ({
    currentPage,
    pageSize,
    searchString,
    sortBy,
    sortOrder
}) =>
    api.get("/testsuitepayment", {
        params: {
            currentPage,
            pageSize,
            searchString,
            sortBy,
            sortOrder
        }
    })

export const getAllTestResult = ({
    currentPage,
    pageSize,
    searchString,
    status,
    studentIds,
    testSuiteIds,
    testPackIds,
    startTime,
    endTime
}) => api.get("/assessmentresponse", {
    params: {
        currentPage,
        pageSize,
        searchString,
        status,
        studentIds,
        testSuiteIds,
        testPackIds,
        startTime,
        endTime
    }
})

export const getTestResult = id => api.get(`/assessmentreponse/${id}/results`)

export const getAllTestReport = reportType => ({
    currentPage,
    pageSize,
    searchString,
    testSuiteIds,
    testPackIds,
    assessmentIds
}) => reportType === 'assessments'
        ? api.get(`/assessmentResponseReport/assessments`, {
            params: {
                currentPage,
                pageSize,
                searchString,
                testSuiteIds,
                testPackIds
            }
        })
        :
        api.get(`/assessmentResponseReport/students`, {
            params: {
                currentPage,
                pageSize,
                searchString,
                testSuiteIds,
                testPackIds,
                assessmentIds
            }
        })

export const getCBTQuestionCategories = () => api.get('/cbtQuestion/getAllCategories')

export const createTask = (data) => api.post('/cbtQuestion/createTask', data)

export const updateTask = (id, data) => api.post(`/cbtQuestion/updateTask/${id}`, data)

export const getTasks = (searchString = '', email = '', shouldRemoveDeletedTask = true) => api.get(`/cbtQuestion/getTasks?search=${searchString}&email=${email}&shouldRemoveDeletedTask=${shouldRemoveDeletedTask}`)

export const getQuestions = (questionCreatorId, taskId, search) => api.get(`/cbtQuestion/getQuestions?taskId=${taskId}&questionCreatorId=${questionCreatorId}&search=${search}`)

export const assignProofreader = (questionId, proofreaderId) => api.put(`/cbtQuestion/assignProofreader?questionId=${questionId}&proofreaderId=${proofreaderId}`)

export const getMyTasks = () => api.get('/cbtQuestion/myTask')

export const getCBTQuestion = (id) => api.get(`/cbtQuestion/${id}`) 

export const createCBTQuestion = (data) => api.post('/cbtQuestion/createQuestion', data)

export const getQuestionByTaskId = (taskId) => api.get(`/cbtQuestion/getQuestionByTaskId?taskId=${taskId}`)

export const getProofReaders = () => api.get('/cbtQuestion/getProofReaders')

export const proofread = (data) => api.post(`/cbtQuestion/proofread`, data)

export const approveQuestion = (questionId, isNewVersion) => api.put(`/cbtQuestion/approveQuestion?questionId=${questionId}&isNewVersion=${isNewVersion}`)

export const approveTask = (taskId) => api.post(`/cbtQuestion/approveTask/${taskId}`);

export const updateCBTQuestion = (data) => api.put(`/cbtQuestion/${data.questionId}`, data)

export const getQuestionCreators = () => api.get('/cbtQuestion/getQuestionCreators');

export const getCBTQuestionDetail = (id) => api.get(`/cbtQuestionDetail/${id}`) 

export const publishQuestionToCBT = (id) => api.post(`/cbtQuestion/publishQuestionToCBT?questionId=${id}`)

export const getAnalysisText = (text) => api.get(`/cbtQuestion/analysisText?text=${text}`)

export const updateProofreaderQuestion = (data) => api.put(`/cbtQuestion/updateProofreaderQuestion`, data) // update question by proofreader

export const getCBTQuestionReview = (id) => api.get(`/cbtQuestion/questionReview/${id}`) // view qs detail by preader

export const getQuestionReviewProofreader = (id) => api.get(`/cbtQuestion/questionReviewProofreader/${id}`)

export const getAdminQuestions = (queryParams) => {
    let queryString = '';

    for (const key in queryParams) {
        queryString += `${key}=${queryParams[key]}&`
    }

    return api.get(`/cbtQuestion/getAdminQuestions?${queryString}`) // qs list for admin
}

export const getProofreaderQuestions = (categoryId, type, keyword) => api.get(`/cbtQuestion/getProofreaderQuestions?categoryId=${categoryId}&type=${type}&keyword=${keyword}`) // qs list for preader

export const publishQuestionByAuthor = (id) => api.put(`/cbtQuestion/publishQuestionByAuthor/${id}`)

export const markQuestionReadyForTest = (id) => api.put(`/cbtQuestion/markQuestionReadyForTest/${id}`)

export const undoQuestionReadyForTest = (id) => api.put(`/cbtQuestion/undoQuestionReadyForTest/${id}`)

// export const publishTaskByAuthor = (id) => api.put(`/cbtQuestion/publishTaskByAuthor/${id}`)

export const deleteTask = (id) => api.delete(`/cbtQuestion/deleteTask/${id}`)

export const getAllUser = () => api.get('/user/getAllUser')

export const removeUser = (id) => api.post(`/user/removeUser/${id}`)

export const createGroup = (data) => api.post(`/group/CreateGroup`, data)

export const getGroups = () => api.get(`/group/GetAllGroup`)

export const updateGroup = (data) => api.put(`/group/UpdateGroup`, data)

export const deleteGroup = (id) => api.delete(`/group/${id}`)

export const getGroupsById = () => api.get(`/group/GetGroup`)

export const getAllTaskSubject = () => api.get(`/taskSubject/GetAllTaskSubject`)

export const createTaskSubject = (data) => api.post(`/taskSubject/CreateTaskSubject`, data)

export const updateTaskSubject = (data) => api.put(`/taskSubject/UpdateTaskSubject`, data)

export const deleteTaskSubject = (id) => api.delete(`/taskSubject/${id}`)

export const getCommentablePreviewQuestion = (id) => api.get(`/cbtQuestion/getCommentablePreviewQuestion?questionId=${id}`)

export const rejectQuestion = (questionId, reason) => api.put(`/cbtQuestion/rejectQuestion?questionId=${questionId}&reason=${reason}`)

export const addComment = (proofReaderCBTQuestionId, comment, hightlightTextId, checkListId) => api.post(`/cbtQuestion/addComment?proofReaderCBTQuestionId=${proofReaderCBTQuestionId}&comment=${comment}&hightlightTextId=${hightlightTextId}&checkListId=${checkListId}`)

export const deleteComment = (commentId) => api.delete(`/cbtQuestion/deleteComment/${commentId}`)

export const getComments = (proofreaderCBTQuestionId) => api.get(`/cbtQuestion/getComments?proofreaderCBTQuestionId=${proofreaderCBTQuestionId}`)

export const updateQuestionHtml = (data) => api.put('/cbtQuestion/updateQuestionHtml', data)

export const getHistoryQuestion = (questionId) => api.get(`/cbtQuestion/history/${questionId}`)

export const getOfficalCBTQuestions = (partOfId) => api.get(`/cbtQuestion/getOfficialCBTQuestions?partOfId=${partOfId}`)

export const getCategoriesToCbt = (subjectName) => api.get(`/cbtQuestion/GetCategoriesToCbt?subjectName=${subjectName}`)

export const resetPassword = (newPassword) => api.put(`/account/resetPassword`, { newPassword })

export const getCheckListComment = () => api.get(`/cbtQuestion/comment/getErrorTypes`)

export const getLinkChangePassword = ({ email }) => api.get(`/forgotPassword/${email}`)