import React, { useState, useEffect } from "react"
import Select from "react-select"
import { FormGroup, Label } from "reactstrap"

import { getAllTestSuite } from "../../util/api"

const TestSuiteSelector = props => {
    const [options, setOptions] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        setIsLoading(true)
        try {
            const res = await getAllTestSuite({})
            setOptions(res.data.data.map(item => ({ value: item.id, label: item.name })))
        } catch (err) {
            console.log(err.message)
        }
        setIsLoading(false)
    }

    return <FormGroup>
        {props.label && <Label>{props.label} {props.require && <span style={{ color: "red" }}>*</span>}</Label>}
        <Select
            placeholder="Select test suite"
            options={options}
            isLoading={isLoading}
            isMulti={props.isNotMulti ? false : true}
            {...props}
        />
    </FormGroup>
}

export default TestSuiteSelector