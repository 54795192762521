import {
    ImageRun,
    Math,
    MathCurlyBrackets,
    MathFraction,
    MathRadical,
    MathRoundBrackets,
    MathRun,
    MathSquareBrackets,
    MathSubScript,
    MathSubSuperScript,
    MathSuperScript,
    Paragraph,
    ShadingType,
    Table,
    TableCell,
    TableRow,
    TextRun,
    UnderlineType,
    WidthType
} from "docx";
import { TYPES } from "./htmlToDocx";

const _base64ToArrayBuffer = (base64) => {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
};

const recursive = (obj, cb, parentNode, siblingNode) => {
    obj.forEach((item, index) => {
        cb(item, parentNode, siblingNode);

        if (item.children) {
            recursive(
                item.children,
                cb,
                item,
                index !== 0 ? obj[index - 1] : null
            );
        }
    });
};

const controller = node => {
    switch (node.type) {
        case TYPES.PARAGRAPH:
            return genParagraph(node);

        case TYPES.TEXT:
            return genTextRun(node);

        case TYPES.IMAGE:
            return genImage(node);

        case TYPES.TABLE:
            return genTable(node);

        case TYPES.TABLE_ROW:
            return genTableRow(node);

        case TYPES.TABLE_CELL:
            return genTableCell(node);

        case TYPES.MATH:
            return genMath(node);

        case TYPES.MATH_RUN:
            return genMathRun(node);

        case TYPES.MATH_FRACTION:
            return genMathFraction(node);

        case TYPES.MATH_RADICAL:
            return genMathRadical(node);

        case TYPES.MATH_SUPER_SCRIPT:
            return genMathSuperScript(node);

        case TYPES.MATH_SUB_SCRIPT:
            return genMathSubScript(node);

        case TYPES.MATH_SUB_SUPER_SCRIPT:
            return genMathSubSuperScript(node);

        case TYPES.MATH_SQUARE_BRACKETS:
            return genMathSquareBrackets(node);

        case TYPES.MATH_ROUND_BRACKETS:
            return genMathRoundBrackets(node);

        case TYPES.MATH_CURLY_BRACKETS:
            return genMathCurlyBrackets(node);

        default:
            break;
    }
};

const genParagraph = node => {
    return new Paragraph({
        children: node.children.map(item => {
            return controller(item);
        })
    });
};

const genTextRun = node => {
    return new TextRun({
        text: node.content,
        bold: node.bold,
        italics: node.italic,
        underline: node.underline
            ? {
                  type: UnderlineType.SINGLE,
                  color: "000000"
              }
            : false,
        break: node.break
    });
};

const genImage = node => {
    return new ImageRun({
        data: _base64ToArrayBuffer(node.base64),
        transformation: {
            width: [node.width],
            height: [node.height]
        }
    });
};

const genTable = node => {
    return new Table({
        rows: node.children.map(item => {
            return controller(item);
        }),
        columnWidths: [4505, 4505]
    });
};

const genTableRow = node => {
    return new TableRow({
        children: node.children.map(item => {
            return controller(item);
        }),
        height: {
            value: 400
        }
    });
};

const genTableCell = node => {
    return new TableCell({
        children: node.children.map(item => {
            return controller(item);
        }),
        width: {
            size: 4505,
            type: WidthType.DXA
        }
    });
};

const genMath = node => {
    return new Math({
        children: node.children.map(item => {
            return controller(item);
        })
    });
};

const genMathRun = node => {
    return new MathRun(node.content);
};

const genMathFraction = node => {
    return new MathFraction({
        numerator: node.numerator.map(item => {
            return controller(item);
        }),
        denominator: node.denominator.map(item => {
            return controller(item);
        })
    });
};

const genMathRadical = node => {
    return new MathRadical({
        children: node.children.map(item => {
            return controller(item);
        }),
        degree: node.degree
            ? node.degree.map(item => {
                  return controller(item);
              })
            : undefined
    });
};

const genMathSuperScript = node => {
    return new MathSuperScript({
        children: node.children.map(item => {
            return controller(item);
        }),
        superScript: node.superScript.map(item => {
            return controller(item);
        })
    });
};

const genMathSubScript = node => {
    return new MathSubScript({
        children: node.children.map(item => {
            return controller(item);
        }),
        subScript: node.subScript.map(item => {
            return controller(item);
        })
    });
};

const genMathSubSuperScript = node => {
    return new MathSubSuperScript({
        children: node.children.map(item => {
            return controller(item);
        }),
        superScript: node.superScript.map(item => {
            return controller(item);
        }),
        subScript: node.subScript.map(item => {
            return controller(item);
        })
    });
};

const genMathSquareBrackets = node => {
    return new MathSquareBrackets({
        children: node.children.map(item => {
            return controller(item);
        })
    });
};

const genMathRoundBrackets = node => {
    return new MathRoundBrackets({
        children: node.children.map(item => {
            return controller(item);
        })
    });
};

const genMathCurlyBrackets = node => {
    return new MathCurlyBrackets({
        children: node.children.map(item => {
            return controller(item);
        })
    });
};

export { controller };
