import { createSubject, deleteSubject, getAllSubjects, getSubject, updateSubject } from "../../../util/api";
import setLoading from "../../../components/Spinner/action";
import setModal from "../../../components/Modal/action";
import setAlert from "../../../components/Alert/action";

export const create = (data, cb) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await createSubject(data)
        if (!res.data) throw new Error("create_subject_failed")
        if (Array.isArray(res.data)) throw new Error(res.data.map(e => e.value))

        dispatch(setAlert({
            type: "success",
            message: "create_subject_successful"
        }))

        if (cb) cb()
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: err.message
        }))
    }

    dispatch(setLoading(false))
}

export const getAll = ({
    currentPage,
    pageSize,
    searchString,
    sortBy,
    sortOrder
}) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await getAllSubjects({
            currentPage,
            pageSize,
            searchString,
            sortBy,
            sortOrder
        })
        if (!res.data) throw new Error("get_all_subject_failed")

        dispatch({
            type: "SET_SUBJECT_LIST",
            payload: res.data.data,
            pageCount: res.data.pageCount,
            totalItems: res.data.totalItems,
            currentPage,
        })
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: err.message
        }))
    }

    dispatch(setLoading(false))
}

export const update = (data, cb) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await updateSubject(data)
        if (!res.data) throw new Error("update_subject_failed")
        if (Array.isArray(res.data)) throw new Error(res.data.map(e => e.value))

        dispatch(setAlert({
            type: "success",
            message: "update_subject_successful"
        }))

        if (cb) cb()
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: err.message
        }))
    }

    dispatch(setLoading(false))
}

export const remove = (id, cb) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await deleteSubject(id)
        if (!res.data) throw new Error("delete_subject_failed")

        dispatch(setAlert({
            type: "success",
            message: "delete_subject_successful"
        }))

        if (cb) cb()
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: err.message
        }))
    }

    dispatch(setLoading(false))
}

export const changePageSizeSubject = pageSize => async dispatch => {
    dispatch({
        type: "CHANGE_PAGE_SIZE_SUBJECT",
        payload: pageSize
    })
}
