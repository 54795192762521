import React, { useState } from "react"
import { Row, Col, Table, Input, Label } from "reactstrap"
import DeleteBtn from "../../../components/Button/DeleteBtn"
import CustomSelected from "../../../components/Selector/CustomSelected"

export default props => {
    const { t, dropZones, dropZoneLayout, onChangeDropZoneLayout, onAddZone, onDeleteZone } = props

    const [text, setText] = useState("")

    const layoutOptions = [
        { value: "Vertical", label: t("vertical") },
        { value: "Horizontal", label: t("horizontal") }
    ]

    return <Row className="mb-2">
        <Col md={4}>
            <Label>{t("drop_zone_layout")}</Label>
            <CustomSelected
                options={layoutOptions}
                value={dropZoneLayout
                    ? layoutOptions.find(option => option.value === dropZoneLayout)
                    : null
                }
                onChange={e => onChangeDropZoneLayout(e.value)}
            />
            <Label>{t("drop_zone_list")}</Label>
            <Table hover bordered striped responsive className="grid-table col-md-12 mb-2">
                <thead>
                    <tr>
                        <th>{t("zone_name")}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {dropZones && dropZones.map((zone, index) => <tr key={index}>
                        <td>{zone.text}</td>
                        <td className="text-center">
                            <DeleteBtn
                                onClick={() => onDeleteZone(zone.text)}
                            />
                        </td>
                    </tr>)}
                    <tr>
                        <td>
                            <Input
                                required
                                value={text}
                                onChange={e => setText(e.target.value)}
                                onKeyDown={e => {
                                    if (e.key === "Enter" && text && text.trim()) {
                                        onAddZone({ text: text.trim() })
                                        setText("")
                                    }
                                }}
                            />
                        </td>
                        <td className="text-center">
                            <div
                                style={{ cursor: "pointer", opacity: text && text.trim() ? 1 : 0.5 }}
                                title={t("add_new_zone")}
                                onClick={() => {
                                    if (!text || !text.trim()) return
                                    onAddZone({ text: text.trim() })
                                    setText("")
                                }}
                            >
                                <i className="fa fa-plus" />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Col>
    </Row>
}