import React, { useState } from "react"
import { Rnd } from "react-rnd"

import { computeImageUrl, guidGenerator } from "../../../util/helper"

const spotStyle = {
    fontWeight: "bold",
    fontSize: "1.25em",
    color: "#fff",
    backgroundColor: "rgba(255, 0, 0, 0.5)",
    opacity: 0.7,
    padding: "8px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between"
}

const hotSpotDragDropItemStyle = {
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    padding: "4px 8px",
    borderRadius: "4px",
    color: "#000"
}

const ImageManipulator = props => {
    const { t, imagePreviewUrl, fileUrl, spots, setSpots, isDisabled, hotSpotDragDropAnswers } = props

    return <div className="d-flex">
        <div style={{ position: "relative" }}>
            {spots && spots.length
                ? spots.map((item, index) => <Rnd
                    key={index}
                    style={spotStyle}
                    disableDragging={isDisabled}
                    bounds="parent"
                    size={{ width: item.width, height: item.height }}
                    position={{ x: item.x, y: item.y }}
                    onDragStop={(e, d) => {
                        setSpots(spots.map(spot => {
                            if (spot.id !== item.id) return spot
                            return { ...spot, x: d.x, y: d.y }
                        }))
                    }}

                    onResizeStop={(e, direction, ref, delta, position) => {
                        if (isDisabled) return
                        setSpots(spots.map(spot => {
                            if (spot.id !== item.id) return spot
                            return { ...spot, width: Number(ref.style.width.slice(0, -2)), height: Number(ref.style.height.slice(0, -2)), }
                        }))
                    }}
                >
                    <div className="flex-grow-1 p-mb-2">
                        <p>{index + 1}</p>
                        {hotSpotDragDropAnswers && hotSpotDragDropAnswers.length > 0 && <div>
                            {hotSpotDragDropAnswers
                                .filter(ans => ans.hotSpotIds.includes(item.id))
                                .map((ans, i) => <div key={i} style={hotSpotDragDropItemStyle} dangerouslySetInnerHTML={{ __html: ans.answer.text }} />)}
                        </div>}
                    </div>
                    {!isDisabled && <div>
                        <i
                            className="fa fa-times"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setSpots(spots.filter(spot => spot.id !== item.id))
                            }}
                        />
                    </div>}
                </Rnd>)
                : null}
            {/* Image is resized in Backend to 800x600 */}
            {(imagePreviewUrl || fileUrl) && 
				<div style={{ maxWidth: 800, maxHeight: 600 }}>
					<img
						style={{ cursor: "default", width: "100%", height: "100%", objectFit: "contain" }}
						alt="Hotspot question"
						src={imagePreviewUrl || computeImageUrl(fileUrl)}
						onClick={() => setSpots([...spots, { id: guidGenerator(), x: 0, y: 0, width: 70, height: 70 }])}
            		/>
				</div>
			}
        </div>
    </div>
}

export default ImageManipulator