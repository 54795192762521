import React, { useEffect } from "react"
import { connect } from "react-redux"
import { FormGroup, Label } from "reactstrap"
import Select from "react-select"

import { getQuestionCategory } from "../../containers/QuestionCategory/redux/action"

const QuestionCategorySelector = props => {
    const { noLabel, label, required, allQuestionCategory } = props

    useEffect(() => {
        props.getQuestionCategory({})
    }, [])

    const options = allQuestionCategory && allQuestionCategory.map(item => ({ value: item.id, label: `${item.path ? item.path + "/" : ""}${item.name}` }))

    return <FormGroup>
        {!noLabel && <Label>{label || "Question category"}{required && <span style={{ color: 'red' }}> *</span>}</Label>}
        <Select
            placeholder="Select category"
            options={options}
            {...props}
        />
    </FormGroup>
}

const mapStateToProps = state => ({
    allQuestionCategory: state.questionCategory.allQuestionCategory
})

const mapDispatchToProps = {
    getQuestionCategory
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionCategorySelector)