import { getAllQuestionCategory, createQuestionCategory, updateQuestionCategory, deleteQuestionCategory } from "../../../util/api"
import setLoading from "../../../components/Spinner/action"
import setModal from "../../../components/Modal/action"
import setAlert from "../../../components/Alert/action"

export const getQuestionCategory = ({ currentPage, pageSize, searchString, sortBy, sortOrder }) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await getAllQuestionCategory({ currentPage, pageSize, searchString, sortBy, sortOrder })

        if (!res.data) throw new Error("Error in getting question category")

        if (pageSize) {
            dispatch({
                type: "SET_QUESTION_CATEGORY",
                payload: res.data.data,
                currentPage: res.data.currentPage,
                sortBy: res.data.sortBy,
                sortOrder: res.data.sortOrder,
                totalItems: res.data.totalItems,
                pageCount: res.data.pageCount,
            })
        } else {
            dispatch({
                type: "SET_ALL_QUESTION_CATEGORY",
                payload: res.data.data
            })
        }
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: JSON.stringify(err.response ? err.response.data : err)
        }))
    }

    dispatch(setLoading(false))
}

export const changePageSizeQuestionCategory = pageSize => async dispatch => {
    dispatch({
        type: "CHANGE_PAGE_SIZE_QUESTION_CATEGORY",
        payload: pageSize
    })
}

export const createCategory = ({ name, skills, parentCategoryId, cb }) => async dispatch => {
    dispatch(setLoading(true))

    try {
        await createQuestionCategory({ name, skills, parentCategoryId })

        dispatch(setAlert({
            type: "success",
            message: "create_category_successfully"
        }))
        if (cb) cb()
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: err.response && err.response.data
        }))
    }

    dispatch(setLoading(false))
}

export const updateCategory = ({ id, name, skills, cb }) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await updateQuestionCategory({ id, name, skills })
        if (!res.data) throw new Error("Error in creating question category")

        if (cb) cb()
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: err.response.data
        }))
    }

    dispatch(setLoading(false))
}

export const deleteCategory = ({ id, cb }) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await deleteQuestionCategory(id)
        if (res.status !== 200) throw new Error("Error in creating question category")

        if (cb) cb()
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: err.response.data
        }))
    }

    dispatch(setLoading(false))
}