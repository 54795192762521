import React, { useEffect, useState } from "react";
import cn from "classnames";
import { format } from 'date-fns'
import { useHistory } from "react-router-dom";
import {
    CardBody,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    Table,
    NavLink,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Alert,
    Progress,
    Collapse,
    Card,
    Row,
    Col,
    FormGroup,
    Label,
    Input
    // ModalFooter
} from "reactstrap";
import { withTranslation } from "react-i18next";
import useFetch from "../../hooks/useFetch";
import useConfirm from "../../hooks/useConfirm";
import useMutation from "../../hooks/useMutation";
import useAlert from "../../hooks/useAlert";
import {
    getMyTasks,
    getQuestionByTaskId,
    publishQuestionByAuthor,
    undoQuestionReadyForTest
} from "../../util/api";
import { QUESTION_TYPE } from "../../util/Constant";
import RenderCategory from "../../components/RenderCategory";
import CustomPagination from "../../components/CustomPagination";
import Spinner from "../../components/Spinner";
import { cbtURL } from "./constants";

const MyTask = () => {
    const history = useHistory();

    const urlParams = new URLSearchParams(window.location.search);
    const taskIdQueryParam = urlParams.get("id");

    const { showConfirm } = useConfirm();
    const { showSuccess, showError, showWarning } = useAlert();
    const [activeTab, setActiveTab] = useState(Number(taskIdQueryParam || 0));
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [urlCBT, setUrlCBT] = useState("");
    const [modal, setModal] = useState(false);
    const [isOpenTaskInstruction, setIsOpenTaskInstruction] = useState(true);

    const toggleTaskInstruction = () => setIsOpenTaskInstruction(!isOpenTaskInstruction);
    // const [modalConfirmPublish, setModalConfirmPublish] = useState(false);
    const { data: tasks, isLoading: isFetchingTasks } = useFetch(getMyTasks);
    const {
        data: questionRows,
        reload: refetchQuestions,
        isLoading: isFetchingQuestions
    } = useFetch(
        () => {
            return getQuestionByTaskId(activeTab);
        },
        [activeTab],
        activeTab > 0
    );
    const {
        mutate: handleUndoQuestionReadyForTest,
        isLoading: isPublishing
    } = useMutation({
        mutationFn: undoQuestionReadyForTest,
        onSuccess: () => {
            refetchQuestions();
            showSuccess("Successfully");
        },
        onError: err => {
            showError("Failed: ", err.response.data.Message);
        }
    });

    // const {
    //     mutate: handlePublishTaskByAuthor,
    //     isLoading: isPublishingTask
    // } = useMutation({
    //     mutationFn: publishTaskByAuthor,
    //     onSuccess: () => {
    //         refetchQuestions();
    //         showSuccess("Publish task successfully");
    //     },
    //     onError: () => {
    //         showError("Failed");
    //     }
    // });

    useEffect(() => {
        window.addEventListener("message", e => {
            if (e.data.eventName === "cbtPopupClose") {
                window.location.reload();
            }
        });
    }, []);

    useEffect(() => {
        if (!questionRows) return;

        if (currentPage > Math.ceil(questionRows.length / pageSize)) {
            setCurrentPage(1);
        }
    }, [pageSize, questionRows]);

    useEffect(() => {
        document.title = "My tasks";
    }, []);

    if (!tasks && !Array.isArray(tasks)) return "";
    if (tasks.length === 0)
        return <h4>You have not been assigned any tasks.</h4>;

    if (!activeTab && !taskIdQueryParam && tasks) {
        setActiveTab(tasks[0].id);
    }

    const toggle = () => setModal(!modal);
    const closeModal = () => {
        setModal(false);
        window.location.reload();
    }

    // const toggleConfirmPublish = () => setModalConfirmPublish(!modalConfirmPublish)

    const onStartTesting = i => {
        setUrlCBT(
            `${cbtURL}/exam/question-creator?email=${i.email}&cmd=pretest&questionIds=${i.cbtQuestionIdsToTest}&task_id=${i.id}`
        );
        toggle();
    };

    const currentTask = tasks.find(item => item.id === activeTab) || {};

    const onStartTestOwnQuestion = (i) => {
        const wrongQuestion = i.wrongTestQuestion || "";
        setUrlCBT(
            `${cbtURL}/exam/question-creator?email=${i.email}&cmd=creatorRetest&task_id=${i.id}&qs=${wrongQuestion}`
        );
        toggle();
    };

    const handleChangeTab = nextTab => {
        setIsOpenTaskInstruction(true);
        setActiveTab(nextTab);
        history.push(`/question-bank/my-task?id=${nextTab}`);
    };

    // const publishTask = () => {
    //     console.log(145, currentTask)
    //     handlePublishTaskByAuthor(currentTask.id);
    //     toggleConfirmPublish();
    //     window.location.reload();
    // }

    return (
        <div>
            <Spinner
                isLoading={
                    isFetchingQuestions || isFetchingTasks || isPublishing
                    // isFetchingQuestions || isFetchingTasks || isPublishing || isPublishingTask
                }
            />

            <CardBody className="grid-body reduced-padding-grid">
                <Nav tabs>
                    {tasks &&
                        tasks.reverse().map(i => (
                            <NavItem key={i.id}>
                                <NavLink
                                    className={cn({
                                        active: activeTab === i.id
                                    })}
                                    onClick={() => {
                                        handleChangeTab(i.id);
                                    }}
                                >
                                    {i.name}
                                </NavLink>
                            </NavItem>
                        ))}
                </Nav>

                <TabContent
                    activeTab={activeTab}
                    className="border-bottom-0 border-left-0 border-right-0 reduced-padding-tabcontent"
                >
                    {tasks &&
                        tasks.reverse().map(i => {
                            if (+currentTask.id !== i.id) return <></>;

                            if (
                                !currentTask.isTestRequired ||
                                (currentTask.isApproved && currentTask.score)
                            ) {
                                return (
                                    <TabPane key={i.id} tabId={i.id}>
                                        <div>
                                            {i.taskInstruction
                                                ? <Row >
                                                    <Col md={12}>
                                                        <FormGroup className="m-0">
                                                            <Label for="taskInstruction">
                                                                <h5 className="mr-1 user-select-none">Task Instruction</h5>
                                                            </Label>

                                                            <Input
                                                                checked={isOpenTaskInstruction}
                                                                type="checkbox"
                                                                id="taskInstruction"
                                                                name="taskInstruction"
                                                                className="m-0 position-relative"
                                                                onClick={() => toggleTaskInstruction()}
                                                            />

                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                : null
                                            }
                                            {i.taskInstruction
                                                ? <Row>
                                                    <Col md={12} className={isOpenTaskInstruction ? "p-2" : ""}>
                                                        <Collapse isOpen={isOpenTaskInstruction}>
                                                            <Card>
                                                                <CardBody className="p-3">
                                                                    <div dangerouslySetInnerHTML={{ __html: i.taskInstruction }}></div>
                                                                </CardBody>
                                                            </Card>
                                                        </Collapse>
                                                    </Col>
                                                </Row>
                                                : null
                                            }
                                            <Progress
                                                className="mb-2"
                                                value={
                                                    questionRows
                                                        ? questionRows.length
                                                        : 0
                                                }
                                                max={currentTask.maxQuestion}
                                            >
                                                Questions created:{" "}
                                                {questionRows
                                                    ? questionRows.length
                                                    : 0}
                                                /{currentTask.maxQuestion}
                                            </Progress>

                                            {/* {questionRows && questionRows.length === currentTask.maxQuestion
                                                ? <Button color="success" onClick={() => toggleConfirmPublish(i)}>
                                                Publish Task
                                                </Button>
                                                : null
                                            } */}
                                            {questionRows &&
                                                questionRows.length !==
                                                currentTask.maxQuestion ? (
                                                <Button
                                                    color="success"
                                                    onClick={() => {
                                                        if (
                                                            questionRows.length >=
                                                            currentTask.maxQuestion
                                                        ) {
                                                            showWarning(
                                                                "You have created the maximum number of questions"
                                                            );
                                                            return;
                                                        }

                                                        history.push(
                                                            `/new-question?taskId=${activeTab}`
                                                        );
                                                    }}
                                                    style={{
                                                        width: "140px"
                                                    }}
                                                >
                                                    <i className="fa fa-plus mr-2"></i>
                                                    Create Question
                                                </Button>
                                            ) : null}
                                            {!i.isReadyRetest && !i.isCompleted && questionRows && questionRows.length === currentTask.maxQuestion
                                                ? <span style={{ color: 'red', fontSize: '18px', fontStyle: "italic" }}>You need update all question to ready for test</span>
                                                : null
                                            }
                                            {i.isReadyRetest && !i.isCompleted
                                                ? (<Button color="primary" onClick={() => onStartTestOwnQuestion(i)}>
                                                    Test your own questions
                                                </Button>
                                                ) : null}

                                            {i.isCompleted && i.isPublished && (
                                                <span
                                                    style={{
                                                        color: "red",
                                                        fontSize: "18px",
                                                        fontStyle: "italic"
                                                    }}
                                                >
                                                    {" "}
                                                    Your task is published
                                                </span>
                                            )}

                                            {/* {i.isCompleted
                                                    ? <Button color="primary" >
                                                    Complete Task
                                                    </Button>
                                                    : null
                                                } */}

                                            <Table
                                                id="table-questions"
                                                hover
                                                bordered
                                                striped
                                                responsive
                                                className="grid-table mb-2 mt-2"
                                            >
                                                <thead>
                                                    <tr>
                                                        <th
                                                            style={{
                                                                width: "30%"
                                                            }}
                                                        >
                                                            Question
                                                        </th>
                                                        <th style={{ width: "10%" }}>
                                                            Question Number
                                                        </th>
                                                        <th style={{ width: "5%" }}>
                                                            File Name
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: "8%"
                                                            }}
                                                        >
                                                            Spend time
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: "7%"
                                                            }}
                                                        >
                                                            Type
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: "10%"
                                                            }}
                                                        >
                                                            Category
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: "10%"
                                                            }}
                                                            className="text-center"
                                                        >
                                                            Status
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: "15%"
                                                            }}
                                                            className="text-center"
                                                        >
                                                            Created/Last updated
                                                        </th>
                                                        <th
                                                            style={{
                                                                width: "5%"
                                                            }}
                                                            className="text-center"
                                                        >
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {questionRows &&
                                                        questionRows
                                                            .map((e, index) => {
                                                                const hours = Math.floor(
                                                                    e.createQuestionTimeInSeconds /
                                                                    3600
                                                                );
                                                                const minutes = Math.floor(
                                                                    (e.createQuestionTimeInSeconds -
                                                                        hours *
                                                                        3600) /
                                                                    60
                                                                );
                                                                const seconds =
                                                                    e.createQuestionTimeInSeconds -
                                                                    hours *
                                                                    3600 -
                                                                    minutes *
                                                                    60;

                                                                return (
                                                                    <tr
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <td>
                                                                            <div
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html:
                                                                                        e.questionText
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td>{e.questionNumber > 0 ? String(e.questionNumber).padStart(4, '0') : ""}</td>
                                                                        <td>{e.fileName}</td>
                                                                        <td>
                                                                            {hours <
                                                                                10
                                                                                ? `0${hours}`
                                                                                : hours}
                                                                            :
                                                                            {minutes <
                                                                                10
                                                                                ? `0${minutes}`
                                                                                : minutes}
                                                                            :
                                                                            {seconds <
                                                                                10
                                                                                ? `0${seconds}`
                                                                                : seconds}
                                                                        </td>
                                                                        <td>
                                                                            {e.type ===
                                                                                0
                                                                                ? QUESTION_TYPE.SINGLE_CHOICE
                                                                                : QUESTION_TYPE.MULTIPLE_CHOICE}
                                                                        </td>
                                                                        <td>
                                                                            <RenderCategory
                                                                                categoryId={
                                                                                    e.categoryId
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {e.isPublished && <span className="text-primary text-bold">Published</span>}
                                                                            {(!e.isPublished && e.isReadyForTest) && <span className="text-primary text-bold">Ready for test</span>}
                                                                            {(!e.isPublished && !e.isReadyForTest) && <span>Not ready for test</span>}
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <p>{format(new Date(e.createAt), 'MM/dd/yyyy HH:mm:ss')}</p>
                                                                            <p>{format(new Date(e.updateAt), 'MM/dd/yyyy HH:mm:ss')}</p>
                                                                        </td>

                                                                        <td className="text-center">
                                                                            <i
                                                                                style={{
                                                                                    cursor:
                                                                                        "pointer"
                                                                                }}
                                                                                onClick={() => {
                                                                                    window.open(
                                                                                        `/preview-question/${e.id}?origin=my-task&action=preview`,
                                                                                        "_blank"
                                                                                    );
                                                                                }}
                                                                                className="fa fa-eye mr-2"
                                                                                aria-hidden="true"
                                                                            ></i>
                                                                            {!e.isReadyForTest && (
                                                                                <i
                                                                                    style={{
                                                                                        cursor:
                                                                                            "pointer"
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        history.push(
                                                                                            `/edit-question/${e.id}`
                                                                                        );
                                                                                    }}
                                                                                    className="fa fa-pencil mr-2"
                                                                                    aria-hidden="true"
                                                                                ></i>
                                                                            )}
                                                                            {e.isReadyForTest && !i.isCompleted && !i.isPublished && (
                                                                                <i
                                                                                    style={{
                                                                                        cursor:
                                                                                            "pointer"
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        showConfirm(
                                                                                            {
                                                                                                message:
                                                                                                    "Are you sure you want to change the status of this question to not ready for test ?",
                                                                                                onConfirm: () => {
                                                                                                    handleUndoQuestionReadyForTest(e.id);
                                                                                                }
                                                                                            }
                                                                                        );
                                                                                    }}
                                                                                    className="fa fa-undo mr-2"
                                                                                    aria-hidden="true"
                                                                                ></i>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                            .reverse()
                                                            .slice(
                                                                (currentPage -
                                                                    1) *
                                                                pageSize,
                                                                currentPage *
                                                                pageSize
                                                            )}
                                                </tbody>
                                            </Table>

                                            <CustomPagination
                                                pageCount={
                                                    questionRows
                                                        ? Math.ceil(
                                                            questionRows.length /
                                                            pageSize
                                                        )
                                                        : 0
                                                }
                                                pageSize={pageSize}
                                                currentPage={currentPage}
                                                // pageSizeName={}
                                                changePage={e => {
                                                    setCurrentPage(e);
                                                }}
                                                changePageSize={e => {
                                                    setPageSize(e);
                                                }}
                                                onChangePageSize={() => { }}
                                            />
                                        </div>
                                    </TabPane>
                                );
                            }

                            if (
                                currentTask.isTestRequired &&
                                !currentTask.score
                            ) {
                                return (
                                    <TabPane key={i.id} tabId={i.id}>
                                        <Button
                                            color="primary"
                                            onClick={() => onStartTesting(i)}
                                        >
                                            Start testing
                                        </Button>
                                    </TabPane>
                                );
                            }

                            if (!currentTask.isApproved && currentTask.score) {
                                return (
                                    <TabPane key={i.id} tabId={i.id}>
                                        <Alert color="primary">
                                            Need admin approval before creating
                                            questions
                                        </Alert>
                                    </TabPane>
                                );
                            }
                        })}
                </TabContent>
            </CardBody>
            <Modal
                style={{ height: "100vh", maxWidth: "100vw", marginTop: 0 }}
                isOpen={modal}
                toggle={toggle}
            >
                <ModalHeader
                    toggle={closeModal}
                // style={{ padding: "0.5rem" }}
                ></ModalHeader>
                <ModalBody className="p-0">
                    <iframe
                        src={urlCBT}
                        style={{
                            height: "calc(100vh - 54px)",
                            width: "100vw",
                            border: "none",
                            display: "block"
                        }}
                        title="exam"
                    />
                </ModalBody>
            </Modal>
            {/* <Modal
                // style={{ height: "100vh", maxWidth: "100vw", marginTop: 0 }}
                isOpen={modalConfirmPublish}
                toggle={toggleConfirmPublish}
                centered
            >
                <ModalHeader
                    toggle={toggleConfirmPublish}
                    // style={{ padding: "0.5rem" }}
                >Confirm publish task</ModalHeader>
                <ModalBody>
                    Do you confirm publish task ? ( You can&apos;t edit question after publish task )
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={publishTask}>
                        Confirm
                    </Button>
                    <Button color="secondary" onClick={toggleConfirmPublish}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal> */}
        </div>
    );
};

export default withTranslation("common")(MyTask);
