import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import classnames from "classnames";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Input
} from "reactstrap";
import useMutation from "../../../hooks/useMutation";
import useAlert from "../../../hooks/useAlert";
import { rejectQuestion } from "../../../util/api";
import Spinner from "../../../components/Spinner";

const RejectDialog = ({ isOpen, toggle }) => {
    const { id: questionId } = useParams();
    const [reason, setReason] = useState("");
    const [error, setError] = useState("");
    const { showSuccess, showError } = useAlert();
    const { mutate: handleReject, isLoading } = useMutation({
        mutationFn: rejectQuestion,
        onSuccess: () => {
            showSuccess("Successfully!");
            toggle();
        },
        onError: (err) => {
            showError("Failed: Cannot reject");
        }
    });

    useEffect(() => {
        setReason("");
        setError("");
    }, [isOpen]);

    const handleChange = e => {
        const { value } = e.target;

        setReason(value);

        if (value.trim() !== "") {
            setError("");
        }
    };

    const handleSubmit = () => {
        if (!reason.trim()) {
            setError("Reason is required!");
            document.getElementById("reason").focus();
            return;
        }

        handleReject(questionId, reason);
    };

    return (
        <>
            {isLoading && <Spinner isLoading />}

            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>Reject question</ModalHeader>
                <ModalBody>
                    <label htmlFor="reason">
                        Reason
                        <span
                            style={{
                                color: "red"
                            }}
                        >
                            *
                        </span>
                    </label>

                    <Input
                        value={reason}
                        className={classnames({
                            "is-invalid": !!error
                        })}
                        rows={5}
                        id="reason"
                        name="text"
                        type="textarea"
                        onChange={handleChange}
                    />
                    {error && (
                        <span
                            style={{
                                color: "red"
                            }}
                        >
                            {error}
                        </span>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={toggle} className="px-3" color="secondary">
                        Close
                    </Button>

                    <Button
                        onClick={handleSubmit}
                        className="px-3"
                        color="primary"
                    >
                        Submit
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default RejectDialog;
