import React from "react"
import { useSelector } from "react-redux"
import { withTranslation } from 'react-i18next'

import CustomTabs from "../../../components/CustomTabs"

import Home from "./Home"
import Question from "../../Question/view"
import QuestionCategory from "../../QuestionCategory/view"
import Article from "../../Article/view"
import CreateQuestionAssignment from "../../CreateQuestionAssignment"
// import TestPack from "../../TestPack/view"
// import TestSuite from "../../TestSuite/view"
import MyTask from "../../MyTask"
import { canAccess } from "../../../util/Common"
import QuestionReview from "../../QuestionReview"

const ELearningHomePage = props => {
    const { t } = props
    const userInfo = useSelector(state => state.common.userInfo);

    const tabs = [
        // { path: "/question-bank/assessmentAssignments", title: t("assessmentAssignment.title"), component: AssessmentsAssignmentList },
        { roles: [""], path: "/question-bank/assessments", title: t("assessment_management"), component: Home },
        { roles: ["Admin"], path: "/question-bank/questions", title: t("Questions"), component: Question },
        { roles: [""], path: "/question-bank/question-categories", title: t("question_category"), component: QuestionCategory },
        { roles: ["Question creator"], path: "/question-bank/articles", title: t("article"), component: Article },
        { roles: ["Admin"], path: "/question-bank/create-task-assignment", title: t("Create Task Assignment"), component: CreateQuestionAssignment },
        { roles: ["Question creator"], path: "/question-bank/my-task", title: "My tasks", component: MyTask},
        { roles: ["Proof reader"], path: "/question-bank/question-review", title: "My question review", component: QuestionReview},

    ]   

    const filterTabs = tabs.filter(({ roles }) => canAccess(userInfo && userInfo.roles, roles));

    return <CustomTabs
        {...props}
        // title={t("assessment.title_upper")}
        title="QUESTION BANK"
        // tabs={tabs}
        tabs={filterTabs}
    />
}

export default withTranslation("common")(ELearningHomePage)

