import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Row, Col, Input, Label, Button, CustomInput } from "reactstrap"
import { withTranslation } from "react-i18next"
import {CKEditor} from "ckeditor4-react"
import { Formik } from "formik"
import * as yup from "yup"
import _ from "lodash"

import ErrorHandler from "../../../components/ErrorHandler"
import LabelRequired from "../../../components/LabelRequired"
import QuestionCategorySelector from "../../../components/Selector/QuestionCategorySelector"
import QuestionTypeSelector from "../../../components/Selector/QuestionTypeSelector"
import ArticleSelector from "../../../components/Selector/ArticleSelector"
import Coordinate from "./Coordinate"
import AnswerList from "./AnswerList"
import OrderingAnswer from "./OrderingAnswer"
import QuestionImageManipulator from "./QuestionImageManipulator"
import DropZones from "./DropZones"

import { questionTypes, QUESTION_TYPE } from "../../../util/Constant"
import {
  computeImageUrl,
  getTextFromHTML,
  guidGenerator,
} from "../../../util/helper"
import { uploadFiles } from "../../User/redux/action"

import { create, update, get } from "../redux/action"
import setModal from "../../../components/Modal/action"

const defaultCoordinate = [{ id: 1, text: "" }]

const defaultAnswerList = [
  {
    id: 1,
    text: "",
    isCorrectAnswer: false,
    mark: 0,
    value: [],
    inputValue: "",
    correctOrder: 0,
    matchingText: "",
  },
  {
    id: 2,
    text: "",
    isCorrectAnswer: false,
    mark: 0,
    value: [],
    inputValue: "",
    correctOrder: 0,
    matchingText: "",
  },
]

const EditQuestion = (props) => {
  const {
    match: {
      params: { id },
    },
    t,
    userInfo,
  } = props

  // control image file hotspot
  const [file, setFile] = useState("")
  const [fileUrl, setFileUrl] = useState(null)
  const [imageChanged, setImageChanged] = useState(false)
  const [spots, setSpots] = useState([])

  // control question text for blank types
  let ckeditor
  let ckeditor_explain_answer
  let ckeditor_hint

  const [blanks, setBlanks] = useState([])

  // control action after creating question
  const [exit, setExit] = useState(false)

  // control creating Composite type
  const [compositeQuestion, setCompositeQuestion] = useState(null)
  const [editingComposite, setEditingComposite] = useState(false)

  // coordinate
  const [xCoordinate, setXCoordinate] = useState(defaultCoordinate)
  const [yCoordinate, setYCoordinate] = useState(defaultCoordinate)

  const [article, setArticle] = useState({})

  const { allQuestionCategory, get, history, uploadFiles, allArticles } = props

  const schema = yup.object({
    type: yup.string().required(t("question_type_required")),
    categoryId: yup
      .number()
      .required(t("question_category_required"))
      .nullable(),
    text: yup
      .string()
      .required(t("question_text_required"))
      .nullable(),
    title: yup
      .string()
      .required(t("question_title_required"))
      .nullable(),
    negativeMark: yup.number(),
    hint: yup.string().nullable(),
    explainAnswer: yup.string().nullable(),
    mark: yup.number(),
    // mark: yup.number().required(t("mark_required")).test("markPositive", t("mark_required"), value => value > 0),
    difficultyLevel: yup.number(),
    answers: yup.array().of(
      yup.object().shape({
        token: yup.string().nullable(),
        text: yup.string().nullable(),
        correctOrder: yup.number(),
        correctNumber: yup.number(),
        isCorrectAnswer: yup.boolean(),
        mark: yup.number(),
      })
    ),
    hotSpotOptions: yup
      .array()
      .of(
        yup.object().shape({
          identifier: yup.string().nullable(),
          text: yup.string().nullable(),
          width: yup.number().nullable(),
          height: yup.number().nullable(),
          xCoordinate: yup.number().nullable(),
          yCoordinate: yup.number().nullable(),
        })
      )
      .nullable(),
    imageUrl: yup.string().nullable(),
    dropZones: yup
      .array()
      .of(
        yup.object().shape({
          text: yup.string(),
        })
      )
      .nullable(),
    dropZoneLayout: yup.string().oneOf(["Vertical", "Horizontal"]),
  })

  const getNextId = (arr) => {
    try {
      if (!arr || !arr.length) return 1
      const maxIdItem = _.maxBy(arr, "id")
      if (maxIdItem) return maxIdItem.id + 1
      return 1
    } catch {
      return 1
    }
  }
  const createMarkup = (content) => {
    if (content) return { __html: content }
  }

  return (
    <Formik
      initialValues={{
        type: "",
        articleId: null,
        categoryId: null,
        text: null,
        title: null,
        negativeMark: 0,
        hint: null,
        explainAnswer: null,
        mark: 0,
        difficultyLevel: 0,
        answers: defaultAnswerList,
        hotSpotOptions: [],
        imageUrl: "",
        dropZones: [],
        dropZoneLayout: "Horizontal",
      }}
      validationSchema={schema}
      onSubmit={async (values, actions) => {
        try {
          values.text = !!ckeditor && !!ckeditor.editor && ckeditor.editor.getData()
          values.explainAnswer = !!ckeditor_explain_answer && !!ckeditor_explain_answer.editor && ckeditor_explain_answer.editor.getData()
          values.hint = !!ckeditor_hint && !!ckeditor_hint.editor && ckeditor_hint.editor.getData()
          const allCkIfr = document.getElementsByClassName("ckeditoranswer")
          values.answers.forEach((a, index) => {
            let iframe
            if (!!allCkIfr && !!allCkIfr[index]) iframe = allCkIfr[index].getElementsByTagName("iframe")[0]
            if (!!iframe && !!iframe.contentDocument) {
              a.text = iframe.contentDocument.getElementsByTagName(
                "body"
              )[0].innerHTML
            }
          })
          if (!values.explainAnswer) {
            values.explainAnswer = ""
          }

          if (!values.hint) {
            values.hint = ""
          }

          // set all answers to isCorrectAnswer true for some types
          if (
            [
              QUESTION_TYPE.MATCH_THE_PAIRS,
              QUESTION_TYPE.DROPZONE_DRAG_DROP,
            ].includes(values.type)
          ) {
            if (values.answers && values.answers.length) {
              values.answers = values.answers.map((ans) => ({
                ...ans,
                isCorrectAnswer: true,
              }))
            }
          }

          if (
            ![
              QUESTION_TYPE.COMPOSITE,
              QUESTION_TYPE.SHORT_ANSWER,
              QUESTION_TYPE.EXTENDED_RESPONSE,
              QUESTION_TYPE.FILL_IN_THE_BLANK_TEXT,
              QUESTION_TYPE.FILL_IN_THE_BLANK_DROPDOWN,
              QUESTION_TYPE.INLINE_ORDER_DRAG_DROP,
              QUESTION_TYPE.FILL_IN_BLANK_DRAG_DROP,
              QUESTION_TYPE.HOTSPOT_DRAG_DROP,
            ].includes(values.type)
          ) {
            if (
              !values.answers ||
              !values.answers.length ||
              !values.answers.find((item) => item.isCorrectAnswer) ||
              !values.answers
                .filter((item) => item.isCorrectAnswer)
                .every((item) => item.mark)
            ) {
              props.setModal({
                type: "danger",
                message: "answer_error_create_question",
              })

              return
            }

            if (
              values.answers.find((item) => !item.text || !item.text.trim())
            ) {
              props.setModal({
                type: "danger",
                message: "answer_must_have_text",
              })

              return
            }
          }

          if (
            [
              QUESTION_TYPE.EXTENDED_RESPONSE,
              QUESTION_TYPE.SHORT_ANSWER,
            ].includes(values.type)
          ) {
            if (
              !values.answers ||
              !values.answers[0] ||
              !values.answers[0].mark
            ) {
              props.setModal({
                type: "danger",
                message: "mark_must_be_positive_number",
              })

              return
            }
          }

          // check blanks duplication
          if (
            [
              QUESTION_TYPE.FILL_IN_BLANK_DRAG_DROP,
              QUESTION_TYPE.FILL_IN_THE_BLANK_DROPDOWN,
              QUESTION_TYPE.FILL_IN_THE_BLANK_TEXT,
              QUESTION_TYPE.HOT_TEXT,
            ].includes(values.type)
          ) {
            if (blanks.some((item, index) => blanks.indexOf(item) !== index)) {
              props.setModal({
                type: "danger",
                message: "duplicate_token_in_answer",
              })

              return
            }
          }

          if (
            [
              QUESTION_TYPE.FILL_IN_THE_BLANK_TEXT,
              QUESTION_TYPE.MATCH_THE_PAIRS,
            ].includes(values.type)
          ) {
            if (values.answers.find((item) => !item.mark)) {
              props.setModal({
                type: "danger",
                message: "mark_must_be_positive_number",
              })

              return
            }
          }

          if (values.type !== QUESTION_TYPE.COMPOSITE) {
            if (compositeQuestion) {
              values.parentQuestionId = _.get(compositeQuestion, "id", null)
            }
          }

          if (
            [
              QUESTION_TYPE.MATRIX_CHECKBOX,
              QUESTION_TYPE.MATRIX_RADIO,
            ].includes(values.type)
          ) {
            if (xCoordinate.length <= 1 && yCoordinate.length <= 1) {
              props.setModal({
                type: "danger",
                message:
                  "this_question_need_more_than_one_coordinate_of_x_or_y",
              })
              return
            }

            const xCoordinateCloner = xCoordinate.map((item) => ({
              text: item.text,
            }))
            const yCoordinateCloner = yCoordinate.map((item) => ({
              text: item.text,
            }))

            values.xCoordinateOptions = xCoordinateCloner
            values.yCoordinateOptions = yCoordinateCloner
          }

          // blank text
          if (
            [
              QUESTION_TYPE.FILL_IN_THE_BLANK_TEXT,
              QUESTION_TYPE.FILL_IN_BLANK_DRAG_DROP,
            ].includes(values.type)
          ) {
            const noToken = blanks.find((blank) =>
              values.answers.every((ans) => ans.token !== blank)
            )

            if (noToken) {
              props.setModal({
                type: "danger",
                message: "please_select_answer_for_all_blank",
              })

              return
            }

            const tokenAnswers = values.answers.filter(
              (ans) => ans.token && ans.token.trim()
            )
            if (tokenAnswers.find((item) => !item.mark)) {
              props.setModal({
                type: "danger",
                message: "mark_must_be_positive_number",
              })

              return
            }

            if (blanks.length !== tokenAnswers.length) {
              props.setModal({
                type: "danger",
                message: "duplicate_token_in_answer",
              })

              return
            }

            values.answers = values.answers.map((item) => ({
              token: item.token,
              text: item.text,
              mark: item.mark,
            }))
          }

          // blank dropdown
          if (QUESTION_TYPE.FILL_IN_THE_BLANK_DROPDOWN === values.type) {
            if (
              !values.answers ||
              !values.answers.length ||
              !values.answers.every((item) => item.mark)
            ) {
              props.setModal({
                type: "danger",
                message: "mark_must_be_positive_number",
              })

              return
            }

            if (
              values.answers.find(
                (answer) =>
                  (answer.value && answer.value.length < 2) || !answer.value
              )
            ) {
              props.setModal({
                type: "danger",
                message: "please_create_at_least_2_option",
              })

              return
            }

            if (
              values.answers.find(
                (answer) =>
                  answer.value &&
                  !answer.value.find((item) => item.isCorrectAnswer)
              )
            ) {
              props.setModal({
                type: "danger",
                message: "please_choose_correct_answer",
              })

              return
            }

            let answerModel = []
            for (const answer of values.answers) {
              for (const option of answer.value) {
                const newAnswer = {
                  token: answer.token,
                  text: option.value,
                  mark: option.isCorrectAnswer ? answer.mark : 0,
                  isCorrectAnswer: option.isCorrectAnswer || false,
                }

                answerModel = [...answerModel, newAnswer]
              }
            }

            values.answers = answerModel
          }

          // hot text
          if (QUESTION_TYPE.HOT_TEXT === values.type) {
            values.answers = values.answers.map((item) => ({
              token: item.token,
              isCorrectAnswer: item.isCorrectAnswer,
              mark: item.mark,
              text: item.text,
            }))
          }

          // hotspot
          if (QUESTION_TYPE.HOTSPOT === values.type) {
            values.answers = values.answers.map((item) => ({
              text: item.text,
              mark: item.mark,
              isCorrectAnswer: item.isCorrectAnswer,
            }))

            // upload image to server
            if (imageChanged || compositeQuestion) {
              const formData = new FormData()
              const { name } = file
              formData.append("filesUpload", file, name)

              const data = await uploadFiles(formData)
              const imageUrl = data ? data[0] : ""

              values.imageUrl = imageUrl
            }
          }

          // inline order drag drop
          if (QUESTION_TYPE.INLINE_ORDER_DRAG_DROP === values.type) {
            if (
              !values.answers ||
              !values.answers.length ||
              values.answers.find((ans) => !ans.text || !ans.text.trim())
            ) {
              props.setModal({
                type: "danger",
                message: "answer_must_have_text",
              })

              return
            }

            if (values.answers.length < 2) {
              props.setModal({
                type: "danger",
                message: "please_create_at_least_2_option_to_order",
              })

              return
            }

            if (!values.mark || values.mark < 0) {
              props.setModal({
                type: "danger",
                message: "mark_must_be_positive_number",
              })

              return
            }

            // check order
            const length = values.answers.length
            const orderArray = Array.from(
              { length },
              (item, index) => index + 1
            )

            for (const order of orderArray) {
              const thisOrder = values.answers.find(
                (ans) => ans.correctOrder === order
              )

              if (!thisOrder) {
                props.setModal({
                  type: "danger",
                  message: "please_select_valid_order",
                })

                return
              }
            }

            values.answers = values.answers.map((ans) => ({
              ...ans,
              mark: values.mark,
            }))
          }

          // match the pairs check matching text
          if (QUESTION_TYPE.MATCH_THE_PAIRS === values.type) {
            if (
              values.answers.find(
                (ans) => !ans.matchingText || !ans.matchingText.trim()
              )
            ) {
              props.setModal({
                type: "danger",
                message: "please_fill_in_all_matching_text",
              })

              return
            }
          }

          // hotspot drag drop
          if (QUESTION_TYPE.HOTSPOT_DRAG_DROP === values.type) {
            if ((!id && file === "") || !spots || !spots.length) {
              props.setModal({
                type: "danger",
                message: "please_select_image_and_hotspots",
              })

              return
            }

            if (spots.length < 2 && values.answers.length < 2) {
              props.setModal({
                type: "danger",
                message: "please_select_at_least_2_spots_or_answers",
              })

              return
            }

            if (values.answers.every((item) => !item.matchingText)) {
              props.setModal({
                type: "danger",
                message: "please_select_hotspot_for_at_least_1_answer",
              })

              return
            }

            if (
              values.answers.find(
                (item) =>
                  (item.mark > 0 && !item.matchingText) ||
                  (item.mark <= 0 && item.matchingText)
              )
            ) {
              props.setModal({
                type: "danger",
                message:
                  "Please select both positive mark and hotspot for correct answers",
              })

              return
            }

            if (
              values.answers.some((answer, index) =>
                values.answers.some(
                  (a, i) =>
                    a.matchingText &&
                    a.matchingText === answer.matchingText &&
                    i !== index
                )
              )
            ) {
              props.setModal({
                type: "danger",
                message: "One spot cannot have multiple answers",
              })
              return
            }

            if (imageChanged || compositeQuestion) {
              const formData = new FormData()
              const { name } = file
              formData.append("filesUpload", file, name)

              const data = await uploadFiles(formData)
              const imageUrl = data ? data[0] : ""

              values.imageUrl = imageUrl
            }

            values.answers = values.answers.map((ans) => ({
              text: ans.text,
              matchingText: ans.matchingText,
              mark: ans.mark,
              isCorrectAnswer: ans.isCorrectAnswer,
            }))
          }

          // dropzones drag drop
          if (QUESTION_TYPE.DROPZONE_DRAG_DROP === values.type) {
            if (!values.dropZones || !values.dropZones.length) {
              props.setModal({
                type: "danger",
                message: "please_create_at_least_1_zone",
              })

              return
            }

            if (
              !values.answers.every(
                (ans) => ans.matchingText && ans.matchingText.trim()
              )
            ) {
              props.setModal({
                type: "danger",
                message: "please_choose_drop_zone_for_all_answer",
              })

              return
            }

            values.answers = values.answers.map((ans) => ({
              text: ans.text,
              mark: ans.mark,
              matchingText: ans.matchingText,
            }))
          }
          let data = null
          if (
            !id ||
            (editingComposite && values.type !== QUESTION_TYPE.COMPOSITE)
          ) {
            data = await props.create(values)
          } else {
            data = await props.update(values)
          }

          if (data) {
            if (exit) {
              props.history.push("/question-bank/questions")
            } else {
              actions.resetForm()
              if (values.type === QUESTION_TYPE.COMPOSITE) {
                setCompositeQuestion(data)
              }
            }
          }
        } catch (err) {
          props.setModal({
            type: "danger",
            message: err.message,
          })
        }
      }}
    >
      {(props) => {
        // answer list manage
        const isMultipleChoice =
          props.values.type &&
          [
            QUESTION_TYPE.MULTIPLE_CHOICE,
            QUESTION_TYPE.MATRIX_CHECKBOX,
            QUESTION_TYPE.HOTSPOT,
          ].includes(props.values.type)

        const isOnlyOneAnswer =
          props.values.type &&
          [
            QUESTION_TYPE.SHORT_ANSWER,
            QUESTION_TYPE.EXTENDED_RESPONSE,
          ].includes(props.values.type)

        const isMatrix =
          props.values.type &&
          [QUESTION_TYPE.MATRIX_RADIO, QUESTION_TYPE.MATRIX_CHECKBOX].includes(
            props.values.type
          )

        const isBlankText =
          QUESTION_TYPE.FILL_IN_THE_BLANK_TEXT === props.values.type
        const isBlankDropDown =
          QUESTION_TYPE.FILL_IN_THE_BLANK_DROPDOWN === props.values.type

        useEffect(() => {
          if (isOnlyOneAnswer) {
            props.setFieldValue("answers", [
              { id: 1, text: "", isCorrectAnswer: true, mark: 0 },
            ])
          }

          if (isMatrix) {
            setXCoordinate(defaultCoordinate)
            setYCoordinate(defaultCoordinate)
          }

          if (!id) {
            if (
              isBlankText ||
              isBlankDropDown ||
              [
                QUESTION_TYPE.HOT_TEXT,
                QUESTION_TYPE.FILL_IN_BLANK_DRAG_DROP,
              ].includes(props.values.type)
            ) {
              props.setFieldValue("answers", [
                {
                  id: guidGenerator(),
                  token: "blank1",
                  text: "",
                  isCorrectAnswer: true,
                  mark: 0,
                  value: [],
                  inputValue: "",
                },
              ])
              if (ckeditor) {
                ckeditor.editor.insertHtml(`{blank1}`)
              }

              return
            }

            if (
              [
                QUESTION_TYPE.HOTSPOT_DRAG_DROP,
                QUESTION_TYPE.DROPZONE_DRAG_DROP,
              ].includes(props.values.type)
            ) {
              props.setFieldValue("answers", [
                {
                  id: guidGenerator(),
                  text: "",
                  isCorrectAnswer: true,
                  mark: 0,
                  matchingText: "",
                },
              ])

              return
            }

            if (QUESTION_TYPE.HOTSPOT === props.values.type) {
              props.setFieldValue("answers", [])
            } else {
              props.setFieldValue("answers", defaultAnswerList)
            }
          }
        }, [props.values.type])

        const [tempAnswer, setTempAnswer] = useState(null)

        useEffect(() => {
          if (!id) return

          get({
            id,
            cb: (data) => {
              props.setFieldValue("id", data.id)
              props.setFieldValue("type", data.type)
              props.setFieldValue(
                "categoryId",
                data.category ? data.category.id : []
              )
              props.setFieldValue(
                "articleId",
                data.article ? data.article.id : null
              )
              props.setFieldValue(
                "articleContent",
                data.article ? data.article.content : null
              )
              props.setFieldValue("explainAnswer", data.explainAnswer)
              props.setFieldValue("negativeMark", data.negativeMark)
              props.setFieldValue("hint", data.hint)
              props.setFieldValue("text", data.text)
              props.setFieldValue("title", data.title ? data.title : null)
              props.setFieldValue("difficultyLevel", data.difficultyLevel)
              props.setFieldValue("answers", data.answers)

              if (
                [
                  QUESTION_TYPE.MATRIX_RADIO,
                  QUESTION_TYPE.MATRIX_CHECKBOX,
                ].includes(data.type)
              ) {
                setTempAnswer(data.answers)
                setXCoordinate(data.xCoordinateOptions || [])
                setYCoordinate(data.yCoordinateOptions || [])
              }

              if (data.type === QUESTION_TYPE.COMPOSITE) {
                setCompositeQuestion(data)
              }

              if (QUESTION_TYPE.FILL_IN_THE_BLANK_DROPDOWN === data.type) {
                const answerModel = []
                const blankGroups = _.groupBy(
                  data.answers,
                  (answer) => answer.token
                )
                for (const group of Object.values(blankGroups)) {
                  const correctAnswer = group.find(
                    (item) => item.isCorrectAnswer
                  )

                  if (correctAnswer) {
                    const mark = correctAnswer.mark
                    const newAnswer = {
                      id: guidGenerator(),
                      token: correctAnswer.token,
                      isCorrectAnswer: true,
                      mark,
                      text: "",
                      inputValue: "",
                      value: group.map((item) => ({
                        isCorrectAnswer: item.isCorrectAnswer,
                        label: item.text,
                        value: item.text,
                      })),
                    }

                    answerModel.push(newAnswer)
                  }
                }

                props.setFieldValue("answers", answerModel)
              }

              // init the blanks
              if (
                [
                  QUESTION_TYPE.FILL_IN_THE_BLANK_TEXT,
                  QUESTION_TYPE.FILL_IN_THE_BLANK_DROPDOWN,
                  QUESTION_TYPE.HOT_TEXT,
                  QUESTION_TYPE.FILL_IN_BLANK_DRAG_DROP,
                ].includes(data.type)
              ) {
                const blankInContent = [...data.text.matchAll(/{blank\d}/g)]
                setBlanks(
                  blankInContent.map((item) => item[0].replace(/[{}]/g, ""))
                )
              }

              // hotspot
              if (
                [
                  QUESTION_TYPE.HOTSPOT,
                  QUESTION_TYPE.HOTSPOT_DRAG_DROP,
                ].includes(data.type)
              ) {
                props.setFieldValue("imageUrl", data.imageUrl)

                setFileUrl(computeImageUrl(data.imageUrl))

                setSpots(
                  data.hotSpotOptions.map((item) => ({
                    id: item.text,
                    x: item.xCoordinate,
                    y: item.yCoordinate,
                    width: item.width,
                    height: item.height,
                  }))
                )
              }

              // inline order drag drop
              if (QUESTION_TYPE.INLINE_ORDER_DRAG_DROP === data.type) {
                props.setFieldValue(
                  "mark",
                  data.answers && data.answers.length ? data.answers[0].mark : 0
                )
              }

              // dropzone drag drop
              if (QUESTION_TYPE.DROPZONE_DRAG_DROP === data.type) {
                props.setFieldValue("dropZones", data.dropZones)
                props.setFieldValue("dropZoneLayout", data.dropZoneLayout)
              }
            },
          })
        }, [])

        const onChangeAnswer = ({ data, id, option }) => {
          const { answers } = props.values

          props.setFieldValue(
            "answers",
            answers.map((item) => {
              if (item.id !== id) return item

              switch (option) {
                case "text":
                  return { ...item, text: data }
                case "mark":
                  return { ...item, mark: Number(data) }
                case "matchingText":
                  return { ...item, matchingText: data }
                case "token":
                  return { ...item, token: data }
                default:
                  return item
              }
            })
          )
        }

        const onAddNewAnswer = () => {
          const { answers } = props.values

          props.setFieldValue("answers", [
            ...answers,
            {
              id: getNextId(answers),
              text: "",
              isCorrectAnswer: false,
              mark: 0,
              correctAnswer: 0,
              matchingText: "",
            },
          ])
        }

        const onCheckCorrectAnswer = ({ e, id }) => {
          const { answers } = props.values

          if (isMultipleChoice) {
            props.setFieldValue(
              "answers",
              answers.map((item) => {
                if (item.id !== id) return item

                return {
                  ...item,
                  isCorrectAnswer: e.target.checked,
                }
              })
            )
          } else if (isMatrix) {
            // matrix radio
            const thisAnswer = answers.find((item) => item.id === id)

            if (!e.target.checked) return

            props.setFieldValue(
              "answers",
              answers.map((item) => {
                if (item.id !== id) {
                  if (item.xCoordinate !== thisAnswer.xCoordinate) return item
                  return {
                    ...item,
                    isCorrectAnswer: false,
                  }
                }

                return {
                  ...item,
                  isCorrectAnswer: true,
                }
              })
            )
          } else {
            if (!e.target.checked) return

            props.setFieldValue(
              "answers",
              answers.map((item) => {
                if (item.id !== id) return { ...item, isCorrectAnswer: false }

                return {
                  ...item,
                  isCorrectAnswer: true,
                }
              })
            )
          }
        }

        const onRemoveAnswer = (id) => () => {
          const { answers } = props.values

          if (answers.length === 2) return

          props.setFieldValue(
            "answers",
            answers.filter((item) => item.id !== id)
          )
        }
        // coordinate manage
        useEffect(() => {
          if (!isMatrix) return

          const answerCloner = tempAnswer || _.cloneDeep(props.values.answers)

          let countIndex = 0
          const answers = []

          for (const xItem of xCoordinate) {
            for (const yItem of yCoordinate) {
              const newAnswer = {
                id: getNextId(answers),
                text: `${xItem.text && xItem.text.trim() ? xItem.text : "X-Coordinate"
                  }-${yItem.text && yItem.text.trim() ? yItem.text : "Y-Coordinate"
                  }`,
                isCorrectAnswer: tempAnswer
                  ? answerCloner[countIndex]
                    ? answerCloner[countIndex].isCorrectAnswer
                    : false
                  : false,
                mark: tempAnswer
                  ? answerCloner[countIndex]
                    ? answerCloner[countIndex].mark
                    : 0
                  : 0,
                xCoordinate: xItem.id,
              }
              answers.push(newAnswer)

              countIndex++
            }
          }

          props.setFieldValue("answers", answers)
          if (
            xCoordinate &&
            xCoordinate.length > 1 &&
            yCoordinate &&
            yCoordinate.length > 1
          )
            setTempAnswer(null)
        }, [xCoordinate, yCoordinate])

        const onAddNewRow = ({ isX }) => () => {
          if (isX) {
            setXCoordinate([
              ...xCoordinate,
              { id: getNextId(xCoordinate), text: "" },
            ])
            return
          }
          setYCoordinate([
            ...yCoordinate,
            { id: getNextId(yCoordinate), text: "" },
          ])
        }

        const onChangeTextRow = ({ text, isX, id }) => {
          let coordinate = null
          let setCoordinate = null

          if (isX) {
            coordinate = xCoordinate
            setCoordinate = setXCoordinate
          } else {
            coordinate = yCoordinate
            setCoordinate = setYCoordinate
          }
          setCoordinate(
            coordinate.map((item) => {
              if (item.id !== id) return item

              return {
                ...item,
                text,
              }
            })
          )
        }

        const onRemoveRow = ({ isX, id }) => () => {
          if (isX) {
            setXCoordinate(xCoordinate.filter((item) => item.id !== id))
            return
          }

          setYCoordinate(yCoordinate.filter((item) => item.id !== id))
        }

        // blank text
        const onAddBlank = () => {
          const count = blanks.length + 1
          const newBlank = `blank${count}`

          ckeditor.editor.insertHtml(`{${newBlank}}`)
        }

        const onChangeTextEditor = (e) => {
          const content = e.editor.getData() || ""

          if (
            isBlankText ||
            isBlankDropDown ||
            [
              QUESTION_TYPE.HOT_TEXT,
              QUESTION_TYPE.FILL_IN_BLANK_DRAG_DROP,
            ].includes(props.values.type)
          ) {
            const blankInContent = [...content.matchAll(/{blank(\d)+}/g)]
            setBlanks(
              blankInContent.map((item) => item[0].replace(/[{}]/g, ""))
            )
          }

          props.setFieldValue("text", content)
        }

        // blank dropdown
        const createOption = (label) => ({
          label,
          value: label,
        })

        const handleBlankDropDownChange = (id) => (newValue) => {
          if (!props.values.answers) return

          props.setFieldValue(
            "answers",
            props.values.answers.map((item) => {
              if (item.id !== id) return item
              return {
                ...item,
                value: newValue,
              }
            })
          )
        }

        const handleBlankDropDownInputChange = (id) => (string) => {
          if (!props.values.answers) return

          props.setFieldValue(
            "answers",
            props.values.answers.map((item) => {
              if (item.id !== id) return item
              return {
                ...item,
                inputValue: string,
              }
            })
          )
        }

        const handleBlankDropDownKeyDown = (id) => (event) => {
          if (!props.values.answers) return

          const thisAnswer = props.values.answers.find((item) => item.id === id)
          if (!thisAnswer || !thisAnswer.inputValue) return
          switch (event.key) {
            case "Enter":
            case "Tab":
              props.setFieldValue(
                "answers",
                props.values.answers.map((item) => {
                  if (item.id !== id) return item
                  return {
                    ...item,
                    value: !item.value.find(
                      (val) =>
                        val &&
                        val.value &&
                        val.value.trim() === item.inputValue.trim()
                    )
                      ? [...item.value, createOption(item.inputValue)]
                      : item.value,
                    inputValue: "",
                  }
                })
              )
              event.preventDefault()
          }
        }

        const onSelectCorrectAnswerBlankDropDown = (index, value) => {
          if (!props.values.answers) return

          const thisAnswer = props.values.answers[index]
          if (!thisAnswer || !thisAnswer.value) return

          const newValue = thisAnswer.value.map((item) => {
            if (item.value !== value) {
              return {
                ...item,
                isCorrectAnswer: false,
              }
            }

            return {
              ...item,
              isCorrectAnswer: true,
            }
          })

          props.setFieldValue(
            "answers",
            props.values.answers.map((item, itemIndex) => {
              if (itemIndex !== index) return item
              return {
                ...item,
                value: newValue,
              }
            })
          )
        }

        // inline order drag drop manage
        const onSetAnswerOrder = ({ id, value }) => {
          props.setFieldValue(
            "answers",
            props.values.answers.map((ans) => {
              if (ans.id !== id) return ans
              return {
                ...ans,
                correctOrder: value,
              }
            })
          )
        }

        useEffect(() => {
          if (
            [
              QUESTION_TYPE.FILL_IN_THE_BLANK_TEXT,
              QUESTION_TYPE.FILL_IN_THE_BLANK_DROPDOWN,
              QUESTION_TYPE.HOT_TEXT,
            ].includes(props.values.type)
          ) {
            if (blanks && blanks.length) {
              const answersCloner = props.values.answers
                ? _.cloneDeep(props.values.answers)
                : []
              const newAnswers = blanks.map((blank) => {
                const oldAnswer = answersCloner.find(
                  (ans) => ans.token === blank
                )
                if (oldAnswer) return oldAnswer

                return {
                  id: guidGenerator(),
                  token: blank,
                  text: "",
                  isCorrectAnswer: props.values.type !== QUESTION_TYPE.HOT_TEXT,
                  mark: 0,
                  value: [],
                  inputValue: "",
                }
              })

              props.setFieldValue("answers", newAnswers)
            }
          }
        }, [blanks])

        // hotspot image manage
        useEffect(() => {
          if (!spots || !spots.length) return

          const { answers } = props.values

          const newHotSpotOptions = spots.map((item) => ({
            text: item.id,
            width: item.width,
            height: item.height,
            xCoordinate: item.x,
            yCoordinate: item.y,
          }))

          props.setFieldValue("hotSpotOptions", newHotSpotOptions)

          if (QUESTION_TYPE.HOTSPOT_DRAG_DROP !== props.values.type) {
            const newAnswerList = spots.map((item) => {
              const thisAnswer =
                answers && answers.find((ans) => ans.text === item.id)

              if (!thisAnswer)
                return {
                  id: item.id,
                  text: item.id,
                  mark: 0,
                  isCorrectAnswer: false,
                }

              return thisAnswer
            })

            props.setFieldValue("answers", newAnswerList)
          }

          if (QUESTION_TYPE.HOTSPOT_DRAG_DROP === props.values.type) {
            // set empty for answer has no matching Text instead of non-exist matchingText
            const newAnswerList = props.values.answers.map((answer) => {
              return {
                ...answer,
                matchingText: spots.some(
                  (spot) => spot.id === answer.matchingText
                )
                  ? answer.matchingText
                  : "",
              }
            })
            props.setFieldValue("answers", newAnswerList)
          }
        }, [spots])

        useEffect(() => {
          setSpots([])
        }, [file])

        // hotspot drag drop
        const onChangeMatchingText = ({ id, value }) => {
          const { answers } = props.values
          if (!answers || !answers.length) return

          const newAnswerList = answers.map((ans) => {
            if (ans.id !== id) return ans

            return {
              ...ans,
              matchingText: value.map((item) => item.id).join(";"),
            }
          })

          props.setFieldValue("answers", newAnswerList)
        }

        // dropzone drag drop
        const onAddZone = (zone) =>
          props.setFieldValue("dropZones", [...props.values.dropZones, zone])
        const onDeleteZone = (text) =>
          props.setFieldValue(
            "dropZones",
            props.values.dropZones.filter((zone) => zone.text !== text)
          )

        const onChangeDropZoneLayout = (value) => {
          if (["Vertical", "Horizontal"].includes(value)) {
            props.setFieldValue("dropZoneLayout", value)
          }
        }

        const currentType = questionTypes.find(
          (item) => item.value === props.values.type
        )
        const currentCategory =
          allQuestionCategory &&
          allQuestionCategory.filter(
            (item) => props.values.categoryId === item.id
          )[0]
        const currentArticle =
          allArticles &&
          allArticles.filter((item) => props.values.articleId === item.id)[0]
        return (
          <div className="p-2 pb-5">
            <h5>
              {id
                ? props.values.type === QUESTION_TYPE.COMPOSITE
                  ? t("edit_question")
                  : compositeQuestion
                    ? `${t("parent_question")}: ${getTextFromHTML(
                      compositeQuestion.text
                    )}`
                    : t("edit_question")
                : compositeQuestion
                  ? `${t("parent_question")}: ${getTextFromHTML(
                    compositeQuestion.text
                  )}`
                  : t("create_question")}
            </h5>

            <Row className="mb-2">
              <Col md={6}>
                <QuestionTypeSelector
                  required
                  onChange={(e) => props.setFieldValue("type", e.value)}
                  value={
                    currentType
                      ? { ...currentType, label: currentType.name }
                      : null
                  }
                  noComposite={compositeQuestion}
                  isDisabled={
                    id &&
                    (props.values.type === QUESTION_TYPE.COMPOSITE ||
                      !editingComposite)
                  }
                />
                {props.touched.type && props.errors.type ? (
                  <ErrorHandler text={props.errors.type} />
                ) : null}
              </Col>
              <Col md={3}>
                <ArticleSelector
                  onChange={(e) => {
                    props.setFieldValue("articleId", e ? e.value : null)
                  }}
                  value={
                    currentArticle
                      ? {
                        ...currentArticle,
                        label: currentArticle.name,
                        value: currentArticle.id,
                      }
                      : null
                  }
                  isClearable
                />
                {/* {props.touched.categoryId && props.errors.categoryId
                            ? <ErrorHandler text={props.errors.categoryId} />
                            : null} */}
              </Col>
              <Col md={3}>
                {/* TODO fix this */}
                <QuestionCategorySelector
                  // isMulti
                  required
                  onChange={(e) => props.setFieldValue("categoryId", e.value)}
                  value={
                    currentCategory
                      ? {
                        ...currentCategory,
                        label: currentCategory.name,
                        value: currentCategory.id,
                      }
                      : null
                  }
                />
                {props.touched.categoryId && props.errors.categoryId ? (
                  <ErrorHandler text={props.errors.categoryId} />
                ) : null}
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={12}>
                <LabelRequired text={t("title")} />
                <Input
                  name="title"
                  type="text"
                  value={props.values.title || ""}
                  onChange={props.handleChange}
                />
                {props.touched.title && props.errors.title ? (
                  <ErrorHandler text={props.errors.title} />
                ) : null}
              </Col>
            </Row>
            <Row className="mb-2">
              {props.values.articleId ? (
                <Col md={12}>
                  <Label>{t("article")}</Label>
                  <div
                    dangerouslySetInnerHTML={createMarkup(
                      currentArticle.content
                    )}
                  />
                </Col>
              ) : null}
            </Row>
            <Row className="mb-2">
              <Col md={12}>
                <LabelRequired text={t("question_text")} />
              </Col>
              {props.values.type &&
                [
                  QUESTION_TYPE.FILL_IN_THE_BLANK_TEXT,
                  QUESTION_TYPE.FILL_IN_THE_BLANK_DROPDOWN,
                  QUESTION_TYPE.HOT_TEXT,
                  QUESTION_TYPE.FILL_IN_BLANK_DRAG_DROP,
                ].includes(props.values.type) ? (
                <Col md={12}>
                  <div className="bg-secondary my-2 p-2">
                    <Button color="link" onClick={onAddBlank}>
                      {t("add_blank_token")}
                    </Button>
                    <p className="mb-0">
                      <i>{t("add_blank_detail")}</i>
                    </p>
                  </div>
                </Col>
              ) : null}
              <Col md={12}>
                {(!id || props.values.text !== null || compositeQuestion) && (
                  <CKEditor
                    ref={(node) => (ckeditor = node)}
                    data={props.values.text}
                    onChange={onChangeTextEditor}
                    config={{
                      height: 300,
                      allowedContent: true,
                      toolbar: [
                        "/",
                        {
                          name: "basicstyles",
                          groups: ["basicstyles", "cleanup"],
                          items: [
                            "Bold",
                            "Italic",
                            "Underline",
                            "Strike",
                            "Subscript",
                            "Superscript",
                            "-",
                            "CopyFormatting",
                            "RemoveFormat",
                          ],
                        },
                        {
                          name: "paragraph",
                          items: [
                            "NumberedList",
                            "BulletedList",
                            "-",
                            "Outdent",
                            "Indent",
                            "-",
                            "Blockquote",
                            "-",
                            "JustifyLeft",
                            "JustifyCenter",
                            "JustifyRight",
                            "JustifyBlock",
                          ],
                        },
                        {
                          name: "insert",
                          groups: ["insert"],
                          items: [
                            "Image",
                            "Table",
                            "HorizontalRule",
                            "SpecialChar",
                          ],
                        },
                        "/",
                        {
                          name: "styles",
                          groups: ["style"],
                          items: ["Styles", "Format", "Font", "FontSize"],
                        },
                        {
                          name: "colors",
                          groups: ["colors"],
                          items: ["TextColor", "BGColor"],
                        },
                        { name: "others" },
                        "/",
                        {
                          name: "wiris",
                          items: [
                            "ckeditor_wiris_formulaEditor",
                            "ckeditor_wiris_formulaEditorChemistry",
                          ],
                        },
                        ["lineheight"],
                        ["Html5audio", "Html5video"],
                      ],
                      //  TODO change upload api
                      filebrowserImageUploadUrl: "/api/file/Upload?",
                      filebrowserUploadUrl: "/api/file/Upload?",
                    }}
                    onBeforeLoad={(CKEDITOR) => {
                      CKEDITOR.disableAutoInline = true
                      CKEDITOR.config.height = 300
                      CKEDITOR.config.enterMode = CKEDITOR.ENTER_BR
                    }}
                  />
                )}
                {props.touched.text && props.errors.text ? (
                  <ErrorHandler text={props.errors.text} />
                ) : null}
              </Col>
            </Row>

            {props.values.type &&
              [QUESTION_TYPE.HOTSPOT, QUESTION_TYPE.HOTSPOT_DRAG_DROP].includes(
                props.values.type
              ) ? (
              <Row className="mb-2">
                <Col md={12}>
                  <QuestionImageManipulator
                    t={t}
                    file={file}
                    setFile={setFile}
                    fileUrl={fileUrl}
                    spots={spots}
                    setSpots={setSpots}
                    setModal={setModal}
                    setImageChanged={setImageChanged}
                  />
                </Col>
              </Row>
            ) : null}

            {/* {props.values.type && [
                    QUESTION_TYPE.HOTSPOT
                ].includes(props.values.type)
                    ? <Row className="mb-2">
                        <Col md={12}>
                            <Label>{t("upload_file_only_image")}</Label>
                            <Input type="file" />
                            <p className="mb-0"><i>{t("audio_length")}</i></p>
                        </Col>
                    </Row>
                    : null} */}

            {props.values.type &&
              props.values.type !== QUESTION_TYPE.COMPOSITE && (
                <>
                  <Row className="mb-2">
                    <Col md={6}>
                      <LabelRequired text={t("difficult_level")} />
                      <p
                        className="mb-2 font-weight-bold"
                        style={{ color: "#a94442", fontSize: "0.9em" }}
                      >
                        {t("difficulty_explain")}
                      </p>
                      <div className="d-flex align-items-center">
                        <CustomInput
                          className="mr-3"
                          type="radio"
                          id="difficulty-0"
                          checked={props.values.difficultyLevel === 0}
                          onClick={() =>
                            props.setFieldValue("difficultyLevel", 0)
                          }
                          label={t("easy")}
                        />

                        <CustomInput
                          className="mr-3"
                          type="radio"
                          id="difficulty-1"
                          checked={props.values.difficultyLevel === 1}
                          onClick={() =>
                            props.setFieldValue("difficultyLevel", 1)
                          }
                          label={t("intermediate")}
                        />

                        <CustomInput
                          type="radio"
                          id="difficulty-2"
                          checked={props.values.difficultyLevel === 2}
                          onClick={() =>
                            props.setFieldValue("difficultyLevel", 2)
                          }
                          label={t("difficult")}
                        />
                      </div>
                    </Col>

                    <Col md={6}>
                      {/* <Label>{t("negative_marks")}</Label>{" "}
                            <p className="mb-0 font-weight-bold" style={{ color: "#a94442", fontSize: "0.9em" }}>{t("negative_mark_explain")}</p>
                            <Input
                                type="number"
                                name="negativeMark"
                                value={props.values.negativeMark}
                                onChange={props.handleChange}
                                placeholder={t("enter_negative_marks")}
                            /> */}
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col md={6}>
                      <Label>{t("explain_your_answer")}</Label>
                      {/* <Input
                                type="textarea"
                                name="explainAnswer"
                                value={props.values.explainAnswer}
                                onChange={props.handleChange}
                                placeholder={t("explain_answer_placeholder")}
                            /> */}
                      {(!id || props.values.explainAnswer !== null) && (
                        <CKEditor
                          ref={(node) => (ckeditor_explain_answer = node)}
                          data={props.values.explainAnswer}
                          onChange={(e) =>
                            props.setFieldValue(
                              "explainAnswer",
                              !!e && !!e.editor ? e.editor.getData() : ""
                            )
                          }
                          config={{
                            height: 300,
                            allowedContent: true,
                            toolbar: [
                              {
                                name: "basicstyles",
                                groups: ["basicstyles", "cleanup"],
                                items: ["Bold", "Italic"],
                              },
                              { name: "insert", items: ["Image"] },
                              { name: "others" },
                              {
                                name: "wiris",
                                items: [
                                  "ckeditor_wiris_formulaEditor",
                                  "ckeditor_wiris_formulaEditorChemistry",
                                ],
                              },
                            ],
                            extraPlugins: "html5audio",
                            //  TODO change upload api
                            filebrowserImageUploadUrl: "/api/file/Upload?",
                            filebrowserUploadUrl: "/api/file/Upload?",
                          }}
                          onBeforeLoad={(CKEDITOR) => {
                            CKEDITOR.disableAutoInline = true
                            CKEDITOR.config.height = 50
                            CKEDITOR.config.enterMode = CKEDITOR.ENTER_BR
                          }}
                        />
                      )}
                    </Col>

                    <Col md={6}>
                      <Label>{t("hint")}</Label>
                      {/* <Input
                                type="textarea"
                                name="hint"
                                value={props.values.hint}
                                onChange={props.handleChange}
                                placeholder={t("type_hint_here")}
                            /> */}
                      {(!id || props.values.hint !== null) && (
                        <CKEditor
                          ref={(node) => (ckeditor_hint = node)}
                          data={props.values.hint}
                          onChange={(e) =>
                            props.setFieldValue("hint", !!e && !!e.editor ? e.editor.getData() : "")
                          }
                          config={{
                            height: 300,
                            allowedContent: true,
                            toolbar: [
                              {
                                name: "basicstyles",
                                groups: ["basicstyles", "cleanup"],
                                items: ["Bold", "Italic"],
                              },
                              { name: "insert", items: ["Image"] },
                              { name: "others" },
                              {
                                name: "wiris",
                                items: [
                                  "ckeditor_wiris_formulaEditor",
                                  "ckeditor_wiris_formulaEditorChemistry",
                                ],
                              },
                            ],
                            extraPlugins: "html5audio",
                            filebrowserImageUploadUrl: "/api/file/Upload?",
                            filebrowserUploadUrl: "/api/file/Upload?",
                          }}
                          onBeforeLoad={(CKEDITOR) => {
                            CKEDITOR.disableAutoInline = true
                            CKEDITOR.config.height = 50
                            CKEDITOR.config.enterMode = CKEDITOR.ENTER_BR
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                </>
              )}

            {props.values.type &&
              QUESTION_TYPE.DROPZONE_DRAG_DROP === props.values.type ? (
              <DropZones
                t={t}
                dropZones={props.values.dropZones}
                dropZoneLayout={props.values.dropZoneLayout}
                onChangeDropZoneLayout={onChangeDropZoneLayout}
                onAddZone={onAddZone}
                onDeleteZone={onDeleteZone}
              />
            ) : null}

            {/* {props.values.type && [QUESTION_TYPE.INLINE_ORDER_DRAG_DROP].includes(props.values.type)
                    ? <InlineOrderDragDrop />
                    : null} */}

            {/* {props.values.type && [QUESTION_TYPE.SHORT_ANSWER, QUESTION_TYPE.EXTENDED_RESPONSE].includes(props.values.type)
                    ? <Row className="mb-2">
                        <Col md={12}>
                            <Label>{t("answer")}</Label>
                            <Input
                                type="textarea"
                                value={props.values.answers && props.values.answers[0] && props.values.answers[0].text}
                                onChange={e => props.setFieldValue("answers", [{ ...props.values.answers[0], text: e.target.value, isCorrectAnswer: true }])}
                                placeholder={t("enter_answer_text_here")}
                            />
                        </Col>
                    </Row>
                    : null} */}

            {props.values.type &&
              (props.values.type === QUESTION_TYPE.MATRIX_CHECKBOX ||
                props.values.type === QUESTION_TYPE.MATRIX_RADIO) ? (
              <Row className="mb-2">
                <Col md={6}>
                  <Coordinate
                    t={t}
                    isX
                    onAddNewRow={onAddNewRow}
                    onChangeTextRow={onChangeTextRow}
                    onRemoveRow={onRemoveRow}
                    coordinateArr={xCoordinate}
                  />
                </Col>
                <Col md={6}>
                  <Coordinate
                    t={t}
                    onAddNewRow={onAddNewRow}
                    onChangeTextRow={onChangeTextRow}
                    onRemoveRow={onRemoveRow}
                    coordinateArr={yCoordinate}
                  />
                </Col>
              </Row>
            ) : null}

            {props.values.type &&
              [
                QUESTION_TYPE.SINGLE_CHOICE,
                QUESTION_TYPE.MULTIPLE_CHOICE,
                QUESTION_TYPE.MATRIX_RADIO,
                QUESTION_TYPE.MATRIX_CHECKBOX,
                QUESTION_TYPE.FILL_IN_THE_BLANK_TEXT,
                QUESTION_TYPE.FILL_IN_THE_BLANK_DROPDOWN,
                QUESTION_TYPE.HOT_TEXT,
                QUESTION_TYPE.HOTSPOT,
                QUESTION_TYPE.INLINE_ORDER_DRAG_DROP,
                QUESTION_TYPE.MATCH_THE_PAIRS,
                QUESTION_TYPE.FILL_IN_BLANK_DRAG_DROP,
                QUESTION_TYPE.HOTSPOT_DRAG_DROP,
                QUESTION_TYPE.DROPZONE_DRAG_DROP,
              ].includes(props.values.type) ? (
              <AnswerList
                t={t}
                spots={spots}
                blanks={blanks}
                dropZones={props.values.dropZones}
                answerArr={props.values.answers}
                isMulti={isMultipleChoice}
                isSingleChoice={
                  QUESTION_TYPE.SINGLE_CHOICE === props.values.type
                }
                isBlankText={isBlankText}
                isBlankDropDown={isBlankDropDown}
                isOnlyOneAnswer={isOnlyOneAnswer}
                isMatrix={isMatrix}
                isHotText={QUESTION_TYPE.HOT_TEXT === props.values.type}
                isHotspot={QUESTION_TYPE.HOTSPOT === props.values.type}
                isInlineOrderDragDrop={
                  QUESTION_TYPE.INLINE_ORDER_DRAG_DROP === props.values.type
                }
                isMatchThePairs={
                  QUESTION_TYPE.MATCH_THE_PAIRS === props.values.type
                }
                isBlankDragDrop={
                  QUESTION_TYPE.FILL_IN_BLANK_DRAG_DROP === props.values.type
                }
                isHotspotDragDrop={
                  QUESTION_TYPE.HOTSPOT_DRAG_DROP === props.values.type
                }
                isDropZoneDragDrop={
                  QUESTION_TYPE.DROPZONE_DRAG_DROP === props.values.type
                }
                xCoordinate={xCoordinate}
                yCoordinate={yCoordinate}
                onAddNewAnswer={onAddNewAnswer}
                onChangeAnswer={onChangeAnswer}
                onCheckCorrectAnswer={onCheckCorrectAnswer}
                onRemoveAnswer={onRemoveAnswer}
                handleBlankDropDownChange={handleBlankDropDownChange}
                handleBlankDropDownInputChange={handleBlankDropDownInputChange}
                handleBlankDropDownKeyDown={handleBlankDropDownKeyDown}
                onSelectCorrectAnswerBlankDropDown={
                  onSelectCorrectAnswerBlankDropDown
                }
                onSetAnswerOrder={onSetAnswerOrder}
                onChangeMatchingText={onChangeMatchingText}
                isClickPublish={false}
              />
            ) : null}

            {props.values.type &&
              props.values.type === QUESTION_TYPE.ORDERING ? (
              <OrderingAnswer
                t={t}
                answerArr={[
                  { text: "test", order: 2 },
                  { text: "test 2", order: 3 },
                  { text: "test 3", order: 1 },
                ]}
              />
            ) : null}

            {props.values.type &&
              [
                QUESTION_TYPE.EXTENDED_RESPONSE,
                QUESTION_TYPE.SHORT_ANSWER,
                QUESTION_TYPE.INLINE_ORDER_DRAG_DROP,
              ].includes(props.values.type) ? (
              <Row className="mb-2">
                <Col md={12}>
                  <LabelRequired text={t("mark")} />
                  <Input
                    type="number"
                    name="mark"
                    value={
                      QUESTION_TYPE.INLINE_ORDER_DRAG_DROP === props.values.type
                        ? props.values.mark || ""
                        : props.values.answers &&
                          props.values.answers[0] &&
                          props.values.answers[0].mark
                          ? props.values.answers[0].mark
                          : ""
                    }
                    onChange={(e) => {
                      if (
                        QUESTION_TYPE.INLINE_ORDER_DRAG_DROP ===
                        props.values.type
                      ) {
                        props.handleChange(e)
                      } else {
                        props.setFieldValue("answers", [
                          { mark: e && e.target ? Number(e.target.value) : 0 },
                        ])
                      }
                    }}
                    placeholder={t("enter_mark")}
                  />
                </Col>
              </Row>
            ) : null}

            <Row className="mb-2">
              {!id || compositeQuestion ? (
                <Col md={12}>
                  {!compositeQuestion &&
                    props.values.type !== QUESTION_TYPE.COMPOSITE ? (
                    <Button
                      color="primary"
                      className="mr-2"
                      onClick={() => {
                        setExit(false)
                        props.handleSubmit()
                      }}
                    >
                      {t("save_and_add_next_question")}
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      className="mr-2"
                      onClick={() => {
                        if (id) {
                          setEditingComposite(true)
                        }
                        props.handleSubmit()
                      }}
                    >
                      {t("save_and_add_sub_question")}
                    </Button>
                  )}
                  <Button
                    className="mr-2"
                    color="success"
                    onClick={() => {
                      setExit(true)
                      props.handleSubmit()
                    }}
                  >
                    {t("save_and_exit")}
                  </Button>
                  {!!id && (
                    <Button
                      color="primary"
                      onClick={() =>
                        window.open(
                          `${userInfo.environmentVariables.examPlayerUrl}/previewQuestion/${id}`
                        )
                      }
                      className="mr-2"
                    >
                      {t("preview")}
                    </Button>
                  )}
                  <Button
                    color="secondary"
                    onClick={() =>
                      history.push("/question-bank/questions")
                    }
                  >
                    {t("cancel")}
                  </Button>
                </Col>
              ) : (
                <Col md={12} className="py-2 floatingActions">
                  <Button
                    color="success"
                    onClick={() => {
                      setExit(true)
                      props.handleSubmit()
                    }}
                    className="mr-2"
                  >
                    {t("update")}
                  </Button>
                  {!!id && (
                    <Button
                      color="primary"
                      onClick={() =>
                        window.open(
                          `${userInfo.environmentVariables.examPlayerUrl}/previewQuestion/${id}`
                        )
                      }
                      className="mr-2"
                    >
                      {t("preview")}
                    </Button>
                  )}
                  <Button
                    color="secondary"
                    onClick={() =>
                      history.push("/question-bank/questions")
                    }
                  >
                    {t("cancel")}
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        )
      }}
    </Formik>
  )
}

const mapStateToProps = (state) => ({
  allQuestionCategory: state.questionCategory.allQuestionCategory,
  allArticles: state.article.allArticles,
  userInfo: state.common.userInfo,
})

const mapDispatchToProps = {
  create,
  update,
  setModal,
  get,
  uploadFiles,
}

const EditQuestionHOC = withTranslation("common")(EditQuestion)

export default connect(mapStateToProps, mapDispatchToProps)(EditQuestionHOC)
