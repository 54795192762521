import React from "react";
import { connect } from "react-redux";
import { Alert } from "reactstrap";
import { withTranslation } from 'react-i18next'

import setAlert from "./action";

class AppAlert extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
  }

  delay = 0;

  componentWillReceiveProps(newProps) {
    if (newProps.alert.message.length > 0 && this.state.visible) {
      if (this.delay) {
        clearTimeout(this.delay);
        this.delay = 0;
      }
      this.delay = setTimeout(() => {
        this.setState({ visible: false }, () => {
          this.props.setAlert({ message: "" });
          this.setState({ visible: true });
        });
      }, 4000);
    }
  }

  onDismiss = () => {
    this.setState({ visible: false });
    this.props.setAlert({ message: "" });
    setTimeout(() => {
      this.setState({ visible: true });
      if (this.delay) {
        clearTimeout(this.delay);
        this.delay = 0;
      }
    });
  };

  render() {
    const { t } = this.props
    return this.props.alert.message.length > 0 ? (
      <div className="app-alert">
        <Alert color={this.props.alert.type} isOpen={this.state.visible} toggle={this.onDismiss}>
          {t(this.props.alert.message)}
        </Alert>
      </div>
    ) : null;
  }
}

const withMultiLanguage = withTranslation('common')(AppAlert)

const mapStateToProps = state => ({
  alert: state.alert.alert,
});

const mapDispatchToProps = dispatch => ({
  setAlert: alert => dispatch(setAlert(alert)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withMultiLanguage);
