import { HIDE_CONFIRM, SHOW_CONFIRM } from "./constants";

const showConfirmDialog = ({ onConfirm, onReject, message }) => {
    return {
        type: SHOW_CONFIRM,
        payload: {
            onConfirm,
            onReject,
            message
        }
    };
};

const hideConfirmDialog = () => {
    return {
        type: HIDE_CONFIRM
    };
};

export { showConfirmDialog, hideConfirmDialog };
