import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Table, Row, Col, Alert, Label, Input } from "reactstrap"
import { withTranslation } from "react-i18next"
import {CKEditor} from "ckeditor4-react"

import { sortAlphabet } from "../../../util/Common";
import SearchBox from "../../../components/SearchBox"
import CreateBtn from "../../../components/Button/CreateBtn"
import DeleteBtn from "../../../components/Button/DeleteBtn"
import LinkEditBtn from "../../../components/Button/LinkEditBtn"
import CustomPagination from "../../../components/CustomPagination"
import ErrorHandler from "../../../components/ErrorHandler"
import LabelRequired from "../../../components/LabelRequired"

import useModal from "../../../components/Modal/useModal"
import CustomModal from "../../../components/Modal/CustomModal"
import setModal from "../../../components/Modal/action"
import {
    getArticleAction,
    changePageSizeArticleAction,
    createArticleAction,
    updateArticleAction,
    deleteArticleAction
} from "../redux/action"

const Article = props => {
    let ckeditor
    const { t, articles, allArticles, totalItems } = props
    const [searchString, setSearchString] = useState("")
    const [sortBy, setSortBy] = useState("Name")
    const [sortOrder, setSortOrder] = useState("ASC")
    const [checkRequired, setCheckRequired] = useState(false)
    const [currentArticle, setCurrentArticle] = useState({})
    console.log('One more thing', {
        checkRequired, currentArticle
    })
    const [isOpen, toggle] = useModal()

    const getAll = page => {
        const { currentPage, pageSize } = props
        props.getArticleAction({
            currentPage: page || currentPage,
            pageSize,
            searchString,
            sortBy,
            sortOrder
        })
    }

    useEffect(() => {
        document.title = t("Article");
        getAll(1);
    }, [sortOrder])

    const resetAndReloadData = () => {
        toggle()
        setCheckRequired(false)
        setCurrentArticle({})
        getAll(1)
    }

    const renderModal = () => {
        const isEdit = currentArticle && currentArticle.id

        return <CustomModal
            size="xl"
            isOpen={isOpen}
            toggle={() => {
                toggle()
                setCheckRequired(false)
                setCurrentArticle({})
            }}
            title={isEdit ? t("Article") : t("create_article")}
            t={t}
            confirmText={isEdit ? t("update") : t("create")}
            onConfirm={() => {

                setCheckRequired(true)
                if(!currentArticle || !currentArticle.name || !currentArticle.name.trim() || !currentArticle.content || !currentArticle.content.trim()) return

                if (!isEdit) {
                    props.createArticleAction({
                        ...currentArticle,
                        cb: resetAndReloadData
                    })
                    return
                }

                props.updateArticleAction({ ...currentArticle, cb: resetAndReloadData })
            }}
        >
            <Row className="mb-2">
                <Col md={12} className="mb-2">
                    <LabelRequired text={t("article_name")} />
                    <Input
                        placeholder={t("article_name")}
                        value={currentArticle && currentArticle.name}
                        onChange={e => {
                            setCurrentArticle({ ...currentArticle, name: e.target.value })
                        }}
                        invalid={checkRequired && (!currentArticle || !currentArticle.name || !currentArticle.name.trim())}
                    />
                    {checkRequired && (!currentArticle || !currentArticle.name || !currentArticle.name.trim())
                        ? <ErrorHandler text={t("article_name_required")} />
                        : null}
                </Col>
                <Col md={12} className="mb-2">
                    <LabelRequired text={t("article_content")} />
                    <CKEditor
                        ref={node => ckeditor = node}
                        // data={currentArticle && currentArticle.content}
                        initData={currentArticle && currentArticle.content}
                        onChange={(e) => {
                            setCurrentArticle((preState) => ({
                                ...preState,
                                content: e.editor.getData()
                            }))
                        }}
                        config={{
                            height: 300,
                            allowedContent: true,
                            toolbar: [
                                '/',
                                { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat'] },
                                { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
                                { name: 'insert', groups: ['insert'], items: ['Image', 'Table', 'HorizontalRule', 'SpecialChar'] },
                                '/',
                                { name: 'styles', groups: ['style'], items: ['Styles', 'Format', 'Font', 'FontSize'] },
                                { name: 'colors', groups: ['colors'], items: ['TextColor', 'BGColor'] },
                                { name: 'others' },
                                '/',
                                { name: 'wiris', items: ['ckeditor_wiris_formulaEditor', 'ckeditor_wiris_formulaEditorChemistry'] },
                                ['lineheight'],
                                ['Html5audio', 'Html5video']
                            ],
                            //  TODO change upload api
                            filebrowserImageUploadUrl: '/api/file/Upload?',
                            filebrowserUploadUrl: '/api/file/Upload?',
                            image_previewText: "",
                        }}
                        onBeforeLoad={(CKEDITOR) => {
                            CKEDITOR.disableAutoInline = true;
                            CKEDITOR.config.height = 300;
                            CKEDITOR.config.enterMode = CKEDITOR.ENTER_BR;
                        }}
                    />
                    {checkRequired && (!currentArticle || !currentArticle.content || !currentArticle.content.trim())
                        ? <ErrorHandler text={t("article_content_required")} />
                        : null}
                </Col>
            </Row>
        </CustomModal>
    }

    const removeArticle = id => {
        props.setModal({
            type: "danger",
            message: t("delete_article_confirm"),
            cb: () => props.deleteArticleAction({
                id,
                cb: () => getAll(1)
            })
        })
    }

    const onSort = sortBy => () => {
        setSortBy(sortBy)
        if (sortOrder === "ASC") {
            setSortOrder("DESC")
        } else {
            setSortOrder("ASC")
        }
    }

    return <div>
        {renderModal()}
        <Row className="mb-2">
            <Col md={5} className="d-flex">
                <div style={{ marginRight: "30px" }}>
                    <CreateBtn
                        title={t("create_article")}
                        style={{ width: "120px", height: "32px" }}
                        onClick={toggle}
                    />
                </div>
                <SearchBox
                    value={searchString}
                    onChange={e => setSearchString(e.target.value)}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            getAll(1);
                        }
                    }}
                />
            </Col>
        </Row>

        <Row className="mb-2">
            {articles && articles.length > 0
                ? <>
                    <Col md={8} className="text-right">
                        <b>{t("total_articles")}: {totalItems}</b>
                    </Col>
                    <Col md={8}>
                        <Table hover bordered striped responsive className="grid-table mb-2">
                            <thead>
                                <tr>
                                    <th>
                                        {t("article_name")}
                                        <i
                                            className="fa fa-sort float-right mt-1"
                                            style={{ cursor: 'pointer' }}
                                            onClick={onSort("Name")}
                                        />
                                    </th>
                                    {/* <th>
                                        {t("number_of_questions")}
                                    </th> */}
                                    <th className="text-center">{t("action")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortAlphabet(articles, 'name').map((Article, index) => <tr key={index}>
                                    <td>
                                        <LinkEditBtn
                                            noIcon
                                            onClick={() => {
                                                setCurrentArticle(Article)
                                                toggle()
                                            }}
                                        >
                                            {Article.name}
                                        </LinkEditBtn>
                                        <p className="mb-0"><i>{Article.path || ""}</i></p>
                                    </td>
                                    {/* <td>{Article.numberOfQuestions}</td> */}
                                    <td style={{ width: '10%' }} className="text-center pl-3">
                                        <DeleteBtn
                                            className="d-inline mr-3"
                                            onClick={() => removeArticle(Article.id)}
                                        />
                                    </td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </Col>
                </>
                : <Col md={8} sm={12}>
                    <Alert color="primary">{t("no_article_to_display")}</Alert>
                </Col>}
        </Row>
        <Row className="mb-2">
            <Col md={8} className="text-right">
                <CustomPagination
                    pageCount={props.pageCount}
                    pageSize={props.pageSize}
                    currentPage={props.currentPage}
                    pageSizeName="articlePageSize"
                    changePage={currentPage => {
                        getAll(currentPage);
                    }}
                    changePageSize={props.changePageSizeArticleAction}
                    onChangePageSize={() => getAll(1)}
                />
            </Col>
        </Row>

    </div>
}

const mapStateToProps = state => ({
    articles: state.article.articles,
    allArticles: state.article.allArticles,
    currentPage: state.article.currentPage,
    pageCount: state.article.pageCount,
    pageSize: state.article.pageSize,
    totalItems: state.article.totalItems
})

const mapDispatchToProps = {
    setModal,
    getArticleAction,
    changePageSizeArticleAction,
    createArticleAction,
    updateArticleAction,
    deleteArticleAction
}

const ArticleHOC = withTranslation("common")(Article)

export default connect(mapStateToProps, mapDispatchToProps)(ArticleHOC)