import { getAllTestReport } from "../../../util/api"
import setLoading from "../../../components/Spinner/action"
import setModal from "../../../components/Modal/action"

export const getAllReportTest = ({ currentPage = 1, pageSize = 100, searchString, testSuiteIds, testPackIds }) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await getAllTestReport("assessments")({ currentPage, pageSize, searchString, testSuiteIds, testPackIds })

        if (!res.data) throw new Error("Error in getting test report test")

        if (Array.isArray(res.data)) throw new Error(res.data.map(item => item.value))

        dispatch({
            type: "SET_TEST_REPORT_TEST",
            payload: res.data.data,
            // currentPage: res.data.currentPage,
            totalItems: res.data.totalItems,
            pageCount: res.data.pageCount,
        })
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: JSON.stringify(err.response.data)
        }))
    }

    dispatch(setLoading(false))
}

export const changePageSizeTestReportTest = pageSize => async dispatch => {
    dispatch({
        type: "CHANGE_PAGE_SIZE_TEST_REPORT_TEST",
        payload: pageSize
    })
}

export const changeCurrentPageTestReportTest = page => async dispatch => {
    dispatch({
        type: "CHANGE_CURRENT_PAGE_TEST_REPORT_TEST",
        payload: page
    })
}

export const getAllReportStudent = ({ currentPage = 1, pageSize = 100, searchString, testSuiteIds, testPackIds, assessmentIds }) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await getAllTestReport("students")({ currentPage, pageSize, searchString, testSuiteIds, testPackIds, assessmentIds })

        if (!res.data) throw new Error("Error in getting test report student")

        if (Array.isArray(res.data)) throw new Error(res.data.map(item => item.value))

        dispatch({
            type: "SET_TEST_REPORT_STUDENT",
            payload: res.data.data,
            // currentPage: res.data.currentPage,
            totalItems: res.data.totalItems,
            pageCount: res.data.pageCount,
        })
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: JSON.stringify(err.response.data)
        }))
    }

    dispatch(setLoading(false))
}

export const changePageSizeTestReportStudent = pageSize => async dispatch => {
    dispatch({
        type: "CHANGE_PAGE_SIZE_TEST_REPORT_STUDENT",
        payload: pageSize
    })
}

export const changeCurrentPageTestReportStudent = page => async dispatch => {
    dispatch({
        type: "CHANGE_CURRENT_PAGE_TEST_REPORT_STUDENT",
        payload: page
    })
}