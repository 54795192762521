import React, { useState, useEffect } from "react"
import { Table, Row, Col, Button, Collapse, Label, Alert } from "reactstrap"
import { useTranslation } from "react-i18next"
import _ from "lodash"

import SearchBox from "../../../components/SearchBox"
import CustomPagination from "../../../components/CustomPagination"
import TestPackSelector from "../../../components/Selector/TestPackSelector"
import TestSuiteSelector from "../../../components/Selector/TestSuiteSelector"
import AssessmentSelector from "../../../containers/Assessment/components/AssessmentSelector"
import LinkEditBtn from "../../../components/Button/LinkEditBtn"
import ViewBtn from '../../../components/Button/ViewBtn'

const nameFactory = report => ({
    test: {
        label: report.assessmentName,
        onClick: () => {
            localStorage.setItem('assessmentId', report.assessmentId)
            window.open(`/test-reports/student/`)
        }
    },
    student: {
        label: report.studentName,
        onClick: () => {
            localStorage.setItem('studentId', report.studentIdentityId)
            localStorage.setItem('studentName', report.studentName)
            localStorage.setItem('studentEmail', report.studentEmail)
            window.open(`/test-management/test-result`)
        }
    }
})

const ReportList = props => {
    const { t } = useTranslation("common")
    const {
        currentPage,
        pageSize,
        pageCount,
        totalItems,
        reportList,
        changePageSizeReport,
        changeCurrentPageReport,
        pageSizeName,
        reportType
    } = props

    const [isOpen, setIsOpen] = useState(false)

    const [searchString, setSearchString] = useState("")
    const [testPackIds, setTestPackIds] = useState([])
    const [testSuiteIds, setTestSuiteIds] = useState([])
    const [assessmentIds, setAssessmentIds] = useState([])

    const getAll = async page => {
        // uncomment it when backend has api

        await props.getAllReport({
            currentPage: page || currentPage,
            pageSize,
            searchString,
            testPackIds,
            testSuiteIds,
            assessmentIds
        })

        if (page) {
            await props.changeCurrentPageReport(page)
        }
    }

    useEffect(() => {
        if (reportType == 'student' && localStorage.getItem('assessmentId')) {
            document.title = t("student_reports")
            setAssessmentIds([parseInt(localStorage.getItem('assessmentId'))])
            localStorage.removeItem('assessmentId')
        }
        else {
            document.title = reportType === "student"
                ? t("student_reports")
                : t("test_reports")
        }
        getAll(1)
    }, [currentPage, testSuiteIds, testPackIds, assessmentIds, reportType])

    return <div className="animated fadeIn p-2">
        <Row className="mb-2">
            <Col md={12}>
                <h5 className="text-uppercase">{reportType === 'test' ? t("test_reports") : t("student_reports")}</h5>
            </Col>
        </Row>
        <Row>
            <Col md={4}>
                <SearchBox
                    className="mr-0"
                    value={searchString}
                    onChange={e => setSearchString(e.target.value)}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            getAll(1)
                        }
                    }}
                />
            </Col>
            <Col md={2} className="d-flex align-items-center">
                <Button
                    color="link"
                    className="m-0"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {isOpen ? t("hide_advanced_search") : t("show_advanced_search")}
                </Button>
            </Col>
        </Row>
        <Collapse isOpen={isOpen} className="mt-2">
            <Row>
                <Col md={2}>
                    <Label>{t("test_packs")}</Label>
                    <TestPackSelector
                        onChange={e => setTestPackIds(e.map(item => item.value))}
                    />
                </Col>
                <Col md={2}>
                    <Label>{t("test_suites")}</Label>
                    <TestSuiteSelector
                        onChange={e => setTestSuiteIds(e.map(item => item.value))}
                    />
                </Col>
                {reportType == 'student' && <Col md={2}>
                    <Label>{t("assessment.title")}</Label>
                    <AssessmentSelector
                        isMulti={true}
                        value={assessmentIds}
                        onChange={e => setAssessmentIds(e.map(item => item.value))}
                    />
                </Col>}
            </Row>
        </Collapse>
        {totalItems
            ? <Row className="mt-2">
                <Col md={8} className="text-right">
                    <b>{t("total_test_reports")}: {totalItems}</b>
                </Col>
            </Row>
            : null}

        <Row className="my-2">
            {reportList && reportList.length
                ? <Col md={8}>
                    <Table hover bordered striped responsive className="grid-table mb-2">
                        <thead>
                            <tr>
                                {[
                                    reportType === 'test' ? "assessment.title" : reportType,
                                    "inprogress",
                                    "pending",
                                    "completed"
                                ].map((th, index) => <th key={index} className="align-top">
                                    {t(th)}
                                </th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {reportList.map((report, index) => <tr key={index}>
                                <td>
                                    <LinkEditBtn
                                        label={nameFactory(report)[reportType].label}
                                        onClick={nameFactory(report)[reportType].onClick}
                                    />
                                </td>
                                <td>{report.inProgressCount}</td>
                                <td>{report.pendingEvaluationCount}</td>
                                <td>{report.completedCount}</td>
                            </tr>)}
                        </tbody>
                    </Table>
                </Col>
                : <Col md={8} sm={12}>
                    <Alert color="primary" className="mb-0">{t("no_test_result_to_display")}</Alert>
                </Col>}
        </Row>

        <Row className="mb-2">
            <Col md={8} className="text-right">
                <CustomPagination
                    pageCount={pageCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    pageSizeName={pageSizeName}
                    changePage={page => {
                        changeCurrentPageReport(page)
                    }}
                    changePageSize={changePageSizeReport}
                    onChangePageSize={() => getAll(1)}
                />
            </Col>
        </Row>
    </div>
}

export default ReportList