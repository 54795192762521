/* eslint-disable no-nested-ternary */
import React from "react"
import {
  Col,
  Row,
  Button,
  Collapse,
  Label
} from "reactstrap"
import moment from "moment"
// import { Pie } from "react-chartjs-2";
import { AgGridReact } from '@ag-grid-community/react'
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import '@ag-grid-community/core/dist/styles/ag-grid.css'
import '@ag-grid-community/core/dist/styles/ag-theme-balham.css'
import _ from "lodash"
import { withTranslation } from 'react-i18next'
import PDF from "jspdf"
import { DOCUMENT_TITLE, roles, removeUserRoles, adminStaffRols, activeOptions, paymentOptions } from "../../../util/Constant"
import "jspdf-autotable"

import CustomPagination from "../../../components/CustomPagination"
import SearchBox from "../../../components/SearchBox"
import ViewModal from "../../../components/Modal/viewModal"
import LinkEditBtn from "../../../components/Button/LinkEditBtn"
import DeleteBtn from "../../../components/Button/DeleteBtn"
import CustomAgHeader from "../../../components/CustomAgHeader"
// import selected
import CustomSelected from "../../../components/Selector/CustomSelected"

import StudentListTable from "../../../components/StudentListTable"
import history from "../../../util/history"
import { deleteOldImportedUser } from "../../../util/api"
import { formatToLocalTime } from "../../../util/helper"


import ChildTable from "./childTable"
import CreateBtn from "../../../components/Button/CreateBtn"
import CustomBtn from "../../../components/Button/CustomBtn"

class userList extends React.PureComponent {
  constructor(props) {
    super(props)
    if (props.isAdminStaff) {
      document.title = props.t(DOCUMENT_TITLE.admin_and_staff)
    } else if (props.isImported) {
      document.title = props.t(DOCUMENT_TITLE.imported_student_list)
    } else {
      document.title = props.t(DOCUMENT_TITLE.users_list)
    }

    this.isAdminStaff = props.isAdminStaff
    this.isImported = props.isImported
    this.state = {
      sortBy: this.props.sortBy || "FirstName",
      roleFilters: this.props.roleFilters || null,
      sortOrder: this.props.sortOrder || "ASC",
      isOpen: false,
      students: [],
      courseIds: [],
      programIds: [],
      deleteIds: [],
      skillIds: [],
      year: "",
      sem: "",
      isLocked: this.props.isAdminStaff || this.props.isImported || this.props.removeUser ?
        { value: 0 } :
        { label: props.t('unlocked'), value: 1 },
      isActive: activeOptions(props.t)[1],
      paymentStatus: paymentOptions(props.t)[0],
      searchString: this.props.isAdminStaff || this.props.isImported ? this.props.searchStringAdmin : this.props.searchString,
    }
    this.timeOut = null
    this.gridOptions = {
      columnDefs: [
        {
          headerName: props.t('name'),
          field: "name",
          width: this.props.tableZone * 0.17,
          // headerComponentParams: {
          //   reGetData: this.getUsers,
          //   sortable: true,
          //   sortOrder: this.state.sortOrder,
          //   sortBy: this.state.sortBy,
          //   reSetState: (o, b) => {
          //     this.setState({ sortOrder: o, sortBy: b })
          //   }
          // },
          // cellRenderer: "agGroupCellRenderer"
        },
        {
          headerName: props.t('email'),
          field: "email",
          width: this.props.tableZone * 0.25,
          cellRendererFramework: this.renderEmail,
        },
        {
          headerName: props.t('cell_phone'),
          field: "phoneNumber",
          width: this.props.tableZone * 0.1,
        },
        {
          headerName: props.t('age'),
          field: "birthday",
          cellRendererFramework: this.renderAge,
          width: this.props.tableZone * 0.045,
        },
        {
          headerName: props.t('gender'),
          field: "gender",
          cellRendererFramework: this.renderGender,
          width: this.props.tableZone * 0.05,
        },
        // {
        //   headerName: props.t('family'),
        //   field: "family",
        //   width: this.props.tableZone * 0.15,
        //   cellRendererFramework: this.renderFamily,
        // },
        // {
        //   headerName: props.t('programs'),
        //   field: "programs",
        //   width: this.props.tableZone * 0.15,
        //   cellRendererFramework: this.renderPrograms,
        // },
        // {
        //   headerName: props.t('courses'),
        //   field: "courses",
        //   width: this.props.tableZone * 0.15,
        //   cellRendererFramework: this.renderCourses,
        // },
        {
          headerName: props.t('roles'),
          field: "roles",
          cellRendererFramework: this.renderRoles,
          width: this.props.tableZone * 0.1,
        },
        {
          headerName: props.t('status'),
          field: "isActive",
          width: this.props.tableZone * 0.05,
          cellRendererFramework: p => <p>{p.value ? props.t('active') : p.value === false ? props.t('non_active') : null}</p>
        },
        // {
        //   headerName: props.t('note'),
        //   field: "note",
        //   width: this.props.tableZone * 0.12,
        // },
        // {
        //   headerName: props.t('teacher_skill'),
        //   cellRendererFramework: this.renderSkill
        // },
        // {
        //   headerName: props.t('sent_invoice_at'),
        //   cellRendererFramework: this.renderSentInvoiceAt
        // }
      ],
      detailCellRenderer: "childTable",
      defaultColDef: {
        resizable: true
      },
      rowData: [],
      localeText: { noRowsToShow: props.t('no_user') }
    }

    this.gridOptionsForAdminAndRemove = {
      columnDefs: [
        {
          headerName: props.t('name'),
          field: "name",
          width: this.props.tableZone * 0.18,
          cellRendererFramework: this.renderName,
          headerCheckboxSelection: this.props.removeUser,
          headerCheckboxSelectionFilteredOnly: this.props.removeUser,
          checkboxSelection: this.props.removeUser
        },
        {
          headerName: props.t('cell_phone'),
          field: "phoneNumber",
          width: this.props.tableZone * 0.16,
        },
        {
          headerName: props.t('roles'),
          field: "roles",
          cellRendererFramework: this.renderRoles,
          width: this.props.tableZone * 0.16,
        },
        {
          headerName: props.t('status'),
          field: "isActive",
          width: this.props.tableZone * 0.12,
          cellRendererFramework: p => <p>{p.value ? props.t('active') : p.value === false ? props.t('non_active') : null}</p>
        }
      ],
      detailCellRenderer: "childTable",
      defaultColDef: {
        resizable: true
      },
      rowData: [],
      localeText: { noRowsToShow: props.t('no_user') },
      suppressRowClickSelection: this.props.removeUser,
      rowSelection: 'multiple',
      onSelectionChanged: this.onSelectionChanged
    }

    this.gridOptionsForImported = {
      columnDefs: [
        {
          headerName: props.t('name'),
          field: "name",
          width: this.props.tableZone * 0.15,
        },
        {
          headerName: props.t('email'),
          field: "email",
          width: this.props.tableZone * 0.2,
          cellRendererFramework: this.renderEmail,
        },
        {
          headerName: props.t('family'),
          field: "family",
          width: this.props.tableZone * 0.2,
          cellRendererFramework: this.renderFamily,
        },
        {
          headerName: props.t('cell_phone'),
          field: "phoneNumber",
          width: this.props.tableZone * 0.1,
        },
        {
          headerName: props.t('age'),
          field: "birthday",
          cellRendererFramework: this.renderAge,
          width: this.props.tableZone * 0.05,
        },
        {
          headerName: props.t('gender'),
          field: "gender",
          cellRendererFramework: this.renderGender,
          width: this.props.tableZone * 0.07,
        },
        {
          headerName: props.t('created_at'),
          field: "createdAt",
          cellRendererFramework: this.renderCreated,
          width: this.props.tableZone * 0.11,
          headerComponentParams: {
            reGetData: this.getUsers, sortable: true, sortOrder: this.state.sortOrder,
            reSetState: (o, b) => {
              this.setState({ sortOrder: o, sortBy: b })
            }
          },
        },
        {
          headerName: props.t('action'),
          cellRendererFramework: this.renderDeleteBtn,
          width: this.props.tableZone * 0.06
        }
      ],
      detailCellRenderer: "childTable",
      defaultColDef: {
        resizable: true
      },
      rowData: [],
      localeText: { noRowsToShow: props.t('no_user') }
    }
  }

  renderSentInvoiceAt = params => {
    if (params.data && Object.keys(params.data).length === 0) {
      return null
    }

    if (!params.data.sentInvoiceAt) {
      return null
    }

    if (params.data.sentInvoiceAt === "0001-01-01T00:00:00") {
      return null
    }

    const utcTime = moment.utc(params.data.sentInvoiceAt).toDate()

    return <p>{moment(utcTime).local().format("L") + " " + moment(utcTime).local().format("HH:mm")}</p>
  }

  renderSkill = params => {
    if (params.data && Object.keys(params.data).length === 0) {
      return null
    }

    if (!params.data.teacherSkills) {
      return null
    }

    return <p>{params.data.teacherSkills.map(skill => skill.name).join(", ")}</p>
  }

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows()
    // console.log(selectedRows.map(user => user.id))
    this.setState({
      deleteIds: selectedRows.map(user => user.id)
    })
  }

  renderEmployed = (params) => {
    if (params.data.roles.includes("Teacher")) {
      return (
        <div style={{ textAlign: 'center' }}>
          <span
            role="button"
            tabIndex="-1"
            onClick={() => {
              this.props.toggleEmployTeacher(params.data.id, result => {
                if (Array.isArray(result)) {
                  const message = result.map(e => e.value)
                  this.props.setModal({
                    type: "danger",
                    message,
                  })
                  return
                }
                this.getUsers()
              })
            }}
            className={params.value ? "ok" : "not-ok"} />
        </div>
      )
    }
    return <div />
  }

  renderName = params => {
    if (params.value) {
      return <LinkEditBtn label={`${_.get(params, "value")}`}
        onClick={() => history.push(`/user/edit-user/${_.get(params, "data.id")}`, { isAdminStaff: this.isAdminStaff })}
      />
    }
    return <div />
  }

  renderPrograms = (params) => {
    if (params.value && params.value.length) {
      return params.value.map((e, i) => (
        <Label key={i}>
          <Button
            onClick={() => window.open(`/course/edit-program/${e.id}`)}
            color="link"
          >
            {e.name}
          </Button>
          <span>{i !== params.value.length - 1 && ", "}</span>
        </Label>
      ))

    }
    return <div />
  }

  renderCourses = (params) => {
    if (params.value && params.value.length) {
      return params.value.map((e, i) => (
        <Label key={i}>
          <Button
            onClick={() => window.open(`/course/edit-course/${e.id}`)}
            color="link"
          >
            {e.name}
          </Button>
          <span>{i !== params.value.length - 1 && ", "}</span>
        </Label>
      ))
    }
    return <div />
  }

  renderEmail = (params) => {
    if (params.value) {
      return <>
        <LinkEditBtn label={`${_.get(params, "value")}`}
          onClick={() => history.push(`/user/edit-user/${_.get(params, "data.id")}`, { isImported: this.isImported })}
        />
        <div className="d-inline ml-2" title="Open in new tab">
          <LinkEditBtn noIcon onClick={() => window.open(`/user/edit-user/${_.get(params, "data.id")}`)}>
            <i style={{ width: '16px', height: '16px' }} className="fa fa-sign-in fa-lg text-primary mt-2" />
          </LinkEditBtn>
        </div>
      </>
    }
    return <div />
  }

  renderFamily = (params) => {
    if (params.value) {
      return <LinkEditBtn label={`${_.get(params, "value.name")}`}
        onClick={() => window.open(`/user/edit-family/${_.get(params, "value.id")}`)}
      />
    }
    return <div />
  }

  renderCreated = params => {
    if (params.value) {
      const time = _.get(params, "value")
      return <span>{time !== '0001-01-01T00:00:00' && formatToLocalTime(time).format('YYYY-MM-DD HH:mm')}</span>
    }

    return <div />
  }

  renderRoles = (params) => {
    const translatedRoles = []
    for (const role in params.value) {
      switch (params.value[role]) {
        case 'STUDENT':
          translatedRoles.push(this.props.t('student'))
          break
        case 'TEACHER':
          translatedRoles.push(this.props.t('teacher'))
          break
        case 'PARENT':
          translatedRoles.push(this.props.t('parent'))
          break
        case 'ASSISTANCE':
          translatedRoles.push(this.props.t('assistance'))
          break
        case 'ADMIN':
          translatedRoles.push(this.props.t('admin'))
          break
        case 'STAFF':
          translatedRoles.push(this.props.t('staff'))
          break
        case 'DEVELOPER':
          translatedRoles.push("DEVELOPER")
          break
        case 'ADMIN SALARY':
          translatedRoles.push(this.props.t("admin_salary"))
          break
        default:
          translatedRoles.push(this.props.t('unknown'))
          break
      }
    }
    return translatedRoles.join(', ')
  }

  renderGender = (params) => {
    switch (params.value) {
      case 'Male':
        return this.props.t('male')
      case 'Female':
        return this.props.t('female')
      case undefined:
        return ''
      default:
        return this.props.t('unknown')
    }
  }

  renderAge = (params) => {
    return <p>{params.value && params.value !== "0001-01-01T00:00:00" ? moment().diff(params.value, 'years') : ""}</p>
  }

  renderDeleteBtn = params => {
    // console.log(params)
    if (params.data && Object.keys(params.data).length === 0) {
      return null
    }

    return <DeleteBtn className="text-center" onClick={() => {
      this.props.setModal({
        type: 'warning',
        message: `${this.props.t('confirm_delete_student')}: ${params.data.name} ?`,
        cb: () => {
          deleteOldImportedUser(params.data.id)
            .then(() => this.props.setAlert({
              type: 'success',
              message: this.props.t('delete_imported_student_success'),
            }))
            .then(() => this.getUsers(1))
            .catch(() => {
              this.props.setAlert({
                message: this.props.t('delete_failed'),
                type: "danger",
              })
            })
        }
      })
    }} />
  }


  studentsCourseModal = () => {
    const { students, courseName } = this.state
    return (
      <ViewModal ref={(ref) => { this.scModal = ref }} title={`${this.props.t('student_list_of_course')} ${courseName}`}>
        <StudentListTable students={students} t={this.props.t} />
      </ViewModal>
    )
  }

  async componentDidMount() {
    if (!this.props.isAdminStaff && !this.props.isImported) {

    }
    this.getUsers()
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen })

  getCurrentPageFromProps = () => {
    if (this.props.isAdminStaff) return this.props.adminStaffCurrentPage
    if (this.props.isImported) return this.props.importedCurrentPage
    return this.props.currentPage
  }

  getPageSizeFromProps = () => {
    if (this.props.isAdminStaff) return this.props.adminStaffPageSize
    if (this.props.isImported) return this.props.importedPageSize
    return this.props.pageSize
  }

  getPageSizeNameFromProps = () => {
    if (this.props.isAdminStaff) return "adminStaffPageSize"
    if (this.props.isImported) return "importedPageSize"
    return "userPageSize"
  }

  getChangePageSizeFromProps = () => {
    if (this.props.isAdminStaff) return this.props.changePageSizeAdminStaff
    if (this.props.isImported) return this.props.changePageSizeImported
    return this.props.changePageSizeUser
  }

  getUsers = (page, sortOrderParam, sortByParam) => {
    const { roleFilters, sortBy, sortOrder, courseIds, programIds, skillIds, searchString, paymentStatus, year, sem } = this.state
    const { isAdminStaff, isImported } = this.props
    let getUserOption = null
    if (!this.props.removeUser) {
      getUserOption = this.props.getAllUser
    } else {
      getUserOption = this.props.getOldUsers
    }

    let availabeRoles
    if (roleFilters && roleFilters.length) {
      availabeRoles = roleFilters.map(m => m.value)
    } else if (isAdminStaff) {
      availabeRoles = ["Admin", "Developer", "Staff"]
    }

    getUserOption({
      currentPage: page || this.getCurrentPageFromProps(),
      searchString,
      roleFilters: availabeRoles,
      sortBy: sortByParam || sortBy,
      sortOrder: sortOrderParam || sortOrder,
      pageSize: this.getPageSizeFromProps(),
      courseIds: courseIds.length > 0 ? courseIds.map(m => m.value) : null,
      programIds: programIds.length > 0 ? programIds.map(m => m.value) : null,
      isImported,
      isLocked: this.state.isLocked.value,
      isActive: this.state.isActive.value === 1,
      skillIds,
      isAdminStaff,
      paymentStatus: paymentStatus.value,
      year: year.value,
      semester: sem.value,
      cb: data => {
        data = [...data, {}]
        this.gridApi.setRowData(data)
        this.gridApi.sizeColumnsToFit()
        this.gridApi.forEachNode(node => {
          node.setSelected(this.state.deleteIds.includes(node.data.id))
        })
      },
    })
  };

  onPrintPDF = () => {
    if (this.props.users.length === 0) {
      this.props.setAlert({
        message: this.props.t('no_data_to_print'),
        type: "danger",
      })
    } else {
      const doc = new PDF()
      const body = []

      this.props.users.forEach(i => {
        const item = [
          i.identity.email,
          i.identity.lastName,
          i.identity.firstName,
          i.identity.phoneNumber,
          i.roles,
        ]
        body.push(item)
      })

      doc.autoTable({
        head: [[this.props.t('username'), this.props.t('last_name'), this.props.t('first_name'), this.props.t('cell_phone')]],
        body,
      })

      doc.save("Administraion-Report.pdf")
    }
  };

  resetPassword = email => {
    const { resetPassword, setModal } = this.props
    setModal({
      type: "primary",
      message: `${this.props.t('reset_password_confirm')} ${email}?`,
      cb: () => resetPassword(email),
    })
  };

  onSearchChange = e => {
    if (this.props.isAdminStaff || this.props.isImported) {
      this.setState({ searchString: e.target.value })
    } else {
      this.props.writeUserState({ searchString: e.target.value })
    }
  };

  onSearchKeyDown = e => {
    if (e.key === 'Enter') {
      this.getUsers(1)
    }
  }

  render() {
    const { isOpen, roleFilters, programIds, courseIds, paymentStatus } = this.state
    const { removeUser, isAdminStaff, t } = this.props

    const searchString = this.props.isImported || this.props.isAdminStaff ? this.state.searchString : this.props.searchString

    const roles = [{ value: "all", label: t('all') }, { value: "student", label: t('student') }, { value: "teacher", label: t('teacher') }]
    const removeUserRoles = [{ value: "student", label: t('student') }, { value: "teacher", label: t('teacher') }, { value: "assistance", label: t('assistance') }]
    const adminStaffRols = [{ value: "admin", label: t('admin') }, { value: "staff", label: t('staff') }, { value: "developer", label: "Developer" }]

    const roleOptions = removeUser ?
      removeUserRoles :
      (isAdminStaff ? adminStaffRols : roles)
    return (
      <div className="animated fadeIn pt-2">
        <Row>
          <Col md={4} className="d-flex align-items-center">
            {!this.props.isImported && <div>
              {!this.props.removeUser ?
                (<div style={{ marginRight: '30px' }}>
                  <CreateBtn title={this.props.t('create_user')} height="32px" onClick={() => history.push("/user/create-user")} />
                </div>) :
                (<div style={{ marginRight: '30px' }}>
                  <CustomBtn
                    color="danger"
                    onClick={() => this.props.removeOldUsers(this.state.deleteIds, this.getUsers)}
                    title={this.props.t('delete_user')}
                  />
                </div>)
              }
            </div>}

            <div className="flex-grow-1">
              <SearchBox
                onChange={this.onSearchChange}
                value={searchString}
                onKeyDown={this.onSearchKeyDown}
              />
            </div>
          </Col>

          {!this.props.isAdminStaff && !this.props.isImported && <Col>
            <Button color="link" className="link-adv" onClick={this.toggle}>{isOpen ? t('hide_advanced_search') : t('show_advanced_search')}</Button>
          </Col>}
          <Col md={this.props.removeUser || this.props.isAdminStaff ? 4 : ''}>
            <div className="text-right link-adv">{t('total_of_users')}: <span className="font-weight-bold">{this.props.totalItems || 0}</span></div>
          </Col>

        </Row>

        {!this.props.isAdminStaff && !this.props.isImported && <Collapse className="pt-2" isOpen={isOpen}>
          <Row>
            {/* <Col md={2}>
              <CustomSelected
                label={t('lock_status')}
                options={[{ label: t('unlocked'), value: 1 }, { label: t('locked'), value: 2 }]}
                value={this.state.isLocked}
                onChange={async e => {
                  await this.setState({
                    isLocked: e
                  })
                  this.getUsers(1)
                }}
              />
            </Col> */}

            <Col md={2}>
              <CustomSelected
                label={t('active_status')}
                options={activeOptions(t)}
                value={this.state.isActive}
                onChange={async e => {
                  await this.setState({
                    isActive: e
                  })
                  this.getUsers(1)
                }}
              />
            </Col>
            {/* <Col md={this.props.removeUser ? 4 : 2}>
              <CustomSelected
                placeholder={t('select_role')}
                options={roleOptions}
                isMulti
                label={t('select_role')}
                value={roleFilters}
                onChange={e => {
                  this.setState({ roleFilters: e }, () => {
                    this.getUsers(1)
                    this.setState({
                      deleteIds: []
                    })
                  })
                }}
              />
            </Col>
            {!this.props.removeUser && <Col md={2}>
              <CustomSelected
                label={t('had_payment')}
                options={paymentOptions(t)}
                value={this.state.paymentStatus}
                onChange={async e => {
                  await this.setState({
                    paymentStatus: e
                  })
                  this.getUsers(1)
                }}
              />
            </Col>} */}
          </Row>
          <Row>

          </Row>
        </Collapse>}

        <Row>
          <Col md={this.props.removeUser || this.props.isAdminStaff ? 8 : 12} sm={12}>
            <div className="ag-theme-balham my-2" style={{ width: "100%" }}>
              <AgGridReact
                domLayout="autoHeight"
                gridOptions={this.props.removeUser || this.props.isAdminStaff ?
                  this.gridOptionsForAdminAndRemove :
                  this.props.isImported ?
                    this.gridOptionsForImported :
                    this.gridOptions}
                masterDetail
                onGridReady={params => {
                  this.gridApi = params.api
                }}
                frameworkComponents={{
                  agColumnHeader: CustomAgHeader
                }}
                modules={[MasterDetailModule, ClientSideRowModelModule]}
              >
              </AgGridReact>
            </div>
            <CustomPagination
              pageCount={this.props.pageCount}
              pageSize={this.getPageSizeFromProps()}
              currentPage={this.getCurrentPageFromProps()}
              pageSizeName={this.getPageSizeNameFromProps()}
              changePage={currentPage => {
                this.getUsers(currentPage)
              }}
              changePageSize={this.getChangePageSizeFromProps()}
              onChangePageSize={() => this.getUsers(1)}
            />
          </Col>
        </Row>

        {this.studentsCourseModal()}
      </div>
    )
  }
}

export default withTranslation('common')(userList)