import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col, Container, Button, Input, Spinner } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4} from 'uuid'
import setLoading from "../../components/Spinner/action"
import setModal from "../../components/Modal/action"
import { getQuestion, createHightlightedTextComment, removeHightlightedTextComment } from "../../util/api"
import { getUserInfo } from '../Common/action'
import moment from 'moment'

const getTextNodesUnder = (el) => {
    const textNodes=[]; 
    const walk=document.createTreeWalker(el, NodeFilter.SHOW_TEXT,null,false);
    while (walk.nextNode()) {
        textNodes.push(walk.currentNode);
    }
    return textNodes;
}

const createHighLightNode = (text, id) => {
    const span = document.createElement('span')
    span.style.backgroundColor = '#ff4122'// '#FFFF76'//'#B4D7FF'
    span.style.userSelect = 'none'
    span.setAttribute('type', 'highlight')
    span.setAttribute('highlightId', id)
    span.innerHTML = text
    return span
}

const QuestionDiscussion = () => {
    const { questionId } = useParams()
    const dispatch = useDispatch()
    const isLoading = useSelector(state => state.loading.isLoading)
    const [question, setQuestion] = useState() 
    const [questionHtml, setQuestionHtml] = useState('')
    const [currentHighLightedText, setCurrentHighLightedText] = useState('')
    const [commentInput, setCommentInput] = useState('')
    const [comments, setComments] = useState([])
    const [isAddingComment, setIsAddingComment] = useState(false)
    const [isDeletingComment, setIsDeletingComment] = useState(false)
    const currentHighlightIdRef = useRef('')
    const userInfo = useSelector(state => state.common.userInfo)
    const getQuestionData = async () => {
        dispatch(setLoading(true))
        try {
            const res = await getQuestion(questionId)
            if (!res.data) throw new Error("")
            setQuestion(res.data)

            const answersHtml = `${res.data.answers.map((e, i) => `<div><span>${e.text}</span></div>`).join(' ')}`
            const initQuestionHtml = `
                <div class='mb-3'>
                    ${res.data.text}
                </div>
                ${answersHtml}
            `
            setQuestionHtml(res.data.questionHighlightHtml ? res.data.questionHighlightHtml.replaceAll('rgb(255, 255, 23)', 'rgb(180, 215, 255)') : initQuestionHtml)
            setComments(res.data.highlightedTextComments)
        } catch (err) {
            dispatch(setModal({
                type: "danger",
                message: err.response && err.response.data && err.response.data.status === 400 ? 'Question not found.' : err.message
            }))
        }

        dispatch(setLoading(false))
    }


    const onMouseUpInSideQuestionArea = () => {
        const selection = document.getSelection();
        if (!selection) return;
        if (!selection.toString()) return;
        const { anchorNode, focusNode } = selection
        const currentTextNode =  selection.anchorNode.nodeValue
        const { anchorOffset, focusOffset } = selection
        if (anchorNode.data.slice(anchorOffset, focusOffset) === selection.toString() ||
            anchorNode.data.slice(focusOffset, anchorOffset) === selection.toString()) {
            const left = focusOffset > anchorOffset ? currentTextNode.slice(0, anchorOffset) : currentTextNode.slice(0, focusOffset)
            const right = focusOffset > anchorOffset ? currentTextNode.slice(focusOffset) : currentTextNode.slice(anchorOffset)
            const selected = focusOffset > anchorOffset ? currentTextNode.slice(anchorOffset, focusOffset) : currentTextNode.slice(focusOffset, anchorOffset)
            const parentSpan = document.createElement('span')
            parentSpan.appendChild(document.createTextNode(left))
            const highlightId = uuidv4()
            parentSpan.appendChild(createHighLightNode(selected, highlightId))
            parentSpan.appendChild(document.createTextNode(right))
            anchorNode.parentNode.replaceChild(parentSpan, anchorNode)

            currentHighlightIdRef.current = highlightId
            const questionAreaNode = document.querySelector('.can-highlight')
            setQuestionHtml(questionAreaNode.innerHTML.replaceAll('rgb(255, 255, 23)', 'rgb(180, 215, 255)').replaceAll('rgb(255, 65, 34)', 'rgb(255, 255, 23)'))
            setCurrentHighLightedText(selected)
            // setComments([])
            return;
            
        }

        let questionAreaNode = document.querySelector('.can-highlight')
        const texts = getTextNodesUnder(questionAreaNode)
        let anchorIndex = -1
        let focusIndex = -1
        
        for (let i = 0; i < texts.length - 1; i++) {
            if (texts[i] === anchorNode) {
                anchorIndex = i
            }

            if (texts[i] === focusNode) {
                focusIndex = i
            }
        }

        const startOffset = focusIndex > anchorIndex ? anchorOffset : focusOffset
        const endOffset = focusIndex > anchorIndex ? focusOffset : anchorOffset
        const startIndex = focusIndex > anchorIndex ? anchorIndex : focusIndex
        const endIndex = focusIndex > anchorIndex ? focusIndex : anchorIndex
        let highlightText = ''

        // Check duplicate highlight
        for (let i = startIndex; i <= endIndex; i++) {
            if (!!texts[i] && texts[i].parentElement.getAttribute('type') === 'highlight') {
                selection.removeAllRanges()
                return
            }
        }

        const highlightId = uuidv4()

        for (let i = startIndex; i <= endIndex; i++) {
            let selected = texts[i].nodeValue
            if (i === startIndex) {
                selected = texts[i].nodeValue.slice(startOffset)
                const left = texts[i].nodeValue.slice(0, startOffset)
                const parentSpan = document.createElement('span')
                parentSpan.appendChild(document.createTextNode(left))
                parentSpan.appendChild(createHighLightNode(selected, highlightId))
                texts[i].parentNode.replaceChild(parentSpan, texts[i])
                highlightText += selected
                continue
            }

            if (i === endIndex) {
                selected = texts[i].nodeValue.slice(0, endOffset)
                const right = texts[i].nodeValue.slice(endOffset)
                const parentSpan = document.createElement('span')
                parentSpan.appendChild(createHighLightNode(selected, highlightId))
                parentSpan.appendChild(document.createTextNode(right))
                texts[i].parentNode.replaceChild(parentSpan, texts[i])
                highlightText += selected
                continue
            }

            highlightText += selected
            const newNode = createHighLightNode(selected, highlightId)
            const forAppendNode = document.querySelector('.for-append')
            forAppendNode.append(newNode)
            const shouldBeIgnored = forAppendNode.firstElementChild.getBoundingClientRect().width === 0
            forAppendNode.removeChild(forAppendNode.firstChild)
            if (!shouldBeIgnored) {
                texts[i].parentNode.replaceChild(newNode, texts[i])
            }
        }

        questionAreaNode = document.querySelector('.can-highlight')
        currentHighlightIdRef.current = highlightId
        setQuestionHtml(questionAreaNode.innerHTML.replaceAll('rgb(255, 255, 23)', 'rgb(180, 215, 255)').replaceAll('rgb(255, 65, 34)', 'rgb(255, 255, 23)'))
        setCurrentHighLightedText(highlightText)
        // setComments([])
    }

    useEffect(() => {
        getQuestionData()
        dispatch(getUserInfo())
    }, [])

    useEffect(() => {
        if (!question) return;
        setTimeout(() => {
            const questionAreaNode = document.querySelector('.can-highlight')
            console.log(questionAreaNode)
            questionAreaNode.addEventListener('mouseup', onMouseUpInSideQuestionArea)
        }, 0)
        
    }, [question])

    useEffect(() => {
        if (!questionHtml) return;
        setTimeout(() => {
            const spans = document.querySelectorAll('[type=highlight]')

            if (currentHighlightIdRef.current) {
                const hightlights = document.querySelectorAll(`[highlightId='${currentHighlightIdRef.current}']`)
                let highlightText = ''
                for (let hIndex = 0; hIndex <= hightlights.length - 1; hIndex++) {
                    hightlights[hIndex].style.backgroundColor = 'rgb(255, 255, 23)'
                    highlightText += hightlights[hIndex].innerHTML
                }
                setCurrentHighLightedText(highlightText)
            }

            spans.forEach((e, i) => e.addEventListener('click', () => {
                const highlightId = e.getAttribute('highlightId')
                if (highlightId === currentHighlightIdRef.current) return;
                const hightlights = document.querySelectorAll(`[highlightId='${highlightId}']`)
                let highlightText = ''
                for (let hIndex = 0; hIndex <= hightlights.length - 1; hIndex++) {
                    hightlights[hIndex].style.backgroundColor = 'rgb(255, 65, 34)'
                    highlightText += hightlights[hIndex].innerHTML
                }
                currentHighlightIdRef.current = highlightId
                const questionAreaNode = document.querySelector('.can-highlight')
                setQuestionHtml(questionAreaNode.innerHTML.replaceAll('rgb(255, 255, 23)', 'rgb(180, 215, 255)').replaceAll('rgb(255, 65, 34)', 'rgb(255, 255, 23)'))
                setCurrentHighLightedText(highlightText)
            }))
        }, 0)
    }, [questionHtml])

    if (isLoading) return <Container className='mt-5'>
        <h3>Loading question data</h3>
    </Container>

    if (!question) return <Container className='mt-5'>
        <h3>No question data</h3>
    </Container>

    const onClickMe = () => {
        const questionAreaNode = document.querySelector('.can-highlight')
            console.log(questionAreaNode.innerHTML)
    }

    const addComment = async () => {
        setIsAddingComment(true)
        const comment = {
            questionId,
            comment: commentInput,
            highlightedTextId: currentHighlightIdRef.current,
            userId: userInfo && userInfo.identity && userInfo.identity.id,
            questionHighlightHtml: questionHtml.replaceAll('rgb(255, 255, 23)', 'rgb(180, 215, 255)')
        }
        console.log(comment)

        try {
            const res = await createHightlightedTextComment(comment)
            if (!res.data) throw new Error("")
            console.log('set na', res.data.highlightedTextComments)
            setQuestionHtml(res.data.questionHighlightHtml)
            setComments(res.data.highlightedTextComments)
            setCommentInput('')
        } catch (err) {
            dispatch(setModal({
                type: "danger",
                message: err.response && err.response.data && err.response.data.status === 400 ? 'Question not found.' : err.message
            }))
        }

        setIsAddingComment(false)
    }

    const removeComment = async (id) => {
        setIsDeletingComment(true)
        try {
            const res = await removeHightlightedTextComment(id, questionId)
            if (!res.data) throw new Error("")
            setQuestionHtml(res.data.questionHighlightHtml)
            setComments(res.data.highlightedTextComments)
            setCommentInput('')
        } catch (err) {
            dispatch(setModal({
                type: "danger",
                message: err.response && err.response.data && err.response.data.status === 400 ? 'Question not found.' : err.message
            }))
        }
        setIsDeletingComment(false)
    }

    return <Container className='mt-5'>
        <Row>
            <Col xs={6}>
                <div  className='can-highlight' dangerouslySetInnerHTML={{__html: questionHtml}} />
            </Col>
            <Col>
                {!currentHighLightedText ? <div style={{textAlign: 'center'}}><h3>{'Select text to add comment'}</h3></div> : <>
                    <Row className='d-flex flex-row  mb-2'><h3 dangerouslySetInnerHTML={{__html: currentHighLightedText}}></h3></Row>
                    {comments.filter(c => c.hightlightedTextId == currentHighlightIdRef.current).map(c => <Row key={c.id} className='mb-2'>
                        <Col xs={8} className='pl-0 d-flex flex-row justify-content-between align-items-center'>
                            <span><b className='mr-2'>{c.userName}</b>  <span>{c.comment}</span></span>
                            <span>{moment(c.createdAt).format('DD-MM-YY hh:mm')}</span>
                        </Col>
                        <Col xs={4} className='d-flex flex-row align-items-center'>
                            <Button disabled={isAddingComment || isDeletingComment} color='danger' outline onClick={() => removeComment(c.id)}>{isDeletingComment ? <><Spinner size='sm' />{' '}</> : null} Remove</Button>
                        </Col>
                        
                    </Row>)}
                    <Row>
                        <Col className='pl-0' xs={8}>
                            <Input value={commentInput} onChange={(e) => setCommentInput(e.target.value)}/>
                        </Col>
                        <Col className='d-flex flex-row align-items-center'>
                            <Button disabled={!commentInput || isAddingComment || isDeletingComment} color='success' onClick={addComment}>{isAddingComment ? <><Spinner size='sm' />{' '}</> : null} Add comment</Button>
                        </Col>
                        
                    </Row>
                    {/* <Row className='mt-3' onSave={onSave}><Button color='primary'>Save</Button></Row> */}
                    
                </>}
                <div className='for-append'></div>
                
            </Col>
        </Row>
    </Container>
}

export default QuestionDiscussion