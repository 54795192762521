import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Editor } from "@tinymce/tinymce-react";
import { Col, Row } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { trim } from "lodash";
import useTimeout from "../../hooks/useTimeout";
import { getAnalysisText } from "../../util/api";
import GrammarSpelling from "./GrammarSpelling";

const ALLOWABLE_SIZE_LIMIT = 1000000

const extractContent = s => {
    const span = document.createElement("span");
    span.innerHTML = s;
    const children = span.querySelectorAll("*");
    for (let i = 0; i < children.length; i++) {
        if (children[i].textContent) children[i].textContent += " ";
        else children[i].innerText += " ";
    }

    return [span.textContent || span.innerText].toString().replace(/ +/g, " ");
};

export const TINY_MCE_API_KEY = "x6y5w5ts8vfe8ehznkupoyiuygogzj553il19oo16c1fe2yi";

const originalHightlight = (data, tag) => {
    const shouldReplaceIndexes = [];
    let index = 0;
    const postRegx = /(^[\w])|>/;
    const preRegx = /(^[\w])|</;
    while (index < data.length) {
        const pre = data[index - 1];
        const post = data[index + tag.length];
        const shouldReplace =
            data.slice(index, index + tag.length) === tag &&
            !((pre && pre.match(preRegx)) || (post && post.match(postRegx)));
        if (shouldReplace) shouldReplaceIndexes.push(index);
        index++;
    }
    if (!shouldReplaceIndexes.length) return data;
    let result = data.slice(0, shouldReplaceIndexes[0]);
    for (let i = 0; i < shouldReplaceIndexes.length; i++) {
        result += `<span style="text-decoration: underline wavy red;">${tag}</span>`;
        if (i === shouldReplaceIndexes.length - 1) {
            result += data.slice(shouldReplaceIndexes[i] + tag.length);

            break;
        }
        result += data.slice(
            shouldReplaceIndexes[i] + tag.length,
            shouldReplaceIndexes[i + 1]
        );
    }

    return result;
};

const hightlight = (data, tag) => {
    const index = data.indexOf(
        `<span style="text-decoration: underline wavy red;">`
    );
    if (index === -1) {
        return originalHightlight(data, tag);
    }
    const left = data.slice(0, index);
    const middle = data.slice(
        index,
        index + data.slice(index).indexOf("</span>") + 7
    );
    const right = data.slice(index + 7 + data.slice(index).indexOf("</span>"));
    return originalHightlight(left, tag) + middle + hightlight(right, tag);
};

const ProwritingEditor = ({
    initValue,
    innerRef,
    onChange,
    answerId,
    currentEdittingEditorIdRef,
    isAnswerList,
    renderInput,
    isClickPublish,
    invalid
}) => {
    const editorRef = useRef(null);
    const tiniDataRef = useRef("");
    const tiniDataVersionRef = useRef(0);
    const isInitvalueChanged = useRef(false);
    const isAutoUpdatedTiniDataRef = useRef(false);
    const [tags, setTags] = useState([]);
    const editorIdRef = useRef(uuidv4());
    const isInitialedValue = useRef(false);

    const analysisText = async version => {
        const texts = trim(extractContent(tiniDataRef.current));
        console.log("Paragraph after convert::", texts);
        if (!texts) return;
        try {
            const res = await getAnalysisText(texts);
            if (version !== tiniDataVersionRef.current) {
                console.log("Expired");
                return;
            }
            let newTags = res.data.result.tags;
            let newTinyData = tiniDataRef.current;
            let index = -2;
            while (true) {
                index = newTinyData.indexOf(
                    '<span style="text-decoration: underline wavy red;">'
                );
                if (index === -1) break;
                newTinyData = newTinyData.replace(
                    '<span style="text-decoration: underline wavy red;">',
                    ""
                );
                newTinyData =
                    newTinyData.slice(0, index) +
                    newTinyData.slice(index).replace("</span>", "");
            }

            newTags = newTags.filter((e, i) => {
                const index = newTags.findIndex(
                    m => m.subcategory === e.subcategory
                );
                return index === i;
            });

            newTags.sort((a, b) => b.subcategory.length - a.subcategory.length);

            for (let i = 0; i < newTags.length; i++) {
                newTinyData = hightlight(newTinyData, newTags[i].subcategory);
            }

            const isTinyDataChanged =
                editorRef.current.getContent() !== newTinyData;
            tiniDataRef.current = newTinyData;
            isAutoUpdatedTiniDataRef.current = isTinyDataChanged;
            editorRef.current.setContent(tiniDataRef.current);
            if (editorIdRef.current === currentEdittingEditorIdRef.current) {
                editorRef.current.selection.select(
                    editorRef.current.getBody(),
                    true
                );
                editorRef.current.selection.collapse(false);
                editorRef.current.focus();
            }
            setTags(newTags);
        } catch (err) { }
    };

    const changeTinyValue = newText => {
        // setTinyValue(newText);
        editorRef.current.setContent(newText);
        // if (innerRef) {
        //     innerRef.current = newText;
        // }
    };

    const { init, clear } = useTimeout(
        () => analysisText(tiniDataVersionRef.current),
        300
    );

    useEffect(() => {
        if (editorRef.current) {
            if (isInitialedValue.current) return;
            isInitialedValue.current = true;
            editorRef.current.setContent((initValue ? initValue : ''));
            if (editorIdRef.current === currentEdittingEditorIdRef.current) {
                editorRef.current.selection.select(
                    editorRef.current.getBody(),
                    true
                );
                editorRef.current.selection.collapse(false);
                editorRef.current.focus();
            }
            isInitvalueChanged.current = false;
            return;
        }

        isInitvalueChanged.current = true;
    }, [initValue]);
    const calWidthContent = () => {
        if (isClickPublish && isAnswerList) {
            return 12
        }
        if (isClickPublish) {
            return 10
        }
        if (!isClickPublish && isAnswerList) {
            return 8
        }
        return 9
    }

    const calWidthCheck = () => {
        if (!isClickPublish && isAnswerList) {
            return 4
        }
        return 3
    }

    return (
        <Row>
            <Col
                xs={calWidthContent()}
                className={isAnswerList ? `d-flex position-relative` : ""}
            >
                <div
                    className={classNames({
                        "border": invalid,
                        "invalid-field": invalid
                    })}
                    style={{
                        borderRadius: '0.3rem'
                    }}
                >
                    <Editor
                        key={editorIdRef.current}
                        onInit={(evt, editor) => {
                            editorRef.current = editor;
                            if (innerRef) {
                                innerRef.current = editor;
                            }

                            if (isInitvalueChanged.current) {
                                // update editor content
                                editor.setContent((initValue ? initValue : ''));
                                isInitvalueChanged.current = false;
                            }
                        }}
                        apiKey={TINY_MCE_API_KEY}
                        // initialValue={}
                        // value={tinyValue}
                        onEditorChange={(content, editor) => {
                            if (onChange) {
                                onChange(content, answerId);
                            }
                            if (isAutoUpdatedTiniDataRef.current) {
                                isAutoUpdatedTiniDataRef.current = false;
                                return;
                            }
                            tiniDataRef.current = content;
                            tiniDataVersionRef.current += 1;
                            clear();
                            init();
                        }}
                        onFocus={() => {
                            currentEdittingEditorIdRef.current =
                                editorIdRef.current;
                        }}
                        init={{
                            height: 500,
                            menubar: false,
                            paste_preprocess: (plugin, args) => {
                                const newNode = document.createElement("span");
                                newNode.innerHTML = `&nbsp;${args.content}`;
                                editorRef.current.selection.setNode(newNode);
                                args.preventDefault();
                            },
                            external_plugins: {
                                'tiny_mce_wiris': `${window.location.href}/node_modules/@wiris/mathtype-tinymce6/plugin.min.js`
                            },
                            extended_valid_elements: '*[*]',
                            toolbar:
                                "undo redo | formatselect | image | " +
                                "bold italic backcolor | alignleft aligncenter " +
                                "alignright alignjustify | bullist numlist outdent indent | " +
                                // "removeformat | help" +
                                "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table",
                            plugins:
                                "advlist autolink lists link image charmap print preview anchor " +
                                "searchreplace visualblocks code fullscreen " +
                                "insertdatetime media table paste code help wordcount table",
                            content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            file_picker_types: "image",
                            image_uploadtab: true,
                            file_picker_callback: (cb, value, meta) => {
                                const input = document.createElement("input");
                                input.setAttribute("type", "file");
                                input.setAttribute("accept", "image/*");

                                input.addEventListener("change", e => {
                                    const file = e.target.files[0];

                                    if (file.size > ALLOWABLE_SIZE_LIMIT) {
                                        window.alert('Do not upload files larger than 1Mb!')
                                        return;
                                    }

                                    const reader = new FileReader();
                                    reader.addEventListener("load", () => {
                                        /*
                                    Note: Now we need to register the blob in TinyMCEs image blob
                                    registry. In the next release this part hopefully won't be
                                    necessary, as we are looking to handle it internally.
                                    */
                                        const id = "blobid" + new Date().getTime();
                                        const blobCache =
                                            window.tinymce.activeEditor.editorUpload
                                                .blobCache;
                                        const base64 = reader.result.split(",")[1];
                                        const blobInfo = blobCache.create(
                                            id,
                                            file,
                                            base64
                                        );
                                        blobCache.add(blobInfo);

                                        // console.log('INFOOOOO', blobInfo.blobUri())

                                        /* call the callback and populate the Title field with the file name */
                                        cb(blobInfo.blobUri(), {
                                            title: file.name
                                        });
                                    });
                                    reader.readAsDataURL(file);
                                });

                                input.click();
                            }
                        }}
                    />
                </div>
                {isAnswerList && (
                    <div
                        style={{
                            width: "40px",
                            height: '200px',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#eee",
                            border: "1px solid #9c9a9a",
                            borderTopRightRadius: "4px",
                            borderBottomRightRadius: "4px",
                            borderLeft: 'none'
                        }}
                    >
                        {renderInput()}
                    </div>
                )}
            </Col>
            {!isClickPublish && <Col xs={calWidthCheck()}>
                {!!tags && tags.length ? (
                    <GrammarSpelling
                        tags={tags}
                        onChangeTinyValue={changeTinyValue}
                        editorRef={editorRef}
                    />
                ) : null}
            </Col>}
        </Row>
    );
};

export default ProwritingEditor;
