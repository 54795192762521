import React from "react";
import Select from "react-select";
import { FormGroup } from "reactstrap";
import useFetch from "../../hooks/useFetch";
import { getAllTaskSubject } from "../../util/api";

const SubjectSelector = ({ onChange, ...rest }) => {
    const { data: subjects } = useFetch(getAllTaskSubject);

    const options =
        subjects &&
        subjects.map(({ id, name }) => {
            return {
                value: id,
                label: name
            };
        });

    return (
        <FormGroup>
            <Select
                isClearable
                placeholder="Select subject"
                options={options}
                onChange={onChange}
                {...rest}
            />
        </FormGroup>
    );
};

export default SubjectSelector;
