import React, { useRef, useState } from "react";
import {
    Row,
    Col,
    FormGroup,
    Input,
    Label,
    Button,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useDispatch } from "react-redux";
import { updateQuestionReminder } from "../../../util/api";
import { TINY_MCE_API_KEY } from "../../MyTask/ProwritingEditor";
import setAlert from "../../../components/Alert/action";

const EditQuestionReminder = (props) => {
    const {
        match: {
          params: { id },
        },
        location: {
          data
        }
      } = props;
    const history = useHistory();
    const [nameQuestionReminder, setNameQuestionReminder] = useState(data.name);
    // const [description, setDescription] = useState(data.description);
    const descriptionRef = useRef(null);
    const dispatch = useDispatch();

    const handleSubmit = async (idx) => {
        // call api update group
        if(!nameQuestionReminder){
          window.alert("Question reminder's name is required")
          return;
      }
      let description = descriptionRef.current.getContent();
      const div = document.createElement("div");
      div.innerHTML = description;
      const text = div.textContent || div.innerText || "";
      if (!text.trim()) {
          description = "";
      }

      if(!description){
          window.alert("Question reminder's description is required")
          return;
      }
        // const newQuestionReminder = {
        //   id : idx,
        //   name: nameQuestionReminder,
        //   description
        // }
        // await updateQuestionReminder(newQuestionReminder)
        // history.push(`/user-family/question-reminder`)

        try{
          const newQuestionReminder = {
            id : idx,
            name: nameQuestionReminder,
            description
          }
          await updateQuestionReminder(newQuestionReminder)
          history.push(`/user-family/question-reminder`)
          dispatch(
              setAlert({
                  type: "success",
                  message: "Edit question reminder successfully"
              })
          );
        }
        catch(err){
            dispatch(
                setAlert({
                    type: "danger",
                    message: "Edit failed"
                })
            );
        }
    }
    return (<>
        <div className="card-body px-3">
            <strong className="table-header-label">Edit Question Reminder&apos;s Name</strong>
        </div>
        <Row form>
          <Col md={5} className="p-3">
            <FormGroup>
              <Label>
                New Question Reminder&apos;s Name
              </Label>
              <Input
                    type="text"
                    autoFocus
                    value={nameQuestionReminder}
                    onChange={e => setNameQuestionReminder(e.target.value)}
                    invalid={false}
                />

              <Label>
                New Description
              </Label>
              <Editor
                onInit={(evt, editor) => {
                    descriptionRef.current = editor
                }}
                apiKey={TINY_MCE_API_KEY}
                // onEditorChange={(content, editor) => {}}
                initialValue={data.description}
                init={{
                    height: 400,
                    menubar: false,
                    branding: false,
                    extended_valid_elements: "*[*]",
                    toolbar:
                        "undo redo | formatselect | image media | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent" ,
                    plugins:
                        "advlist autolink lists link image media charmap preview anchor " +
                        "searchreplace visualblocks code fullscreen " +
                        "insertdatetime media table code help wordcount",
                    content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    file_picker_types: "image",
                    image_uploadtab: true
                }}
            />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
            <Col md={5} className="px-3">
                <Button 
                className="px-4 pl-1 mr-2" 
                color="primary"
                onClick={()=> handleSubmit(id)}
                >
                Update
                </Button>
                <Button
                onClick={() => {
                    history.push("/user-family/question-reminder")
                }}
                className="px-4 mr-2 ml-2"
                >
                Cancel
                </Button>
            </Col>
        </Row>
        </>
    )
}

export default EditQuestionReminder