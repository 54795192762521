import React from "react";
import Select from "react-select";

import useFetch from "../hooks/useFetch";
import { getAllUser } from "../util/api";

const CreatorSelector = ({ value, onChange, groupId, ...rest }) => {
    const { data: users } = useFetch(getAllUser);

    const formatOptionLabel = e => {
        return e.value;
    };

    const options = users
        ? users
              .filter(user => {
                  return user.roles.includes("Question creator");
              })
              .filter(user => {
                  if (!groupId) return user;

                  return user.groups.some(i => {
                      return i.id === groupId;
                  });
              })
              .map(user => {
                  const fullName =
                      user.firstName && user.lastName
                          ? `${user.firstName} ${user.lastName}`
                          : "";

                  return {
                      label: user.email,
                      value: `${user.id} ${fullName ? `| ${fullName}` : ""} | ${
                          user.email
                      }`
                  };
              })
              .reverse()
        : [];

    return (
        <Select
            value={value ? options.find(e => e.value.includes(value)) : ""}
            formatOptionLabel={formatOptionLabel}
            options={options}
            onChange={e => {
                if (e) {
                    const arr = e.value.split(" | ");

                    onChange(arr[arr.length - 1]);
                } else {
                    onChange(null);
                }
            }}
            {...rest}
        />
    );
};

export default CreatorSelector;
