import React from "react"
import { connect } from "react-redux"

import ReportList from "./ReportList"
import {
    getAllReportTest,
    getAllReportStudent,
    changePageSizeTestReportStudent,
    changePageSizeTestReportTest,
    changeCurrentPageTestReportStudent,
    changeCurrentPageTestReportTest
} from "../redux/action"

const ReportListContainer = props => {
    const { match: { params: { reportType } } } = props

    if (!["test", "student"].includes(reportType)) return null

    let reportProps = {}

    if (reportType === "test") {
        reportProps = {
            currentPage: props.currentPageTest,
            pageSize: props.pageSizeTest,
            pageCount: props.pageCountTest,
            totalItems: props.totalItemsTest,
            reportList: props.testReportList,
            changePageSizeReport: props.changePageSizeTestReportTest,
            changeCurrentPageReport: props.changeCurrentPageTestReportTest,
            getAllReport: props.getAllReportTest,
            pageSizeName: "testReportTestPageSize"
        }
    }

    if (reportType === "student") {
        reportProps = {
            currentPage: props.currentPageStudent,
            pageSize: props.pageSizeStudent,
            pageCount: props.pageCountStudent,
            totalItems: props.totalItemsStudent,
            reportList: props.studentReportList,
            changePageSizeReport: props.changePageSizeTestReportStudent,
            changeCurrentPageReport: props.changeCurrentPageTestReportStudent,
            getAllReport: props.getAllReportStudent,
            pageSizeName: "testReportStudentPageSize"
        }
    }

    reportProps.reportType = reportType

    return <ReportList {...reportProps} report />
}

const mapStateToProps = state => ({
    testReportList: state.testReport.testReportList,
    studentReportList: state.testReport.studentReportList,
    totalItemsTest: state.testReport.totalItemsTest,
    totalItemsStudent: state.testReport.totalItemsStudent,
    currentPageTest: state.testReport.currentPageTest,
    currentPageStudent: state.testReport.currentPageStudent,
    pageCountTest: state.testReport.pageCountTest,
    pageCountStudent: state.testReport.pageCountStudent,
    pageSizeTest: state.testReport.pageSizeTest,
    pageSizeStudent: state.testReport.pageSizeStudent,
})

const mapDispatchToProps = {
    getAllReportTest,
    getAllReportStudent,
    changePageSizeTestReportStudent,
    changePageSizeTestReportTest,
    changeCurrentPageTestReportStudent,
    changeCurrentPageTestReportTest
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportListContainer)