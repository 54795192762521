import React, { useState, useEffect } from "react";
import { Row, Col, Table, Badge, Input } from "reactstrap";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import SearchBox from "../../components/SearchBox";
import CreateBtn from "../../components/Button/CreateBtn";
import LinkEditBtn from "../../components/Button/LinkEditBtn";
import RenderCategory from "../../components/RenderCategory";
import CustomPagination from "../../components/CustomPagination";
import useFetch from "../../hooks/useFetch";
import { createTask, getTasks, deleteTask, approveTask, updateTask } from "../../util/api";
import TestingDialog from "./TestingDialog";
import CreateTaskDialog from "./CreateTaskDialog";
import Spinner from "../../components/Spinner";
import useAlert from "../../hooks/useAlert";
import useMutation from "../../hooks/useMutation";
import useConfirm from "../../hooks/useConfirm";
import CreatorSelector from "../../components/CreatorSelector";
import { cbtURL } from "../MyTask/constants";
// import Example from "../../components/DataTable/DataTable.example";

const SUBJECTS = [
    {
        value: 57,
        label: "Reading"
    },
    {
        value: 58,
        label: "Mathematical Reasoning"
    },
    {
        value: 572,
        label: "Writing"
    },
    {
        value: 586,
        label: "Thinking Skills"
    }
];

const CreateQuestionAssignment = () => {
    const { t } = useTranslation("common");
    const { showConfirm } = useConfirm();
    const { showSuccess, showError } = useAlert();
    const [taskDetail, setTaskDetail] = useState(null);
    const [isViewDetail, setIsViewDetail] = useState(false);

    const [searchString, setSearchString] = useState("");
    const [selectedQsCreator, setSelectedQsCreator] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const [urlCBT, setUrlCBT] = useState("");
    const [isOpenTestingDialog, setIsOpenTestingDialog] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const {
        data: tasks,
        reload: refetchTasks,
        isLoading: isFetching
    } = useFetch(() => getTasks(searchString, ""), []);
    const { mutate: handleDeleteTask, isLoading: isDeleting } = useMutation({
        mutationFn: deleteTask,
        onSuccess: () => {
            refetchTasks();
            showSuccess("Delete task successfully");
        },
        onError: err => {
            showError("Delete failed: ", err.response.data.Message);
        }
    });
    const { mutate: handleCreateTask, isLoading: isCreating } = useMutation({
        mutationFn: createTask,
        onSuccess: () => {
            setIsOpen(false);
            refetchTasks();
            showSuccess("Create task successfully");
        },
        onError: err => {
            showError("Create failed: ", err.response.data.Message);
        }
    });

    const { mutate: handleUpdateTask, isLoading: isUpdating } = useMutation({
        mutationFn: updateTask,
        onSuccess: () => {
            setIsOpen(false);
            refetchTasks();
            showSuccess("Successfully");
        },
        onError: err => {
            showError("Failed: ", err.response.data.Message);
        }
    });
    const { mutate: handleApproveTask, isLoading: isApproving } = useMutation({
        mutationFn: approveTask,
        onSuccess: () => {
            refetchTasks();
            showSuccess("Successfully");
        },
        onError: err => {
            showError("Failed: ", err.response.data.Message);
        }
    });

    useEffect(() => {
        document.title = t("Create Question Assignment");
    }, []);

    const onConfirm = payload => {
        if (isViewDetail) {
            handleUpdateTask(payload.id, payload)
        } else {
            handleCreateTask(payload);
        }
    };

    const handleDelete = taskId => {
        showConfirm({
            onConfirm: () => {
                handleDeleteTask(taskId);
            },
            onReject: () => {},
            message: <h6 className="m-0">Are you sure you want to delete?</h6>
        });
    };

    const handleApprove = taskId => {
        showConfirm({
            message: <h6 className="m-0">Approve this task?</h6>,
            onConfirm: () => {
                handleApproveTask(taskId);
            }
        });
    };

    const viewDetailTask = (data) => {
        setIsOpen(true);
        setIsViewDetail(true);
        setTaskDetail(data);
    };

    return (
        <div>
            <Spinner
                isLoading={
                    isDeleting || isFetching || isCreating || isApproving
                }
            />

            {/* <Example /> */}

            {isOpen && ( // want to refresh all state of CreateTaskDialog Component when enabled, this may degrade performance
                <CreateTaskDialog
                    taskDetail={taskDetail}
                    isViewDetail={isViewDetail}
                    toggle={() => setIsOpen(!isOpen)}
                    onConfirm={onConfirm}
                />
            )}

            <TestingDialog
                isOpen={isOpenTestingDialog}
                toggle={() => {
                    setIsOpenTestingDialog(false);
                }}
                iframeSrc={urlCBT}
            />

            <Row className="mb-2">
                <Col xs={12} className="d-flex">
                    <CreateBtn
                        className='px-3 mr-3'
                        title={t("Create task")}
                        // style={{ width: "100%", height: "32px" }}
                        onClick={() => {
                            setIsOpen(true);
                            setIsViewDetail(false);
                        }}
                    />
               
                    <Input
                        style={{
                            maxWidth: 300
                        }}
                        className="filter-input mr-3"
                        value={searchString}
                        onChange={e => setSearchString(e.target.value)}
                        onKeyDown={e => {
                            if (e.key === "Enter") {
                                refetchTasks();
                            }
                        }}
                    />
              
                    <div 
                        style={{
                            width: 300
                        }}
                    >
                        <CreatorSelector
                            isClearable
                            value={selectedQsCreator}
                            placeholder="Select question creator"
                            onChange={e => {
                                setSelectedQsCreator(e);
                            }}
                        />
                    </div>
                </Col>
                <Col xs={12}>
                    <Table
                        hover
                        bordered
                        striped
                        responsive
                        className="grid-table mb-2 mt-2"
                    >
                        <thead>
                            <tr>
                                <th style={{ width: "18%" }}>Name</th>
                                <th style={{ width: "10%" }}>Subject</th>
                                <th style={{ width: "12%" }}>
                                    Question creator
                                </th>
                                <th style={{ width: "10%" }}>
                                    Question difficulty level
                                </th>
                                <th style={{ width: "10%" }}>
                                    Max question to create
                                </th>
                                <th style={{ width: "5%" }}>Show test</th>
                                <th style={{ width: "20%" }}>Categories</th>
                                <th
                                    style={{ width: "5%" }}
                                    className="text-center"
                                >
                                    Status
                                </th>
                                <th
                                    style={{ width: "5%" }}
                                    className="text-center"
                                >
                                    {t("Score")}
                                </th>
                                <th
                                    style={{ width: "5%" }}
                                    className="text-center"
                                >
                                    {t("Action")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {(tasks || [])
                                .filter(t => {
                                    if (selectedQsCreator) {
                                        return t.email === selectedQsCreator;
                                    }

                                    return true;
                                })
                                .map((e, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {e.name}{" "}
                                                {e.isDeleted && (
                                                    <Badge
                                                        href="#"
                                                        color="danger"
                                                    >
                                                        (Deleted)
                                                    </Badge>
                                                )}
                                            </td>
                                            <td>
                                                <span>{e.subjectName}</span>
                                            </td>
                                            <td>
                                                <span>{e.email}</span>
                                            </td>
                                            <td>
                                                <span>
                                                    {+e.questionDifficultyLevel ===
                                                    0
                                                        ? "Allow creator to select"
                                                        : e.questionDifficultyLevel}
                                                </span>
                                            </td>
                                            <td className="text-center">
                                                <span>{e.maxQuestion}</span>
                                            </td>
                                            <td className="text-center">
                                                <span>
                                                    {e.isTestRequired && (
                                                        <i className="fa fa-check" />
                                                    )}
                                                </span>
                                            </td>
                                            <td>
                                                {e.categoryIds
                                                    .split(",")
                                                    .map(id => {
                                                        return (
                                                            <RenderCategory
                                                                categoryId={id}
                                                            />
                                                        );
                                                    })}
                                            </td>
                                            <td className="text-center pl-3">
                                                {e.isPublished && <span style={{ color: "#0d6efd" }}>Published</span>}
                                                {(!e.isPublished && e.isStarted) && <span>Inprogress</span>}
                                                {(!e.isPublished && !e.isStarted) && <span>Not started</span>}
                                            </td>
                                            <td className="text-center pl-3">
                                                <LinkEditBtn
                                                    noIcon
                                                    onClick={() => {
                                                        setUrlCBT(
                                                            `${cbtURL}/player/${e.testSessionId}/1?mode=practice&cmd=random`
                                                        );
                                                        setIsOpenTestingDialog(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    {e.score
                                                        ? `${e.score}/${e.questionCount}`
                                                        : ""}
                                                </LinkEditBtn>
                                            </td>
                                            <td className="text-center">
                                                <i
                                                    onClick={() =>
                                                        handleDelete(e.id)
                                                    }
                                                    className="fa fa-trash mr-2"
                                                    aria-hidden="true"
                                                    style={{
                                                        cursor: "pointer"
                                                    }}
                                                />

                                                <i
                                                    onClick={() => {
                                                        viewDetailTask(e);
                                                        // handleDelete(e.id)
                                                    }}
                                                    className="fa fa-pencil mr-2"
                                                    aria-hidden="true"
                                                    style={{
                                                        cursor: "pointer"
                                                    }}
                                                />

                                                {e.score && !e.isApproved && (
                                                    <i
                                                        onClick={() =>
                                                            handleApprove(e.id)
                                                        }
                                                        className="fa fa-check"
                                                        aria-hidden="true"
                                                        style={{
                                                            cursor: "pointer"
                                                        }}
                                                    />
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })
                                .reverse()
                                .slice(
                                    (currentPage - 1) * pageSize,
                                    currentPage * pageSize
                                )}
                        </tbody>
                    </Table>

                    <CustomPagination
                        pageCount={
                            tasks ? Math.ceil(tasks.length / pageSize) : 0
                        }
                        pageSize={pageSize}
                        currentPage={currentPage}
                        changePage={e => {
                            setCurrentPage(e);
                        }}
                        changePageSize={e => {
                            setPageSize(e);
                        }}
                        onChangePageSize={() => {}}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default CreateQuestionAssignment;
