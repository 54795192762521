import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import "../Question/view/CommentableQuestionPreview.scss";
import {
    Nav,
    NavItem,
    NavLink,
    Container,
    Row,
    Col,
    FormGroup,
    Input,
    Label
} from "reactstrap";
import { getCheckListComment, getHistoryQuestion } from "../../util/api";
import useFetch from "../../hooks/useFetch";
import RenderByRole from "../../components/RenderByRole";

const statusCons = {
    1: "Commented and Approved",
    2: "Approved"
};

const ApprovalHistory = () => {
    const { questionId } = useParams();
    const [versions, setversions] = useState([]);
    const [currentHighLightId, setCurrentHighLightId] = useState(null);
    const { data: rawChecklist, isLoading: isFetchingCheckList} = useFetch(getCheckListComment, [], true)

    const [activeTab, setActiveTab] = useState(0);
    const [isShowComment, setIsShowComment] = useState(false);

    useEffect(() => {
        (async () => {
            const res = await getHistoryQuestion(questionId);
            const { proofreaderCBTQuestions } = res.data;

            const newVersions = []
            newVersions.push({
                proofreaders: [],
                html: `
                    ${res.data.article ? res.data.article.content : ""}
                    ${res.data.instruction || ''}
                    ${res.data.questionText || ''}
                    ${JSON.parse(res.data.answer || "[]")
                        .map(ans => {
                            return ans.text;
                        })
                        .join("")}
                    ${res.data.solution || ''}
                `,
                comments: [],
                author: res.data.authorName,
                htmlWithMark: '',
                status: '',
            })

            for (let i = 0; i <= proofreaderCBTQuestions.length - 1; i++) {
                const lastIndexVersion = newVersions.length - 1
                if (proofreaderCBTQuestions[i].rejectionReason) {
                    newVersions[lastIndexVersion].proofreaders.push({
                        name: proofreaderCBTQuestions[i].fullName,
                        isRejected: true,
                        status: `Rejected: ${proofreaderCBTQuestions[i].rejectionReason}`
                    })

                    continue
                }

                if (proofreaderCBTQuestions[i].status === 0) {
                    newVersions[lastIndexVersion].proofreaders.push({
                        name: proofreaderCBTQuestions[i].fullName,
                        status: 'Not approved yet'
                    })
                }

                if (proofreaderCBTQuestions[i].status === 2) {
                    newVersions[lastIndexVersion].proofreaders.push({
                        name: proofreaderCBTQuestions[i].fullName,
                        status: 'Approved'
                    })
                }

                if (proofreaderCBTQuestions[i].status === 1) {
                    newVersions[lastIndexVersion].proofreaders.push({
                        name: proofreaderCBTQuestions[i].fullName,
                        status: 'Commented and approved new version'
                    })
                    newVersions[lastIndexVersion].comments = proofreaderCBTQuestions[i].comments
                    const qsHtml = proofreaderCBTQuestions[i].questionHtml &&
                                        JSON.parse(proofreaderCBTQuestions[i].questionHtml.replaceAll(
                                            "rgb(255, 255, 23)",
                                            "rgb(180, 215, 255)"
                                        ))
                    newVersions[lastIndexVersion].htmlWithMark = `
                        ${qsHtml.question_text}
                        ${qsHtml.answer}
                        ${qsHtml.solution}
                    `
                    
                    newVersions.push({
                        proofreaders: [],
                        html: `
                            ${proofreaderCBTQuestions[i].article ? proofreaderCBTQuestions[i].article.content : ""}
                            ${proofreaderCBTQuestions[i].instruction || ''}
                            ${proofreaderCBTQuestions[i].questionText || ''}
                            ${JSON.parse(proofreaderCBTQuestions[i].answer || "[]")
                                .map(ans => {
                                    return ans.text;
                                })
                                .join("")}
                            ${proofreaderCBTQuestions[i].solution || ''}
                        `,
                        comments: [],
                        author: proofreaderCBTQuestions[i].fullName,
                        htmlWithMark: '',
                        status: '',
                        isRejected: false
                    })     
                }
            }

            newVersions[newVersions.length - 1].isLastVersion = true

            setversions(newVersions)
        })();
    }, []);

    useEffect(() => {
        addEvent();
    }, [activeTab, isShowComment]);

    useEffect(() => {
        const crVersion = versions.find((v, index) => index === activeTab);

        if (crVersion) {
            if (crVersion.comments.length > 0) {
                setIsShowComment(true);
            }
        }
    }, [activeTab]);

    const addEvent = () => {
        const spans = document.querySelectorAll("[type=highlight]");

        spans.forEach(el => {
            el.addEventListener("click", () => {
                const highlightId = el.getAttribute("highlightId");

                if (highlightId === currentHighLightId) return;

                setCurrentHighLightId(highlightId);

                const hightlights = document.querySelectorAll(
                    `[highlightId='${highlightId}']`
                );

                for (
                    let hIndex = 0;
                    hIndex <= hightlights.length - 1;
                    hIndex++
                ) {
                    hightlights[hIndex].style.backgroundColor =
                        "rgb(255, 65, 34)";
                }

                const questionAreaNode = document.querySelector(
                    ".can-highlight"
                );

                if (!questionAreaNode) return;

                questionAreaNode.innerHTML = questionAreaNode.innerHTML
                    .replaceAll("rgb(255, 255, 23)", "rgb(180, 215, 255)")
                    .replaceAll("rgb(255, 65, 34)", "rgb(255, 255, 23)");

                addEvent();
                // setQuestionHtml(
                //     questionAreaNode.innerHTML
                //         .replaceAll("rgb(255, 255, 23)", "rgb(180, 215, 255)")
                //         .replaceAll("rgb(255, 65, 34)", "rgb(255, 255, 23)")
                // );
            });
        });
    };

    return (
        <Container fluid className="mt-2">
            <Row className="mb-2">
                <h1>Question Versions History</h1>
            </Row>

            <Row>
                <Col xs={12} className="px-0">
                    <Nav tabs>
                        {versions.map((_, index) => {
                            return (
                                <NavItem key={1}>
                                    <NavLink
                                        className={classNames({
                                            active: index === activeTab
                                        })}
                                        onClick={() => {
                                            setIsShowComment(false);
                                            setActiveTab(index);
                                        }}
                                    >
                                        Version {index + 1}
                                    </NavLink>
                                </NavItem>
                            );
                        })}
                    </Nav>
                </Col>

                <Col xs={12}>
                    {versions.filter((v, index) => index === activeTab)
                        .map(({proofreaders, author, comments, html, htmlWithMark, isLastVersion}) => {
                            return <Row className="border border-top-0 py-2">
                            <Col xs={8}>
                                {proofreaders.map(p => <>
                                    <RenderByRole roles={['Admin']}>
                                        <h6 className="my-1">
                                            Proofreader: {p.name}
                                        </h6>
                                    </RenderByRole>
                                    <h6
                                        style={{
                                            color: p.isRejected
                                                ? "rgb(220, 53, 69)"
                                                : "rgb(13, 110, 253)"
                                        }}
                                        className="my-1 mb-3"
                                    >
                                        {p.status}
                                    </h6>
                                </>)}
                                <RenderByRole roles={['Admin']}>
                                    <h6 className="my-1">
                                        Author: {author}
                                    </h6>
                                </RenderByRole>
                            </Col>

                            {!isLastVersion && comments.length > 0 && (
                                <Col
                                    xs={4}
                                    className="d-flex justify-content-end"
                                >
                                    <Input
                                        checked={isShowComment}
                                        onChange={e => {
                                            setIsShowComment(
                                                e.target.checked
                                            );
                                        }}
                                        id="show-comment"
                                        type="checkbox"
                                    />
                                    <Label
                                        style={{
                                            fontSize: 16
                                        }}
                                        className="mr-3"
                                        id="show-comment"
                                    >
                                        Show comments
                                    </Label>
                                </Col>
                            )}

                            {comments.length === 0 && (
                                <Col
                                    xs={4}
                                    className="d-flex justify-content-end"
                                >
                                    <h5>No comments</h5>
                                </Col>
                            )}

                            <Col xs={isShowComment ? 8 : 12}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: isShowComment
                                                ? htmlWithMark
                                                : html
                                        }}
                                        className="border rounded can-highlight p-2 my-2"
                                    ></div>
                                
                            </Col>

                            {isShowComment && (
                                <Col xs={4}>
                                    <div className="border rounded p-2 mt-2">
                                        <h5 className="m-0">
                                            Comments
                                        </h5>
                                        <hr />
                                        <ul
                                            className="comment-list p-0"
                                            style={{
                                                listStyle: "none"
                                            }}
                                        >
                                            {comments
                                                .filter((comment) => {
                                                    return comment.hightlightTextId === currentHighLightId
                                                })
                                                .map(
                                                (
                                                    comment,
                                                    index
                                                ) => {
                                                    const checkList = rawChecklist ? rawChecklist.find(e => e.id === comment.checkListId) : null
                                                    const checkListText = checkList ? checkList.title : ''

                                                    return (
                                                        <li
                                                            onClick={() => {
                                                                // handleClickComment(
                                                                //     comment.hightlightTextId
                                                                // );
                                                            }}
                                                            className={classNames(
                                                                "d-flex align-items-top",
                                                                {
                                                                    active:
                                                                        comment.hightlightTextId ===
                                                                        currentHighLightId
                                                                }
                                                            )}
                                                        >
                                                            <div style={{width: '20%'}}>
                                                                <span>{index + 1}</span>
                                                            </div>
                                                            <div style={{width: '80%'}}>
                                                                <div className="font-weight-bold">{checkListText}</div>
                                                                <div>{comment.comment}</div>
                                                            </div>
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </div>
                                </Col>
                            )}
                        </Row>
                        })
                    }
                </Col>
            </Row>
        </Container>
    );
};

export default ApprovalHistory;
