import { connect } from "react-redux";

import DefaultLayout from ".";
import setAlert from "../../../components/Alert/action";
import { logout } from "../../Login/redux/actions";
import { setTableZoneWidth, getUserInfo } from "../../Common/action";

const mapStateToProps = ({ common: { userInfo } }) => ({ userInfo })

const mapDispatchToProps =
{
  logout,
  setAlert,
  setTableZoneWidth,
  getUserInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DefaultLayout);
