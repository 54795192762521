import React from "react";
import { useSelector } from "react-redux";

const Spinner = ({ isLoading: isLoadingProp }) => {
    const { isLoading } = useSelector(s => s.loading);

    return isLoadingProp || isLoading ? (
        <div className="fixed-loader-container">
            <div className="main-loader" />
        </div>
    ) : null;
};

export default Spinner;
