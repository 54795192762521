import React from "react";
import { useSelector } from "react-redux";

const RenderByRole = ({ roles, children }) => {
    const currentUser = useSelector(state => {
        return state.common.userInfo;
    });
    const isRender = roles.some(role => {
        return currentUser.roles.includes(role);
    });

    return isRender ? children : <></>;
};

export default RenderByRole;
