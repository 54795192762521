import { Formik } from "formik"
import * as yup from "yup"
import { withTranslation } from "react-i18next"
import React, { useState, useEffect } from "react"
import { Row, Col, Input, Label, Button, FormGroup, Card, CardBody } from "reactstrap"
import { connect } from "react-redux"
import SubjectSelector from "../../../components/Selector/SubjectSelector"
import { create, get, update } from "../redux/action"
import ErrorHandler from "../../../components/ErrorHandler"
import LabelRequired from "../../../components/LabelRequired"
import AssessmentQuestionSelector from "./AssessmentQuestionSelector"
import { getTextFromHTML } from "../../../util/helper"

const PreviewAssessment = props => {
    const { match: { params: { id } }, t, history, get: getAssessment } = props
    const [assessment, setAssessment] = useState({})
    useEffect(() => {
        if (!id) return 'id is not defined';
        getAssessment({
            id, cb: data => {
                setAssessment(data);
            }
        })
    }, [])

    return <div className="p-2">
        <h5>Preview assessment: {assessment.name}</h5>
        {/* <Row className="mb-2">
            <Col md={12}>
                <Card className="mb-1">
                    <CardBody className="p-2" >
                        Information here
                {JSON.stringify(assessment.questions)}
                    </CardBody>
                </Card>

            </Col>
        </Row> */}
        <Row className="mb-2">
            <Col md={4}>
                {assessment.questions && assessment.questions.map((q, index) =>
                    <Card className="mb-1">
                        <CardBody className="p-2" >
                            {index + 1}. {getTextFromHTML(q.question.text)}
                        </CardBody>
                    </Card>
                )}
            </Col>
            <Col md={4}>
            </Col>
            <Col md={4}>
            </Col>
        </Row>
    </div>
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = {
    create,
    get,
    update,
}

const PreviewAssessmentHOC = withTranslation("common")(PreviewAssessment)

export default connect(mapStateToProps, mapDispatchToProps)(PreviewAssessmentHOC)

