import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { Table, Row, Col, Alert } from "reactstrap"
import moment from "moment"
import _ from "lodash"

import SearchBox from "../../../components/SearchBox"
import CreateBtn from "../../../components/Button/CreateBtn"
import DeleteBtn from "../../../components/Button/DeleteBtn"
import LinkEditBtn from "../../../components/Button/LinkEditBtn"
import EditBtn from "../../../components/Button/EditBtn"
import CustomPagination from "../../../components/CustomPagination"

import setModal from "../../../components/Modal/action"

import useModal from "../../../components/Modal/useModal"

import {
    getAllTestSuitePayments,
    changeCurrentPageTestSuitePayment,
    changePageSizeTestSuitePayment
} from "../redux/action"

const TestSuitePayment = (props) => {
    const {
        t,
        testSuitePayments,
        currentPage,
        pageSize,
        pageCount,
        totalItems
    } = props

    const sortBy = "StudentName"
    const [sortOrder, setSortOrder] = useState("ASC")
    const [loaded, setLoaded] = useState(false)

    const [searchString, setSearchString] = useState()

    const getAll = async (page) => {
        const { currentPage, pageSize } = props
        await props.getAllTestSuitePayments({
            currentPage: page || currentPage,
            pageSize,
            searchString,
            sortBy,
            sortOrder
        })

        if (page) {
            await props.changeCurrentPageTestSuitePayment(page)
        }

        if (!loaded) {
            setLoaded(true)
        }
    }

    useEffect(() => {
        document.title = t("Payment")
        getAll(1)
    }, [])

    useEffect(() => {
        if (loaded) {
            getAll(1)
        }
    }, [sortOrder])

    useEffect(() => {
        if (loaded) {
            getAll()
        }
    }, [currentPage])

    const onSort = () => {
        if (sortOrder === "ASC") {
            setSortOrder("DESC")
        } else {
            setSortOrder("ASC")
        }
    }

    return (
        <div className={"pl-2"}>
            <Row className={`mb-2 my-2`}>
                <Col md={12} className={"py-2"}>
                    <h5>{t("Payments").toUpperCase()}</h5>
                </Col>
                <Col md={5} className="d-flex">
                    <SearchBox
                        placeholder="Search"
                        className="mr-0"
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                getAll(1)
                            }
                        }}
                    />
                </Col>
            </Row>
            {totalItems ? (
                <Row>
                    <Col md={8} className="text-right">
                        <b>
                            {t("total_payment")}: {totalItems}
                        </b>
                    </Col>
                </Row>
            ) : null}
            <Row className="mb-2">
                {testSuitePayments && testSuitePayments.length ? (
                    <Col md={8}>
                        <Table
                            hover
                            bordered
                            striped
                            responsive
                            className="grid-table mb-2"
                        >
                            <thead>
                                <tr>
                                    <th>{t("student")}</th>
                                    <th>{t("test_suite")}</th>
                                    <th>{t("amount")}</th>
                                    <th>{t("paid_date")}</th>
                                    <th>{t("pricing_plan")}</th>
                                    <th className="text-center">Approved</th>
                                </tr>
                            </thead>
                            <tbody>
                                {testSuitePayments.map((payment, index) => (
                                    <tr key={index}>
                                        <td>
                                            <LinkEditBtn
                                                label={_.get(payment, "studentName", 0)}
                                                onClick={() => {
                                                    window.open(`/user/edit-user/${_.get(payment, "studentIdentityId", 0)}`)
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <LinkEditBtn
                                                label={_.get(payment, "suiteName", 0)}
                                                onClick={() => {
                                                    window.open(`/edit-test-suite/${_.get(payment, "testSuiteId", 0)}`)
                                                }}
                                            />
                                        </td>
                                        <td>{payment.amount}</td>
                                        <td>
                                            {moment(payment.createdAt).format(
                                                "L"
                                            )}
                                        </td>
                                        <td>{payment.pricingPlan.name}</td>
                                        <td className="text-center">{!!payment.isApproved ? <i className="fa fa-check text-success" /> : null}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                ) : (
                    <Col md={8}>
                        <Alert color="primary" className="mb-0">
                            {t("no_payments_to_display")}
                        </Alert>
                    </Col>
                )}
            </Row>

            <Row>
                <Col md={8} className="text-right">
                    <CustomPagination
                        pageCount={pageCount}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        pageSizeName="paymentPageSize"
                        changePage={(page) => {
                            props.changeCurrentPageTestSuitePayment(page)
                        }}
                        changePageSize={props.changePageSizeTestSuitePayment}
                        onChangePageSize={() => getAll(1)}
                    />
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = (state) => ({
    testSuitePayments: state.testSuitePayment.testSuitePayments,
    currentPage: state.testSuitePayment.currentPage,
    pageCount: state.testSuitePayment.pageCount,
    pageSize: state.testSuitePayment.pageSize,
    totalItems: state.testSuitePayment.totalItems
})

const mapDispatchToProps = {
    setModal,
    getAllTestSuitePayments,
    changeCurrentPageTestSuitePayment,
    changePageSizeTestSuitePayment
}

const TestSuitePaymentHOC = withTranslation("common")(TestSuitePayment)

export default connect(mapStateToProps, mapDispatchToProps)(TestSuitePaymentHOC)
