import { Formik } from "formik"
import * as yup from "yup"
import { withTranslation } from "react-i18next"
import React, { useState, useEffect } from "react"
import { Row, Col, Input, Label, Button, FormGroup } from "reactstrap"
import { connect } from "react-redux"
import {CKEditor} from "ckeditor4-react"
import DatePicker from "react-datepicker"
import moment from "moment"

import SubjectSelector from "../../../components/Selector/SubjectSelector"
import GradeSelector from "../../../components/Selector/GradeSelector"
import { create, get, update } from "../redux/action"
import ErrorHandler from "../../../components/ErrorHandler"
import LabelRequired from "../../../components/LabelRequired"
import AssessmentQuestionSelector from "./AssessmentQuestionSelector"
import QuestionCategorySelector from "../../../components/Selector/QuestionCategorySelector"

const EditAssessment = (props) => {
  const {
    match: {
      params: { id },
    },
    t,
    history,
    get: getAssessment,
    allQuestionCategory,
    userInfo,
  } = props

  const [exit, setExit] = useState(false)

  const initValues = {
    name: "",
    description: "",
    durationInMinutes: 0,
    submitMessage: "",
    numberOfQuestions: 0,
    instruction: null,
    questionIds: [],
    subjectId: 0,
    randomizedQuestionCategoryIds: [],
    type: "Randomized",
    isInHouseTest: false,
    grade: 0,
    term: null,
    week: null,
    year: null,
    beginDate: null,
    endDate: null,
  }

  const schema = yup.object({
    name: yup.string().required(t("validation.name_required")),
    description: yup.string().required(t("validation.description_required")),
    subjectId: yup
      .number()
      .test(
        "testSubject",
        t("validation.subject_required"),
        (subjectId) => subjectId
      ),
    durationInMinutes: yup
      .number()
      .min(0)
      .required(t("validation.duration_required")),
    submitMessage: yup
      .string()
      .required(t("validation.submitMessage_required")),
    // numberOfQuestions: yup.number().min(1).required(t("assessment.numberOfQuestions_required")),
    numberOfQuestions: yup
      .number()
      .test(
        "testNumberOfQuestion",
        t("validation.number_of_questions_invalid"),
        (number) => number >= 1
      ),
    instruction: yup
      .string()
      .required(t("validation.instruction_required"))
      .nullable(),
    randomizedQuestionCategoryIds: yup.array().when("type", {
      is: "Randomized",
      then: yup.array().required(t("question_category_required")),
    }),
    type: yup.string().oneOf(["Randomized", "SelectQuestions"]),
    isInHouseTest: yup.boolean(),
    grade: yup
      .number()
      .test(
        "testGrade",
        t("validation.grade_required"),
        (number) => number >= 1 && number <= 12
      ),
    term: yup
      .number()
      .nullable()
      .min(0, "Term must be a positive number"),
    week: yup
      .number()
      .nullable()
      .min(0, "Week must be a positive number"),
    year: yup
      .number()
      .nullable()
      .min(0, "Year must be a positive number"),
  })

  return (
    <Formik
      initialValues={initValues}
      enableReinitialize
      validationSchema={schema}
      onSubmit={async (values, actions) => {
        let response = null
        if (!id) {
          response = await props.create(values)
        } else {
          response = await props.update(values)
        }
        if (response) {
          if (exit) {
            props.history.push({
              pathname: "/question-bank/assessments",
              searchString: response.name,
            })
          } else {
            actions.resetForm()
          }
        }
      }}
    >
      {(formProps) => {
        useEffect(() => {
          if (!id) return
          getAssessment({
            id,
            cb: (data) => {
              formProps.setFieldValue("id", data.id)
              formProps.setFieldValue("name", data.name)
              formProps.setFieldValue("description", data.description)
              formProps.setFieldValue(
                "durationInMinutes",
                data.durationInMinutes
              )
              formProps.setFieldValue(
                "randomizedQuestionCategoryIds",
                data.randomizedQuestionCategoryIds
              )
              formProps.setFieldValue("submitMessage", data.submitMessage)
              formProps.setFieldValue(
                "numberOfQuestions",
                data.numberOfQuestions
              )
              formProps.setFieldValue("instruction", data.instruction)
              formProps.setFieldValue(
                "questionIds",
                data.questions.map((q) => q.question.id) || []
              )
              formProps.setFieldValue("type", data.type)
              formProps.setFieldValue(
                "subjectId",
                data.subject && data.subject.id
              )
              formProps.setFieldValue(
                "isInHouseTest",
                data.isInHouseTest && data.isInHouseTest
              )
              formProps.setFieldValue("grade", data.grade)
              formProps.setFieldValue("beginDate", data.beginDate)
              formProps.setFieldValue("endDate", data.endDate)
              formProps.setFieldValue("week", data.week)
              formProps.setFieldValue("term", data.term)
              formProps.setFieldValue("year", data.year)
            },
          })
        }, [])

        const currentCategories =
          allQuestionCategory &&
          allQuestionCategory.filter((item) =>
            formProps.values.randomizedQuestionCategoryIds.includes(item.id)
          )
        return (
          <div className="p-2 pb-5">
            <h5>Edit assessment</h5>
            <Row className="mb-2">
              <Col md={4}>
                <FormGroup>
                  <LabelRequired text={t("assessment.name")} />
                  <Input
                    name="name"
                    value={formProps.values.name}
                    onChange={formProps.handleChange}
                    placeholder={t("assessment.name")}
                  />
                  {formProps.touched.name && formProps.errors.name ? (
                    <ErrorHandler text={formProps.errors.name} />
                  ) : null}
                </FormGroup>

                <FormGroup>
                  <LabelRequired text={t("assessment.durationInMinutes")} />
                  <Input
                    name="durationInMinutes"
                    type="number"
                    value={formProps.values.durationInMinutes}
                    onChange={formProps.handleChange}
                    placeholder={t("durationInMinutes")}
                  />
                  {formProps.touched.durationInMinutes &&
                    formProps.errors.durationInMinutes ? (
                    <ErrorHandler text={formProps.errors.durationInMinutes} />
                  ) : null}
                </FormGroup>

                <FormGroup>
                  <LabelRequired text={t("assessment.submitMessage")} />
                  <Input
                    name="submitMessage"
                    value={formProps.values.submitMessage}
                    onChange={formProps.handleChange}
                    placeholder={t("assessment.submitMessage")}
                  />
                  {formProps.touched.submitMessage &&
                    formProps.errors.submitMessage ? (
                    <ErrorHandler text={formProps.errors.submitMessage} />
                  ) : null}
                </FormGroup>

                <FormGroup>
                  <LabelRequired text={t("assessment.description")} />
                  <Input
                    style={{ height: 87 }}
                    type="textarea"
                    rows={5}
                    name="description"
                    value={formProps.values.description}
                    onChange={formProps.handleChange}
                    placeholder={t("assessment.description")}
                  />
                  {formProps.touched.description &&
                    formProps.errors.description ? (
                    <ErrorHandler text={formProps.errors.description} />
                  ) : null}
                </FormGroup>
              </Col>

              <Col md={4}>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <SubjectSelector
                        name="subjectId"
                        value={formProps.values.subjectId}
                        onChange={(e) =>
                          formProps.setFieldValue("subjectId", e.value)
                        }
                        placeholder={t("assessment.select_subject")}
                        label={t("assessment.subject")}
                      />
                      {formProps.touched.subjectId &&
                        formProps.errors.subjectId ? (
                        <ErrorHandler text={formProps.errors.subjectId} />
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <LabelRequired
                        text={t("assessment.number_of_questions")}
                      />
                      <Input
                        name="numberOfQuestions"
                        type="number"
                        value={formProps.values.numberOfQuestions}
                        onChange={formProps.handleChange}
                        placeholder={t("assessment.number_of_questions")}
                      />
                      {formProps.touched.numberOfQuestions &&
                        formProps.errors.numberOfQuestions ? (
                        <ErrorHandler
                          text={formProps.errors.numberOfQuestions}
                        />
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <GradeSelector
                        require
                        name="grade"
                        value={
                          formProps.values.grade && {
                            value: formProps.values.grade,
                            label: formProps.values.grade,
                          }
                        }
                        onChange={(e) =>
                          formProps.setFieldValue("grade", e.value)
                        }
                        invalid={
                          formProps.touched.grade && formProps.errors.grade
                        }
                        errorMessage={formProps.errors.grade}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup>
                  <LabelRequired text={t("assessment.instruction")} />
                  {(!id || formProps.values.instruction !== null) && (
                    <CKEditor
                      data={formProps.values.instruction}
                      onChange={(e) =>
                        formProps.setFieldValue(
                          "instruction",
                          e.editor.getData()
                        )
                      }
                      config={{
                        height: 71,
                        allowedContent: true,
                        toolbar: [
                          {
                            name: "basicstyles",
                            groups: ["basicstyles", "cleanup"],
                            items: ["Bold", "Italic"],
                          },
                          { name: "insert", items: ["Image"] },
                          { name: "others" },
                          ["Html5audio", "Html5video"],
                        ],
                        extraPlugins: "html5audio",
                        //  TODO change upload api
                        filebrowserImageUploadUrl: "/api/file/Upload?",
                        filebrowserUploadUrl: "/api/file/Upload?",
                      }}
                      onBeforeLoad={(CKEDITOR) => {
                        CKEDITOR.disableAutoInline = true
                        CKEDITOR.config.height = 100
                        CKEDITOR.enterMode = CKEDITOR.ENTER_BR
                      }}
                    />
                  )}
                  {formProps.touched.instruction &&
                    formProps.errors.instruction ? (
                    <ErrorHandler text={formProps.errors.instruction} />
                  ) : null}
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>Begin date</Label>
                  <DatePicker
                    isClearable={
                      !!formProps.values && !!formProps.values.beginDate
                    }
                    todayButton="Today"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    value={
                      !!formProps.values &&
                      !!formProps.values.beginDate &&
                      moment(formProps.values.beginDate).format("DD/MM/YYYY")
                    }
                    selected={
                      !!formProps.values &&
                      !!formProps.values.beginDate &&
                      new Date(formProps.values.beginDate)
                    }
                    onChange={(e) =>
                      e
                        ? formProps.setFieldValue(
                          "beginDate",
                          moment(e).format("YYYY-MM-DD")
                        )
                        : formProps.setFieldValue("beginDate", null)
                    }
                    placeholderText="Begin date"
                    customInput={
                      <Input type="text" style={{ width: "maxContent" }} />
                    }
                    locale="en-gb"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>End date</Label>
                  <DatePicker
                    isClearable={
                      !!formProps.values && !!formProps.values.endDate
                    }
                    todayButton="Today"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    value={
                      !!formProps.values &&
                      !!formProps.values.endDate &&
                      moment(formProps.values.endDate).format("DD/MM/YYYY")
                    }
                    selected={
                      !!formProps.values &&
                      !!formProps.values.endDate &&
                      new Date(formProps.values.endDate)
                    }
                    onChange={(e) =>
                      e
                        ? formProps.setFieldValue(
                          "endDate",
                          moment(e).format("YYYY-MM-DD")
                        )
                        : formProps.setFieldValue("endDate", null)
                    }
                    placeholderText="End date"
                    customInput={
                      <Input type="text" style={{ width: "maxContent" }} />
                    }
                    locale="en-gb"
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Year</Label>
                  <Input
                    name="year"
                    type="number"
                    value={formProps.values.year}
                    onChange={formProps.handleChange}
                    placeholder="Year"
                  />
                  {formProps.touched.year && formProps.errors.year ? (
                    <ErrorHandler text={formProps.errors.year} />
                  ) : null}
                </FormGroup>

                <FormGroup>
                  <Label>Week</Label>
                  <Input
                    name="week"
                    type="number"
                    value={formProps.values.week}
                    onChange={formProps.handleChange}
                    placeholder="Week"
                  />
                  {formProps.touched.week && formProps.errors.week ? (
                    <ErrorHandler text={formProps.errors.week} />
                  ) : null}
                </FormGroup>

                <FormGroup>
                  <Label>Term</Label>
                  <Input
                    clear
                    name="term"
                    type="number"
                    value={formProps.values.term}
                    onChange={formProps.handleChange}
                    placeholder="Term"
                  />
                  {formProps.touched.term && formProps.errors.term ? (
                    <ErrorHandler text={formProps.errors.term} />
                  ) : null}
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Row>
                    <Label>{t("assessment.quiz_type")}</Label>
                  </Row>
                  <Row>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          value="Randomized"
                          name="type"
                          checked={formProps.values.type === "Randomized"}
                          onClick={(e) => {
                            formProps.handleChange(e)
                          }}
                          disabled={id}
                        />{" "}
                        {t("assessment.randomized")}
                      </Label>
                    </FormGroup>
                  </Row>
                  <Row>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          value="SelectQuestions"
                          name="type"
                          checked={formProps.values.type === "SelectQuestions"}
                          onClick={(e) => {
                            formProps.handleChange(e)
                          }}
                          disabled={id}
                        />{" "}
                        {t("assessment.select_questions")}
                      </Label>
                    </FormGroup>
                    {formProps.touched.assessmentType &&
                      formProps.errors.assessmentType ? (
                      <ErrorHandler text={formProps.errors.assessmentType} />
                    ) : null}
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Label>{t("assessment.other_options")}</Label>
                  </Row>
                  <Row className="mb-1">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="isInHouseTest"
                          checked={formProps.values.isInHouseTest}
                          onClick={(e) => {
                            formProps.handleChange(e)
                          }}
                          disabled={id}
                        />{" "}
                        {t("assessment.isInHouseTest")}
                      </Label>
                    </FormGroup>
                    {formProps.touched.isInHouseTest &&
                      formProps.errors.isInHouseTest ? (
                      <ErrorHandler text={formProps.errors.isInHouseTest} />
                    ) : null}
                  </Row>
                  {
                    userInfo && formProps.values.isInHouseTest && !!id ? (
                      <Row>
                        <Label>{t("Example URL")}: </Label>
                        <a
                          href={`${userInfo.environmentVariables.examPlayerUrl}/player/intro/${id}/1`}
                        >{`${userInfo.environmentVariables.examPlayerUrl}/player/intro/${id}/1`}</a>
                      </Row>
                    ) : null
                  }
                  {
                    userInfo && !!id
                      ? (
                        <Row>
                          <Label>{t("Preview")}: </Label>
                          <a
                            href={`${userInfo.environmentVariables.examPlayerUrl}/previewAssessment/${id}`}
                          >{`${userInfo.environmentVariables.examPlayerUrl}/previewAssessment/${id}`}</a>
                        </Row>
                      )
                      : null
                  }
                </FormGroup>
              </Col>
            </Row>
            <Row className="mb-2">
              {formProps.values.type === "SelectQuestions" ? (
                <Col md={12}>
                  <h5>
                    <LabelRequired text="Select questions" />
                  </h5>
                  <Label>Question category</Label>
                  <AssessmentQuestionSelector
                    selectedQuestionIds={formProps.values.questionIds}
                    onChangeSelectedItems={(ids) =>
                      formProps.setFieldValue("questionIds", ids)
                    }
                  />
                </Col>
              ) : null}
              {formProps.values.type === "Randomized" ? (
                <Col md={4}>
                  <h5>
                    <LabelRequired text="Select questions" />
                  </h5>
                  <QuestionCategorySelector
                    value={
                      currentCategories && currentCategories.length > 0
                        ? currentCategories.map((i) => ({
                          label: i.name,
                          value: i.id,
                        }))
                        : null
                    }
                    onChange={(e) => {
                      formProps.setFieldValue(
                        "randomizedQuestionCategoryIds",
                        e.map((item) => item.value)
                      )
                    }}
                    isMulti
                  />
                  {formProps.touched.randomizedQuestionCategoryIds &&
                    formProps.errors.randomizedQuestionCategoryIds ? (
                    <ErrorHandler
                      text={formProps.errors.randomizedQuestionCategoryIds}
                    />
                  ) : null}
                </Col>
              ) : null}
            </Row>
            <Row className="py-2 floatingActions">
              <Col md={12}>
                <Button
                  color="success"
                  onClick={() => {
                    formProps.handleSubmit()
                    setExit(true)
                  }}
                  className="mr-2"
                >
                  {t("assessment.save_assessment")}
                </Button>
                <Button color="secondary" onClick={() => history.goBack()}>
                  {t("cancel")}
                </Button>
              </Col>
            </Row>
          </div>
        )
      }}
    </Formik>
  )
}

const mapStateToProps = (state) => ({
  allQuestionCategory: state.questionCategory.allQuestionCategory,
  userInfo: state.common.userInfo,
})

const mapDispatchToProps = {
  create,
  get,
  update,
}

const EditAssessmentHOC = withTranslation("common")(EditAssessment)

export default connect(mapStateToProps, mapDispatchToProps)(EditAssessmentHOC)
