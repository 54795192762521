import React, { Component } from 'react';

import { Creatable } from 'react-select';

const components = {
    DropdownIndicator: null,
};

// const createOption = label => ({
//     label,
//     value: label,
// });

export default props => {
    //   state = {
    //     inputValue: '',
    //     value: [],
    //   };
    const { inputValue, value, handleChange, handleInputChange, handleKeyDown, t } = props
    //   handleChange = (value: any, actionMeta: any) => {
    //     console.group('Value Changed');
    //     console.log(value);
    //     console.log(`action: ${actionMeta.action}`);
    //     console.groupEnd();
    //     this.setState({ value });
    //   };
    //   handleInputChange = (inputValue: string) => {
    //     this.setState({ inputValue });
    //   };
    //   handleKeyDown = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    //     const { inputValue, value } = this.state;
    //     if (!inputValue) return;
    //     switch (event.key) {
    //       case 'Enter':
    //       case 'Tab':
    //         console.group('Value Added');
    //         console.log(value);
    //         console.groupEnd();
    //         this.setState({
    //           inputValue: '',
    //           value: [...value, createOption(inputValue)],
    //         });
    //         event.preventDefault();
    //     }
    //   };
    return (
        <Creatable
            components={components}
            placeholder={t("type_something_create_option")}
            isClearable
            isMulti
            menuIsOpen={false}
            value={value}
            inputValue={inputValue}
            onChange={handleChange}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
        />
    );
}
