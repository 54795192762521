import React, { useEffect } from "react"
import { connect } from "react-redux"
import { FormGroup, Label } from "reactstrap"
import Select from "react-select"

import { getArticleAction } from "../../containers/Article/redux/action"
import { sortAlphabet } from "../../util/Common"

const ArticleSelector = props => {
    const { noLabel, label, required, allArticles, invalid } = props
    useEffect(() => {
        props.getArticleAction({})
    }, [])
    const options = allArticles && allArticles.map(item => ({ value: item.id, label: `${item.path ? item.path + "/" : ""}${item.name}` }))
    return <FormGroup>
        {!noLabel && <Label>{label || "Article"}{required && <span style={{ color: 'red' }}> *</span>}</Label>}
        <Select
            placeholder="Select article"
            options={sortAlphabet(options, 'name')}
            className={invalid ? 'invalid-field' : ''}
            {...props}
        />
    </FormGroup>
}

const mapStateToProps = state => ({
    allArticles: state.article.allArticles
})

const mapDispatchToProps = {
    getArticleAction
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleSelector)