import React, { PureComponent } from "react";
import { Input } from "reactstrap";
import { withTranslation } from 'react-i18next'

class SearchBox extends PureComponent {
  render() {
    return (
      <Input
        className="filter-input"
        placeholder={this.props.t('search')}
        {...this.props}
        style={{ marginRight: 15 }}
      />
    );
  }
}

export default withTranslation('common')(SearchBox);
