import _ from "lodash";
import history from "../../../../util/history";

const switchUser = async (type, studentList, id, cb) => {
    let index = -1;

    if (studentList) {
        index = _.findIndex(studentList, o => o.id === Number(id));
    }
    if (index < 0) return;
    if (studentList.length < 2) return;
    if (type === "next") {
        if (index >= studentList.length - 1) index = -1
        const nextId = _.get(studentList[index + 1], 'id')
        await history.replace(`/user/edit-user/${nextId}`);
    }
    if (type === "prev") {
        if (index === 0) index = studentList.length
        const prevId = _.get(studentList[index - 1], 'id')
        await history.replace(`/user/edit-user/${prevId}`);
    }
}

export { switchUser }