import { FormGroup, Label } from "reactstrap"
import Select from "react-select"
import { connect } from "react-redux"
import React, { useEffect } from "react"
import { getAll } from "../redux/action"
import LabelRequired from "../../../components/LabelRequired"

const AssessmentSelector = props => {
    const { label, value, allAssessments, placeholder, onChange, isMulti } = props

    useEffect(() => {
        props.getAll({})
    }, [])

    const options = allAssessments && allAssessments.map(item => ({ value: item.id, label: `${item.path ? `${item.path}/` : ""}${item.name}` }))
    const selectedOptions = isMulti
        ? options.filter(i => value.includes(i.value))
        : options.find(i => i.value === value)
    return <FormGroup>
        {props.label && <Label>{props.label}</Label>}
        <Select
            options={options}
            isDisabled={false}
            {...props}
            value={selectedOptions}
        />
    </FormGroup>
}

const mapStateToProps = state => ({
    allAssessments: state.assessment.fullAssessmentList,
})

const mapDispatchToProps = {
    getAll
}

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentSelector)