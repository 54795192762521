import PDF from "jspdf";
import moment from "moment";
import _ from "lodash";
import { semester, optionImport } from "./Constant";

export const save = (data, headerArr, headerText, fileName, t) => {
    const doc = new PDF('l');
    const body = [];

    const dataHeaders = []

    Object.keys(data[0]).map(key => {
        if (key !== 'id' && key !== 'userId') {
            dataHeaders.push(key)
        }
    })
    data.forEach(item => {
        const dataContent = dataHeaders.map(header => (item[header]))
        body.push(dataContent)
    })
    doc.autoTable({
        head: [
            headerArr
        ],
        body,
        margin: { top: 20 },
        rowPageBreak: 'avoid',
        didDrawPage: data => {
            doc.text(headerText, 14, 12)
        }
    });

    doc.setFontSize(10)

    const pageWidth = doc.internal.pageSize.width

    const pageCount = doc.internal.getNumberOfPages()
    for (let i = 0; i < pageCount; i++) {
        doc.setPage(i)
        doc.text(`${t("printed_date")}: ${moment().format("L")}     ${doc.internal.getCurrentPageInfo().pageNumber}/${pageCount}`, pageWidth - 62, 12)
    }

    doc.save(fileName);
};

export const formatToLocalTime = time => moment(`${time}Z`)


export const readCookie = (name) => {
    const key = `${name}=`;
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1, cookie.length);
        }
        if (cookie.indexOf(key) === 0) {
            return cookie.substring(key.length, cookie.length);
        }
    }
    return null;
}

export const saveTime = (key, value) => {
    localStorage.setItem(key, value)
}

export const handleDynamicError = errorArr => {
    try {
        const dynamicErrorRegex = /\|\|(\[\w+\]\:( )*[^\|]+)\|\|/g
        const result = errorArr.map(err => {
            if (err.value && err.value.match(dynamicErrorRegex) && !err.value.match(dynamicErrorRegex)) {
                return { errCode: err.value, errObj: {} }
            }

            const errCode = err.value.replace(dynamicErrorRegex, "").trim()
            const objArr = err.value.match(dynamicErrorRegex) || []
            const errObj = {}
            objArr.forEach(obj => {
                const objKey = obj.split(":")[0]
                const key = objKey.slice(3, objKey.length - 1).trim()

                const objValue = obj.split(":")[1]
                const value = objValue.slice(0, objValue.length - 2).trim()

                errObj[key] = value
            })

            return { errCode, errObj }
        })

        return result
    } catch (err) {
        return errorArr
    }
}

export const formatImportStudentDataForm1 = data => {
    const result = {}

    result["form_id"] = "1"
    result["9"] = data.email
    result["55.3"] = data.firstName
    result["55.6"] = data.lastName
    result["14.1"] = data.address
    result["14.5"] = data.zipCode
    result["14.3"] = data.city
    result["7"] = data.phoneHome
    result["10"] = data.dateOfBirth
    result["11"] = data.gender ? "Kille" : "Tjej"
    result["15.3"] = data.parentFirstName
    result["15.6"] = data.parentLastName
    result["16"] = data.phone1
    result["17"] = data.phone2
    result["18"] = data.phone3
    result["19"] = data.phone4
    result["20"] = data.parentEmail
    result["8"] = data.phoneMobile
    result["62"] = data.agree ? "Jag samtycker" : "Jag samtycker inte"
    result["56"] = data.under18 ? "Ja" : "Nej"
    result["38.1"] = data.lines && data.lines.map(id => optionImport.lines.find(item => item.id === id).swe).join(", ")
    result["39.1"] = data.courses && data.courses.map(id => optionImport.courses.find(item => item.id === id).swe).join(", ")
    result["40.1"] = data.others && data.others.map(id => optionImport.others.find(item => item.id === id).swe).join(", ")
    result["41.1"] = data.trainings && data.trainings.map(id => optionImport.trainings.find(item => item.id === id).swe).join(", ")
    result["43.1"] = data.levels && data.levels.map(id => optionImport.levels.find(item => item.id === id).swe).join(", ")
    result["44"] = data.previousExp
    result["45"] = data.otherInfo

    return result
}

export const formatImportStudentDataForm2 = data => {
    const result = {}

    result["form_id"] = "2"
    result["4"] = data.email
    result["5"] = data.phoneHome
    result["6"] = data.postSecondaryEducation
    result["7"] = data.secondaryEducation
    result["11"] = data.dateOfBirth
    result["13"] = data.singExp
    result["14"] = data.danceExp
    result["15"] = data.anythingElse
    result["16"] = data.theaterExp
    result["18"] = data.agree ? "Jag samtycker" : "Jag samtycker inte"
    result["20"] = data.briefPreviousExp
    result["2.3"] = data.firstName
    result["2.6"] = data.lastName
    result["3.1"] = data.address
    result["3.2"] = data.address2
    result["3.3"] = data.city
    result["3.5"] = data.zipCode
    result["3.6"] = data.country

    return result
}

export const generateRandomString = () => Math.random().toString(36).substring(2)

export const getBaseUrl = () => {
    const windowUrl = window.location.href
    const url = `${windowUrl.split("//")[0]}//${windowUrl.split("//")[1].split("/")[0]}`

    return url
}

export const renderSemesterName = semester => {
    switch (semester) {
        case 1:
            return localStorage.getItem("semester1")
        case 2:
            return localStorage.getItem("semester2")
        default:
            return ""
    }
}

export const getTextFromHTML = htmlString => {
    const p = document.createElement("p")
    p.innerHTML = htmlString
    const text = p.innerText
    p.remove()
    return text
}

export const guidGenerator = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const computeImageUrl = (imageUrl) => {
	let httpRegex = /^(http|https)/;
	const url = `${getBaseUrl()}/api`
	if (httpRegex.test(String(imageUrl).toLowerCase()) === true)
		return imageUrl
	return `${url}/file/download/${imageUrl}`
}