import React, { useState } from "react"
import { Label, Input, Form } from "reactstrap"
import { computeImageUrl } from "../util/helper"

const maxFileSize = 20097152

const UploadImage = props => {
    const { t, imageUrl, file, setFile, setModal, callback, maxWidth, maxHeight } = props

    const [imagePreviewUrl, setImagePreviewUrl] = useState("")

    const handleImageChange = e => {
        e.preventDefault()

        let reader = new FileReader()
        let newFile = e.target.files[0]

        if (newFile.size > maxFileSize) {
            setModal({
                type: "danger",
                message: t('file_cant_be_larger'),
            })
            return
        }

        reader.onloadend = () => {
            setFile(newFile)
            setImagePreviewUrl(reader.result)
        }

        if (newFile) {
            reader.readAsDataURL(newFile)
        }

        if (callback) callback()
    }

    const imagePreviewContainer = imagePreviewUrl || imageUrl
        ? <img
            style={{ maxWidth: maxWidth || "100%", maxHeight: maxHeight || "100%" }}
            src={imagePreviewUrl || computeImageUrl(imageUrl)}
            alt=""
        />
        : null

    return (
        <div className="mb-2">
            <Form className="mb-2">
                <Input
                    className="d-none"
                    type="file"
                    id="uploadFile"
                    onChange={handleImageChange}
                    accept="image/x-png,image/jpeg"
                />
                <Label
                    htmlFor="uploadFile"
                    role="button"
                    tabIndex={0}
                    style={{
                        padding: "4px",
                        marginBottom: 0,
                        border: "1px solid #20a8d8",
                        backgroundColor: "#20a8d8",
                        borderRadius: "2px",
                        color: "#fff",
                        fontWeight: 500,
                        fontSize: "0.9em",
                        cursor: "pointer"
                    }}
                    onMouseEnter={e => {
                        e.target.style.backgroundColor = "#1b8eb7";
                        e.target.style.border = "1px solid #1985ac";
                    }}

                    onMouseLeave={e => {
                        e.target.style.backgroundColor = "#20a8d8";
                        e.target.style.border = "1px solid #20a8d8";
                    }} >
                    <i className="fa fa-paperclip mr-1" aria-hidden="true"></i>
                    {imagePreviewUrl || imageUrl ? t("change_file") : t("choose_file")}
                </Label>
            </Form>
            {imagePreviewContainer}
        </div>
    )
}

export default UploadImage
