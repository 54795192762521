import React, { PureComponent } from 'react'
import { connect } from "react-redux";
import { Button, FormGroup, Label, Input } from 'reactstrap';
import { checkPassword, updateSecretNote } from "../redux/action";
import ViewModal from "../../../components/Modal/viewModal";

class secret extends PureComponent {
    state = {
        secretNote: "",
        password: "",
        passwordCorrect: false,
    }

    updateSecretNote = () => {
        const { id } = this.props;
        const { password, secretNote } = this.state;
        this.props.updateSecretNote({ password, secretNote }, id, result => {
            this.setState({ secretNote: result }, () => this.modal.show(false))
            this.props.afterUpdateSecret(secretNote)
        })
    }

    checkPassword = () => {
        const { id } = this.props;
        const { password } = this.state;
        this.props.checkPassword({ password }, id, secretNote => {
            this.setState({ secretNote, passwordCorrect: true })
        })
    }

    show = value => {
        this.modal.show(value);
    }

    render() {
        const { viewOnly, t } = this.props;
        const { passwordCorrect, password, secretNote } = this.state;
        const passed = passwordCorrect && password.length > 0;

        return (
            <ViewModal actionClose={() => {
                this.setState({ password: "", passwordCorrect: false })
            }}
                ref={ref => { this.modal = ref }}
                title={t("SECRET_INFO")}
                btn={() =>
                    <Button className="px-4 mr-2" color="primary" onClick={passed ? this.updateSecretNote : this.checkPassword} disabled={passed && viewOnly}>
                        {passed ? t("Update") : t("Next")}
                    </Button>
                }
            >

                <FormGroup>
                    <Label>{passed ? t("Secret_note") : t("Enter_your_password")}</Label>
                    {
                        passed
                            ?
                            <Input type="textarea" placeholder={t('note')} value={secretNote} rows="5"
                                onChange={event => {
                                    this.setState({
                                        secretNote: event.target.value,
                                    });
                                }}
                                readOnly={this.state.viewOnly}
                            />
                            :
                            <Input
                                type="password"
                                placeholder={t('Password')}
                                onChange={event => this.setState({ password: event.target.value })}
                            />
                    }

                </FormGroup>
            </ViewModal>
        )
    }
}

const mapDispatchToProps =
{
    checkPassword,
    updateSecretNote,
};

export default connect(
    null,
    mapDispatchToProps,
    null,
    {
        forwardRef: true
    }
)(secret);
