import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const TestingDialog = ({
    isOpen, // boolean
    toggle, // VoidFunction
    iframeSrc // string
}) => {
    return (
        <Modal
            style={{ height: "100vh", maxWidth: "100vw", marginTop: 0 }}
            isOpen={isOpen}
            toggle={toggle}
        >
            <ModalHeader toggle={toggle}></ModalHeader>

            <ModalBody
                className="p-0"
                // style={{ height: "100vh", width: "100vw" }}
            >
                {iframeSrc && (
                    <iframe
                        src={iframeSrc}
                        // className="w-100 h-100"
                        style={{
                            height: "calc(100vh - 54px)",
                            width: "100vw",
                            border: "none",
                            display: "block"
                        }}
                        title="testing result"
                    />
                )}
            </ModalBody>
        </Modal>
    );
};

export default TestingDialog;
