import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    FormGroup,
    Input,
    Label,
    Button,
    Table
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Editor } from "@tinymce/tinymce-react";
import CreateBtn from "../../components/Button/CreateBtn";
import CustomPagination from "../../components/CustomPagination";
import { sortAlphabet } from "../../util/Common";
import setAlert from "../../components/Alert/action";
import { createQuestionReminder, deleteQuestionReminder, getAllQuestionReminder } from "../../util/api";
import useFetch from "../../hooks/useFetch";
import { TINY_MCE_API_KEY } from "../MyTask/ProwritingEditor";

const QuestionReminderList = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { data: questionRemindersData, 
            reload: refetchTasks 
    } = useFetch(getAllQuestionReminder);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const ipSearch = useRef("");
    const [search, setSearch] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [nameQuestionReminder, setNameQuestionReminder] = useState("");
    // const [description, setDescription] = useState("");
    const descriptionRef = useRef(null);
    
    const { t } = useTranslation("common");

    const dataTable = sortAlphabet(
        questionRemindersData
            ? questionRemindersData
                  .filter(e => {
                      return (
                          `${e.name}`.includes(
                              search.trim()
                          )
                      );
                  })
            : [],
        "firstName"
    );

    useEffect(() => {
        if (!dataTable) return;

        if (currentPage > Math.ceil(dataTable.length / pageSize)) {
            setCurrentPage(1);
        }
    }, [pageSize, questionRemindersData]);

    const toggle = () => {
        setIsOpen(!isOpen);
        setNameQuestionReminder("");
        // setDescription("");
    };

    const onConfirm = async ()  => {
        if(!nameQuestionReminder){
            window.alert("Question reminder's name is required")
            return;
        }
        let description = descriptionRef.current.getContent();
        const div = document.createElement("div");
        div.innerHTML = description;
        const text = div.textContent || div.innerText || "";
        if (!text.trim()) {
            description = "";
        }

        if(!description){
            window.alert("Question reminder's description is required")
            return;
        }
        
        try{
            // call api
            const data  = {
                name: nameQuestionReminder,
                description
            }
            await createQuestionReminder(data)
            toggle();
            dispatch(
                setAlert({
                    type: "success",
                    message: "Create question reminder successfully"
                })
            );
            refetchTasks();
        }
        catch(err){
            toggle();
            dispatch(
                setAlert({
                    type: "danger",
                    message: "Create failed"
                })
            );
        }
        
    }

    const handleDelete = async taskId => {
        try {
            const isConfirm = window.confirm(
                "Are you sure you want to delete?"
            );

            if (isConfirm) {
                await deleteQuestionReminder(taskId)
                refetchTasks();
                dispatch(
                    setAlert({
                        type: "success",
                        message: "Delete question reminder successfully"
                    })
                );
            }
        } catch (error) {
            console.log("Error::", error);
            dispatch(
                setAlert({
                    type: "danger",
                    message: "Delete failed"
                })
            );
        }
    };

    const renderModal = () => {
        return (
            <Modal isOpen={isOpen} toggle={toggle} className="create-question-reminder" size="lg">
                <ModalHeader toggle={toggle}>Create QuestionReminder</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>Name</Label>

                        <Input
                            type="text"
                            autoFocus
                            value={nameQuestionReminder}
                            onChange={e => setNameQuestionReminder(e.target.value)}
                            invalid={false}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label>Description</Label>
                        <Editor
                            onInit={(evt, editor) => {
                                descriptionRef.current = editor
                            }}
                            apiKey={TINY_MCE_API_KEY}
                            // onEditorChange={(content, editor) => {}}
                            // initialValue="<p>This is the initial content of the editor.</p>"
                            init={{
                                height: 400,
                                menubar: false,
                                branding: false,
                                extended_valid_elements: "*[*]",
                                toolbar:
                                    "undo redo | formatselect | image media | " +
                                    "bold italic backcolor | alignleft aligncenter " +
                                    "alignright alignjustify | bullist numlist outdent indent" ,
                                plugins:
                                    "advlist autolink lists link image media charmap preview anchor " +
                                    "searchreplace visualblocks code fullscreen " +
                                    "insertdatetime media table code help wordcount",
                                content_style:
                                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                file_picker_types: "image",
                                image_uploadtab: true
                            }}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{ width: "100px" }}
                        color="secondary"
                        onClick={toggle}
                    >
                        {t("Cancel")}
                    </Button>
                    <Button
                        style={{ width: "100px" }}
                        color="success"
                        onClick={onConfirm}
                    >
                        {t("Confirm")}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    return (
        <div>
            {renderModal()}
            <div className="row">
                <div className="col-4 d-flex">
                    <CreateBtn
                        title="Create question reminder"
                        onClick={()=> {setIsOpen(true)}}
                        style={{
                            width: "190px"
                        }}
                    />

                    <div style={{ marginRight: "30px" }} />

                    <Input
                        placeholder="Search"
                        onChange={e => {
                            ipSearch.current = e.target.value;
                        }}
                        onKeyDown={e => {
                            if (e.key === "Enter") {
                                setSearch(ipSearch.current);
                            }
                        }}
                        style={{
                            flex: 1
                        }}
                    />
                </div>
            </div>
                   
                <div className="table-content" style={{width: '75%'}}>
                    <Table
                        id="table-questions"
                        hover
                        bordered
                        striped
                        responsive
                        className="grid-table mb-2 mt-2"
                    >
                        <thead>
                            <tr>
                                <th style={{ width: "20%" }}>Name</th>
                                <th style={{width: "20%"}}>Description</th>
                                <th style={{ width: "20%", textAlign: "center" }}>
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataTable
                                ? dataTable
                                    .slice(
                                        (currentPage - 1) * pageSize,
                                        currentPage * pageSize
                                    )
                                    .map((e, index) => (
                                        <tr key={index}>
                                            <td >{e.name}</td>
                                            <td>
                                                <div 
                                                    dangerouslySetInnerHTML={{ __html: e.description }}
                                                    style={{
                                                        maxHeight: '100px',
                                                        overflowY: 'hidden'
                                                    }}
                                                ></div>
                                            </td>
                                            <td className="text-center">
                                                <i
                                                    style={{
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => {
                                                        history.push({
                                                            pathname: `/question-reminder/edit/${e.id}`,
                                                            data: e
                                                        }
                                                        );
                                                    }}
                                                    className="fa fa-pencil mr-2"
                                                    aria-hidden="true"
                                                ></i>
                                                    <i
                                                            onClick={() =>
                                                                handleDelete(e.id)
                                                            }
                                                            className="fa fa-trash"
                                                            aria-hidden="true"
                                                            style={{
                                                                cursor: "pointer"
                                                            }}
                                                        />
                                            </td>
                                        </tr>
                                    ))
                                : ""}
                        </tbody>
                    </Table>

                    <CustomPagination
                        pageCount={
                            dataTable ? Math.ceil(dataTable.length / pageSize) : 0
                        }
                        pageSize={pageSize}
                        currentPage={currentPage}
                        changePage={e => {
                            setCurrentPage(e);
                        }}
                        changePageSize={e => {
                            setPageSize(e);
                        }}
                        onChangePageSize={() => {}}
                    />
                </div>
        </div>
    );
};

export default QuestionReminderList;
