export default (state = {
    fullQuestionList: [],
    questionList: [],
    totalItems: 0,
    currentPage: 1,
    pageCount: 1,
    pageSize: localStorage.getItem("questionListPageSize") ? Number(localStorage.getItem("questionListPageSize")) : 30
}, action) => {
    switch (action.type) {
        case "SET_QUESTION_LIST":
            return {
                ...state,
                questionList: action.payload,
                currentPage: action.currentPage,
                totalItems: action.totalItems,
                pageCount: action.pageCount,
            }
        case "SET_FULL_QUESTION_LIST":
            return {
                ...state,
                fullQuestionList: action.payload,
            }
        case "CHANGE_PAGE_SIZE_QUESTION_LIST":
            return {
                ...state,
                pageSize: action.payload
            }

        default:
            return state
    }
}