export default (state = {
    // mock data, replace them by [] when backend has api
    testReportList: [],
    studentReportList: [],
    totalItemsTest: 0,
    totalItemsStudent: 0,
    currentPageTest: 1,
    currentPageStudent: 1,
    pageCountTest: 1,
    pageCountStudent: 1,
    pageSizeTest: localStorage.getItem("testReportTestPageSize") ? Number(localStorage.getItem("testReportTestPageSize")) : 30,
    pageSizeStudent: localStorage.getItem("testReportStudentPageSize") ? Number(localStorage.getItem("testReportStudentPageSize")) : 30,
}, action) => {
    switch (action.type) {
        case "SET_TEST_REPORT_TEST":
            return {
                ...state,
                testReportList: action.payload,
                // currentPage: action.currentPage,
                // sortBy: action.sortBy,
                // sortOrder: action.sortOrder,
                totalItemsTest: action.totalItems,
                pageCountTest: action.pageCount || 1,
            }
        case "CHANGE_PAGE_SIZE_TEST_REPORT_TEST":
            return {
                ...state,
                pageSizeTest: action.payload
            }
        case "CHANGE_CURRENT_PAGE_TEST_REPORT_TEST":
            return {
                ...state,
                currentPageTest: action.payload
            }
        case "SET_TEST_REPORT_STUDENT":
            return {
                ...state,
                studentReportList: action.payload,
                // currentPage: action.currentPage,
                // sortBy: action.sortBy,
                // sortOrder: action.sortOrder,
                totalItemsStudent: action.totalItems,
                pageCountStudent: action.pageCount || 1,
            }
        case "CHANGE_PAGE_SIZE_TEST_REPORT_STUDENT":
            return {
                ...state,
                pageSizeStudent: action.payload
            }
        case "CHANGE_CURRENT_PAGE_TEST_REPORT_STUDENT":
            return {
                ...state,
                currentPageStudent: action.payload
            }
        default:
            return state
    }
}