export default (state = {
    assessmentResponseList: [],
    currentAssessmentResponse:null,
    totalItems: 0,
    currentPage: 1,
    pageCount: 1,
    pageSize: localStorage.getItem("assessmentAssignmentListPageSize") ? Number(localStorage.getItem("assessmentAssignmentListPageSize")) : 30
}, action) => {
    switch (action.type) {
        case "SET_ASSESSMENT_RESPONSE_LIST":
            return {
                ...state,
                assessmentResponseList: action.payload,
                currentPage: action.currentPage,
                totalItems: action.totalItems,
                pageCount: action.pageCount,
            }
        default:
            return state
    }
}