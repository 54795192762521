import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Button,
    FormGroup,
    Label
} from "reactstrap";
import { assignProofreader } from "../../../util/api";
import useAlert from "../../../hooks/useAlert";
import useMutation from "../../../hooks/useMutation";
import Spinner from "../../../components/Spinner";

const AssignDialog = ({
    questionId,
    isOpen,
    toggle,
    proofReaders,
    refetch,
    proofAssigned
}) => {
    const [firstProofReader, setFirstProofReader] = useState(null);
    const { showSuccess, showWarning, showError } = useAlert();
    const { mutate: handleAssignProofreader, isLoading } = useMutation({
        mutationFn: assignProofreader,
        onSuccess: () => {
            showSuccess("Successfully!");
            refetch();
            toggle();
        },
        onError: err => {
            showError(err.response.data.Message);
        }
    });

    const options = proofReaders
        .filter(p => !proofAssigned.includes(p.id))
        .map(item => {
            return {
                value: item.id,
                label: item.name
            };
        });

    const handleAssign = () => {
        if (!firstProofReader) {
            showWarning("Proofreader is required");
            return false;
        }

        handleAssignProofreader(questionId, firstProofReader);
    };

    useEffect(() => {
        setFirstProofReader(null);
    }, [isOpen]);

    return (
        <Modal
            // className="mw-100 h-100 m-2"
            isOpen={isOpen}
            toggle={toggle}
            // fullscreen="true"
        >
            <Spinner isLoading={isLoading} />

            <ModalHeader toggle={toggle} style={{ padding: "0.5rem" }}>
                Assign for Proofreader
            </ModalHeader>
            <ModalBody
                className="p-2"
                // style={{ height: "94vh" }}
            >
                <FormGroup>
                    <Label>Proofreader</Label>

                    <Select
                        isClearable
                        placeholder="Select proofreader"
                        options={options}
                        onChange={e => {
                            setFirstProofReader(e ? e.value : null);
                        }}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleAssign} color="primary">
                    Assign
                </Button>
                <Button onClick={toggle} color="secondary">
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AssignDialog;
