import React, { useEffect, useState } from "react";
import { Button, Card, CardTitle, Col, Row } from "reactstrap";

function stripHtml(html)
{
   const tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
}

const GrammarSpelling = (props) => {
  const {tags, editorRef, onChangeTinyValue} = props;
  const [currentTab, setCurrentTab] = useState(0);

  const increaseTab = () => {
    if ((currentTab + 1) === tags.length) return;
    setCurrentTab(currentTab + 1);
  }

  const decreaseTab = () => {
    if (currentTab === 0) return;
    setCurrentTab(currentTab - 1);
  }

  const onReplaceErrorText = (startPos, endPos, newChar) => {
    const allText = stripHtml(editorRef.current.getContent())
    const newText = allText.slice(0, startPos) + newChar + allText.slice(endPos + 1);
    onChangeTinyValue(newText);
    if (tags.length > 1 && currentTab > 0) {
      setCurrentTab(0)
    } 
  }


  console.log('tags::::', tags)
  if (!tags[currentTab]) {
    return '';
  }

  return (
    <Card
      className="p-2"
      style={{ fontSize: "1rem" }}
      body
      outline
      color="secondary"
    >
      <CardTitle className="font-weight-bold">
        <h4>Document Check Spelling</h4>
      </CardTitle>
      <Row>
        <Col xs={8}>
          <h5>Grammar/Spelling</h5>
        </Col>
        <Col className="text-right" xs={4}>
          <Button disabled={currentTab === 0} className="btn-change-tab-spell" onClick={() => decreaseTab()}><i className="fa fa-chevron-left"></i></Button>
          <span> {currentTab + 1}/{tags.length} </span>
          <Button disabled={(currentTab + 1) === tags.length} className="btn-change-tab-spell" onClick={() => increaseTab()}><i className="fa fa-chevron-right"></i></Button>
        </Col>
      </Row>
      <Card className="spelling-box">
        <Col xs={12}><h6 className="mt-2">{tags[currentTab] && tags[currentTab].hint}</h6> </Col>
        {tags[currentTab].suggestions.map((item) => (
          <Col key={item} className="word-replace" xs={12} 
          // onClick={() => onReplaceErrorText(tags[currentTab].startPos, tags[currentTab].endPos,item)}
          >
            <h6 className="mt-2 position-relative">
              <i className="fa fa-long-arrow-right"></i> 
                {" "}{item} 
              <i className="fa fa-reply position-absolute end-0"></i> 
            </h6>
          </Col>
        ))}
      </Card>
    </Card>
  );
};

export default GrammarSpelling;
