import React from 'react'
import { FormGroup } from 'reactstrap';
import Select from "react-select";
import { connect } from "react-redux";
import _ from "lodash";
import { withTranslation } from 'react-i18next';
import { langOptions } from '../../util/Constant';
import { onChangeLanguage } from '../../containers/Common/action';

import { registerLocale } from "react-datepicker";
import { enGB, sv } from 'date-fns/locale'

import moment from "moment"
import "moment/locale/sv"
import "moment/locale/en-gb"

class langSelected extends React.PureComponent {
    constructor(props) {
        super(props)
        let la = localStorage.getItem('lang');
        if (la) {
            la = _.find(langOptions, l => l.value === la)
        } else {
            la = { ...langOptions[0] };
        }
        this.state = {
            lang: la
        };
        // if (la.value === "se") {
        //     moment.locale("sv")
        // } else {
        //     moment.locale("en-gb")
        // }
        moment.locale("en-gb")
    }

    saveToLocalStore = e => {
        const { i18n } = this.props;
        this.setState({ lang: e })
        localStorage.setItem('lang', e.value);
        i18n.changeLanguage(e.value)
        this.props.onChangeLanguage(e.value)

        // const datePickerLanguage = e.value === "se" ? sv : enGB
        const datePickerLanguage = enGB

        registerLocale('en-gb', datePickerLanguage)

        // if (e.value === "se") {
        //     moment.locale("sv")
        // } else {
        //     moment.locale("en-gb")
        // }
        moment.locale("en-gb")
    }

    render() {
        const { lang } = this.state;
        return (
            <FormGroup style={{ width: 100 }}>
                <Select
                    value={lang}
                    options={langOptions}
                    onChange={this.saveToLocalStore}
                />
            </FormGroup>
        )
    }
}


const mapDispatchToProps = {
    onChangeLanguage
};

export default connect(
    null,
    mapDispatchToProps,
)(withTranslation('common')(langSelected));

