import React, { Component } from "react";
import { Route, Switch, Router } from "react-router-dom";
// import Loadable from "react-loadable";
import { Provider } from "react-redux";

import { LicenseManager } from "@ag-grid-enterprise/core";

import { registerLocale } from "react-datepicker";
import { enGB, sv } from 'date-fns/locale'
import "react-datepicker/dist/react-datepicker.css";

import "./App.scss";
import store from "./store";
// import Loading from "./components/Loading";
import Modal from "./components/Modal";
import ModalInput from "./components/ModalInput";
import Spinner from "./components/Spinner/index";
import Alert from "./components/Alert/index";
import ConfirmDialog from "./components/ConfirmDialog";
import history from "./util/history";

import DefaultLayout from "./containers/Layout/views/layoutContainer";
import Login from "./containers/Login/views/loginContainer";
import Page404 from "./components/Page404";
import Page500 from "./components/Page500";
import QuestionDiscussion from "./containers/QuestionDiscusstion";

import Authenticator from "./containers/Login/views/Authenticator"
import ResetPassword from "./containers/Login/views/ResetPassword";
import ForgotPassword from "./containers/ForgotPassword/view/index";

const datePickerLanguage = localStorage.getItem("lang") === "se" ? sv : enGB

registerLocale('en-gb', datePickerLanguage)

LicenseManager.setLicenseKey("Alhanko&Johnson_BASE23_single_1_Devs__22_November_2020_[v2]_MTYwNjAwMzIwMDAwMA==d452f38afc893671cad92795e95d52a1")

// const DefaultLayout = Loadable({
//   loader: () => import("./containers/Layout/views/layoutContainer"),
//   loading: Loading,
// });

// const Login = Loadable({
//   loader: () => import("./containers/Login/views/loginContainer"),
//   loading: Loading,
// });

// const Page404 = Loadable({
//   loader: () => import("./components/Page404"),
//   loading: Loading,
// });

// const Page500 = Loadable({
//   loader: () => import("./components/Page500"),
//   loading: Loading,
// });

// const QuestionDiscussion = Loadable({
//   loader: () => import("./containers/QuestionDiscusstion"),
//   loading: Loading
// })

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Alert />
        <Modal />
        <ModalInput />
        <Spinner />
        <ConfirmDialog />
        <Router history={history}>
          <Switch>
            <Route exact path="/authenticator" name="Authenticator page" component={Authenticator} />
            <Route exact path="/question-discussion/:questionId" component={QuestionDiscussion} />
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route exact path="/forgot-password" name="Forgot Password Page" component={ForgotPassword} />
            <Route exact path="/reset-password" name="Reset Password" component={ResetPassword} />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <Route path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
