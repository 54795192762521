import React, { memo } from 'react'

export default memo(function EditBtn(props) {
    return (
        <div
            onClick={props.onClick}
            {...props}
            role = "button"
            tabIndex = {0}>
            <i className="fa fa-edit" style={{ fontSize: 16, cursor: 'pointer'}} />
        </div>
    )
})
