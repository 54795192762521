import { useDispatch } from "react-redux";
import setAlert from "../components/Alert/action";

const useAlert = () => {
    const dispatch = useDispatch();

    const showAlert = type => message => {
        dispatch(
            setAlert({
                type,
                message
            })
        );
    };

    return {
        showSuccess: showAlert("success"),
        showError: showAlert("danger"),
        showWarning: showAlert("warning")
    };
};

export default useAlert;
