import React, { PureComponent } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
} from "reactstrap";
import {
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler,
} from "@coreui/react";
import { withTranslation } from 'react-i18next'

import { connect } from 'react-redux';
// import logo from "../assets/img/logo.png";
import sygnet from "../assets/img/minilogo.png";
import LangSelected from "./Selector/LangSelected"

import { isTeacherOrAssistanceOnly, isParentOrStudent } from '../util/Common';
import history from "../util/history";

const defaultLogo = "/assets/img/logo_default.png"
const defaultBigLogo = "/assets/img/big_logo_default.png"

class Header extends PureComponent {
  state = {
    logo: "",
    bigLogo: ""
  }

  async componentDidMount() {
    this.setState({ logo: defaultLogo, bigLogo: defaultBigLogo })
  }

  parseUserInfo = () => {
    const { userInfo } = this.props
    if (userInfo && userInfo.identity) {
      const { avatar, email } = userInfo.identity
      return {
        avatar: avatar ? `/api/file/downloadAvatar/${avatar}` : null, email
      };
    }
    return { avatar: '', email: '' }
  }

  render() {
    const { avatar, email } = this.parseUserInfo()
    const { t } = this.props

    const salary = isTeacherOrAssistanceOnly()
    const info = isParentOrStudent()

    return (
      <React.Fragment>
        {!salary && !info && <AppSidebarToggler className="d-lg-none" display="md" mobile />}
        <AppNavbarBrand
          full={{ src: this.state.bigLogo, width: 120, height: 41, alt: "School manager Logo" }}
          minimized={{ src: this.state.logo, width: 30, height: 30, alt: "School manager Logo" }}
        />
        {!salary && !info && <AppSidebarToggler className="d-md-down-none" display="lg" />}

        <Nav className="d-md-down-none" navbar>
          <NavItem className="px-3" />
          <NavItem className="px-3" />
        </Nav>
        <Nav className="ml-auto" navbar>
          <NavItem className="d-md-down-none" />
          <NavItem className="d-md-down-none" />
          <NavItem className="d-md-down-none" />
          {/* <LangSelected /> */}
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              <img
                alt='avtar'
                src={avatar || require('../assets/img/avatar_default.PNG')}
                className="img-avatar"
              />
            </DropdownToggle>
            <DropdownMenu right className="avatar-menu">
              <DropdownItem>
                <i className="fa fa-shield" /> {email}
              </DropdownItem>
              <DropdownItem onClick={e => this.props.onLogout(e)}>
                <i className="fa fa-lock" /> {t('logout')}
              </DropdownItem>
              <DropdownItem onClick={e => history.push('/reset-password')}>
                <i className="fa fa-key" /> {t('Reset password')}
              </DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
      </React.Fragment>
    );
  }
}

const withMultiLanguage = withTranslation('common')(Header)

const mapStateToProps = ({ common: { userInfo } }) => ({ userInfo })
export default connect(mapStateToProps)(withMultiLanguage);
