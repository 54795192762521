import React, { useEffect, useState } from "react";
import { Input, Label } from "reactstrap";
import Select from "react-select";
import "./CommentBox.scss";

const CommentBox = ({ toggle, position, onAddComment, checkListData, isLoading }) => {
    const [comment, setComment] = useState("");
    const [errorType, setErrorType] = useState(null)
    const [subCategory, setSubCategory] = useState(null)
    const [checkList, setCheckList] = useState(null)
    const [showError, setShowError] = useState(false)

    useEffect(() => {
        const table = document.querySelector('table');
        const rect = table.getBoundingClientRect();
        document.querySelector('.CommentPlugin_CommentInputBox').style.left = `${rect.right + 30}px`;
    }, [])

    useEffect(() => {
        document.getElementById("comment").focus();
    }, [position]);

    const errorTypes = checkListData ? checkListData.filter(e => !e.parentId).map(e => ({value: e.id, label: e.title})) : []
    const subCategories = checkListData ? checkListData.filter(e => errorType && e.parentId === errorType.value).map(e => ({value: e.id, label: e.title})) : []
    const checkLists = checkListData ? checkListData.filter(e => subCategory && e.parentId === subCategory.value).map(e => ({value: e.id, label: e.title})) : []

    return (
        <div
            className="CommentPlugin_CommentInputBox bg-white"
            style={{
                bottom: 30
            }}
        >
            <div className="CommentPlugin_CommentInputBox_EditorContainer">
                <Label>
                    Error type
                    <span className="color-danger">*</span>
                </Label>

                <Select
                    value={errorType}
                    placeholder="Select error type"
                    options={errorTypes}
                    onChange={e => {
                        if (!e) return;
                       setErrorType(e)
                       setSubCategory(null)
                       setCheckList(null)
                    }}
                    isLoading={isLoading}
                />
                {showError && !errorType && <div style={{ color: 'red' }}>{'Must select error type'}</div>}

                <Label className="mt-2">
                    Sub category
                    <span className="color-danger">*</span>
                </Label>

                <Select
                    value={subCategory}
                    placeholder="Select subcategory"
                    options={subCategories}
                    onChange={e => {
                        if (!e) return;
                       setSubCategory(e)
                       setCheckList(null)
                    }}
                    isLoading={isLoading}
                />
                {showError && !subCategory && <div style={{ color: 'red' }}>{'Must select sub category'}</div>}

                <Label className="mt-2">
                    Check list
                    <span className="color-danger">*</span>
                </Label>

                <Select
                    value={checkList}
                    placeholder="Select check list"
                    options={checkLists}
                    onChange={e => {
                        if (!e) return;
                       setCheckList(e)
                    }}
                    isLoading={isLoading}
                />
                {showError && !checkList && <div style={{ color: 'red' }}>{'Must select check list'}</div>}


                <Input
                    value={comment}
                    className="p-2 mt-3"
                    rows={5}
                    id="comment"
                    name="comment"
                    type="textarea"
                    placeholder="Type a comment..."
                    style={{
                        fontSize: 15
                    }}
                    onChange={e => {
                        setComment(e.target.value);
                    }}
                />
            </div>

            <div className="CommentPlugin_CommentInputBox_Buttons">
                <button
                    onClick={toggle}
                    className="Button__root CommentPlugin_CommentInputBox_Button"
                >
                    Cancel
                </button>
                <button
                    onClick={() => {
                        setShowError(true)
                        if (!checkList) return
                        onAddComment(comment, checkList.value);
                    }}
                    disabled={!comment}
                    className="Button__root Button__disabled CommentPlugin_CommentInputBox_Button primary"
                >
                    Comment
                </button>
            </div>
        </div>
    );
};

export default CommentBox;
