import React, { useMemo, useState, useEffect } from "react";
// import useFetch from "../hooks/useFetch";
import { getCBTQuestionCategories } from "../util/api";
import Skeleton from "./Skeleton";

let cache = null;
let isSentRequest = false;

const subscribers = [];
const event = {
    on: cb => {
        subscribers.push(cb);
    },
    emit: () => {
        subscribers.forEach(sub => {
            sub();
        });
    }
};

const RenderCategory = ({ categoryId }) => {
    // const { data: categories } = useFetch(getCBTQuestionCategories);
    const [categories, setCategories] = useState(null);

    useEffect(() => {
        if (cache) {
            setCategories(cache);
        } else {
            event.on(() => {
                setCategories(cache);
            });
        }

        if (!isSentRequest) {
            isSentRequest = true;
            (async () => {
                try {
                    const res = await getCBTQuestionCategories();
                    setCategories(res.data || res);
                    cache = res.data || res;

                    event.emit();
                } catch (error) { }
            })();
        }
    }, []);

    const hashMapCategories = useMemo(() => {
        if (!categories) return {};

        return categories.reduce((pre, cr) => {
            return {
                ...pre,
                [cr.id]: cr
            };
        }, {});
    }, [categories]);

    let current = hashMapCategories[categoryId];
    let chain = [];
    console.log(current);
    if (current && Object.keys(hashMapCategories).length > 0) {
        chain = [current];
        while (current.parentId) {
            current = hashMapCategories[current.parentId];
            chain = [current, ...chain];
        }
    }

    if (!categories) {
        return <Skeleton />;
    }

    return (
        <div>
            {chain.map((c, index) => {
                if (
                    c &&
                    Number(c.id) !== Number(categoryId) &&
                    index < chain.length - 1
                ) {
                    return (
                        <b
                            style={{
                                color: "#bcbcbc"
                            }}
                            key={`chain-${index}`}
                        >
                            {c.title} /{" "}
                        </b>
                    );
                }
                return <b>{c.title}</b>;
            })}
        </div>
    );
};

export default RenderCategory;
