import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Table,
    Button
} from "reactstrap";
import Select from "react-select";
import { Editor } from "@tinymce/tinymce-react";
import CreatorSelector from "../../components/CreatorSelector";
import { sortAlphabet } from "../../util/Common";
import {
    getAllTaskSubject,
    getCBTQuestionCategories,
    getCategoriesToCbt,
    getOfficalCBTQuestions,
    getGroups,
    getTasks,
    updateTask,
    getAllQuestionReminder
} from "../../util/api";
import useFetch from "../../hooks/useFetch";
import RenderCategory from "../../components/RenderCategory";
import { NUMBER_ANSWEARS_MATHEMATICAL_REASONING, NUMBER_ANSWEARS_READING, NUMBER_ANSWEARS_THINKING_SKILLS, QUESTION_DIFFICULTY_LEVEL } from "../../util/Constant";
import useAlert from "../../hooks/useAlert";
import { TINY_MCE_API_KEY } from "../MyTask/ProwritingEditor";

// const SUBJECTS = sortAlphabet([
//     {
//         value: 57,
//         label: "Reading"
//     },
//     {
//         value: 58,
//         label: "Mathematical Reasoning"
//     },
//     {
//         value: 572,
//         label: "Writing"
//     },
//     {
//         value: 586,
//         label: "Thinking Skills"
//     }
// ]);

const DEFAULT_VALUES = {
    name: "",
    email: "",
    previousTask: "",
    subject: "",
    questionDifficultyLevel: 0,
    maxQuestion: 10,
    questionCount: 10,
    isTestRequired: false,
    categoryIds: "",
    categories: "",
    nswCategoryID: "",
    numberOfAnswears: 5,
    isRandomTest: false,
    isManualSelect: false,
};

const CreateTaskDialog = ({ taskDetail, isViewDetail, toggle, onConfirm }) => {
    const { t } = useTranslation("common");
    const { showWarning } = useAlert();
    const { data: allCategory, error } = useFetch(getCBTQuestionCategories);
    const { data: groups } = useFetch(getGroups);
    const { data: subjects } = useFetch(getAllTaskSubject);
    const { data: questionReminders } = useFetch(getAllQuestionReminder);
    const convertSubjects =
        subjects &&
        subjects.map(a => {
            return {
                value: a.id,
                label: a.name
            };
        });
    const convertQuestionReminder =
        questionReminders &&
        questionReminders.map(a => {
            return {
                value: a.id,
                label: a.name
            };
        });
    const [formData, setFormData] = useState(isViewDetail ? {
        ...taskDetail,
        isRandomTest: !!taskDetail.questionCount,
        isManualSelect: !!taskDetail.cBTQuestionIdsToTest
    } : DEFAULT_VALUES);
    const [invalidFields, setInvalidFields] = useState({});
    const { data: tasks } = useFetch(
        () => getTasks("", formData.email),
        [formData.email],
        !!formData.email
    );

    const [enableShowError, setEnableShowError] = useState(false);

    const [selectedCategories, setSelectedCategories] = useState([]);

    const [selectedGroup, setSelectedGroup] = useState(isViewDetail ? {
        value: formData.subjectId,
        label: formData.subjectName,
    } : null);
    const [questionReminder, setQuestionReminder] = useState(isViewDetail ? {
        value: formData.questionReminderId,
        label: formData.questionReminderName,
    } : null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [selectedClassification, setSelectedClassification] = useState(null);

    const [selectedGroupUser, setSelectedGroupUser] = useState(null);
    const [partOfQuestionId, setPartOfQuestionId] = useState('')
    const [questionToTestOptions, setQuestionToTestOptions] = useState([])
    const [testQuestions, setTestQuestions] = useState([])
    const [isFetching, setIsFetching] = useState(false)

    // const parentSubject = (allCategory || []).find(
    //     e => selectedGroup && !e.parentId && e.title === selectedGroup.label
    // );
    // const categories = (allCategory || []).filter(
    //     e => selectedGroup && parentSubject && e.parentId === parentSubject.id
    // );
    // const subCategories = (allCategory || []).filter(
    //     e => selectedCategory && +e.parentId === +selectedCategory.value
    // );
    const parentSubject = allCategory || [];
    const categories = allCategory || [];
    const subCategories = allCategory || [];
    const { data: categoriesNSW } = useFetch(
        () => getCategoriesToCbt(selectedGroup.label),
        [selectedGroup ? selectedGroup.label : ''],
        !!selectedGroup
    );
    const defaultNumberAnswears = () => {
        if (selectedGroup && selectedGroup.label === "Thinking Skills") return NUMBER_ANSWEARS_THINKING_SKILLS
        if (selectedGroup && selectedGroup.label === "Mathematical Reasoning") return NUMBER_ANSWEARS_MATHEMATICAL_REASONING
        if (selectedGroup && selectedGroup.label === "Reading") return NUMBER_ANSWEARS_READING
        return DEFAULT_VALUES.numberOfAnswears
    }

    useEffect(() => {
        if (isViewDetail) {
            const findCategory = categories && categories.find((e) => +e.id === +formData.categoryIds);
            if (findCategory) {
                setSelectedCategory({
                    value: findCategory.id,
                    label: findCategory.title,
                });
                return
            }

            const classifi = allCategory && allCategory.find(e => +e.id === +formData.categoryIds);

            if (classifi) {
                const subC = allCategory.find(e => +e.id === +classifi.parentId);
                const cat = allCategory.find(e => +e.id === +subC.parentId);

                setSelectedCategory({
                    value: cat.id,
                    label: cat.title,
                })
                setSelectedClassification({
                    value: subC.id,
                    label: `${subC.title} - ${classifi.title}`,
                })
            }
        }
    }, [allCategory, selectedCategory ? selectedCategory.value : 0, selectedClassification ? selectedClassification.value : 0])

    const debounceRef = useRef(null)

    const getQuestionToTestOptions = async () => {
        try {
            if (partOfQuestionId.length < 4) {
                setQuestionToTestOptions([])
                return
            }
            setIsFetching(true)
            const res = await getOfficalCBTQuestions(partOfQuestionId)
            setQuestionToTestOptions(res.data.map(e => ({
                value: e.id,
                label: e.questionText.replaceAll(/<img[^>]*>/g, "")
            })))
        } catch {

        }
        setIsFetching(false)
    }

    useEffect(() => {
        debounceRef.current = setTimeout(() => {
            getQuestionToTestOptions()
        }, 500)
        return () => {
            if (debounceRef.current) {
                clearTimeout(debounceRef.current)
            }
        }
    }, [partOfQuestionId])

    const Option = ({ innerRef, innerProps, value, label }) => {
        return <div className="cursor-pointer p-2 question-to-test-option" ref={innerRef} {...innerProps} >
            <div>{`QuestionId: ${value}`}</div>
            <div dangerouslySetInnerHTML={{ __html: label }} />
        </div>
    }

    const taskInstructionRef = useRef(null);

    const handleChangeValue = field => value => {
        setFormData(pre => {
            return {
                ...pre,
                [field]: value
            };
        });

        if (enableShowError) {
            setInvalidFields(pre => {
                return {
                    ...pre,
                    [field]: value === "" || value === null || value === undefined
                };
            });
        }
    };

    const onAdd = () => {
        const selected =
            selectedClassification || selectedSubCategory || selectedCategory;

        if (!selected) {
            showWarning("Please choose a category!");
            return;
        }
        // || selectedGroup;

        const isExisted = selectedCategories.find(
            c => +c.value === +selected.value
        );

        if (isExisted) {
            showWarning("Already exist in the list!");
            return;
        }
        setSelectedCategories([...selectedCategories, selected]);
        setSelectedCategory(null);
        setSelectedSubCategory(null);
        setSelectedClassification(null);
        setInvalidFields(pre => {
            return {
                ...pre,
                categoryIds: false
            };
        });
    };

    const isValid = () => {
        let isValid = true;
        const invalidFields = {};

        [
            "name",
            "email",
            "subject",
            "questionDifficultyLevel",
            "maxQuestion",
            // "nswCategoryID",
            // "numberOfAnswears"
            // "questionCount"
        ].forEach(field => {
            if (formData[field] === "" || formData[field] === null || formData[field] === undefined) {
                isValid = false;
                invalidFields[field] = true;
            }
        });

        if (selectedCategories.length === 0) {
            isValid = false;
            invalidFields.categoryIds = true;
        }

        if (!isValid) {
            setInvalidFields(invalidFields);
        }

        return isValid;
    };

    const handleConfirm = () => {
        let taskInstructionValue = taskInstructionRef.current.getContent();
        const div = document.createElement("div");
        div.innerHTML = taskInstructionValue;
        const text = div.textContent || div.innerText || "";
        if (!text.trim()) {
            taskInstructionValue = "";
        }

        if (isViewDetail) {
            onConfirm({
                id: taskDetail.id,
                taskInstruction: taskInstructionValue
            });
            return;
        }

        if (!enableShowError) {
            setEnableShowError(true);
        }

        if (!isValid()) {
            return;
        }

        // if (formData.isTestRequired && !testQuestions.length) {
        //     return;
        // }

        const payload = {
            email: formData.email,
            previousTask: formData.previousTask,
            name: formData.name,
            categoryIds: selectedCategories.map(e => e.value).join(","),
            categories: selectedCategories.map(e => e.label).join(","),
            isTestRequired: formData.isTestRequired,
            cBTQuestionIdsToTest: testQuestions.map(tq => tq.value),
            subjectId: selectedGroup.value,
            subjectName: selectedGroup.label,
            questionDifficultyLevel: formData.questionDifficultyLevel,
            nswCategoryID: formData.nswCategoryID,
            numberOfAnswears: formData.numberOfAnswears,
            maxQuestion: formData.maxQuestion,
            questionCount: formData.isTestRequired
                ? formData.questionCount
                : undefined,
            taskInstruction: taskInstructionValue,
            questionReminderId: questionReminder ? questionReminder.value : 0,
            questionReminderName: questionReminder ? questionReminder.label : "",
            nswSubjectID: formData.nswSubjectID,
        };

        onConfirm(payload);
    };

    const groupOptions =
        (groups &&
            groups.map(g => {
                return {
                    value: g.id,
                    label: g.name
                };
            })) ||
        [];

    const taskOptions = tasks
        ? tasks.map(t => {
            return {
                label: t.name,
                value: t.id
            };
        })
        : [];

    const categoriesNSWOptions = categoriesNSW ? categoriesNSW.map(({ id, title, parentId }) => {
        return {
            label: title,
            value: id,
            parentId
        }
    }) : []

    // const categoryDescription = sortAlphabet(
    //     subCategories
    //         .map(sC => {
    //             const classifications = (
    //                 allCategory || []
    //             ).filter(
    //                 e => e.parentId === sC.id
    //             );

    //             return classifications.map(
    //                 cl => {
    //                     return {
    //                         value: cl.id,
    //                         label: `${sC.title} - ${cl.title}`
    //                     };
    //                 }
    //             );
    //         })
    //         .flat()
    // )

    const categoryDescription = sortAlphabet(
        subCategories
            .map(sC => {
                return {
                    value: sC.id,
                    label: `${sC.title}`
                };
            })
            .flat()
    )

    if (error) {
        return (
            <div>
                <span
                    style={{
                        color: "red"
                    }}
                >
                    Something went wrong, please try again!
                </span>
            </div>
        );
    }

    return (
        <Modal isOpen toggle={toggle} className="create-task" size="lg">
            <ModalHeader toggle={toggle}>
                <h4 className="m-0">{isViewDetail ? 'Task detail' : 'Create task'}</h4>
            </ModalHeader>

            <ModalBody>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label>
                                Task Name<span className="color-danger">*</span>
                            </Label>

                            <Input
                                value={formData.name}
                                disabled={isViewDetail}
                                type="text"
                                autoFocus
                                className={classnames({
                                    "is-invalid": invalidFields.name
                                })}
                                onChange={e => {
                                    setSelectedGroup(e);
                                    setSelectedCategory(null);
                                    setSelectedSubCategory(null);
                                    setSelectedClassification(null);
                                    handleChangeValue("subject")(e.value);
                                    handleChangeValue("nswCategoryID")(null);
                                    setSelectedCategories([]);
                                    handleChangeValue("name")(e.target.value);
                                }}
                                invalid={false}
                            />

                        </FormGroup>
                    </Col>

                    <Col md={6}>
                        <FormGroup>
                            <Label>Creator Groups</Label>

                            <Select
                                isDisabled={isViewDetail}
                                isClearable
                                options={groupOptions}
                                onChange={e => {
                                    setSelectedGroupUser(e ? e.value : null);
                                    handleChangeValue("email")("");
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <FormGroup className="mt-2">
                            <Label>
                                Question Creator<span className="color-danger">*</span>
                            </Label>

                            <CreatorSelector
                                isDisabled={isViewDetail}
                                groupId={selectedGroupUser}
                                className={classnames({
                                    "invalid-field": invalidFields.email
                                })}
                                value={formData.email}
                                onChange={value => {
                                    handleChangeValue("email")(value);
                                    handleChangeValue("previousTask")(null);
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup className="mt-2">
                            <Label>
                                Subject<span className="color-danger">*</span>
                            </Label>

                            <Row>
                                <Col>
                                    <Select
                                        value={selectedGroup}
                                        isDisabled={isViewDetail}
                                        // value={formData.subject}
                                        className={classnames({
                                            "invalid-field": invalidFields.subject
                                        })}
                                        options={convertSubjects}
                                        onChange={e => {
                                            setSelectedGroup(e);
                                            setSelectedCategory(null);
                                            setSelectedSubCategory(null);
                                            setSelectedClassification(null);
                                            handleChangeValue("subject")(e.value);
                                            setSelectedCategories([]);
                                            handleChangeValue("numberOfAnswears")(defaultNumberAnswears());
                                        }}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <FormGroup className="mt-2">
                            <Label>
                                Question difficulty level
                                <span className="color-danger">*</span>
                            </Label>

                            <Row>
                                <Col>
                                    <Select
                                        isDisabled={isViewDetail}
                                        value={QUESTION_DIFFICULTY_LEVEL.find(e => {
                                            return +e.value === +formData.questionDifficultyLevel
                                        })}
                                        defaultValue={{
                                            label: "Allow creator to select",
                                            value: 0
                                        }}
                                        className={classnames({
                                            "invalid-field":
                                                invalidFields.questionDifficultyLevel
                                        })}
                                        options={QUESTION_DIFFICULTY_LEVEL}
                                        onChange={e => {
                                            handleChangeValue(
                                                "questionDifficultyLevel"
                                            )(e.value);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup className="mt-2">
                            <Label>
                                Max question to create
                                <span className="color-danger">*</span>
                            </Label>

                            <Input
                                disabled={isViewDetail}
                                type="number"
                                value={formData.maxQuestion}
                                onChange={e => {
                                    const value = e.target.value ? Math.abs(e.target.value) : '';

                                    handleChangeValue("maxQuestion")(
                                        value > 1000 ? 1000 : value
                                    );
                                }}
                                className={classnames({
                                    "is-invalid": invalidFields.maxQuestion
                                })}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup className="mt-2">
                            <Label>
                                Number of answears in each question
                                <span className="color-danger">*</span>
                            </Label>

                            <Input
                                disabled={isViewDetail}
                                type="number"
                                value={formData.numberOfAnswears}
                                onChange={e => {
                                    const value = e.target.value ? Math.abs(e.target.value) : '';

                                    handleChangeValue("numberOfAnswears")(
                                        value > 1000 ? 1000 : value
                                    );
                                }}
                                className={classnames({
                                    "is-invalid": invalidFields.numberOfAnswears
                                })}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup className="mt-2">
                            <Label>
                                Question Reminder
                            </Label>

                            <Row>
                                <Col>
                                    <Select
                                        value={questionReminder}
                                        isDisabled={isViewDetail}
                                        options={convertQuestionReminder}
                                        onChange={e => {
                                            setQuestionReminder(e);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup className="mt-4">
                    <div
                        className={classnames("position-relative px-2 py-4", {
                            "invalid-field": invalidFields.categoryIds
                        })}
                        style={{
                            border: "1px solid #dedede",
                            borderRadius: "0.3rem"
                        }}
                    >
                        <div className="d-flex align-items-center">
                            <div
                                style={{
                                    top: 0,
                                    left: 10,
                                    transform: "translateY(-50%)",
                                    backgroundColor: "#dadada",
                                    padding: "1px 5px",
                                    borderRadius: "0.3rem"
                                }}
                                className="position-absolute"
                            >
                                <p className="mb-0">1st Category</p>
                            </div>

                            <div className="mr-2" style={{ width: "40%" }}>
                                <Select
                                    isDisabled={
                                        isViewDetail ||
                                        !selectedGroup ||
                                        selectedCategories.length > 0
                                    }
                                    value={selectedCategory}
                                    options={sortAlphabet(
                                        categories.map(c => ({
                                            value: c.id,
                                            label: c.title
                                        }))
                                    )}
                                    onChange={e => {
                                        setSelectedCategory(e);
                                        setSelectedSubCategory(null);
                                        setSelectedClassification(null);
                                    }}
                                />
                            </div>

                            <div className="mr-2" style={{ width: "40%" }}>
                                <Select
                                    isDisabled={
                                        isViewDetail ||
                                        !selectedGroup ||
                                        selectedCategories.length > 0
                                    }
                                    value={selectedClassification}
                                    options={categoryDescription}
                                    onChange={e => {
                                        // setSelectedSubCategory(e);
                                        setSelectedClassification(e);
                                    }}
                                />
                            </div>

                            <div style={{ width: "20%" }}>
                                <Button
                                    disabled={
                                        isViewDetail ||
                                        !selectedGroup ||
                                        selectedCategories.length > 0
                                    }
                                    onClick={onAdd}
                                    color="success"
                                    className="w-100"
                                >
                                    Add
                                </Button>
                            </div>
                        </div>

                        {invalidFields.categoryIds && (
                            <span className="color-danger d-block mt-1">
                                Please add at least one category
                            </span>
                        )}

                        {selectedCategories.length > 0 && (
                            <div className="mt-2">
                                {selectedCategories.map(e => {
                                    return (
                                        <Row key={e.value} className="mb-1">
                                            <Col className="d-flex flex-row justify-content-between align-items-center">
                                                <RenderCategory
                                                    categoryId={e.value}
                                                />

                                                <i
                                                    className="fa fa-times-circle-o"
                                                    aria-hidden="true"
                                                    style={{
                                                        fontSize: "1.2rem",
                                                        marginLeft: "5px",
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() =>
                                                        setSelectedCategories([
                                                            ...selectedCategories.filter(
                                                                c =>
                                                                    c.value !==
                                                                    e.value
                                                            )
                                                        ])
                                                    }
                                                ></i>
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </FormGroup>

                <FormGroup className="mt-2">
                    <Label>2nd Category</Label>

                    <Select
                        // isClearable
                        isDisabled={isViewDetail}
                        value={categoriesNSWOptions.find((e) => {
                            return +e.value === +formData['nswCategoryID']
                        })}
                        className={classnames({
                            "invalid-field": invalidFields.nswCategoryID
                        })}
                        options={categoriesNSWOptions}
                        onChange={e => {
                            handleChangeValue("nswCategoryID")(e.value);
                            handleChangeValue("nswSubjectID")(e.parentId);
                        }}
                    />
                </FormGroup>


                <Row>
                    <Col md={12} id="task_instruction">
                        <FormGroup className="mt-2">
                            <Label for="exampleText">
                                Task Instruction
                            </Label>
                            <Editor
                                // disabled={isViewDetail}
                                onInit={(evt, editor) => {
                                    taskInstructionRef.current = editor
                                }}
                                apiKey={TINY_MCE_API_KEY}
                                onEditorChange={(content, editor) => { }}
                                initialValue={formData.taskInstruction}
                                init={{
                                    height: 400,
                                    menubar: false,
                                    branding: false,
                                    extended_valid_elements: "*[*]",
                                    toolbar:
                                        "undo redo | formatselect | image media | " +
                                        "bold italic backcolor | alignleft aligncenter " +
                                        "alignright alignjustify | bullist numlist outdent indent",
                                    plugins:
                                        "advlist autolink lists link image media charmap preview anchor " +
                                        "searchreplace visualblocks code fullscreen " +
                                        "insertdatetime media table code help wordcount",
                                    content_style:
                                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                    file_picker_types: "image",
                                    image_uploadtab: true
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                {/* <FormGroup className="mt-2">
                    <Label>Category NSW<span className="color-danger">*</span></Label>

                    <Select
                        // isClearable
                        isDisabled={isViewDetail}
                        value={categoriesNSWOptions.find((e) => {
                            return +e.value === +formData['nswCategoryID']
                        })}
                        className={classnames({
                            "invalid-field": invalidFields.nswCategoryID
                        })}
                        options={categoriesNSWOptions}
                        onChange={e => {
                            handleChangeValue("nswCategoryID")(e.value);
                            handleChangeValue("nswSubjectID")(e.parentId);
                        }}
                    />
                </FormGroup> */}

                <FormGroup className="mt-2">
                    <Label>Previous Task</Label>

                    <Select
                        isDisabled={isViewDetail}
                        value={
                            formData.previousTask
                                ? taskOptions.find(
                                    t => +t.id === +formData.previousTask
                                )
                                : ""
                        }
                        isClearable
                        options={taskOptions}
                        onChange={e => {
                            handleChangeValue("previousTask")(e.value);
                        }}
                    />
                </FormGroup>

                <Row>
                    <Col md={6}>
                        <FormGroup className="mt-2 d-flex justify-content-between" check>
                            <Label className="d-flex align-items-center" check>
                                <Input
                                    disabled={isViewDetail}
                                    checked={formData.isTestRequired}
                                    type="checkbox"
                                    className="mt-0"
                                    onChange={e => {
                                        handleChangeValue("isTestRequired")(
                                            e.target.checked
                                        );
                                        if (e.target.checked) {
                                            handleChangeValue("isManualSelect")(true)
                                        }
                                    }}
                                />
                                <span>Show test</span>
                            </Label>
                            {formData.isTestRequired && <Label className="d-flex align-items-center" check>
                                <Input
                                    disabled={isViewDetail}
                                    checked={formData.isRandomTest}
                                    type="checkbox"
                                    className="mt-0"
                                    onChange={e => {
                                        handleChangeValue("isRandomTest")(
                                            e.target.checked
                                        );
                                        handleChangeValue("isManualSelect")(
                                            !e.target.checked
                                        );
                                    }}
                                />
                                <span>Random</span>
                            </Label>}
                            {formData.isTestRequired && <Label className="d-flex align-items-center" check>
                                <Input
                                    disabled={isViewDetail}
                                    checked={formData.isManualSelect}
                                    type="checkbox"
                                    className="mt-0"
                                    onChange={e => {
                                        handleChangeValue("isManualSelect")(
                                            e.target.checked
                                        );
                                        handleChangeValue("isRandomTest")(
                                            !e.target.checked
                                        )
                                    }}
                                />
                                <span>Manual select</span>
                            </Label>}
                        </FormGroup>
                    </Col>
                </Row>

                {formData.isTestRequired && (<>
                    {formData.isRandomTest && <FormGroup className="mt-2">
                        <div className="d-flex align-items-center">
                            <Label>
                                Question count
                                <span className="color-danger">*</span>
                            </Label>

                            <Input
                                disabled={isViewDetail}
                                type="number"
                                value={formData.questionCount}
                                onChange={e => {
                                    const value = e.target.value
                                        ? Math.abs(e.target.value)
                                        : "";

                                    handleChangeValue("questionCount")(
                                        value > 1000 ? 1000 : value
                                    );
                                }}
                                className={classnames("mt-0 w-25 ml-2", {
                                    "is-invalid": invalidFields.questionCount
                                })}
                            />
                        </div>
                    </FormGroup>}
                    {formData.isManualSelect && <FormGroup className="mt-2">
                        <Label>
                            Select questions to test
                            <span className="color-danger">*</span>
                        </Label>

                        <Select
                            isDisabled={isViewDetail}
                            value={null}
                            placeholder="Search question by Id(at least 4 digits)"
                            isClearable
                            options={questionToTestOptions}
                            components={{ Option }}
                            onChange={e => {
                                if (!e) return
                                setQuestionToTestOptions([])
                                if (testQuestions.map(tq => tq.value).includes(e.value)) return
                                setTestQuestions([...testQuestions, e])
                            }}
                            isLoading={isFetching}
                            onKeyDown={e => {
                                if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || [37, 39, 8, 46].includes(e.keyCode)) { // 0-9
                                    return
                                }
                                e.preventDefault();
                            }}
                            onInputChange={e => {
                                console.log(e)
                                setPartOfQuestionId(e)
                            }}
                        />
                        {testQuestions.length ? <Table
                            hover
                            bordered
                            striped
                            responsive
                            className="grid-table mb-2 mt-2"
                        >
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Question</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {testQuestions.map(tq => {
                                    return <tr key={tq.value}>
                                        <td>{tq.value}</td>
                                        <td><div dangerouslySetInnerHTML={{ __html: tq.label }} /></td>
                                        <td className="text-center">
                                            <i
                                                onClick={() => {
                                                    //handleDelete(e.id)
                                                    setTestQuestions([...testQuestions.filter(e => e.value !== tq.value)])
                                                }}
                                                className="fa fa-trash mr-2"
                                                aria-hidden="true"
                                                style={{
                                                    cursor: "pointer"
                                                }}
                                            />
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table> : enableShowError ?
                            <span className="color-danger d-block mt-1">
                                Please select at least one question
                            </span> : null
                        }
                    </FormGroup>}
                </>
                )}
            </ModalBody>

            {!isViewDetail && <ModalFooter>
                <Button
                    style={{ width: "100px" }}
                    color="secondary"
                    onClick={toggle}
                >
                    {t("Cancel")}
                </Button>
                <Button
                    style={{ width: "100px" }}
                    color="success"
                    onClick={handleConfirm}
                >
                    {t("Confirm")}
                </Button>
            </ModalFooter>}

            {isViewDetail && <ModalFooter>
                <Button
                    style={{ width: "100px" }}
                    color="secondary"
                    onClick={toggle}
                >
                    Close
                </Button>
                <Button
                    style={{ width: "100px" }}
                    color="success"
                    onClick={handleConfirm}
                >
                    {t("Confirm")}
                </Button>
            </ModalFooter>}
        </Modal>
    );
};

export default CreateTaskDialog;
