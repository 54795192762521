import React from "react";
import { CardBody, Col, Row, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { Route } from "react-router-dom";
import { withTranslation } from 'react-i18next';
// import UsersList from "../userListContainer";
import UsersList from '../UserList';
import Page404 from "../../../components/Page404";
import UserList from "../UserList";
import GroupsList from "../GroupsList";
import SubjectList from "../SubjectList";
import QuestionReminderList from "../QuestionReminderList";

class home extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.tabPaths = ["/user-family/users", "/user-family/families", "/user-family/groups", "/user-family/subjects", "/user-family/question-reminder"];
    }

    toggle(tab) {
        this.props.history.push(this.tabPaths[tab]);
    }

    componentDidMount = () => {
        document.title = this.props.t("elearning")
    }

    renderContent = () => {
        const { t, location } = this.props;
        const { pathname } = location;
        const activeTab = this.tabPaths.indexOf(pathname) + 1;
        if (activeTab === 0) {
            return <Page404 />
        }
        return (
            <Row>
                <Col xl={12}>
                    <div className="header-wrapper">
                        <strong className="table-header-label">
                            {pathname.includes("groups") ? t('Group list') : pathname.includes("subjects") ? t('Subject list') : pathname.includes("users") ? t('User list') : t('Question Reminder List')}
                        </strong>
                    </div>

                    <CardBody className="grid-body reduced-padding-grid">
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === 1 })}
                                    onClick={() => {
                                        this.toggle(0);
                                    }}
                                >
                                    {t('users')}
                                </NavLink>
                            </NavItem>
                            {/* <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === 2 })}
                                    onClick={() => {
                                        this.toggle(1);
                                    }}
                                >
                                    {t('families')}
                                </NavLink>
                            </NavItem> */}
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === 3 })}
                                    onClick={() => {
                                        this.toggle(2);
                                    }}
                                >
                                    {t('Groups')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === 4 })}
                                    onClick={() => {
                                        this.toggle(3);
                                    }}
                                >
                                    {t('Subject')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === 5 })}
                                    onClick={() => {
                                        this.toggle(4);
                                    }}
                                >
                                    {t('Question Reminder')}
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab.toString()} className="border-bottom-0 border-left-0 border-right-0 reduced-padding-tabcontent">
                            <TabPane tabId="1">
                                <Route
                                    path={this.tabPaths[0]}
                                    exact
                                    name="user-list"
                                    {...this.props}
                                    component={UsersList}
                                />
                            </TabPane>
                            {/* <TabPane tabId="2">
                                <Route
                                    path={this.tabPaths[1]}
                                    exact
                                    name="family-list"
                                    {...this.props}
                                    component={FamiliesList}
                                />
                            </TabPane> */}
                            <TabPane tabId="3">
                                <Route
                                    path={this.tabPaths[2]}
                                    exact
                                    name="groups-list"
                                    {...this.props}
                                    component={GroupsList}
                                />
                            </TabPane>
                            <TabPane tabId="4">
                                <Route
                                    path={this.tabPaths[3]}
                                    exact
                                    name="subject-list"
                                    {...this.props}
                                    component={SubjectList}
                                />
                            </TabPane>
                            <TabPane tabId="5">
                                <Route
                                    path={this.tabPaths[4]}
                                    exact
                                    name="reminder-list"
                                    {...this.props}
                                    component={QuestionReminderList}
                                />
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Col>
            </Row>
        )
    }

    render() {
        return (
            <div className="animated fadeIn">
                {this.renderContent()}
            </div>
        );
    }
}
export default withTranslation('common')(home);