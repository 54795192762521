import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { getAllUser, removeUser } from "../../util/api";
import useFetch from "../../hooks/useFetch";
import CreateBtn from "../../components/Button/CreateBtn";
import CustomPagination from "../../components/CustomPagination";
import SearchBox from "../../components/SearchBox";
import { sortAlphabet } from "../../util/Common";
import setAlert from "../../components/Alert/action";

const UserList = () => {
    const history = useHistory();
    const { data: users, reload: refetchUser } = useFetch(getAllUser);

    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const ipSearch = useRef("");
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState(null); // 0 | 1
    const [modal, setModal] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const dispatch = useDispatch();

    const toggle = () => setModal(!modal);

    useEffect(() => {
        if (!dataTable) return;

        if (currentPage > Math.ceil(dataTable.length / pageSize)) {
            setCurrentPage(1);
        }
    }, [pageSize, users]);
    
    const dataTable = sortAlphabet(
        users
            ? users
                  .filter(e => {
                      if (status !== null) {
                          return e.isActive === !!status;
                      }

                      return e;
                  })
                  .filter(e => {
                      return (
                          `${e.firstName} ${e.lastName}`.includes(
                              search.trim()
                          ) || e.email.includes(search.trim())
                      );
                  })
            : [],
        "firstName"
    );

    const openModalConfirm = (e) => {
        setCurrentUser(e);
        toggle();
    }

    const confirmDeleteUser = async () => {
        try{
            // call api
            await removeUser(currentUser.id)
            dispatch(
                setAlert({
                    type: "success",
                    message: "Delete User successfully"
                })
            );
            toggle();
            refetchUser();
        }
        catch(err){
            toggle();
            dispatch(
                setAlert({
                    type: "danger",
                    message: "Delete failed"
                })
            );
        }
    }


    const groupName = "";

    return (
        <div>
            <div className="row">
                <div className="col-4 d-flex">
                    <CreateBtn
                        title="Create user"
                        onClick={() => history.push("/user/create-user")}
                        style={{
                            width: "100px"
                        }}
                    />

                    <div style={{ marginRight: "30px" }} />

                    <Input
                        placeholder="Search"
                        onChange={e => {
                            ipSearch.current = e.target.value;
                        }}
                        onKeyDown={e => {
                            if (e.key === "Enter") {
                                setSearch(ipSearch.current);
                            }
                        }}
                        style={{
                            flex: 1
                        }}
                    />
                </div>
                <div className="col-2">
                    <Select
                        isClearable
                        placeholder="Select status"
                        options={[
                            { value: 1, label: "Active" },
                            { value: 0, label: "Non active" }
                        ]}
                        onChange={e => {
                            if (!e) {
                                setStatus(null);
                                return;
                            }
                            setStatus(e.value);
                        }}
                    />
                </div>
            </div>

            <Table
                id="table-questions"
                hover
                bordered
                striped
                responsive
                className="grid-table mb-2 mt-2"
            >
                <thead>
                    <tr>
                        <th style={{ width: "15%" }}>Name</th>
                        <th style={{ width: "20%" }}>Email</th>
                        <th style={{ width: "10%" }}>Phone number</th>
                        <th style={{ width: "15%" }}>Roles</th>
                        <th style={{ width: "10%" }}>Gender</th>
                        <th style={{ width: "10%" }}>Status</th>
                        <th style={{ width: "10%"}}>Groups</th>
                        <th style={{ width: "10%", textAlign: "center" }}>
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {dataTable
                        ? dataTable
                              .slice(
                                  (currentPage - 1) * pageSize,
                                  currentPage * pageSize
                              )
                              .map((e, index) => (
                                  <tr key={index}>
                                      <td>{`${e.firstName || ''} ${e.lastName || ''}`}</td>
                                      <td>{e.email}</td>
                                      <td>{e.phoneNumber}</td>
                                      <td>{e.roles.join(", ")}</td>
                                      <td>
                                          {+e.gender === 0
                                              ? "Unknow"
                                              : +e.gender === 1
                                              ? "Male"
                                              : "Female"}
                                      </td>
                                      <td>
                                          {e.isActive ? "Active" : "Non active"}
                                      </td>
                                      <td>
                                           {e.groups.map(i => i.name).join(", ")}
                                      </td>
                                      <td className="text-center">
                                          <i
                                              style={{
                                                  cursor: "pointer"
                                              }}
                                              onClick={() => {
                                                  history.push({
                                                      pathname: `/user/edit-user/${e.id}`,
                                                      data: e
                                                  });
                                              }}
                                              className="fa fa-pencil mr-2"
                                              aria-hidden="true"
                                          ></i>

                                        <i
                                            style={{
                                                cursor: "pointer"
                                            }}
                                            onClick={() => openModalConfirm(e)}
                                            className="fa fa-trash mr-2"
                                            aria-hidden="true"
                                        ></i>
                                      </td>
                                  </tr>
                              ))
                        : ""}
                </tbody>
            </Table>

            <Modal isOpen={modal} toggle={toggle} centered>
                <ModalHeader toggle={toggle}>Confirm delete</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete 
                    <b> {currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : 'this user'} </b>
                    ?
                </ModalBody>
                <ModalFooter>
                <Button color="primary" onClick={confirmDeleteUser}>
                    Confirm
                </Button>{' '}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
                </ModalFooter>
            </Modal>

            <CustomPagination
                pageCount={
                    dataTable ? Math.ceil(dataTable.length / pageSize) : 0
                }
                pageSize={pageSize}
                currentPage={currentPage}
                // pageSizeName={}
                changePage={e => {
                    setCurrentPage(e);
                }}
                changePageSize={e => {
                    setPageSize(e);
                }}
                onChangePageSize={() => {}}
            />
        </div>
    );
};

export default UserList;
