import moment from "moment";

export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const EXPIRE_TIME = "EXPIRE_TIME";
export const USER_EMAIL = "USER_EMAIL";
export const EXPIRE_TIME_DEFAULT = 24 * 60 * 60 * 1000;
export const API_STATUS = {
    IN_PROGRESS: "IN_PROGRESS",
    DONE: "DONE",
    ERROR: "ERROR"
};
export const SERVER_DATE_FORMAT = "YYYY-MM-DD";
export const SERVER_HOUR_MINUTE_FORMAT = "HH:mm";

export const MAIL_CATE_GENERAL = 1;
export const MAIL_CATE_COURSE_INFO = 2;
export const MAIL_CATE_INVOICE = 3;
export const MAIL_CATE_NEW_USER = 4;
export const TEACHER_SALARY_EMAIL = 5;

export const EXPIRE_TIME_IN_DAYS_INVOICE = 3;
export const NUMBER_ANSWEARS_THINKING_SKILLS = 4;
export const NUMBER_ANSWEARS_MATHEMATICAL_REASONING = 5;
export const NUMBER_ANSWEARS_READING = 4;


export const DOCUMENT_TITLE = {
    dashboard: "dashboard_lower",
    class_missing_reports: "class_missing_reports",
    report_absence_of_teacher: "report_absence_of_teacher",
    report_absence_of_student_by_course: "report_absence_of_student_by_course",
    report_absence_of_student_by_list: "report_absence_of_student_by_list",

    users_list: "users_list",
    edit_user: "edit_user",
    create_user: "create_user",

    courses_list: "courses_list",
    edit_course: "edit_course",
    create_course: "create_course",

    families_list: "families_list",
    edit_family: "edit_family",
    create_family: "create_family",

    programs_list: "Programs list",
    edit_program: "edit_program",
    create_program: "create_program",

    send_mail: "send_mail",
    mail_template: "mail_template",
    create_mail_template: "create_mail_template",
    edit_mail_template: "edit_mail_template",

    schema: "schema",

    imported_student_list: "imported_student_list",

    teacher_skills: "teacher_skills",
    course_types: "course_types",
    extra_salary_category: "extra_salary_category",

    studios: "studios",
    holidays: "holidays",
    admin_and_staff: "admin_and_staff",

    teacher_salaries: "teacher_salaries",

    payment: "payment_title",

    page_404: "page_404",
    set_up_other: "set_up_other",
    old_imported_students: "old_imported_students",
    waiting_course: "waiting_course_list",
    create_waiting_course: "create_waiting_course"
};

export const ALERT = {
    quit_but_not_saved:
        "You have some changes. Click OK to discard or click Cancel to continue edit, then click Update below to save your changes."
};

export const semesterOptions = [
    { label: "VT", value: "VT" },
    { label: "HT", value: "HT" }
];
export const roles = [
    { value: "all", label: "All" },
    { value: "student", label: "Student" },
    { value: "teacher", label: "Teacher" }
];
export const removeUserRoles = [
    { value: "student", label: "Student" },
    { value: "teacher", label: "Teacher" },
    { value: "assistance", label: "Assistance" }
];
export const adminStaffRols = [
    { value: "admin", label: "Admin" },
    { value: "staff", label: "Staff" },
    { value: "developer", label: "Developer" }
];
export const activeOptions = t => [
    { label: t("non_active"), value: 0 },
    { label: t("active"), value: 1 }
];
export const paymentOptions = t => [
    { label: t("all"), value: 0 },
    { label: t("yes"), value: 1 },
    { label: t("no"), value: 2 }
];
export const semester =
    moment().format("MM/DD") >= moment("8/1", "MM/DD").format("MM/DD")
        ? () => ({ value: 2, label: localStorage.getItem("semester2") })
        : () => ({ value: 1, label: localStorage.getItem("semester1") });

// export const langOptions = [{ label: "English", value: "en" }, { label: "Swedish", value: "se" }]
export const langOptions = [{ label: "English", value: "en" }];

export const optionImport = {
    lines: [
        { id: 1, name: "ballet_line", swe: "Ballet line" },
        { id: 2, name: "dancing_line", swe: "Danslinje" },
        { id: 3, name: "musical_line", swe: "Musical line" },
        { id: 4, name: "street_line", swe: "Street line" }
    ],
    courses: [
        { id: 1, name: "base_kids", swe: "BaseKids (3-10 år)" },
        { id: 2, name: "ballet", swe: "Balett" },
        { id: 3, name: "jazz", swe: "Jazz" },
        { id: 4, name: "modern", swe: "Modern" },
        { id: 5, name: "tap", swe: "Tapp" },
        { id: 6, name: "street", swe: "Street" }
    ],
    others: [
        { id: 1, name: "open_classes", swe: "Öppna klasser (drop-in)" },
        { id: 2, name: "dance_camp", swe: "Dance camp / Early summner course" }
    ],
    trainings: [
        { id: 1, name: "day_base", swe: "DayBase" },
        { id: 2, name: "alhanko", swe: "Alhanko Academy of Ballet" }
    ],
    levels: [
        { id: 1, name: "beginner", swe: "Nybörjare" },
        {
            id: 2,
            name: "conditinuation",
            swe: "Conditinuation (dansat i några år)"
        },
        {
            id: 3,
            name: "between_dance",
            swe: "Mellan (dansade flera gånger i veckan i flera år)"
        },
        {
            id: 4,
            name: "advanced_dance",
            swe:
                "Advanced (dansar professionellt, går till en yrkesutbildning eller annan avancerad linje)"
        }
    ]
};

export const termOptions = [
    { label: "10", value: 10 },
    { label: "30", value: 30 },
    { label: "60", value: 60 },
    { label: "90", value: 90 },
    { label: "120", value: 120 },
    { label: "150", value: 150 },
    { label: "180", value: 180 },
    { label: "210", value: 210 },
    { label: "240", value: 240 },
    { label: "270", value: 270 },
    { label: "300", value: 300 },
    { label: "330", value: 330 },
    { label: "360", value: 360 }
];

export const QUESTION_TYPE = {
    SINGLE_CHOICE: "SingleChoice",
    MULTIPLE_CHOICE: "MultipleChoice",
    EXTENDED_RESPONSE: "ExtendedResponse",
    SHORT_ANSWER: "ShortAnswer",
    MATRIX_CHECKBOX: "MatrixCheckbox",
    MATRIX_RADIO: "MatrixRadio",
    COMPOSITE: "Composite",
    FILL_IN_THE_BLANK_TEXT: "FillInTheBlankText",
    FILL_IN_THE_BLANK_DROPDOWN: "FillInTheBlankDropdown",
    HOT_TEXT: "HotText",
    HOTSPOT: "HotSpot",
    INLINE_ORDER_DRAG_DROP: "InlineOrderDragDrop",
    MATCH_THE_PAIRS: "MatchThePairs",
    FILL_IN_BLANK_DRAG_DROP: "FillInTheBlankDragDrop",
    HOTSPOT_DRAG_DROP: "HotSpotDragDrop",
    DROPZONE_DRAG_DROP: "DropZoneDragDrop"
    // CALCULATED_NUMERIC: "CalculatedNumeric",
    // ORDERING: "Ordering",
    // VIDEO_RECORDING: "VideoRecording",
    // PROGRAMMING: "Programming"
};

export const QUESTION_TYPE_NUM = {
    SINGLE_CHOICE: 0,
    MULTIPLE_CHOICE: 1
};

export const questionTypes = [
    { name: "Single Choice", value: "SingleChoice" },
    { name: "Multiple Choice", value: "MultipleChoice" },
    { name: "Extended Response", value: "ExtendedResponse" },
    { name: "Short Answer", value: "ShortAnswer" },
    { name: "Matrix Checkbox", value: "MatrixCheckbox" },
    { name: "Matrix Radio", value: "MatrixRadio" },
    { name: "Composite", value: "Composite" },
    { name: "Fill In The Blank Text", value: "FillInTheBlankText" },
    { name: "Fill In The Blank Dropdown", value: "FillInTheBlankDropdown" },
    { name: "Hot Text", value: "HotText" },
    { name: "HotSpot", value: "HotSpot" },
    { name: "Inline Order Drag Drop", value: "InlineOrderDragDrop" },
    { name: "Match The Pairs", value: "MatchThePairs" },
    { name: "Fill In The Blank Drag Drop", value: "FillInTheBlankDragDrop" },
    { name: "HotSpot Drag Drop", value: "HotSpotDragDrop" },
    { name: "DropZone Drag Drop", value: "DropZoneDragDrop" }
    // { name: "Calculated Numeric", value: "CalculatedNumeric" },
    // { name: "Ordering", value: "Ordering" },
    // { name: "Video Recording", value: "VideoRecording" },
    // { name: "Programming", value: "Programming" }
];

export const gradeOptions = Array(12)
    .fill("")
    .map((item, index) => ({ label: index + 1, value: index + 1 }));

export const cities = [
    {
        city: "Sydney",
        lat: "-33.8650",
        lng: "151.2094",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "admin",
        population: "5312163",
        population_proper: "4840600"
    },
    {
        city: "Melbourne",
        lat: "-37.8136",
        lng: "144.9631",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "admin",
        population: "5078193",
        population_proper: "4529500"
    },
    {
        city: "Brisbane",
        lat: "-27.4678",
        lng: "153.0281",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "admin",
        population: "2514184",
        population_proper: "2360241"
    },
    {
        city: "Perth",
        lat: "-31.9522",
        lng: "115.8589",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "admin",
        population: "2059484",
        population_proper: "2039200"
    },
    {
        city: "Adelaide",
        lat: "-34.9289",
        lng: "138.6011",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "admin",
        population: "1345777",
        population_proper: "1295714"
    },
    {
        city: "Gold Coast",
        lat: "-28.0167",
        lng: "153.4000",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "679127",
        population_proper: "638090"
    },
    {
        city: "Cranbourne",
        lat: "-38.0996",
        lng: "145.2834",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "460491",
        population_proper: "39419"
    },
    {
        city: "Canberra",
        lat: "-35.2931",
        lng: "149.1269",
        country: "Australia",
        iso2: "AU",
        admin_name: "Australian Capital Territory",
        capital: "primary",
        population: "426704",
        population_proper: "381488"
    },
    {
        city: "Newcastle",
        lat: "-32.9167",
        lng: "151.7500",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "322278",
        population_proper: "152984"
    },
    {
        city: "Wollongong",
        lat: "-34.4331",
        lng: "150.8831",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "302739",
        population_proper: "289236"
    },
    {
        city: "Geelong",
        lat: "-38.1500",
        lng: "144.3500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "263280",
        population_proper: "191440"
    },
    {
        city: "Hobart",
        lat: "-42.8806",
        lng: "147.3250",
        country: "Australia",
        iso2: "AU",
        admin_name: "Tasmania",
        capital: "admin",
        population: "240342",
        population_proper: "222356"
    },
    {
        city: "Townsville",
        lat: "-19.2564",
        lng: "146.8183",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "180820",
        population_proper: "178649"
    },
    {
        city: "Ipswich",
        lat: "-27.6167",
        lng: "152.7667",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "163000",
        population_proper: "163000"
    },
    {
        city: "Cairns",
        lat: "-16.9303",
        lng: "145.7703",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "152729",
        population_proper: "146778"
    },
    {
        city: "Toowoomba",
        lat: "-27.5667",
        lng: "151.9500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "136861",
        population_proper: "135631"
    },
    {
        city: "Darwin",
        lat: "-12.4381",
        lng: "130.8411",
        country: "Australia",
        iso2: "AU",
        admin_name: "Northern Territory",
        capital: "admin",
        population: "136828",
        population_proper: "136828"
    },
    {
        city: "Ballarat",
        lat: "-37.5500",
        lng: "143.8500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "105471",
        population_proper: "98543"
    },
    {
        city: "Bendigo",
        lat: "-36.7500",
        lng: "144.2667",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "99122",
        population_proper: "95587"
    },
    {
        city: "Launceston",
        lat: "-41.4419",
        lng: "147.1450",
        country: "Australia",
        iso2: "AU",
        admin_name: "Tasmania",
        capital: "",
        population: "87328",
        population_proper: "87328"
    },
    {
        city: "Mackay",
        lat: "-21.1411",
        lng: "149.1861",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "80148",
        population_proper: "3659"
    },
    {
        city: "Rockhampton",
        lat: "-23.3750",
        lng: "150.5117",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "78592",
        population_proper: "78592"
    },
    {
        city: "Maitland",
        lat: "-32.7167",
        lng: "151.5500",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "78015",
        population_proper: "1885"
    },
    {
        city: "Bunbury",
        lat: "-33.3333",
        lng: "115.6333",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "74363",
        population_proper: "71090"
    },
    {
        city: "Coffs Harbour",
        lat: "-30.3022",
        lng: "153.1189",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "71822",
        population_proper: "25752"
    },
    {
        city: "Bundaberg",
        lat: "-24.8500",
        lng: "152.3500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "70921",
        population_proper: "70540"
    },
    {
        city: "Wagga Wagga",
        lat: "-35.1189",
        lng: "147.3689",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "56442",
        population_proper: "54411"
    },
    {
        city: "Hervey Bay",
        lat: "-25.2853",
        lng: "152.8728",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "54674",
        population_proper: "54674"
    },
    {
        city: "Shepparton",
        lat: "-36.3833",
        lng: "145.4000",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "51631",
        population_proper: "31197"
    },
    {
        city: "Albury",
        lat: "-36.0806",
        lng: "146.9158",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "47974",
        population_proper: "4804"
    },
    {
        city: "Port Macquarie",
        lat: "-31.4333",
        lng: "152.9000",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "47973",
        population_proper: "44830"
    },
    {
        city: "Pakenham",
        lat: "-38.0712",
        lng: "145.4878",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "46421",
        population_proper: "46421"
    },
    {
        city: "Tamworth",
        lat: "-31.0833",
        lng: "150.9167",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "42872",
        population_proper: "180"
    },
    {
        city: "Caloundra",
        lat: "-26.7986",
        lng: "153.1289",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "41293",
        population_proper: "41293"
    },
    {
        city: "Orange",
        lat: "-33.2833",
        lng: "149.1000",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "40493",
        population_proper: "38097"
    },
    {
        city: "Dubbo",
        lat: "-32.2569",
        lng: "148.6011",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "38392",
        population_proper: "38392"
    },
    {
        city: "Geraldton",
        lat: "-28.7744",
        lng: "114.6089",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "37648",
        population_proper: "37648"
    },
    {
        city: "Nowra",
        lat: "-34.8833",
        lng: "150.6000",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "37420",
        population_proper: "9193"
    },
    {
        city: "Bathurst",
        lat: "-33.4200",
        lng: "149.5778",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "36801",
        population_proper: "35020"
    },
    {
        city: "Queanbeyan",
        lat: "-35.3533",
        lng: "149.2342",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "36348",
        population_proper: "6237"
    },
    {
        city: "Frankston",
        lat: "-38.1580",
        lng: "145.1350",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "36097",
        population_proper: "36097"
    },
    {
        city: "Sunbury",
        lat: "-37.5811",
        lng: "144.7139",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "36084",
        population_proper: "36084"
    },
    {
        city: "Warrnambool",
        lat: "-38.3833",
        lng: "142.4833",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "35214",
        population_proper: "29661"
    },
    {
        city: "Wodonga",
        lat: "-36.1214",
        lng: "146.8881",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "35130",
        population_proper: "18948"
    },
    {
        city: "Albany",
        lat: "-35.0228",
        lng: "117.8814",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "34205",
        population_proper: "29373"
    },
    {
        city: "Gladstone",
        lat: "-23.8489",
        lng: "151.2625",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "33418",
        population_proper: "33418"
    },
    {
        city: "Mildura",
        lat: "-34.1889",
        lng: "142.1583",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "32738",
        population_proper: "32738"
    },
    {
        city: "Southport",
        lat: "-27.9667",
        lng: "153.4000",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "31908",
        population_proper: "31908"
    },
    {
        city: "Alice Springs",
        lat: "-23.7000",
        lng: "133.8667",
        country: "Australia",
        iso2: "AU",
        admin_name: "Northern Territory",
        capital: "",
        population: "31120",
        population_proper: "31120"
    },
    {
        city: "Dandenong",
        lat: "-37.9810",
        lng: "145.2150",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "29906",
        population_proper: "29906"
    },
    {
        city: "Kalgoorlie",
        lat: "-30.7489",
        lng: "121.4658",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "29849",
        population_proper: "29849"
    },
    {
        city: "Mount Gambier",
        lat: "-37.8294",
        lng: "140.7828",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "29639",
        population_proper: "25512"
    },
    {
        city: "Lismore",
        lat: "-28.8167",
        lng: "153.2833",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "28720",
        population_proper: "3579"
    },
    {
        city: "Maryborough",
        lat: "-25.5375",
        lng: "152.7019",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "27282",
        population_proper: "15406"
    },
    {
        city: "Quakers Hill",
        lat: "-33.7344",
        lng: "150.8789",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "27080",
        population_proper: "27080"
    },
    {
        city: "Gawler",
        lat: "-34.5981",
        lng: "138.7450",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "26472",
        population_proper: "942"
    },
    {
        city: "Caboolture",
        lat: "-27.0667",
        lng: "152.9670",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "26433",
        population_proper: "26433"
    },
    {
        city: "Ballina",
        lat: "-28.8333",
        lng: "153.5333",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "26381",
        population_proper: "8655"
    },
    {
        city: "Taree",
        lat: "-31.9000",
        lng: "152.4500",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "26381",
        population_proper: "16197"
    },
    {
        city: "Traralgon",
        lat: "-38.1958",
        lng: "146.5403",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "25485",
        population_proper: "24933"
    },
    {
        city: "Busselton",
        lat: "-33.6478",
        lng: "115.3458",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "25329",
        population_proper: "25329"
    },
    {
        city: "Armidale",
        lat: "-30.5000",
        lng: "151.6500",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "24504",
        population_proper: "24504"
    },
    {
        city: "Goulburn",
        lat: "-34.7547",
        lng: "149.6186",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "23835",
        population_proper: "22419"
    },
    {
        city: "Devonport",
        lat: "-41.1806",
        lng: "146.3464",
        country: "Australia",
        iso2: "AU",
        admin_name: "Tasmania",
        capital: "",
        population: "23046",
        population_proper: "23046"
    },
    {
        city: "Bacchus Marsh",
        lat: "-37.6750",
        lng: "144.4389",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "22223",
        population_proper: "6394"
    },
    {
        city: "Whyalla",
        lat: "-33.0333",
        lng: "137.5667",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "21742",
        population_proper: "21742"
    },
    {
        city: "Cessnock",
        lat: "-32.8342",
        lng: "151.3555",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "21725",
        population_proper: "15177"
    },
    {
        city: "Gympie",
        lat: "-26.1900",
        lng: "152.6655",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "21599",
        population_proper: "10803"
    },
    {
        city: "Griffith",
        lat: "-34.2900",
        lng: "146.0400",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "20251",
        population_proper: "18196"
    },
    {
        city: "Kwinana",
        lat: "-32.2394",
        lng: "115.7702",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "20086",
        population_proper: "17549"
    },
    {
        city: "Burnie",
        lat: "-41.0636",
        lng: "145.8753",
        country: "Australia",
        iso2: "AU",
        admin_name: "Tasmania",
        capital: "",
        population: "19385",
        population_proper: "19385"
    },
    {
        city: "Wangaratta",
        lat: "-36.3583",
        lng: "146.3125",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "19318",
        population_proper: "17377"
    },
    {
        city: "Grafton",
        lat: "-29.6833",
        lng: "152.9333",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "19078",
        population_proper: "10408"
    },
    {
        city: "Yeppoon",
        lat: "-23.1333",
        lng: "150.7333",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "19003",
        population_proper: "6334"
    },
    {
        city: "Murray Bridge",
        lat: "-35.1170",
        lng: "139.2670",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "18779",
        population_proper: "14560"
    },
    {
        city: "Mount Isa",
        lat: "-20.7333",
        lng: "139.5000",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "18588",
        population_proper: "118"
    },
    {
        city: "Narangba",
        lat: "-27.2015",
        lng: "152.9655",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "18573",
        population_proper: "18573"
    },
    {
        city: "Mount Eliza",
        lat: "-38.1890",
        lng: "145.0920",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "17888",
        population_proper: "17888"
    },
    {
        city: "Broken Hill",
        lat: "-31.9567",
        lng: "141.4678",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "17734",
        population_proper: "17734"
    },
    {
        city: "Nerang",
        lat: "-27.9956",
        lng: "153.3220",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "16864",
        population_proper: "16864"
    },
    {
        city: "Karratha",
        lat: "-20.7364",
        lng: "116.8464",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "16708",
        population_proper: "16708"
    },
    {
        city: "Mount Barker",
        lat: "-35.0667",
        lng: "138.8500",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "16629",
        population_proper: "13842"
    },
    {
        city: "Horsham",
        lat: "-36.7167",
        lng: "142.2000",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "16514",
        population_proper: "14543"
    },
    {
        city: "Lara",
        lat: "-38.0167",
        lng: "144.4167",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "16355",
        population_proper: "16355"
    },
    {
        city: "Singleton",
        lat: "-32.5667",
        lng: "151.1697",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "16346",
        population_proper: "5000"
    },
    {
        city: "Port Lincoln",
        lat: "-34.7322",
        lng: "135.8586",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "16326",
        population_proper: "14120"
    },
    {
        city: "Banora Point",
        lat: "-28.2225",
        lng: "153.5386",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "16167",
        population_proper: "16167"
    },
    {
        city: "Warwick",
        lat: "-28.2152",
        lng: "152.0352",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "15380",
        population_proper: "12222"
    },
    {
        city: "Rochedale",
        lat: "-27.5952",
        lng: "153.1233",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "15317",
        population_proper: "15317"
    },
    {
        city: "Kempsey",
        lat: "-31.0833",
        lng: "152.8333",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "15309",
        population_proper: "474"
    },
    {
        city: "Ulladulla",
        lat: "-35.3486",
        lng: "150.4678",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "15278",
        population_proper: "15278"
    },
    {
        city: "Sale",
        lat: "-38.1000",
        lng: "147.0667",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "15135",
        population_proper: "13673"
    },
    {
        city: "Echuca",
        lat: "-36.1333",
        lng: "144.7500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "14934",
        population_proper: "14043"
    },
    {
        city: "Australind",
        lat: "-33.2800",
        lng: "115.7260",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "14539",
        population_proper: "14539"
    },
    {
        city: "Broome",
        lat: "-17.9619",
        lng: "122.2361",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "14445",
        population_proper: "4042"
    },
    {
        city: "Port Hedland",
        lat: "-20.3100",
        lng: "118.6011",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "14320",
        population_proper: "4180"
    },
    {
        city: "Warragul",
        lat: "-38.1500",
        lng: "145.9333",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "14276",
        population_proper: "14276"
    },
    {
        city: "Port Pirie",
        lat: "-33.1858",
        lng: "138.0169",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "14188",
        population_proper: "195"
    },
    {
        city: "Barwon Heads",
        lat: "-38.2500",
        lng: "144.5167",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "14165",
        population_proper: "14165"
    },
    {
        city: "Emerald",
        lat: "-23.5167",
        lng: "148.1500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "14119",
        population_proper: "14119"
    },
    {
        city: "Ulverstone",
        lat: "-41.1667",
        lng: "146.1667",
        country: "Australia",
        iso2: "AU",
        admin_name: "Tasmania",
        capital: "",
        population: "14109",
        population_proper: "14109"
    },
    {
        city: "Morwell",
        lat: "-38.2333",
        lng: "146.4000",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "14026",
        population_proper: "13771"
    },
    {
        city: "Gisborne",
        lat: "-37.4900",
        lng: "144.5889",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "13963",
        population_proper: "8999"
    },
    {
        city: "Port Augusta",
        lat: "-32.4925",
        lng: "137.7658",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "13799",
        population_proper: "6562"
    },
    {
        city: "Forster",
        lat: "-32.1806",
        lng: "152.5117",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "13740",
        population_proper: "13740"
    },
    {
        city: "Penrith",
        lat: "-33.7511",
        lng: "150.6942",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "13295",
        population_proper: "13295"
    },
    {
        city: "Torquay",
        lat: "-38.3333",
        lng: "144.3167",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "13258",
        population_proper: "13258"
    },
    {
        city: "Jimboomba",
        lat: "-27.8333",
        lng: "153.0333",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "13201",
        population_proper: "13201"
    },
    {
        city: "Lithgow",
        lat: "-33.4833",
        lng: "150.1500",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "12973",
        population_proper: "5201"
    },
    {
        city: "Bowral",
        lat: "-34.4792",
        lng: "150.4181",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "12949",
        population_proper: "10335"
    },
    {
        city: "Goonellabah",
        lat: "-28.8150",
        lng: "153.3178",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "12900",
        population_proper: "12900"
    },
    {
        city: "Raymond Terrace",
        lat: "-32.7615",
        lng: "151.7441",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "12820",
        population_proper: "12820"
    },
    {
        city: "Leopold",
        lat: "-38.1892",
        lng: "144.4644",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "12814",
        population_proper: "12814"
    },
    {
        city: "Dalby",
        lat: "-27.1833",
        lng: "151.2667",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "12719",
        population_proper: "12719"
    },
    {
        city: "Campbelltown",
        lat: "-34.0733",
        lng: "150.8261",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "12566",
        population_proper: "12566"
    },
    {
        city: "Colac",
        lat: "-38.3403",
        lng: "143.5847",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "12547",
        population_proper: "9048"
    },
    {
        city: "Muswellbrook",
        lat: "-32.2654",
        lng: "150.8885",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "12364",
        population_proper: "12075"
    },
    {
        city: "Esperance",
        lat: "-33.8611",
        lng: "121.8919",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "12145",
        population_proper: "2144"
    },
    {
        city: "Drouin",
        lat: "-38.1333",
        lng: "145.8500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "11887",
        population_proper: "11887"
    },
    {
        city: "Rutherford",
        lat: "-32.7150",
        lng: "151.5317",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "11884",
        population_proper: "11884"
    },
    {
        city: "Nelson Bay",
        lat: "-32.7150",
        lng: "152.1511",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "11820",
        population_proper: "11820"
    },
    {
        city: "Inverell",
        lat: "-29.7667",
        lng: "151.1167",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "11660",
        population_proper: "11660"
    },
    {
        city: "Parkes",
        lat: "-33.0950",
        lng: "148.2628",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "11408",
        population_proper: "11408"
    },
    {
        city: "Somerville",
        lat: "-38.2260",
        lng: "145.1770",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "11336",
        population_proper: "11336"
    },
    {
        city: "Gracemere",
        lat: "-23.4333",
        lng: "150.4500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "11315",
        population_proper: "11315"
    },
    {
        city: "Batemans Bay",
        lat: "-35.7081",
        lng: "150.1744",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "11294",
        population_proper: "1530"
    },
    {
        city: "Nambour",
        lat: "-26.6269",
        lng: "152.9592",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "11187",
        population_proper: "11187"
    },
    {
        city: "Swan Hill",
        lat: "-35.3333",
        lng: "143.5500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "11103",
        population_proper: "9864"
    },
    {
        city: "Mareeba",
        lat: "-16.9833",
        lng: "145.4167",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "11079",
        population_proper: "11079"
    },
    {
        city: "Corinda",
        lat: "-27.4833",
        lng: "153.1000",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "11019",
        population_proper: "11019"
    },
    {
        city: "Samford",
        lat: "-27.3667",
        lng: "152.8833",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "10931",
        population_proper: "796"
    },
    {
        city: "Mudgee",
        lat: "-32.6125",
        lng: "149.5872",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "10923",
        population_proper: "10923"
    },
    {
        city: "Casino",
        lat: "-28.8483",
        lng: "153.0511",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "10914",
        population_proper: "10914"
    },
    {
        city: "Portland",
        lat: "-38.3333",
        lng: "141.6000",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "10900",
        population_proper: "9712"
    },
    {
        city: "Kingston",
        lat: "-42.9769",
        lng: "147.3083",
        country: "Australia",
        iso2: "AU",
        admin_name: "Tasmania",
        capital: "",
        population: "10409",
        population_proper: "10409"
    },
    {
        city: "Kingaroy",
        lat: "-26.5333",
        lng: "151.8333",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "10398",
        population_proper: "10020"
    },
    {
        city: "Kangaroo Flat",
        lat: "-36.8000",
        lng: "144.2500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "10394",
        population_proper: "10394"
    },
    {
        city: "Bowen",
        lat: "-20.0167",
        lng: "148.2333",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "10377",
        population_proper: "10377"
    },
    {
        city: "Young",
        lat: "-34.3000",
        lng: "148.3000",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "10295",
        population_proper: "10295"
    },
    {
        city: "Cowra",
        lat: "-33.8183",
        lng: "148.6578",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "10063",
        population_proper: "10063"
    },
    {
        city: "Hamilton",
        lat: "-37.7333",
        lng: "142.0167",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "9974",
        population_proper: "9974"
    },
    {
        city: "Gunnedah",
        lat: "-30.9667",
        lng: "150.2500",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "9726",
        population_proper: "9726"
    },
    {
        city: "Mount Evelyn",
        lat: "-37.7833",
        lng: "145.3833",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "9702",
        population_proper: "9702"
    },
    {
        city: "Andergrove",
        lat: "-21.0931",
        lng: "149.1860",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "9419",
        population_proper: "9419"
    },
    {
        city: "Moree",
        lat: "-29.4650",
        lng: "149.8344",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "9311",
        population_proper: "9311"
    },
    {
        city: "Benalla",
        lat: "-36.5519",
        lng: "145.9817",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "9298",
        population_proper: "9298"
    },
    {
        city: "Currumbin",
        lat: "-28.1580",
        lng: "153.4690",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "9121",
        population_proper: "9121"
    },
    {
        city: "Wollert",
        lat: "-37.5970",
        lng: "145.0330",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "9060",
        population_proper: "9060"
    },
    {
        city: "Mittagong",
        lat: "-34.4500",
        lng: "150.4500",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "8999",
        population_proper: "5767"
    },
    {
        city: "Kelso",
        lat: "-33.4186",
        lng: "149.6056",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "8968",
        population_proper: "8968"
    },
    {
        city: "Yanchep",
        lat: "-31.5500",
        lng: "115.6340",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "8868",
        population_proper: "8868"
    },
    {
        city: "Bentley",
        lat: "-32.0010",
        lng: "115.9240",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "8782",
        population_proper: "8782"
    },
    {
        city: "Moe",
        lat: "-38.1722",
        lng: "146.2678",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "8778",
        population_proper: "8778"
    },
    {
        city: "Ayr",
        lat: "-19.5744",
        lng: "147.4066",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "8738",
        population_proper: "8738"
    },
    {
        city: "Moranbah",
        lat: "-22.0016",
        lng: "148.0533",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "8735",
        population_proper: "8735"
    },
    {
        city: "Leeton",
        lat: "-34.5483",
        lng: "146.4011",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "8623",
        population_proper: "8623"
    },
    {
        city: "Moss Vale",
        lat: "-34.5483",
        lng: "150.3678",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "8579",
        population_proper: "8579"
    },
    {
        city: "Wallan",
        lat: "-37.4167",
        lng: "144.9833",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "8520",
        population_proper: "8504"
    },
    {
        city: "Springwood",
        lat: "-33.6967",
        lng: "150.5683",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "8475",
        population_proper: "8475"
    },
    {
        city: "Lesmurdie",
        lat: "-31.9940",
        lng: "116.0500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "8437",
        population_proper: "8437"
    },
    {
        city: "Forbes",
        lat: "-33.3833",
        lng: "148.0167",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "8432",
        population_proper: "8432"
    },
    {
        city: "Emu Plains",
        lat: "-33.7483",
        lng: "150.6678",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "8421",
        population_proper: "8421"
    },
    {
        city: "Rye",
        lat: "-38.3770",
        lng: "144.8380",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "8416",
        population_proper: "8416"
    },
    {
        city: "Darley",
        lat: "-37.6547",
        lng: "144.4370",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "8372",
        population_proper: "8372"
    },
    {
        city: "Ararat",
        lat: "-37.2833",
        lng: "142.9167",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "8300",
        population_proper: "8297"
    },
    {
        city: "Tweed Heads",
        lat: "-28.1833",
        lng: "153.5500",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "8176",
        population_proper: "8176"
    },
    {
        city: "Highfields",
        lat: "-27.4581",
        lng: "151.9492",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "8131",
        population_proper: "8131"
    },
    {
        city: "Charters Towers",
        lat: "-20.1000",
        lng: "146.2667",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "8120",
        population_proper: "8120"
    },
    {
        city: "Melton",
        lat: "-37.6833",
        lng: "144.5833",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "8069",
        population_proper: "8069"
    },
    {
        city: "Katoomba",
        lat: "-33.7150",
        lng: "150.3120",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "7964",
        population_proper: "7964"
    },
    {
        city: "Kilmore",
        lat: "-37.2833",
        lng: "144.9500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "7958",
        population_proper: "7958"
    },
    {
        city: "Mooroopna",
        lat: "-36.3833",
        lng: "145.3500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "7942",
        population_proper: "7942"
    },
    {
        city: "Yarrawonga",
        lat: "-36.0167",
        lng: "146.0000",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "7930",
        population_proper: "7930"
    },
    {
        city: "Maryborough",
        lat: "-37.0500",
        lng: "143.7350",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "7921",
        population_proper: "7921"
    },
    {
        city: "Deniliquin",
        lat: "-35.5317",
        lng: "144.9344",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "7862",
        population_proper: "7862"
    },
    {
        city: "Mandurah",
        lat: "-32.5289",
        lng: "115.7231",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "7837",
        population_proper: "7837"
    },
    {
        city: "Bli Bli",
        lat: "-26.6180",
        lng: "153.0370",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "7801",
        population_proper: "7801"
    },
    {
        city: "Kiama",
        lat: "-34.6708",
        lng: "150.8542",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "7700",
        population_proper: "6881"
    },
    {
        city: "Narre Warren North",
        lat: "-37.9833",
        lng: "145.3167",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "7674",
        population_proper: "7674"
    },
    {
        city: "Narrabri",
        lat: "-30.3317",
        lng: "149.7678",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "7606",
        population_proper: "7606"
    },
    {
        city: "Bairnsdale",
        lat: "-37.8333",
        lng: "147.6167",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "7580",
        population_proper: "7580"
    },
    {
        city: "Clifton Springs",
        lat: "-38.1500",
        lng: "144.5667",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "7519",
        population_proper: "7519"
    },
    {
        city: "North Tamborine",
        lat: "-27.9694",
        lng: "153.1992",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "7506",
        population_proper: "7506"
    },
    {
        city: "Bargara",
        lat: "-24.8205",
        lng: "152.4625",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "7485",
        population_proper: "7485"
    },
    {
        city: "Kingscliff",
        lat: "-28.2564",
        lng: "153.5781",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "7464",
        population_proper: "7464"
    },
    {
        city: "Healesville",
        lat: "-37.6561",
        lng: "145.5139",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "7461",
        population_proper: "7461"
    },
    {
        city: "Fremantle",
        lat: "-32.0569",
        lng: "115.7439",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "7459",
        population_proper: "7459"
    },
    {
        city: "Blaxland",
        lat: "-33.7453",
        lng: "150.6175",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "7451",
        population_proper: "7451"
    },
    {
        city: "Atherton",
        lat: "-17.2658",
        lng: "145.4780",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "7331",
        population_proper: "7331"
    },
    {
        city: "Kyabram",
        lat: "-36.3167",
        lng: "145.0500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "7321",
        population_proper: "7321"
    },
    {
        city: "Innisfail",
        lat: "-17.5236",
        lng: "146.0292",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "7236",
        population_proper: "1145"
    },
    {
        city: "Sanctuary Point",
        lat: "-35.1036",
        lng: "150.6267",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "7225",
        population_proper: "7225"
    },
    {
        city: "Collie",
        lat: "-33.3630",
        lng: "116.1560",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "7192",
        population_proper: "7192"
    },
    {
        city: "Gatton",
        lat: "-27.5611",
        lng: "152.2755",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "7101",
        population_proper: "7101"
    },
    {
        city: "Merrimac",
        lat: "-28.0500",
        lng: "153.3667",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "7071",
        population_proper: "7071"
    },
    {
        city: "Murwillumbah",
        lat: "-28.3333",
        lng: "153.3833",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "7032",
        population_proper: "7032"
    },
    {
        city: "Urraween",
        lat: "-25.2955",
        lng: "152.8219",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "6969",
        population_proper: "6969"
    },
    {
        city: "Kyneton",
        lat: "-37.2333",
        lng: "144.4500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "6951",
        population_proper: "6951"
    },
    {
        city: "Bongaree",
        lat: "-27.0814",
        lng: "153.1633",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "6947",
        population_proper: "6947"
    },
    {
        city: "Roma",
        lat: "-26.5733",
        lng: "148.7869",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "6848",
        population_proper: "6848"
    },
    {
        city: "Mount Cotton",
        lat: "-27.6188",
        lng: "153.2210",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "6835",
        population_proper: "6835"
    },
    {
        city: "Cootamundra",
        lat: "-34.6483",
        lng: "148.0344",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "6782",
        population_proper: "6782"
    },
    {
        city: "Beerwah",
        lat: "-26.8556",
        lng: "152.9600",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "6769",
        population_proper: "6769"
    },
    {
        city: "Castlemaine",
        lat: "-37.0636",
        lng: "144.2172",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "6757",
        population_proper: "6757"
    },
    {
        city: "Pottsville",
        lat: "-28.3869",
        lng: "153.5608",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "6704",
        population_proper: "6704"
    },
    {
        city: "Cooma",
        lat: "-36.2350",
        lng: "149.1258",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "6681",
        population_proper: "6681"
    },
    {
        city: "Gordonvale",
        lat: "-17.0936",
        lng: "145.7866",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "6671",
        population_proper: "6671"
    },
    {
        city: "Lysterfield",
        lat: "-37.9333",
        lng: "145.3000",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "6663",
        population_proper: "6663"
    },
    {
        city: "Bomaderry",
        lat: "-34.8480",
        lng: "150.6050",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "6661",
        population_proper: "6661"
    },
    {
        city: "Chinchilla",
        lat: "-26.7397",
        lng: "150.6250",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "6612",
        population_proper: "6612"
    },
    {
        city: "Northam",
        lat: "-31.6531",
        lng: "116.6661",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "6548",
        population_proper: "6548"
    },
    {
        city: "Yass",
        lat: "-34.8203",
        lng: "148.9136",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "6506",
        population_proper: "6506"
    },
    {
        city: "Strathalbyn",
        lat: "-35.2667",
        lng: "138.9000",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "6504",
        population_proper: "6504"
    },
    {
        city: "Burpengary",
        lat: "-27.1442",
        lng: "153.0000",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "6433",
        population_proper: "6433"
    },
    {
        city: "Waterford",
        lat: "-27.6833",
        lng: "153.1330",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "6431",
        population_proper: "6431"
    },
    {
        city: "Lennox Head",
        lat: "-28.7983",
        lng: "153.5844",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "6407",
        population_proper: "6407"
    },
    {
        city: "Beaudesert",
        lat: "-27.9880",
        lng: "152.9958",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "6395",
        population_proper: "6395"
    },
    {
        city: "Margaret River",
        lat: "-33.9550",
        lng: "115.0750",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "6392",
        population_proper: "6392"
    },
    {
        city: "Helensburgh",
        lat: "-34.1833",
        lng: "151.0000",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "6383",
        population_proper: "6383"
    },
    {
        city: "Goondiwindi",
        lat: "-28.5461",
        lng: "150.3097",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "6355",
        population_proper: "6355"
    },
    {
        city: "Seymour",
        lat: "-37.0300",
        lng: "145.1300",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "6327",
        population_proper: "6327"
    },
    {
        city: "Nambucca Heads",
        lat: "-30.6414",
        lng: "152.9906",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "6327",
        population_proper: "6327"
    },
    {
        city: "Nuriootpa",
        lat: "-34.4667",
        lng: "138.9833",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "6314",
        population_proper: "6314"
    },
    {
        city: "Wauchope",
        lat: "-31.4650",
        lng: "152.7344",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "6312",
        population_proper: "6312"
    },
    {
        city: "Tumut",
        lat: "-35.3039",
        lng: "148.2233",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "6230",
        population_proper: "6230"
    },
    {
        city: "Tuncurry",
        lat: "-32.1750",
        lng: "152.4989",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "6186",
        population_proper: "6186"
    },
    {
        city: "Glen Innes",
        lat: "-29.7500",
        lng: "151.7361",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "6155",
        population_proper: "6155"
    },
    {
        city: "Yamba",
        lat: "-29.4333",
        lng: "153.3500",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "6076",
        population_proper: "6076"
    },
    {
        city: "Wentworth Falls",
        lat: "-33.7164",
        lng: "150.3772",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "6076",
        population_proper: "6076"
    },
    {
        city: "South Grafton",
        lat: "-29.7150",
        lng: "152.9344",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "6068",
        population_proper: "6068"
    },
    {
        city: "Kurri Kurri",
        lat: "-32.8167",
        lng: "151.4833",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "6044",
        population_proper: "6044"
    },
    {
        city: "Stawell",
        lat: "-37.0500",
        lng: "142.7667",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "6032",
        population_proper: "5736"
    },
    {
        city: "Cobram",
        lat: "-35.9667",
        lng: "145.6500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "6014",
        population_proper: "6014"
    },
    {
        city: "Wynyard",
        lat: "-41.0000",
        lng: "145.7167",
        country: "Australia",
        iso2: "AU",
        admin_name: "Tasmania",
        capital: "",
        population: "6001",
        population_proper: "6001"
    },
    {
        city: "Naracoorte",
        lat: "-36.9550",
        lng: "140.7429",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "5960",
        population_proper: "5960"
    },
    {
        city: "North Mackay",
        lat: "-21.1216",
        lng: "149.1783",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "5933",
        population_proper: "5933"
    },
    {
        city: "Cannington",
        lat: "-32.0167",
        lng: "115.9500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "5929",
        population_proper: "5929"
    },
    {
        city: "Woodend",
        lat: "-37.3630",
        lng: "144.5260",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "5806",
        population_proper: "5806"
    },
    {
        city: "Dromana",
        lat: "-38.3380",
        lng: "144.9650",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "5803",
        population_proper: "5803"
    },
    {
        city: "Emerald",
        lat: "-37.9331",
        lng: "145.4370",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "5778",
        population_proper: "5778"
    },
    {
        city: "Biloela",
        lat: "-24.4002",
        lng: "150.5133",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "5758",
        population_proper: "5758"
    },
    {
        city: "Alstonville",
        lat: "-28.8317",
        lng: "153.4344",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "5739",
        population_proper: "5739"
    },
    {
        city: "Cannonvale",
        lat: "-20.2863",
        lng: "148.6866",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "5716",
        population_proper: "5716"
    },
    {
        city: "Point Vernon",
        lat: "-25.2538",
        lng: "152.8177",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "5699",
        population_proper: "5699"
    },
    {
        city: "Palmwoods",
        lat: "-26.6883",
        lng: "152.9597",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "5676",
        population_proper: "5676"
    },
    {
        city: "Scone",
        lat: "-32.0833",
        lng: "150.8500",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "5624",
        population_proper: "5624"
    },
    {
        city: "Whittlesea",
        lat: "-37.5139",
        lng: "145.1140",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "5611",
        population_proper: "5611"
    },
    {
        city: "East Ballina",
        lat: "-28.8594",
        lng: "153.5872",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "5571",
        population_proper: "5571"
    },
    {
        city: "Sarina",
        lat: "-21.4225",
        lng: "149.2175",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "5522",
        population_proper: "5522"
    },
    {
        city: "Byron Bay",
        lat: "-28.6483",
        lng: "153.6178",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "5521",
        population_proper: "5521"
    },
    {
        city: "Corowa",
        lat: "-35.9942",
        lng: "146.3844",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "5482",
        population_proper: "5482"
    },
    {
        city: "Richmond",
        lat: "-33.5983",
        lng: "150.7511",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "5482",
        population_proper: "5482"
    },
    {
        city: "Cooranbong",
        lat: "-33.0740",
        lng: "151.4510",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "5449",
        population_proper: "5449"
    },
    {
        city: "Bullsbrook",
        lat: "-31.6630",
        lng: "116.0300",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "5448",
        population_proper: "5448"
    },
    {
        city: "Inverloch",
        lat: "-38.6334",
        lng: "145.7278",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "5437",
        population_proper: "5437"
    },
    {
        city: "New Norfolk",
        lat: "-42.7828",
        lng: "147.0594",
        country: "Australia",
        iso2: "AU",
        admin_name: "Tasmania",
        capital: "",
        population: "5432",
        population_proper: "5432"
    },
    {
        city: "Stanthorpe",
        lat: "-28.6547",
        lng: "151.9350",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "5406",
        population_proper: "5406"
    },
    {
        city: "Wingham",
        lat: "-31.8500",
        lng: "152.3670",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "5374",
        population_proper: "5374"
    },
    {
        city: "Irymple",
        lat: "-34.2333",
        lng: "142.1667",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "5325",
        population_proper: "5325"
    },
    {
        city: "Dunsborough",
        lat: "-33.6167",
        lng: "115.1000",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "5320",
        population_proper: "5320"
    },
    {
        city: "Kununurra",
        lat: "-15.7736",
        lng: "128.7386",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "5308",
        population_proper: "5308"
    },
    {
        city: "Smithfield Heights",
        lat: "-16.8314",
        lng: "145.6910",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "5303",
        population_proper: "5303"
    },
    {
        city: "Woolgoolga",
        lat: "-30.1150",
        lng: "153.2011",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "5290",
        population_proper: "5290"
    },
    {
        city: "Bannockburn",
        lat: "-38.0500",
        lng: "144.1667",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "5283",
        population_proper: "5283"
    },
    {
        city: "Maffra",
        lat: "-37.9500",
        lng: "146.9830",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "5280",
        population_proper: "5280"
    },
    {
        city: "Tannum Sands",
        lat: "-23.9474",
        lng: "151.3675",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "5145",
        population_proper: "5145"
    },
    {
        city: "Ocean Shores",
        lat: "-28.5092",
        lng: "153.5375",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "5137",
        population_proper: "5137"
    },
    {
        city: "Howard Springs",
        lat: "-12.4922",
        lng: "131.0530",
        country: "Australia",
        iso2: "AU",
        admin_name: "Northern Territory",
        capital: "",
        population: "5132",
        population_proper: "5132"
    },
    {
        city: "Leongatha",
        lat: "-38.4833",
        lng: "145.9500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "5119",
        population_proper: "5119"
    },
    {
        city: "Worragee",
        lat: "-34.9144",
        lng: "150.6386",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "5068",
        population_proper: "5068"
    },
    {
        city: "Tahmoor",
        lat: "-34.2233",
        lng: "150.5928",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "5067",
        population_proper: "5067"
    },
    {
        city: "Red Cliffs",
        lat: "-34.3075",
        lng: "142.1881",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "5060",
        population_proper: "5060"
    },
    {
        city: "Glenbrook",
        lat: "-33.7650",
        lng: "150.6267",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "5051",
        population_proper: "5051"
    },
    {
        city: "McMinns Lagoon",
        lat: "-12.5329",
        lng: "131.0500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Northern Territory",
        capital: "",
        population: "5025",
        population_proper: "5025"
    },
    {
        city: "Kiama Downs",
        lat: "-34.6317",
        lng: "150.8511",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "5004",
        population_proper: "5004"
    },
    {
        city: "Hazelbrook",
        lat: "-33.7250",
        lng: "150.4572",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "4974",
        population_proper: "4974"
    },
    {
        city: "Wonthaggi",
        lat: "-38.6056",
        lng: "145.5917",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "4965",
        population_proper: "4965"
    },
    {
        city: "Junee",
        lat: "-34.8661",
        lng: "147.5822",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "4922",
        population_proper: "4922"
    },
    {
        city: "Pinjarra",
        lat: "-32.6300",
        lng: "115.8711",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "4910",
        population_proper: "4910"
    },
    {
        city: "Encounter Bay",
        lat: "-35.5742",
        lng: "138.6005",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "4901",
        population_proper: "4901"
    },
    {
        city: "Nairne",
        lat: "-35.0333",
        lng: "138.9167",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "4842",
        population_proper: "4842"
    },
    {
        city: "Cowes",
        lat: "-38.4511",
        lng: "145.2419",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "4839",
        population_proper: "4839"
    },
    {
        city: "Boulder",
        lat: "-30.7780",
        lng: "121.4890",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "4825",
        population_proper: "4825"
    },
    {
        city: "Picton",
        lat: "-34.1817",
        lng: "150.6011",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "4816",
        population_proper: "4816"
    },
    {
        city: "Salamander Bay",
        lat: "-32.7292",
        lng: "152.0894",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "4815",
        population_proper: "4815"
    },
    {
        city: "Lakes Entrance",
        lat: "-37.8667",
        lng: "147.9833",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "4810",
        population_proper: "4810"
    },
    {
        city: "Mansfield",
        lat: "-37.0500",
        lng: "146.0830",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "4787",
        population_proper: "4787"
    },
    {
        city: "Churchill",
        lat: "-38.3167",
        lng: "146.4170",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "4783",
        population_proper: "4783"
    },
    {
        city: "Bucasia",
        lat: "-21.0311",
        lng: "149.1597",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "4771",
        population_proper: "4771"
    },
    {
        city: "Boyne Island",
        lat: "-23.9477",
        lng: "151.3550",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "4760",
        population_proper: "4760"
    },
    {
        city: "Blackwater",
        lat: "-23.4667",
        lng: "148.7667",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "4749",
        population_proper: "4749"
    },
    {
        city: "Millicent",
        lat: "-37.5967",
        lng: "140.3524",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "4734",
        population_proper: "4734"
    },
    {
        city: "Oakey",
        lat: "-27.4338",
        lng: "151.7211",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "4705",
        population_proper: "4705"
    },
    {
        city: "Temora",
        lat: "-34.4483",
        lng: "147.5344",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "4693",
        population_proper: "4693"
    },
    {
        city: "Narromine",
        lat: "-32.2333",
        lng: "148.2333",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "4689",
        population_proper: "4689"
    },
    {
        city: "Ningi",
        lat: "-27.0667",
        lng: "153.0833",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "4675",
        population_proper: "4675"
    },
    {
        city: "Bega",
        lat: "-36.6847",
        lng: "149.8422",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "4668",
        population_proper: "4668"
    },
    {
        city: "Mount Low",
        lat: "-19.2247",
        lng: "146.6808",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "4655",
        population_proper: "4655"
    },
    {
        city: "Leura",
        lat: "-33.7089",
        lng: "150.3353",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "4644",
        population_proper: "4644"
    },
    {
        city: "Renmark",
        lat: "-34.1741",
        lng: "140.7418",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "4634",
        population_proper: "4634"
    },
    {
        city: "South West Rocks",
        lat: "-30.8842",
        lng: "153.0403",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "4603",
        population_proper: "4603"
    },
    {
        city: "Tanunda",
        lat: "-34.5167",
        lng: "138.9667",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "4588",
        population_proper: "4588"
    },
    {
        city: "Kadina",
        lat: "-33.9625",
        lng: "137.7125",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "4587",
        population_proper: "2849"
    },
    {
        city: "Newman",
        lat: "-23.3539",
        lng: "119.7319",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "4567",
        population_proper: "4567"
    },
    {
        city: "Glenella",
        lat: "-21.1197",
        lng: "149.1542",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "4519",
        population_proper: "4519"
    },
    {
        city: "Roxby Downs",
        lat: "-30.5625",
        lng: "136.8785",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "4500",
        population_proper: "3884"
    },
    {
        city: "Numurkah",
        lat: "-36.0833",
        lng: "145.4333",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "4477",
        population_proper: "4477"
    },
    {
        city: "Korumburra",
        lat: "-38.4333",
        lng: "145.8167",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "4469",
        population_proper: "4469"
    },
    {
        city: "Rasmussen",
        lat: "-19.3497",
        lng: "146.7160",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "4456",
        population_proper: "4456"
    },
    {
        city: "Tatura",
        lat: "-36.4401",
        lng: "145.2330",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "4448",
        population_proper: "4448"
    },
    {
        city: "Carnarvon",
        lat: "-24.8672",
        lng: "113.6611",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "4426",
        population_proper: "4426"
    },
    {
        city: "Romsey",
        lat: "-37.3500",
        lng: "144.7500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "4412",
        population_proper: "4412"
    },
    {
        city: "Blackheath",
        lat: "-33.6317",
        lng: "150.2845",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "4396",
        population_proper: "4396"
    },
    {
        city: "Bargo",
        lat: "-34.2983",
        lng: "150.5844",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "4393",
        population_proper: "4393"
    },
    {
        city: "Old Bar",
        lat: "-31.9667",
        lng: "152.5830",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "4389",
        population_proper: "4389"
    },
    {
        city: "Narrandera",
        lat: "-34.7483",
        lng: "146.5511",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "4375",
        population_proper: "4375"
    },
    {
        city: "Ingham",
        lat: "-18.6500",
        lng: "146.1667",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "4357",
        population_proper: "4357"
    },
    {
        city: "Manjimup",
        lat: "-34.2411",
        lng: "116.1464",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "4349",
        population_proper: "4349"
    },
    {
        city: "George Town",
        lat: "-41.1000",
        lng: "146.8167",
        country: "Australia",
        iso2: "AU",
        admin_name: "Tasmania",
        capital: "",
        population: "4347",
        population_proper: "4347"
    },
    {
        city: "Wyong",
        lat: "-33.2820",
        lng: "151.4180",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "4326",
        population_proper: "4326"
    },
    {
        city: "Broadford",
        lat: "-37.2000",
        lng: "145.0670",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "4319",
        population_proper: "4319"
    },
    {
        city: "Drysdale",
        lat: "-38.1667",
        lng: "144.5667",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "4275",
        population_proper: "4275"
    },
    {
        city: "Narrogin",
        lat: "-32.9360",
        lng: "117.1780",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "4274",
        population_proper: "4274"
    },
    {
        city: "Cockatoo",
        lat: "-37.9350",
        lng: "145.4920",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "4256",
        population_proper: "4256"
    },
    {
        city: "Victor Harbor",
        lat: "-35.5500",
        lng: "138.6167",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "4233",
        population_proper: "4233"
    },
    {
        city: "Moama",
        lat: "-36.0833",
        lng: "144.7500",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "4198",
        population_proper: "4198"
    },
    {
        city: "Bungendore",
        lat: "-35.2483",
        lng: "149.4511",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "4178",
        population_proper: "4178"
    },
    {
        city: "Latrobe",
        lat: "-41.2464",
        lng: "146.4246",
        country: "Australia",
        iso2: "AU",
        admin_name: "Tasmania",
        capital: "",
        population: "4169",
        population_proper: "4169"
    },
    {
        city: "Beachmere",
        lat: "-27.1294",
        lng: "153.0538",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "4112",
        population_proper: "4112"
    },
    {
        city: "Berri",
        lat: "-34.2833",
        lng: "140.6000",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "4103",
        population_proper: "4088"
    },
    {
        city: "Wellington",
        lat: "-32.5500",
        lng: "148.9333",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "4077",
        population_proper: "4077"
    },
    {
        city: "Tenterfield",
        lat: "-29.0553",
        lng: "152.0178",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "4066",
        population_proper: "4066"
    },
    {
        city: "Thirlmere",
        lat: "-34.1983",
        lng: "150.5678",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "4046",
        population_proper: "4046"
    },
    {
        city: "Legana",
        lat: "-41.3667",
        lng: "147.0500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Tasmania",
        capital: "",
        population: "4029",
        population_proper: "4029"
    },
    {
        city: "Faulconbridge",
        lat: "-33.6875",
        lng: "150.5439",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "4025",
        population_proper: "4025"
    },
    {
        city: "The Entrance",
        lat: "-33.3472",
        lng: "151.4960",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "4006",
        population_proper: "4006"
    },
    {
        city: "Cobar",
        lat: "-31.4997",
        lng: "145.8319",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3990",
        population_proper: "3990"
    },
    {
        city: "Wallaroo",
        lat: "-33.9167",
        lng: "137.6167",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "3988",
        population_proper: "3481"
    },
    {
        city: "Moruya",
        lat: "-35.9111",
        lng: "150.0806",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3982",
        population_proper: "3982"
    },
    {
        city: "Gerringong",
        lat: "-34.7483",
        lng: "150.8344",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3966",
        population_proper: "3966"
    },
    {
        city: "Sandstone Point",
        lat: "-27.0736",
        lng: "153.1233",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3959",
        population_proper: "3959"
    },
    {
        city: "Tambourine",
        lat: "-27.8816",
        lng: "153.1300",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3950",
        population_proper: "3950"
    },
    {
        city: "Margate",
        lat: "-43.0294",
        lng: "147.2617",
        country: "Australia",
        iso2: "AU",
        admin_name: "Tasmania",
        capital: "",
        population: "3920",
        population_proper: "3920"
    },
    {
        city: "Trafalgar",
        lat: "-38.2000",
        lng: "146.1500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "3912",
        population_proper: "3912"
    },
    {
        city: "Somerset",
        lat: "-41.0500",
        lng: "145.8333",
        country: "Australia",
        iso2: "AU",
        admin_name: "Tasmania",
        capital: "",
        population: "3903",
        population_proper: "3903"
    },
    {
        city: "Marian",
        lat: "-21.1453",
        lng: "148.9431",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3903",
        population_proper: "3903"
    },
    {
        city: "Weipa",
        lat: "-12.6167",
        lng: "141.8667",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3899",
        population_proper: "3899"
    },
    {
        city: "Kerang",
        lat: "-35.7167",
        lng: "143.9167",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "3893",
        population_proper: "3893"
    },
    {
        city: "Westbrook",
        lat: "-27.6000",
        lng: "151.8333",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3885",
        population_proper: "3885"
    },
    {
        city: "Smithton",
        lat: "-40.8440",
        lng: "145.1200",
        country: "Australia",
        iso2: "AU",
        admin_name: "Tasmania",
        capital: "",
        population: "3881",
        population_proper: "3881"
    },
    {
        city: "Longford",
        lat: "-41.5958",
        lng: "147.1220",
        country: "Australia",
        iso2: "AU",
        admin_name: "Tasmania",
        capital: "",
        population: "3863",
        population_proper: "3863"
    },
    {
        city: "Karana Downs",
        lat: "-27.5500",
        lng: "152.8170",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3862",
        population_proper: "3862"
    },
    {
        city: "Beechworth",
        lat: "-36.3594",
        lng: "146.6869",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "3859",
        population_proper: "3859"
    },
    {
        city: "Penguin",
        lat: "-41.1167",
        lng: "146.0708",
        country: "Australia",
        iso2: "AU",
        admin_name: "Tasmania",
        capital: "",
        population: "3849",
        population_proper: "3849"
    },
    {
        city: "Anna Bay",
        lat: "-32.7817",
        lng: "152.0844",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3846",
        population_proper: "3846"
    },
    {
        city: "Loxton",
        lat: "-34.4539",
        lng: "140.5669",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "3838",
        population_proper: "3838"
    },
    {
        city: "Pearcedale",
        lat: "-38.2030",
        lng: "145.2310",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "3821",
        population_proper: "3821"
    },
    {
        city: "Landsborough",
        lat: "-26.8091",
        lng: "152.9655",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3812",
        population_proper: "3812"
    },
    {
        city: "Laidley",
        lat: "-27.6313",
        lng: "152.3938",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3808",
        population_proper: "3808"
    },
    {
        city: "Wonga Park",
        lat: "-37.7390",
        lng: "145.2670",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "3796",
        population_proper: "3796"
    },
    {
        city: "Peregian Beach",
        lat: "-26.4810",
        lng: "153.0960",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3791",
        population_proper: "3791"
    },
    {
        city: "Cooroy",
        lat: "-26.4166",
        lng: "152.9119",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3791",
        population_proper: "3791"
    },
    {
        city: "Bellingen",
        lat: "-30.4483",
        lng: "152.9011",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3779",
        population_proper: "3779"
    },
    {
        city: "Riddell",
        lat: "-37.4667",
        lng: "144.6830",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "3753",
        population_proper: "3753"
    },
    {
        city: "Suffolk Park",
        lat: "-28.6983",
        lng: "153.6178",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3750",
        population_proper: "3750"
    },
    {
        city: "Buninyong",
        lat: "-37.6503",
        lng: "143.8839",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "3714",
        population_proper: "3714"
    },
    {
        city: "Weston",
        lat: "-32.8103",
        lng: "151.4561",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3693",
        population_proper: "3693"
    },
    {
        city: "Katanning",
        lat: "-33.6908",
        lng: "117.5553",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "3687",
        population_proper: "3687"
    },
    {
        city: "Bellbrae",
        lat: "-38.3333",
        lng: "144.3000",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "3683",
        population_proper: "865"
    },
    {
        city: "Sawtell",
        lat: "-30.3650",
        lng: "153.1011",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3682",
        population_proper: "3682"
    },
    {
        city: "Silverdale",
        lat: "-33.9150",
        lng: "150.6094",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3682",
        population_proper: "3682"
    },
    {
        city: "Tallebudgera",
        lat: "-28.1489",
        lng: "153.4180",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3667",
        population_proper: "3667"
    },
    {
        city: "Bay View",
        lat: "-33.6592",
        lng: "151.2861",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3620",
        population_proper: "3620"
    },
    {
        city: "Portarlington",
        lat: "-38.1167",
        lng: "144.6500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "3619",
        population_proper: "3619"
    },
    {
        city: "Nanango",
        lat: "-26.6667",
        lng: "152.0000",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3599",
        population_proper: "3599"
    },
    {
        city: "Logan Village",
        lat: "-27.7667",
        lng: "153.1000",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3586",
        population_proper: "3586"
    },
    {
        city: "Koo-Wee-Rup",
        lat: "-38.1980",
        lng: "145.4890",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "3579",
        population_proper: "3579"
    },
    {
        city: "Glass House Mountains",
        lat: "-26.9000",
        lng: "152.9167",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3576",
        population_proper: "3576"
    },
    {
        city: "Sussex Inlet",
        lat: "-35.1250",
        lng: "150.5436",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3575",
        population_proper: "3575"
    },
    {
        city: "Proserpine",
        lat: "-20.4017",
        lng: "148.5814",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3562",
        population_proper: "3562"
    },
    {
        city: "Merimbula",
        lat: "-36.8983",
        lng: "149.9011",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3544",
        population_proper: "3544"
    },
    {
        city: "Scarness",
        lat: "-25.2833",
        lng: "152.8500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3519",
        population_proper: "3519"
    },
    {
        city: "Port Douglas",
        lat: "-16.4834",
        lng: "145.4652",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3504",
        population_proper: "3504"
    },
    {
        city: "Gosford",
        lat: "-33.4233",
        lng: "151.3414",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3499",
        population_proper: "3499"
    },
    {
        city: "Bridgewater",
        lat: "-35.0164",
        lng: "138.7680",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "3498",
        population_proper: "3498"
    },
    {
        city: "Lake Cathie",
        lat: "-31.5483",
        lng: "152.8511",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3494",
        population_proper: "3494"
    },
    {
        city: "Condobolin",
        lat: "-33.0886",
        lng: "147.1494",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3486",
        population_proper: "3486"
    },
    {
        city: "Paynesville",
        lat: "-37.9167",
        lng: "147.7167",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "3480",
        population_proper: "3480"
    },
    {
        city: "Woodford",
        lat: "-26.9408",
        lng: "152.7669",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3458",
        population_proper: "3458"
    },
    {
        city: "Maddingley",
        lat: "-37.6864",
        lng: "144.4336",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "3453",
        population_proper: "3453"
    },
    {
        city: "Hurstbridge",
        lat: "-37.6389",
        lng: "145.1950",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "3450",
        population_proper: "3450"
    },
    {
        city: "Quirindi",
        lat: "-31.5081",
        lng: "150.6800",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3444",
        population_proper: "3444"
    },
    {
        city: "Maleny",
        lat: "-26.7580",
        lng: "152.8513",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3441",
        population_proper: "3441"
    },
    {
        city: "Calliope",
        lat: "-24.0061",
        lng: "151.1988",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3438",
        population_proper: "3438"
    },
    {
        city: "North Beach",
        lat: "-31.8610",
        lng: "115.7580",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "3410",
        population_proper: "3410"
    },
    {
        city: "Walkerston",
        lat: "-21.1608",
        lng: "149.0591",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3403",
        population_proper: "3403"
    },
    {
        city: "Blayney",
        lat: "-33.5333",
        lng: "149.2500",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3378",
        population_proper: "3378"
    },
    {
        city: "Camperdown",
        lat: "-38.2333",
        lng: "143.1500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "3369",
        population_proper: "3369"
    },
    {
        city: "Aldgate",
        lat: "-35.0153",
        lng: "138.7360",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "3361",
        population_proper: "3361"
    },
    {
        city: "Port Fairy",
        lat: "-38.3840",
        lng: "142.2350",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "3340",
        population_proper: "3340"
    },
    {
        city: "Tyabb",
        lat: "-38.2590",
        lng: "145.1890",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "3338",
        population_proper: "3338"
    },
    {
        city: "Lowood",
        lat: "-27.4667",
        lng: "152.5833",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3336",
        population_proper: "3336"
    },
    {
        city: "Charleville",
        lat: "-26.4016",
        lng: "146.2383",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3335",
        population_proper: "3335"
    },
    {
        city: "Derby",
        lat: "-17.3056",
        lng: "123.6339",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "3325",
        population_proper: "3325"
    },
    {
        city: "Mooloolah",
        lat: "-26.7625",
        lng: "152.9633",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3321",
        population_proper: "3321"
    },
    {
        city: "Pittsworth",
        lat: "-27.7167",
        lng: "151.6333",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3294",
        population_proper: "3294"
    },
    {
        city: "Vincentia",
        lat: "-35.0758",
        lng: "150.6742",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3290",
        population_proper: "3290"
    },
    {
        city: "Coonabarabran",
        lat: "-31.2650",
        lng: "149.2678",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3290",
        population_proper: "3290"
    },
    {
        city: "Euroa",
        lat: "-36.7500",
        lng: "145.5670",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "3275",
        population_proper: "3275"
    },
    {
        city: "Oberon",
        lat: "-33.7167",
        lng: "149.8667",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3256",
        population_proper: "3256"
    },
    {
        city: "Woombye",
        lat: "-26.6667",
        lng: "152.9670",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3246",
        population_proper: "3246"
    },
    {
        city: "Nhulunbuy",
        lat: "-12.1825",
        lng: "136.7819",
        country: "Australia",
        iso2: "AU",
        admin_name: "Northern Territory",
        capital: "",
        population: "3240",
        population_proper: "3240"
    },
    {
        city: "Camden",
        lat: "-34.0544",
        lng: "150.6958",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3230",
        population_proper: "3230"
    },
    {
        city: "Murrumbateman",
        lat: "-34.9625",
        lng: "149.0261",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3219",
        population_proper: "3219"
    },
    {
        city: "Fernvale",
        lat: "-27.4500",
        lng: "152.6500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3209",
        population_proper: "3209"
    },
    {
        city: "East Hills",
        lat: "-33.9567",
        lng: "150.9844",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3206",
        population_proper: "3206"
    },
    {
        city: "Bohle Plains",
        lat: "-19.3136",
        lng: "146.6919",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3205",
        population_proper: "3205"
    },
    {
        city: "Junortoun",
        lat: "-36.7667",
        lng: "144.3500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "3201",
        population_proper: "3201"
    },
    {
        city: "Myrtleford",
        lat: "-36.5500",
        lng: "146.7170",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "3193",
        population_proper: "3193"
    },
    {
        city: "Crib Point",
        lat: "-38.3630",
        lng: "145.2020",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "3183",
        population_proper: "3183"
    },
    {
        city: "Marcoola",
        lat: "-26.5833",
        lng: "153.0830",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3173",
        population_proper: "3173"
    },
    {
        city: "Mullumbimby",
        lat: "-28.5500",
        lng: "153.5000",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3172",
        population_proper: "3172"
    },
    {
        city: "Creswick",
        lat: "-37.4333",
        lng: "143.9000",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "3170",
        population_proper: "3170"
    },
    {
        city: "Clare",
        lat: "-33.8333",
        lng: "138.6000",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "3160",
        population_proper: "3160"
    },
    {
        city: "Tura Beach",
        lat: "-36.8483",
        lng: "149.9261",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3158",
        population_proper: "3158"
    },
    {
        city: "Eden",
        lat: "-37.0650",
        lng: "149.9011",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3151",
        population_proper: "3151"
    },
    {
        city: "West Wyalong",
        lat: "-33.9167",
        lng: "147.2167",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3141",
        population_proper: "3141"
    },
    {
        city: "Gilgandra",
        lat: "-31.7000",
        lng: "148.6667",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3126",
        population_proper: "3126"
    },
    {
        city: "Rochester",
        lat: "-36.3667",
        lng: "144.7000",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "3113",
        population_proper: "3113"
    },
    {
        city: "McLaren Vale",
        lat: "-35.2198",
        lng: "138.5411",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "3096",
        population_proper: "3096"
    },
    {
        city: "Miners Rest",
        lat: "-37.4860",
        lng: "143.7570",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "3095",
        population_proper: "3095"
    },
    {
        city: "Robinvale",
        lat: "-34.5833",
        lng: "142.7667",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "3088",
        population_proper: "3088"
    },
    {
        city: "Wilton",
        lat: "-34.2400",
        lng: "150.7011",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3080",
        population_proper: "3080"
    },
    {
        city: "Riverview",
        lat: "-27.6000",
        lng: "152.8500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3073",
        population_proper: "3073"
    },
    {
        city: "Bogangar",
        lat: "-28.3317",
        lng: "153.5594",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3060",
        population_proper: "3060"
    },
    {
        city: "Shoalhaven Heads",
        lat: "-34.8500",
        lng: "150.7417",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3059",
        population_proper: "3059"
    },
    {
        city: "Saint George",
        lat: "-28.0363",
        lng: "148.5797",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3048",
        population_proper: "3048"
    },
    {
        city: "Little Hampton",
        lat: "-35.0500",
        lng: "138.8500",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "3044",
        population_proper: "3044"
    },
    {
        city: "Clermont",
        lat: "-22.8230",
        lng: "147.6383",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3031",
        population_proper: "3031"
    },
    {
        city: "Home Hill",
        lat: "-19.6611",
        lng: "147.4139",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3027",
        population_proper: "2954"
    },
    {
        city: "Central Coast",
        lat: "-33.4200",
        lng: "151.3000",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3026",
        population_proper: "3026"
    },
    {
        city: "Kuranda",
        lat: "-16.8197",
        lng: "145.6369",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "3008",
        population_proper: "3008"
    },
    {
        city: "Wilberforce",
        lat: "-33.5594",
        lng: "150.8410",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3007",
        population_proper: "3007"
    },
    {
        city: "Tom Price",
        lat: "-22.6939",
        lng: "117.7950",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "3005",
        population_proper: "3005"
    },
    {
        city: "Urunga",
        lat: "-30.5000",
        lng: "153.0167",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "3000",
        population_proper: "3000"
    },
    {
        city: "Dysart",
        lat: "-22.5855",
        lng: "148.3508",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "2991",
        population_proper: "2991"
    },
    {
        city: "Two Rocks",
        lat: "-31.4950",
        lng: "115.5880",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "2990",
        population_proper: "2990"
    },
    {
        city: "Ballan",
        lat: "-37.6020",
        lng: "144.2290",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "2985",
        population_proper: "2985"
    },
    {
        city: "Gloucester",
        lat: "-32.0094",
        lng: "151.9603",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2975",
        population_proper: "2975"
    },
    {
        city: "Bonny Hills",
        lat: "-31.5817",
        lng: "152.8344",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2971",
        population_proper: "2971"
    },
    {
        city: "Longreach",
        lat: "-23.4422",
        lng: "144.2491",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "2970",
        population_proper: "2970"
    },
    {
        city: "Stirling",
        lat: "-35.0022",
        lng: "138.7060",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "2966",
        population_proper: "2966"
    },
    {
        city: "Perth",
        lat: "-41.5731",
        lng: "147.1714",
        country: "Australia",
        iso2: "AU",
        admin_name: "Tasmania",
        capital: "",
        population: "2965",
        population_proper: "2965"
    },
    {
        city: "Bordertown",
        lat: "-36.3118",
        lng: "140.7702",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "2953",
        population_proper: "2953"
    },
    {
        city: "Lewiston",
        lat: "-34.6044",
        lng: "138.5914",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "2947",
        population_proper: "2947"
    },
    {
        city: "Waroona",
        lat: "-32.8667",
        lng: "115.9167",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "2934",
        population_proper: "2934"
    },
    {
        city: "Leschenault",
        lat: "-33.2450",
        lng: "115.7350",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "2932",
        population_proper: "2932"
    },
    {
        city: "Terranora",
        lat: "-28.2456",
        lng: "153.5025",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2926",
        population_proper: "2926"
    },
    {
        city: "Kapunda",
        lat: "-34.3389",
        lng: "138.9167",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "2917",
        population_proper: "2917"
    },
    {
        city: "Woori Yallock",
        lat: "-37.7780",
        lng: "145.5280",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "2913",
        population_proper: "2913"
    },
    {
        city: "Saint Georges Basin",
        lat: "-35.1300",
        lng: "150.6269",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2913",
        population_proper: "2913"
    },
    {
        city: "Pomona",
        lat: "-26.3625",
        lng: "152.8564",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "2910",
        population_proper: "2910"
    },
    {
        city: "Sorell",
        lat: "-42.7831",
        lng: "147.5625",
        country: "Australia",
        iso2: "AU",
        admin_name: "Tasmania",
        capital: "",
        population: "2907",
        population_proper: "2907"
    },
    {
        city: "Tea Gardens",
        lat: "-32.6669",
        lng: "152.1594",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2884",
        population_proper: "2884"
    },
    {
        city: "Culburra",
        lat: "-34.9347",
        lng: "150.7539",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2874",
        population_proper: "2874"
    },
    {
        city: "Yarra Glen",
        lat: "-37.6489",
        lng: "145.3719",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "2869",
        population_proper: "2869"
    },
    {
        city: "Beaconsfield Upper",
        lat: "-37.9960",
        lng: "145.4150",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "2861",
        population_proper: "2861"
    },
    {
        city: "Morisset",
        lat: "-33.1150",
        lng: "151.5011",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2857",
        population_proper: "2857"
    },
    {
        city: "Cohuna",
        lat: "-35.8000",
        lng: "144.2000",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "2853",
        population_proper: "2853"
    },
    {
        city: "Merredin",
        lat: "-31.4820",
        lng: "118.2790",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "2850",
        population_proper: "2850"
    },
    {
        city: "Deloraine",
        lat: "-41.5333",
        lng: "146.6667",
        country: "Australia",
        iso2: "AU",
        admin_name: "Tasmania",
        capital: "",
        population: "2848",
        population_proper: "2848"
    },
    {
        city: "Evans Head",
        lat: "-29.1150",
        lng: "153.4344",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2847",
        population_proper: "2847"
    },
    {
        city: "Rosewood",
        lat: "-27.6333",
        lng: "152.5830",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "2834",
        population_proper: "2834"
    },
    {
        city: "Greta",
        lat: "-32.6872",
        lng: "151.3844",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2830",
        population_proper: "2830"
    },
    {
        city: "Wollongbar",
        lat: "-28.8150",
        lng: "153.4011",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2828",
        population_proper: "2828"
    },
    {
        city: "Donnybrook",
        lat: "-33.5800",
        lng: "115.8200",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "2824",
        population_proper: "2824"
    },
    {
        city: "McCrae",
        lat: "-38.3497",
        lng: "144.9281",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "2823",
        population_proper: "2823"
    },
    {
        city: "Angle Vale",
        lat: "-34.6420",
        lng: "138.6470",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "2818",
        population_proper: "2818"
    },
    {
        city: "Bridgetown",
        lat: "-33.9583",
        lng: "116.1381",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "2812",
        population_proper: "2812"
    },
    {
        city: "North Wonthaggi",
        lat: "-38.6000",
        lng: "145.6000",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "2806",
        population_proper: "2806"
    },
    {
        city: "Heathcote",
        lat: "-36.9167",
        lng: "144.7000",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "2793",
        population_proper: "2793"
    },
    {
        city: "Howlong",
        lat: "-35.9817",
        lng: "146.6344",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2777",
        population_proper: "2777"
    },
    {
        city: "Williamstown",
        lat: "-34.6721",
        lng: "138.8884",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "2755",
        population_proper: "2755"
    },
    {
        city: "Harvey",
        lat: "-33.0800",
        lng: "115.8900",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "2750",
        population_proper: "2750"
    },
    {
        city: "Coonamble",
        lat: "-30.9317",
        lng: "148.3678",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2750",
        population_proper: "2750"
    },
    {
        city: "Uralla",
        lat: "-30.6472",
        lng: "151.4956",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2743",
        population_proper: "2743"
    },
    {
        city: "Bundanoon",
        lat: "-34.6556",
        lng: "150.2994",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2729",
        population_proper: "2729"
    },
    {
        city: "Cloncurry",
        lat: "-20.7000",
        lng: "140.5000",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "2719",
        population_proper: "2719"
    },
    {
        city: "Tolga",
        lat: "-17.2230",
        lng: "145.4788",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "2718",
        population_proper: "2718"
    },
    {
        city: "Huonville",
        lat: "-43.0333",
        lng: "147.0333",
        country: "Australia",
        iso2: "AU",
        admin_name: "Tasmania",
        capital: "",
        population: "2714",
        population_proper: "2714"
    },
    {
        city: "Merbein",
        lat: "-34.1667",
        lng: "142.0500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "2713",
        population_proper: "2713"
    },
    {
        city: "Alexandra",
        lat: "-37.1917",
        lng: "145.7100",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "2695",
        population_proper: "2695"
    },
    {
        city: "Waikerie",
        lat: "-34.1667",
        lng: "139.9833",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "2684",
        population_proper: "2684"
    },
    {
        city: "Point Lonsdale",
        lat: "-38.2833",
        lng: "144.6000",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "2684",
        population_proper: "2684"
    },
    {
        city: "Hill Top",
        lat: "-34.3525",
        lng: "150.4917",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2674",
        population_proper: "2674"
    },
    {
        city: "Stirling North",
        lat: "-32.5167",
        lng: "137.8333",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "2673",
        population_proper: "2673"
    },
    {
        city: "Hahndorf",
        lat: "-35.0303",
        lng: "138.8076",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "2670",
        population_proper: "2670"
    },
    {
        city: "Berry",
        lat: "-34.7817",
        lng: "150.7011",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2667",
        population_proper: "2667"
    },
    {
        city: "Burnett Heads",
        lat: "-24.7667",
        lng: "152.4000",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "2656",
        population_proper: "2656"
    },
    {
        city: "Moore Park",
        lat: "-24.7108",
        lng: "152.2388",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "2650",
        population_proper: "2650"
    },
    {
        city: "Burradoo",
        lat: "-34.5031",
        lng: "150.4089",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2645",
        population_proper: "2645"
    },
    {
        city: "Mannum",
        lat: "-34.9151",
        lng: "139.3044",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "2644",
        population_proper: "2644"
    },
    {
        city: "Wooroloo",
        lat: "-31.8030",
        lng: "116.3140",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "2642",
        population_proper: "2642"
    },
    {
        city: "Crookwell",
        lat: "-34.4567",
        lng: "149.4678",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2641",
        population_proper: "2641"
    },
    {
        city: "Appin",
        lat: "-34.2067",
        lng: "150.7844",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2633",
        population_proper: "2633"
    },
    {
        city: "Cooloola Cove",
        lat: "-25.9805",
        lng: "152.9944",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "2633",
        population_proper: "2633"
    },
    {
        city: "Cooktown",
        lat: "-15.4690",
        lng: "145.2515",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "2631",
        population_proper: "2631"
    },
    {
        city: "Jindabyne",
        lat: "-36.4150",
        lng: "148.6178",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2629",
        population_proper: "2629"
    },
    {
        city: "Maclean",
        lat: "-29.4567",
        lng: "153.2011",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2628",
        population_proper: "2628"
    },
    {
        city: "Stratford",
        lat: "-37.9667",
        lng: "147.0830",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "2617",
        population_proper: "2617"
    },
    {
        city: "Lawson",
        lat: "-33.7178",
        lng: "150.4322",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2614",
        population_proper: "2614"
    },
    {
        city: "Thursday Island",
        lat: "-10.5789",
        lng: "142.2200",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "2610",
        population_proper: "2610"
    },
    {
        city: "Woodside",
        lat: "-34.9531",
        lng: "138.8742",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "2608",
        population_proper: "2608"
    },
    {
        city: "Narooma",
        lat: "-36.2000",
        lng: "150.1333",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2605",
        population_proper: "2605"
    },
    {
        city: "Macksville",
        lat: "-30.7175",
        lng: "152.9172",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2598",
        population_proper: "2598"
    },
    {
        city: "Molong",
        lat: "-33.0981",
        lng: "148.8561",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2577",
        population_proper: "2577"
    },
    {
        city: "Grenfell",
        lat: "-33.9097",
        lng: "148.1950",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2573",
        population_proper: "2573"
    },
    {
        city: "Yarrabah Aboriginal Community",
        lat: "-16.9094",
        lng: "145.8703",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "2559",
        population_proper: "2559"
    },
    {
        city: "Denmark",
        lat: "-34.9600",
        lng: "117.3531",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "2558",
        population_proper: "2558"
    },
    {
        city: "Bonville",
        lat: "-30.3833",
        lng: "153.0500",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2554",
        population_proper: "2554"
    },
    {
        city: "Walcha",
        lat: "-30.9833",
        lng: "151.6000",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2553",
        population_proper: "2553"
    },
    {
        city: "Manilla",
        lat: "-30.7483",
        lng: "150.7178",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2550",
        population_proper: "2550"
    },
    {
        city: "Yarra Junction",
        lat: "-37.7790",
        lng: "145.6060",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "2549",
        population_proper: "2549"
    },
    {
        city: "Daylesford",
        lat: "-37.3500",
        lng: "144.1500",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "2548",
        population_proper: "2548"
    },
    {
        city: "York",
        lat: "-31.8883",
        lng: "116.7686",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "2548",
        population_proper: "2535"
    },
    {
        city: "Anglesea",
        lat: "-38.4064",
        lng: "144.1869",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "2545",
        population_proper: "2545"
    },
    {
        city: "Kambalda",
        lat: "-31.2000",
        lng: "121.6700",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "2539",
        population_proper: "2539"
    },
    {
        city: "Lobethal",
        lat: "-34.9000",
        lng: "138.8667",
        country: "Australia",
        iso2: "AU",
        admin_name: "South Australia",
        capital: "",
        population: "2527",
        population_proper: "2527"
    },
    {
        city: "The Oaks",
        lat: "-34.0817",
        lng: "150.5678",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2525",
        population_proper: "2525"
    },
    {
        city: "Gulgong",
        lat: "-32.3650",
        lng: "149.5344",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2521",
        population_proper: "2521"
    },
    {
        city: "Finley",
        lat: "-35.6317",
        lng: "145.5678",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2519",
        population_proper: "2519"
    },
    {
        city: "Capel",
        lat: "-33.5570",
        lng: "115.5700",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "2509",
        population_proper: "2509"
    },
    {
        city: "Korora",
        lat: "-30.2503",
        lng: "153.1156",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2488",
        population_proper: "2488"
    },
    {
        city: "Exmouth",
        lat: "-21.9331",
        lng: "114.1281",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "2486",
        population_proper: "2486"
    },
    {
        city: "Boonah",
        lat: "-27.9997",
        lng: "152.6816",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "2484",
        population_proper: "2484"
    },
    {
        city: "Saint Leonards",
        lat: "-38.1667",
        lng: "144.7167",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "2480",
        population_proper: "2480"
    },
    {
        city: "Vasse",
        lat: "-33.6900",
        lng: "115.3000",
        country: "Australia",
        iso2: "AU",
        admin_name: "Western Australia",
        capital: "",
        population: "2479",
        population_proper: "2479"
    },
    {
        city: "Aberdare",
        lat: "-32.8428",
        lng: "151.3803",
        country: "Australia",
        iso2: "AU",
        admin_name: "New South Wales",
        capital: "",
        population: "2473",
        population_proper: "2473"
    },
    {
        city: "Bunyip",
        lat: "-38.0833",
        lng: "145.7170",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "2468",
        population_proper: "2468"
    },
    {
        city: "Dodges Ferry",
        lat: "-42.8539",
        lng: "147.6194",
        country: "Australia",
        iso2: "AU",
        admin_name: "Tasmania",
        capital: "",
        population: "2467",
        population_proper: "2467"
    },
    {
        city: "Lancefield",
        lat: "-37.2667",
        lng: "144.7167",
        country: "Australia",
        iso2: "AU",
        admin_name: "Victoria",
        capital: "",
        population: "2455",
        population_proper: "2455"
    },
    {
        city: "Palm Island",
        lat: "-18.7345",
        lng: "146.5794",
        country: "Australia",
        iso2: "AU",
        admin_name: "Queensland",
        capital: "",
        population: "2455",
        population_proper: "2455"
    },
    {
        city: "Currie",
        lat: "-39.9311",
        lng: "143.8510",
        country: "Australia",
        iso2: "AU",
        admin_name: "Tasmania",
        capital: "minor",
        population: "768",
        population_proper: "768"
    }
];

export const QUESTION_DIFFICULTY_LEVEL = [
    {
        label: "Allow creator to select",
        value: 0
    },
    {
        label: "Level 1",
        value: 1
    },
    {
        label: "Level 2",
        value: 2
    },
    {
        label: "Level 3",
        value: 3
    },
    {
        label: "Level 4",
        value: 4
    }
];

export const PROOFREADER_STATUS = {
    0: "not started",
    1: "commented and approved",
    2: "approved"
}

export const CHECKLIST_PUBLISHING = [
    {
        value: 1,
        label: "Confirm the subject and question type (number of options/ TTC MR 5 options, TS 4 options)",
        subCategory: "Subject & Question Type",
        category: "General"
    },
    {
        value: 2,
        label: "Use Australian English for spelling and terminology",
        subCategory: "Subject & Question Type",
        category: "General"
    }, 
    {
        value: 3,
        label: "Check and fix typos",
        subCategory: "Typos",
        category: "Spelling"
    }, 
    {
        value: 4,
        label: "Use English spelling, not American spelling",
        subCategory: "Australian English (G)",
        category: "Spelling"
    }, 
    {
        value: 5,
        label: "Ensure correct punctuation",
        subCategory: "Punctuation",
        category: "Grammar"
    },
    {
        value: 6,
        label: "Use grammatically correct sentence structure",
        subCategory: "Sentence Structure",
        category: "Grammar"
    },
    {
        value: 7,
        label: "Use the correct tense",
        subCategory: "Tense",
        category: "Grammar"
    },
    {
        value: 8,
        label: "Use the correct plural or singular forms",
        subCategory: "Plural/Single Using",
        category: "Grammar"
    },
    {
        value: 9,
        label: "Use appropriate articles",
        subCategory: "Article",
        category: "Grammar"
    },
    {
        value: 10,
        label: "Ensure proper capitalization",
        subCategory: "Capitalization",
        category: "Formatting"
    },
    {
        value: 11,
        label: "Add units where necessary",
        subCategory: "Units",
        category: "Formatting"
    },
    {
        value: 12,
        label: "Maintain consistency in formatting and presentation",
        subCategory: "Consistency",
        category: "Formatting"
    },
    {
        value: 13,
        label: "Check for wrong or confusing information and reword or correct it",
        subCategory: "Wrong Information",
        category: "Content"
    },
    {
        value: 14,
        label: "Avoid ambiguous questions and provide clear context",
        subCategory: "Ambiguity",
        category: "Content"
    },
    {
        value: 15,
        label: "Avoid sensitive topics and reword questions if needed",
        subCategory: "Sensitive Topics",
        category: "Content"
    },
    {
        value: 16,
        label: "Ensure all necessary information is provided",
        subCategory: "Missing Information",
        category: "Content"
    },
    {
        value: 17,
        label: "Provide clear, easy-to-understand, and accurate diagrams and images",
        subCategory: "Clarity",
        category: "Diagrams & Images"
    },
    {
        value: 18,
        label: "Ensure diagrams and images are complete and include all required parts",
        subCategory: "Completeness",
        category: "Diagrams & Images"
    },
    {
        value: 19,
        label: "Use appropriate titles for passages",
        subCategory: "Titles",
        category: "Passages (Reading)"
    },
    {
        value: 20,
        label: "Avoid sensitive topics in passages",
        subCategory: "Content",
        category: "Passages (Reading)"
    },
    {
        value: 21,
        label: "Provide clear and concise explanations and solutions",
        subCategory: "Clarity",
        category: "Explanations & Solutions"
    },
    {
        value: 22,
        label: "Ensure explanations and solutions are complete and address all aspects of the question",
        subCategory: "Completeness",
        category: "Explanations & Solutions"
    },
    {
        value: 23,
        label: "Verify the correctness of explanations and solutions",
        subCategory: "Accuracy",
        category: "Explanations & Solutions"
    }
];