import React from "react"
import _ from "lodash"
import { Table } from "reactstrap"

import ImageManipulator from '../../Question/view/ImageManipulator'

import { QUESTION_TYPE } from "../../../util/Constant"
import { computeImageUrl } from "../../../util/helper"

const Response = props => {
    const { type, text, response, answers, xCoordinateOptions, yCoordinateOptions, hotSpotOptions, imageUrl } = props

    if (!response || !type) return null

    let dropZones = []
    if (QUESTION_TYPE.DROPZONE_DRAG_DROP === type) {
        try {
            const responseParsed = JSON.parse(response)
			const groupZone = _.groupBy(responseParsed, item => item.matchingText)
			for (const answer of answers) {
				dropZones = [...dropZones, {zone: answer.matchingText, items: []}]
			}
			dropZones.forEach(element => {
				if (groupZone[element.zone]) {
					element.items = groupZone[element.zone].map(item => item.text)
				}
			})
        } catch (err) {
            console.log(err.message)
        }
    }

    let answer = null
    if ([QUESTION_TYPE.SINGLE_CHOICE, QUESTION_TYPE.HOT_TEXT].includes(type)) {
        answer = answers.find(ans => ans.id == response)
    }

    let orderedAnswers = []
    if (QUESTION_TYPE.INLINE_ORDER_DRAG_DROP === type) {
        try {
            const responseParsed = JSON.parse(response)
            orderedAnswers = responseParsed.map(item => answers.find(ans => ans.id == item)).filter(Boolean)
        } catch (err) {
            console.log(err.message)
        }
    }

    let blankTexts = []
    if ([
        QUESTION_TYPE.FILL_IN_THE_BLANK_TEXT,
        QUESTION_TYPE.FILL_IN_THE_BLANK_DROPDOWN
    ].includes(type)) {
        try {
            blankTexts = JSON.parse(response)
        } catch (err) {
            console.log(err.message)
        }
    }

    if (QUESTION_TYPE.FILL_IN_BLANK_DRAG_DROP === type) {
        try {
			const blankArrays = text.match(/{blank\d}/g)
			blankArrays.forEach(blank => {
				blankTexts = [...blankTexts, {blank: blank.replace(/[{}]/g,''), items: []}]
			});
            const responseParsed = JSON.parse(response)
			const groupBlank = _.groupBy(responseParsed, res => res.token)
			blankTexts.forEach(element => {
				if (groupBlank[element.blank]) {
					element.items = groupBlank[element.blank].map(item => item.answer)
				}
			})
        } catch (err) {
            console.log(err.message)
        }
    }

    let matrixOptions = []
    if ([QUESTION_TYPE.MATRIX_RADIO, QUESTION_TYPE.MATRIX_CHECKBOX].includes(type)) {
        try {
            const responseParsed = JSON.parse(response)
            responseParsed.map(item => {
                const x = parseInt(item.split(",")[0])
                const y = parseInt(item.split(",")[1])
                matrixOptions = [...matrixOptions, xCoordinateOptions[x].text + "<p class=\"mx-2\">-</p>" + yCoordinateOptions[y].text]
            })
        } catch (err) {
            console.log(err.message)
        }
    }

    let matchingPairs = []
    if (QUESTION_TYPE.MATCH_THE_PAIRS === type) {
        try {
            matchingPairs = JSON.parse(response)
        } catch (err) {
            console.log(err.message)
        }
    }

    let multiAnswers = []
    if (QUESTION_TYPE.MULTIPLE_CHOICE === type) {
        try {
            const responseParsed = JSON.parse(response)
            multiAnswers = answers.filter(ans => responseParsed.includes(ans.id))
        } catch (err) {
            console.log(err.message)
        }
    }

    let spots = []
    if (QUESTION_TYPE.HOTSPOT === type) {
        try {
            const responseParsed = JSON.parse(response)
            spots = hotSpotOptions
                .filter(option => responseParsed.includes(option.id))
                .map(option => ({ ...option, x: option.xCoordinate, y: option.yCoordinate }))
        } catch (err) {
            console.log(err.message)
        }
    }

    let hotSpotDragDropAnswers = null
    if (QUESTION_TYPE.HOTSPOT_DRAG_DROP === type) {
        try {
            const responseParsed = JSON.parse(response)
            spots = hotSpotOptions.map(item => ({ ...item, x: item.xCoordinate, y: item.yCoordinate }))
            hotSpotDragDropAnswers = responseParsed.map(item => ({
                hotSpotIds: item.hotSpotIds,
                answer: answers.find(ans => ans.id === item.answerId)
            }))
        } catch (err) {
            console.log(err.message)
        }
    }

    return <div className="mb-2">
        {[
            QUESTION_TYPE.EXTENDED_RESPONSE,
            QUESTION_TYPE.SHORT_ANSWER
        ].includes(type) && <div dangerouslySetInnerHTML={{ __html: response }}/>}

        {QUESTION_TYPE.DROPZONE_DRAG_DROP === type && <div>
            <Table hover bordered striped responsive className="grid-table mb-2" >
                <thead>
                    <tr>
						{dropZones
							.filter(dropZone => 
								dropZone.zone !== "undefined" && 
								dropZone.zone !== "null")
							.map((dropZone, index) => 
								<th key={index}>
									{dropZone.zone}
								</th>)}
                    </tr>
                </thead>
                <tbody>
                    <tr>
						{dropZones
							.filter(dropZone =>
								dropZone.zone !== "undefined" &&
								dropZone.zone !== "null")
							.map((dropZone, index) => 
								<td key={index}>
									{dropZone.items
										.map((item, i) => 
											<div key={i} className="p-mb-2" dangerouslySetInnerHTML={{ __html: item }} />)}
								</td>)}
                    </tr>
                </tbody>
            </Table>
        </div>}

        {[QUESTION_TYPE.SINGLE_CHOICE].includes(type) && <div className="p-mb-2" dangerouslySetInnerHTML={{ __html: answer.text }} />}

        {QUESTION_TYPE.HOT_TEXT === type && <div>
            {answer && <span>{`${answer.text} (${answer.token})`}</span>}
        </div>}

        {QUESTION_TYPE.INLINE_ORDER_DRAG_DROP === type && <div className="p-mb-2 d-flex">
            {orderedAnswers.map((ans, index) => <div className="mr-2" key={index} dangerouslySetInnerHTML={{ __html: ans.text }} />)}
        </div>}

        {[
            QUESTION_TYPE.FILL_IN_THE_BLANK_TEXT,
            QUESTION_TYPE.FILL_IN_THE_BLANK_DROPDOWN
        ].includes(type) && <div>
                {blankTexts.map((item, index) => <p className="mb-2" key={index}>{`${item.token} = ${item.answer}`}</p>)}
            </div>}

        {QUESTION_TYPE.FILL_IN_BLANK_DRAG_DROP === type && <div>
            <Table hover bordered striped responsive className="grid-table mb-2" >
                <thead>
                    <tr>
						{blankTexts
						.filter(blankText => 
							blankText.blank !== "null" && 
							blankText.blank !== "undefined")
							.map((blankText, index) => 
								<th key={index}>
									{blankText.blank}
								</th>)}
                    </tr>
                </thead>
                <tbody>
                    <tr>
						{blankTexts
							.filter(blankText => 
								blankText.blank !== "null" && 
								blankText.blank !== "undefined")
							.map((blankText, index) => 
								<td key={index}>
									{blankText.items
										.map((item, i) => 
											<div key={i} className="p-mb-2" dangerouslySetInnerHTML={{ __html: item }} />)}
								</td>)}
                    </tr>
                </tbody>
            </Table>
        </div>}

        {[QUESTION_TYPE.MATRIX_RADIO, QUESTION_TYPE.MATRIX_CHECKBOX].includes(type) && <div className="p-mb-2">
            {matrixOptions.map((item, index) => <div key={index} className="p-mb-2 d-flex align-items-center" dangerouslySetInnerHTML={{ __html: item }} />)}
        </div>}

        {QUESTION_TYPE.MATCH_THE_PAIRS === type && <div>
            {matchingPairs.map((pair, index) => <div
                key={index}
                className="p-mb-2 d-flex align-items-center"
                dangerouslySetInnerHTML={{ __html: pair.text + "<p>-----</p>" + pair.matchingText }}
            />)}
        </div>}

        {QUESTION_TYPE.MULTIPLE_CHOICE === type && <div className="d-flex align-items-center">
            {multiAnswers.map((ans, index) => <React.Fragment key={index}>
                <div className="p-mb-2" dangerouslySetInnerHTML={{ __html: ans.text }} />
                {index < multiAnswers.length - 1 && <p className="mr-2 mb-0">,</p>}
            </React.Fragment>)}
        </div>}

        {[QUESTION_TYPE.HOTSPOT, QUESTION_TYPE.HOTSPOT_DRAG_DROP].includes(type) && <div>
            <ImageManipulator
                isDisabled
                fileUrl={computeImageUrl(imageUrl)}
                spots={spots}
                setSpots={() => { }}
                hotSpotDragDropAnswers={hotSpotDragDropAnswers}
            />
        </div>}
    </div>
}

export default Response