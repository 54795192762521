import React, { Component, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import moment from "moment";
import { withTranslation } from 'react-i18next'

import {
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarNav,
} from "@coreui/react";
import AutoCloseSideBarMinimizer from "../../../components/AutoCloseSideBarMinimizer";
import navigation from "../navigation";
import routes from "../routes";
import { canAccess, firstCheckToken, isTeacherOrAssistanceOnly, isAdminOrSalary, isParentOrStudent, isQuestionCreator, isProofReader } from "../../../util/Common";
import Loading from "../../../components/Loading";
import Header from "../../../components/Header";
import { EXPIRE_TIME, ACCESS_TOKEN } from "../../../util/Constant";
import history from "../../../util/history";
import Page404 from "../../../components/Page404";

const forgotPasswordPath = '/forgot-password'
class DefaultLayout extends Component {
  async componentDidMount() {
    console.log(345)
    const token = await firstCheckToken()

    if (!token) {
      if (forgotPasswordPath === window.location.pathname) {
        history.push(forgotPasswordPath)
      } else {

        history.push(`/login?redirectUrl=${window.location.href}`);
      }
    } else {
      this.loadInfo()
    }
    this.getSize()
    this.setSessionTimeout();
  }

  loadInfo = async () => {
    try {
      const info = await this.props.getUserInfo();
      if (!info) {
        localStorage.removeItem(ACCESS_TOKEN);
        history.push("/login");
      }
    } catch (error) {
      localStorage.removeItem(ACCESS_TOKEN);
      history.push("/login");
    }
  }

  getSize = () => setTimeout(() => this.el && this.props.setTableZoneWidth(this.el.getBoundingClientRect().width), 500);

  setSessionTimeout = () => {
    try {
      const currentTime = moment().valueOf();
      const expireTime = Number(localStorage.getItem(EXPIRE_TIME));
      if (expireTime > currentTime) {
        setTimeout(() => {
          this.props.setAlert({
            message: "session_expired",
            type: "danger",
          });
          this.props.logout();
        }, expireTime - currentTime);
      }
    } catch (err) {
    }
  };

  signOut = e => {
    if (e) {
      e.preventDefault();
    }
    this.props.logout();
  };

  render() {
    const { userInfo, t, location: { pathname } } = this.props;
    const userRoles = userInfo && userInfo.roles
    const filterNavigation = navigation.items(t)
      .filter(({ roles }) => canAccess(userRoles, roles))
      .map((item) => {
        if (item.children) {
          return {
            ...item,
            children: item.children.filter(({ roles }) => canAccess(userRoles, roles))
          }
        } 

        return item
      })

    if (pathname === '/question-bank/my-task') {
      const urlParams = new URLSearchParams(window.location.search);
      const emailReceivedTask = urlParams.get("email");

      if (emailReceivedTask) {
        const currentUser = window.localStorage.getItem("USER_EMAIL");

        if (emailReceivedTask !== currentUser) {
          this.signOut();

          return "Logout...";
        }
      }
    }
   
    if (!userInfo) {
      return (
        <Loading />
      )
    }
    const adminSalary = isAdminOrSalary();
    let rs = routes;
    if (!adminSalary) {
      rs = rs.filter(f => f.name !== "TeacherSalary")
    }

    const salary = isTeacherOrAssistanceOnly()

    const info = isParentOrStudent()

    const noMarginLeft = (salary || info) ? { marginLeft: 0 } : {}
    return (
      <div className="app">
        <AppHeader fixed>
          <Suspense fallback={() => <Loading />}>
            <Header onLogout={this.signOut} />
          </Suspense>
        </AppHeader>
        <div className="app-body">
          {!salary && !info && <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense fallback={() => <Loading />}>
              <AppSidebarNav navConfig={{ items: filterNavigation }} {...this.props} />
            </Suspense>
            <AppSidebarFooter />
            <AutoCloseSideBarMinimizer getSize={this.getSize} />
          </AppSidebar>}
          <main className="main bg-white" ref={ref => {
            this.el = ref
          }} style={noMarginLeft}>
            <Container fluid className="px-0">
              <Suspense fallback={() => <Loading />}>
                <Switch>
                  {!salary && !info && rs.map((route, idx) => (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      component={route.component}
                    />)
                  )}
                  {isQuestionCreator() && <Redirect from="/" to="/question-bank/my-task" />}
                  {isProofReader() && <Redirect from="/" to="/question-bank/question-review" />}
                  {isAdminOrSalary() && <Redirect from="/" to="/user-family/users" />}
                  {!salary && !info && !isQuestionCreator() && !isProofReader() && <Redirect from="/" to="/question-bank/assessments" />}

                  <Route
                    path="*"
                    component={<Page404 />}
                  />
                </Switch>
              </Suspense>
            </Container>
          </main>
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(DefaultLayout)