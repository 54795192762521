import React from "react";

const CustomRadio = ({ checked, label }) => {
    return (
        <>
            <div
                style={{
                    display: "inline-block",
                    width: "40px",
                    height: "40px",
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    boxShadow: "inset 0 0 5.9px 0 rgb(0 0 0 / 30%)",
                    background: "#fff",
                    position: "relative",
                    verticalAlign: "middle",
                    // marginTop: '-20px',
                    borderRadius: "50%",
                    marginRight: "15px"
                }}
            >
                {checked && (
                    <div
                        style={{
                            backgroundColor: "#1798fa",
                            width: "20px",
                            height: "20px",
                            display: "block",
                            borderRadius: "50%",
                            border: "10px solid #1798fa",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)"
                            // marginTop: '-10px',
                            // left: '10px',
                        }}
                    />
                )}
            </div>
            <span>
                <div
                    style={{
                        display: "inline-block"
                    }}
                    dangerouslySetInnerHTML={{
                        __html: label
                    }}
                ></div>
            </span>
        </>
    );
};

export default CustomRadio;
