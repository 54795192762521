import React, { useEffect } from "react"
import { FormGroup, Label } from "reactstrap"
import Select from "react-select"

import { questionTypes, QUESTION_TYPE } from "../../util/Constant"

const QuestionTypeSelector = props => {
    const { noLabel, label, required, selectOptions, noComposite, isDisabled } = props

    let options = questionTypes.map(item => ({ label: item.name, value: item.value }))

    if (noComposite) {
        options = options.filter(item => item.value !== QUESTION_TYPE.COMPOSITE)
    }

    return <FormGroup>
        {!noLabel && <Label>{label || "Question type"}{required && <span style={{ color: 'red' }}> *</span>}</Label>}
        <Select
            isDisabled={isDisabled}
            placeholder="Select type"
            options={selectOptions || options}
            {...props}
        />
    </FormGroup>
}

export default QuestionTypeSelector