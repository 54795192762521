import React, { Component } from "react";
import {
  Col,
  Container,
  Row,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { DOCUMENT_TITLE } from '../util/Constant'

class Page404 extends Component {
  constructor(props) {
    super(props);
    document.title = props.t(DOCUMENT_TITLE.page_404)
  };

  render() {

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <div className="clearfix">
                <h1 className="float-left display-3 mr-4">404</h1>
                <h4 className="pt-3">Oops! You&apos;re lost.</h4>
                <p className="text-muted float-left">The page you are looking for was not found.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withTranslation('common')(Page404);
