import { getAssessmentResponses, getAssessmentResponseWithResults, updateResponseEvaluation, updateAssessmentResponseStatus } from "../../../util/api"
import setLoading from "../../../components/Spinner/action"
import setModal from "../../../components/Modal/action"
import setAlert from "../../../components/Alert/action"

export const get = ({ id, cb }) => async dispatch => {
    dispatch(setLoading(true))
    try {
        const res = await getAssessmentResponseWithResults(id)
        if (cb) cb(res.data)
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: [err.response && err.response.data, "get_assessment_response_error"]
        }))
    }

    dispatch(setLoading(false))
}


export const getList = ({ assessmentAssignmentId, currentPage, pageSize }) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await getAssessmentResponses({ assessmentAssignmentId, currentPage, pageSize });
        dispatch({
            type: "SET_ASSESSMENT_RESPONSE_LIST",
            payload: res.data.data,
            currentPage: res.data.currentPage,
            totalItems: res.data.totalItems,
            pageCount: res.data.pageCount,
        })
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: [err.response && err.response.data, "get_assessment_error"]
        }))
    }

    dispatch(setLoading(false))
}

export const updateResponseEvaluationAction = (data, callback) => async dispatch => {
    dispatch(setLoading(true))
    try {
        const res = await updateResponseEvaluation(data)
        if (callback) callback(res.data)
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: [err.response && err.response.data, "update_questionresponse_evaluation_error"]
        }))
    }
    dispatch(setLoading(false))
}

export const updateAssessmentResponseStatusAction = (id, data, callback) => async dispatch => {
    dispatch(setLoading(true))
    try {
        const res = await updateAssessmentResponseStatus(id, data)
        if (callback) callback(res.data)
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: [err.response && err.response.data, "update_questionresponse_evaluation_error"]
        }))
    }
    dispatch(setLoading(false))
}
