export default t => ({
    information: null,

    email: {
        field: "email",
        display: t('Email/login_name'),
        value: "",
        errorMessage: "",
        invalid: false,
    },
    firstName: {
        field: "firstName",
        display: t('first_name'),
        value: "",
        errorMessage: "",
        invalid: false,
    },
    isActive: {
        field: "isActive",
        display: t("Active"),
        value: true,
        errorMessage: "",
        invalid: false,
    },
    lastName: {
        field: "lastName",
        display: t("Last_name"),
        value: "",
        errorMessage: "",
        invalid: false,
    },
    additionalEmail: {
        field: "additionalEmail",
        display: t("Additional_email"),
        value: "",
        errorMessage: "",
        invalid: false
    },
    homePhone: {
        field: "homePhone",
        display: t("Home_phone"),
        value: "",
        errorMessage: "",
        invalid: false
    },
    workOfficePhone: {
        field: "workOfficePhone",
        display: t("Work_office_phone"),
        value: "",
        errorMessage: "",
        invalid: false
    },
    birthday: {
        field: "birthday",
        display: t("Birthday"),
        value: "",
        errorMessage: "",
        invalid: false,
    },
    grade: {
        field: "grade",
        display: t("grade"),
        value: "",
        errorMessage: "",
        invalid: false,
    },
    gender: {
        field: "gender",
        display: t("Gender"),
        value: 0,
        errorMessage: "",
        invalid: false,
    },
    phoneNumber: {
        field: "phoneNumber",
        display: t("Cell_phone"),
        value: "",
        errorMessage: "",
        invalid: false,
    },
    roles: {
        field: "roles",
        display: t("Roles"),
        value: [],
        errorMessage: "",
        invalid: false,
    },
    note: {
        field: "note",
        display: t("Note"),
        value: "",
        errorMessage: "",
        invalid: false,
    },

    // teacher salary
    mainSalary: {
        field: "mainSalary",
        display: t("Main_salary"),
        value: 0,
        errorMessage: "",
        invalid: false,
    },
    specialSalary: {
        field: "specialSalary",
        display: t("Special_salary"),
        value: 0,
        errorMessage: "",
        invalid: false,
    },
    salaryInvoice: {
        field: "salaryInvoice",
        display: t("Salary_via_invoice"),
        value: false,
        errorMessage: "",
        invalid: false,
    },
    ssn: {
        field: "ssn",
        display: "SSN",
        value: "",
        errorMessage: "",
        invalid: false,
    },
    // end teacher salary

    // assistance salary
    assistanceMainSalary: {
        field: "assistanceMainSalary",
        display: t('assistance_main_salary'),
        value: 0,
        errorMessage: "",
        invalid: false,
    },
    assistanceSpecialSalary: {
        field: "assistanceSpecialSalary",
        display: t('assistance_special_salary'),
        value: 0,
        errorMessage: "",
        invalid: false,
    },
    assistanceSalaryInvoice: {
        field: "assistanceSalaryInvoice",
        display: t("Assistance_salary_via_invoice"),
        value: false,
        errorMessage: "",
        invalid: false,
    },
    assistanceSSN: {
        field: "assistanceSSN",
        display: "Assistance SSN",
        value: "",
        errorMessage: "",
        invalid: false,
    },
    // end assistance salary

    skill: {
        field: "teacherSkills",
        display: t("skill"),
        value: "",
        errorMessage: "",
        invalid: false,
    },
    createdAt: "",
    updatedAt: "",
    courseTypes: {
        field: "courseTypes",
        display: t('course_types'),
        value: "",
        errorMessage: "",
        invalid: false,
    },
    emergencyIs: {
        field: "emergencyIs",
        display: "EmergencyIs",
        value: "",
        errorMessage: "",
        invalid: false,
    },
    emergencyFirstName: {
        field: "emergencyFirstName",
        display: t('emergency_first_name'),
        value: "",
        errorMessage: "",
        invalid: false,
    },
    emergencyLastName: {
        field: "emergencyLastName",
        display: t('emergency_last_name'),
        value: "",
        errorMessage: "",
        invalid: false,
    },
    emergencyPhoneNumber: {
        field: "emergencyPhoneNumber",
        display: t('emergency_phone_number'),
        value: "",
        errorMessage: "",
        invalid: false,
    },
    employeeId: {
        field: "employeeId",
        display: t("Employee_id"),
        value: 0,
        errorMessage: "",
        invalid: false,
    },
    oldPassword: {
        field: "oldPassword",
        display: t("old_password"),
        value: "",
        errorMessage: "",
        invalid: false
    },
    newPassword: {
        field: "newPassword",
        display: t("new_password"),
        value: "",
        errorMessage: "",
        invalid: false
    },
    confirmNewPassword: {
        field: "confirmNewPassword",
        display: t("confirm_new_password"),
        value: "",
        errorMessage: "",
        invalid: false
    },
    taxTable: 30,
    taxColumn: 1,
    avatar: null,
    age: 0,
    employed: true,
    isLocked: false,
    family: null,
    course: null,
    userCourses: [],
    syncType: null,
    userPrograms: [],
    courseWaitingTemp: [],
    courseTemp: [],
    programTemp: [],
    year: null,
    sem: null,
    payment: null,
    isOpen: false,
    isOpenWaiting: false,
    hadAvatarChange: false,
    hadFamily: false,
    edited: false,
    viewOnly: false,
    fortnoxCustomer: null,
    fortnoxEmployee: null,
    importStudentId: null,
    receiveCourseInfo: false,
    hasSecret: false
})