import React from "react"
import Select from "react-select"
import { FormFeedback, FormGroup, Label } from "reactstrap"
import { useTranslation } from "react-i18next"

import { gradeOptions } from "../../util/Constant"

export default props => <FormGroup>
    {!props.noLabel && <Label>{props.label || useTranslation("common").t('grade')}  {props.require && <span style={{ color: "red" }}>*</span>}</Label>}
    <Select
        options={gradeOptions}
        placeholder={useTranslation("common").t("select_grade")}
        {...props}
    />
    {props.invalid && (
        <FormFeedback style={{ display: "flex" }}>
            {props.errorMessage}
        </FormFeedback>
    )}
</FormGroup>