import { getAllTestSuitePayment } from "../../../util/api"
import setLoading from "../../../components/Spinner/action"
import setModal from "../../../components/Modal/action"

export const getAllTestSuitePayments = ({
    currentPage,
    pageSize,
    searchString,
    sortBy,
    sortOrder
}) => async (dispatch) => {
    dispatch(setLoading(true))

    try {
        const res = await getAllTestSuitePayment({
            currentPage,
			pageSize,
			searchString,
            sortBy,
            sortOrder
        })

        if (!res.data) throw new Error("Error in getting payment")

        if (Array.isArray(res.data))
            throw new Error(res.data.map((item) => item.value))

        if (pageSize) {
            dispatch({
                type: "SET_TEST_SUITE_PAYMENT_LIST",
                payload: res.data.data,
                // currentPage: res.data.currentPage,
                totalItems: res.data.totalItems,
                pageCount: res.data.pageCount
            })
        }
    } catch (err) {
        dispatch(
            setModal({
                type: "danger",
                message: JSON.stringify(err.response.data)
            })
        )
    }

    dispatch(setLoading(false))
}

export const changePageSizeTestSuitePayment = (pageSize) => async (
    dispatch
) => {
    dispatch({
        type: "CHANGE_PAGE_SIZE_TEST_SUITE_PAYMENT",
        payload: pageSize
    })
}

export const changeCurrentPageTestSuitePayment = (page) => async (dispatch) => {
    dispatch({
        type: "CHANGE_CURRENT_PAGE_TEST_SUITE_PAYMENT",
        payload: page
    })
}
