import React from "react";
import { withTranslation } from 'react-i18next'

// import RemoveUser from "../../RemoveUsers/view/home"
import CustomTabs from "../../../components/CustomTabs";
import UserList from '../../User/userListContainer';
import Subject from "../../Subject/view"

const AdminStaff = () => (
    <UserList isAdminStaff />
)

class Home extends React.Component {
    render() {
        const { t } = this.props

        const title = {
            adminStaff: t('admin_staff'),
            subject: t("subject"),
            tab: t('set_up'),
            generateData: t('generate_data')
        }

        const { adminStaff, subject, tab, generateData } = title

        const tabs = [
            { path: "/setup/admin-staff", title: adminStaff, component: AdminStaff },
            { path: "/setup/subject", title: subject, component: Subject },
            // { path: "/setup/gen-data", title: generateData, component: GenerateData }
        ]

        return (
            <CustomTabs
                {...this.props}
                title={tab}
                tabs={tabs}
            />
        );
    }
}

export default withTranslation('common')(Home)

