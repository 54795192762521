import React, { Component } from "react";
import {
  Col,
  Row,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Select from "react-select";
import { cities} from '../util/Constant';
import { getGroups } from "../util/api";

class AddressFrom extends Component {
  state = {
    addressLine1: {
      field: "addressLine1",
      display: this.props.t('Address_line_1'),
      value: "",
      errorMessage: "",
      invalid: false,
    },
    addressLine2: {
      field: "addressLine2",
      display: this.props.t('Address_line_2'),
      value: "",
      errorMessage: "",
      invalid: false,
    },
    groups: {
      field: "groups",
      display: this.props.t('Groups'),
      value: [],
      errorMessage: "",
      invalid: false,
    },
    city: {
      field: "city",
      display: this.props.t("City"),
      value: "",
      errorMessage: "",
      invalid: false,
    },
    country: {
      field: "country",
      display: this.props.t("Country"),
      value: "Australia",
      errorMessage: "",
      invalid: false,
    },
    zipcode: {
      field: "zipcode",
      display: this.props.t("Post code"),
      value: "",
      errorMessage: "",
      invalid: false,
    },
    groupList: []

  };
  
  componentDidMount = async () => {
    const script = document.createElement('script');
    const data = await getGroups()

    this.setState({ groupList: data.data})
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBrcEj87qKfpY8RfN44GbUzq1YwYjrhruU&libraries=places&callback=initAutocomplete';
    
    document.head.append(script);

    setTimeout(() => {
      window.autocomplete.addListener('place_changed', () => {
        this.setState({ addressLine1: { ...this.state.addressLine1, value: window.autocomplete.getPlace().name } })
        if (this.props.haveEdit) {
          this.props.haveEdit()
        }
      })
    }, 2000);
  }

  setValue = (field, value) => {
    this.setState({
      [field]: { ...this.state[field], value: value || "" },
    });
  };

  // handleChange = () => {
  //   const { addressLine1, addressLine2, city, state, country } = this.state;
  //   const arr = [addressLine1, addressLine2, city, state, country];
  //   arr.forEach(t => {
  //     if (t.value.length === 0 || t.value === 0) {
  //       this.setState({
  //         [t.field]: { ...t, value: "", invalid: true, errorMessage: `${t.display} empty.` },
  //       });
  //     }
  //   });
  // };

  value = () => {
    const { addressLine1, addressLine2, groups, city, country } = this.state;
    const values = [addressLine1, addressLine2, groups ,city, country].map(e => e.value);
    return values.join("/ ");
  };

  singleValue = field => {
    return this.state[field].value;
  };

  render() {
    const { groups, city, country, zipcode, groupList} = this.state;
    const { viewOnly, t } = this.props
    console.log('Current State::::', this.state)
    return (
      <>
        <Row form>
          <Col md={12}>
            <FormGroup>
              <Label for="groups">
                {t('Groups')}
              </Label>
              <Select
                isMulti
                value = {groups.value}
                placeholder={t('Groups')}
                options={groupList.map((e) => {
                  return {
                    label: e.name,
                    value: e.id
                  }
                })}
                onChange={e => {
                    this.setState({ groups: { ...groups, value: e } })
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default AddressFrom;
