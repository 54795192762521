import { useRef } from "react"

const useTimeout = (action, time) => {
    const timeoutRef = useRef()

    const init = () => {
        timeoutRef.current = setTimeout(action, time)
    }

    const clear = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
    }

    return { init, clear }
}

export default useTimeout

