import React, { memo } from 'react'
import { withTranslation } from 'react-i18next'

export default withTranslation('common')(memo(function DeleteBtn(props) {
    return (
        <div
            role="button"
            tabIndex={0}
            title={props.title || (props.isUndo ? props.t('Undo') : props.t('delete'))}
            onClick={props.onClick}
            {...props}>
            <i className={props.isUndo ? "fa fa-undo" : "fa fa-trash"} style={{ fontSize: 16, cursor: 'pointer', color: props.isUndo ? "#04d944" : "#1f1f1f" }} />
        </div>
    )
}))
