import {
    getAllSubscription,
    createSubscription,
    updateSubscription,
    deleteSubscription,
    getSubscription
} from "../../../util/api"
import setLoading from "../../../components/Spinner/action"
import setModal from "../../../components/Modal/action"
import setAlert from "../../../components/Alert/action"

export const getAllSub = ({ currentPage, pageSize, searchString, sortBy, sortOrder, testSuiteId }) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await getAllSubscription({ currentPage, pageSize, searchString, sortBy, sortOrder, testSuiteId })

        if (!res.data) throw new Error("Error in getting subscription")

        if (Array.isArray(res.data)) throw new Error(res.data.map(item => item.value))

        if (pageSize) {
            dispatch({
                type: "SET_SUBSCRIPTION_LIST",
                payload: res.data.data,
                // currentPage: res.data.currentPage,
                totalItems: res.data.totalItems,
                pageCount: res.data.pageCount,
            })
        } else {
            dispatch({
                type: "SET_ALL_SUBSCRIPTION",
                payload: res.data.data
            })
        }
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: JSON.stringify(err.response.data)
        }))
    }

    dispatch(setLoading(false))
}

export const changePageSizeSubscription = pageSize => async dispatch => {
    dispatch({
        type: "CHANGE_PAGE_SIZE_SUBSCRIPTION",
        payload: pageSize
    })
}

export const changeCurrentPageSubscription = page => async dispatch => {
    dispatch({
        type: "CHANGE_CURRENT_PAGE_SUBSCRIPTION",
        payload: page
    })
}

export const createSub = (data, cb) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await createSubscription(data)
        if (!res.data) throw new Error("Error in creating subscription")

        if (Array.isArray(res.data)) throw new Error(res.data.map(item => item.value))

        dispatch(setAlert({
            type: "success",
            message: "create_subscription_success"
        }))
        if (cb) cb()
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: err.response.data
        }))
    }

    dispatch(setLoading(false))
}

export const updateSub = (data, cb) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await updateSubscription(data)
        if (!res.data) throw new Error("Error in updating subscription")

        if (Array.isArray(res.data)) throw new Error(res.data.map(item => item.value))

        dispatch(setAlert({
            type: "success",
            message: "update_subscription_success"
        }))
        if (cb) cb()
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: err.response.data
        }))
    }

    dispatch(setLoading(false))
}

export const removeSub = (id, cb) => async dispatch => {
    dispatch(setLoading(true))

    try {
        const res = await deleteSubscription(id)
        if (res.status !== 200) throw new Error("Error in deleting subscription")

        dispatch(setAlert({
            type: "success",
            message: "delete_subscription_success"
        }))

        if (cb) cb()
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: JSON.stringify(err.message)
        }))
    }

    dispatch(setLoading(false))
}

export const getSub = id => async dispatch => {
    dispatch(setLoading(true))
    let res = null

    try {
        res = await getSubscription(id)
        if (!res.data) throw new Error("Error in creating subscription")

        if (Array.isArray(res.data)) throw new Error(res.data.map(item => item.value))
    } catch (err) {
        dispatch(setModal({
            type: "danger",
            message: JSON.stringify(err.message)
        }))
    }

    dispatch(setLoading(false))
    return res
}