import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Select from "react-select";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Box from "@mui/material/Box";
import {
    Alert,
    Button,
    Col,
    CustomInput,
    FormGroup,
    Input,
    Label,
    Row,
    CardTitle
} from "reactstrap";
import useMutation from "../../hooks/useMutation";
import ArticleSelector from "../../components/Selector/ArticleSelector";
import {
    getCBTQuestion,
    updateCBTQuestion,
    markQuestionReadyForTest
} from "../../util/api";
import AnswerList from "../Question/view/AnswerList";
import setLoading from "../../components/Spinner/action";
import ProwritingEditor, { TINY_MCE_API_KEY } from "./ProwritingEditor";
import Timer from "../../components/Timer";
import CategorySelector from "../../components/CategorySelector";
import useAlert from "../../hooks/useAlert";
import { Editor } from "@tinymce/tinymce-react";

const questionTypes = [
    { label: "Single Choice", value: 0 },
    { label: "Multiple Choice", value: 1 }
];
const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"


const EditQuestion = ({ t }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { id: questionIdParam } = useParams();
    const allArticles = useSelector(state => state.article.allArticles);
    const { showSuccess, showError, showWarning } = useAlert();
    const [question, setQuestion] = useState(null);
    const [isChecking, setIsChecking] = useState(true);
    const [alert, setAlert] = useState("");
    const [msg, setMsg] = useState(null); // { type: primary | danger, value: string }
    const questionText = useRef();
    const instruction = useRef();
    const solution = useRef();
    const questionProposeRef = useRef(null);
    const questionSyllabus = useRef(null);

    const [noSetInitValue, setNoSetInitValue] = useState(false);
    const currentEdittingEditorIdRef = useRef();
    const timeInSeconds = useRef(0);
    const [wizardDialog, setWizardDialog] = useState(0);
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const [isClickPublish, setIsClickPublish] = useState(false);
    const [isShowInstruction, setIsShowInstruction] = useState(false);
    const [isShowQuestionPurpose, setIsShowQuestionPurpose] = useState(true);
    const [isShowArticle, setIsShowArticle] = useState(false);
    let isEnoughAnswer;
    const { mutate: handleMarkQuestionReadyForTest } = useMutation({
        mutationFn: markQuestionReadyForTest,
        onSuccess: () => {
            showSuccess("Successfully!");
        },
        onError: err => {
            showError("Failed: ", err.response.data.Message);
        }
    });
    const [errors, setErrors] = useState({});
    const [enableShowError, setEnableShowError] = useState(false);

    const getQuestion = async () => {
        dispatch(setLoading(true));

        try {
            const res = await getCBTQuestion(questionIdParam);

            if (res && res.data) {
                setQuestion({
                    ...res.data,
                    answer: JSON.parse(res.data.answer)
                });

                setNoSetInitValue(true);
            } else {
                setAlert("No question found!");
            }
        } catch (error) { }

        setIsChecking(false);
        dispatch(setLoading(false));
    };

    const steps = ["Step 1", "Step 2", "Step 3", "Step 4"];
    const checkListStep1 = [
        { label: "Spelling errors and typos", type: "general" },
        {
            label: "Grammatical errors in the wording of questions",
            type: "general"
        },
        { label: "Punctuation needs to be correct", type: "general" },
        {
            label: "All questions should be clear and understandable",
            type: "general"
        },
        {
            label: "All questions should be relevant to a topic",
            type: "general"
        },
        {
            label: "Use Australian English for spelling and terminology",
            type: "general"
        }
    ];

    const checkListStep2 = [
        {
            label: "Confirm the subject and question type (number of options)",
            type: "general"
        },
        {
            label:
                "Ensure proper punctuation, sentence structure, tense, plural/singular forms, and articles",
            type: "general"
        },
        {
            label:
                "Maintain consistency in formatting, capitalization, and units.",
            type: "general"
        },
        {
            label:
                "Avoid wrong/confusing information, ambiguous questions, and sensitive topics.",
            type: "general"
        },
        {
            label:
                "Images need to be clear and not slanted or cutting into the margin.",
            type: "general"
        },
        {
            label: "Ensure all necessary information is provided",
            type: "general"
        }
    ];

    const checkListStep3Total = [
        //Answer
        { label: "All answers to questions must be correct", type: "general" },
        {
            label: "Solutions for questions are provided and correct",
            type: "general"
        },
        {
            label:
                "Each question has only 1 DEFINITE or BEST answer unless specified.",
            type: "general"
        },
        {
            label:
                "Units need to be specified in MR answers unless indicated otherwise.",
            type: "MR"
        },
        {
            label:
                "Diagrams are provided for questions which indicate specifically the need for a diagram.",
            type: "MR"
        },
        { label: "Diagrams are clear and easy to understand", type: "MR" },
        {
            label: "Diagrams are correct, all required parts are visible.",
            type: "MR"
        },
        {
            label: "Answer options for Mathematical Reasoning: 5 options",
            type: "MR",
            choiceType: "Multi"
        },
        {
            label: "Answer options for Thinking Skills: 4 options",
            type: "TS",
            choiceType: "Multi"
        },
        {
            label: "Passages for English must have titles unless specified",
            type: "R"
        },
        {
            label: "Answer options for Reading: 4 options",
            type: "R",
            choiceType: "Multi"
        }
    ];
    const urlParams = new URLSearchParams(window.location.search);
    const origin = urlParams.get("origin");

    let checkListStep3;
    const initValidate1 = {};
    const initValidate2 = {};
    const initValidate3 = {};

    useEffect(() => {
        getQuestion();
        // if (origin === "preview-question"){
        //     setWizardDialog(1);
        //     setIsClickPublish(true);
        // }
    }, []);

    useEffect(() => {
        if (question && question.instruction) {
            setIsShowInstruction(true)
        }
        if (question && question.purpose === "") {
            setIsShowQuestionPurpose(false)
        }
        if (question && question.articleId) {
            setIsShowArticle(true)
        }
    }, [question])

    const initValidate = [initValidate1, initValidate2, initValidate3];
    const [checkList1Validation, setCheckList1Validation] = useState(
        initValidate
    );
    const initConfirmStep = {
        0: false,
        1: false,
        2: false,
        3: false
    };
    const [isConfirmStep, setIsConfirmStep] = useState(initConfirmStep);

    const toggleDialog = () => {
        setWizardDialog(0);
        setActiveStep(0);
        setCompleted({});
        setCheckList1Validation(initValidate);
        setIsConfirmStep({ ...initConfirmStep });
        // setIsClickPublish(false);
    };

    const handleCompleted = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
    };

    const onCancel = () => {
        toggleDialog();
    };

    const handleCheckBoxChange = (e, index, step) => {
        const newcheckList1Validation = checkList1Validation;
        newcheckList1Validation[step - 1][index] = e.target.checked;
        setCheckList1Validation({ ...newcheckList1Validation });
    };
    const renderCheckList = inputArray => {
        return inputArray.map((label, index) => {
            return (
                <React.Fragment key={label.label}>
                    <Row className="ml-4">
                        <FormGroup className="ml-4 mb-2">
                            <Label className="mb-0 d-flex align-items-start cursor-pointer">
                                <Input
                                    type="checkbox"
                                    className="cursor-pointer mt-0"
                                    onChange={e =>
                                        handleCheckBoxChange(
                                            e,
                                            index,
                                            wizardDialog
                                        )
                                    }
                                />
                                <span
                                    style={{
                                        lineHeight: 1.2
                                    }}
                                >
                                    {label.label}
                                </span>
                            </Label>
                        </FormGroup>
                    </Row>
                    <Row className="ml-4">
                        <FormGroup className="ml-4">
                            {isConfirmStep[wizardDialog - 1] &&
                                !checkList1Validation[wizardDialog - 1][
                                index
                                ] && (
                                    <div style={{ color: "red" }}>
                                        You need ensure above check list item
                                        has been done correctly.
                                    </div>
                                )}
                            {label.choiceType === "Multi" &&
                                isConfirmStep[wizardDialog - 1] &&
                                !isEnoughAnswer && (
                                    <div style={{ color: "red" }}>
                                        You need to create enough options
                                    </div>
                                )}
                        </FormGroup>
                    </Row>
                </React.Fragment>
            );
        });
    };

    const isDuplicateAnswer = answer => {
        const answerTexts = {};
        let isDuplicate = false;
        answer.forEach(ans => {
            if (!answerTexts[ans.text]) {
                answerTexts[ans.text] = ans.text;
            } else {
                isDuplicate = true;
            }
        });

        if (isDuplicate) {
            showWarning("Duplicate answer!");
        }
        return isDuplicate;
    };

    const validate = () => {
        let isValid = true;
        const error = {};

        [
            "categoryId",
            "difficultyLevel",
            "type",
            // "point"
            // "questionOrder"
        ].forEach(field => {
            if (
                question[field] === null ||
                question[field] === undefined ||
                question[field] === ""
            ) {
                isValid = false;
                error[field] = true;
            } else {
                error[field] = false;
            }
        });

        if (questionText.current && questionText.current.getContent() === "") {
            isValid = false;
            error.questionText = true;
        }

        if (solution.current && solution.current.getContent() === "") {
            isValid = false;
            error.solution = true;
        }

        if (
            !question.answer ||
            !question.answer.find(e => e.checked === true)
        ) {
            isValid = false;
            error.questionAnswers = true;
        }

        if (question.answer.length !== question.task.numberOfAnswears) {
            isValid = false;
            error.numberOfAnswears = true
        }

        if (Object.keys(error).length > 0) {
            let el = null;
            [
                "categoryId",
                "difficultyLevel",
                "questionText",
                "type",
                "questionAnswers",
                "solution",
                // "point"
            ].forEach(field => {
                if (error[field] && !el) {
                    el = document.getElementById(field);
                }
            });
            if (el) {
                window.scroll({
                    top:
                        el.getBoundingClientRect().bottom +
                        el.getBoundingClientRect().height,
                    left: 0,
                    behavior: "smooth"
                });
            }
        }

        // if (instruction.current.getContent() === "") {
        //     isValid = false;
        //     error.instruction = true;
        // }

        setErrors(error);
        return isValid;
    };

    const handleSubmit = async () => {
        // if (!enableShowError) {
        //     setEnableShowError(true);
        // }
        // if (!validate()) return;
        if (isDuplicateAnswer(question.answer)) return;
        dispatch(setLoading(true));
        try {
            const res = await updateCBTQuestion({
                ...question,
                questionId: questionIdParam,
                answer: JSON.stringify(
                    question.answer
                        ? question.answer.map(item => {
                            return {
                                ...item,
                                isCorrectAnswer: undefined
                            };
                        })
                        : ""
                ),
                questionText: questionText.current ? questionText.current
                    .getContent()
                    .replaceAll("text-decoration: underline wavy red;", "")
                    : '',
                instruction: instruction.current ? instruction.current
                    .getContent()
                    .replaceAll("text-decoration: underline wavy red;", "")
                    : '',
                solution: solution.current ? solution.current
                    .getContent()
                    .replaceAll("text-decoration: underline wavy red;", "")
                    : '',
                timeInSeconds: timeInSeconds.current,
                articleId: question.articleId,
                point: question.point || 0,
                questionOrder: question.questionOrder || 0,
                purpose: question.purpose || '',
                syllabus: question.syllabus || '',
            });

            if (res && res.data && !isClickPublish) {
                setMsg({
                    type: "primary",
                    value: "Successfully"
                });
                getQuestion();
            }
        } catch (error) {
            if (error.response) {
                setMsg({
                    type: "danger",
                    value: error.response.data.Message
                });
            }

        }
        if (!isClickPublish) {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
        }
        // setTimeout(() => {
        //     setMsg("")
        // }, 1000)
        dispatch(setLoading(false));
    };

    // const onConfirm = async () => {
    //     if (wizardDialog === 1) {
    //         const arrayValidation = Object.values(checkList1Validation[0]);
    //         setIsConfirmStep({ ...isConfirmStep, 0: true });
    //         if (arrayValidation.length === checkListStep1.length) {
    //             const isValid = arrayValidation.every(i => i === true);
    //             if (isValid) {
    //                 setWizardDialog(2);
    //                 setActiveStep(1);
    //                 handleCompleted();
    //                 await handleSubmit()
    //             }
    //         }
    //     }
    //     if (wizardDialog === 2) {
    //         const arrayValidation = Object.values(checkList1Validation[1]);
    //         setIsConfirmStep({ ...isConfirmStep, 1: true });
    //         if (arrayValidation.length === checkListStep2.length) {
    //             const isValid = arrayValidation.every(i => i === true);
    //             if (isValid) {
    //                 setWizardDialog(3);
    //                 setActiveStep(2);
    //                 handleCompleted();
    //                 await handleSubmit()
    //             }
    //         }
    //     }

    //     if (wizardDialog === 3) {
    //         const arrayValidation = Object.values(checkList1Validation[2]);
    //         setIsConfirmStep({ ...isConfirmStep, 2: true });
    //         if (
    //             checkListStep3 &&
    //             arrayValidation.length === checkListStep3.length
    //         ) {
    //             const isValid = arrayValidation.every(i => i === true);
    //             if (isValid) {
    //                 if (isEnoughAnswer)
    //                 {
    //                     setWizardDialog(4);
    //                     setActiveStep(3);
    //                     handleCompleted();
    //                     await handleSubmit()
    //                 }
    //             }
    //         }
    //     }
    //     if (wizardDialog === 4) {
    //         await handleSubmit()
    //         toggleDialog();
    //         await handleMarkQuestionReadyForTest(questionIdParam);
    //         history.push(`/question-bank/my-task?id=${question.taskId}`);
    //     }
    // };

    const handlePublish = async () => {
        await handleSubmit();
        // if (!validate()) return;
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        history.push(`/preview-question/${questionIdParam}?action=publish&taskId=${question.taskId}`)
        // setWizardDialog(1);
        // setIsClickPublish(true);
    };

    const handleChange = field => e => {
        setQuestion(preState => ({
            ...preState,
            [field]: e ? e.value : null
        }));

        if (enableShowError) {
            setErrors(pre => {
                return {
                    ...pre,
                    [field]: (e || e.value) === ""
                };
            });
        }
    };

    const handleChangeAnswer = ({ data, id, option }) => {
        handleChange("answer")({
            value: question.answer.map(item => {
                if (item.id !== id) return item;

                switch (option) {
                    case "text":
                        return { ...item, text: data };
                    case "mark":
                        return { ...item, mark: Number(data) };
                    case "matchingText":
                        return { ...item, matchingText: data };
                    case "token":
                        return { ...item, token: data };
                    default:
                        return item;
                }
            })
        });
    };

    const handleCheckCorrectAnswer = ({ e, id }) => {
        if (+question.type === 1) {
            handleChange("answer")({
                value: question.answer.map(item => {
                    if (item.id !== id) return item;

                    return {
                        ...item,
                        // isCorrectAnswer: e.target.checked,
                        checked: e.target.checked
                    };
                })
            });
        } else {
            if (!e.target.checked) return;
            setErrors(pre => {
                return {
                    ...pre,
                    questionAnswers: false
                };
            });
            handleChange("answer")({
                value: question.answer.map(item => {
                    if (item.id !== id) return { ...item, checked: false };

                    return {
                        ...item,
                        // isCorrectAnswer: true
                        checked: e.target.checked
                    };
                })
            });
        }
    };

    const getNextId = arr => {
        try {
            if (!arr || !arr.length) return 1;
            const maxIdItem = _.maxBy(arr, "id");
            if (maxIdItem) return maxIdItem.id + 1;
            return 1;
        } catch {
            return 1;
        }
    };

    const handleAddNewAnswer = () => {
        const genAlphabet = ALPHABET.split('')

        handleChange("answer")({
            value: [
                ...question.answer,
                {
                    id: getNextId(question.answer),
                    text: `<b>${genAlphabet[question.answer.length]}. </b>`,
                    checked: false,
                    mark: 0,
                    correctAnswer: 0,
                    matchingText: ""
                }
            ]
        });
        if (question && question.task && question.answer.length + 1 === question.task.numberOfAnswears) {
            setErrors(pre => {
                return {
                    ...pre,
                    numberOfAnswears: false
                };
            });
        }
    };

    const handleRemoveAnswer = id => () => {
        if (question.answer.length === 2) return;

        handleChange("answer")({
            value: question.answer.filter(item => item.id !== id)
        });
    };

    const currentArticle =
        allArticles &&
        question &&
        allArticles.find(item => +question.articleId === +item.id);

    if (isChecking || alert) {
        return (
            <div className="mt-2 p-2">
                <div className="mb-2">
                    <Link
                        style={{
                            fontSize: "1.2rem"
                        }}
                        to="/question-bank/my-task"
                    >
                        <i
                            className="fa fa-long-arrow-left mr-2"
                            aria-hidden="true"
                        ></i>
                        My tasks
                    </Link>
                </div>

                {alert && <Alert color="warning">{alert}</Alert>}
                {/* {setTimeout(() => setAlert(!alert), 500)} */}
            </div>
        );
    }

    console.log(question);

    const categories = question ? question.task.categories.split(",") : [];
    const categoryIds = question ? question.task.categoryIds.split(",") : [];

    const categoryOptions = categories.map((c, index) => {
        return {
            label: c,
            value: categoryIds[index]
        };
    });
    if (question && question.task.subjectName === "Reading") {
        checkListStep3 = checkListStep3Total.filter(
            i => i.type === "general" || i.type === "R"
        );
        isEnoughAnswer = question.answer.length >= 4 ? true : false;
    } else if (
        question &&
        question.task.subjectName === "Mathematical Reasoning"
    ) {
        checkListStep3 = checkListStep3Total.filter(
            i => i.type === "general" || i.type === "MR"
        );
        isEnoughAnswer = question.answer.length >= 5 ? true : false;
    } else if (question && question.task.subjectName === "Thinking Skills") {
        checkListStep3 = checkListStep3Total.filter(
            i => i.type === "general" || i.type === "TS"
        );
        isEnoughAnswer = question.answer.length >= 4 ? true : false;
    } else {
        checkListStep3 = checkListStep3Total.filter(i => i.type === "general");
        isEnoughAnswer = true;
    }
    return (
        <div className="row mx-0">
            <div className={wizardDialog === 0 ? "mt-2 p-2" : "mt-2 p-2 col-8"}>
                <div className="mb-2">
                    <Link
                        style={{
                            fontSize: "1.2rem"
                        }}
                        to={`/question-bank/my-task?id=${question.task.id}`}
                    >
                        <i
                            className="fa fa-long-arrow-left mr-2"
                            aria-hidden="true"
                        ></i>
                        My tasks
                    </Link>
                </div>

                {msg && <Alert color={msg.type}>{msg.value}</Alert>}

                <Row className="mb-2">
                    <Col id="categoryId" md={9} className="mb-2">
                        <FormGroup className="d-flex align-items-center">
                            <Label>Question purpose</Label>
                            <Input type="checkbox" className="ml-2 mt-0 position-relative" checked={isShowQuestionPurpose} onChange={(e) => setIsShowQuestionPurpose(e.target.checked)} />
                        </FormGroup>
                        {isShowQuestionPurpose && <Editor
                            // disabled={isViewDetail}
                            onInit={(evt, editor) => {
                                questionProposeRef.current = editor
                            }}
                            apiKey={TINY_MCE_API_KEY}
                            onEditorChange={(content, editor) => {
                                handleChange("purpose")({
                                    value: content
                                })
                            }}
                            value={question ? question.purpose : ""}
                            init={{
                                height: 400,
                                menubar: false,
                                branding: false,
                                extended_valid_elements: "*[*]",
                                toolbar:
                                    "undo redo | formatselect | image media | " +
                                    "bold italic backcolor | alignleft aligncenter " +
                                    "alignright alignjustify | bullist numlist outdent indent" +
                                    "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table",
                                plugins:
                                    "advlist autolink lists link image media charmap preview anchor " +
                                    "searchreplace visualblocks code fullscreen " +
                                    "insertdatetime media table code help wordcount",
                                content_style:
                                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                file_picker_types: "image",
                                image_uploadtab: true
                            }}
                        />}
                    </Col>

                    <Col md={isClickPublish ? 2 : 3}>
                        <FormGroup>
                            <Label className="d-block text-white">.</Label>
                            <Timer
                                onChange={time => {
                                    timeInSeconds.current = time;
                                }}
                            />
                        </FormGroup>
                    </Col>

                    <Col id="categoryId" md={isClickPublish ? 10 : 9}>
                        <FormGroup>
                            <Label>
                                Question category
                                <span className="color-danger">*</span>
                            </Label>

                            <CategorySelector
                                isDisabled
                                invalid={errors.categoryId}
                                value={categoryOptions.find(c => {
                                    return +c.value === +question.categoryId;
                                })}
                                placeholder="Select category"
                                options={categoryOptions}
                                onChange={handleChange("categoryId")}
                            />

                            {errors.categoryId && (
                                <span className="color-danger">
                                    Question category is required!
                                </span>
                            )}
                        </FormGroup>
                    </Col>

                    <Col id="categoryId" md={12}>
                        <Label>
                            Difficulty level
                            <span className="color-danger">*</span>
                        </Label>
                        <p
                            className="mb-2 font-weight-bold"
                            style={{ color: "#a94442", fontSize: "0.9em" }}
                        >
                            (The Difficulty Level provides an indication of the
                            difficulty level of each test question)
                        </p>
                        <div className="d-flex align-items-center">
                            <CustomInput
                                className="mr-3"
                                type="radio"
                                id="difficulty-1"
                                onClick={() =>
                                    handleChange("difficultyLevel")({
                                        value: 1
                                    })
                                }
                                checked={question.difficultyLevel === 1}
                                label="Level 1"
                            />

                            <CustomInput
                                className="mr-3"
                                type="radio"
                                id="difficulty-2"
                                onClick={() =>
                                    handleChange("difficultyLevel")({
                                        value: 2
                                    })
                                }
                                checked={question.difficultyLevel === 2}
                                label="Level 2"
                            />

                            <CustomInput
                                className="mr-3"
                                type="radio"
                                id="difficulty-3"
                                onClick={() =>
                                    handleChange("difficultyLevel")({
                                        value: 3
                                    })
                                }
                                checked={question.difficultyLevel === 3}
                                label="Level 3"
                            />

                            <CustomInput
                                type="radio"
                                id="difficulty-4"
                                onClick={() =>
                                    handleChange("difficultyLevel")({
                                        value: 4
                                    })
                                }
                                checked={question.difficultyLevel === 4}
                                label="Level 4"
                            />
                        </div>

                        {errors.difficultyLevel && (
                            <span className="color-danger">
                                Difficulty level is required!
                            </span>
                        )}
                    </Col>


                    <Col md={isClickPublish ? 10 : 9} className="mb-2">
                        <FormGroup className="position-relative">
                            <Label>Article</Label>
                            <Input type="checkbox" className="position-absolute mt-0" style={{ top: "2px", left: "64px" }} checked={isShowArticle} onChange={(e) => setIsShowArticle(e.target.checked)} />
                        </FormGroup>

                        {isShowArticle && <ArticleSelector
                            // isDisabled
                            noLabel
                            onChange={handleChange("articleId")}
                            value={
                                question && currentArticle
                                    ? {
                                        ...currentArticle,
                                        label: currentArticle.name,
                                        value: currentArticle.id
                                    }
                                    : null
                            }
                            isClearable
                        />}
                    </Col>


                    {isShowArticle && (
                        <Col md={isClickPublish ? 10 : 9} className="mb-2">
                            <Label>Question order</Label>

                            <Input
                                type="number"
                                value={question.questionOrder || ""}
                                onChange={e => {
                                    handleChange("questionOrder")({
                                        value: e.target.value
                                    });
                                }}
                            />
                        </Col>
                    )}

                    <Col id="instruction" md={12}>
                        <FormGroup className="d-flex align-items-center">
                            <Label>
                                Instruction
                            </Label>
                            <Input type="checkbox" className="ml-2 mt-0 position-relative" checked={isShowInstruction} onChange={(e) => setIsShowInstruction(e.target.checked)} />
                        </FormGroup>

                        {isShowInstruction && <ProwritingEditor
                            // invalid={errors.instruction}
                            initValue={question.instruction}
                            innerRef={instruction}
                            currentEdittingEditorIdRef={
                                currentEdittingEditorIdRef
                            }
                            isClickPublish={isClickPublish}
                            onChange={value => {
                                if (enableShowError) {
                                    setErrors(pre => {
                                        return {
                                            ...pre,
                                            instruction: value === ""
                                        };
                                    });
                                }
                            }}
                        />
                        }

                        {/* {errors.instruction && (
                            <span className="color-danger">
                                Instruction is required!
                            </span>
                        )} */}
                    </Col>

                    <Col id="questionText" xs={12}>
                        <FormGroup>
                            <Label>
                                Question text
                                <span className="color-danger">*</span>
                            </Label>

                            <ProwritingEditor
                                invalid={errors.questionText}
                                initValue={question.questionText}
                                innerRef={questionText}
                                currentEdittingEditorIdRef={
                                    currentEdittingEditorIdRef
                                }
                                isClickPublish={isClickPublish}
                                onChange={value => {
                                    if (enableShowError) {
                                        setErrors(pre => {
                                            return {
                                                ...pre,
                                                questionText: value === ""
                                            };
                                        });
                                    }
                                }}
                            />

                            {errors.questionText && (
                                <span className="color-danger">
                                    Question text is required!
                                </span>
                            )}
                        </FormGroup>
                    </Col>

                    <Col
                        id="type"
                        md={isClickPublish ? 10 : 9}
                        className="mb-2"
                    >
                        <FormGroup>
                            <Label>
                                Question type
                                <span className="color-danger">*</span>
                            </Label>

                            <Select
                                className={classNames({
                                    "invalid-field": errors.type
                                })}
                                value={
                                    question.type === 0
                                        ? {
                                            label: "Single Choice",
                                            value: 0
                                        }
                                        : {
                                            label: "Multiple Choice",
                                            value: 1
                                        }
                                }
                                placeholder="Select type"
                                options={questionTypes}
                                onChange={e => {
                                    handleChange("type")(e);

                                    handleChange("answer")({
                                        value: question.answer.map(ans => {
                                            return {
                                                ...ans,
                                                checked: false
                                            };
                                        })
                                    });
                                }}
                            />

                            {errors.type && (
                                <span className="color-danger">
                                    Question type is required!
                                </span>
                            )}
                        </FormGroup>
                    </Col>


                    <Col md={isClickPublish ? 10 : 9} className="mb-2">
                        <Label>Number of answers: {question ? question.task.numberOfAnswears : ""}</Label>
                    </Col>


                    <Col id="answer-list" md={12} className="mt-2">
                        <div id="questionAnswers">
                            <AnswerList
                                t={t}
                                noSetInitValue={noSetInitValue}
                                answerArr={question.answer || []}
                                isMulti={+question.type === 1}
                                isSingleChoice={+question.type === 0}
                                onAddNewAnswer={handleAddNewAnswer}
                                onChangeAnswer={handleChangeAnswer}
                                onCheckCorrectAnswer={handleCheckCorrectAnswer}
                                onRemoveAnswer={handleRemoveAnswer}
                                currentEdittingEditorIdRef={
                                    currentEdittingEditorIdRef
                                }
                                isClickPublish={isClickPublish}
                            />
                            {/* {errors.questionAnswers && (
                                <span className="color-danger">
                                    The question must have the correct answer!
                                </span>
                            )} */}
                            <div className="d-flex flex-column">
                                {errors.questionAnswers && (
                                    <span className="color-danger">
                                        The question must have the correct answer!
                                    </span>
                                )}
                                {errors.numberOfAnswears && (
                                    <span className="color-danger">
                                        The question must have correct number of answer!
                                    </span>
                                )}
                            </div>
                        </div>
                    </Col>

                    <Col id="solution" md={12}>
                        <Label>
                            Solution<span className="color-danger">*</span>
                        </Label>

                        <ProwritingEditor
                            invalid={errors.solution}
                            initValue={question.solution}
                            innerRef={solution}
                            currentEdittingEditorIdRef={
                                currentEdittingEditorIdRef
                            }
                            isClickPublish={isClickPublish}
                            onChange={value => {
                                if (enableShowError) {
                                    setErrors(pre => {
                                        return {
                                            ...pre,
                                            solution: value === ""
                                        };
                                    });
                                }
                            }}
                        />

                        {errors.solution && (
                            <span className="color-danger">
                                Solution is required!
                            </span>
                        )}
                    </Col>

                    {/* <Col
                        id="point"
                        md={isClickPublish ? 10 : 9}
                        className="mb-2"
                    >
                        <Label>
                            Point<span className="color-danger">*</span>
                        </Label>

                        <Input
                            className={classNames({
                                "is-invalid": errors.point
                            })}
                            type="number"
                            value={question.point || ""}
                            onChange={e => {
                                handleChange("point")({
                                    value: e.target.value
                                });
                            }}
                        />

                        {errors.point && (
                            <span className="color-danger">
                                Point is required!
                            </span>
                        )}
                    </Col> */}

                    <Col md={9} className="mb-2">
                        <FormGroup className="d-flex align-items-center">
                            <Label>Syllabus</Label>
                        </FormGroup>
                        <Editor
                            // disabled={isViewDetail}
                            onInit={(evt, editor) => {
                                questionSyllabus.current = editor
                            }}
                            apiKey={TINY_MCE_API_KEY}
                            onEditorChange={(content, editor) => {
                                handleChange("syllabus")({
                                    value: content
                                })
                            }}
                            value={question ? question.syllabus : ""}
                            init={{
                                height: 400,
                                menubar: false,
                                branding: false,
                                extended_valid_elements: "*[*]",
                                toolbar:
                                    "undo redo | formatselect | image media | " +
                                    "bold italic backcolor | alignleft aligncenter " +
                                    "alignright alignjustify | bullist numlist outdent indent" +
                                    "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | table",
                                plugins:
                                    "advlist autolink lists link image media charmap preview anchor " +
                                    "searchreplace visualblocks code fullscreen " +
                                    "insertdatetime media table code help wordcount",
                                content_style:
                                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                file_picker_types: "image",
                                image_uploadtab: true
                            }}
                        />
                    </Col>

                    <Col xs={12} className="mt-2">
                        <Button
                            color="primary"
                            className="mr-2 px-2"
                            onClick={handleSubmit}
                        >
                            Save question
                        </Button>

                        <Button
                            color="primary"
                            className="mr-2 px-2"
                            onClick={() => {
                                window.open(
                                    `/preview-question/${questionIdParam}?action=preview`,
                                    "_blank"
                                );
                            }}
                        >
                            Preview
                        </Button>
                        <Button
                            color="primary"
                            className="mr-2 px-2"
                            onClick={() => {
                                handlePublish();
                            }}
                        >
                            Ready for test
                        </Button>
                    </Col>
                </Row>
            </div>
            {/* {wizardDialog !== 0 && (
                <div className="col-4 p-3 square border border-top-0">
                    <div className="d-flex justify-content-between">
                        <CardTitle tag="h5">
                            Check list before publishing
                        </CardTitle>
                        <button className="mb-3" onClick={onCancel}>
                            <i className="fa fa-close fa-lg"></i>
                        </button>
                    </div>

                    <Box sx={{ width: "100%" }}>
                        <Stepper orientation="vertical" activeStep={activeStep}>
                            {steps.map((label, index) => (
                                <Step key={label} completed={completed[index]}>
                                    <StepLabel color="inherit">
                                        {label}
                                    </StepLabel>
                                    <StepContent>
                                        {wizardDialog === 1 &&
                                            renderCheckList(checkListStep1)}
                                        {wizardDialog === 2 &&
                                            renderCheckList(checkListStep2)}
                                        {checkListStep3 &&
                                            checkListStep3.length &&
                                            wizardDialog === 3 &&
                                            renderCheckList(checkListStep3)}
                                        {wizardDialog === 4 && (
                                            <FormGroup className="d-flex align-items-center my-3 justify-content-center">
                                                <Label>
                                                    Please check this question
                                                    in preview mode carefully
                                                    before publishing. Do you
                                                    want to publish this
                                                    question now ?
                                                </Label>
                                            </FormGroup>
                                        )}

                                        <div className="d-flex justify-content-end mt-2">
                                            <Button
                                                className="mr-2"
                                                style={{ width: "100px" }}
                                                color="secondary"
                                                onClick={onCancel}
                                            >
                                                Back to Edit
                                            </Button>
                                            <Button
                                                style={{ width: "100px" }}
                                                color="primary"
                                                onClick={onConfirm}
                                            >
                                                Next
                                            </Button>
                                        </div>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </div>
            )} */}
        </div>
    );
};

export default withTranslation("common")(EditQuestion);
