import React, { memo } from 'react'

export default memo(function DiscussionBtn(props) {
    return (
        <div
            role="button"
            tabIndex={0}
            title={props.title || "View"}
            onClick={props.onClick}
            {...props}
        >
            <i className="fa fa-comments" style={{ fontSize: 16, cursor: 'pointer', color: "#1f1f1f" }} />
        </div>
    )
})
