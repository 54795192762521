import { Formik, ErrorMessage } from 'formik';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, FormGroup, Input, Label } from 'reactstrap';
import * as yup from 'yup'
import { EXPIRE_TIME, ACCESS_TOKEN } from "../../../util/Constant";
import { api, resetPassword } from "../../../util/api";
import history from "../../../util/history";
import useAlert from '../../../hooks/useAlert';
import { logout } from "../redux/actions";
import Spinner from '../../../components/Spinner';


const signUpStyle = {
    width: "100%",
    borderRadius: "4px",
    backgroundColor: "#fff",
    padding: "16px 24px"
}

const fields = [
    { name: "newPassword", placeholder: "New password" },
    { name: "newPasswordConfirm", placeholder: "Confirm new password" },
]

const initialValues = {
    newPassword: "",
    newPasswordConfirm: ""
}

const schema = yup.object({
    newPassword: yup.string().required("New password is required"),
    newPasswordConfirm: yup.string().required("Confirm new password is required").test("matching", "Password not match", function () {
        return this.parent.newPassword === this.parent.newPasswordConfirm
    })
})

function ResetPassword() {
    const [isLoading, setIsLoading] = useState(false);
    const { showSuccess } = useAlert()
    const dispatch = useDispatch()
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromParam = urlParams.get('token');

    const loadInfo = async () => {
        try {
            let res;
            if (tokenFromParam) {
                res = await api.get('account/info', {
                    headers: {
                        'Authorization': `Bearer ${tokenFromParam}`
                    }
                });
            } else {
                res = await api.get('account/info');
            }
            if (res.status !== 200) {
                localStorage.removeItem(ACCESS_TOKEN);
                history.push("/login");
            }
        } catch (error) {
            localStorage.removeItem(ACCESS_TOKEN);
            history.push("/login");
        }
    }
    
    useEffect(() => {
        loadInfo()
    }, [])

    const submitResetPassword = async (newPassword) => {
        setIsLoading(true)
        try {
            if (tokenFromParam) {
                await api.put(`/account/resetPassword`, { newPassword }, {
                    headers: {
                        'Authorization': `Bearer ${tokenFromParam}`
                    }
                })
            } else {
                await resetPassword(newPassword)
            }
            showSuccess("Reset password successfully")
            setTimeout(() => dispatch(logout()), 500)
        } catch(error) {

        }
        setIsLoading(false)
    }

    return (
        <>
            <Spinner isLoading={isLoading} />
            <div className="bg-gradient-info" style={{ height: "30vh" }} />
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(values, actions) => {
                    const data = { ...values };
                    submitResetPassword(values.newPassword)
                }}
            >
                {formikProps => <div
                    className="d-flex flex-column align-items-center"
                    style={{ marginTop: "-150px", padding: "0 32px" }}
                >
                    <h2 className="text-center text-white">RESET PASSWORD</h2>
                    <Col md={5} style={signUpStyle}>
                        {
                            fields.map((field, index) => <FormGroup
                                key={index}
                                className="mb-2"
                            >
                                <Label>{field.placeholder}</Label>
                                <Input
                                    type="password"
                                    name={field.name}
                                    placeholder={field.placeholder}
                                    value={formikProps.values[field.name]}
                                    onChange={formikProps.handleChange}
                                    onBlur={formikProps.handleBlur}
                                    invalid={!!(formikProps.touched[field.name] && formikProps.errors[field.name])}
                                />
                                {formikProps.touched[field.name] && formikProps.errors[field.name] && <ErrorMessage name={field.name}>
                                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                                </ErrorMessage>}
                            </FormGroup>)
                        }

                        <FormGroup className="mb-2 mt-3">
                            <Button block color="primary" onClick={formikProps.handleSubmit}>Send</Button>
                        </FormGroup>
                    </Col>
                </div>}
            </Formik>
        </>
    )
}


export default ResetPassword
