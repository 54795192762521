import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { Alert, Badge, Button, Col, FormGroup, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap";

import store from "../../store"
import useFetch from "../../hooks/useFetch";
import {
    getCBTQuestionCategories,
    getProofreaderQuestions
} from "../../util/api";
import { QUESTION_TYPE } from "../../util/Constant";
import SearchBox from "../../components/SearchBox";
import setLoading from "../../components/Spinner/action";
import RenderCategory from "../../components/RenderCategory";
import CustomPagination from "../../components/CustomPagination";
import { cbtURL } from "../MyTask/constants";
import DiscussionBtn from "../../components/Button/DiscussionBtn";

const questionTypes = [
    { label: "Single Choice", value: "SingleChoice" },
    { label: "Multiple Choice", value: "MultipleChoice" }
];

const QuestionReview = props => {
    const { t } = props;
    const history = useHistory()
    const userId = store.getState().common.userInfo.identity.id || 0
    const dispatch = useDispatch();
    const { data: cbtQuestionCategories } = useFetch(getCBTQuestionCategories);
    const [questions, setQuestions] = useState(null);
    const [queryParams, setQueryParams] = useState({
        search: "",
        questionType: "",
        categoryId: ""
    });
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [modal, setModal] = useState(false);
    const [urlCBT, setUrlCBT] = useState("");
    const groups = (cbtQuestionCategories || []).filter(e => !e.parentId);

    const { categoryId, questionType, search } = queryParams;

    useEffect(() => {
        if (!questions) return;

        if (currentPage > Math.ceil(questions.length / pageSize)) {
            setCurrentPage(1);
        }
    }, [pageSize, questions]);

    useEffect(() => {
        document.title = "My questions review";
        window.addEventListener("message", e => {
            if (e.data.eventName === "cbtPopupClose") {
                window.location.reload();
            }
        });
    }, []);

    const getQuestionList = async () => {
        dispatch(setLoading(true));
        try {
            // categoryId, type, keyword
            const res = await getProofreaderQuestions(
                categoryId,
                questionType,
                search
            );
            setQuestions(res.data);
        } catch (error) {}
        dispatch(setLoading(false));
    };

    const handleChangeQueryParams = newParam => {
        setQueryParams(oldParams => ({
            ...oldParams,
            ...newParam
        }));
    };

    useEffect(() => {
        getQuestionList();
    }, [categoryId, questionType]);

    if (!questions) {
        return "Loading...";
    }

    const ApprovedItem = () => {
        return <Badge color="success">Approved</Badge>;
    };

    const NotApprovedItem = () => {
        return <Badge color="danger">Not approved yet</Badge>;
    };

    const toggle = () => setModal(!modal);

    const onStartTesting = (cbtQuestionId, proofreaderQuestionId) => {
        setUrlCBT(
            `${cbtURL}/exam/question-creator?email=proofreader&cmd=proofreaderTest&questionId=${cbtQuestionId}&proofreaderNumber=${proofreaderQuestionId}`
          );
        toggle();
    }
    const RejectedItem = () => {
        return <Badge color="danger">Rejected</Badge>;
    }

    return (
        <div>
        <Row>
            <Col xs={4} className="mb-2">
                <SearchBox
                    value={queryParams.search}
                    onChange={e => {
                        handleChangeQueryParams({
                            search: e.target.value
                        });
                    }}
                    onKeyDown={e => {
                        if (e.key === "Enter") {
                            getQuestionList();
                        }
                    }}
                />
            </Col>

            <Col xs={2}>
                <FormGroup>
                    <Select
                        isClearable
                        placeholder="Select question type"
                        options={questionTypes}
                        onChange={e => {
                            if (e) {
                                handleChangeQueryParams({
                                    questionType:
                                        e.value === QUESTION_TYPE.SINGLE_CHOICE
                                            ? 0
                                            : 1
                                });
                            } else {
                                handleChangeQueryParams({
                                    questionType: ""
                                });
                            }
                        }}
                    />
                </FormGroup>
            </Col>

            <Col xs={2}>
                <FormGroup>
                    <Select
                        isClearable
                        placeholder="Select question category"
                        // value={group}
                        options={groups.map(c => ({
                            value: c.id,
                            label: c.title
                        }))}
                        onChange={e => {
                            if (e) {
                                handleChangeQueryParams({
                                    categoryId: e.value
                                });
                            } else {
                                handleChangeQueryParams({
                                    categoryId: ""
                                });
                            }
                            // setGroup(e);
                            // setCategory(null);
                            // setSubCategory(null);
                            // setClassification(null);
                        }}
                    />
                </FormGroup>
            </Col>

            {questions && questions.length ? (
                <Col md={12}>
                    <div className="ag-theme-balham mb-2">
                        <Table
                            id="table-questions"
                            hover
                            bordered
                            striped
                            responsive
                            className="grid-table mb-2 mt-2"
                        >
                            <thead>
                                <tr>
                                    <th style={{ width: "55%" }}>Question</th>
                                    <th style={{ width: "10%" }}>Type</th>
                                    <th
                                        style={{ width: "15%" }}
                                        className="text-center"
                                    >
                                        Category
                                    </th>
                                    <th
                                        style={{ width: "10%" }}
                                        className="text-center"
                                    >
                                        Approval
                                    </th>
                                    <th
                                        style={{ width: "10%" }}
                                        className="text-center"
                                    >
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {questions
                                    .map(item => {
                                        let proofreaderQuestion = null;
                                        proofreaderQuestion = item.proofreaderCBTQuestions.find(e => e.proofreaderId === userId)
                                        if (proofreaderQuestion.status !== 0){
                                            const questionVersions = item.proofreaderCBTQuestions.filter(i => i.status !== 0).sort((a,b) => (a.id > b.id) ? -1 : 1);
                                            if(questionVersions.length > 0){
                                                proofreaderQuestion = questionVersions[0];
                                            }
                                        }
                                        
                                        const isRejected = proofreaderQuestion && proofreaderQuestion.rejectionReason
                                        const isApproved = proofreaderQuestion && proofreaderQuestion.status !== 0
                                        const isCompletedTest = proofreaderQuestion && proofreaderQuestion.isCompletedTest
                                        const proofreaderQuestionId = proofreaderQuestion.id
                                        const cbtQuestionId = proofreaderQuestion.toTestCBTQuestionId
                                        const questionText = proofreaderQuestion ? proofreaderQuestion.questionText : item.questionText;
                                        
                                        return (
                                            <tr>
                                                <td>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                questionText
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {item.type === 0
                                                        ? QUESTION_TYPE.SINGLE_CHOICE
                                                        : QUESTION_TYPE.MULTIPLE_CHOICE}
                                                </td>
                                                <td>
                                                    {/* <div>{item.categoryName}</div>
                                                <div>
                                                    <i>{item.categoryPath}</i>
                                                </div> */}
                                                    <RenderCategory
                                                        categoryId={
                                                            item.categoryId
                                                        }
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    {isRejected ? <RejectedItem /> : (isApproved ? 
                                                        <ApprovedItem />
                                                    : 
                                                        <NotApprovedItem />
                                                    )}
                                                </td>
                                                <td className="text-center">
                                                    { isCompletedTest
                                                    ? <div className="d-flex align-items-center">
                                                        <i
                                                            style={{
                                                                cursor: "pointer"
                                                            }}
                                                            onClick={() => {
                                                                window.open(
                                                                    `/preview-question/${item.id}?origin=question-review&action=checkToApprove`,
                                                                    "_blank"
                                                                );
                                                            }}
                                                            className="fa fa-eye mr-2"
                                                            aria-hidden="true"
                                                        ></i>
                                                        {/* <DiscussionBtn
                                                            className="mr-3"
                                                            onClick={() => {
                                                                window.open(
                                                                    `/commentable-preview-question/${item.id}`,
                                                                    "_blank"
                                                                );
                                                            }}
                                                        /> */}
                                                        <i
                                                            onClick={() => {
                                                                window.open(
                                                                    `/approval-history/${item.id}`,
                                                                    "_blank"
                                                                );
                                                            }}
                                                            title="History"
                                                            className="fa fa-history cursor-pointer"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                    : <Button
                                                        className="px-2"
                                                        color="primary"
                                                        onClick={() => onStartTesting(cbtQuestionId, proofreaderQuestionId)}
                                                        // style={{width: '4rem'}}
                                                    > Start Test</Button>
                                                    }
                                                </td>
                                            </tr>
                                        );
                                    })
                                    .reverse()
                                    .slice(
                                        (currentPage - 1) * pageSize,
                                        currentPage * pageSize
                                    )}
                            </tbody>
                        </Table>

                        <CustomPagination
                            pageCount={
                                questions
                                    ? Math.ceil(questions.length / pageSize)
                                    : 0
                            }
                            pageSize={pageSize}
                            currentPage={currentPage}
                            // pageSizeName={}
                            changePage={e => {
                                setCurrentPage(e);
                            }}
                            changePageSize={e => {
                                setPageSize(e);
                            }}
                            onChangePageSize={() => {}}
                        />
                    </div>
                </Col>
            ) : (
                <Col md={12}>
                    <Alert color="primary">{t("no_question_to_display")}</Alert>
                </Col>
            )}
        </Row>
        <Modal
                style={{ height: "100vh", maxWidth: "100vw", marginTop: 0 }}
                isOpen={modal}
                toggle={toggle}
            >
                <ModalHeader
                    toggle={toggle}
                ></ModalHeader>
                <ModalBody className="p-0">
                    <iframe
                        src={urlCBT}
                        style={{
                            height: "calc(100vh - 54px)",
                            width: "100vw",
                            border: "none",
                            display: "block"
                        }}
                        title="exam"
                    />
                </ModalBody>
            </Modal>
        </div>
    );
};

export default withTranslation("common")(QuestionReview);
