import React from "react"
import { Row, Col, Button, Input } from "reactstrap"

import DeleteBtn from "../../../components/Button/DeleteBtn"

const OrderingAnswer = props => {
    const { t, answerArr } = props

    return <div className="mb-2">
        <Row>
            <Col md={8}>{t("answer")}</Col>
            <Col md={2}>{t("order")}</Col>
            <Col md={2} className="text-center">
                <Button
                    color="primary"
                    size="sm"
                    style={{ fontSize: "0.8em" }}
                    onClick={() => { }}
                >
                    <i className="fa fa-plus mr-1" />
                    {t("add_new_row")}
                </Button>
            </Col>
        </Row>

        <hr className="my-2" />

        {answerArr && answerArr.map((item, index) => <Row key={index} className="mb-2">
            <Col md={8}>
                <Input
                    value={item.text}
                    placeholder={t("enter_answer_text")}
                />
            </Col>

            <Col md={2}>
                <Input
                    type="number"
                    value={item.order}
                />
            </Col>

            <Col md={2} className="text-center">
                <DeleteBtn />
            </Col>
        </Row>)}
    </div>
}

export default OrderingAnswer