import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Table, Row, Col, Alert, CustomInput } from "reactstrap"
import { withTranslation } from "react-i18next"

import SearchBox from "../../../components/SearchBox"
import CreateBtn from "../../../components/Button/CreateBtn"
import DeleteBtn from "../../../components/Button/DeleteBtn"
import LinkEditBtn from "../../../components/Button/LinkEditBtn"
import CustomPagination from "../../../components/CustomPagination"

import setModal from "../../../components/Modal/action"

import {
    getAllPack,
    changePageSizeTestPack,
    changeCurrentPageTestPack,
    removePack
} from "../redux/action"

const TestPack = props => {
    const {
        t,
        testPackList,
        currentPage,
        pageSize,
        pageCount,
        totalItems,
        // props for selecting pack in test suite detail
        isInTestSuiteDetail,
        selectedPacks,
        onClickCheckbox
    } = props

    const [searchString, setSearchString] = useState("")
    const sortBy = "Name"
    const [sortOrder, setSortOrder] = useState("ASC")
    const [loaded, setLoaded] = useState(false)

    const getAll = async page => {
        const { currentPage, pageSize } = props
        await props.getAllPack({
            currentPage: page || currentPage,
            pageSize,
            searchString,
            sortBy,
            sortOrder
        })

        if (page) {
            await props.changeCurrentPageTestPack(page)
        }

        if (!loaded) {
            setLoaded(true)
        }
    }

    useEffect(() => {
        document.title = t("test_pack")
        getAll(1)
    }, [])

    useEffect(() => {
        if (loaded) {
            getAll(1)
        }
    }, [sortOrder])

    useEffect(() => {
        if (loaded) {
            getAll()
        }
    }, [currentPage])

    const onSort = () => {
        if (sortOrder === "ASC") {
            setSortOrder("DESC")
        } else {
            setSortOrder("ASC")
        }
    }

    const onDelete = id => {
        props.setModal({
            type: "danger",
            message: t("delete_test_pack_confirm"),
            cb: () => props.removePack(id, () => getAll(1))
        })
    }

    const colSize = !isInTestSuiteDetail ? 8 : 12

    return <div>
        <Row className="mb-2">
            <Col md={colSize} className="d-flex">
                {!isInTestSuiteDetail
                    ? <div style={{ marginRight: "30px" }}>
                        <CreateBtn
                            title={t("create_pack")}
                            style={{ width: "120px", height: "32px" }}
                            onClick={() => props.history.push("/create-test-pack")}
                        />
                    </div>
                    : null}
                <SearchBox
                    className="mr-0"
                    value={searchString}
                    onChange={e => setSearchString(e.target.value)}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            getAll(1);
                        }
                    }}
                />
            </Col>
        </Row>
        {totalItems
            ? <Row>
                <Col md={colSize} className="text-right">
                    <p className="mb-2 font-weight-bold">{t("total_test_pack")}: {totalItems}</p>
                </Col>
            </Row>
            : null}
        <Row className="mb-2">
            {testPackList && testPackList.length
                ? <Col md={colSize}>
                    <Table hover bordered striped responsive className="grid-table mb-2">
                        <thead>
                            <tr>
                                <th className="align-top">
                                    {t("test_pack_name")}
                                    {/* <i
                                        className="fa fa-sort float-right mt-1"
                                        style={{ cursor: 'pointer' }}
                                        onClick={onSort}
                                    /> */}
                                </th>
                                <th className="align-top">
                                    {t("description")}
                                </th>
                                <th className="text-center align-top">{t("action")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {testPackList.map((testPack, index) => <tr key={index}>
                                <td>
                                    <LinkEditBtn
                                        noIcon
                                        onClick={() => !isInTestSuiteDetail
                                            ? props.history.push(`/edit-test-pack/${testPack.id}`)
                                            : window.open(`/edit-test-pack/${testPack.id}`)}
                                    >
                                        {testPack.name}
                                    </LinkEditBtn>
                                </td>
                                <td className="p-mb-0" dangerouslySetInnerHTML={{ __html: testPack.description }} />
                                <td className="text-center pl-3">
                                    {!isInTestSuiteDetail
                                        ? <DeleteBtn
                                            className="d-inline mr-3"
                                            onClick={() => onDelete(testPack.id)}
                                        />
                                        : <CustomInput
                                            type="checkbox"
                                            id={`testpack-checkbox-${index}`}
                                            checked={selectedPacks && selectedPacks.map(pack => pack.id).includes(testPack.id)}
                                            onClick={e => onClickCheckbox(e.target.checked, testPack)}
                                        />}
                                </td>
                            </tr>)}
                        </tbody>
                    </Table>
                </Col>
                : <Col md={colSize} sm={12}>
                    <Alert color="primary" className="mb-0">{t("no_test_pack_to_display")}</Alert>
                </Col>}
        </Row>

        <Row className="mb-2">
            <Col md={colSize} className="text-right">
                <CustomPagination
                    pageCount={pageCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    pageSizeName="testPackPageSize"
                    changePage={page => {
                        props.changeCurrentPageTestPack(page);
                    }}
                    changePageSize={props.changePageSizeTestPack}
                    onChangePageSize={() => getAll(1)}
                />
            </Col>
        </Row>
    </div>
}

const mapStateToProps = state => ({
    testPackList: state.testPack.testPackList,
    currentPage: state.testPack.currentPage,
    pageCount: state.testPack.pageCount,
    pageSize: state.testPack.pageSize,
    totalItems: state.testPack.totalItems
})

const mapDispatchToProps = {
    setModal,
    getAllPack,
    changePageSizeTestPack,
    changeCurrentPageTestPack,
    removePack
}

const TestPackHOC = withTranslation("common")(TestPack)

export default connect(mapStateToProps, mapDispatchToProps)(TestPackHOC)