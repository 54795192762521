import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import {
  Row,
  Col,
  Input,
  Collapse,
  Button,
  Table,
  Alert,
  Label,
} from "reactstrap"
import _ from "lodash"
import {CKEditor} from "ckeditor4-react"
import AssessmentList from "../../Assessment/view/Home"
import ErrorHandler from "../../../components/ErrorHandler"
import ImagePreview from "../../../components/ImagePreview"
import setModal from "../../../components/Modal/action"
import { createPack, updatePack, getPack } from "../redux/action"
import { uploadFiles } from "../../User/redux/action"
import { guidGenerator } from "../../../util/helper"
import LinkEditBtn from "../../../components/Button/LinkEditBtn"
import useModal from "../../../components/Modal/useModal"
import CustomModal from "../../../components/Modal/CustomModal"
import DeleteBtn from "../../../components/Button/DeleteBtn"

const defaultTestSet = {
  guid: guidGenerator(),
  isOpen: false,
  name: "New set",
  selectedTests: [],
}

const testSetStyle = {
  padding: "8px 16px",
  border: "1px solid #ddd",
  borderRadius: "4px",
  backgroundColor: "#f2f2f2",
}

const TestPackDetail = (props) => {
  const {
    t,
    match: {
      params: { id },
    },
  } = props

  useEffect(() => {
    if (id) {
      getData()
    } else {
      document.title = t("create_test_pack")
    }
  }, [])

  const getData = async () => {
    const res = await props.getPack(id)
    if (!res || !res.data) return

    setName(res.data.name)
    setDescription(res.data.description)
    setTestSets(
      res.data.testSets.map((set) => ({
        guid: guidGenerator(),
        id: set.id,
        isOpen: false,
        name: set.name,
        selectedTests: set.tests,
      }))
    )

    setImageUrl(res.data.imageUrl)

    document.title = res.data.name
  }

  const [isOpen, toggle] = useModal()
  const renderModalEditSet = () => (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      title={t("edit_set", { setName: currentSet ? currentSet.name : "" })}
      t={t}
      noFooter
    >
      <Row>
        <Col md={6} className="mb-2">
          <Label>{t("set_name")}</Label>
          <Input
            value={currentSet ? currentSet.name : ""}
            onChange={(e) =>
              updateTestSets(currentSet.guid, { name: e.target.value })
            }
            placeholder={t("set_name")}
          />
        </Col>
        <Col md={12}>
          <AssessmentList
            isInTestPackDetail
            selectedTests={currentSet ? currentSet.selectedTests : []}
            allSelectedTestIds={allSelectedTestIds}
            onClickCheckbox={onClickCheckbox}
          />
        </Col>
      </Row>
    </CustomModal>
  )

  const [testSets, setTestSets] = useState([defaultTestSet])

  const [currentSetGuid, setCurrentSetGuid] = useState(null)

  const updateTestSets = (guid, value) => {
    const newTestSets = testSets.map((set) => {
      if (set.guid !== guid) return set
      return {
        ...set,
        ...value,
      }
    })

    setTestSets(newTestSets)
  }

  const onClickCheckbox = (checked, item) => {
    if (!currentSet) return

    const { guid } = currentSet

    if (!checked) {
      const newSelectedTests = currentSet.selectedTests.filter(
        (test) => test.id !== item.id
      )
      updateTestSets(guid, { selectedTests: newSelectedTests })
      return
    }

    const containerSet = testSets.find(
      (set) =>
        set.selectedTests &&
        set.selectedTests.map((test) => test.id).includes(item.id)
    )
    if (containerSet) {
      props.setModal({
        type: "danger",
        message: t("test_in_another_set", { setName: containerSet.name }),
      })
      return
    }

    if (!currentSet.selectedTests.map((test) => test.id).includes(item.id)) {
      updateTestSets(guid, {
        selectedTests: [...currentSet.selectedTests, item],
      })
    }
  }

  const addNewSet = () => {
    setTestSets([
      ...testSets,
      {
        guid: guidGenerator(),
        isOpen: true,
        name: "New set",
        selectedTests: [],
      },
    ])
  }

  const [name, setName] = useState("")
  const [checkRequired, setCheckRequired] = useState(false)

  const [description, setDescription] = useState(null)

  const [file, setFile] = useState(null)
  const [imageUrl, setImageUrl] = useState("")
  const [imageChanged, setImageChanged] = useState(false)

  const handleSubmit = async () => {
    setCheckRequired(true)

    if (!name || !name.trim()) return

    if (!description || !description) return

    // check number of tests in each test set
    const emptySet = testSets.find(
      (set) => !set.selectedTests || !set.selectedTests.length
    )
    if (emptySet) {
      props.setModal({
        type: "danger",
        message: t("please_choose_at_least_1_test_in_a_set"),
      })
      return
    }

    // check set name empty
    const setNames = testSets.map((set) => set.name.trim())
    if (setNames.includes("")) {
      props.setModal({
        type: "danger",
        message: t("set_name_empty"),
      })
      return
    }

    // check set name dupplication
    const uniqueSetNames = [...new Set(setNames)]
    if (uniqueSetNames.length < setNames.length) {
      props.setModal({
        type: "danger",
        message: t("duplicate_set_name"),
      })
      return
    }

    const testSetData = testSets.map((set) => ({
      id: set.id,
      name: set.name,
      testIds: set.selectedTests.map((test) => test.id),
    }))

    // check duplicate test in sets
    const selectedTestIds = testSetData.map((set) => set.testIds).flat()
    const uniqueSelectedTestIds = [...new Set(selectedTestIds)]
    if (uniqueSelectedTestIds.length < selectedTestIds.length) {
      props.setModal({
        type: "danger",
        message: t("each_test_can_only_in_1_set"),
      })
      return
    }

    if ((!id && !file) || (id && !imageUrl)) {
      props.setModal({
        type: "danger",
        message: t("you_have_not_choose_file_yet"),
      })
    }

    let newImageUrl = imageUrl

    if (!id || imageChanged) {
      // upload image to get imageUrl
      const formData = new FormData()
      const fileName = file.name
      formData.append("filesUpload", file, fileName)
      const res = await props.uploadFiles(formData)
      newImageUrl = res ? res[0] : ""
    }

    const data = {
      id,
      name,
      description,
      imageUrl: newImageUrl,
      testSets: testSetData,
    }

    const func = id ? props.updatePack : props.createPack

    func(data, () => props.history.push("/test-management/test-pack"))
  }

  const onDeleteTest = (testId) => {
    let testSet = testSets.find((set) => {
      const testDeleted = set.selectedTests.find((test) => test.id === testId)
      if (testDeleted) {
        return true
      }
      return false
    })

    testSet = {
      ...testSet,
      selectedTests: testSet.selectedTests.filter((test) => test.id !== testId),
    }

    const newTestSets = testSets.filter((set) => set.guid !== testSet.guid)

    setTestSets([...newTestSets, testSet])
  }

  const onDeleteSet = (setId) => {
    setTestSets(testSets.filter((set) => set.guid !== setId))
  }

  const currentSet = testSets.find((set) => set.guid === currentSetGuid)
  const allSelectedTestIds = testSets
    .map((set) => set.selectedTests.map((test) => test.id))
    .flat()
  return (
    <div className="pt-2 pb-5 px-3">
      {renderModalEditSet()}
      <Row>
        <Col md={12}>
          <h5>{t("test_pack_information")}</h5>
        </Col>
        <Col md={6}>
          <p className="mb-2 font-weight-bold">
            {t("test_pack_name")}
            <span className="text-danger">*</span>
          </p>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={t("test_pack_name")}
            className="mb-2"
            invalid={checkRequired && (!name || !name.trim())}
          />

          {checkRequired && (!name || !name.trim()) && (
            <ErrorHandler text={t("test_pack_name_empty")} />
          )}
          <p className="mb-2 font-weight-bold">
            {t("test_pack_description")}
            <span className="text-danger">*</span>
          </p>
          {(!id || description !== null) && (
            <CKEditor
              data={description}
              onChange={(e) => setDescription(e.editor.getData())}
              config={{
                height: 100,
                allowedContent: true,
                toolbar: [
                  {
                    name: "basicstyles",
                    groups: ["basicstyles", "cleanup"],
                    items: ["Bold", "Italic"],
                  },
                  { name: "insert", items: ["Image"] },
                  { name: "others" },
                ],
                extraPlugins: "html5audio",
                //  TODO change upload api
                filebrowserImageUploadUrl: "/api/file/Upload?",
                filebrowserUploadUrl: "/api/file/Upload?",
              }}
              onBeforeLoad={(CKEDITOR) => {
                CKEDITOR.disableAutoInline = true
                CKEDITOR.config.height = 100
                CKEDITOR.config.enterMode = CKEDITOR.ENTER_BR
              }}
            />
          )}
          {checkRequired && (!description || !description.trim()) && (
            <ErrorHandler text={t("test_pack_description_empty")} />
          )}

          <p className="my-2 font-weight-bold">
            {t("test_pack_image")}
            <span className="text-danger">*</span>
          </p>
          <ImagePreview
            maxHeight="300px"
            imageUrl={imageUrl}
            file={file}
            setFile={setFile}
            t={t}
            setModal={props.setModal}
            callback={() => setImageChanged(true)}
          />
        </Col>

        <Col md={6}>
          <p className="font-weight-bold mb-2">{t("set_list")}</p>
          {testSets.map((set, index) => (
            <div key={index} className="mb-2" style={testSetStyle}>
              <div className="d-flex align-items-center justify-content-between">
                <b>{set.name}</b>
                <span>
                  <i
                    className={`fa ${
                      set.isOpen ? "fa-chevron-down" : "fa-chevron-right"
                    }`}
                    style={{ fontSize: 12, cursor: "pointer" }}
                    aria-hidden="true"
                    onClick={() =>
                      updateTestSets(set.guid, { isOpen: !set.isOpen })
                    }
                  />
                </span>
              </div>
              <Collapse isOpen={set.isOpen}>
                <Row className="my-2">
                  <Col md={12} className="d-flex justify-content-end mb-2">
                    <Button
                      className="mx-1"
                      color="link"
                      onClick={() => {
                        setCurrentSetGuid(set.guid)
                        toggle()
                      }}
                    >
                      {t("edit")}
                    </Button>
                    <Button
                      className="mx-1 text-danger"
                      color="link"
                      onClick={() => onDeleteSet(set.guid)}
                    >
                      Delete
                    </Button>
                  </Col>
                  <Col md={12}>
                    {set.selectedTests && set.selectedTests.length ? (
                      <Table
                        hover
                        bordered
                        striped
                        responsive
                        className="grid-table mb-2"
                      >
                        <thead>
                          <tr>
                            <th>{t("test_name")}</th>
                            <th>{t("subject")}</th>
                            <th>{t("duration")}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {set.selectedTests.map((test, i) => (
                            <tr key={i}>
                              <td>
                                <LinkEditBtn
                                  label={test.name}
                                  onClick={() => {
                                    window.open(`/edit-assessment/${test.id}`)
                                  }}
                                ></LinkEditBtn>
                              </td>
                              <td>{test.subject ? test.subject.name : ""}</td>

                              <td>{test.durationInMinutes}</td>
                              <td className="text-center">
                                <DeleteBtn
                                  className="d-inline mr-3"
                                  onClick={() => onDeleteTest(test.id)}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <Alert color="primary">{t("no_test_to_display")}</Alert>
                    )}
                  </Col>
                </Row>
              </Collapse>
            </div>
          ))}
          <div>
            <Button className="mb-2" color="link" onClick={addNewSet}>
              {t("add_new_set")}
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="py-2 floatingActions">
        <Col md={12}>
          <Button color="success" className="mr-2" onClick={handleSubmit}>
            {id ? t("update") : t("create")}
          </Button>
          <Button
            onClick={() => props.history.push("/test-management/test-pack")}
          >
            {t("cancel")}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  setModal,
  createPack,
  updatePack,
  getPack,
  uploadFiles,
}

const TestPackDetailHOC = withTranslation("common")(TestPackDetail)

export default connect(mapStateToProps, mapDispatchToProps)(TestPackDetailHOC)
