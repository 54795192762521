import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Table, Row, Col, Alert, Label, Input } from "reactstrap"
import { withTranslation } from "react-i18next"

import SearchBox from "../../../components/SearchBox"
import CreateBtn from "../../../components/Button/CreateBtn"
import DeleteBtn from "../../../components/Button/DeleteBtn"
import LinkEditBtn from "../../../components/Button/LinkEditBtn"
import CustomPagination from "../../../components/CustomPagination"
import QuestionCategorySelector from "../../../components/Selector/QuestionCategorySelector"
import ErrorHandler from "../../../components/ErrorHandler"

import useModal from "../../../components/Modal/useModal"
import CustomModal from "../../../components/Modal/CustomModal"
import setModal from "../../../components/Modal/action"
import { getQuestionCategory, changePageSizeQuestionCategory, createCategory, updateCategory, deleteCategory } from "../redux/action"

const QuestionCategory = props => {
    const { t, questionCategory, allQuestionCategory, totalItems } = props

    const [searchString, setSearchString] = useState("")
    const [sortBy, setSortBy] = useState("Name")
    const [sortOrder, setSortOrder] = useState("ASC")

    const [currentCategory, setCurrentCategory] = useState({})
    const [currentCategoryError, setCurrentCategoryError] = useState("")

    const [isOpen, toggle] = useModal()

    const getAll = page => {
        const { currentPage, pageSize } = props
        props.getQuestionCategory({
            currentPage: page || currentPage,
            pageSize,
            searchString,
            sortBy,
            sortOrder
        })
    }

    useEffect(() => {
        document.title = t("question_category");
        getAll(1);
    }, [sortOrder])

    const resetAndReloadData = () => {
        toggle()
        setCurrentCategory({})
        getAll(1)
    }

    const renderModal = () => {
        const isEdit = currentCategory && currentCategory.id

        const currentParentCategory = currentCategory
            && allQuestionCategory
            && allQuestionCategory.find(item => item.id === currentCategory.parentCategoryId)

        return <CustomModal
            isOpen={isOpen}
            toggle={() => {
                toggle()
                setCurrentCategory({})
            }}
            title={isEdit ? t("question_category") : t("create_category")}
            t={t}
            confirmText={isEdit ? t("update") : t("create")}
            onConfirm={() => {
                if (!currentCategory || !currentCategory.name || !currentCategory.name.trim()) {
                    setCurrentCategoryError(t("category_name_required"))
                    return
                }

                if (!isEdit) {
                    props.createCategory({
                        ...currentCategory,
                        skills: [],
                        cb: resetAndReloadData
                    })
                    return
                }

                props.updateCategory({ ...currentCategory, cb: resetAndReloadData })
            }}
        >
            <Row className="mb-2">
                <Col md={12} className="mb-2">
                    <Label>{t("category_name")}</Label>
                    <Input
                        placeholder={t("category_name")}
                        value={currentCategory && currentCategory.name}
                        onChange={e => {
                            setCurrentCategory({ ...currentCategory, name: e.target.value })
                            setCurrentCategoryError("")
                        }}
                        invalid={currentCategoryError && currentCategoryError.trim()}
                    />
                    {currentCategoryError && currentCategoryError.trim()
                        ? <ErrorHandler text={currentCategoryError} />
                        : null}
                </Col>
                <Col md={12} className="mb-2">
                    <QuestionCategorySelector
                        label={t("parent_category")}
                        isDisabled={isEdit}
                        value={currentParentCategory
                            ? { value: currentParentCategory.id, label: `${currentParentCategory.path ? currentParentCategory.path + "/" : ""}${currentParentCategory.name}` }
                            : null}
                        onChange={e => setCurrentCategory({ ...currentCategory, parentCategoryId: e.value })}
                    />
                </Col>
                {/* <Col md={12}>
                    <Label>{t("skills")}</Label>
                </Col> */}
            </Row>
        </CustomModal>
    }

    const removeCategory = id => {
        props.setModal({
            type: "danger",
            message: t("delete_category_confirm"),
            cb: () => props.deleteCategory({
                id,
                cb: () => getAll(1)
            })
        })
    }

    const onSort = sortBy => () => {
        setSortBy(sortBy)
        if (sortOrder === "ASC") {
            setSortOrder("DESC")
        } else {
            setSortOrder("ASC")
        }
    }

    return <div>
        {renderModal()}
        <Row className="mb-2">
            <Col md={5} className="d-flex">
                <div style={{ marginRight: "30px" }}>
                    <CreateBtn
                        title={t("create_category")}
                        style={{ width: "120px", height: "32px" }}
                        onClick={toggle}
                    />
                </div>
                <SearchBox
                    value={searchString}
                    onChange={e => setSearchString(e.target.value)}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            getAll(1);
                        }
                    }}
                />
            </Col>
        </Row>

        <Row className="mb-2">
            {questionCategory && questionCategory.length > 0
                ? <>
                    <Col md={8} className="text-right">
                        <b>{t("total_category")}: {totalItems}</b>
                    </Col>
                    <Col md={8}>
                        <Table hover bordered striped responsive className="grid-table mb-2">
                            <thead>
                                <tr>
                                    <th>
                                        {t("category_name")}
                                        <i
                                            className="fa fa-sort float-right mt-1"
                                            style={{ cursor: 'pointer' }}
                                            onClick={onSort("Name")}
                                        />
                                    </th>
                                    <th>
                                        {t("number_of_questions")}
                                        {/* <i
                                        className="fa fa-sort float-right mt-1"
                                        style={{ cursor: 'pointer' }}
                                        onClick={onSort("NumberOfQuestions")}
                                    /> */}
                                    </th>
                                    {/* <th>{t("skills")}</th> */}
                                    <th className="text-center">{t("action")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {questionCategory.map((category, index) => <tr key={index}>
                                    <td>
                                        <LinkEditBtn
                                            noIcon
                                            onClick={() => {
                                                setCurrentCategory(category)
                                                toggle()
                                            }}
                                        >
                                            {category.name}
                                        </LinkEditBtn>
                                        <p className="mb-0"><i>{category.path || ""}</i></p>
                                    </td>
                                    <td>{category.numberOfQuestions}</td>
                                    {/* <td>{category.skill}</td> */}
                                    <td className="text-center pl-3">
                                        <DeleteBtn
                                            className="d-inline mr-3"
                                            onClick={() => removeCategory(category.id)}
                                        />
                                    </td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </Col>
                </>
                : <Col md={8} sm={12}>
                    <Alert color="primary">{t("no_category_to_display")}</Alert>
                </Col>}
        </Row>
        <Row className="mb-2">
            <Col md={8} className="text-right">
                <CustomPagination
                    pageCount={props.pageCount}
                    pageSize={props.pageSize}
                    currentPage={props.currentPage}
                    pageSizeName="questionCategoryPageSize"
                    changePage={currentPage => {
                        getAll(currentPage);
                    }}
                    changePageSize={props.changePageSizeQuestionCategory}
                    onChangePageSize={() => getAll(1)}
                />
            </Col>
        </Row>

    </div>
}

const mapStateToProps = state => ({
    questionCategory: state.questionCategory.questionCategory,
    allQuestionCategory: state.questionCategory.allQuestionCategory,
    currentPage: state.questionCategory.currentPage,
    pageCount: state.questionCategory.pageCount,
    pageSize: state.questionCategory.pageSize,
    totalItems: state.questionCategory.totalItems
})

const mapDispatchToProps = {
    setModal,
    getQuestionCategory,
    changePageSizeQuestionCategory,
    createCategory,
    updateCategory,
    deleteCategory
}

const QuestionCategoryHOC = withTranslation("common")(QuestionCategory)

export default connect(mapStateToProps, mapDispatchToProps)(QuestionCategoryHOC)