import React from "react";
import Select from "react-select";
import RenderCategory from "./RenderCategory";

const CategorySelector = ({ options = [], onChange = () => {}, invalid, ...props }) => {
    const formatOptionLabel = ({ value }) => {
        return <RenderCategory categoryId={value} />
    };

    return (
        <Select
            placeholder="Select category"
            options={options}
            formatOptionLabel={formatOptionLabel}
            onChange={onChange}
            className={invalid ? 'invalid-field' : ''}
            {...props}
        />
    );
};

export default CategorySelector;
