import React from 'react'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";

export default class ViewModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            modal: props.isShowing || false
        }
    }

    show = (modal) => this.setState({ modal })

    close = () => {
        const { closeAction } = this.props;
        this.show(false);
        if (closeAction) closeAction()
    }

    render() {
        const { modal } = this.state;
        const { title, children, btn, cancel, color, cancelLabel } = this.props;
        const closeLabel = this.props.closeLabel ? this.props.closeLabel : "Close"
        return (
            <Modal isOpen={modal} toggle={this.close} size="lg">
                {title && <ModalHeader toggle={this.close}>
                    {title}
                </ModalHeader>
                }
                <ModalBody>
                    {children}
                </ModalBody>
                <ModalFooter>
                    {btn && btn()}
                    <Button color={color || "secondary"} onClick={this.close}>
                        {cancel ? cancelLabel : closeLabel}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}
