import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Select from "react-select";
import { Alert, Col, FormGroup, Input, Row, Table } from "reactstrap";

import useFetch from "../../../hooks/useFetch";
import setModal from "../../../components/Modal/action";
import SearchBox from "../../../components/SearchBox";
import {
    getProofReaders,
    getQuestionCreators,
    getTasks,
    getAdminQuestions,
    getCBTQuestionCategories
} from "../../../util/api";
import { PROOFREADER_STATUS, QUESTION_TYPE } from "../../../util/Constant";
import AssignDialog from "./AssignDialog";
import RenderCategory from "../../../components/RenderCategory";
import CustomPagination from "../../../components/CustomPagination";
import { sortAlphabet } from "../../../util/Common";
import Spinner from "../../../components/Spinner";
import useAlert from "../../../hooks/useAlert";
import { cbtControllerURL } from "../../MyTask/constants";
import SubjectSelector from "../../../components/Selector/SubjectSelector";

const questionTypes = [
    { label: "Single Choice", value: "SingleChoice" },
    { label: "Multiple Choice", value: "MultipleChoice" }
];

const Question = props => {
    const { t } = props;
    const history = useHistory();
    const [questions, setQuestions] = useState(null);
    const { data: questionCreators } = useFetch(getQuestionCreators);
    const { data: allCategory } = useFetch(getCBTQuestionCategories);
    const [queryParams, setQueryParams] = useState({
        questionCreatorId: "",
        taskId: "",
        search: "",
        questionType: "",
        isPublishedToCBT: "",
        subjectId: "",
        subjectName: "",
        categoryId: "",
        difficultyLevel: "",
        fileName: "",
        questionNumber: "",
        categoryNumber: ""
    });
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [questionId, setQuestionId] = useState(null);
    const { data: proofReaders } = useFetch(getProofReaders);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [isFetchingQuestions, setIsFetchingQuestions] = useState(false);
    const { showError } = useAlert();
    const { data: tasks } = useFetch(getTasks, []);

    const {
        questionCreatorId,
        taskId,
        search,
        questionType,
        isPublishedToCBT,
        categoryId,
        subjectId,
        subjectName,
        difficultyLevel,
        fileName,
        questionNumber,
        categoryNumber
    } = queryParams;

    useEffect(() => {
        document.title = "Questions";
    }, []);

    useEffect(() => {
        getQuestionList();
    }, [
        questionCreatorId,
        taskId,
        questionType,
        isPublishedToCBT,
        subjectId,
        categoryId,
        difficultyLevel
    ]);

    useEffect(() => {
        if (!questions) return;

        if (currentPage > Math.ceil(questions.length / pageSize)) {
            setCurrentPage(1);
        }
    }, [pageSize, questions]);

    const getQuestionList = async () => {
        setIsFetchingQuestions(true);
        try {
            const res = await getAdminQuestions({
                isPublishedToCBT,
                taskId,
                questionCreatorId,
                questionType,
                search,
                subjectId,
                categoryId,
                difficultyLevel,
                fileName,
                questionNumber,
                categoryNumber
            });

            setQuestions(res.data);
        } catch (error) {
            showError(error.response.data.Message || error.message);
        }
        setIsFetchingQuestions(false);
    };

    const handleChangeQueryParams = newParam => {
        setQueryParams(oldParams => ({
            ...oldParams,
            ...newParam
        }));
    };

    const toggle = () => setIsOpenModal(!isOpenModal);

    const handleOpenAssignDialog = id => {
        setQuestionId(id);
        setIsOpenModal(true);
    };

    const qCreatorOptions =
        questionCreators &&
        questionCreators.map(item => {
            return {
                value: item.id,
                label: item.email
            };
        });

    const taskOptions = (tasks || [])
        .filter(e => e.email === queryParams.questionCreatorEmail)
        .map(item => {
            return {
                value: item.id,
                label: item.name
            };
        });

    const handleClickQuestionDetailModal = qsId => {
        const win = window.open(`/preview-question/${qsId}?action=preview`);
        win.focus();
    };

    const summaryWords = str => {
        const arr = str.split(" ");
        const arrFilter = arr.filter(word => word !== "");
        if (arrFilter.length > 21) {
            const newArrFilter = arrFilter.slice(0, 20);
            return `${newArrFilter.join(" ")} ...`;
        }

        return arrFilter.join(" ");
    };

    const parentSubject = (allCategory || []).find(
        e => subjectId && !e.parentId && e.title === subjectName
    );
    const categories = (allCategory || []).filter(
        e => subjectId && parentSubject && e.parentId === parentSubject.id
    );

    return (
        <div>
            <Spinner isLoading={isFetchingQuestions} />

            <Row>
                <Col xs={4} className="mb-2">
                    <SearchBox
                        value={queryParams.search}
                        onChange={e => {
                            handleChangeQueryParams({
                                search: e.target.value
                            });
                        }}
                        onKeyDown={e => {
                            if (e.key === "Enter") {
                                getQuestionList();
                            }
                        }}
                    />
                </Col>
            </Row>

            <Row>
                <AssignDialog
                    isOpen={isOpenModal}
                    toggle={toggle}
                    questionId={questionId}
                    refetch={getQuestionList}
                    proofReaders={proofReaders || []}
                    proofAssigned={[]}
                />

                <Col xs={2}>
                    <FormGroup>
                        <Select
                            isClearable
                            value={
                                qCreatorOptions &&
                                qCreatorOptions.find(item => {
                                    return (
                                        +item.value ===
                                        +queryParams.questionCreatorId
                                    );
                                })
                            }
                            placeholder="Select question creator"
                            options={sortAlphabet(qCreatorOptions)}
                            onChange={e => {
                                if (e) {
                                    handleChangeQueryParams({
                                        questionCreatorId: e.value,
                                        taskId: "",
                                        questionCreatorEmail: e.label
                                    });
                                } else {
                                    handleChangeQueryParams({
                                        questionCreatorEmail: "",
                                        questionCreatorId: "",
                                        taskId: ""
                                    });
                                }
                                // getTasksByQuestionCreator(e ? e.label : "");
                            }}
                        />
                    </FormGroup>
                </Col>

                {queryParams.questionCreatorId && (
                    <Col xs={2}>
                        <FormGroup>
                            <Select
                                isClearable
                                value={
                                    (taskOptions &&
                                        taskOptions.find(item => {
                                            return (
                                                +item.value ===
                                                +queryParams.taskId
                                            );
                                        })) ||
                                    null
                                }
                                placeholder="Select task"
                                options={sortAlphabet(taskOptions)}
                                onChange={e => {
                                    if (e) {
                                        handleChangeQueryParams({
                                            taskId: e.value
                                        });
                                    } else {
                                        handleChangeQueryParams({
                                            taskId: ""
                                        });
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                )}

                <Col xs={2}>
                    <FormGroup>
                        <Select
                            isClearable
                            placeholder="Select question type"
                            options={sortAlphabet(questionTypes)}
                            onChange={e => {
                                if (e) {
                                    handleChangeQueryParams({
                                        questionType:
                                            e.value ===
                                            QUESTION_TYPE.SINGLE_CHOICE
                                                ? 0
                                                : 1
                                    });
                                } else {
                                    handleChangeQueryParams({
                                        questionType: ""
                                    });
                                }
                            }}
                        />
                    </FormGroup>
                </Col>

                <Col xs={2}>
                    <FormGroup>
                        <Select
                            isClearable
                            placeholder="Select question export status"
                            // value={group}
                            options={[
                                {
                                    value: true,
                                    label: "Exported to CBT"
                                },
                                {
                                    value: false,
                                    label: "Not Exported to CBT"
                                }
                            ]}
                            onChange={e => {
                                if (e) {
                                    handleChangeQueryParams({
                                        isPublishedToCBT: e.value
                                    });
                                } else {
                                    handleChangeQueryParams({
                                        isPublishedToCBT: ""
                                    });
                                }
                            }}
                        />
                    </FormGroup>
                </Col>

                <Col xs={2}>
                    <SubjectSelector
                        onChange={e => {
                            if (e) {
                                handleChangeQueryParams({
                                    subjectId: e.value,
                                    subjectName: e.label
                                });
                            } else {
                                handleChangeQueryParams({
                                    subjectId: "",
                                    subjectName: ""
                                });
                            }
                        }}
                    />
                </Col>

                {subjectId && (
                    <>
                        <Col xs={2}>
                            <FormGroup>
                                <Select
                                    isClearable
                                    placeholder="Select category"
                                    options={sortAlphabet(
                                        categories.map(c => ({
                                            value: c.id,
                                            label: c.title
                                        }))
                                    )}
                                    onChange={e => {
                                        if (e) {
                                            handleChangeQueryParams({
                                                categoryId: e.value
                                            });
                                        } else {
                                            handleChangeQueryParams({
                                                categoryId: ""
                                            });
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={2}>
                            <Input
                                type="number"
                                placeholder="Category Number"
                                onChange={e => {
                                    handleChangeQueryParams({
                                        categoryNumber: e.target.value
                                    });
                                }}
                                onKeyDown={e => {
                                    if (e.key === "Enter") {
                                        getQuestionList();
                                    }
                                }}
                            />
                        </Col>
                    </>
                )}

                <Col xs={2}>
                    <Input
                        type="number"
                        placeholder="Question Number"
                        onChange={e => {
                            handleChangeQueryParams({
                                questionNumber: e.target.value
                            });
                        }}
                        onKeyDown={e => {
                            if (e.key === "Enter") {
                                getQuestionList();
                            }
                        }}
                    />
                </Col>

                <Col xs={2}>
                    <Input
                        type="text"
                        placeholder="File Name"
                        onChange={e => {
                            handleChangeQueryParams({
                                fileName: e.target.value
                            });
                        }}
                        onKeyDown={e => {
                            if (e.key === "Enter") {
                                getQuestionList();
                            }
                        }}
                    />
                </Col>

                <Col xs={2}>
                    <FormGroup>
                        <Select
                            isClearable
                            placeholder="Select difficulty level"
                            options={[
                                {
                                    value: 1,
                                    label: "Level 1"
                                },
                                {
                                    value: 2,
                                    label: "Level 2"
                                },
                                {
                                    value: 3,
                                    label: "Level 3"
                                },
                                {
                                    value: 4,
                                    label: "Level 4"
                                }
                            ]}
                            onChange={e => {
                                if (e) {
                                    handleChangeQueryParams({
                                        difficultyLevel: e.value
                                    });
                                } else {
                                    handleChangeQueryParams({
                                        difficultyLevel: ""
                                    });
                                }
                            }}
                        />
                    </FormGroup>
                </Col>

                {questions && questions.length ? (
                    <Col md={12}>
                        <div className="ag-theme-balham mb-2">
                            <Table
                                id="table-questions"
                                hover
                                bordered
                                striped
                                responsive
                                className="grid-table mb-2 mt-2"
                            >
                                <thead>
                                    <tr>
                                        <th style={{ width: "10%" }}>
                                            Question
                                        </th>
                                        <th style={{ width: "5%" }}>
                                            Question Number
                                        </th>
                                        <th style={{ width: "5%" }}>
                                            File Name
                                        </th>
                                        <th style={{ width: "5%" }}>
                                            Question order
                                        </th>
                                        <th style={{ width: "10%" }}>
                                            Article title
                                        </th>
                                        <th style={{ width: "10%" }}>
                                            Created by
                                        </th>
                                        <th style={{ width: "5%" }}>
                                            Spend time
                                        </th>
                                        <th style={{ width: "10%" }}>
                                            Task name
                                        </th>
                                        <th style={{ width: "5%" }}>Type</th>
                                        <th
                                            style={{ width: "10%" }}
                                            className="text-center"
                                        >
                                            Category
                                        </th>
                                        <th style={{ width: "10%" }}>
                                            Proofreaders
                                        </th>
                                        <th style={{ width: "10%" }}>
                                            Exported to CBT
                                        </th>
                                        <th style={{ width: "5%" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {questions
                                        .map(item => {
                                            const questionVersions = item.proofreaderCBTQuestions
                                                .filter(i => i.status != 0)
                                                .sort((a, b) =>
                                                    a.id > b.id ? -1 : 1
                                                );

                                            let proofreaderQuestion = null;
                                            if (questionVersions.length > 0)
                                                proofreaderQuestion =
                                                    questionVersions[0];
                                            const qText = proofreaderQuestion
                                                ? proofreaderQuestion.questionText
                                                : item.questionText;
                                            const hours = Math.floor(
                                                item.createQuestionTimeInSeconds /
                                                    3600
                                            );
                                            const minutes = Math.floor(
                                                (item.createQuestionTimeInSeconds -
                                                    hours * 3600) /
                                                    60
                                            );
                                            const seconds =
                                                item.createQuestionTimeInSeconds -
                                                hours * 3600 -
                                                minutes * 60;
                                            let shortText;
                                            const wrapper = document.createElement(
                                                "div"
                                            );
                                            const html = `<div dangerouslySetInnerHTML=${qText}</div>`;
                                            wrapper.innerHTML = html;
                                            const div = wrapper.firstChild;
                                            shortText = div.innerText;
                                            const questionText = summaryWords(
                                                shortText
                                            );
                                            const articleTitle = summaryWords(
                                                item.articleTitle
                                            );
                                            return (
                                                <tr key={item.id}>
                                                    <td>{questionText}</td>
                                                    <td>{item.questionNumber > 0 ? String(item.questionNumber).padStart(4, '0') : ""}</td>
                                                    <td>{item.fileName}</td>
                                                    <td>
                                                        {item.questionOrder
                                                            ? item.questionOrder
                                                            : ""}
                                                    </td>
                                                    <td>
                                                        {item.articleTitle
                                                            ? `${articleTitle} (${item.maxQuestion})`
                                                            : ""}
                                                    </td>
                                                    <td>{item.authorEmail}</td>
                                                    <td>
                                                        {hours < 10
                                                            ? `0${hours}`
                                                            : hours}
                                                        :
                                                        {minutes < 10
                                                            ? `0${minutes}`
                                                            : minutes}
                                                        :
                                                        {seconds < 10
                                                            ? `0${seconds}`
                                                            : seconds}
                                                    </td>
                                                    <td>{item.taskName}</td>
                                                    <td>
                                                        {item.type === 0
                                                            ? QUESTION_TYPE.SINGLE_CHOICE
                                                            : QUESTION_TYPE.MULTIPLE_CHOICE}
                                                    </td>
                                                    <td>
                                                        {/* {item.categoryName} */}
                                                        <RenderCategory
                                                            categoryId={
                                                                item.categoryId
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        {item.proofreaderCBTQuestions.map(
                                                            (p, index) => {
                                                                const isApprove =
                                                                    !p.rejectionReason &&
                                                                    p.status !==
                                                                        0;

                                                                const isReject = !!p.rejectionReason;

                                                                return (
                                                                    <div
                                                                        className="my-1"
                                                                        style={{
                                                                            color: isApprove
                                                                                ? "#0d6efd"
                                                                                : isReject
                                                                                ? "#dc3545"
                                                                                : "black"
                                                                        }}
                                                                    >
                                                                        {isApprove && (
                                                                            <i className="fa fa-check mr-1" />
                                                                        )}
                                                                        {isReject && (
                                                                            <i className="fa fa-times mr-1" />
                                                                        )}
                                                                        Proofreader{" "}
                                                                        {index +
                                                                            1}
                                                                        :{" "}
                                                                        <b>
                                                                            {
                                                                                p.fullName
                                                                            }
                                                                        </b>{" "}
                                                                        {p.rejectionReason
                                                                            ? "rejected the question"
                                                                            : p.isCompletedTest &&
                                                                              p.status ===
                                                                                  0
                                                                            ? "completed test"
                                                                            : PROOFREADER_STATUS[
                                                                                  p
                                                                                      .status
                                                                              ]}
                                                                    </div>
                                                                );
                                                            }
                                                        )}

                                                        {(item
                                                            .proofreaderCBTQuestions
                                                            .length === 0 ||
                                                            item
                                                                .proofreaderCBTQuestions[
                                                                item
                                                                    .proofreaderCBTQuestions
                                                                    .length - 1
                                                            ].status !== 0) &&
                                                            !item.isPublishedToCBT && (
                                                                <a
                                                                    className="d-block mt-2"
                                                                    onClick={() =>
                                                                        handleOpenAssignDialog(
                                                                            item.id
                                                                        )
                                                                    }
                                                                    href="#"
                                                                >
                                                                    + Add
                                                                    proofreader
                                                                </a>
                                                            )}
                                                    </td>
                                                    <td>
                                                        {item.isPublishedToCBT ? (
                                                            <i
                                                                className="fa fa-check mr-1"
                                                                aria-hidden="true"
                                                                style={{
                                                                    color:
                                                                        "blue"
                                                                }}
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                        {item.publishedCBTQuestionId ? (
                                                            <a
                                                                target="_blank"
                                                                href={`${cbtControllerURL}/question_bank/edit/${item.publishedCBTQuestionId}`}
                                                                alt=""
                                                                rel="noreferrer"
                                                            >
                                                                View in CBT
                                                            </a>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </td>
                                                    <td>
                                                        <i
                                                            className="fa fa-eye cursor-pointer mr-2"
                                                            aria-hidden="true"
                                                            title="View detail"
                                                            onClick={() => {
                                                                handleClickQuestionDetailModal(
                                                                    item.id
                                                                );
                                                            }}
                                                        ></i>

                                                        <i
                                                            onClick={() =>
                                                                history.push(
                                                                    `/approval-history/${item.id}`
                                                                )
                                                            }
                                                            title="History"
                                                            className="fa fa-history cursor-pointer"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                        .reverse()
                                        .slice(
                                            (currentPage - 1) * pageSize,
                                            currentPage * pageSize
                                        )}
                                </tbody>
                            </Table>

                            <CustomPagination
                                pageCount={
                                    questions
                                        ? Math.ceil(questions.length / pageSize)
                                        : 0
                                }
                                pageSize={pageSize}
                                currentPage={currentPage}
                                // pageSizeName={}
                                changePage={e => {
                                    setCurrentPage(e);
                                }}
                                changePageSize={e => {
                                    setPageSize(e);
                                }}
                                onChangePageSize={() => {}}
                            />
                        </div>
                    </Col>
                ) : (
                    <Col md={12} className="mt-1">
                        <Alert color="primary">
                            {t("no_question_to_display")}
                        </Alert>
                    </Col>
                )}
            </Row>
        </div>
    );
};

const mapStateToProps = state => ({
    questionList: state.question.questionList,
    pageSize: state.question.pageSize,
    currentPage: state.question.currentPage,
    pageCount: state.question.pageCount,
    totalItems: state.question.totalItems,
    tableZone: state.common.tableZone,
    userInfo: state.common.userInfo
});

const mapDispatchToProps = {
    setModal
};

const QuestionHOC = withTranslation("common")(Question);

export default connect(mapStateToProps, mapDispatchToProps)(QuestionHOC);
