import React from 'react'
import {
    Card,
    CardBody,
    Col,
    Row,
    FormGroup,
    Label,
    Input,
    CardTitle,
} from "reactstrap";
import _ from "lodash";

export default ({
    fortnoxCustomer,
    fortnoxEmployee,
    t
}) => {
    return (
        <Card className="mt-2 mb-1">
            <CardBody className="p-2">
                <CardTitle className="m-0"><strong>{t('Fortnox_information')}</strong></CardTitle>
                <Row>
                    <Col md="6 pr-md-1">
                        <FormGroup>
                            <Label>{t('Employee_id')}</Label>
                            <Input
                                value={_.get(fortnoxEmployee, "employeeId", t("empty"))}
                                readOnly
                            />
                        </FormGroup>
                    </Col>

                    <Col md="6 pl-md-1">
                        <FormGroup>
                            <Label>{t('Customer_id')}</Label>
                            <Input
                                value={_.get(fortnoxCustomer, "customerNumber", t("empty"))}
                                readOnly
                            />
                        </FormGroup>
                    </Col>

                </Row>
            </CardBody>
        </Card>
    )
}
