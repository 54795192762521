import React, { useState, useRef, useEffect } from "react"
import { Row, Col, Alert, Table, CustomInput } from "reactstrap"
import { withTranslation } from "react-i18next"
import { AgGridReact } from '@ag-grid-community/react'
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { connect } from "react-redux"
import _ from "lodash"
import moment from "moment"

import SearchBox from "../../../components/SearchBox"
import CreateBtn from "../../../components/Button/CreateBtn"
import { getAll, removeAssessment, changePageSizeAssessmentList } from "../redux/action"
import DeleteBtn from "../../../components/Button/DeleteBtn"
import EditBtn from "../../../components/Button/EditBtn"
import ViewBtn from "../../../components/Button/ViewBtn"
import setModal from "../../../components/Modal/action"
import CustomPagination from "../../../components/CustomPagination"

const AssessmentsList = props => {
    const {
        t,
        assessmentList,
        // props for select test in test pack detail
        isInTestPackDetail,
        selectedTests,
        allSelectedTestIds,
        onClickCheckbox
    } = props

    const [searchString, setSearchString] = useState(props.location && props.location.searchString || "")

    const gridApi = useRef(null)

    const getData = (page, pageSizeFromState) => {
        const { currentPage, pageSize } = props
        props.getAll({
            currentPage: page || currentPage,
            pageSize: pageSizeFromState || pageSize,
            searchString,
            isInHouseTest: isInTestPackDetail ? false : undefined
        })
    }

    useEffect(() => {
        if (!isInTestPackDetail) document.title = t("assessment.title")
        getData(1)
    }, [])

    useEffect(() => {
        if (gridApi.current) {
            gridApi.current.setRowData([...assessmentList])
            gridApi.current.sizeColumnsToFit()
        }
    }, [assessmentList])

    const onDelete = (id, pageSizeFromState) => {
        props.setModal({
            type: "danger",
            message: t("delete_assessment_confirm"),
            cb: () => props.removeAssessment({ id, cb: () => getData(1, pageSizeFromState) })
        })
    }

    const renderName = params => {
        if (!params.data || Object.keys(params.data).length === 0) return null
        return <p>{(params.data.name)}</p>
    }

    const renderGrade = params => {
        if (!params.data || Object.keys(params.data).length === 0) return null
        return params.data.grade
            ? <p>{(params.data.grade)}</p>
            : null
    }

    const renderSubject = params => {
        if (!params.data || Object.keys(params.data).length === 0) return null
        return <p>{params.data.subject.name}</p>
    }

    const renderCategory = params => {
        if (!params.data || Object.keys(params.data).length === 0) return null
        const categoryNames = params.data.questions.filter(i => i.question.category).map(q => q.question.category.name).reduce((a, b) => a.concat(b), [])
        return <p>{(new Set(categoryNames).join(', '))}</p>
    }

    const renderNumberOfQuestions = params => {
        if (!params.data || Object.keys(params.data).length === 0) return null
        if (params.data.type === "Randomized") {
            return params.data.numberOfQuestions
        }
        return <p>{`${params.data.questions.length}/${params.data.numberOfQuestions}`}</p>
    }

    const typeCapitalized = type => (type.charAt(0).toUpperCase() + type.toLowerCase().slice(1))

    const renderType = params => {
        if (!params.data || Object.keys(params.data).length === 0) return null
        switch (params.data.type) {
            case 'Randomized':
                return <p>{typeCapitalized(t('assessment.randomized'))}</p>
            case 'SelectQuestions':
                return <p>{typeCapitalized(t('assessment.selectQuestions'))}</p>
            default:
                return <p>{typeCapitalized(t('unknown'))}</p>
        }
    }

    const renderDuration = params => {
        if (!params.data || Object.keys(params.data).length === 0) return null
        return <p>{(params.data.durationInMinutes)}</p>
    }
    const renderBeginDate = params => {
        if (!params.data || Object.keys(params.data).length === 0 || !params.data.endDate) return null
        return <p>{moment(params.data.beginDate).format("L")}</p>
    }
    const renderEndDate = params => {
        if (!params.data || Object.keys(params.data).length === 0 || !params.data.endDate) return null
        return <p>{moment(params.data.endDate).format("L")}</p>
    }
    const renderYear = params => {
        if (!params.data || Object.keys(params.data).length === 0) return null
        return <p>{(params.data.year)}</p>
    }
    const renderTerm = params => {
        if (!params.data || Object.keys(params.data).length === 0) return null
        return <p>{(params.data.term)}</p>
    }
    const renderWeek = params => {
        if (!params.data || Object.keys(params.data).length === 0) return null
        return <p>{(params.data.week)}</p>
    }
    const renderActions = params => {
        if (!params.data || Object.keys(params.data).length === 0 || !params.data.id) return null
        const pageSizeFromState = params.agGridReact.props.props.pageSize

        const { id } = params.data

        return <div className="d-flex">
            <ViewBtn
                className="mr-3"
                onClick={() => props.history.push(`/preview-assessment/${id}`)}

            />
            <EditBtn
                className="mr-3"
                onClick={() => props.history.push(`/edit-assessment/${id}`)}

            />
            <DeleteBtn
                onClick={() => onDelete(id, pageSizeFromState)}
            />
        </div>
    }

    const renderIsInHouseTest = (params) => {
        if (!params.data || Object.keys(params.data).length === 0) return null
        if (params.data.isInHouseTest) {
            return <p>In-house</p>
        }
        return <p>Commercial</p>
    }

    const gridOptions = {
        columnDefs: [
            {
                headerName: t('name'),
                width: props.tableZone * 0.15,
                cellRendererFramework: params => renderName(params)
            },
            {
                headerName: t('grade'),
                width: props.tableZone * 0.05,
                cellRendererFramework: params => renderGrade(params)
            },
            {
                headerName: t('subject'),
                width: props.tableZone * 0.05,
                field: "type",
                cellRendererFramework: params => renderSubject(params),
            },
            {
                headerName: t('category'),
                width: props.tableZone * 0.075,
                field: "categories",
                cellRendererFramework: params => renderCategory(params),
            },
            {
                headerName: t('assessment.type'),
                width: props.tableZone * 0.08,
                cellRendererFramework: params => renderType(params)
            },
            {
                headerName: t('assessment.numberOfQuestions'),
                width: props.tableZone * 0.05,
                cellRendererFramework: params => renderNumberOfQuestions(params)
            },
            {
                headerName: t('assessment.duration'),
                width: props.tableZone * 0.05,
                cellRendererFramework: params => renderDuration(params)
            },
            {
                headerName: t('assessment.type'),
                width: props.tableZone * 0.08,
                cellRendererFramework: params => renderIsInHouseTest(params)
            },
            {
                headerName: "Begin date",
                width: props.tableZone * 0.07,
                cellRendererFramework: params => renderBeginDate(params)
            },
            {
                headerName: "End date",
                width: props.tableZone * 0.07,
                cellRendererFramework: params => renderEndDate(params)
            },
            {
                headerName: "Term",
                width: props.tableZone * 0.05,
                cellRendererFramework: params => renderTerm(params)
            },
            {
                headerName: "Week",
                width: props.tableZone * 0.05,
                cellRendererFramework: params => renderWeek(params)
            },
            {
                headerName: "Year",
                width: props.tableZone * 0.05,
                cellRendererFramework: params => renderYear(params)
            },
            {
                headerName: t('actions'),
                width: props.tableZone * 0.075,
                cellRendererFramework: params => renderActions(params)
            }
        ],
        defaultColDef: {
            resizable: true
        },
        rowData: [],
        localeText: { noRowsToShow: t('assessment.no_assessements_to_display') },
        // detailCellRenderer: "childTable",
        // frameworkComponents: { childTable: ChildTableWithProps }
    }


    useEffect(() => {
        if (gridApi.current) {
            gridApi.current.setRowData([...assessmentList])
            gridApi.current.sizeColumnsToFit()
        }
    }, [assessmentList])

    const TableMode = () => <Table hover bordered striped responsive className="grid-table mb-2">
        <thead>
            <tr>
                <th>{t("name")}</th>
                <th>{t("subject")}</th>
                <th>{t("duration")}</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {assessmentList.map((item, index) => <tr
                key={index}
            >
                <td>{item.name}</td>
                <td>{_.get(item, "subject.name", "")}</td>
                <td>{item.durationInMinutes}</td>
                <td className="text-center">
                    <CustomInput
                        id={`test-checkbox-${index}`}
                        type="checkbox"
                        checked={allSelectedTestIds && allSelectedTestIds.includes(item.id)}
                        disabled={selectedTests && !selectedTests.map(item => item.id).includes(item.id) && allSelectedTestIds.includes(item.id)}
                        onClick={e => onClickCheckbox(e.target.checked, item)}
                    />
                </td>
            </tr>)}
        </tbody>
    </Table>
    return <div>
        <Row className="mb-2">
            <Col md={!isInTestPackDetail ? 6 : 12} className="d-flex">
                {!isInTestPackDetail && <div style={{ marginRight: "30px" }}>
                    <CreateBtn
                        title={t("create_assessment")}
                        style={{ width: "150px", height: "32px" }}
                        onClick={() => props.history.push("/create-assessment")}
                    />
                </div>}
                <SearchBox
                    className="mr-0"
                    value={searchString}
                    onChange={e => setSearchString(e.target.value)}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            getData(1)
                        }
                    }}
                />
            </Col>
        </Row>
        <Row>
            {assessmentList && assessmentList.length
                ? <Col md={12}>
                    {!isInTestPackDetail
                        ? <div className="ag-theme-balham mb-2">
                            <AgGridReact
                                props={{ pageSize: props.pageSize }}
                                domLayout="autoHeight"
                                gridOptions={gridOptions}
                                masterDetail
                                onGridReady={params => {
                                    gridApi.current = params.api
                                    gridApi.current.setRowData([...assessmentList])
                                }}
                                modules={[MasterDetailModule, ClientSideRowModelModule]}
                            >
                            </AgGridReact>
                        </div>
                        : <TableMode />}
                </Col>
                : <Col md={12}>
                    <Alert color="primary">{t('assessment.no_assessments_to_display')}</Alert>
                </Col>}
        </Row>
        <Row className="mb-2">
            <Col md={12} className="text-right">
                <CustomPagination
                    pageCount={props.pageCount}
                    pageSize={props.pageSize}
                    currentPage={props.currentPage}
                    pageSizeName="assessmentListPageSize"
                    changePage={currentPage => {
                        getData(currentPage)
                    }}
                    changePageSize={props.changePageSizeAssessmentList}
                    onChangePageSize={() => getData(1)}
                />
            </Col>
        </Row>
    </div>
}

const mapStateToProps = state => ({
    assessmentList: state.assessment.assessmentList,
    pageSize: state.assessment.pageSize,
    currentPage: state.assessment.currentPage,
    pageCount: state.assessment.pageCount,
    totalItems: state.assessment.totalItems,
    tableZone: state.common.tableZone
})

const mapDispatchToProps = {
    getAll,
    removeAssessment,
    changePageSizeAssessmentList,
    setModal
}

const AssessmentsHOC = withTranslation("common")(AssessmentsList)

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentsHOC)