import { useEffect, useRef, useState } from "react"

const useFetch = (queryFn, deps = [], enable = true) => {
    const [data, setData] = useState()
    const [error, setError] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const refetch = useRef();
    const action = useRef(queryFn);

    action.current = queryFn;

    useEffect(() => {
        if (!enable) return;
        let isExpired = false;

        const fetcher = async () => {
            try {
                setIsLoading(true)
                const res = await action.current()

                if (!isExpired) {
                    setData(res.data || res)
                }
            } catch (err) {
                setError(err)
            }
            setIsLoading(false)
        }

        refetch.current = fetcher;

        fetcher()

        return () => {
            isExpired = true;
        }   
    }, deps)

    return { data, error, isLoading, reload: refetch.current }
}

export default useFetch