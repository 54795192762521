import React, { Component } from "react";
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { withTranslation } from 'react-i18next'
import { getAccessToken } from "../../../util/Common";
import history from "../../../util/history";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      lang: localStorage.getItem("lang") || "en",
      isNew: false
    };
  }

  componentDidMount() {
    const adminToken = this.props.location
      && this.props.location.search.split("=")[0] === "adminToken"
      && this.props.location.search.split("=")[1]

    if (adminToken) {
      localStorage.setItem("ACCESS_TOKEN", adminToken)
      history.push("/")
    } else {
      const token = getAccessToken();
      if (token) {
        history.push("/");
      } else {
        const isNew = this.props.location && this.props.location.search.includes("?new")

        if (isNew) {
          const adminEmail = this.props.location.search.split("=")[1]
          this.setState({ isNew, username: adminEmail })
        }
      }
    }
  }

  handleClick = e => {
    e.preventDefault();
    const { username, password } = this.state;
    const { t } = this.props
    if (username.trim().length === 0 || password.trim().length === 0) {
      this.props.setAlert(t('email_password_not_valid'));
    } else {
      this.props.login(username.trim(), password);
    }
  };

  changeLanguage = e => {
    const { i18n } = this.props;
    this.setState({ lang: e })
    localStorage.setItem('lang', e);
    i18n.changeLanguage(e)
  }

  render() {
    const { t } = this.props
    const { lang } = this.state
    const srcImg = lang === "en" ? "assets/img/images/england.png" : "assets/img/images/sweden.png"

    return (
      <Form onSubmit={this.handleClick}>
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="4">
                <CardGroup>
                  <Card className="p-4">
                    <CardBody>
                      <h1>{t('login')}</h1>
                      <p className="text-muted d-flex align-items-center justify-content-between">
                        {t('sign_in_to_your_account')}
                        {/* <img
                          src={srcImg}
                          alt="flag language"
                          width="20px"
                          height="20px"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            lang === "en" ? this.changeLanguage("se") : this.changeLanguage("en")
                          }}
                        /> */}
                      </p>
                      {this.state.isNew && <p>{t("please_check_your_email")}</p>}
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          value={this.state.username}
                          onChange={event => this.setState({ username: event.target.value })}
                          placeholder={t("Email/login_name")}
                          autoComplete="username"
                          required
                        />
                      </InputGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          onChange={event => this.setState({ password: event.target.value })}
                          placeholder={t('Password')}
                          autoComplete="current-password"
                          required
                        />
                      </InputGroup>
                      <Row>
                        <Col xs="12">
                          <Link to="/forgot-password" className="d-inline-block mt-1 mb-3">
                            Forgot password?
                        </Link>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6">
                          <Button color="primary" className="px-4" type="submit">
                            {t('login')}
                          </Button>
                        </Col>
                        <Col xs="6" className="text-right" />
                      </Row>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div>
      </Form>
    );
  }
}
export default withTranslation('common')(Login);
