import React, { useState, useEffect } from "react"
import {
  Table,
  Row,
  Col,
  Alert,
  Input,
  Button,
  Label,
  Collapse,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import {CKEditor} from "ckeditor4-react"

import SubscriptionList from "../../Subscription/view/Home"
import TestPackList from "../../TestPack/view/index"
import ErrorHandler from "../../../components/ErrorHandler"
import ImagePreview from "../../../components/ImagePreview"
import GradeSelector from "../../../components/Selector/GradeSelector"
import CustomModal from "../../../components/Modal/CustomModal"

import setModal from "../../../components/Modal/action"
import { createSuite, updateSuite, getSuite } from "../redux/action"
import { uploadFiles } from "../../User/redux/action"
import { guidGenerator } from "../../../util/helper"
import LinkEditBtn from "../../../components/Button/LinkEditBtn"
import useModal from "../../../components/Modal/useModal"
import DeleteBtn from "../../../components/Button/DeleteBtn"
import CustomInput from "reactstrap/lib/CustomInput"

const packStyle = {
  padding: "8px 16px",
  marginBottom: "8px",
  borderRadius: "4px",
  backgroundColor: "#f2f2f2",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}

const pricingPlanStyle = {
  padding: "8px 16px",
  border: "1px solid #ddd",
  borderRadius: "4px",
  backgroundColor: "#f2f2f2",
}

const defaultPricingPlan = {
  guid: guidGenerator(),
  isOpen: true,
  name: "New pricing plan",
  description: "",
  price: 0,
  // renewalPrice: 0,
  expiryPeriodInDays: 30,
}

const TestSuiteDetail = (props) => {
  const {
    t,
    match: {
      params: { id },
    },
  } = props
  const [description, setDescription] = useState(null)
  const [instruction, setInstruction] = useState(undefined)
  const [name, setName] = useState("")
  const [grade, setGrade] = useState(null)
  const [testPacks, setTestPacks] = useState([])
  const [pricingPlans, setPricingPlans] = useState([defaultPricingPlan])
  const [imageUrl, setImageUrl] = useState("")
  const [isFree, setIsFree] = useState(false)
  const [freeDurationInDays, setFreeDurationInDays] = useState(false)

  const getData = async () => {
    const res = await props.getSuite(id)
    if (!res || !res.data) return

    setName(res.data.name)
    setGrade(res.data.grade)
    setDescription(res.data.description)
    setInstruction(res.data.instruction)
    setTestPacks(res.data.testPacks)
    setIsFree(res.data.isFree)
    setFreeDurationInDays(res.data.freeDurationInDays)
    setPricingPlans(
      res.data.pricingPlans.map((plan) => ({
        guid: guidGenerator(),
        id: plan.id,
        isOpen: false,
        name: plan.name,
        description: plan.description,
        price: plan.price,
        // renewalPrice: plan.renewalPrice,
        expiryPeriodInDays: plan.expiryPeriodInDays,
      }))
    )
    setImageUrl(res.data.imageUrl)

    document.title = res.data.name
  }

  useEffect(() => {
    if (id) {
      getData()
    } else {
      document.title = t("create_test_suite")
    }
  }, [])

  const onClickCheckbox = (checked, pack) => {
    if (!checked) {
      setTestPacks(testPacks.filter((item) => item.id !== pack.id))
      return
    }

    if (!testPacks.find((item) => item.id === pack.id)) {
      setTestPacks([...testPacks, pack])
    }
  }

  const updatePricingPlans = (guid, value) => {
    const newPricingPlans = pricingPlans.map((plan) => {
      if (plan.guid !== guid) return plan
      return {
        ...plan,
        ...value,
      }
    })

    setPricingPlans(newPricingPlans)
  }

  const onAddNewPricingPlan = () => {
    setPricingPlans([
      ...pricingPlans,
      {
        guid: guidGenerator(),
        isOpen: true,
        name: "New pricing plan",
        description: "",
        price: 0,
        // renewalPrice: 0,
        expiryPeriodInDays: 30,
      },
    ])
  }

  const onDeletePrincingPlan = (guid) => {
    if (!id) {
      setPricingPlans(pricingPlans.filter((plan) => plan.guid !== guid))
      return
    }

    props.setModal({
      type: "danger",
      message: t("delete_pricing_plan_confirm"),
      cb: () =>
        setPricingPlans(pricingPlans.filter((plan) => plan.guid !== guid)),
    })
  }

  const [file, setFile] = useState(null)
  const [imageChanged, setImageChanged] = useState(false)
  const [checkRequired, setCheckRequired] = useState(false)
  const handleSubmit = async () => {
    try {
      setCheckRequired(true)
      if (!name || !name.trim()) return
      if (!description || !description.trim()) return
      if (!instruction || !instruction.trim()) return
      if (!grade) return
      // check number of packs in suite
      if (!testPacks || !testPacks.length) {
        props.setModal({
          type: "danger",
          message: t("test_suite_must_have_at_least_1_pack"),
        })
        return
      }
      if (isFree && (!freeDurationInDays || parseInt(freeDurationInDays) <= 0)) return
      if (!isFree) {
        // check duplicate name pricing plan
        if (pricingPlans.length < 1) {
          props.setModal({
            type: "danger",
            message: t("at_least_1_plan_paid_test_suite"),
          })
          return
        }
        const pricingPlanNames = pricingPlans.map((plan) => plan.name)
        const uniquePricingPlanNames = [...new Set(pricingPlanNames)]

        if (uniquePricingPlanNames.length < pricingPlanNames.length) {
          props.setModal({
            type: "danger",
            message: t("duplicate_pricing_plan_name"),
          })
          return
        }

        if (pricingPlans.some((i) => i.price === 0)) {
          props.setModal({
            type: "danger",
            message: t("cannot_have_zero_price_pricing_plan"),
          })
          return
        }
      }

      if ((!id && !file) || (id && !imageUrl)) {
        props.setModal({
          type: "danger",
          message: t("you_have_not_choose_file_yet"),
        })
      }

      let newImageUrl = imageUrl

      if (!id || imageChanged) {
        // upload image to get imageUrl
        const formData = new FormData()
        const fileName = file.name
        formData.append("filesUpload", file, fileName)
        const res = await props.uploadFiles(formData)
        newImageUrl = res ? res[0] : ""
      }

      const data = {
        id,
        name,
        grade,
        description,
        instruction,
        isFree,
        freeDurationInDays: parseInt(freeDurationInDays),
        imageUrl: newImageUrl,
        testPackIds: testPacks.map((pack) => pack.id),
        pricingPlans: (!id && !!isFree)
          ? []
          : pricingPlans.map((plan) => ({
            id: plan.id,
            name: plan.name,
            description: plan.description,
            price: plan.price,
            // renewalPrice: plan.renewalPrice,
            expiryPeriodInDays: plan.expiryPeriodInDays,
          })),
      }



      const func = id ? props.updateSuite : props.createSuite
      func(data, () => props.history.push("/test-management/test-suite"))
    } catch (err) {
      console.log(err.message)
    }
  }

  const [isOpenModal, toggleModal] = useModal()

  const renderModal = () => {
    return (
      <CustomModal
        isOpen={isOpenModal}
        toggle={toggleModal}
        title={t("add_test_pack")}
        noFooter
      >
        <Row>
          <Col md={12}>
            <p className="mb-2 font-weight-bold">{t("test_pack_search")}</p>
            <TestPackList
              history={props.history}
              isInTestSuiteDetail
              selectedPacks={testPacks}
              onClickCheckbox={onClickCheckbox}
            />
          </Col>
        </Row>
      </CustomModal>
    )
  }

  const [activeTab, setActiveTab] = useState("1")

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const tabs = [
    { name: t("basic_information"), tabId: "1" },
    { name: t("test_pack_list"), tabId: "2" },
    { name: t("pricing_plan_list"), tabId: "3" },
  ]

  const idRequiredTabs = [{ name: t("subscription_list"), tabId: "4" }]

  const tabRender = id ? [...tabs, ...idRequiredTabs] : tabs

  return (
    <div className="pt-2 pb-5">
      {renderModal()}
      <h5 className="p-2">{t("test_suite_detail")}</h5>
      <Nav tabs>
        {tabRender.map((tab, index) => (
          <NavItem key={index}>
            <NavLink
              className={activeTab === tab.tabId ? "active" : ""}
              onClick={() => toggle(tab.tabId)}
            >
              {tab.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <Row className="py-2 px-3">
        <Col md={12} className="mb-2">
          <TabContent activeTab={activeTab} style={{ border: "none" }}>
            <TabPane tabId="1" className="p-0">
              <Row>
                <Col md={6}>
                  <Row>
                    <Col md={8}>
                      <p className="mb-2 font-weight-bold">
                        {t("test_suite_name")}
                        <span className="text-danger">*</span>
                      </p>
                      <Input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder={t("test_suite_name")}
                        className="mb-2"
                        invalid={checkRequired && (!name || !name.trim())}
                      />
                      {checkRequired && (!name || !name.trim()) && (
                        <ErrorHandler text={t("test_suite_name_empty")} />
                      )}
                    </Col>
                    <Col md={4}>
                      <p className="mb-2 font-weight-bold">
                        {t("test_suite_grade")}
                        <span className="text-danger">*</span>
                      </p>
                      <GradeSelector
                        noLabel
                        value={
                          grade
                            ? {
                              value: grade,
                              label: grade,
                            }
                            : null
                        }
                        onChange={(e) => setGrade(e.value)}
                      />
                      {checkRequired && !grade && (
                        <ErrorHandler text={t("test_suite_grade_empty")} />
                      )}
                    </Col>
                  </Row>
                  <p className="mb-2 font-weight-bold">
                    {t("test_suite_description")}
                    <span className="text-danger">*</span>
                  </p>
                  {(!id || description !== null) && (
                    <CKEditor
                      data={description}
                      onChange={(e) => setDescription(e.editor.getData())}
                      config={{
                        height: 100,
                        allowedContent: true,
                        toolbar: [
                          {
                            name: "basicstyles",
                            groups: ["basicstyles", "cleanup"],
                            items: ["Bold", "Italic"],
                          },
                          {
                            name: "insert",
                            items: ["Image"],
                          },
                          { name: "others" },
                        ],
                        extraPlugins: "html5audio",
                        //  TODO change upload api
                        filebrowserImageUploadUrl: "/api/file/Upload?",
                        filebrowserUploadUrl: "/api/file/Upload?",
                      }}
                      onBeforeLoad={(CKEDITOR) => {
                        CKEDITOR.disableAutoInline = true
                        CKEDITOR.config.height = 100
                        CKEDITOR.enterMode = CKEDITOR.ENTER_BR
                      }}
                    />
                  )}
                  {checkRequired && (!description || !description.trim()) && (
                    <ErrorHandler text={t("test_suite_description_empty")} />
                  )}

                  <p className="my-2 font-weight-bold">
                    {t("test_suite_instruction")}
                    <span className="text-danger">*</span>
                  </p>
                  {(!id ||
                    instruction === null ||
                    typeof instruction === "string") && (
                      <CKEditor
                        data={instruction}
                        onChange={(e) => setInstruction(e.editor.getData())}
                        config={{
                          height: 100,
                          allowedContent: true,
                          toolbar: [
                            {
                              name: "basicstyles",
                              groups: ["basicstyles", "cleanup"],
                              items: ["Bold", "Italic"],
                            },
                            {
                              name: "insert",
                              items: ["Image"],
                            },
                            { name: "others" },
                          ],
                          extraPlugins: "html5audio",
                          //  TODO change upload api
                          filebrowserImageUploadUrl: "/api/file/Upload?",
                          filebrowserUploadUrl: "/api/file/Upload?",
                        }}
                        onBeforeLoad={(CKEDITOR) => {
                          CKEDITOR.disableAutoInline = true
                          CKEDITOR.config.height = 100
                          CKEDITOR.config.enterMode = CKEDITOR.ENTER_BR
                        }}
                      />
                    )}
                  {checkRequired && (!instruction || !instruction.trim()) && (
                    <ErrorHandler text={t("test_suite_instruction_empty")} />
                  )}
                </Col>
                <Col md={6}>
                  <p className="mb-2 font-weight-bold">
                    {t("test_suite_image")}
                    <span className="text-danger">*</span>
                  </p>
                  <ImagePreview
                    maxHeight="300px"
                    imageUrl={imageUrl}
                    file={file}
                    setFile={setFile}
                    t={t}
                    setModal={props.setModal}
                    callback={() => setImageChanged(true)}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2" className="p-0">
              <Row>
                <Col md={12}>
                  <p className="mb-2">
                    <b>{t("test_packs")}</b>
                  </p>
                </Col>
                <Col md={6} className="mb-2">
                  <Button color="link" className="mb-1" onClick={toggleModal}>
                    {t("edit")}
                  </Button>
                  {testPacks.length ? (
                    <Table
                      hover
                      bordered
                      striped
                      responsive
                      className="grid-table mb-2"
                    >
                      <thead>
                        <tr>
                          <th>{t("test_pack_name")}</th>
                          <th>{t("description")}</th>
                          <th className="text-center">{t("action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {testPacks.map((pack, index) => (
                          <tr key={index}>
                            <td>
                              <LinkEditBtn
                                noIcon
                                onClick={() =>
                                  props.history.push(
                                    `/edit-test-pack/${pack.id}`
                                  )
                                }
                              >
                                {pack.name}
                              </LinkEditBtn>
                            </td>
                            <td
                              className="p-mb-0"
                              dangerouslySetInnerHTML={{
                                __html: pack.description,
                              }}
                            />
                            <td className="text-center pl-3">
                              <DeleteBtn
                                className="d-inline mr-3"
                                onClick={() => onClickCheckbox(false, pack)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <Alert color="primary">{t("no_pack_in_suite")}</Alert>
                  )}
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3" className="p-0">
              <Row>
                <Col md={6} className="mb-2">
                  <Row className="mb-2">
                    <Col md={6}>
                      <p className="mb-2">
                        <b>{t("Options")}</b>
                      </p>
                      <CustomInput
                        id="Free"
                        label="Free test suite"
                        type="checkbox"
                        name="idFree"
                        checked={isFree}
                        onChange={() => {
                          setIsFree(!isFree)
                        }}
                      />
                    </Col>
                    {!!isFree && (<Col md={6}>
                      <p className="mb-2">
                        <b>Duration (days)</b>
                      </p>
                      <Input
                        type="number"
                        value={freeDurationInDays}
                        onChange={(e) => {
                          setFreeDurationInDays(e.target.value)
                        }}
                        placeholder="Duration"
                      />
                      {checkRequired && !!isFree && (!freeDurationInDays || parseInt(freeDurationInDays) <= 0) && (
                        <ErrorHandler text={t("duration_greater_zero")} />
                      )}
                    </Col>)}
                  </Row>
                  {!isFree && <div>
                    <p className="mb-2">
                      <b>{t("pricing_plan_list")}</b>
                    </p>
                    {pricingPlans.map((plan, index) => (
                      <div key={index} className="mb-2" style={pricingPlanStyle}>
                        <div className="d-flex align-items-center justify-content-between">
                          <b>{plan.name}</b>
                        </div>
                        <Row className="mt-2">
                          <Col md={6} className="mb-2">
                            <Label>{t("name")}</Label>
                            <Input
                              value={plan.name}
                              onChange={(e) =>
                                updatePricingPlans(plan.guid, {
                                  name: e.target.value,
                                })
                              }
                              placeholder={t("name")}
                            />
                          </Col>
                          <Col md={6} className="mb-2">
                            <Label>{t("description")}</Label>
                            <Input
                              value={plan.description}
                              onChange={(e) =>
                                updatePricingPlans(plan.guid, {
                                  description: e.target.value,
                                })
                              }
                              placeholder={t("description")}
                            />
                          </Col>
                          <Col md={6} className="mb-2">
                            <Label>{t("price")}</Label>
                            <Input
                              type="number"
                              step={0.1}
                              value={plan.price}
                              onChange={(e) =>
                                updatePricingPlans(plan.guid, {
                                  price: parseFloat(e.target.value),
                                })
                              }
                              placeholder={t("price")}
                            />
                          </Col>
                          {/* <Col md={6} className="mb-2">
                                            <Label>{t("renewal_price")}</Label>
                                            <Input
                                                type="number"
                                                value={plan.renewalPrice}
                                                onChange={e => updatePricingPlans(plan.guid, { renewalPrice: e.target.value })}
                                                placeholder={t("renewal_price")}
                                            />
                                        </Col> */}
                          <Col md={6} className="mb-2">
                            <Label>{t("expiry_period_in_days")}</Label>
                            <Input
                              type="number"
                              value={plan.expiryPeriodInDays}
                              onChange={(e) =>
                                updatePricingPlans(plan.guid, {
                                  expiryPeriodInDays: e.target.value,
                                })
                              }
                              placeholder={t("expiry_period_in_days")}
                            />
                          </Col>
                          {pricingPlans.length > 1 && (
                            <Col md={6} className="d-flex align-items-end mb-2">
                              <Button
                                color="danger"
                                onClick={() => onDeletePrincingPlan(plan.guid)}
                              >
                                <i className="fa fa-trash mr-2" />
                                {t("delete")}
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </div>
                    ))}
                    <Button color="link" onClick={onAddNewPricingPlan}>
                      {t("add_new_pricing_plan")}
                    </Button>
                  </div>
                  }
                </Col>
              </Row>
            </TabPane>
            {id && (
              <TabPane tabId="4" className="p-0">
                <Row>
                  <Col md={10} xl={12}>
                    <SubscriptionList testSuiteId={parseInt(id)} />
                  </Col>
                </Row>
              </TabPane>
            )}
          </TabContent>
        </Col>
      </Row>
      <Row className="py-2 px-3 floatingActions">
        <Col md={12}>
          <Button color="success" className="mr-2" onClick={handleSubmit}>
            {id ? t("update") : t("create")}
          </Button>
          <Button
            onClick={() => props.history.push("/test-management/test-suite")}
          >
            {t("cancel")}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  setModal,
  createSuite,
  updateSuite,
  getSuite,
  uploadFiles,
}

const TestSuiteDetailHOC = withTranslation("common")(TestSuiteDetail)

export default connect(mapStateToProps, mapDispatchToProps)(TestSuiteDetailHOC)
