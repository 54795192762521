import React, { useState, useEffect } from "react"
import Select from "react-select"
import { FormGroup, Label } from "reactstrap"

import { getAllTestPack } from "../../util/api"

const TestPackSelector = props => {
    const [options, setOptions] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        setIsLoading(true)
        try {
            const res = await getAllTestPack({})
            setOptions(res.data.data.map(item => ({ value: item.id, label: item.name })))
        } catch (err) {
            console.log(err.message)
        }
        setIsLoading(false)
    }

    return <FormGroup>
        {props.label && <Label>{props.label}</Label>}
        <Select
            placeholder="Select test pack"
            options={options}
            isLoading={isLoading}
            isMulti
            {...props}
        />
    </FormGroup>
}

export default TestPackSelector