import React from "react"
import { Card, CardBody, CardTitle, Form, FormGroup, FormFeedback, Label, Input, Button } from 'reactstrap'

export default ({
    t,
    oldPassword,
    newPassword,
    confirmNewPassword,
    onChangeOldPassword,
    onChangeNewPassword,
    onChangeConfirmNewPassword,
    onChangePassword
}) => {

    return <>
        <Card className="my-2">
            <CardTitle className="font-weight-bold mb-0 pt-2 px-2">{t("change_password")}</CardTitle>
            <CardBody className="p-2">
                <Form className="w-100">
                    <FormGroup>
                        <Label>{t("old_password")}</Label>
                        <Input
                            type="password"
                            placeholder={t("old_password")}
                            value={oldPassword.value}
                            onChange={e => onChangeOldPassword(e.target.value)}
                            invalid={oldPassword.invalid}
                        />
                        {oldPassword.invalid && <FormFeedback>{oldPassword.errorMessage}</FormFeedback>}
                    </FormGroup>
                    <FormGroup>
                        <Label>{t("new_password")}</Label>
                        <Input
                            type="password"
                            placeholder={t("new_password")}
                            value={newPassword.value}
                            onChange={e => onChangeNewPassword(e.target.value)}
                            invalid={newPassword.invalid}
                        />
                        {newPassword.invalid && <FormFeedback>{newPassword.errorMessage}</FormFeedback>}
                    </FormGroup>
                    <FormGroup className="mb-2">
                        <Label>{t("confirm_new_password")}</Label>
                        <Input
                            type="password"
                            placeholder={t("confirm_new_password")}
                            value={confirmNewPassword.value}
                            onChange={e => onChangeConfirmNewPassword(e.target.value)}
                            invalid={confirmNewPassword.invalid}
                        />
                        {confirmNewPassword.invalid && <FormFeedback>{confirmNewPassword.errorMessage}</FormFeedback>}
                    </FormGroup>
                    <Button block color="success" onClick={onChangePassword}>{t("change_password")}</Button>
                </Form>
            </CardBody>
        </Card>
    </>
}