/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import {
  Col,
  Row,
  Button,
  FormGroup,
  Label,
  Input,
  Pagination,
  PaginationLink,
  PaginationItem,
  CardBody
} from "reactstrap";
import "jspdf-autotable";
import moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next'

import setInputModal from "../../../components/ModalInput/action";

import setModal from "../../../components/Modal/action";
import setAlert from "../../../components/Alert/action";

import {
  getUserInformation, editUser, getUserReports, getAllUser, createUser,
  enrollCourseOrProgram, unEnrollCourseOrProgram, resetPassword,
  lockUser,
  setReportSearchString,
  setReportSearchStringAdmin
} from "../../Report/redux/actions";
import { uploadFile, toggleEmployTeacher, checkUserOnFortnox, resendMailToken, changePassword } from "../redux/action";

import AddressFrom from "../../../components/AddressForm";
import * as Helper from "./components/helper";

import history from "../../../util/history";
import CustomBtn from "../../../components/Button/CustomBtn";
import ViewModal from "../../../components/Modal/viewModal";

import { DOCUMENT_TITLE } from "../../../util/Constant";
import formatHelper, { formatToLocalTimeWithTime } from "../../../components/Selector/formatHelper";
import { fetchStudentOfCourse, getEditStatus, overwriteEditStatus, deleteEditStatus, randomEmail, completeImport } from "../../../util/api";
import QuitPrompt from "../../../components/QuitPrompt";
import initState from "./initState";
import Secret from "./secret";
import MoreInfo from "./moreInfo";
import Btns from "./btns";
import Basic from "./basic";
import Avatar from "./avatar";
import ChangePassword from "./ChangePassword"
import FortnoxInformation from "./fortnoxInformation";
import GradeSelector from '../../../components/Selector/GradeSelector'

import validation from "../../../util/validation"
import { showConfirmDialog } from "../../../components/ConfirmDialog/action";

const { password } = validation

class edit extends React.PureComponent {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { id },
      },
      location: {
        data
      },
      t
    } = props;
    if (!id) {
      document.title = props.t(DOCUMENT_TITLE.create_user)
    }
    this.onDidMount.bind(this)   
    this.state = { ...initState(t) };
    this.students = [];
    this.firstFamilyId = null;
    this.url = window.location.href
  }

  componentDidMount() {
    const importStudentId = _.get(this.props, 'history.location.state.importStudentId')
    this.setState({
      importStudentId: importStudentId || null
    }, this.onDidMount)
  }

  resetPasswordAlert = () => {
    const { email } = this.state;
    this.props.setModal({
      type: "primary",
      message: `${this.props.t('reset_password_confirm')} ${email.value}?`,
      cb: () => {
        this.props.resetPassword(email.value)
        this.setEditedTrue()
      },
    });
  };

  resendToken = () => {
    const {
      match: {
        params: { id },
      },
      t
    } = this.props;

    this.props.setModal({
      type: "primary",
      message: t("resend_token_confirm"),
      cb: () => this.props.resendMailToken(id)
    })
  }

  onChangePassword = () => {
    const { oldPassword, newPassword, confirmNewPassword } = this.state
    const { t } = this.props

    if (!oldPassword.value || oldPassword.value && !oldPassword.value.trim()) {
      this.setState({
        oldPassword: {
          ...oldPassword,
          invalid: true,
          errorMessage: `${t("old_password")} ${t("empty")}`
        }
      })

      return
    } else {
      this.setState({
        oldPassword: {
          ...oldPassword,
          invalid: false,
          errorMessage: ""
        }
      })
    }

    if (!newPassword.value || newPassword.value && !newPassword.value.trim()) {
      this.setState({
        newPassword: {
          ...newPassword,
          invalid: true,
          errorMessage: `${t("new_password")} ${t("empty")}`
        }
      })

      return
    } else {
      this.setState({
        newPassword: {
          ...newPassword,
          invalid: false,
          errorMessage: ""
        }
      })
    }

    if (!newPassword.value || !password(newPassword.value)) {
      this.setState({
        newPassword: {
          ...newPassword,
          invalid: true,
          errorMessage: t("password_must_have")
        }
      })

      return
    } else {
      this.setState({
        newPassword: {
          ...newPassword,
          invalid: false,
          errorMessage: ""
        }
      })
    }

    if (newPassword.value !== confirmNewPassword.value) {
      this.setState({
        confirmNewPassword: {
          ...confirmNewPassword,
          invalid: true,
          errorMessage: t("password_dont_match")
        }
      })

      return
    } else {
      this.setState({
        confirmNewPassword: {
          ...confirmNewPassword,
          invalid: false,
          errorMessage: ""
        }
      })
    }

    const data = { oldPassword: oldPassword.value, newPassword: newPassword.value }
    this.props.changePassword(data, () => this.setState({
      oldPassword: {
        ...oldPassword,
        value: ""
      },
      newPassword: {
        ...newPassword,
        value: ""
      },
      confirmNewPassword: {
        ...confirmNewPassword,
        value: ""
      }
    }))
  }



  onDidMount() {
    const {
      match: {
        params: { id },
      },
      t
    } = this.props;
    if (!id) return;

    // get edit status 
    getEditStatus('User', id)
      .then(res => {
        if (res.status === 204) return;

        const editStatus = res.data
        if (!editStatus.status) {
          this.props.setModal({
            type: 'warning',
            message: `${t('user_edited_by')} ${editStatus.userEditing} ${t('for')} ${editStatus.editTiming} ${t('minutes')}`,
            label1: t('overwrite'),
            type1: 'danger',
            cb1: () => {
              overwriteEditStatus('User', id)
            },
            label2: t('view_only'),
            cb2: () => {
              this.setState({ viewOnly: true })
            },
            label3: t('cancel'),
            dismiss: () => {
              history.push(this.getBackUrlFromProps())
            }
          })
        }
      })

    const { firstName,
      lastName,
      phoneNumber,
      roles,
      birthday,
      gender,
      email,
      additionalEmail,
      homePhone,
      note,
      emergencyFirstName,
      emergencyLastName,
      emergencyPhoneNumber,
      emergencyIs,
      salaryInvoice,
      mainSalary,
      specialSalary,
      ssn,
      skill,
      isActive,
      assistanceMainSalary,
      assistanceSalaryInvoice,
      assistanceSpecialSalary,
      courseTypes,
      workOfficePhone,
      receiveCourseInfo,
      hasSecret,
      grade
    } = this.state;
    this.props.getUserInformation(id, async userInformation => {
      document.title = _.get(userInformation, "identity.fullName", null) || this.props.t(DOCUMENT_TITLE.edit_user);

      // render salary if teacher
      if (userInformation.roles.includes("Teacher")) {
        this.setState({
          salaryInvoice: { ...salaryInvoice, value: userInformation.salaryInvoice || "" },
          mainSalary: { ...mainSalary, value: userInformation.mainSalary || "" },
          specialSalary: { ...specialSalary, value: userInformation.specialSalary || "" },
        });
      }
      if (userInformation.roles.includes("Assistance")) {
        this.setState({
          assistanceMainSalary: { ...assistanceMainSalary, value: userInformation.assistanceMainSalary || '' },
          assistanceSalaryInvoice: { ...assistanceSalaryInvoice, value: userInformation.assistanceSalaryInvoice || '' },
          assistanceSpecialSalary: { ...assistanceSpecialSalary, value: userInformation.assistanceSpecialSalary || '' },
        });
      }

      const family = formatHelper.singleFamilyFormat(userInformation.family);
      this.firstFamilyId = _.get(family, 'id');

      const { profile } = userInformation.identity;

      const field = f => userInformation.identity[f]

      this.setState({
        firstName: { ...firstName, value: field('firstName') || '' },
        lastName: { ...lastName, value: field('lastName') || '' },
        phoneNumber: { ...phoneNumber, value: field('phoneNumber') || '' },
        email: { ...email, value: field('email') || '' },
        additionalEmail: { ...additionalEmail, value: field('additionalEmail') || '' },
        homePhone: { ...homePhone, value: field('homePhone') || '' },
        emergencyFirstName: { ...emergencyFirstName, value: field('emergencyFirstName') || '' },
        note: { ...note, value: field('note') || '' },
        emergencyLastName: { ...emergencyLastName, value: field('emergencyLastName') || '' },
        emergencyPhoneNumber: { ...emergencyPhoneNumber, value: field('emergencyPhoneNumber') || '' },
        isActive: { ...isActive, value: field('isActive') },
        workOfficePhone: { ...workOfficePhone, value: field('workOfficePhone') || '' },
        ssn: { ...ssn, value: userInformation.ssn || "" },
        taxTable: field('taxTable') || 30,
        taxColumn: field('taxColumn') || 1,
        information: userInformation,
        avatar: this.avatarFolderOath(userInformation.identity.avatar),
        family,
        hadFamily: !!family,
        isLocked: userInformation.identity.isLocked,
        roles: { ...roles, value: userInformation.roles },
        skill: { ...skill, value: formatHelper.basicFormat(userInformation.teacherSkills) },
        emergencyIs: { ...emergencyIs, value: { value: userInformation.identity.emergencyIs } },
        employed: userInformation.employed,
        createdAt: userInformation.identity.createdAt,
        updatedAt: userInformation.identity.updatedAt,
        courseTypes: {
          ...courseTypes, value: formatHelper.basicFormat(userInformation.courseTypes)
        },
        hasSecret: userInformation.hasSecret,
        grade: { ...grade, value: userInformation.grade === 0 ? null : { value: userInformation.grade, label: userInformation.grade } }
      });


      // render sem, year if student
      if (userInformation.roles.includes("Student")) {
        this.setState({ receiveCourseInfo: userInformation.receiveCourseInfo }, () => { })
      }

      // render profile
      if (profile) {
        this.setState({
          birthday: { ...birthday, value: profile.birthday !== "0001-01-01T00:00:00" ? profile.birthday : "" },
          age: moment().diff(profile.birthday, 'years'),
          gender: { ...gender, value: profile.gender },
        });
        this.addressFrom.setValue("addressLine1", profile.addressLine1 || "");
        this.addressFrom.setValue("addressLine2", profile.addressLine2 || "");
        this.addressFrom.setValue("city", profile.city || "");
        this.addressFrom.setValue("zipcode", profile.zipcode || "");
        this.addressFrom.setValue("country", profile.country || "");
        const groupsLoaded = userInformation.groups.map(e =>  {
          return {
            label: e.name,
            value: e.id
          }
        })
        this.addressFrom.setValue("groups", groupsLoaded || []);
      }

    });
  }

  renderFortnox = () => {
    const { fortnoxCustomer, fortnoxEmployee } = this.state;
    return <FortnoxInformation fortnoxCustomer={fortnoxCustomer} fortnoxEmployee={fortnoxEmployee} t={this.props.t} />
  }

  deleteEdit = () => {
    const { id } = this.props.match.params
    if (!id) return

    deleteEditStatus('User', id)
  }

  avatarFolderOath = (img) => {
    if (img) {
      return `/api/file/downloadAvatar/${img}`;
    }
    return null;
  }



  reGetCoursesOfStudent = (year, sem) => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.props.getCoursesOfStudent(id, year, sem, userCourses => {
      this.setState({ userCourses })
    })
  }

  reGetProgramsOfStudent = (year, sem) => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.props.getProgramsOfStudent(id, year, sem, userPrograms => {
      this.setState({ userPrograms })
    })
  }

  reDiscount = () => {
    const { userPrograms, userCourses, payment } = this.state;
    if (payment && payment.id) return 0;
    return _.sumBy(userCourses, 'discount') + _.sumBy(userPrograms, 'programDiscount')
  }

  rePrice = () => {
    const { userPrograms, userCourses, payment } = this.state;
    if (payment && payment.id) return 0;
    return _.get(userPrograms[0], "price", 0) + _.get(userPrograms[1], "price", 0) + _.sumBy(userCourses, "course.price")
  }

  lockUser = () => {
    const {
      match: {
        params: { id },
      },
      t
    } = this.props;
    this.props.setModal({
      type: "warning",
      message: this.state.isLocked ? t('unlock_user_message') : t('lock_user_message'),
      cb: () => this.props.lockUser(id, () => this.onDidMount()),
    });
  }

  setEditedTrue = async () => {
    localStorage.setItem(this.url, 'true')
    await this.setState({
      edited: true
    })
  }

  setEditedFalse = async () => {
    localStorage.removeItem(this.url)
    await this.setState({
      edited: false
    })
  }

  getBackUrlFromProps = () => {
    const { roles } = this.state
    const _roles = _.get(roles, "value", [])
    if (_.get(this.props, 'history.location.state.isAdminStaff')) return '/user-family/users';
    if (_.get(this.props, 'history.location.state.isImported')) return '/imported-students';
    if (_roles.includes("Parent") || _roles.includes("Student") || _roles.includes("Assistance") || _roles.includes("Teacher")) return '/user-family/users';
    if (_roles.includes("Admin") || _roles.includes("Staff")) return '/user-family/users';
    return '/user-family/users'
  }

  onPressAbsenceBtn = () => {
    this.abModal.show(true)
    this.abModal.getUserReports(1)
  }

  handleChange = async () => {
    const {
      firstName,
      lastName,
      additionalEmail,
      homePhone,
      phoneNumber,
      roles,
      birthday,
      gender,
      skill,
      mainSalary,
      specialSalary,
      ssn,
      note,
      hadAvatarChange,
      salaryInvoice,
      isActive,
      email,
      emergencyIs,
      emergencyFirstName,
      emergencyLastName,
      emergencyPhoneNumber,
      family,
      employed,
      avatar,
      courseTypes,
      assistanceMainSalary,
      assistanceSalaryInvoice,
      assistanceSpecialSalary,
      workOfficePhone,
      taxTable,
      taxColumn,
      importStudentId,
      receiveCourseInfo,
      grade
    } = this.state;
    const {
      match: {
        params: { id },
      },
      t
    } = this.props;

    let nextStep = null;
    const arr = [firstName, lastName, roles, email, grade];
    arr.forEach(item => {
      if (item.value === null || item.value.length === 0) {
        this.setState({
          [item.field]: { ...item, value: "", invalid: true, errorMessage: `${item.display} ${t('empty')}` },
        });
      }
    });
    if (this.canCreate() && this.checkSsn()) {
      if (!roles.value.includes("Parent") && !roles.value.includes("Student") && family && Object.keys(family).length > 0 && _.get(family, 'value') !== 0) {
        this.props.setModal({
          type: "danger",
          message: t('must_be_parent_or_student'),
        });
        return null;
      }

      let avatarName = avatar;
      if (avatar && hadAvatarChange) {
        avatarName = await this.props.uploadFile(this.avatar.getAvatar());
      }

      this.setEditedFalse()
      const groupIds = this.addressFrom.singleValue("groups").map(e => e.value)
      const data = {
        email: email.value,
        additionalEmail: additionalEmail.value,
        homePhone: homePhone.value,
        familyId: (family && family.value) || 0,
        userName: email.value,
        firstName: firstName.value,
        lastName: lastName.value,
        phoneNumber: phoneNumber.value,
        workOfficePhone: workOfficePhone.value,
        birthday: birthday.value && moment(birthday.value).format("YYYY-MM-DD") || "",
        roles: roles.value,
        gender: gender.value,
        taxTable,
        taxColumn,
        ssn: ssn.value,
        addressLine1: this.addressFrom.singleValue("addressLine1"),
        addressLine2: this.addressFrom.singleValue("addressLine2"),
        city: this.addressFrom.singleValue("city"),
        country: this.addressFrom.singleValue("country"),
        zipCode: this.addressFrom.singleValue("zipcode"),
        isActive: isActive.value,
        emergencyIs: (emergencyIs.value && emergencyIs.value.value) || null,
        note: note.value,
        emergencyFirstName: emergencyFirstName.value,
        emergencyLastName: emergencyLastName.value,
        emergencyPhoneNumber: emergencyPhoneNumber.value,
        avatarPath: avatarName || null,
        courseTypeIds: (courseTypes.value && courseTypes.value.map(m => m.value)) || [],
        receiveCourseInfo: roles.value && roles.value.includes("Student") ? receiveCourseInfo : false,
        grade: grade.value && grade.value.value,
        groupIds
      };

      if (roles.value.includes("Teacher") || roles.value.includes("Assistance")) {
        if (taxTable !== 0 && (taxTable < 29 || taxTable > 40 || parseInt(taxTable) !== taxTable)) {
          this.props.setModal({
            type: "danger",
            message: t('tax_table_integer'),
          });
          return null;
        }
        if (taxColumn !== 0 && (taxColumn < 1 || taxColumn > 6 || parseInt(taxColumn) !== taxColumn)) {
          this.props.setModal({
            type: "danger",
            message: t('tax_column_integer'),
          });
          return null;
        }
      }

      if (roles.value.includes("Teacher")) {
        // if (mainSalary.value !== 0 && mainSalary.value !== parseInt(mainSalary.value)) {
        //   this.props.setModal({
        //     type: "danger",
        //     message: t('salary_integer'),
        //   });
        //   return null;
        // }
        data.mainSalary = mainSalary.value || 0;
        data.specialSalary = specialSalary.value || 0;
        data.salaryInvoice = salaryInvoice.value || false;
        data.teacherSkillIds = (skill.value && skill.value.map(m => m.value)) || []
        data.employed = employed
      }

      //   if (roles.value.includes("Assistance")) {
      //     if (assistanceMainSalary.value !== 0 && assistanceMainSalary.value !== parseInt(assistanceMainSalary.value)) {
      //       this.props.setModal({
      //         type: "danger",
      //         message: t('salary_integer'),
      //       });
      //       return null;
      //     }
      // data.assistanceMainSalary = assistanceMainSalary.value || 0;
      // data.assistanceSpecialSalary = assistanceSpecialSalary.value || 0;
      // data.assistanceSalaryInvoice = assistanceSalaryInvoice.value || false;
      //   }

      if (id) {
        data["id"] = id;
        nextStep = this.props.editUser;
      } else {
        data["employed"] = employed;
        nextStep = this.props.createUser;
      }
      nextStep(data, result => {
        if (Array.isArray(result)) {
          const message = result.map(e => e.value);
          this.props.setModal({
            type: "danger",
            message,
          });
          return;
        }

        if (importStudentId) {
          completeImport(importStudentId, id)
        }

        this.props.setAlert({
          type: "success",
          message: id ? t('user_updated_success') : t('user_created_success'),
          cb: null,
        });

        const backToUserList = this.getBackUrlFromProps() === '/imported-students' ||
          this.getBackUrlFromProps() === '/user-family/users'


        if (backToUserList) {
          this.props.setReportSearchString(email.value)
          history.push('/user-family/users')
        } else {
          this.props.setReportSearchStringAdmin(email.value)
          history.push(this.getBackUrlFromProps())
        }

      });
    }
    return null;
  };

  canCreate = () => {
    const { firstName, lastName, roles, email, phoneNumber, workOfficePhone, homePhone, additionalEmail, grade } = this.state;
    let arr = roles.value.includes("Student") ? [firstName, lastName, roles, email, grade] : [firstName, lastName, roles, email];
    return !arr.some(e => e.invalid) &&
      !arr.some(e => !e.value) &&
      !arr.some(e => e.value.length === 0) &&
      !additionalEmail.invalid &&
      !phoneNumber.invalid &&
      !workOfficePhone.invalid &&
      !homePhone.invalid;
  };

  check = (e, val, acceptEmpty = false) => {
    const t = this.state[e];
    if ((typeof t.value === 'string' && t.value.trim()) || (typeof t.value === 'object' && t.value.length > 0)) {
      if (val) {
        this.setState({
          [t.field]: { ...t, invalid: !val(t.value), errorMessage: `${t.display} ${this.props.t('invalid')}` },
        });
      } else {
        this.setState({
          [t.field]: { ...t, invalid: false },
        });
      }
    } else if (!acceptEmpty) {
      this.setState({
        [t.field]: { ...t, invalid: true, errorMessage: `${t.display} ${this.props.t('empty')}` },
      });
    }
  };

  checkValidateOnly = (e, val) => {
    const t = this.state[e];
    if (typeof t.value === 'string' && t.value.trim()) {
      this.setState({
        [t.field]: { ...t, invalid: !val(t.value), errorMessage: `${t.display} ${this.props.t('invalid')}` },
      });
    } else {
      this.setState({
        [t.field]: { ...t, invalid: false, errorMessage: "" },
      });
    }
  };

  checkSsn = () => {
    const { ssn } = this.state
    const { t } = this.props
    if (ssn.value && ssn.value.trim() && (ssn.value.length !== 4 || parseInt(ssn.value) < 0)) {
      this.setState({
        ssn: {
          ...ssn,
          invalid: true,
          errorMessage: t("ssn_invalid")
        }
      })
      return false
    }

    this.setState({
      ssn: {
        ...ssn,
        invalid: false,
        errorMessage: ""
      }
    })
    return true
  }

  select = async e => {
    if (e === 'Teacher') {
      await this.setState({
        employed: true
      })
    }
    const { roles } = this.state;
    let value = [...roles.value];
    const t = value.indexOf(e);
    if (t === -1) {
        this.setState({ roles: { ...roles, value: [...roles.value, e], invalid: false } });
    } else {
      value.splice(t, 1);
      if (value.includes('Admin Salary') && !value.includes('Admin') && !value.includes('Staff')) {
        value = value.filter(role => role !== 'Admin Salary')
      }
      this.setState({ roles: { ...roles, value } }, () => {
        if (this.state.roles && this.state.roles.value.includes('Teacher')) {
          this.setState({
            employed: true
          })
        }
      });
    }

  };

  onChangeReceiveCourseInfo = e => this.setState({
    receiveCourseInfo: e.target.checked
  })


  componentWillUnmount() {
    this.setEditedFalse()
    this.deleteEdit()
  }

  getAll = page => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.props.getAllByUser(page || this.props.currentPage, id);
  };

  removeFamily = () => {
    this.setState({
      family: null,
    })
  }
  // courseHistoryModal = () => {
  //   return (
  //     <ViewModal ref={(ref) => { this.chModal = ref }} title="COURSE HISTORY">

  //     </ViewModal>
  //   )
  // }

  afterUpdateSecret = str => {
    this.setState({ hasSecret: str && str.trim() })
  }

  secretInfoModal = () => {
    const { viewOnly } = this.state;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    return <Secret ref={ref => { this.siModal = ref }} viewOnly={viewOnly} id={id} t={this.props.t} afterUpdateSecret={this.afterUpdateSecret} />
  }


  renderDate = (date = null) => {
    if (!date || date === '0001-01-01T00:00:00') return null;

    return formatToLocalTimeWithTime(date).format('MMM DD, YYYY - HH:mm');
  };

  switchUser = async (type) => {
    if (!this.students || this.students && this.students.length === 0) {
      return
    }

    const {
      match: {
        params: { id },
      },
    } = this.props;
    await Helper.switchUser(type, this.students, id);
    if (this.courseSelect) this.courseSelect.loadData();
    this.onDidMount()
  }

  showPaymentModal = (s) => {
    this.pmModal.show(s)
  }

  randomEmail = async () => {
    const { email } = this.state;
    await this.check("firstName")
    await this.check("lastName")
    if (this.state.firstName.invalid || this.state.lastName.invalid) {
      this.props.setModal({
        type: "danger",
        message: this.props.t('please_fill_in_name')
      });
      return 0;
    }
    const searchRegExp = / +/g;
    const replaceWith = "_"
    const name = `${_.get(this.state.firstName, 'value')}_${_.get(this.state.lastName, 'value')}`.trim().replace(searchRegExp, replaceWith);
    const rs = await randomEmail(name);
    if (rs.status === 200) {
      this.setState({ email: { ...email, value: rs.data, invalid: false, errorMessage: "" } });
    } else {
      this.props.setModal({
        type: "danger",
        message: this.props.t('system_err_create_email'),
      });
    }
  }

  onChangeState = _state => value => this.setState({ [_state]: { ...this.state[_state], value } }, this.setEditedTrue)

  onChangeStateField = _state => value => this.setState({ [_state]: value }, this.setEditedTrue);

  render() {
    const {
      firstName,
      lastName,
      phoneNumber,
      additionalEmail,
      homePhone,
      roles,
      birthday,
      gender,
      isActive,
      edited,
      ssn,
      family,
      email,
      avatar,
      workOfficePhone,
      viewOnly,
      receiveCourseInfo,
      oldPassword,
      newPassword,
      confirmNewPassword,
      grade
    } = this.state;
    const {
      match: {
        params: { id },
      },
      t,
      userInfo,
    } = this.props;
    const isStudent = roles.value && roles.value.includes("Student")
    const userRoles = userInfo ? userInfo.roles : []

    return (
      <div className="animated fadeIn">

        <div>
          <QuitPrompt
            when={edited}
          />

          <Col md={12} className="px-2" style={{ paddingBottom: '60px' }}>
            <div className="card-body px-3">
              <strong className="table-header-label">{id ? `${t("EDIT_USER")} - ${t("user_id")}: ${id}` : t("CREATE_USER")}</strong>
              {/* <div className="float-right w-75">
                <Row className="d-flex justify-content-end align-items-center">
                  {id && roles.value && roles.value.includes('Student') && <Label>{t('go_to_other_student')}</Label>}
                  {id && roles.value && roles.value.includes('Student') && <Col md={4} sm={8}>
                    <CourseSelected
                      ref={(ref) => { this.courseSelect = ref }}
                      className="family-inline-group"
                      noLabel
                      flags="programCourse"
                      noSearch
                      studentId={id}
                      onChange={async e => {
                        const { data } = await fetchStudentOfCourse(e.value)
                        this.students = data
                      }}
                    />
                  </Col>}


                  {id && roles.value && roles.value.includes('Student') && <Pagination className="grid-pagination mb-1" style={{ marginRight: 10 }}>
                    <PaginationItem>
                      <PaginationLink
                        onClick={() => this.switchUser("prev")}
                        previous
                        tag="button"
                        disabled={!userCourses || !userPrograms || userCourses.length + userPrograms.length === 0}
                      >
                        {"<"}
                      </PaginationLink>
                    </PaginationItem>

                    <PaginationItem>
                      <PaginationLink
                        onClick={() => this.switchUser("next")}
                        disabled={!userCourses || !userPrograms || userCourses.length + userPrograms.length === 0}
                        next tag="button">
                        {">"}
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>}


                  <CustomBtn
                    title={t('back_to_list')}
                    icon="fa fa-list"
                    onClick={() => {
                      history.push(this.getBackUrlFromProps())
                    }}
                    className="mb-1"
                  />
                </Row>
              </div> */}
              <div className="clearfix"></div>
            </div>
            <CardBody className="grid-body py-0">
              <Row form>
                {/* base info */}
                <Col md={3} xs={6} dark="true">

                  <Basic
                    id={id}
                    randomEmail={this.randomEmail}
                    check={this.check}
                    checkValidateOnly={this.checkValidateOnly}
                    firstName={firstName}
                    lastName={lastName}
                    email={email}
                    phoneNumber={phoneNumber}
                    homePhone={homePhone}
                    workOfficePhone={workOfficePhone}
                    birthday={birthday}
                    additionalEmail={additionalEmail}
                    ssn={ssn}
                    onChangeSSN={this.onChangeState('ssn')}
                    onChangeFirstName={this.onChangeState('firstName')}
                    onChangeLastName={this.onChangeState('lastName')}
                    onChangeEmail={this.onChangeState('email')}
                    onChangePN={this.onChangeState('phoneNumber')}
                    onChangeHP={this.onChangeState('homePhone')}
                    onChangeWOP={this.onChangeState('workOfficePhone')}
                    onChangeBirthday={this.onChangeState('birthday')}
                    onChangeAE={this.onChangeState('additionalEmail')}
                    checkSsn={this.checkSsn}
                    t={this.props.t}
                  />

                  {isStudent && <GradeSelector
                    require
                    value={grade.value}
                    onChange={e => this.setState({
                      grade: {
                        ...grade,
                        invalid: e ? false : true,
                        errorMessage: e ? "" : t("grade_empty"),
                        value: e || ""
                      }
                    })}
                    invalid={grade.invalid}
                    errorMessage={grade.errorMessage}
                  />}

                  <Avatar
                    ref={ref => { this.avatar = ref }}
                    avatar={avatar}
                    onChangeAvatar={this.onChangeStateField('avatar')}
                    onChangeHadAvatar={this.onChangeStateField('hadAvatarChange')}
                    viewOnly={viewOnly}
                    setModal={this.props.setModal}
                    t={this.props.t}
                  />
                </Col>


                <Col md={3} xs={6} dark="true">
                  <AddressFrom
                    ref={e => { this.addressFrom = e }}
                    haveEdit={this.setEditedTrue}
                    viewOnly={viewOnly}
                    t={this.props.t}
                  />
                  {/* more info */}
                  <MoreInfo
                    id={id}
                    select={this.select}
                    roles={roles}
                    userRoles={userRoles}
                    gender={gender}
                    onChangeGender={this.onChangeState('gender')}
                    isActive={isActive}
                    onChangeActive={this.onChangeState('isActive')}
                    receiveCourseInfo={receiveCourseInfo}
                    onChangeReceiveCourseInfo={this.onChangeReceiveCourseInfo}
                    family={family}
                    t={this.props.t}
                  />

                </Col>
                <Col md={3} xs={6} dark="true">

                  {id === this.props.userInfo.identity.id &&
                    <ChangePassword
                      t={t}
                      oldPassword={oldPassword}
                      newPassword={newPassword}
                      confirmNewPassword={confirmNewPassword}
                      onChangeOldPassword={this.onChangeState("oldPassword")}
                      onChangeNewPassword={this.onChangeState("newPassword")}
                      onChangeConfirmNewPassword={this.onChangeState("confirmNewPassword")}
                      onChangePassword={this.onChangePassword}
                    />
                  }
                </Col>
              </Row>

              <Row>
                <Col md={8}>
                  {this.renderDate(this.state.updatedAt) ? `${t('User_last_changed_on')} ${this.renderDate(this.state.updatedAt)} ` : ''}
                  {this.renderDate(this.state.updatedAt) && this.renderDate(this.state.createdAt) && '| '}
                  {this.renderDate(this.state.createdAt) ? `${t('User_created_on')} ${this.renderDate(this.state.createdAt)}` : ''}
                </Col>
              </Row>

              <Row
                form
                className="py-2 floatingActions"
              >
                <Col md={12}>
                  <Button onClick={this.handleChange} className="px-4 pl-1 mr-2" color="primary" disabled={viewOnly}>
                    {id ? t('update') : t('create')}
                  </Button>
                  <Button
                    onClick={() => {
                      history.push(this.getBackUrlFromProps())
                    }}
                    className="px-4 mr-2 ml-2"
                  >
                    {t('cancel')}
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Col>
        </div>
        {/* {this.absenceModal()} */}
        {/* {this.courseHistoryModal()} */}
        {/* {this.secretInfoModal()} */}
        {/* {this.buyHistoryModal()} */}
        {/* {this.paymentModal()} */}
        {/* {this.renderFortnoxModal()} */}
        {/* {this.renderTooltipCourses()} */}
        {/* {this.renderTooltipCoursesProgram()} */}
      </div>
    );
  }
}

const withMultiLanguage = withTranslation('common')(edit)

const mapStateToProps = state => ({
  userInfo: state.common.userInfo
});

const mapDispatchToProps =
{
  getUserReports,
  getUserInformation,
  editUser,
  createUser,
  setInputModal,
  setModal,
  setAlert,
  unEnrollCourseOrProgram,
  enrollCourseOrProgram,
  resetPassword,
  getAllUser,
  toggleEmployTeacher,
  lockUser,
  uploadFile,
  setReportSearchString,
  setReportSearchStringAdmin,
  checkUserOnFortnox,
  resendMailToken,
  changePassword
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withMultiLanguage);
