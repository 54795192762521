import React from "react";
import "./Skeleton.scss";

const Skeleton = ({ height = 20 }) => {
    return (
        <div
            style={{
                height
            }}
            className="skeleton-loading"
        />
    );
};

export default Skeleton;
