import { connect } from "react-redux";

import View from "./view/userList";
import { 
  getAllUser, 
  resetPassword, changePageSizeUser, changePageSizeImported, changePageSizeAdminStaff, writeUserState, removeUserState } from "../Report/redux/actions";
import { toggleEmployTeacher } from "./redux/action";
import setAlert from "../../components/Alert/action";
import setModal from "../../components/Modal/action";
// import { getAllUser } from "../../util/api";

const mapStateToProps = state => ({
  users: state.report.users,
  currentPage: state.report.currentPage,
  importedCurrentPage: state.report.importedCurrentPage,
  adminStaffCurrentPage: state.report.adminStaffCurrentPage,
  searchString: state.report.searchString,
  searchStringAdmin: state.report.searchStringAdmin,
  pageCount: state.report.pageCount,
  totalItems: state.report.totalItems,
  pageSize: state.report.userPageSize,
  importedPageSize: state.report.importedPageSize,
  adminStaffPageSize: state.report.adminStaffPageSize,
  tableZone: state.common.tableZone
});

const mapDispatchToProps =
{
  writeUserState,
  removeUserState,
  getAllUser,
  setAlert,
  setModal,
  resetPassword,
  toggleEmployTeacher,
  changePageSizeUser,
  changePageSizeImported,
  changePageSizeAdminStaff
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View);
