import { useDispatch } from "react-redux";
import { showConfirmDialog } from "../components/ConfirmDialog/action";

const useConfirm = () => {
    const dispatch = useDispatch();

    const showConfirm = ({ onConfirm, onReject, message }) => {
        dispatch(
            showConfirmDialog({
                onConfirm,
                onReject,
                message
            })
        );
    };

    return { showConfirm };
};

export default useConfirm;
