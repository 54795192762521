import {
  api,
  REPORT_ENDPOINT,
  TEACHER_ENDPOINT,
  RESET_PASSWORD_TEACHER_ENDPOINT,
  REMOVE_ATTENDANCE_ENDPOINT,
  USER_INFORMATION,
  CREATE_USER,
  COURSE_ENDPOINT,
  OLD_USERS,
  REMOVE_OLD_USERS,
} from "../../../util/api";
import setLoading from "../../../components/Spinner/action";
import setModal from "../../../components/Modal/action";
import setAlert from "../../../components/Alert/action";

import { getAccessToken } from "../../../util/Common";
import FormatHelper from "../../../components/Selector/formatHelper";
import encodeParams from "../../../util/encodeParams";
import state from '../../../store'

export const setReportSearchString = (searchString) => ({
  type: 'SET_REPORT_SEARCH_STRING',
  payload: { searchString }
})

export const setReportSearchStringAdmin = (searchStringAdmin) => ({
  type: 'SET_REPORT_SEARCH_STRING_ADMIN',
  payload: { searchStringAdmin }
})

export const getAllCourse = cb => async dispatch => {
  try {
    api.defaults.headers.common["Authorization"] = `Bearer ${getAccessToken()}`;
    const response = await api.get(`${COURSE_ENDPOINT}/?currentPage=-1`);
    dispatch({
      type: "SET_COURSE_LIST",
      payload: response.data.data,
    });
    if (cb) {
      setTimeout(() => {
        cb();
      }, 100);
    }
  } catch (error) { }
};

export const deleteAttendance = (id, isTeacher, callBack) => async dispatch => {
  dispatch(setLoading(true));
  try {
    const x = isTeacher ? "true" : "false";
    await api.post(`${REMOVE_ATTENDANCE_ENDPOINT}?id=${id}&isTeacher=${x}`);
    if (callBack)
      dispatch(
        setModal({
          type: "success",
          message: `delete_attendance_success`,
          cb: null,
        }),
      );
    callBack();
  } catch (error) { }
  dispatch(setLoading(false));
};

export const getStudentAbsenceReports = (courseId = null) => async dispatch => {
  if (!courseId) return;

  dispatch(setLoading(true));

  try {
    const response = await api.get(`${COURSE_ENDPOINT}/overview/?courseId=${courseId}`);
    if (response.data) {
      dispatch({
        type: "SET_REPORT_STUDENT",
        payload: {
          courseId,
          ...response.data
        },
      });
    }
  } catch (error) { }
  dispatch(setLoading(false));
};

export const resetReportStudent = () => async dispatch => {
  dispatch({
    type: "SET_REPORT_STUDENT",
    payload: [],
  });
}

export const updateStudentAbsenceReports = (reports) => async (dispatch, getState) => {
  dispatch(setLoading(true))
  try {
    const { data } = await api.post('attendance/student/update', reports)
    if (data) {
      dispatch(setAlert({
        type: "success",
        message: 'update_report_success',
      }))
    } else {
      setAlert({
        type: "danger",
        message: `update_report_failed`,
      })
    }
    const { courseId } = getState().report.stReports;
    await dispatch(getStudentAbsenceReports(courseId))
    dispatch(setLoading(false))
    return data
  } catch (error) {
    setAlert({
      type: "danger",
      message: `update_report_failed`,
    })
    dispatch(setLoading(false))
    return false
  }
}

export const getReports = (
  currentPage = 1,
  searchString = "",
  startDate = "",
  endDate = "",
  courseIds = null,
  teacherIds = null,
  studioIds = null,
  showType = "missing",
  sortBy = "FirstName",
  sortOrder = "ASC",
  cb = null,
  pageSize = 10
) => async dispatch => {
  dispatch(setLoading(true));
  let actionType = null;
  try {
    switch (showType) {
      case 'teacher':
        actionType = 'teacher';
        break;
      case 'missing':
        actionType = 'missing';
        break;
      case 'submitted':
        actionType = 'submitted';
        break;
      default:
        break;
    }

    let url = `${REPORT_ENDPOINT}/${actionType}/?pageSize=${pageSize}&searchString=${encodeURIComponent(searchString.replace(/\[/g, "[[]"))}&sortBy=${sortBy}&sortOrder=${sortOrder}&startDate=${startDate}&endDate=${endDate}`;
    if (courseIds != null && Array.isArray(courseIds) && courseIds.length > 0) {
      courseIds.forEach(i => {
        url += `&courseIds=${i}`;
      });
    }

    if (teacherIds != null && Array.isArray(teacherIds) && teacherIds.length > 0) {
      teacherIds.forEach(i => {
        url += `&teacherIds=${i}`;
      });
    }

    if (studioIds != null && Array.isArray(studioIds) && studioIds.length > 0) {
      studioIds.forEach(i => {
        url += `&studioIds=${i}`;
      });
    }

    url += `&currentPage=${currentPage !== -1 ? currentPage - 1 : -1}`
    const response = await api.get(url);
    if (response.data && response.data.data || response.data.data === null) {
      if (currentPage !== -1) {
        switch (showType) {
          case 'missing':
            dispatch({
              type: "SET_REPORT_MISSING",
              payload: Array.isArray(response.data.data) ? response.data.data : [],
              msCurrentPage: currentPage,
              msTotalItems: response.data.totalItems,
              msPageCount: response.data.pageCount,
            });
            break;
          case 'teacher':
            dispatch({
              type: "SET_REPORT_TEACHER",
              payload: Array.isArray(response.data.data) ? response.data.data : [],
              tcCurrentPage: currentPage,
              tcTotalItems: response.data.totalItems,
              tcPageCount: response.data.pageCount,
            });
            break;
          case 'submitted':
            dispatch({
              type: "SET_REPORT_SUBMIT",
              payload: Array.isArray(response.data.data) ? response.data.data : [],
              subCurrentPage: currentPage,
              subTotalItems: response.data.totalItems,
              subPageCount: response.data.pageCount,
            });
            break;
          default:
            break;
        }
      } else if (cb) {
        cb(response.data.data);
      }
    }
  } catch (error) { }
  dispatch(setLoading(false));
};

export const getUserReports = ({
  currentPage = 1,
  searchString = "",
  startDate = "",
  endDate = "",
  sortBy = "Date",
  sortOrder = "ASC",
  userId = 0,
  cb = null,
  pageSize = 10
}) => async dispatch => {
  dispatch(setLoading(true));
  try {
    const url = `${REPORT_ENDPOINT}/?currentPage=${currentPage -
      1}&pageSize=${pageSize}&searchString=${encodeURIComponent(searchString.replace(/\[/g, "[[]"))}&sortBy=${sortBy}&sortOrder=${sortOrder}&startDate=${startDate}&endDate=${endDate}&showType=All&userId=${userId}`;
    const response = await api.get(url);
    if (response.data && response.data.data) {
      dispatch({
        type: "SET_REPORT_USER",
        payload: Array.isArray(response.data.data) ? response.data.data : [],
        urCurrentPage: currentPage,
        urTotalItems: response.data.totalItems,
        urPageCount: response.data.pageCount,
      });
      if (cb) {
        cb()
      }
    }
  } catch (error) { }
  dispatch(setLoading(false));
};

export const getAllUser = ({
  currentPage = 1,
  searchString = "",
  roleFilters = "all",
  sortBy = "FirstName",
  sortOrder = "ASC",
  pageSize = 10,
  cb = null,
  programIds = null,
  courseIds = null,
  isImported,
  isLocked = 0,
  isActive = true,
  skillIds = [],
  isAdminStaff = false,
  paymentStatus = 0
}) => async (dispatch, getState) => {
  const searchKey = isImported || roleFilters &&
    roleFilters.length === 3 &&
    roleFilters.includes('Admin') &&
    roleFilters.includes('Developer') &&
    roleFilters.includes('Staff') ? searchString : getState().report.searchString

  const params = encodeParams({
    currentPage: currentPage - 1,
    pageSize,
    searchString: searchKey,
    sortBy,
    sortOrder,
    programIds,
    courseIds,
    isImported,
    isLocked,
    isActive,
    roleFilters: roleFilters && roleFilters.includes("all") ? null : roleFilters,
    skillIds,
    paymentStatus
  })
  let url = "";

  // if (isAdminStaff) {
  //   url = `${TEACHER_ENDPOINT}/adminnstaff/?${params}`
  // } else {
  //   url = `${TEACHER_ENDPOINT}/?${params}`
  // }
  url = '/user/getAllUser'

  dispatch(setLoading(true));
  try {
    const response = await api.get(url);
    let dataOption = null

    if (isImported) {
      dataOption = {
        type: "SET_USER_LIST",
        payload: response.data.data,
        pageCount: response.data.pageCount,
        totalItems: response.data.totalItems,
        importedCurrentPage: response.data.currentPage,
        importedPageSize: response.data.pageSize,
        searchString,
        sortBy,
        sortOrder,
        roleFilters,
      }
    } else if (roleFilters &&
      roleFilters.length === 3 &&
      roleFilters.includes('Admin') &&
      roleFilters.includes('Developer') &&
      roleFilters.includes('Staff')) {
      dataOption = {
        type: "SET_USER_LIST",
        payload: response.data.data,
        pageCount: response.data.pageCount,
        totalItems: response.data.totalItems,
        adminStaffCurrentPage: response.data.currentPage,
        adminStaffPageSize: response.data.pageSize,
        searchString,
        sortBy,
        sortOrder,
        roleFilters,
      }
    } else {
      dataOption = {
        type: "SET_USER_LIST",
        payload: response.data.data,
        pageCount: response.data.pageCount,
        totalItems: response.data.totalItems,
        currentPage,
        searchString,
        sortBy,
        sortOrder,
        roleFilters,
      }
    }

    dispatch(dataOption);
    if (cb) {
      cb(FormatHelper.userListPageFormat(response.data.data))
    }
  } catch (error) { }
  dispatch(setLoading(false));
};

export const getAllUserByRole = role => async dispatch => {
  try {
    const response = await api.get(`${TEACHER_ENDPOINT}/userByRole?role=${role}`);
    if (role === "Student") {
      dispatch({
        type: "SET_STUDENT_LIST",
        payload: response.data,
      });
    } else if (role === "Parent") {
      dispatch({
        type: "SET_PARENT_LIST",
        payload: response.data,
      });
    } else if (role === "Teacher") {
      dispatch({
        type: "SET_TEACHER_LIST",
        payload: response.data,
      });
    } else if (role === "Assistance") {
      dispatch({
        type: "SET_ASSISTANCE_LIST",
        payload: response.data,
      });
    }
  } catch (error) { }
};


export const resetPassword = Email => async dispatch => {
  dispatch(setLoading(true));
  try {
    const response = await api.post(RESET_PASSWORD_TEACHER_ENDPOINT, {
      Email,
    });
    if (response.data) {
      dispatch(
        setModal({
          type: "success",
          message: `New password of ${Email} is "${response.data}"`,
          cb: null,
        }),
      );
    }
  } catch (error) { }
  dispatch(setLoading(false));
};

export const getUserInformation = (id, cb) => async dispatch => {
  dispatch(setLoading(true));
  try {
    const response = await api.get(USER_INFORMATION(id));
    if (response.data) {
      dispatch({
        type: "SET_USER_INFORMATION",
        payload: response.data,
      });
      if (cb) cb(response.data);
    }
  } catch (error) { }
  dispatch(setLoading(false));
};

export const editUser = (data, cb) => async dispatch => {
  dispatch(setLoading(true));
  try {
    const userId = state.getState().common.userInfo.identity.id || 0;
    const response = await api.put(`${CREATE_USER}?editerId=${userId}`, data);
    if (response.status >= 200 && response.status < 300) {
      if (Array.isArray(response.data)) {
        const message = response.data.map(e => e.value);
        dispatch(
          setModal({
            type: "danger",
            message,
          })
        )
        dispatch(setLoading(false));
        return;
      }
      if (cb) cb(response);
    }
  } catch (error) { }
  dispatch(setLoading(false));
};

export const createUser = (data, cb) => async dispatch => {
  dispatch(setLoading(true));
  try {
    const response = await api.post(CREATE_USER, data);
    if (response.data) {
      if (cb) cb(response.data);
    }
  } catch (error) { }
  dispatch(setLoading(false));
};

export const lockUser = (id, cb) => async dispatch => {
  dispatch(setLoading(true));
  try {
    const response = await api.get(`${CREATE_USER}/lockUser/?id=${id}`);
    if (response.data) {
      dispatch(setAlert({
        type: "success",
        message: `update_user_success`,
        cb: null,
      }))
      if (cb) cb();
    }
  } catch (error) { }
  dispatch(setLoading(false));
};

export const enrollCourseOrProgram = (data, cb) => async dispatch => {
  dispatch(setLoading(true));
  try {
    const response = await api.post(`${COURSE_ENDPOINT}/enrollcourseorprogram`, data);
    if (response.data) {
      if (Array.isArray(response.data)) {
        const message = response.data.map(e => e.value);
        dispatch(
          setModal({
            type: "danger",
            message,
            cb: null,
          }),
        );
        dispatch(setLoading(false));
        return;
      }
      dispatch(setAlert({
        type: "success",
        message: `enroll_success`,
        cb: null,
      }))
      if (cb) cb(response.data);
    } else {
      dispatch(setAlert({
        type: "danger",
        message: `enroll_failed`,
        cb: null,
      }))
    }
  } catch (error) { }
  dispatch(setLoading(false));
};

export const unEnrollCourseOrProgram = (data, cb) => async dispatch => {
  dispatch(setLoading(true));
  try {
    const response = await api.post(`${COURSE_ENDPOINT}/unenrollcourseorprogram`, data);
    if (response.data) {
      dispatch(setAlert({
        type: "success",
        message: `unenroll_success`,
        cb: null,
      }))
      if (cb) cb(response.data);
    } else {
      dispatch(setAlert({
        type: "danger",
        message: `unenroll_failed`,
        cb: null,
      }))
    }
  } catch (error) { }
  dispatch(setLoading(false));
};

export const changePageSizeClassMissing = pageSize => async dispatch => {
  dispatch({
    type: "CHANGE_PAGE_SIZE_CLASS_MISSING",
    payload: pageSize
  })
}

export const changePageSizeTeacherAbsence = pageSize => async dispatch => {
  dispatch({
    type: "CHANGE_PAGE_SIZE_TEACHER_ABSENCE",
    payload: pageSize
  })
}

export const changePageSizeClassSubmitted = pageSize => async dispatch => {
  dispatch({
    type: "CHANGE_PAGE_SIZE_CLASS_SUBMITTED",
    payload: pageSize
  })
}

export const changePageSizeStudentByList = pageSize => async dispatch => {
  dispatch({
    type: "CHANGE_PAGE_SIZE_STUDENT_BY_LIST",
    payload: pageSize
  })
}

export const changePageSizeUser = pageSize => async dispatch => {
  dispatch({
    type: "CHANGE_PAGE_SIZE_USER",
    payload: pageSize
  })
}

export const changePageSizeImported = pageSize => async dispatch => {
  dispatch({
    type: "CHANGE_PAGE_SIZE_IMPORTED",
    payload: pageSize
  })
}

export const changePageSizeAdminStaff = pageSize => async dispatch => {
  dispatch({
    type: "CHANGE_PAGE_SIZE_ADMIN_STAFF",
    payload: pageSize
  })
}


export const getOldUsers = ({
  currentPage = 1,
  searchString = "",
  roleFilters = "all",
  sortBy = "FirstName",
  sortOrder = "ASC",
  pageSize = 10,
  cb = null,
  programIds = null,
  courseIds = null,
}) => async dispatch => {

  const params = encodeParams({
    currentPage: currentPage - 1,
    pageSize,
    searchString,
    sortBy,
    sortOrder,
    programIds,
    courseIds,
    roleFilters: roleFilters && roleFilters.includes("all") ? null : roleFilters,
  })
  const url = `${OLD_USERS}/?${params}`

  dispatch(setLoading(true));
  try {
    const response = await api.get(url);
    dispatch({
      type: "GET_OLD_USERS",
      payload: response.data.data,
      ouCurrentPage: response.data.currentPage,
      ouTotalItems: response.data.totalItems,
      ouPageCount: response.data.pageCount,
      searchString,
      sortBy,
      sortOrder
    });
    if (cb) {
      cb(FormatHelper.userListPageFormat(response.data.data))
    }
  } catch (error) { }
  dispatch(setLoading(false));
};

export const removeOldUsers = (idArray, cb) => async dispatch => {

  dispatch(setLoading(true));
  try {
    const response = await api.post(REMOVE_OLD_USERS, { userIds: idArray });
    if (response.data) {
      dispatch(setAlert({
        type: "success",
        message: `delete_old_user_success`,
        cb: null,
      }))
      if (cb) cb(response.data);
    } else {
      dispatch(setAlert({
        type: "danger",
        message: `delete_old_user_failed`,
        cb: null,
      }))
    }
  } catch (error) { }
  dispatch(setLoading(false));
};

export const changePageSizeOldUsers = pageSize => async dispatch => {
  dispatch({
    type: "CHANGE_PAGE_SIZE_OLD_USERS",
    payload: pageSize
  })
}

export const setTotalStudentReport = num => async dispatch => {
  dispatch({
    type: "SET_TOTAL_STUDENT_REPORT",
    payload: num
  })
}


export const writeUserState = ({ searchString }) => dispatch => {
  dispatch({
    type: "WRITE_USER_STATE",
    searchString
  })
}


export const removeUserState = () => dispatch => {
  dispatch({
    type: "REMOVE_USER_STATE",
  })
}


export const getStudentAbsenceByList = ({
  currentPage = 1,
  searchString = "",
  courseId = 0,
  sortBy = "Date",
  sortOrder = "ASC",
  pageSize = 10,
  cb,
  startDate,
  endDate
}) => async dispatch => {
  dispatch(setLoading(true));
  try {
    const url = `${REPORT_ENDPOINT}/studentabsencebylist/?currentPage=${currentPage - 1}&pageSize=${pageSize}&searchString=${encodeURIComponent(searchString.replace(/\[/g, "[[]"))}&sortBy=${sortBy}&sortOrder=${sortOrder}&courseId=${courseId}&startDate=${startDate}&endDate=${endDate}`;
    const response = await api.get(url);

    if (currentPage > 0) {
      if (response.data && response.data.data) {
        dispatch({
          type: "SET_STUDENT_SUBMIT",
          payload: Array.isArray(response.data.data) ? response.data.data : [],
          studentCurrentPage: currentPage,
          studentTotalItems: response.data.totalItems,
          studentPageCount: response.data.pageCount,
        });
      }
    } else {
      if (cb && response.data) cb(response.data.data);
    }

  } catch (error) { }
  dispatch(setLoading(false));
};
