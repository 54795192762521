import { Document, Packer } from "docx";
import { saveAs } from "file-saver";
import { parse } from "html-to-ast";
import { controller } from "./docxUtils";

export const TYPES = {
    PARAGRAPH: "PARAGRAPH",
    TEXT: "TEXT",
    IMAGE: "IMAGE",
    TABLE: "TABLE",
    TABLE_ROW: "TABLE_ROW",
    TABLE_CELL: "TABLE_CELL",
    BULLET_POINTS: "BULLET_POINTS",
    MATH: "MATH",
    MATH_RUN: "MATH_RUN",
    MATH_FRACTION: "MATH_FRACTION",
    NUMERATOR: "NUMERATOR",
    DENOMINATOR: "DENOMINATOR",
    MATH_RADICAL: "MATH_RADICAL",
    MATH_SUPER_SCRIPT: "MATH_SUPER_SCRIPT",
    MATH_SUB_SUPER_SCRIPT: "MATH_SUB_SUPER_SCRIPT",
    MATH_SQUARE_BRACKETS: "MATH_SQUARE_BRACKETS",
    MATH_ROUND_BRACKETS: "MATH_ROUND_BRACKETS",
    MATH_SUB_SCRIPT: "MATH_SUB_SCRIPT",
    MATH_CURLY_BRACKETS: "MATH_CURLY_BRACKETS",
};

const MFENCED = {
    "[": TYPES.MATH_SQUARE_BRACKETS,
    "(": TYPES.MATH_ROUND_BRACKETS,
    "{": TYPES.MATH_CURLY_BRACKETS,
    "|": TYPES.MATH_SQUARE_BRACKETS,
};

const isUnderlineStyle = (node) => {
    if (!node.attrs) return false;
    if (Object.keys(node.attrs).length === 0) return false;
    if (!node.attrs.style) return;

    return node.attrs.style.includes("text-decoration: underline");
};

const htmlToAst = (htmlStr) => {
    return parse(htmlStr);
};

const astToTreeContent = (ast) => {
    return ast.map((astNode) => {
        return convertAstNodeToContentNode(astNode);
    });
};

const convertAstNodeToContentNode = (astNode, context) => {
    if (astNode.type === "text") {
        if (!context) {
            return {
                type: TYPES.PARAGRAPH,
                children: [
                    {
                        type: TYPES.TEXT,
                        content: astNode.content,
                    },
                ],
            };
        } else if (!context.hasParagraph) {
            return {
                type: TYPES.PARAGRAPH,
                children: [
                    {
                        type: TYPES.TEXT,
                        content: astNode.content,
                        ...context,
                    },
                ],
            };
        } else {
            return {
                type: TYPES.TEXT,
                content: astNode.content,
                ...context,
            };
        }
    }

    switch (astNode.name) {
        case "br":
            if (!context) {
                return {
                    type: TYPES.PARAGRAPH,
                    children: [
                        {
                            type: TYPES.TEXT,
                            content: "",
                        },
                    ],
                };
            }

            return {
                type: TYPES.TEXT,
                context: "",
                break: 1,
            };

        case "div":
        case "p":
        case "h1":
        case "h2":
        case "h3":
        case "h4":
        case "h5":
        case "h6":
            if (!context) {
                return {
                    type: TYPES.PARAGRAPH,
                    children: astNode.children
                        .map((childNode) => {
                            return convertAstNodeToContentNode(childNode, {
                                hasParagraph: true,
                            });
                        })
                        .flat(Infinity),
                };
            }

            return astNode.children
                .map((childNode) => {
                    return convertAstNodeToContentNode(childNode, {
                        hasParagraph: true,
                    });
                })
                .flat(Infinity);

        case "img":
            if (!context) {
                return {
                    type: TYPES.PARAGRAPH,
                    children: [
                        {
                            type: TYPES.IMAGE,
                            base64: astNode.attrs.src
                                .split(";")[1]
                                .split(",")[1],
                            width: +astNode.width,
                            height: +astNode.height,
                        },
                    ],
                };
            }

            return {
                type: TYPES.IMAGE,
                base64: astNode.attrs.src.split(";")[1].split(",")[1],
                width: +astNode.attrs.width,
                height: +astNode.attrs.height,
            };

        case "span":
            if (!context) {
                return {
                    type: TYPES.PARAGRAPH,
                    children: astNode.children
                        .map((childNode) => {
                            return convertAstNodeToContentNode(childNode, {
                                hasParagraph: true,
                                underline: isUnderlineStyle(astNode),
                            });
                        })
                        .flat(Infinity),
                };
            }

            return astNode.children
                .map((childNode) => {
                    return convertAstNodeToContentNode(childNode, {
                        ...context,
                        underline: isUnderlineStyle(astNode),
                    });
                })
                .flat(Infinity);

        case "strong":
            if (!context) {
                return {
                    type: TYPES.PARAGRAPH,
                    children: astNode.children
                        .map((childNode) => {
                            return convertAstNodeToContentNode(childNode, {
                                bold: true,
                                hasParagraph: true,
                            });
                        })
                        .flat(Infinity),
                };
            }

            return astNode.children
                .map((childNode) => {
                    return convertAstNodeToContentNode(childNode, {
                        ...context,
                        bold: true,
                    });
                })
                .flat(Infinity);

        case "em":
            if (!context) {
                return {
                    type: TYPES.PARAGRAPH,
                    children: astNode.children
                        .map((childNode) => {
                            return convertAstNodeToContentNode(childNode, {
                                italic: true,
                                hasParagraph: true,
                            });
                        })
                        .flat(Infinity),
                };
            }

            return astNode.children
                .map((childNode) => {
                    return convertAstNodeToContentNode(childNode, {
                        ...context,
                        italic: true,
                    });
                })
                .flat(Infinity);

        case "table":
            return {
                type: TYPES.TABLE,
                children: astNode.children
                    .filter((childNode) => {
                        return childNode.name === "tbody";
                    })
                    .map((childNode) => {
                        return convertAstNodeToContentNode(childNode);
                    })
                    .flat(1),
            };

        case "tbody":
            return astNode.children
                .filter((childNode) => {
                    return childNode.name === "tr";
                })
                .map((childNode) => {
                    return convertAstNodeToContentNode(childNode);
                });

        case "tr":
            return {
                type: TYPES.TABLE_ROW,
                children: astNode.children
                    .filter((childNode) => {
                        return childNode.name === "td";
                    })
                    .map((childNode) => {
                        return convertAstNodeToContentNode(childNode);
                    }),
            };

        case "td":
            return {
                type: TYPES.TABLE_CELL,
                children: astNode.children
                    .map((childNode) => {
                        return convertAstNodeToContentNode(childNode, {
                            hasParagraph: false,
                        });
                    })
                    .flat(Infinity),
            };

        case "math":
            if (context && context.hasParagraph) {
                return {
                    type: TYPES.MATH,
                    children: astNode.children.map((childNode) => {
                        return convertAstNodeToContentNode(childNode, {
                            hasParagraph: true,
                        });
                    }),
                };
            }

            return {
                type: TYPES.PARAGRAPH,
                children: [
                    {
                        type: TYPES.MATH,
                        children: astNode.children.map((childNode) => {
                            return convertAstNodeToContentNode(childNode, {
                                hasParagraph: true,
                            });
                        }),
                    },
                ],
            };

        case "mstyle":
            return convertAstNodeToContentNode(astNode.children[0]);

        case "mfrac":
            const numerator = convertAstNodeToContentNode(astNode.children[0], {
                hasParagraph: true,
            });
            const denominator = convertAstNodeToContentNode(
                astNode.children[1],
                {
                    hasParagraph: true,
                }
            );

            return {
                type: TYPES.MATH_FRACTION,
                numerator: Array.isArray(numerator)
                    ? numerator.flat(Infinity)
                    : [numerator],
                denominator: Array.isArray(denominator)
                    ? denominator.flat(Infinity)
                    : [denominator],
            };

        case "mrow":
            return astNode.children.map((childNode) => {
                return convertAstNodeToContentNode(childNode);
            });

        case "mfenced":
            return {
                type: MFENCED[astNode.attrs.open],
                children: astNode.children
                    .map((childNode) => {
                        return convertAstNodeToContentNode(childNode);
                    })
                    .flat(Infinity),
            };

        case "msqrt":
            return {
                type: TYPES.MATH_RADICAL,
                children: astNode.children.map((childNode) => {
                    return convertAstNodeToContentNode(childNode);
                }),
            };

        case "mroot":
            const degree = convertAstNodeToContentNode(astNode.children[1], {
                hasParagraph: true,
            });

            return {
                type: TYPES.MATH_RADICAL,
                children: [
                    convertAstNodeToContentNode(astNode.children[0], {
                        hasParagraph: true,
                    }),
                ],
                degree: Array.isArray(degree) ? degree : [degree],
            };

        case "sup":
            const supScript = convertAstNodeToContentNode(astNode.children[0], {
                hasParagraph: true,
            });

            return {
                type: TYPES.MATH,
                children: [
                    {
                        type: TYPES.MATH_SUPER_SCRIPT,
                        children: [
                            {
                                type: TYPES.MATH_RUN,
                                content: " ",
                            },
                        ],
                        superScript: [
                            {
                                type: TYPES.MATH_RUN,
                                content: astNode.children[0].content,
                            },
                        ],
                    },
                ],
            };

        case "msup":
            const superScript = convertAstNodeToContentNode(
                astNode.children[1],
                {
                    hasParagraph: true,
                }
            );

            return {
                type: TYPES.MATH_SUPER_SCRIPT,
                children: [
                    convertAstNodeToContentNode(astNode.children[0], {
                        hasParagraph: true,
                    }),
                ],
                superScript: Array.isArray(superScript)
                    ? superScript
                    : [superScript],
            };

        case "msub":
            const subScript = convertAstNodeToContentNode(astNode.children[1], {
                hasParagraph: true,
            });

            return {
                type: TYPES.MATH_SUB_SCRIPT,
                children: [
                    convertAstNodeToContentNode(astNode.children[0], {
                        hasParagraph: true,
                    }),
                ],
                subScript: Array.isArray(subScript) ? subScript : [subScript],
            };

        case "sub":
            return {
                type: TYPES.MATH,
                children: [
                    {
                        type: TYPES.MATH_SUB_SCRIPT,
                        children: [
                            {
                                type: TYPES.MATH_RUN,
                                content: " ",
                            },
                        ],
                        subScript: [
                            {
                                type: TYPES.MATH_RUN,
                                content: astNode.children[0].content,
                            },
                        ],
                    },
                ],
            };

        case "mi":
        case "mo":
        case "mn":
            return {
                type: TYPES.MATH_RUN,
                content: astNode.children[0].content,
            };

        default:
            break;
    }
};

const treeContentToDocx = (treeContent) => {
    const children = [];

    treeContent
        .filter((item) => {
            return item.content !== " ";
        })
        .forEach((item) => {
            children.push(controller(item));
        });

    const doc = new Document({
        sections: [
            {
                children: children,
            },
        ],
    });

    return doc;
};

const saveDocumentToFile = (doc, fileName) => {
    const mimeType =
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

    Packer.toBlob(doc).then((blob) => {
        const docblob = blob.slice(0, blob.size, mimeType);
        saveAs(docblob, fileName);
    });
};

const htmlEscape = (html) => {
    return html
        .replaceAll("&rsquo;", "’")
        .replaceAll("&lsquo;", "‘")
        .replaceAll("&nbsp;", " ")
        .replaceAll("&#160;", " ")
        .replaceAll("&times;", "×")
        .replaceAll("&sbquo;", "‚")
        .replaceAll("&ldquo;", "“")
        .replaceAll("&rdquo;", "”")
        .replaceAll("&thinsp;", " ")
        .replaceAll("&quot;", "“")
        .replaceAll("&#8594;", "→")
        .replaceAll("&#8710;", "Δ")
        .replaceAll("&#39;", "‘")
        .replaceAll("&minus;", "−")
        .replaceAll("&gt;", ">");
};

const htmlToDocx = (html, fileName) => {
    const ast = htmlToAst(htmlEscape(html));
    console.log("Ast: ", ast);

    const treeContent = astToTreeContent(ast);
    console.log("treeContent", treeContent);

    const docxContent = treeContentToDocx(treeContent.filter(Boolean));

    saveDocumentToFile(docxContent, fileName);
};

export { htmlToDocx };
