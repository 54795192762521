import React from "react";
import "./style.scss";

const PreviewQuestionProofreader = ({
    questionHighlightHtml,
    solution,
    answer,
    syllabus,
    purpose,
    categoryId,
    difficultyLevel,
    subject,
    category
}) => {
    return (
        <div style={{
            marginBottom: 60
        }} className="comment-proof bg-white">
            <table border={1}>
                <tr>
                    <td colSpan={2}></td>
                    <td className="head">File Name</td>
                    <td></td>
                </tr>

                <tr>
                    <td className="head">Subject</td>
                    <td>{subject}</td>
                    <td className="head">Category Number</td>
                    <td>{categoryId}</td>
                </tr>

                <tr>
                    <td className="head">Category</td>
                    <td>{category}</td>
                    <td className="head">Description</td>
                    <td></td>
                </tr>

                <tr>
                    <td className="head">Level</td>
                    <td>{difficultyLevel}</td>
                    <td className="head">Correction Rate</td>
                    <td></td>
                </tr>

                <tr>
                    <td className="head">Writer</td>
                    <td></td>
                    <td className="head">Date</td>
                    <td></td>
                </tr>

                <tr>
                    <td colSpan={4} className="head">
                        Question 1
                    </td>
                </tr>

                <tr>
                    <td colSpan={4}>
                        <div
                            className="can-highlight"
                            dangerouslySetInnerHTML={{
                                __html: questionHighlightHtml
                            }}
                        />
                    </td>
                </tr>

                <tr>
                    <td colSpan={2} className="head">
                        Answer
                    </td>
                    <td colSpan={2} className="head">
                        Solution
                    </td>
                </tr>

                <tr>
                    <td colSpan={2}>
                        <div
                            className="can-highlight"
                            dangerouslySetInnerHTML={{
                                __html: answer
                            }}
                        />
                    </td>
                    <td colSpan={2}>
                        <div
                            className="can-highlight"
                            dangerouslySetInnerHTML={{
                                __html: solution
                            }}
                        />
                    </td>
                </tr>

                <tr>
                    <td colSpan={4} className="head">
                        Note (Question purpose, what theory use, benefits for
                        students.)
                    </td>
                </tr>

                <tr>
                    <td style={{ minHeight: 50 }} colSpan={4}>
                        <div 
                            dangerouslySetInnerHTML={{
                                __html: purpose
                            }}
                        />
                    </td>
                </tr>

                <tr>
                    <td colSpan={4} className="head">
                        Syllabus
                    </td>
                </tr>

                <tr>
                    <td style={{ minHeight: 50 }} colSpan={4}>
                        <div 
                            dangerouslySetInnerHTML={{
                                __html: syllabus
                            }}
                        />
                    </td>
                </tr>
            </table>
        </div>
    );
};

export default PreviewQuestionProofreader;
