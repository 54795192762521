import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Row, Col, Table, Alert, Collapse, Button, Input, Label } from "reactstrap"
import _ from "lodash"
import DatePicker from "react-datepicker"
import moment from "moment"

import SearchBox from "../../../components/SearchBox"
import LinkEditBtn from "../../../components/Button/LinkEditBtn"
import ViewBtn from "../../../components/Button/ViewBtn"
import CustomPagination from "../../../components/CustomPagination"
import UserSelector from "../../../components/Selector/UserSelected"
import CustomSelector from "../../../components/Selector/CustomSelected"
import TestSuiteSelector from "../../../components/Selector/TestSuiteSelector"
import TestPackSelector from "../../../components/Selector/TestPackSelector"

import setModal from "../../../components/Modal/action"
import {
    getAllResult,
    getResult,
    changeCurrentPageTestResult,
    changePageSizeTestResult
} from "../redux/action"

import { SERVER_DATE_FORMAT } from "../../../util/Constant"

const statusOptions = [
    { label: "All", value: null },
    { label: "In progress", value: 0 },
    { label: "Pending Evaluation", value: 1 },
    { label: "Completed", value: 2 }
]

const TestResult = props => {
    const { t } = useTranslation("common")

    const { testResultList, currentPage, pageSize, pageCount, totalItems } = props

    const [isOpen, setIsOpen] = useState(false)

    const [testSuiteIds, setTestSuiteIds] = useState([])
    const [testPackIds, setTestPackIds] = useState([])
    const [students, setStudents] = useState([])
    const [startTime, setStartTime] = useState(null)
    const [endTime, setEndTime] = useState(null)
    const [status, setStatus] = useState(null)

    const [searchString, setSearchString] = useState("")

    const getAll = async page => {
        const { currentPage, pageSize } = props

        if (startTime && endTime) {
            const start = new Date(startTime).getTime()
            const end = new Date(endTime).getTime()

            if (start > end) {
                props.setModal({
                    type: "danger",
                    message: t("end_time_cant_equal_start_time")
                })

                return
            }
        }

        const filterings = {
            currentPage: page || currentPage,
            pageSize,
            testSuiteIds,
            testPackIds,
            searchString
        }

        if (status !== null) {
            filterings.status = status
        }

        filterings.studentIds = students.map(student => student.userId)
        if (startTime) filterings.startTime = moment(startTime).format(SERVER_DATE_FORMAT)
        if (endTime) filterings.endTime = moment(endTime).format(SERVER_DATE_FORMAT)

        await props.getAllResult(filterings)

        if (page) {
            await props.changeCurrentPageTestResult(page)
        }
    }

    useEffect(() => {
        document.title = t("test_result")
        if (localStorage.getItem('studentId')) {
            setStudents([{
                email: localStorage.getItem('studentEmail'),
                label: `${localStorage.getItem('studentName')} (${localStorage.getItem('studentEmail')})`,
                userId: localStorage.getItem('studentId'),
                value: localStorage.getItem('studentId'),
            }])
            localStorage.removeItem('studentId')
            localStorage.removeItem('studentName')
            localStorage.removeItem('studentEmail')
        }
        getAll(1)
    }, [status, startTime, endTime, students, testPackIds, testSuiteIds])

    useEffect(() => {
        getAll(currentPage)
    }, [currentPage])

    return <div>
        <Row className="mb-2">
            <Col md={4}>
                <SearchBox
                    className="mr-0"
                    value={searchString}
                    onChange={e => setSearchString(e.target.value)}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            getAll(1)
                        }
                    }}
                />
            </Col>
            <Col md={2} className="d-flex align-items-center">
                <Button
                    color="link"
                    className="m-0"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {isOpen ? t("hide_advanced_search") : t("show_advanced_search")}
                </Button>
            </Col>
        </Row>
        <Collapse isOpen={isOpen}>
            <Row>
                <Col md={8}>
                    <Row className="mb-2">
                        <Col md={2}>
                            <Label>{t("start_time")}</Label>
                            <DatePicker
                                todayButton={t('today')}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                value={startTime && moment(startTime).format("L")}
                                selected={startTime}
                                onChange={e => setStartTime(e)}
                                placeholderText={t('start_time')}
                                customInput={
                                    <Input
                                        invalid={false}
                                    />
                                }
                                locale="en-gb"
                            />
                        </Col>
                        <Col md={2}>
                            <Label>{t("end_time")}</Label>
                            <DatePicker
                                todayButton={t('today')}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                value={endTime && moment(endTime).format("L")}
                                selected={endTime}
                                onChange={e => setEndTime(e)}
                                placeholderText={t('end_time')}
                                customInput={
                                    <Input
                                        invalid={false}
                                    />
                                }
                                locale="en-gb"
                            />
                        </Col>
                        <Col md={4}>
                            <Label>{t("students")}</Label>
                            <UserSelector
                                require
                                noLabel
                                userRole="Student"
                                includeEmail
                                isMulti
                                important
                                value={students}
                                onChange={e => setStudents(e)}
                            />
                        </Col>
                        <Col md={4}>
                            <Label>{t("status")}</Label>
                            <CustomSelector
                                options={statusOptions}
                                value={statusOptions.find(option => option.value === status)}
                                onChange={e => setStatus(e.value)}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={4}>
                            <Label>{t("test_packs")}</Label>
                            <TestPackSelector
                                onChange={e => setTestPackIds(e.map(item => item.value))}
                            />
                        </Col>
                        <Col md={4}>
                            <Label>{t("test_suites")}</Label>
                            <TestSuiteSelector
                                onChange={e => setTestSuiteIds(e.map(item => item.value))}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Collapse>
        {totalItems
            ? <Row>
                <Col md={8} className="text-right">
                    <b>{t("total_test_result")}: {totalItems}</b>
                </Col>
            </Row>
            : null}
        <Row className="mb-2">
            {testResultList && testResultList.length
                ? <Col md={8}>
                    <Table hover bordered striped responsive className="grid-table mb-2">
                        <thead>
                            <tr>
                                {[
                                    "assessment.title",
                                    "student",
                                    "right_percent",
                                    "right_answers",
                                    "wrong_answers",
                                    "pending_answers",
                                    "unanswered_questions",
                                    "submitted_time",
                                ].map((th, index) => <th key={index} className="align-top">
                                    {t(th)}
                                </th>)}
                                <th className="align-top text-center">
                                    {t("action")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {testResultList.map((testResult, index) => <tr key={index}>
                                <td>{testResult.examName}</td>
                                <td>
                                    <LinkEditBtn
                                        label={_.get(testResult, "student.identity.fullName", "")}
                                        onClick={() => window.open(`/user/edit-user/${_.get(testResult, "student.identity.id")}`)}
                                    />
                                </td>
                                <td>{`${Math.round(testResult.rightAnswerCount / testResult.totalNumberOfQuestions * 100, 2)}%`}</td>
                                <td>{testResult.rightAnswerCount}</td>
                                <td>{testResult.wrongAnswerCount}</td>
                                <td>{testResult.markPendingAnswerCount}</td>
                                <td>{testResult.unansweredQuestionCount}</td>
                                <td>
                                    {testResult.status == "New"
                                        ? 'Not submitted'
                                        : moment(testResult.submittedDateTime).format("L")}
                                </td>
                                <td className="text-center">
                                    <ViewBtn
                                        onClick={() => props.history.push(`/assessment-response/${testResult.id}`)}
                                    />
                                </td>
                            </tr>)}
                        </tbody>
                    </Table>
                </Col>
                : <Col md={8} sm={12}>
                    <Alert color="primary" className="mb-0">{t("no_test_result_to_display")}</Alert>
                </Col>}
        </Row>

        <Row className="mb-2">
            <Col md={8} className="text-right">
                <CustomPagination
                    pageCount={pageCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    pageSizeName="testResultPageSize"
                    changePage={page => {
                        props.changeCurrentPageTestResult(page)
                    }}
                    changePageSize={props.changePageSizeTestResult}
                    onChangePageSize={() => getAll(1)}
                />
            </Col>
        </Row>
    </div>
}

const mapStateToProps = state => ({
    testResultList: state.testResult.testResultList,
    currentPage: state.testResult.currentPage,
    pageCount: state.testResult.pageCount,
    pageSize: state.testResult.pageSize,
    totalItems: state.testResult.totalItems
})

const mapDispatchToProps = {
    setModal,
    getAllResult,
    getResult,
    changeCurrentPageTestResult,
    changePageSizeTestResult
}

export default connect(mapStateToProps, mapDispatchToProps)(TestResult)