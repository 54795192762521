import React from 'react'
import { Row } from 'reactstrap';
import CustomBtn from '../../../components/Button/CustomBtn';

const localAvatar = require('../../../assets/img/avatar_default.PNG');
// kb -> mb
const imageMaxSize = 1000000;

export default class extends React.PureComponent {
    constructor(props) {
        super(props);
        this.formData = new FormData();
    }

    getAvatar = () => this.formData;

    render() {
        const {
            avatar,
            setModal,
            onChangeAvatar,
            onChangeHadAvatar,
            viewOnly, t } = this.props;
        return (
            <Row className="my-2 px-3">
                <div>
                    <img
                        src={avatar || localAvatar}
                        alt="avatar"
                        className=" center-cropped"
                        style={{ width: '120px', height: '120px', objectFit: "cover" }}
                    />
                    <input type="file"
                        onChange={e => {
                            if (e.target.files[0]) {
                                if (e.target.files[0].size > imageMaxSize) {
                                    setModal({
                                        type: "danger",
                                        message: t('image_cant_larger'),
                                    });
                                    return
                                }
                                this.formData = new FormData();
                                onChangeAvatar(window.URL.createObjectURL(e.target.files[0]));
                                onChangeHadAvatar(true);
                                this.formData.append("filesUpload", e.target.files[0], e.target.files[0].name);
                            }
                        }}
                        accept="image/x-png,image/jpeg"
                        name="filesUpload" ref={ref => { this.avatarPicker = ref }} className="d-none" />
                </div>

                <div className="ml-2 d-flex flex-column justify-content-end">
                    <CustomBtn
                        title={t("Upload")}
                        color="success"
                        icon="fa fa-cloud-upload"
                        className="mb-2"
                        onClick={() => this.avatarPicker.click()}
                        disabled={viewOnly}
                    />

                    <CustomBtn
                        color="danger"
                        icon="fa fa-trash"
                        className="px-3"
                        onClick={() => {
                            if (avatar) {
                                setModal({
                                    type: "warning",
                                    message: t('remove_avatar_message'),
                                    cb: () => {
                                        onChangeAvatar(null);
                                    },
                                });
                            }
                        }}
                        disabled={viewOnly}
                    />
                </div>
            </Row>
        )
    }
}
