import React from 'react'
import { Prompt } from 'react-router-dom'
import { ALERT } from '../util/Constant'

export default function QuitPrompt(props) {
    return (
        <Prompt
            when={props.when}
            message={ALERT.quit_but_not_saved} />
    )
}
